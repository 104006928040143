import { unsafelyKeys } from 'common/utilities/object';
import { FilterCategories } from 'domains/topicSearchFilter/types';
import { RootState } from 'types';

// if we didn't pass type => false
// if passed "publications", "videos", "data" => true
// if passed any other type => hasOwnFilters || isAdvancedModeOn
export const getContentHasFilters =
  (type?: FilterCategories) =>
  (state: RootState): boolean => {
    if (!type) {
      return false;
    }
    return !!getTopicFilters(type)(state).length;
  };

export const getLatestContentHasFilters =
  () =>
  (state: RootState): boolean => {
    const { filters } = state.domains.topicContentMeta;
    let hasOwnFilters = false;

    if (filters) {
      unsafelyKeys(filters).forEach((key) => {
        if (filters[key].length) hasOwnFilters = true;
      });
    }
    return hasOwnFilters;
  };

export const getTopicFilters = (type?: FilterCategories) => (state: RootState) =>
  state.domains.topicContentMeta.filters && type ? state.domains.topicContentMeta.filters[type] : [];

export const getTopicContentMetaIsLoaded = (state: RootState) => !!state.domains.topicContentMeta.filters;
