import union from 'lodash.union';
import { combineReducers } from 'redux';

import { CoachmarkEntityActionType, FetchCoachmarksAction } from 'entities/coachmarks/actionTypes';
import { CoachmarkEntityState } from 'entities/coachmarks/types';
import { addUserOrLanguageChangeReset } from 'entities/topics/reducerHelpers';

const initialState: CoachmarkEntityState = {
  allIds: [],
  byId: {},
};

const allIds = (state = initialState.allIds, action: FetchCoachmarksAction): CoachmarkEntityState['allIds'] => {
  switch (action.type) {
    case CoachmarkEntityActionType.FETCH_FULFILLED:
      return union(
        state,
        action.payload.coachmarks.map(({ id }) => id),
      );

    default:
      return state;
  }
};

const byId = (state = initialState.byId, action: FetchCoachmarksAction): CoachmarkEntityState['byId'] => {
  switch (action.type) {
    case CoachmarkEntityActionType.FETCH:
      return Object.keys(state).reduce(
        (byId, id) => ({
          ...byId,
          [id]: { ...state[id], isFetching: true },
        }),
        {},
      );

    case CoachmarkEntityActionType.FETCH_FULFILLED:
      return action.payload.coachmarks.reduce(
        (byId, coachmark) => ({
          ...byId,
          [coachmark.id]: {
            ...state[coachmark.id],
            ...coachmark,
            isFetching: false,
          },
        }),
        state,
      );

    case CoachmarkEntityActionType.FETCH_REJECTED:
      return Object.keys(state).reduce(
        (byId, id) => ({
          ...byId,
          [id]: { ...state[id], isFetching: false },
        }),
        {},
      );

    default:
      return state;
  }
};

export const coachmarksReducer = combineReducers({
  allIds: addUserOrLanguageChangeReset(allIds, initialState.allIds),
  byId: addUserOrLanguageChangeReset(byId, initialState.byId),
});
