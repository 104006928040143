import { SVGProps } from 'react';

export const BookmarkFilledIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16,1H2V19l7-8,7,8Z"
      transform="translate(-1)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      fill="currentColor"
    />
  </svg>
);
