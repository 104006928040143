import { MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css, styled } from 'styled-components';

import { ThemeDarkIcon, ThemeLightIcon } from 'common/components';
import { useTranslations } from 'domains/language/useTranslations';
import { toggleThemeMode } from 'domains/theme/actions';
import { getCurrentThemeMode } from 'domains/theme/selectors';

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
`;

const themeIconStyle = css`
  width: 18px;
  height: 18px;
`;

const StyledLabel = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.blue};
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;
`;

const StyledDarkThemeIcon = styled(ThemeDarkIcon)`
  ${themeIconStyle};
`;

const StyledLightThemeIcon = styled(ThemeLightIcon)`
  ${themeIconStyle};
`;

export const ThemeButton = () => {
  const dispatch = useDispatch();
  const { textDictionary } = useTranslations();
  const currentThemeMode = useSelector(getCurrentThemeMode);

  const onClick = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    requestAnimationFrame(() => {
      dispatch(toggleThemeMode());
    });
  };

  let label = <StyledLabel>{textDictionary['app.settings.default']}</StyledLabel>;
  if (currentThemeMode !== 'default') {
    label = currentThemeMode === 'dark' ? <StyledDarkThemeIcon /> : <StyledLightThemeIcon />;
  }

  return (
    <StyledContainer onClick={onClick}>
      <div>{textDictionary['app.settings.theme.title']}</div>
      {label}
    </StyledContainer>
  );
};
