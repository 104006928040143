import { useState, useEffect } from 'react';

const isFullScreenElement = () => {
  const el: any = document;
  return Boolean(
    el.fullscreenElement ||
      el.mozFullScreenElement ||
      el.webkitFullscreenElement ||
      el.msFullscreenElement ||
      el.fullscreen ||
      el.mozFullScreen ||
      el.webkitIsFullScreen ||
      el.fullScreenMode,
  );
};

const supportsFullscreen = () => {
  const el: any = document.documentElement;
  return Boolean(
    !!el.requestFullscreen || !!el.mozRequestFullScreen || !!el.webkitRequestFullscreen || !!el.msRequestFullscreen,
  );
};

export function useFullScreen() {
  const initialState = isFullScreenElement();
  const [isFullScreen, setIsFullScreen] = useState(initialState);

  const openFullScreen = () => {
    const el: any = document.documentElement;
    if (el.requestFullscreen) {
      return el.requestFullscreen();
    } else if (el.mozRequestFullScreen) {
      return el.mozRequestFullScreen();
    } else if (el.webkitRequestFullscreen) {
      return el.webkitRequestFullscreen();
    } else if (el.msRequestFullscreen) {
      return el.msRequestFullscreen();
    }
  };

  const closeFullScreen = () => {
    const el: any = document;
    if (el.exitFullscreen) {
      return el.exitFullscreen();
    } else if (el.mozCancelFullScreen) {
      return el.mozCancelFullScreen();
    } else if (el.webkitExitFullscreen) {
      return el.webkitExitFullscreen();
    } else if (el.msExitFullscreen) {
      return el.msExitFullscreen();
    }
  };

  useEffect(() => {
    function handleChange() {
      setIsFullScreen(isFullScreenElement());
    }

    document.addEventListener('webkitfullscreenchange', handleChange, false);
    document.addEventListener('mozfullscreenchange', handleChange, false);
    document.addEventListener('msfullscreenchange', handleChange, false);
    document.addEventListener('MSFullscreenChange', handleChange, false);
    document.addEventListener('fullscreenchange', handleChange, false);

    return () => {
      document.removeEventListener('webkitfullscreenchange', handleChange);
      document.removeEventListener('mozfullscreenchange', handleChange);
      document.removeEventListener('msfullscreenchange', handleChange);
      document.removeEventListener('MSFullscreenChange', handleChange);
      document.removeEventListener('fullscreenchange', handleChange);
    };
  }, []);

  const isAvailable = supportsFullscreen();

  return {
    isFullScreen,
    open: openFullScreen,
    close: closeFullScreen,
    toggle: isFullScreen ? closeFullScreen : openFullScreen,
    isAvailable,
  };
}
