import { combineReducers } from 'redux';

import { CreateMapTypes, EditMapAction } from 'domains/createMapDetail/actionTypes';
import { CustomKeyIssueAction, CustomKeyIssueTypes } from 'domains/customKeyIssue/actionTypes';
import { CustomKeyIssueState } from 'domains/customKeyIssue/types';
import { addUserOrLanguageChangeReset } from 'entities/topics/reducerHelpers';
import { addUserChangeReset } from 'entities/user/utils';

const initialState: CustomKeyIssueState = {
  upload: {
    isCreateFormActive: false,
    isPending: false,
    isFulfilled: false,
    error: false,
  },
  topicSearch: {
    isPending: false,
    idsForQuery: {},
    currentQuery: '',
  },
  tagSearch: {
    isPending: false,
    idsForQuery: {},
    byId: {},
    currentQuery: '',
  },
  fingerprint: {
    isPending: false,
    error: false,
    data: [],
    mappings: [],
    removedConcepts: [],
    isPendingKnowledge: false,
    knowledgeError: false,
    knowledgeIds: [],
  },
  suggestions: {
    isPending: false,
    error: false,
    tags: [],
    topics: [],
  },
};

const upload = (
  state = initialState.upload,
  action: CustomKeyIssueAction | EditMapAction,
): CustomKeyIssueState['upload'] => {
  switch (action.type) {
    case CustomKeyIssueTypes.CREATE:
      return {
        ...state,
        isPending: true,
        error: false,
      };
    case CustomKeyIssueTypes.CREATE_FULFILLED:
      return {
        ...state,
        isPending: false,
        isCreateFormActive: false,
        entityId: undefined,
        error: false,
      };
    case CustomKeyIssueTypes.CREATE_REJECTED:
      return {
        ...state,
        isPending: false,
        error: true,
      };
    case CustomKeyIssueTypes.ACTIVATE_FORM:
      return {
        ...state,
        isCreateFormActive: action.meta?.isActive ?? !state.isCreateFormActive,
        entityId: action.meta?.id,
        error: false,
      };
    case CustomKeyIssueTypes.LOAD:
      return {
        ...state,
        isCreateFormActive: true,
        entityId: action.meta.id,
      };
    case CreateMapTypes.ADD_NEW_CUSTOM_KEY_ISSUE:
      return {
        ...state,
        isCreateFormActive: false,
        error: false,
      };
    default:
      return state;
  }
};

const topicSearch = (
  state = initialState.topicSearch,
  action: CustomKeyIssueAction,
): CustomKeyIssueState['topicSearch'] => {
  switch (action.type) {
    case CustomKeyIssueTypes.FETCH_TOPIC_SEARCH: {
      return {
        ...state,
        isPending: true,
      };
    }
    case CustomKeyIssueTypes.FETCH_TOPIC_SEARCH_FULFILLED: {
      return {
        ...state,
        isPending: false,
        currentQuery: action.meta.query,
        idsForQuery: {
          ...state.idsForQuery,
          [action.meta.query]: action.payload.map((item) => item.id),
        },
      };
    }
    case CustomKeyIssueTypes.FETCH_TOPIC_SEARCH_REJECTED: {
      return {
        ...state,
        isPending: false,
      };
    }
    case CustomKeyIssueTypes.RESET_TOPIC_SEARCH_IDS_FOR_QUERY: {
      return {
        ...state,
        idsForQuery: {},
      };
    }
    default:
      return state;
  }
};

const tagSearch = (state = initialState.tagSearch, action: CustomKeyIssueAction): CustomKeyIssueState['tagSearch'] => {
  switch (action.type) {
    case CustomKeyIssueTypes.FETCH_CONCEPT_SEARCH:
      return {
        ...state,
        isPending: true,
      };
    case CustomKeyIssueTypes.FETCH_CONCEPT_SEARCH_FULFILLED:
      return {
        ...state,
        isPending: false,
        currentQuery: action.meta.query,
        idsForQuery: {
          ...state.idsForQuery,
          [action.meta.query]: action.payload.map((item) => item.id),
        },
        byId: action.payload.reduce(
          (byId, concept) => ({
            ...byId,
            [concept.id]: {
              ...state.byId[concept.id],
              ...concept,
            },
          }),
          state.byId,
        ),
      };
    case CustomKeyIssueTypes.FETCH_CONCEPT_SEARCH_REJECTED:
      return {
        ...state,
        isPending: false,
      };
    default:
      return state;
  }
};

const fingerprint = (
  state = initialState.fingerprint,
  action: CustomKeyIssueAction,
): CustomKeyIssueState['fingerprint'] => {
  switch (action.type) {
    case CustomKeyIssueTypes.FETCH_FINGERPRINT_BUILDER:
      return {
        ...state,
        isPending: true,
        error: false,
        mappings: action.payload.mappings,
      };
    case CustomKeyIssueTypes.FETCH_FINGERPRINT_BUILDER_FULFILLED: {
      return {
        ...state,
        isPending: false,
        error: false,
        data: action.payload,
        mappings: action.meta.mappings,
      };
    }
    case CustomKeyIssueTypes.FETCH_FINGERPRINT_BUILDER_REJECTED:
      return {
        ...state,
        isPending: false,
        error: true,
      };

    case CustomKeyIssueTypes.FETCH_FP_KNOWLEDGE:
      return {
        ...state,
        isPendingKnowledge: true,
        knowledgeError: false,
      };
    case CustomKeyIssueTypes.FETCH_FP_KNOWLEDGE_FULFILLED: {
      return {
        ...state,
        isPendingKnowledge: false,
        knowledgeIds: action.payload.map((x) => x.id),
        knowledgeError: false,
      };
    }
    case CustomKeyIssueTypes.FETCH_FP_KNOWLEDGE_REJECTED:
      return {
        ...state,
        isPendingKnowledge: false,
        knowledgeError: true,
        knowledgeIds: [],
      };
    case CustomKeyIssueTypes.ACTIVATE_FORM:
      return {
        ...state,
        knowledgeError: false,
        error: false,
      };
    default:
      return state;
  }
};

const suggestions = (
  state = initialState.suggestions,
  action: CustomKeyIssueAction,
): CustomKeyIssueState['suggestions'] => {
  switch (action.type) {
    case CustomKeyIssueTypes.FETCH_TOPIC_AND_TAG_SUGGESTIONS:
      return {
        ...state,
        isPending: true,
        error: false,
      };

    case CustomKeyIssueTypes.FETCH_TOPIC_AND_TAG_SUGGESTIONS_FULFILLED: {
      return {
        ...state,
        isPending: false,
        tags: action.payload.fp,
        topics: action.payload.topics,
        error: false,
      };
    }

    case CustomKeyIssueTypes.FETCH_TOPIC_AND_TAG_SUGGESTIONS_REJECTED:
      return {
        ...state,
        isPending: false,
        error: true,
      };

    default:
      return state;
  }
};

export const customKeyIssueReducer = combineReducers({
  upload: addUserOrLanguageChangeReset(upload, initialState.upload),
  topicSearch: addUserOrLanguageChangeReset(topicSearch, initialState.topicSearch),
  tagSearch: addUserChangeReset(tagSearch, initialState.tagSearch),
  fingerprint: addUserChangeReset(fingerprint, initialState.fingerprint),
  suggestions: addUserChangeReset(suggestions, initialState.suggestions),
});
