import { MouseEvent } from 'react';
import { ThemeProvider } from 'styled-components';

import { UIFollowButton } from 'common/components/UIFollowButton';
import { TrackMeta } from 'domains/analytics/types';
import { darkTheme } from 'domains/theme/constants/theme';
import { useTopicFollowing } from 'domains/topic/components/useTopicFollowing';

type TopicTileFollowButtonProps = { id: string; trackData?: TrackMeta };

export const TopicTileFollowButton = ({ id, trackData }: TopicTileFollowButtonProps) => {
  const { isFollowing, isFollowingUpdating, canFollow, toggleFollow } = useTopicFollowing(id);

  const onToggleFollowClick = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    toggleFollow({ event: trackData });
  };

  if (!canFollow || isFollowing === undefined) {
    return null;
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <UIFollowButton
        variant={'interactive'}
        isFollowing={isFollowing}
        onClick={onToggleFollowClick}
        isLoading={isFollowingUpdating}
      />
    </ThemeProvider>
  );
};
