import { ImageCollection } from 'common/types/imageCollection';
import { AddKeyIssuePayload, AIGeneratedMapProgress, LocalImage, UploadMapMeta } from 'domains/createMapDetail/types';
import { CustomKeyIssueMeta } from 'domains/customKeyIssue/types';
import { Topic } from 'entities/topics/types';
import { ErrorAction } from 'types';

export enum CreateMapTypes {
  INIT_FULFILLED = '[Create] - Init fulfilled',
  SET_IMAGE = 'domains/MAP_SET_IMAGE',
  RESET = 'domains/MAP_RESET',
  SET = 'domains/MAP_SET',
  UPLOAD = 'domains/MAP_UPLOAD',
  UPLOAD_FULFILLED = 'domains/MAP_UPLOAD_FULFILLED',
  UPLOAD_REJECTED = 'domains/MAP_UPLOAD_REJECTED',
  ADD_KEY_ISSUE = 'domains/ADD_KEY_ISSUE_TO_MAP',
  ADD_KEY_ISSUE_FULFILLED = 'domains/ADD_KEY_ISSUE_TO_MAP_FULFILLED',
  ADD_KEY_ISSUE_REJECTED = 'domains/ADD_KEY_ISSUE_TO_MAP_REJECTED',
  REMOVE_KEY_ISSUE = 'domains/REMOVE_KEY_ISSUE_TO_MAP',
  REMOVE_ALL_KEY_ISSUES = 'domains/REMOVE_ALL_KEY_ISSUES_TO_MAP',
  SET_DRAGGING = 'domains/TOGGLE_MAP_DRAGGING',
  ADD_NEW_CUSTOM_KEY_ISSUE = '[Create] - Add new Custom Key Issue to map',
  ENTER_AI_GENERATED_MODE = '[Create] - Enter AI generated mode',
  EXIT_AI_GENERATED_MODE = '[Create] - Exit AI generated mode',
  GET_AI_GENERATED_PROGRESS = '[Topic detail] - Get AI generated topic progress',
  GET_AI_GENERATED_PROGRESS_SUCCESS = '[Topic detail] - Get AI generated topic progress success',
}

export type AddKeyIssueToMapDefaultAction = { meta: { keyIssueId: string }; type: CreateMapTypes.ADD_KEY_ISSUE };

export type AddKeyIssueToMapFulfilledAction = {
  meta: { keyIssueId: string };
  payload: AddKeyIssuePayload;
  type: CreateMapTypes.ADD_KEY_ISSUE_FULFILLED;
};

export type AddKeyIssueToMapRejectedAction = {
  meta: { keyIssueId: string };
  type: CreateMapTypes.ADD_KEY_ISSUE_REJECTED;
};

export type RemoveKeyIssueFromMapAction = { payload: { keyIssueId: string }; type: CreateMapTypes.REMOVE_KEY_ISSUE };
export type RemoveAllKeyIssuesFromMapAction = { type: CreateMapTypes.REMOVE_ALL_KEY_ISSUES };
export type ResetMap = { type: CreateMapTypes.RESET };
export type SetMapDraggingAction = { meta: { isDragging: boolean }; type: CreateMapTypes.SET_DRAGGING };
export type AddNewCustomKeyIssueAction = { payload: CustomKeyIssueMeta; type: CreateMapTypes.ADD_NEW_CUSTOM_KEY_ISSUE };

export type EditMapAction =
  | AddKeyIssueToMapDefaultAction
  | AddKeyIssueToMapFulfilledAction
  | AddKeyIssueToMapRejectedAction
  | RemoveKeyIssueFromMapAction
  | RemoveAllKeyIssuesFromMapAction
  | ResetMap
  | SetMapDraggingAction
  | AddNewCustomKeyIssueAction;

export type CreateInitFulfilledAction = { payload: Topic; type: CreateMapTypes.INIT_FULFILLED };
export type UploadMapDefaultAction = { meta?: UploadMapMeta; type: CreateMapTypes.UPLOAD };

export type UploadMapFulfilledAction = {
  payload: { id: string; images?: ImageCollection; dimensions?: Topic[] };
  type: CreateMapTypes.UPLOAD_FULFILLED;
};

export type UploadMapRejectedAction = ErrorAction & { type: CreateMapTypes.UPLOAD_REJECTED };
export type MapSetAction = { meta: Partial<Topic>; type: CreateMapTypes.SET };
export type MapSetImageAction = { meta: { image: LocalImage }; type: CreateMapTypes.SET_IMAGE };
type EnterAIGeneratedModeAction = { type: CreateMapTypes.ENTER_AI_GENERATED_MODE };
type ExitAIGeneratedModeAction = { type: CreateMapTypes.EXIT_AI_GENERATED_MODE };
export type UpdateAIGeneratedMapProgressAction = { type: CreateMapTypes.GET_AI_GENERATED_PROGRESS };

export type UpdateAIGeneratedMapProgressSuccessAction = {
  meta: AIGeneratedMapProgress;
  type: CreateMapTypes.GET_AI_GENERATED_PROGRESS_SUCCESS;
};

export type CustomMapAction =
  | CreateInitFulfilledAction
  | MapSetImageAction
  | MapSetAction
  | UploadMapDefaultAction
  | UploadMapFulfilledAction
  | EnterAIGeneratedModeAction
  | ExitAIGeneratedModeAction
  | UpdateAIGeneratedMapProgressAction
  | UpdateAIGeneratedMapProgressSuccessAction
  | UploadMapRejectedAction;
