import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslations } from 'domains/language/useTranslations';
import { ModalHeader } from 'domains/modal/components/ModalHeader';
import { updateWhatsNewBadge } from 'domains/whatsNew/actions';
import { WhatsNewStoryItem } from 'domains/whatsNew/components/WhatsNewStoryItem';
import { getWhatsNewStories, getWhatsNewVersion } from 'domains/whatsNew/selectors';

const { localStorage } = window;

export const WHATS_NEW_VERSION = 'WHATS_NEW_VERSION';

export const WhatsNewModal = () => {
  const dispatch = useDispatch();
  const stories = useSelector(getWhatsNewStories);
  const version = useSelector(getWhatsNewVersion);
  const { textDictionary } = useTranslations();

  useEffect(() => {
    if (version) {
      localStorage.setItem(WHATS_NEW_VERSION, version);
    }
    dispatch(updateWhatsNewBadge({ showBadge: false }));
  }, [dispatch, version]);

  return (
    <>
      <ModalHeader title={textDictionary['app.whatsNew.title']} />
      <div>
        {stories.map((story) => (
          <WhatsNewStoryItem key={story.seq} {...story} />
        ))}
      </div>
    </>
  );
};
