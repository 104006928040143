import { SetVisualMediaPlayerAction, VisualMediaPlayerTypes } from 'domains/visualMediaPlayer/actionTypes';
import { VisualMediaPlayerState } from 'domains/visualMediaPlayer/types';

const initialState: VisualMediaPlayerState = {
  isPlaying: false,
};

export const visualMediaPlayerReducer = (
  state = initialState,
  action: SetVisualMediaPlayerAction,
): VisualMediaPlayerState => {
  switch (action.type) {
    case VisualMediaPlayerTypes.SET:
      return { ...state, file: action.meta };
    default:
      return state;
  }
};
