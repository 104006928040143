import { useDispatch } from 'react-redux';
import { styled } from 'styled-components';

import { breakpoints } from 'common/styles';
import { unauthenticate } from 'domains/authentication/actions';
import { useTranslations } from 'domains/language/useTranslations';

const StyledWrapper = styled.div`
  display: none;
  align-items: center;

  @media ${breakpoints.medium} {
    display: flex;
  }
`;

export const LogoutButton = () => {
  const dispatch = useDispatch();
  const { textDictionary } = useTranslations();

  const onClick = () => dispatch(unauthenticate({ isUserLogout: true }));

  return <StyledWrapper onClick={onClick}>{textDictionary['app.user.logout']}</StyledWrapper>;
};
