import {
  ModalActivateAction,
  ModalTypes,
  ModalPushScreenAction,
  ModalDeactivateAction,
  ModalPopScreenAction,
} from 'domains/modal/actionTypes';

export const activateModal = (meta: ModalActivateAction['meta']): ModalActivateAction => ({
  type: ModalTypes.ACTIVATE,
  meta,
});

export const deactivateModal = (): ModalDeactivateAction => ({
  type: ModalTypes.DEACTIVATE,
});

export const pushModalScreen = (meta: ModalPushScreenAction['meta']): ModalPushScreenAction => ({
  type: ModalTypes.PUSH_SCREEN,
  meta,
});

export const popModalScreen = (): ModalPopScreenAction => ({
  type: ModalTypes.POP_SCREEN,
});
