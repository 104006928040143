import feed from 'domains/resources/covers/Content Panel-Thumbnail.jpg';
import tms_1 from 'domains/resources/covers/Create-Thumbnail.jpg';
import discover from 'domains/resources/covers/Discover-Thumbnail.jpg';
import fullscreen from 'domains/resources/covers/Full Screen Mode-Thumbnail.jpg';
import brief from 'domains/resources/covers/Intelligence Briefings B-Thumbnail.jpg';
import languages from 'domains/resources/covers/Languages-Thumbnail.jpg';
import monitor from 'domains/resources/covers/Monitor-Thumbnail.jpg';
import sharing from 'domains/resources/covers/Sharing Maps-Thumbnail.jpg';
import tms_2 from 'domains/resources/covers/Smart Map Assistant-Thumbnail.jpg';
import introduction from 'domains/resources/covers/Strategic Intelligence - In Conversation-Thumbnail.png';
import themes from 'domains/resources/covers/Themes-Thumbnail.jpg';
import tms from 'domains/resources/covers/Transformation Maps-Thumbnail.jpg';

export const covers = {
  brief,
  discover,
  feed,
  fullscreen,
  introduction,
  languages,
  monitor,
  sharing,
  themes,
  tms_1,
  tms_2,
  tms,
};
