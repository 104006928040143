import { RootState } from 'types';

export const getNotificationList = (state: RootState) =>
  state.domains.notifications.list.map((id) => state.domains.notifications.data[id]);

export const getNotificationIsPending = (state: RootState) => state.domains.notifications.isPending;

export const getNotificationListOffset = (state: RootState) => state.domains.notifications.offset || 0;

export const getNotification = (id?: string) => (state: RootState) =>
  id ? state.domains.notifications.data[id] : undefined;

export const getNotificationHasNextPage = (state: RootState) => state.domains.notifications.hasNextPage || false;

export const getNotificationBadgeCount = (state: RootState) => state.domains.notifications.badgeCount;
