import { all, call, put } from 'redux-saga/effects';

import { setCookie } from 'common/utilities/cookies';
import { authenticateFailure, authenticateSuccess } from 'domains/authentication/actions';
import { FWSLoginAction } from 'domains/authentication/actionTypes';
import { fetchPermissionsSuccess } from 'entities/permissions/actions';
import { fetchPermissions } from 'entities/permissions/sagas/fetchPermissionsSaga';
import { fetchProfileSuccess } from 'entities/user/actions';
import { fetchProfile } from 'entities/user/sagas/fetchProfileSaga';
import { IS_AUTHENTICATING_COOKIE } from 'useAuth';

export function* fwsAuthLoginSaga(action: FWSLoginAction): any {
  try {
    const { idToken } = action.payload;
    const [profilePayload, permissionsPayload] = yield all([
      call(fetchProfile, idToken),
      call(fetchPermissions, idToken),
    ]);

    yield all([
      put(
        authenticateSuccess(action.payload, {
          event: { userId: profilePayload.id },
        }),
      ),
      put(fetchPermissionsSuccess(permissionsPayload)),
      put(
        fetchProfileSuccess({
          userData: profilePayload,
        }),
      ),
    ]);
  } catch (error) {
    console.error(error);
    yield put(authenticateFailure({ error: true }));
  }
  setCookie(IS_AUTHENTICATING_COOKIE, 'false', 'path=/');
}
