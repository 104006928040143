import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { fetchCollectionsSuccess, fetchCollectionsFailure } from 'domains/collection/actions';
import { FetchCollectionsAction } from 'domains/collection/actionTypes';
import { CollectionViewResponse } from 'domains/collection/types/collectionResponses';
import { CollectionType } from 'domains/collection/types/collectionType';

export function* fetchCollectionsSaga({ payload }: FetchCollectionsAction) {
  try {
    const { limit, offset, type } = payload;
    const queryParams = { ...queryParamsForType(type), limit, offset };
    const response: CollectionViewResponse[] = yield apiFetch('collection', { queryParams });
    yield put(fetchCollectionsSuccess({ ...payload, response }));
  } catch (error) {
    yield put(fetchCollectionsFailure(payload));
    console.error(`[fetchCollectionsSaga] Failed to fetch collections: ${error}`);
  }
}

const queryParamsForType = (type: CollectionType) => {
  if (type === CollectionType.Featured) return { featured: 'true' };
  if (type === CollectionType.Listed) return { listed: 'true' };
  return {};
};
