import { SVGProps } from 'react';

export const FullscreenIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      fill="currentColor"
      d="M3.145 1.885h8.833c.244 0 .48-.099.652-.276a.953.953 0 000-1.333.913.913 0 00-.652-.276H.92A.905.905 0 00.27.277.943.943 0 000 .944v11.293c0 .25.097.49.27.666a.913.913 0 001.306 0 .953.953 0 00.27-.667v-9.02l15.012 15.332a.91.91 0 001.282-.021.952.952 0 00.02-1.309L3.145 1.885zM16.855 29.45L1.848 44.784v-9.021c0-.25-.098-.49-.27-.667a.913.913 0 00-1.306 0 .953.953 0 00-.27.667v11.292c0 .25.097.489.27.665a.912.912 0 00.651.276h11.055c.244 0 .48-.1.652-.276a.953.953 0 000-1.334.913.913 0 00-.652-.276H3.145l15.012-15.332a.943.943 0 00.29-.671.958.958 0 00-.269-.68.919.919 0 00-.666-.276.905.905 0 00-.657.297v.004zM46.99 34.822c-.244 0-.478.1-.65.276a.952.952 0 00-.27.666v9.02L31.057 29.452a.91.91 0 00-1.282.021.951.951 0 00-.02 1.31l15.011 15.331h-8.833c-.244 0-.48.1-.652.277a.953.953 0 000 1.333.913.913 0 00.652.276h11.057c.244 0 .478-.1.651-.276a.951.951 0 00.27-.665V35.767a.96.96 0 00-.269-.668.921.921 0 00-.652-.277zM47.199.003H36.143c-.245 0-.48.1-.653.276a.953.953 0 000 1.334.913.913 0 00.653.276h8.624L29.755 17.22a.94.94 0 00-.29.671.959.959 0 00.269.68.919.919 0 00.666.276.906.906 0 00.658-.297l15.22-15.545v9.234c0 .25.097.49.27.666a.913.913 0 001.305 0 .953.953 0 00.27-.666V.944a.959.959 0 00-.27-.666.921.921 0 00-.654-.275z"
    />
  </svg>
);
