import { styled } from 'styled-components';

import { Image, SourceLabel } from 'common/components';
import { breakpoints } from 'common/styles';
import { Knowledge } from 'entities/knowledge/types';

const StyledImage = styled(Image)`
  height: 200px;
  border-radius: 4px;

  @media ${breakpoints.medium} {
    height: 250px;
  }

  @media ${breakpoints.extraLarge} {
    height: 350px;
  }
`;

const StyledSourceLabel = styled(SourceLabel)`
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.textBright};
`;

type ArticleDetailProps = { knowledge?: Knowledge };

export const ArticleDetail = ({ knowledge }: ArticleDetailProps) => (
  <>
    <StyledImage src={knowledge?.images?.medium} alt={knowledge?.title || 'Article Image'} />
    {knowledge?.source && <StyledSourceLabel source={knowledge?.source} date={knowledge?.time} type="small" />}
  </>
);
