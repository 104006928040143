import { styled } from 'styled-components';

import { ListItemBox } from 'common/components';
import { EditListItem, EditListItemProps } from 'domains/topic/components/EditListItem';
import { PrimaryTopic } from 'entities/keyIssues/types';

type KeyIssueTileProps = Omit<EditListItemProps, 'subtitle'> & { primary_topic?: PrimaryTopic };

const StyledListItemBox = styled(ListItemBox)`
  padding: 1rem;
  margin-bottom: 1rem;
`;

export const KeyIssueTile = ({ images, primary_topic, ...props }: KeyIssueTileProps) => (
  <StyledListItemBox>
    <EditListItem {...props} images={images ?? primary_topic?.images} subtitle={primary_topic?.name} />
  </StyledListItemBox>
);
