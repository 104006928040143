import { addStateReset, combineStateResets, GenericReducer } from 'common/reducer/addStateReset';
import { CreateMapTypes } from 'domains/createMapDetail/actionTypes';
import { TopicDetailType } from 'domains/topicDetail/actionTypes';
import { TransformationMapActionType } from 'domains/transformationMap/actionTypes';
import { addUserChangeReset } from 'entities/user/utils';

export const addTopicSelectionReset = combineStateResets(
  addUserChangeReset,
  addStateReset(TopicDetailType.INIT, TransformationMapActionType.SET_EDIT_MODE),
);

const addMapEditReset = (reducer: GenericReducer) => (state: any, action: any) => {
  if (action.type === CreateMapTypes.UPLOAD_FULFILLED) {
    const { [action.payload.id]: _, ...newState } = state;
    return newState;
  }
  return reducer(state, action);
};

export const addUserOrMapChangeReset = combineStateResets(addUserChangeReset, addMapEditReset);
