import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

import { ChevronIcon } from 'common/components';

const StyledChevronLink = styled(Link)`
  display: flex;
`;

const StyledChevronIcon = styled(ChevronIcon)`
  margin-left: 1.5rem;
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  align-self: center;
  color: ${({ theme }) => theme.colors.textFade};
`;

export const ChevronLink = ({ to }: { to: string }) => (
  <StyledChevronLink to={to}>
    <StyledChevronIcon />
  </StyledChevronLink>
);
