import { styled } from 'styled-components';

export const ListItemBox = styled.div`
  color: ${({ theme }) => theme.colors.textBright};
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};

  &:last-child {
    margin-bottom: 0;
  }
`;
