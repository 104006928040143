import { useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { StyledGrid, SectionTitleUnderlined, SeeAllButton, Tile } from 'common/components';
import { useTranslations } from 'domains/language/useTranslations';
import { getVideosSearchIsPending, getVideosSearchResults } from 'domains/search/selectors';

const StyledContainer = styled.div`
  margin-bottom: 2rem;
`;

export const VideosResults = () => {
  const { textDictionary } = useTranslations();
  const videos = useSelector(getVideosSearchResults(4));
  const isPending = useSelector(getVideosSearchIsPending);

  if (videos.length === 0) {
    return null;
  }

  return (
    <>
      <StyledContainer>
        <SectionTitleUnderlined isLoading={isPending} id="videos" rightElement={<SeeAllButton link="/search/videos" />}>
          {textDictionary['app.discover.topic.tabs.videos.title']}
        </SectionTitleUnderlined>
        <StyledGrid numberOfItems={[1, 2, 3, 4, 5]}>
          {videos.map((video) => (
            <Tile
              key={video.id}
              title={video.title}
              subtitle={video.source?.name}
              link={`/search/videos/${video.id}`}
              image={video.images?.thumbnail}
            />
          ))}
        </StyledGrid>
      </StyledContainer>
    </>
  );
};
