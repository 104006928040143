import { memo } from 'react';
import { styled } from 'styled-components';

import { Image, SourceLabel } from 'common/components';
import { breakWordStyle, lineTruncationStyle } from 'common/styles';
import { CustomKnowledge } from 'domains/customContentAndSources/types';
import { StyledContent } from 'domains/knowledgeFeed/components/shared';
import { ExpandableKnowledgeItemWrapper } from 'domains/publication/components/ExpandableKnowledgeItemWrapper';
import { KnowledgeDetailButton } from 'domains/publication/components/KnowledgeDetailButton';
import { Knowledge } from 'entities/knowledge/types';

const StyledTitle = styled.div`
  font-weight: 500;
  font-size: 1rem;
  margin: 0;
  margin-top: 5px;

  ${breakWordStyle}
  ${lineTruncationStyle(2)}
`;

const StyledRow = styled.div`
  display: flex;
  margin-right: 1rem;
`;

const StyledOuterRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledImage = styled(Image)`
  width: 64px;
  min-width: 64px;
  height: 64px;
  border-radius: 4px;
  margin-right: 1rem;
  object-fit: contain;
`;

const StyledKnowledgeDetailButton = styled(KnowledgeDetailButton)`
  flex: 0;
`;

type ExpandableKnowledgeItemProps = { knowledge: Knowledge | CustomKnowledge; link: string };

export const ExpandableKnowledgeItem = memo(({ knowledge, link }: ExpandableKnowledgeItemProps) => {
  const { title, source, time } = knowledge;

  return (
    <ExpandableKnowledgeItemWrapper knowledge={knowledge} link={link}>
      <StyledOuterRow title={title}>
        <StyledRow>
          {source.images?.thumbnail && <StyledImage src={source.images.thumbnail} alt={source.name} />}
          <StyledContent>
            <StyledTitle>{title}</StyledTitle>
            <SourceLabel source={source} date={time} type="small" hideImage={true} />
          </StyledContent>
        </StyledRow>
        <StyledKnowledgeDetailButton link={link} />
      </StyledOuterRow>
    </ExpandableKnowledgeItemWrapper>
  );
});

ExpandableKnowledgeItem.displayName = 'ExpandableKnowledgeItem';
