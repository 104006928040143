import { filterNoEmpty } from 'common/utilities';
import { getDimensionById, getDimensionByIdIsFetching } from 'entities/keyIssues/selectors';
import { getTopic } from 'entities/topics/selectors';
import { RootState } from 'types';

export const getCustomKeyIssueTopicListForQuery = (query: string) => (state: RootState) => {
  const ids = state.domains.customKeyIssue.topicSearch.idsForQuery[query];
  if (!ids) {
    return;
  }
  return ids.map((id) => getTopic(id)(state));
};

export const getCustomKeyIssueTopicList = (state: RootState) => {
  const query = state.domains.customKeyIssue.topicSearch.currentQuery;
  if (!query || query === '') {
    return [];
  }
  const list = getCustomKeyIssueTopicListForQuery(query)(state) || [];
  return filterNoEmpty(list);
};

export const getCustomKeyIssueTopicListIsPending = (state: RootState) =>
  state.domains.customKeyIssue.topicSearch.isPending;

export const getCustomKeyIssueConceptListForQuery = (query: string) => (state: RootState) => {
  const ids = state.domains.customKeyIssue.tagSearch.idsForQuery[query];
  if (!ids) {
    return;
  }
  return ids.map((id) => state.domains.customKeyIssue.tagSearch.byId[id]);
};

export const getCustomKeyIssueConceptList = (state: RootState) => {
  const query = state.domains.customKeyIssue.tagSearch.currentQuery;
  if (!query || query === '') {
    return [];
  }
  const list = getCustomKeyIssueConceptListForQuery(query)(state) || [];
  return filterNoEmpty(list);
};

export const getCustomKeyIssueConceptIsPending = (state: RootState) => state.domains.customKeyIssue.tagSearch.isPending;

export const getCustomKeyIssueFingerprintIsPending = (state: RootState) =>
  state.domains.customKeyIssue.fingerprint.isPending;

export const getCustomKeyIssueFingerprintHasErrored = (state: RootState) =>
  state.domains.customKeyIssue.fingerprint.error;

export const getCustomKeyIssueFingerprint = (state: RootState) => state.domains.customKeyIssue.fingerprint.data;

export const getCustomKeyIssueFPKnowledgeIsPending = (state: RootState) =>
  state.domains.customKeyIssue.fingerprint.isPendingKnowledge;

export const getCustomKeyIssueFPKnowledgeHasErrored = (state: RootState) =>
  state.domains.customKeyIssue.fingerprint.knowledgeError;

export const getCustomKeyIssueFPKnowledgeIds = (state: RootState) =>
  state.domains.customKeyIssue.fingerprint.knowledgeIds;

export const getCustomKeyIssueFPKnowledge = (state: RootState) =>
  getCustomKeyIssueFPKnowledgeIds(state).map((knowledgeId) => state.entities.knowledge.byId[knowledgeId]);

export const getCustomKeyIssueIsActiveForm = (state: RootState) =>
  state.domains.customKeyIssue.upload.isCreateFormActive;

export const getCustomKeyIssueIsUploading = (state: RootState) => state.domains.customKeyIssue.upload.isPending;

export const getCustomKeyIssueUploadHasErrored = (state: RootState) => state.domains.customKeyIssue.upload.error;

export const getPreEditedCustomKeyIssue = (state: RootState) => {
  const { entityId } = state.domains.customKeyIssue.upload;
  if (!entityId) {
    return undefined;
  }
  const keyIssue = getDimensionById(entityId)(state);
  if (keyIssue) {
    return keyIssue;
  }
  return state.domains.createMap.newCustomKeyIssues.find(({ id }) => id === entityId);
};

export const getCurrentCustomKeyIssueIsPending = (state: RootState) => {
  const { entityId } = state.domains.customKeyIssue.upload;
  return entityId ? getDimensionByIdIsFetching(entityId)(state) : false;
};

export const getCustomKeyIssueTagSuggestions = (state: RootState) => state.domains.customKeyIssue.suggestions.tags;

export const getCustomKeyIssueTopicSuggestions = (state: RootState) => state.domains.customKeyIssue.suggestions.topics;
