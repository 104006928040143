import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { UIActionButton, UIButtonsRow } from 'common/components';
import { trackEvent } from 'domains/analytics/actions';
import { toggleBookmark } from 'domains/bookmarks/actions';
import { CaseStudy } from 'domains/caseStudy/types';
import { CustomKnowledge } from 'domains/customContentAndSources/types';
import { shareLinkAction } from 'domains/share/actions';
import { Knowledge } from 'entities/knowledge/types';
import { getCanDisplayBookmarks } from 'entities/user/selectors';

const StyledButtonRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px 1rem;
  margin-top: 5px;
`;

type KnowledgeButtonsProps = {
  className?: string;
  fullwidth?: boolean;
  knowledge: Knowledge | CustomKnowledge | CaseStudy;
  shareLink: string;
};

export const KnowledgeButtons = ({ knowledge, shareLink, fullwidth, className }: KnowledgeButtonsProps) => {
  const { id, title } = knowledge;

  const dispatch = useDispatch();
  const accessToBookmarks = useSelector(getCanDisplayBookmarks);
  const onBookmarkHandle = () => {
    if (!('is_bookmarked' in knowledge)) {
      return;
    }
    dispatch(
      toggleBookmark({
        bookmark: knowledge as Knowledge,
        isBookmarked: !knowledge.is_bookmarked,
        entityType: 'Knowledge',
      }),
    );
  };

  const trackOpenLink = () => {
    dispatch(
      trackEvent({
        name: 'Open Link',
        entityId: id,
        entityName: title,
        entityType: 'Knowledge',
      }),
    );
  };

  let completeShareLink = `${process.env.REACT_APP_WEBSITE_URL}${shareLink}`;
  if (shareLink && !shareLink.startsWith('/')) {
    completeShareLink = shareLink;
  }

  const onShareClick = () =>
    dispatch(
      shareLinkAction({
        id: id,
        name: title,
        link: completeShareLink,
        type: 'Knowledge',
      }),
    );

  return fullwidth ? (
    <StyledButtonRow className={className}>
      <UIActionButton type="open" href={knowledge.link} onClick={trackOpenLink} />
      <UIActionButton type="share" onClick={onShareClick} />
      {accessToBookmarks && 'is_bookmarked' in knowledge && (
        <UIActionButton
          type="bookmark"
          isActive={knowledge.is_bookmarked}
          isLoading={knowledge.isBookmarkPending}
          onClick={onBookmarkHandle}
        />
      )}
    </StyledButtonRow>
  ) : (
    <UIButtonsRow className={className}>
      {' '}
      <UIActionButton type="open" href={knowledge.link} onClick={trackOpenLink} />
      <UIActionButton type="share" onClick={onShareClick} />
      {accessToBookmarks && 'is_bookmarked' in knowledge && (
        <UIActionButton
          type="bookmark"
          isActive={knowledge.is_bookmarked}
          isLoading={knowledge.isBookmarkPending}
          onClick={onBookmarkHandle}
        />
      )}
    </UIButtonsRow>
  );
};
