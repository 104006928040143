import { Switch, Route } from 'react-router-dom';
import { css, styled } from 'styled-components';

import { ClusterPage } from 'domains/clusters/components/ClusterPage';
import { DigDeeperPage } from 'domains/clusters/components/DigDeeperPage';
import { LatestKnowledgePage } from 'domains/monitor/latestKnowledge/components/LatestKnowledgePage';
import { Monitor } from 'domains/monitor/Monitor';

const StyledContainer = styled.div`
  height: 100%;

  ${({ theme }) => css`
    background-color: ${theme.colors.backgroundPrimary};
    color: ${theme.colors.fillPrimary};
  `};
`;

export const MonitorRoutes = () => (
  <StyledContainer>
    <Switch>
      <Route path="/monitor/emerging-themes/:clusterId/dig-deeper/:id?" component={DigDeeperPage} />
      <Route path="/monitor/emerging-themes/:clusterId?" component={ClusterPage} />
      <Route path="/monitor/latest-knowledge/:id?" component={LatestKnowledgePage} />
      <Route exact={true} path="/monitor" component={Monitor} />
    </Switch>
  </StyledContainer>
);
