import { Initiative } from 'entities/initiatives/types';
import { ErrorMeta } from 'types';

export enum InitiativesType {
  FETCH_FOR_TOPIC = '[Initiatives] - Fetch for topic',
  FETCH_FOR_TOPIC_SUCCESS = '[Initiatives] - Fetch for topic successful',
  FETCH_FOR_TOPIC_FAILURE = '[Initiatives] - Fetch for topic failure',
  FETCH_FOR_ID = '[Initiatives] - Fetch for id',
  FETCH_FOR_ID_SUCCESS = '[Initiatives] - Fetch for id successful',
  FETCH_FOR_ID_FAILURE = '[Initiatives] - Fetch for id failure',
}

type TopicInitiativesPayload = { id: string; limit: number; offset: number; searchTerm?: string; type?: string };
export type FetchInitiativeDefaultAction = { payload: TopicInitiativesPayload; type: InitiativesType.FETCH_FOR_TOPIC };

export type FetchInitiativeFulfilledAction = {
  meta: TopicInitiativesPayload;
  payload: { data: Initiative[]; topicId: string };
  type: InitiativesType.FETCH_FOR_TOPIC_SUCCESS;
};

type FetchInitiativesRejectedMeta = ErrorMeta & { searchCriteria: TopicInitiativesPayload };

export type FetchInitiativeRejectedAction = {
  meta: FetchInitiativesRejectedMeta;
  payload: { topicId: string };
  type: InitiativesType.FETCH_FOR_TOPIC_FAILURE;
};

export type FetchInitiativeForIdAction = { meta: { id: string }; type: InitiativesType.FETCH_FOR_ID };

export type FetchInitiativeForIdFulfilledAction = {
  meta: { id: string };
  payload: Initiative;
  type: InitiativesType.FETCH_FOR_ID_SUCCESS;
};

export type FetchInitiativeForIdRejectedAction = {
  meta: ErrorMeta;
  payload: { id: string };
  type: InitiativesType.FETCH_FOR_ID_FAILURE;
};

export type InitiativeAction =
  | FetchInitiativeDefaultAction
  | FetchInitiativeFulfilledAction
  | FetchInitiativeRejectedAction
  | FetchInitiativeForIdAction
  | FetchInitiativeForIdFulfilledAction
  | FetchInitiativeForIdRejectedAction;
