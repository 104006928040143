import { SVGProps } from 'react';

export const DeleteIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
    <path
      fill="currentColor"
      d="M100.8 37.075h.288l.744 9.73a2.567 2.567 0 0 0 2.549 2.363h9.582a2.567 2.567 0 0 0 2.549-2.363l.744-9.731h.288a1.637 1.637 0 0 0 1.628-1.628v-.93a2.558 2.558 0 0 0-2.558-2.558h-2.093V30.8a1.628 1.628 0 0 0-1.628-1.628h-7.442a1.628 1.628 0 0 0-1.628 1.628v1.163h-2.553a2.093 2.093 0 0 0-2.093 2.093v1.4a1.637 1.637 0 0 0 1.628 1.628zm4.419-6.279a.233.233 0 0 1 .233-.233h7.448a.233.233 0 0 1 .233.233v1.163h-7.907zm9.9 15.907a1.154 1.154 0 0 1-1.154 1.07h-9.582a1.154 1.154 0 0 1-1.154-1.07l-.744-9.628h13.377zm-14.547-12.651a.707.707 0 0 1 .7-.7h15.349a1.163 1.163 0 0 1 1.163 1.163v.93a.232.232 0 0 1-.233.233H100.8a.233.233 0 0 1-.233-.233z"
      transform="translate(-99.177 -29.168)"
    />
  </svg>
);
