import { PropsWithChildren } from 'react';
import { styled } from 'styled-components';

import { ExpandableContent } from 'common/components/ExpandableContent';
import { ErrorIcon } from 'common/components/icons';

const StyledContainer = styled.div<{ isWarning: boolean }>`
  align-items: center;
  background-color: transparent;
  border-radius: 5px;
  border: 2px solid ${({ isWarning, theme }) => (isWarning ? theme.colors.yellow : theme.colors.error)};
  color: ${({ theme }) => theme.colors.textBright};
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin: 0.75rem 0 1.5rem;
  padding: 0.5rem 1rem;
`;

const StyledIcon = styled(ErrorIcon)`
  color: ${({ theme }) => theme.colors.textBright};
  flex-shrink: 0;
  height: 20px;
  width: 20px;
`;

const StyledErrorMessage = styled.p`
  margin: 0;

  strong {
    cursor: pointer;
  }
`;

type ErrorMessageProps = PropsWithChildren & {
  applyClassToWrapper?: boolean;
  className?: string;
  isActive?: boolean;
  isWarning?: boolean;
};

export const ErrorMessage = ({
  applyClassToWrapper = false,
  className,
  children,
  isActive = false,
  isWarning = false,
}: ErrorMessageProps) => (
  <ExpandableContent className={applyClassToWrapper ? className : undefined} isActive={isActive} collapsedHeight="0">
    <StyledContainer isWarning={isWarning} className={applyClassToWrapper ? undefined : className}>
      <StyledIcon />
      <StyledErrorMessage>{children}</StyledErrorMessage>
    </StyledContainer>
  </ExpandableContent>
);
