import { useDispatch, useSelector } from 'react-redux';

import { StyledModalText, UIButton } from 'common/components';
import { pushHistory } from 'common/utilities';
import { useTranslations } from 'domains/language/useTranslations';
import { deactivateModal } from 'domains/modal/actions';
import { ModalHeader } from 'domains/modal/components/ModalHeader';
import { getModalParams } from 'domains/modal/selectors';

export const CancelMapModal = () => {
  const { textDictionary } = useTranslations();
  const dispatch = useDispatch();
  const { onCancelRoute } = useSelector(getModalParams);
  const cancelRoute = onCancelRoute ?? '/create';

  const handleYes = (event: any) => {
    event.preventDefault();
    dispatch(deactivateModal());
    pushHistory(cancelRoute);
  };

  return (
    <>
      <ModalHeader title={textDictionary['app.create.cancel.modal_title']} />
      <StyledModalText>{textDictionary['app.create.cancel.title']}</StyledModalText>
      <UIButton onClick={handleYes} as="a" href={cancelRoute} error={true} fullwidth>
        {textDictionary['app.create.cancel.confirm']}
      </UIButton>
    </>
  );
};
