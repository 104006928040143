import {
  FetchLegacyTopicBriefingFailureAction,
  FetchLegacyTopicBriefingSuccessAction,
  FetchSurveyLinkAction,
  FetchSurveyLinkFailureAction,
  FetchSurveyLinkSuccessAction,
  FetchSurveyLinkSuccessPayload,
  FetchTopicPresentationAction,
  FetchTopicPresentationFulfilledAction,
  FetchTopicPresentationRejectedAction,
  LegacyTopicActionTypes,
} from 'domains/topic/actionTypes';
import { ErrorMeta } from 'types';

export const fetchLegacyTopicBriefingSuccess = (): FetchLegacyTopicBriefingSuccessAction => ({
  type: LegacyTopicActionTypes.FETCH_BRIEFING_SUCCESS,
});

export const fetchLegacyTopicBriefingFailure = (
  payload: { error: any },
  meta: ErrorMeta,
): FetchLegacyTopicBriefingFailureAction => ({
  type: LegacyTopicActionTypes.FETCH_BRIEFING_FAILURE,
  payload,
  meta,
});

export const fetchSurveyLink = (topicId: string): FetchSurveyLinkAction => ({
  type: LegacyTopicActionTypes.FETCH_SURVEY_LINK,
  payload: topicId,
});

export const fetchSurveyLinkSuccess = (payload: FetchSurveyLinkSuccessPayload): FetchSurveyLinkSuccessAction => ({
  type: LegacyTopicActionTypes.FETCH_SURVEY_LINK_SUCCESS,
  payload,
});

export const fetchSurveyLinkFailure = (): FetchSurveyLinkFailureAction => ({
  type: LegacyTopicActionTypes.FETCH_SURVEY_LINK_FAILURE,
});

export const fetchTopicPresentation = (
  payload: FetchTopicPresentationAction['payload'],
): FetchTopicPresentationAction => ({
  type: LegacyTopicActionTypes.FETCH_PRESENTATION,
  payload,
});

export const fetchTopicPresentationFulfilled = (): FetchTopicPresentationFulfilledAction => ({
  type: LegacyTopicActionTypes.FETCH_PRESENTATION_FULFILLED,
});

export const fetchTopicPresentationRejected = (
  payload: FetchTopicPresentationRejectedAction['payload'],
  meta: FetchTopicPresentationRejectedAction['meta'],
): FetchTopicPresentationRejectedAction => ({
  type: LegacyTopicActionTypes.FETCH_PRESENTATION_REJECTED,
  payload,
  meta,
});
