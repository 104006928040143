import { getFollowedTopicsIds } from 'entities/topics/selectors';
import { RootState } from 'types';

export const getSuggestedTopics = (state: RootState) => {
  const followingTopicsIds = getFollowedTopicsIds(state);
  const suggestedTopics = state.domains.suggestedTopics.ids;
  const suggestions = suggestedTopics.filter((id) => !followingTopicsIds.includes(id));
  return (suggestions || []).map((id) => state.domains.suggestedTopics.data[id]);
};

export const getIsSuggestionsPending = (state: RootState) => state.domains.suggestedTopics.isPending;
