import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { AddItemIcon, CrossCircledIcon, InfinityLoader, UIButton } from 'common/components';
import { FILTERED_TOPICS_LIMIT, TOPICS_FOR_COLLECTION_LIMIT } from 'common/constants/limit';
import { createScrollbarStyle } from 'common/styles';
import { cssResets } from 'common/styles/cssResets';
import { Setter } from 'common/types/setter';
import { Status } from 'common/types/status';
import { SearchDropdown } from 'domains/collection/components/edit/parts/SearchDropdown';
import { selectCollections } from 'domains/collection/selectors';
import { clearFilteredTopics, fetchFilteredTopics } from 'domains/filteredTopicList/actions';
import {
  getFilteredTopics,
  getFilteredTopicsAreFetching,
  getFilteredTopicsIsPossibleNextPage,
} from 'domains/filteredTopicList/selectors';
import { useTranslations } from 'domains/language/useTranslations';
import { fetchTopicsForCollection } from 'entities/topics/actions';
import { getTopicsByIds } from 'entities/topics/selectors';
import { Topic } from 'entities/topics/types';

const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  overflow: clip scroll;

  ${createScrollbarStyle()}
`;

const StyledCard = styled.div`
  background-color: ${({ theme }) => theme.colors.coBrandBackground};
  display: flex;
  gap: 1rem;
  height: calc(7.875rem - 19.5px);
  padding: 1rem;
  width: 20.3125rem;
`;

const StyledImage = styled.img`
  border-radius: 0.25rem;
  height: 3.125rem;
  object-fit: cover;
  object-position: center;
  width: 3.125rem;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledName = styled.p`
  ${cssResets.p};
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  color: ${({ theme }) => theme.colors.textBright};
  display: -webkit-box;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  gap: 1.75rem;
`;

const StyledButton = styled(UIButton)`
  background-color: ${({ theme }) => theme.colors.coBrandBackground};
  padding: 0;

  &:focus-visible,
  &:hover {
    background-color: ${({ theme }) => theme.colors.coBrandBackground};
  }
`;

const StyledDeleteIcon = styled(CrossCircledIcon)`
  height: 1rem;
  padding-right: 1rem;
  width: 1rem;
`;

type TopicsTabProps = { setTopicIds: Setter<string[]>; topicIds: string[] };

export const TopicsTab = ({ setTopicIds, topicIds }: TopicsTabProps) => {
  const dispatch = useDispatch();
  const filteredTopics = useSelector(getFilteredTopics);
  const filteredTopicsIsLoading = useSelector(getFilteredTopicsAreFetching);
  const nextPageMayExist = useSelector(getFilteredTopicsIsPossibleNextPage);
  const collectionTopics = useSelector(getTopicsByIds(topicIds));
  const { forViewing } = useSelector(selectCollections); // TODO don't load all collection topics to the forViewing object
  const { textDictionary } = useTranslations();

  const fetchResults = (term: string, offset: number) =>
    dispatch(fetchFilteredTopics({ limit: FILTERED_TOPICS_LIMIT, offset, filterTerm: term }));

  const fetchNextPage = () =>
    typeof forViewing === 'string' ||
    dispatch(
      fetchTopicsForCollection({
        id: forViewing.id,
        limit: TOPICS_FOR_COLLECTION_LIMIT,
        offset: forViewing.topics.offset,
      }),
    );

  const collectionTopicsIsPending =
    forViewing === Status.Loading || (typeof forViewing !== 'string' && forViewing.topics.status !== Status.Ready);

  return (
    <>
      <SearchDropdown
        clearResults={() => dispatch(clearFilteredTopics())}
        fetchResults={fetchResults}
        isLoading={filteredTopicsIsLoading}
        nextPageMayExist={nextPageMayExist}
        placeholder={textDictionary['app.collection.search_for_topics']}
      >
        {filteredTopics.map((item) => ({
          icon: topicIds.some((id) => id === item.id) ? <CrossCircledIcon /> : <AddItemIcon />,
          image: item.images?.thumbnail,
          key: item.id,
          name: item.name,
          onClick: () => setTopicIds(toggleInclusion(item)),
        }))}
      </SearchDropdown>
      <InfinityLoader
        isPending={collectionTopicsIsPending}
        loadNextPage={fetchNextPage}
        hasNextPage={
          forViewing === Status.Loading || (typeof forViewing !== 'string' && forViewing.topics.nextPageMayExist)
        }
      >
        <StyledContainer>
          {collectionTopics.map((item) => (
            <StyledCard key={item.id}>
              <StyledImage src={item.images?.thumbnail} />
              <StyledColumn>
                <StyledName>{item.name}</StyledName>
                <StyledButtonContainer>
                  <StyledButton
                    onClick={() => setTopicIds((value) => value.filter((id) => id !== item.id))}
                    variant="neutral"
                  >
                    <StyledDeleteIcon />
                    <span>{textDictionary['app.create.collection.co_curator.remove']}</span>
                  </StyledButton>
                </StyledButtonContainer>
              </StyledColumn>
            </StyledCard>
          ))}
        </StyledContainer>
      </InfinityLoader>
    </>
  );
};

const toggleInclusion = (item: Topic) => (ids: string[]) =>
  ids.some((id) => id === item.id) ? ids.filter((id) => id !== item.id) : [...ids, item.id];
