import { styled } from 'styled-components';

export const StyledModalTextLabel = styled.strong`
  text-transform: capitalize;
  font-size: 1.1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.blue};

  &:hover {
    color: ${({ theme }) => theme.colors.blueDark};
  }
`;
