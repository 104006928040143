import { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css, styled } from 'styled-components';

import {
  CrossIcon,
  ErrorMessage,
  ImageBackground,
  SectionTitleUnderlined,
  Spinner,
  WebFrame,
  TextArea,
  TextInput,
} from 'common/components';
import { ClearInput } from 'common/components/form/ClearInput';
import { StyledInput } from 'common/components/form/TextArea';
import { updateTopicContentScrapedArticle } from 'domains/customContentAndSources/actions';
import {
  getCustomContentIsUploading,
  getScrapedCustomArticle,
  getScrapedCustomArticleHasErrored,
  getScrapedCustomArticleIsPending,
} from 'domains/customContentAndSources/selectors';
import { NewCustomKnowledge } from 'domains/customContentAndSources/types';
import { useTranslations } from 'domains/language/useTranslations';

const StyledSpinner = styled(Spinner)`
  width: 14px;
  height: 14px;
  color: ${({ theme }) => theme.colors.fillPrimary};
`;

const ArticleFormContainer = styled.div`
  & > * {
    margin-bottom: 1rem;
  }
`;

const StyledSectionTitleUnderlined = styled(SectionTitleUnderlined)`
  margin-top: 2rem;
`;

const StyledClearButtonIcon = styled(CrossIcon)`
  width: 14px;
  height: 14px;
  cursor: pointer;
  transition: all 0.3s;
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.colors.textFade};
  &:hover {
    color: ${({ theme }) => theme.colors.textBright};
  }
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 0px;
`;

const imageStyles = css`
  width: 100%;
  height: 200px;
  border-radius: 5px;
`;

const StyledImage = styled(ImageBackground)`
  ${imageStyles}
`;

const StyledWebFrame = styled(WebFrame)`
  padding-top: 0;
  ${imageStyles};
`;

const StyledTextArea = styled(TextArea)`
  ${StyledInput} {
    resize: vertical;
  }
`;

export const ArticlePreviewForm = () => {
  const dispatch = useDispatch();
  const { textDictionary } = useTranslations();
  const isUploading = useSelector(getCustomContentIsUploading);
  const scrapedArticle = useSelector(getScrapedCustomArticle);
  const hasScrapingFailed = useSelector(getScrapedCustomArticleHasErrored);
  const isScrapingPending = useSelector(getScrapedCustomArticleIsPending);

  if (!scrapedArticle && !isScrapingPending && !hasScrapingFailed) {
    return null;
  }

  const clearArticle = () => {
    dispatch(updateTopicContentScrapedArticle(undefined));
  };

  return (
    <>
      {!scrapedArticle?.id && (
        <StyledSectionTitleUnderlined
          rightElement={isScrapingPending ? <StyledSpinner /> : <StyledClearButtonIcon onClick={clearArticle} />}
        >
          {textDictionary['app.custom_attachment.article.title']}
        </StyledSectionTitleUnderlined>
      )}
      <StyledErrorMessage isActive={hasScrapingFailed} isWarning={true}>
        {textDictionary['app.custom_attachment.article.scrape_error']}
      </StyledErrorMessage>
      {scrapedArticle && (
        <ArticleForm article={scrapedArticle} textDictionary={textDictionary} isUploading={isUploading} />
      )}
    </>
  );
};

type ArticleFormProps = { article: NewCustomKnowledge; isUploading: boolean; textDictionary: Record<string, string> };

const ArticleForm = ({ article, textDictionary, isUploading }: ArticleFormProps) => {
  const dispatch = useDispatch();

  const onChange = (key: 'title' | 'publisher') => (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(updateTopicContentScrapedArticle({ [key]: event.target.value }));
  };

  const clearHandler = (key: 'title' | 'publisher') => () => {
    dispatch(updateTopicContentScrapedArticle({ [key]: '' }));
  };

  const onSummaryChange = (summary: string) => {
    dispatch(updateTopicContentScrapedArticle({ summary }));
  };

  let imageComponent = null;
  if (article.embedded_media) {
    imageComponent = (
      <StyledWebFrame {...article.embedded_media} title={article.embedded_media.title || article.title} />
    );
  } else if (article.image) {
    imageComponent = <StyledImage src={article.image} />;
  }

  return (
    <ArticleFormContainer>
      {imageComponent}
      <TextInput
        name="link"
        label={textDictionary['app.custom_attachment.url.title']}
        value={article.link ?? ''}
        autoComplete="off"
        disabled={true}
      />
      <ClearInput
        name="title"
        label={textDictionary['app.custom_key_issue.title']}
        value={article.title ?? ''}
        onChange={onChange('title')}
        disabled={isUploading}
        onClearHandle={clearHandler('title')}
        isPending={isUploading}
      />
      <ClearInput
        name="publisher"
        label={textDictionary['app.custom_attachment.article.publisher']}
        value={article.publisher ?? ''}
        onChange={onChange('publisher')}
        disabled={isUploading}
        onClearHandle={clearHandler('publisher')}
        isPending={isUploading}
      />
      <StyledTextArea
        name="summary"
        placeholder={textDictionary['app.custom_attachment.article.summary']}
        value={article.summary ?? ''}
        onValueChange={onSummaryChange}
        isPending={isUploading}
      />
    </ArticleFormContainer>
  );
};
