import {
  AudioFileIcon,
  DocFileIcon,
  FileIcon,
  ImageFileIcon,
  PDFFileIcon,
  PresentationFileIcon,
  SpreadsheetFileIcon,
  VideoFileIcon,
} from 'common/components';
import { getFileTypeFromMime } from 'domains/customContentAndSources/components/fileType';

const typeToComponent = {
  image: ImageFileIcon,
  audio: AudioFileIcon,
  video: VideoFileIcon,
  presentation: PresentationFileIcon,
  spreadsheet: SpreadsheetFileIcon,
  pdf: PDFFileIcon,
  document: DocFileIcon,
  generic: FileIcon,
};

type ContentFileIconProps = { className?: string; mimetype?: string };

export const ContentFileIcon = ({ mimetype, className }: ContentFileIconProps) => {
  const simpleFileType = getFileTypeFromMime(mimetype);
  const FileIcon = typeToComponent[simpleFileType];
  return <FileIcon className={className} />;
};
