import { put } from 'redux-saga/effects';

import { fetchUnsplashAPI, fetchUnsplashImages } from 'common/components/unsplash/utils';
import { apiFetch } from 'common/utilities';
import { getImageDownloadFile } from 'common/utilities/imageDownload';
import {
  getAIGeneratedMapDetailsFailure,
  getAIGeneratedMapDetailsSuccess,
  getAIGeneratedMapDetailsNotValidPrompt,
  setMapImage,
} from 'domains/createMapDetail/actions';
import { activateModal } from 'domains/modal/actions';
import { GetAIGeneratedTopicDetailsAction, errorCodes } from 'domains/topicDetail/actionTypes';
import { Topic } from 'entities/topics/types';
import { FetchError } from 'types';

export function* getAIGeneratedMapDetailsSaga({ payload }: GetAIGeneratedTopicDetailsAction) {
  try {
    const topic: Topic = yield apiFetch(`map/generation`, {
      method: 'POST',
      body: {
        user_prompt: payload.prompt,
      },
    });
    const { results } = yield fetchUnsplashImages(topic.name);
    const { url: imgSrc } = yield fetchUnsplashAPI(results[0].links.download_location);
    const { file, dataURL } = yield getImageDownloadFile(`${imgSrc}&w=600`);
    yield put(setMapImage({ file, url: dataURL }));

    yield put(getAIGeneratedMapDetailsSuccess(topic));
  } catch (error) {
    const fetchError = error as FetchError;
    if (Object.values(errorCodes).includes(fetchError.status)) {
      yield put(getAIGeneratedMapDetailsNotValidPrompt(fetchError.status));
    } else {
      yield put(getAIGeneratedMapDetailsFailure());
      yield put(activateModal({ id: 'Error' }));
    }
  }
}
