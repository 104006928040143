import { select, put } from 'redux-saga/effects';

import { pushHistory } from 'common/utilities';
import { initiateCreateModeFulfilled, resetCreateMap } from 'domains/createMapDetail/actions';
import { TransformationMapSetEditModeAction } from 'domains/transformationMap/actionTypes';
import { getEditTopic } from 'domains/transformationMap/selectors';
import { Topic } from 'entities/topics/types';

export function* setEditModeSaga({ meta }: TransformationMapSetEditModeAction) {
  if (meta.mode) {
    const topic: Topic = yield select(getEditTopic);
    if (topic) {
      yield put(initiateCreateModeFulfilled(topic));
      if (!topic.can_edit) {
        pushHistory('/create');
      } else {
        pushHistory('?editing=true');
      }
    }
  } else {
    yield put(resetCreateMap());
    // Save map before setting
  }
}
