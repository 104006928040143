import { useEffect, useState, useRef, FormEvent, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { Link, UIButton } from 'common/components';
import { useTranslations } from 'domains/language/useTranslations';
import { ModalHeader } from 'domains/modal/components/ModalHeader';
import { TextInput } from 'domains/user/components/TextInput';
import { UserProfileAccountModalErrors } from 'domains/user/components/UserProfileAccountModalErrors';
import { UserProfileInfo } from 'domains/user/components/UserProfileInfo';
import { clearProfileFailure, updateProfile } from 'entities/user/actions';
import { getUserData, getIsUpdatingProfile, getProfileUpdateError } from 'entities/user/selectors';

export const FIRST_NAME_FIELD_NAME = 'firstName';
export const LAST_NAME_FIELD_NAME = 'lastName';
const ORGANIZATION_FIELD_NAME = 'organization';
export type FieldsType = typeof FIRST_NAME_FIELD_NAME | typeof LAST_NAME_FIELD_NAME | typeof ORGANIZATION_FIELD_NAME;

const StyledEmailChangeDisclaimer = styled.p`
  margin: 1.5rem 0;
  font-weight: bold;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.textFade};
`;

const StyledInputContainer = styled.div`
  margin-bottom: 1rem;
`;

const StyledEmailInputContainer = styled.div`
  margin-bottom: 2rem;
`;

export const UserProfileModal = () => {
  const dispatch = useDispatch();
  const { textDictionary, interpolate } = useTranslations();
  const storedUserData = useSelector(getUserData);
  const isUpdatingProfile = useSelector(getIsUpdatingProfile);
  const profileUpdateError = useSelector(getProfileUpdateError);
  const [isFieldsMissing, setIsFieldsMissing] = useState(false);
  const [userData, setUserData] = useState({
    firstName: storedUserData.firstName || '',
    lastName: storedUserData.lastName || '',
    organization: storedUserData.organization || '',
  });
  const firstNameFieldRef = useRef<HTMLInputElement>(null);
  const lastNameFieldRef = useRef<HTMLInputElement>(null);

  const handleFormSubmit = (event: FormEvent) => {
    const missingFields = !userData.firstName || !userData.lastName;
    if (missingFields !== isFieldsMissing) {
      setIsFieldsMissing(missingFields);
    }
    if (!missingFields) {
      dispatch(updateProfile(userData));
    }

    event.preventDefault();
  };

  const onLabelClick = (id: FieldsType) => {
    switch (id) {
      case FIRST_NAME_FIELD_NAME: {
        firstNameFieldRef.current?.focus();
        break;
      }
      case LAST_NAME_FIELD_NAME: {
        lastNameFieldRef.current?.focus();
        break;
      }
      default:
        break;
    }
  };

  const updateValue = (event: ChangeEvent<HTMLInputElement>) =>
    setUserData({ ...userData, [event.target.name]: event.target.value });

  useEffect(() => {
    if (isFieldsMissing) {
      setIsFieldsMissing(false);
    }
    if (profileUpdateError) {
      dispatch(clearProfileFailure());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    setUserData({
      firstName: storedUserData.firstName || '',
      lastName: storedUserData.lastName || '',
      organization: storedUserData.organization || '',
    });
  }, [storedUserData]);

  return (
    <>
      <ModalHeader title={textDictionary['app.registration.my_profile_modal_title']} />
      <UserProfileInfo />
      <form onSubmit={handleFormSubmit}>
        <StyledEmailInputContainer>
          <TextInput
            name="email"
            label={textDictionary['app.registration.email']}
            disabled={true}
            value={storedUserData.email}
          />
        </StyledEmailInputContainer>
        <StyledInputContainer>
          <TextInput
            name={FIRST_NAME_FIELD_NAME}
            label={textDictionary['app.registration.first_name']}
            autoComplete="given-name"
            required={true}
            ref={firstNameFieldRef}
            defaultValue={userData.firstName}
            onChange={updateValue}
          />
        </StyledInputContainer>
        <StyledInputContainer>
          <TextInput
            name={LAST_NAME_FIELD_NAME}
            label={textDictionary['app.registration.last_name']}
            autoComplete="family-name"
            required={true}
            ref={lastNameFieldRef}
            defaultValue={userData.lastName}
            onChange={updateValue}
          />
        </StyledInputContainer>
        <StyledInputContainer>
          <TextInput
            name={ORGANIZATION_FIELD_NAME}
            label={textDictionary['app.registration.organization']}
            autoComplete="organization"
            defaultValue={userData.organization}
            onChange={updateValue}
          />
        </StyledInputContainer>
        <UIButton type="submit" variant="filled" fullwidth disabled={isUpdatingProfile} isLoading={isUpdatingProfile}>
          {textDictionary['app.profile.save_button']}
        </UIButton>
        <UserProfileAccountModalErrors onLabelClick={onLabelClick} isFieldsMissing={isFieldsMissing} />
        <StyledEmailChangeDisclaimer>
          {interpolate(textDictionary['app.profile.change_email_disclaimer'], {
            supportEmail: (supportEmail: string) => <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>,
          })}
        </StyledEmailChangeDisclaimer>
      </form>
    </>
  );
};
