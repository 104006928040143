import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { fetchBookmarksFulfilled, fetchBookmarksRejected } from 'domains/bookmarks/actions';
import { BookmarksFetchAction, BookmarkPayload } from 'domains/bookmarks/actionTypes';

export const BOOKMARKS_LIMIT = 20;

export function* fetchBookmarksSaga({ meta }: BookmarksFetchAction) {
  try {
    const { offset } = meta;
    const payload: BookmarkPayload = yield apiFetch('bookmark', {
      queryParams: {
        limit: BOOKMARKS_LIMIT,
        offset: offset * BOOKMARKS_LIMIT,
      },
    });
    yield put(fetchBookmarksFulfilled(meta, payload));
  } catch (error) {
    yield put(fetchBookmarksRejected(meta));
  }
}
