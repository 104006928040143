import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslations } from 'domains/language/useTranslations';
import { getModalParams } from 'domains/modal/selectors';
import { TopicFilterModal } from 'domains/topicSearchFilter/components/TopicFilterModal';
import { getTopicFilters } from 'domains/topicSearchFilter/selectors';
import { applyVideoSearchCriteria, clearVideoSearchCriteria } from 'domains/videos/actions';
import { TopicVideoApplySearchCriteriaPayload } from 'domains/videos/actionTypes';
import { getVideoSearchCriteria } from 'domains/videos/selectors';

export const TopicVideoFilterModal = () => {
  const dispatch = useDispatch();
  const searchCriteria = useSelector(getVideoSearchCriteria);
  const { textDictionary } = useTranslations();
  const { selectedTopicId } = useSelector(getModalParams);
  const filters = useSelector(getTopicFilters('videos'));

  useEffect(() => {
    dispatch(applyVideoSearchCriteria({ topicId: selectedTopicId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleApplyFilter = (payload: TopicVideoApplySearchCriteriaPayload) => () =>
    dispatch(applyVideoSearchCriteria(payload));

  const handleClear = () => dispatch(clearVideoSearchCriteria());

  return (
    <TopicFilterModal
      title={textDictionary['app.videos.filter.title']}
      fields={filters}
      searchCriteria={searchCriteria as any}
      handleApplyFilter={handleApplyFilter}
      handleClear={handleClear}
    />
  );
};
