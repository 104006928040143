import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { unfollowCollectionSuccess, unfollowCollectionFailure } from 'domains/collection/actions';
import { UnfollowCollectionAction } from 'domains/collection/actionTypes';

export function* unfollowCollectionSaga({ payload }: UnfollowCollectionAction) {
  try {
    yield apiFetch(`collection/${payload.id}/follow`, { method: 'DELETE' });
    yield put(unfollowCollectionSuccess(payload.id));
  } catch (error) {
    yield put(unfollowCollectionFailure(payload.id));
    console.error(`[unfollowCollectionSaga] Failed to unfollow collection with id '${payload.id}': ${error}`);
  }
}
