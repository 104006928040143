import { BookmarksAction, BookmarkActionTypes, BookmarksFetchFulfilledAction } from 'domains/bookmarks/actionTypes';
import { ClusterActionType, ClusterDigDeeperAction, ClusterItemAction } from 'domains/clusters/actionTypes';
import { DIG_DEEPER_LIMIT } from 'domains/clusters/sagas';
import { ClusterState, Cluster } from 'domains/clusters/types';
import { EmergingThemesAction, EmergingThemesActionType } from 'domains/monitor/emergingThemes/actionTypes';
import { addUserChangeReset } from 'entities/user/utils';

const initialState: ClusterState = {
  data: {},
};

type Action = EmergingThemesAction | ClusterDigDeeperAction | BookmarksAction | ClusterItemAction;

const getClusters = (payload: BookmarksFetchFulfilledAction['payload']) =>
  payload.bookmarks.filter((item) => item.type === 'cluster') as Cluster[];

const reducer = (state: ClusterState = initialState, action: Action): ClusterState => {
  switch (action.type) {
    case ClusterActionType.FETCH:
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.id]: {
            ...state.data[action.meta.id],
            isPending: true,
          },
        },
      };
    case ClusterActionType.FETCH_FULFILLED:
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.id]: {
            ...state.data[action.meta.id],
            ...action.payload,
            isPending: false,
          },
        },
      };
    case ClusterActionType.FETCH_REJECTED: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.id]: {
            ...state.data[action.meta.id],
            isPending: false,
          },
        },
      };
    }
    case EmergingThemesActionType.FETCH_FULFILLED: {
      const data = { ...state.data };
      action.payload.forEach((item) => {
        data[item.id] = { ...state.data[item.id], ...item };
      });
      return {
        ...state,
        data,
      };
    }
    case BookmarkActionTypes.BOOKMARK_TOGGLE: {
      if (!state.data[action.meta.bookmark.id]) {
        return state;
      }
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.bookmark.id]: {
            ...state.data[action.meta.bookmark.id],
            isBookmarkPending: true,
          },
        },
      };
    }
    case BookmarkActionTypes.BOOKMARK_TOGGLE_FULFILLED: {
      if (!state.data[action.meta.bookmark.id]) {
        return state;
      }
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.bookmark.id]: {
            ...state.data[action.meta.bookmark.id],
            isBookmarkPending: false,
            is_bookmarked: action.meta.isBookmarked,
          },
        },
      };
    }
    case BookmarkActionTypes.BOOKMARK_TOGGLE_REJECTED: {
      if (!state.data[action.meta.bookmark.id]) {
        return state;
      }
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.bookmark.id]: {
            ...state.data[action.meta.bookmark.id],
            isBookmarkPending: false,
          },
        },
      };
    }
    case ClusterActionType.FETCH_DIG_DEEPER: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.id]: {
            ...state.data[action.meta.id],
            digDeeperKnowledge: {
              offset: action.meta.offset,
              isPending: true,
            },
          },
        },
      };
    }
    case ClusterActionType.FETCH_DIG_DEEPER_FULFILLED: {
      const previousData = state.data[action.meta.id];
      const previousIds = (previousData.digDeeperKnowledge && previousData.digDeeperKnowledge.ids) || [];
      const newIds = action.payload.map((item) => item.id) || [];
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.id]: {
            ...previousData,
            digDeeperKnowledge: {
              ...previousData.digDeeperKnowledge,
              ids: action.meta.offset === 0 ? newIds : [...previousIds, ...newIds],
              isPending: false,
              hasNextPage: newIds.length === DIG_DEEPER_LIMIT,
            },
          },
        },
      };
    }
    case ClusterActionType.FETCH_DIG_DEEPER_REJECTED:
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.id]: {
            ...state.data[action.meta.id],
            digDeeperKnowledge: {
              isPending: false,
            },
          },
        },
      };
    case BookmarkActionTypes.BOOKMARKS_FETCH_FULFILLED: {
      const data = { ...state.data };
      getClusters(action.payload).forEach((item) => {
        data[item.id] = { ...state.data[item.id], ...item };
      });
      return {
        ...state,
        data,
      };
    }
    default:
      return state;
  }
};

export const clustersReducer = addUserChangeReset(reducer, initialState);
