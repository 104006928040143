import { ComponentPropsWithRef } from 'react';

type ResponsiveImageProps = Omit<ComponentPropsWithRef<'img'>, 'srcSet'> & { alt: string; srcSet: string[] };

export const ResponsiveImage = ({ srcSet, alt, ...props }: ResponsiveImageProps) => (
  <img
    {...props}
    src={props.src || srcSet[1]}
    srcSet={srcSet.map((image, index) => `${image} ${index}x`).join(',')}
    alt={alt}
  />
);
