import { useSelector } from 'react-redux';
import { styled } from 'styled-components';

import {
  ImageBackground,
  UIChip,
  UIButtonsRow,
  UIActionButton,
  MarkdownArea,
  MasterDetailContentWrapper,
} from 'common/components';
import { useFormatDate } from 'common/utilities';
import { useTranslations } from 'domains/language/useTranslations';
import { MembershipEvent } from 'domains/membershipEvents/types';
import { getHasAccessToMembershipEvents } from 'entities/user/selectors';

const StyledImage = styled(ImageBackground)`
  height: 250px;
  border-radius: 5px;
  margin-bottom: 1rem;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
`;

const StyledImageOverlay = styled.div`
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
`;

const StyledTitle = styled.h1`
  font-size: 2rem;
  margin: 1rem 0;
`;

const StyledSubtitle = styled.p`
  margin: 1rem 0 0 0;
  font-size: 1.2rem;
`;

const StyledButtons = styled(UIButtonsRow)`
  margin: 1rem 0;
`;

const StyledTag = styled(UIChip)`
  background-color: ${({ theme }) => theme.colors.green};
  color: white;
`;

type MembershipEventDetailProps = { event: MembershipEvent };

export const MembershipEventDetail = ({ event }: MembershipEventDetailProps) => {
  const hasAccessToEvents = useSelector(getHasAccessToMembershipEvents);
  const { textDictionary } = useTranslations();
  const { getDate } = useFormatDate();

  const onClick = () =>
    (window.location.href = `${process.env.REACT_APP_TOPLINK_BASE_URL}/events/${event.id}/view` || '');

  return (
    <MasterDetailContentWrapper>
      <StyledImage src={event.images?.thumbnail}>
        <StyledImageOverlay />
      </StyledImage>
      {event.is_registered && <StyledTag disabled>{textDictionary['app.event.registered']}</StyledTag>}
      {event.start_date && <StyledSubtitle>{getDate({ date: event.start_date })}</StyledSubtitle>}
      <StyledTitle>{event.name}</StyledTitle>
      {event.description && <MarkdownArea>{event.description}</MarkdownArea>}
      {event.venue && <StyledSubtitle>{event.venue}</StyledSubtitle>}
      {hasAccessToEvents && (
        <StyledButtons>
          <UIActionButton
            type={'open'}
            onClick={onClick}
            label={textDictionary['app.discover.topic.tabs.events.expand_article']}
          />
        </StyledButtons>
      )}
    </MasterDetailContentWrapper>
  );
};
