import { EntityType } from 'domains/analytics/types';
import { pushModalScreen } from 'domains/modal/actions';

type LinkActionProps = { id: string; image?: string; link: string; name: string; type: EntityType };

export const shareLinkAction = ({ id, image, link, name, type }: LinkActionProps) =>
  pushModalScreen({
    id: 'Share',
    params: { id, image, link, name, popOnClose: true, type },
  });
