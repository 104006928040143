import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { styled } from 'styled-components';

import { ErrorMessage, HiddenImageUpload, UIButton } from 'common/components';
import { UnsplashGallery } from 'common/components/unsplash/UnsplashGallery';
import { fetchUnsplashAPI } from 'common/components/unsplash/utils';
import { dataUrlToBlob } from 'common/utilities/dataUrlToBlob';
import { setMapImage } from 'domains/createMapDetail/actions';
import { LocalImage } from 'domains/createMapDetail/types';
import { useTranslations } from 'domains/language/useTranslations';
import { deactivateModal } from 'domains/modal/actions';
import { ModalHeader } from 'domains/modal/components/ModalHeader';

const StyledTitle = styled.p`
  color: ${({ theme }) => theme.colors.textBright};
`;

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.textBright};
`;

const UNSPLASH_URL = 'https://unsplash.com/?utm_source=strategic_intelligence&utm_medium=referral';

export const MapImagePickerModal = () => {
  const { textDictionary, interpolate } = useTranslations();
  const inputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const onError = () => setError(true);

  const onLocalImageUploadClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const dispatchSetMapImage = (image: LocalImage) => {
    dispatch(setMapImage(image));
    dispatch(deactivateModal());
  };
  // Request the image download link from Unsplash
  // https://help.unsplash.com/api-guidelines/more-on-each-guideline/guideline-triggering-a-download
  const handleImageClick = async (image: string) =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise<void>(async (resolve) => {
      setError(false);
      try {
        const json = await fetchUnsplashAPI(image);
        const imgSrc = json.url;

        const img = new Image();
        img.setAttribute('crossOrigin', 'anonymous');
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          if (!ctx) {
            return;
          }
          ctx.drawImage(img, 0, 0);
          const dataURL = canvas.toDataURL('image/png');
          dispatchSetMapImage({ file: dataUrlToBlob(dataURL), url: dataURL });
          resolve();
        };
        img.onerror = (error) => {
          console.error(error);
          resolve();
        };
        // Restrict the width of the Unsplash image to 600px
        // https://unsplash.com/documentation#supported-parameters
        img.src = `${imgSrc}&w=600`;
      } catch (error) {
        console.error(error);
        setError(true);
        resolve();
      }
    });
  const Unsplash = (value: string) => (
    <StyledLink href={UNSPLASH_URL} target="_blank">
      {value}
    </StyledLink>
  );

  return (
    <>
      <ModalHeader title={textDictionary['app.create.change_image']} />
      <StyledTitle>{textDictionary['app.create.create_map.add_image']}</StyledTitle>
      <HiddenImageUpload accept="image/*" onSetImage={dispatchSetMapImage} ref={inputRef} />
      <UIButton onClick={onLocalImageUploadClick} variant={'filled'} fullwidth>
        {textDictionary['app.profile.upload_image.title']}
      </UIButton>
      <StyledTitle>
        {interpolate(textDictionary['app.create.create_map.choose_image'], {
          Unsplash,
        })}
      </StyledTitle>
      {error && <ErrorMessage isActive={true}>{textDictionary['app.profile.upload_image.error']}</ErrorMessage>}
      <UnsplashGallery onImageClick={handleImageClick} onError={onError} />
    </>
  );
};
