import { call, put } from 'redux-saga/effects';

import { pushHistory } from 'common/utilities';
import { LogoutAction } from 'domains/authentication/actionTypes';
import { deactivateModal } from 'domains/modal/actions';
import { TokenManagerSingleton } from 'TokenManager';

const { localStorage } = window;

export function* unauthenticatedSaga(action: LogoutAction) {
  localStorage.removeItem('permissions');

  const logout = TokenManagerSingleton.signOut.bind(TokenManagerSingleton);
  yield call(logout);
  pushHistory('/');

  // If the user chose to activate logout, then dismiss the inevitable "log
  // back in" modal that'll appear from any 401 responses we get.
  if (action.payload?.isUserLogout) {
    yield put(deactivateModal());
  }
}
