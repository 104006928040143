import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { fetchEventByIdFailure, fetchEventByIdSuccessful } from 'entities/events/actions';
import { FetchEventForIdAction } from 'entities/events/actionTypes';
import { EventSession } from 'entities/events/types';

export function* fetchEventsByIdSaga(action: FetchEventForIdAction) {
  try {
    const payload: EventSession = yield apiFetch(`session/${action.meta.id}`);
    yield put(fetchEventByIdSuccessful(payload, action.meta));
  } catch {
    yield put(fetchEventByIdFailure(action.meta, { logoutOnAuthError: true }));
  }
}
