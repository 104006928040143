import { hasOwnProperty } from 'common/utilities/prototype';
import { EditMapAction, CreateMapTypes, CustomMapAction } from 'domains/createMapDetail/actionTypes';
import {
  CreateCustomKeyIssueFulfilledAction,
  CustomKeyIssueTypes,
  LoadCustomKeyIssueDefaultAction,
  LoadCustomKeyIssueFulfilledAction,
  LoadCustomKeyIssueRejectedAction,
} from 'domains/customKeyIssue/actionTypes';
import { TopicDetailAction, TopicDetailType } from 'domains/topicDetail/actionTypes';
import { KeyIssuesAction, KeyIssuesActionType } from 'entities/keyIssues/actionTypes';
import { KeyIssueState } from 'entities/keyIssues/types';

export const initialState: KeyIssueState = {
  isPending: false,
  hasNextPage: true,
  byId: {},
  idsForSearch: {},
};

type Action =
  | KeyIssuesAction
  | CustomMapAction
  | EditMapAction
  | CreateCustomKeyIssueFulfilledAction
  | LoadCustomKeyIssueDefaultAction
  | LoadCustomKeyIssueFulfilledAction
  | TopicDetailAction
  | LoadCustomKeyIssueRejectedAction;

export const keyIssuesReducer = (state = initialState, action: Action): KeyIssueState => {
  switch (action.type) {
    case KeyIssuesActionType.FETCH:
      return {
        ...state,
        isPending: true,
        searchTerm: action.meta.searchTerm,
      };
    case KeyIssuesActionType.FETCH_FULFILLED: {
      const data = { ...state.byId };
      action.payload.forEach((dimension) => {
        data[dimension.id] = { ...data[dimension.id], ...dimension };
      });
      const newIds = action.payload.map(({ id }) => id);

      let { searchTerm } = action.meta;
      if (!searchTerm) {
        // If there is no search term (i.e. we're not searching, we're just
        // loading the default list of key issues), we use "" as a key to
        // store the ids
        searchTerm = '';
      }

      const oldIds = hasOwnProperty(state.idsForSearch, searchTerm) ? state.idsForSearch[searchTerm] || [] : [];

      const allIds = action.meta.offset === 0 ? newIds : [...oldIds, ...newIds];
      return {
        ...state,
        isPending: false,
        byId: data,
        idsForSearch: {
          ...state.idsForSearch,
          [searchTerm || '']: allIds,
        },
        hasNextPage: newIds.length === action.meta.limit,
      };
    }
    case KeyIssuesActionType.FETCH_REJECTED:
      return {
        ...state,
        isPending: false,
      };
    case KeyIssuesActionType.UPDATE_SEARCH_TERM: {
      return {
        ...state,
        searchTerm: action.payload,
      };
    }
    case TopicDetailType.GET_AI_GENERATED_DETAILS_SUCCESS: {
      const byId = { ...state.byId };
      (action.payload.dimensions || []).forEach((issue) => {
        const previousIssue = byId[issue.id] || {};
        byId[issue.id] = {
          ...previousIssue,
          ...issue,
        };
      });
      return {
        ...state,
        byId,
      };
    }
    case CreateMapTypes.INIT_FULFILLED: {
      const byId = { ...state.byId };
      (action.payload.dimensions || []).forEach((issue) => {
        const previousIssue = byId[issue.id] || {};
        // different endpoints return the same data under different names
        const primary_topic = issue?.parent_topic || previousIssue.primary_topic;

        byId[issue.id] = {
          ...previousIssue,
          ...issue,
          images: previousIssue.images || primary_topic?.images || undefined,
          primary_topic: primary_topic as any,
        };
      });
      return {
        ...state,
        byId,
      };
    }
    case CreateMapTypes.ADD_KEY_ISSUE: {
      if (!state.byId[action.meta.keyIssueId]) {
        return state;
      }

      return {
        ...state,
        byId: {
          ...state.byId,
          [action.meta.keyIssueId]: {
            ...state.byId[action.meta.keyIssueId],
            isPending: true,
          },
        },
      };
    }
    case CreateMapTypes.ADD_KEY_ISSUE_FULFILLED: {
      const previousIssue = state.byId[action.meta.keyIssueId];
      const primary_topic = action.payload.parent_topic || previousIssue.primary_topic;
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.meta.keyIssueId]: {
            ...state.byId[action.meta.keyIssueId],
            ...action.payload,
            primary_topic,
            isPending: false,
          },
        },
      };
    }
    case CreateMapTypes.ADD_KEY_ISSUE_REJECTED: {
      if (!state.byId[action.meta.keyIssueId]) {
        return state;
      }
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.meta.keyIssueId]: {
            ...state.byId[action.meta.keyIssueId],
            isPending: false,
          },
        },
      };
    }

    case CustomKeyIssueTypes.LOAD:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.meta.id]: {
            ...state.byId[action.meta.id],
            isFetching: true,
          },
        },
      };

    case CustomKeyIssueTypes.CREATE_FULFILLED:
    case CustomKeyIssueTypes.LOAD_FULFILLED:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.meta.id]: {
            ...state.byId[action.meta.id],
            isFetching: false,
            isCustomDimensionLoaded: true,
            ...action.payload,
          },
        },
      };

    case CustomKeyIssueTypes.LOAD_REJECTED:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.meta.id]: {
            ...state.byId[action.meta.id],
            isFetching: false,
          },
        },
      };

    default:
      return state;
  }
};
