import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

import { SectionTitleUnderlined } from 'common/components';
import { breakpoints } from 'common/styles';
import { getSiteLanguage } from 'domains/language/selectors';
import { useTranslations } from 'domains/language/useTranslations';
import { fetchEmergingThemes } from 'domains/monitor/emergingThemes/actions';
import { EmergingThemeCarousel } from 'domains/monitor/emergingThemes/components/EmergingThemeCarousel';
import { EmergingThemesList } from 'domains/monitor/emergingThemes/components/EmergingThemesList';
import { getEmergingThemesIsPending, getEmergingThemesItems } from 'domains/monitor/emergingThemes/selectors';
import { getSelectedTopicFiltersDependency, getTopicsFilterSelectedIds } from 'domains/monitor/filter/selectors';
import { useRouterLink } from 'domains/routing/utils';
import { getFollowingTopicsDependency } from 'entities/topics/selectors';
import { getUserId } from 'entities/user/selectors';

const StyledContainer = styled.div`
  margin: 0;
  box-sizing: border-box;

  @media ${breakpoints.medium} {
    display: flex;
    flex-direction: column;
    flex: 5;
    margin-left: 2rem;
  }

  @media ${breakpoints.extraLarge} {
    margin-left: 3rem;
  }
`;

const StyledTitleContainer = styled.div`
  margin: 10px 1rem 0 1rem;

  @media ${breakpoints.medium} {
    margin: 0;
  }
`;

const StyledSeeAllButton = styled(Link)`
  display: block;
  color: ${({ theme }) => theme.colors.textFade};
  margin: 0;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.9rem;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.colors.textBright};
  }

  @media ${breakpoints.medium} {
    display: none;
  }
`;

export const EmergingThemes = () => {
  const dispatch = useDispatch();
  const { textDictionary } = useTranslations();
  const selectedTopics = useSelector(getTopicsFilterSelectedIds);
  const isPending = useSelector(getEmergingThemesIsPending);
  const items = useSelector(getEmergingThemesItems);
  const language = useSelector(getSiteLanguage);
  const link = useRouterLink({ to: 'emerging-themes' });
  const followingTopicsDependency = useSelector(getFollowingTopicsDependency);
  const selectedTopicsDependency = useSelector(getSelectedTopicFiltersDependency);
  const userId = useSelector(getUserId);

  useEffect(() => {
    dispatch(
      fetchEmergingThemes({
        offset: 0,
        filters: { topic: selectedTopics },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTopicsDependency, language, userId, followingTopicsDependency]);

  const seeAllButton =
    items.length > 0 ? (
      <StyledSeeAllButton to={link}>{textDictionary['app.discover.topic.tabs.see_more']}</StyledSeeAllButton>
    ) : undefined;

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <SectionTitleUnderlined isLoading={isPending} rightElement={seeAllButton}>
          {textDictionary['app.monitor.emerging_themes']}
        </SectionTitleUnderlined>
      </StyledTitleContainer>
      <EmergingThemeCarousel />
      <EmergingThemesList />
    </StyledContainer>
  );
};
