import {
  FetchKeyIssuesDefaultAction,
  FetchKeyIssuesFulfilledAction,
  FetchKeyIssuesRejectedAction,
  UpdateSearchTermAction,
  KeyIssuesActionType,
} from 'entities/keyIssues/actionTypes';

export const fetchKeyIssues = (meta: FetchKeyIssuesDefaultAction['meta']): FetchKeyIssuesDefaultAction => ({
  type: KeyIssuesActionType.FETCH,
  meta,
});

export const fetchKeyIssuesSuccess = (
  payload: FetchKeyIssuesFulfilledAction['payload'],
  meta: FetchKeyIssuesFulfilledAction['meta'],
): FetchKeyIssuesFulfilledAction => ({
  type: KeyIssuesActionType.FETCH_FULFILLED,
  payload,
  meta,
});

export const fetchKeyIssuesFailure = (
  payload: FetchKeyIssuesRejectedAction['payload'],
): FetchKeyIssuesRejectedAction => ({
  type: KeyIssuesActionType.FETCH_REJECTED,
  payload,
});

export const updateSearchTerm = (
  payload: UpdateSearchTermAction['payload'],
  meta?: UpdateSearchTermAction['meta'],
): UpdateSearchTermAction => ({
  type: KeyIssuesActionType.UPDATE_SEARCH_TERM,
  payload,
  meta,
});
