import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from 'styled-components';

import { useQueryParams } from 'common/utilities';
import { Footer } from 'domains/about/components/Footer';

const StyledMobileAppPage = styled(Footer)`
  height: 100%;
  justify-content: flex-start;
`;

export const AppRedirectRoute = () => {
  const { search } = useLocation();
  const params = useQueryParams();

  useEffect(() => {
    if (params.redirect_uri) {
      window.location.href = `${params.redirect_uri}${search}`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <StyledMobileAppPage />;
};
