import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { useTranslations } from 'domains/language/useTranslations';
import { activateModal } from 'domains/modal/actions';
import { showWhatsNewIconBadge } from 'domains/whatsNew/selectors';

const RedDot = styled.div`
  position: absolute;
  left: 9px;
  top: 16px;
  width: 8px;
  height: 8px;
  opacity: 1;
  background-color: #fb5252;
  border-radius: 50%;
`;

export const WhatsNewButton = () => {
  const dispatch = useDispatch();
  const { textDictionary } = useTranslations();
  const showBadge = useSelector(showWhatsNewIconBadge);

  const onClick = () => dispatch(activateModal({ id: 'Whats New' }));

  return (
    <div onClick={onClick}>
      {showBadge && <RedDot />}
      {textDictionary['app.navigation.whats_new']}
    </div>
  );
};
