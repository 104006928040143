import { filterNoEmpty } from 'common/utilities';
import { getTopicById, getTopic } from 'entities/topics/selectors';
import { Topic } from 'entities/topics/types';
import { RootState } from 'types';

const getMapTopic = (topic: Topic) => {
  if (!topic?.id) {
    return;
  }
  return {
    id: topic.id,
    name: topic.name,
    image_url: topic.images?.thumbnail,
    fullsize_map_image_url: topic.fullsize_map_image_url,
    is_custom: topic.is_custom,
    provider: topic.provider,
    dimensions: (topic.dimensions || []).map((dimension) => ({
      id: dimension.id,
      name: dimension.name,
      image_url: dimension.images?.thumbnail,
      topics: dimension.topics || [],
      is_custom_dimension: dimension.is_custom_dimension,
    })),
  };
};

export const getTransformationMapId = (state: RootState) => state.domains.transformationMap.id;

export const getTransformationMapSelectedId = (state: RootState) =>
  state.domains.transformationMap.selectedInnerNodeId || state.domains.transformationMap.id;

export const getTransformationMapSelectedOuterNode = (state: RootState) => {
  const id = state.domains.transformationMap.selectedOuterNodeId;
  return id ? getTopic(id)(state) : undefined;
};

export const getTransformationMapSelectedTopic = (state: RootState) =>
  getTopic(getTransformationMapSelectedId(state))(state);

export const getTransformationMapTopic = (state: RootState) => {
  const { id } = state.domains.transformationMap;
  if (!id) {
    return;
  }
  const topic = getTopicById(state, id);
  return getMapTopic(topic);
};

export const getEditTopic = (state: RootState) => {
  const { id } = state.domains.transformationMap;
  if (!id) {
    return;
  }
  const topic = getTopicById(state, id);
  if (!topic?.id) {
    return;
  }
  return topic;
};

export const getTransformationMapEditMode = ({ domains }: RootState) => domains.transformationMap.isEditingMode;

export const getTransformationMapSelectedInnerNode = (state: RootState) => {
  const id = state.domains.transformationMap.selectedInnerNodeId;
  if (!id) {
    return;
  }
  const topic = getTopicById(state, id);
  return getMapTopic(topic);
};

export const getTransformationMapSelectedKeyIssue = (state: RootState) => {
  const id = state.domains.transformationMap.selectedInnerNodeId;
  if (!id) {
    return;
  }
  return getTopic(id)(state);
};

export const getIsTransformationMapPending = (state: RootState) => state.domains.transformationMap.isPending;

export const getTransformationMapHistory = (state: RootState): Topic[] => {
  const items = state.domains.transformationMap.history.slice(1, 6);
  const list = items.map((id) => getTopic(id)(state));
  return filterNoEmpty(list);
};
