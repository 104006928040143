import isPropValid from '@emotion/is-prop-valid';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { StyleSheetManager } from 'styled-components';

import { App } from 'App';
import { history } from 'common/utilities';
import { GlobalStyles } from 'global-styles';
import { store } from 'store';
import 'fonts.css';

const renderApp = () => {
  const container = document.getElementById('root');
  if (container === null) throw Error('No element has ID "root".');
  const root = createRoot(container);
  root.render(
    <StyleSheetManager shouldForwardProp={(prop, target) => (typeof target === 'string' ? isPropValid(prop) : true)}>
      <GlobalStyles />
      <Provider store={store}>
        <Router history={history}>
          <App />
        </Router>
      </Provider>
    </StyleSheetManager>,
  );
};

const renderInternetExplorerScreen = () => {
  document.write(`
    <div style="width: 100%; max-width: 700px; margin: 0 auto; font-family: Helvetica Neue, Helvetica, sans-serif; line-height: 28px; font-size: 18px; padding-top: 40px;">
      <div>
        <img style="display: inline-block; height: 50px; vertical-align: middle; margin-right: 10px;" src="/images/internetExplorer/wef_logo.png" />
        <h1 style="display: inline-block; vertical-align: middle; font-weight: normal; font-size: 28px;">Strategic Intelligence</h1>
      </div>
      <p>Thanks for visiting Strategic Intelligence.</p>
      <p>We don’t support this browser as Microsoft has now replaced it with Microsoft Edge. Rather than offering you a compromised experience, we invite you to try using Google Chrome, Safari or Microsoft Edge instead, or alternatively try our apps by searching “Strategic IQ” in the App, Play or Huawei stores.</p>
      <p>Microsoft provides some further context <a href="https://techcommunity.microsoft.com/t5/Windows-IT-Pro-Blog/The-perils-of-using-Internet-Explorer-as-your-default-browser/ba-p/331732" target="_blank">here</a> which may be helpful to you.</p>
      <div style="display: block; text-align: left;">
        <h3 style="font-weight: bold;">Try using a different Browser<h3>
        <div style="display: block; text-align: left;">
          <a style="display: inline-block; height: 50px; background: #4c8bf5; color: #fff; text-decoration: none; border-radius: 8px; padding: 10px 12px;" href="https://www.google.co.uk/chrome/" target="_blank">
            <img style="display: inline-block; vertical-align: middle; height: 50px; margin-right: 10px;" src="/images/internetExplorer/google_chrome_logo.png" />
            <span style="display: inline-block; vertical-align: middle; height: 42px;">
              <span style="display: block; font-weight: normal; font-size: 12px; letter-spacing: 0.4px; line-height: 14px;">Download</span>
              <span style="display: block; font-weight: 500; font-size: 24px; line-height: 28px;">Google Chrome</span>
            </span>
          </a>
        </div>
        <h3 style="font-weight: bold;">Or download the App on your Mobile <h3>
        <div style="display: block; text-align: left;">
          <a style="display: inline-block; height: 50px;" href="https://apps.apple.com/gb/app/strategic-iq/id1454211238" target="_blank"><img style="display: block; height: 50px;" src="/images/internetExplorer/app_store_logo.png" /></a>
          <a style="display: inline-block; height: 50px;" href="https://play.google.com/store/apps/details?id=org.weforum.intelligence&hl=en_GB" target="_blank"><img style="display: block; height: 50px;" src="/images/internetExplorer/google_play_store_logo.png" /></a>
          <a style="display: inline-block; height: 50px;" href="https://appgallery.cloud.huawei.com/marketshare/app/C100941449" target="_blank"><img style="display: block; height: 50px;" src="/images/internetExplorer/huawei_app_store_logo.png" /></a>
        </div>
        </div>
    </div>
  `);
};

const browserIsInternetExplorer =
  navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1;

if (browserIsInternetExplorer) {
  renderInternetExplorerScreen();
} else {
  renderApp();
}
