import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

export const replaceHistory = (urlOrLocation: string) => pushHistory(urlOrLocation, history.replace);

export const pushHistory = (urlOrLocation: string, updateFunction = history.push) => {
  const newLocation = getLocationWithSavedParams(urlOrLocation, history.location.search);
  if (
    urlOrLocation.startsWith('http') &&
    process.env.REACT_APP_WEBSITE_URL &&
    !urlOrLocation.startsWith(process.env.REACT_APP_WEBSITE_URL)
  ) {
    // This url is not intended for our website, so let's use window.location
    window.location.href = urlOrLocation;
    return;
  }
  updateFunction(newLocation);
};

export const getLocationWithSavedParams = (urlOrLocation: string, oldSearchString?: string) => {
  if (!urlOrLocation) {
    urlOrLocation = '/';
  }

  if (!oldSearchString || oldSearchString === '?') {
    // There's no query parameters to preserve, so don't bother
    return urlOrLocation;
  }

  // Parse the string/object into url parts
  if (typeof urlOrLocation !== 'string') {
    throw new Error('Incorrect argument passed to history push()');
  }
  const parsedUrl = new URL(urlOrLocation, window.location.href);

  // Copy over any parameters that start with "utm"
  const oldParams = new URLSearchParams(oldSearchString);
  oldParams.forEach((value, key) => {
    if (key.startsWith('utm_') && !parsedUrl.searchParams.has(key)) {
      parsedUrl.searchParams.set(key, value);
    }
  });
  return parsedUrl.href.replace(parsedUrl.origin, '');
};
