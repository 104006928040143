import { QueryValue } from 'common/utilities/apiFetch';
import { KnowledgeFeedFetchForTopicPayload } from 'entities/knowledgeFeed/actionTypes';

const KEYS_TO_RENAME: Record<string, string> = {
  searchTerm: 'search',
  fromTime: 'from_time',
  toTime: 'to_time',
  publicationType: 'publication_type',
  contentType: 'content_type',
};

export const getLatestFetchParams = (params: KnowledgeFeedFetchForTopicPayload) => {
  const filter: Record<string, QueryValue> = {};

  (Object.keys(params) as (keyof KnowledgeFeedFetchForTopicPayload)[]).forEach(
    (key: keyof KnowledgeFeedFetchForTopicPayload) => {
      if (key === 'id') return;
      if (params[key] !== '') {
        // If key needs to be renamed check
        filter[KEYS_TO_RENAME[key] ? KEYS_TO_RENAME[key] : key] = params[key];
      }
    },
  );
  return filter;
};
