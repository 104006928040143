import { put, select } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import {
  deleteTopicContentFulfilled,
  deleteTopicContentRejected,
  fetchTopicContentList,
} from 'domains/customContentAndSources/actions';
import { DeleteTopicContentAction } from 'domains/customContentAndSources/actionTypes';
import { deactivateModal, pushModalScreen } from 'domains/modal/actions';
import { getActiveModalId } from 'domains/modal/selectors';
import { ModalContentType } from 'domains/modal/types';
import { FetchError } from 'types';

export function* deleteTopicContentSaga({ payload }: DeleteTopicContentAction) {
  try {
    yield apiFetch(`topic/${payload.topicId}/attachment/${payload.id}`, {
      method: 'DELETE',
    });
    const { topicId } = payload;
    yield put(fetchTopicContentList({ topicId }));
    yield put(deleteTopicContentFulfilled(payload));
    const currentModal: ModalContentType | undefined = yield select(getActiveModalId);
    if (currentModal === 'Add Custom Content') {
      yield put(deactivateModal());
    }
  } catch (error) {
    yield put(deleteTopicContentRejected({ error: error as FetchError }, { logoutOnAuthError: true, ...payload }));
    yield put(pushModalScreen({ id: 'Error' }));
  }
}
