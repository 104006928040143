import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { MasterDetailLayout } from 'common/components';
import { getPageId } from 'common/utilities';
import { fetchClusterDigDeeperItems } from 'domains/clusters/actions';
import {
  getDigDeeperHasNextPage,
  getDigDeeperIsPending,
  getDigDeeperItems,
  getDigDeeperOffset,
} from 'domains/clusters/selectors';
import { useTranslations } from 'domains/language/useTranslations';
import { KnowledgeDetail } from 'domains/publication/components/KnowledgeDetail';
import { KnowledgeList } from 'domains/publication/components/KnowledgeList';
import { getKnowledgeById } from 'entities/knowledge/selectors';

export const DigDeeperPage = () => {
  const dispatch = useDispatch();
  const { clusterId, id } = useParams<{ clusterId: string; id?: string }>();
  const { textDictionary } = useTranslations();

  const items = useSelector(getDigDeeperItems(clusterId));
  const isPending = useSelector(getDigDeeperIsPending(clusterId));
  const offset = useSelector(getDigDeeperOffset(clusterId));
  const hasNextPage = useSelector(getDigDeeperHasNextPage(clusterId));

  const knowledgeId = getPageId(id, items);
  const knowledge = useSelector(getKnowledgeById(knowledgeId));

  useEffect(() => {
    if (clusterId) {
      dispatch(
        fetchClusterDigDeeperItems({
          id: clusterId,
          offset: 0,
        }),
      );
    }
  }, [dispatch, clusterId]);

  const loadNextPage = useCallback(() => {
    if (clusterId) {
      dispatch(
        fetchClusterDigDeeperItems({
          id: clusterId,
          offset: offset + 1,
        }),
      );
    }
  }, [dispatch, clusterId, offset]);

  if (!knowledge) {
    return null;
  }

  const getToLink = (itemId: string) => `/monitor/emerging-themes/${clusterId}/dig-deeper/${itemId}`;

  const list = (
    <KnowledgeList
      items={items}
      isPending={isPending}
      hasNextPage={hasNextPage}
      loadNextPage={loadNextPage}
      getToLink={getToLink}
      selectedId={id}
      trackPage="Cluster detail"
    />
  );

  const shareLink = `${process.env.REACT_APP_WEBSITE_URL}${getToLink(knowledge.id)}`;

  return (
    <MasterDetailLayout
      listTitle={textDictionary['app.cluster.dig_deeper']}
      listComponent={list}
      listBackNavigation={{
        label: textDictionary['app.monitor.emerging_theme'],
        path: `/monitor/emerging-themes/${clusterId}`,
      }}
      detailBackNavigation={{
        label: textDictionary['app.monitor.emerging_theme'],
        path: `/monitor/emerging-themes/${clusterId}`,
      }}
    >
      <KnowledgeDetail id={knowledgeId} knowledge={knowledge} shareLink={shareLink} />
    </MasterDetailLayout>
  );
};
