import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { StyledGrid, InfinityLoader, SectionTitleUnderlined } from 'common/components';
import { mediaStyles } from 'common/styles';
import { getSiteLanguage } from 'domains/language/selectors';
import { TopicTile } from 'domains/topic/components/topicTile/TopicTile';
import { Topic } from 'entities/topics/types';

const StyledInnerContainer = styled.div`
  box-sizing: border-box;
  ${mediaStyles(({ spacing }) => `padding: 0 ${spacing}rem ${spacing}rem ${spacing}rem;`)};
`;

type CustomTopicGridProps = {
  fetchTopics: (shouldInvalidate: boolean) => void;
  isFetching: boolean;
  isPossibleNextPage: boolean;
  title: string;
  titleDataTestId?: string;
  topics: Topic[];
};

export const CustomTopicGrid = ({
  fetchTopics,
  isFetching,
  isPossibleNextPage,
  title,
  titleDataTestId,
  topics,
}: CustomTopicGridProps) => {
  const selectedLanguage = useSelector(getSiteLanguage);

  useEffect(() => {
    fetchTopics(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  return (
    <StyledInnerContainer>
      <SectionTitleUnderlined dataTestId={titleDataTestId}>{title}</SectionTitleUnderlined>
      <InfinityLoader isPending={isFetching} loadNextPage={() => fetchTopics(false)} hasNextPage={isPossibleNextPage}>
        <StyledGrid>
          {topics.map((topic) => (
            <TopicTile key={topic.id} {...topic} />
          ))}
        </StyledGrid>
      </InfinityLoader>
    </StyledInnerContainer>
  );
};
