import { SVGProps } from 'react';

export const MapIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill={'currentColor'}>
      <path d="M0,2.5A2.5,2.5,0,1,1,2.5,5,2.5,2.5,0,0,1,0,2.5Z" transform="translate(13)" />
      <path d="M0,2.5A2.5,2.5,0,1,1,2.5,5,2.5,2.5,0,0,1,0,2.5Z" transform="translate(0 13)" />
      <path d="M0,2.5A2.5,2.5,0,1,1,2.5,5,2.5,2.5,0,0,1,0,2.5Z" transform="translate(13 13)" />
      <path d="M0,2.5A2.5,2.5,0,1,1,2.5,5,2.5,2.5,0,0,1,0,2.5Z" />
      <path d="M0,4A4,4,0,1,1,4,8,4,4,0,0,1,0,4Z" transform="translate(5 5)" />
      <path d="M0,9a9,9,0,1,1,9,9A9,9,0,0,1,0,9Z" fill="none" stroke="currentColor" strokeWidth="1" />
    </g>
  </svg>
);
