import { styled } from 'styled-components';

import { PlayIcon } from 'common/components';
import { useTranslations } from 'domains/language/useTranslations';
import { TopicAttachment } from 'entities/topics/types';

const StyledContainer = styled.div`
  display: flex;
  gap: 10px;
  height: 30px;
`;

const StyledMicrophoneIcon = styled(PlayIcon)`
  width: 18px;
  height: 18px;
  background-color: hsla(0, 0%, 0%, 0.6);
  padding: 6px;
  transition: border-color 0.3s ease;
  border-radius: 4px;
  color: #fff;
`;

const StyledNewTag = styled.div`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.blue};
  padding: 5px 8px;
  border-radius: 5px;
  text-transform: uppercase;
`;

type TopicTileIconsProps = { attachment?: TopicAttachment; isNew?: boolean };

export const TopicTileIcons = ({ attachment, isNew }: TopicTileIconsProps) => {
  const { textDictionary } = useTranslations();
  return (
    <StyledContainer>
      {isNew && <StyledNewTag>{textDictionary['app.topics.new']}</StyledNewTag>}
      {attachment?.type === 'podcast' && <StyledMicrophoneIcon />}
    </StyledContainer>
  );
};
