import { useDispatch, useSelector } from 'react-redux';

import { applyInitiativesSearchCriteria, clearInitiativesSearchCriteria } from 'domains/initiatives/actions';
import { getInitiativesSearchCriteria } from 'domains/initiatives/selectors';
import { useTranslations } from 'domains/language/useTranslations';
import { TopicFilterModal } from 'domains/topicSearchFilter/components/TopicFilterModal';
import { getTopicFilters } from 'domains/topicSearchFilter/selectors';

export const TopicInitiativeFilterModal = () => {
  const dispatch = useDispatch();
  const { textDictionary } = useTranslations();
  const searchCriteria = useSelector(getInitiativesSearchCriteria);
  const filters = useSelector(getTopicFilters('initiatives'));

  const handleApplyFilter = (payload: Partial<any>) => () => dispatch(applyInitiativesSearchCriteria(payload));

  const handleClear = () => dispatch(clearInitiativesSearchCriteria());

  return (
    <TopicFilterModal
      title={textDictionary['app.initiatives.filter.title']}
      fields={filters}
      searchCriteria={searchCriteria}
      handleApplyFilter={handleApplyFilter}
      handleClear={handleClear}
    />
  );
};
