import { combineReducers } from 'redux';
import { takeEvery, takeLatest, takeLeading, throttle } from 'redux-saga/effects';

import { audioPlayerReducer } from 'domains/audioPlayer/reducer';
import { AuthenticationType } from 'domains/authentication/actionTypes';
import { authenticationReducer } from 'domains/authentication/reducers';
import { fwsAuthLoginSaga } from 'domains/authentication/sagas/fwsAuthLoginSaga';
import { unauthenticatedSaga } from 'domains/authentication/sagas/logoutSaga';
import { BookmarkActionTypes } from 'domains/bookmarks/actionTypes';
import { bookmarksReducer } from 'domains/bookmarks/reducer';
import { fetchBookmarksSaga } from 'domains/bookmarks/sagas/fetchBookmarksSaga';
import { toggleBookmarkSaga } from 'domains/bookmarks/sagas/toggleBookmarkSaga';
import { BriefingActionTypes } from 'domains/briefing/actionTypes';
import { briefingsReducer } from 'domains/briefing/reducer';
import { monitorProgressGenerateBriefingSaga } from 'domains/briefing/sagas/monitorProgressGenerateBriefingSaga';
import { triggerGenerateBriefingSaga } from 'domains/briefing/sagas/triggerGenerateBriefingSaga';
import { TopicCaseStudyActionTypes } from 'domains/caseStudy/actionTypes';
import { caseStudiesReducer } from 'domains/caseStudy/reducer';
import { fetchCaseStudiesByIdSaga } from 'domains/caseStudy/sagas/fetchCaseStudiesByIdSaga';
import { ClusterActionType } from 'domains/clusters/actionTypes';
import { clustersReducer } from 'domains/clusters/reducers';
import { clusterItemSaga, clusterKnowledgeSaga } from 'domains/clusters/sagas';
import { CoachmarkDomainsActionType } from 'domains/coachmarks/actionTypes';
import { coachmarksReducer } from 'domains/coachmarks/reducer';
import { changeCoachmarkUsedStatusSaga } from 'domains/coachmarks/sagas/changeCoachmarkUsedStatusSaga';
import { fetchCoachmarksVisibilityStatusSaga } from 'domains/coachmarks/sagas/fetchCoachmarksVisibilityStatusSaga';
import { fetchUsedCoachmarksSaga } from 'domains/coachmarks/sagas/fetchUsedCoachmarksSaga';
import { initSaga } from 'domains/coachmarks/sagas/initSaga';
import { toggleCoachmarksSaga } from 'domains/coachmarks/sagas/toggleCoachmarksSaga';
import { CollectionActionType } from 'domains/collection/actionTypes';
import { collectionsReducer } from 'domains/collection/reducer';
import { deleteCollectionSaga } from 'domains/collection/sagas/deleteCollectionSaga';
import { editCommunitySaga } from 'domains/collection/sagas/editCommunitySaga';
import { fetchCollectionForEditingSaga } from 'domains/collection/sagas/fetchCollectionForEditingSaga';
import { fetchCollectionForViewingSaga } from 'domains/collection/sagas/fetchCollectionForViewingSaga';
import { fetchCollectionsSaga } from 'domains/collection/sagas/fetchCollectionsSaga';
import { fetchCommunitiesSaga } from 'domains/collection/sagas/fetchCommunitiesSaga';
import { fetchCommunitySaga } from 'domains/collection/sagas/fetchCommunitySaga';
import { followCollectionSaga } from 'domains/collection/sagas/followCollectionSaga';
import { unfollowCollectionSaga } from 'domains/collection/sagas/unfollowCollectionSaga';
import { updateCollectionSaga } from 'domains/collection/sagas/updateCollectionSaga';
import { uploadImageSaga } from 'domains/collection/sagas/uploadImageSaga';
import { CreateMapTypes } from 'domains/createMapDetail/actionTypes';
import { createMapReducer } from 'domains/createMapDetail/reducers';
import { addKeyIssueSaga } from 'domains/createMapDetail/sagas/addKeyIssueSaga';
import { getAIGeneratedMapProgressSaga } from 'domains/createMapDetail/sagas/getAIGeneratedMapProgressSaga';
import { uploadMapSaga } from 'domains/createMapDetail/sagas/uploadMapSaga';
import { MapShareType } from 'domains/createShareSettings/actionTypes';
import { shareMapReducer } from 'domains/createShareSettings/reducer';
import { acceptMapInviteSaga } from 'domains/createShareSettings/sagas/acceptMapInviteSaga';
import { changePermissionSaga } from 'domains/createShareSettings/sagas/changePermissionSaga';
import { createInvitationSaga } from 'domains/createShareSettings/sagas/createInvitationSaga';
import { createLinkSaga } from 'domains/createShareSettings/sagas/createLinkSaga';
import { getAccessListSaga } from 'domains/createShareSettings/sagas/getAccessListSaga';
import { openLinkInvitationSaga } from 'domains/createShareSettings/sagas/openLinkInvitationSaga';
import { publicLinkSaga } from 'domains/createShareSettings/sagas/publicLinkSaga';
import { removeInvitationSaga } from 'domains/createShareSettings/sagas/removeInvitationSaga';
import { CustomContentAndSourcesTypes } from 'domains/customContentAndSources/actionTypes';
import { customContentAndSourcesReducer } from 'domains/customContentAndSources/reducers';
import { deleteTopicContentSaga } from 'domains/customContentAndSources/sagas/deleteTopicContentSaga';
import { getTopicContentSaga } from 'domains/customContentAndSources/sagas/getTopicContentSaga';
import { scrapeTopicContentUrlSaga } from 'domains/customContentAndSources/sagas/scrapeTopicContentUrlSaga';
import { uploadTopicContentSaga } from 'domains/customContentAndSources/sagas/uploadTopicContentSaga';
import { CustomKeyIssueTypes } from 'domains/customKeyIssue/actionTypes';
import { customKeyIssueReducer } from 'domains/customKeyIssue/reducers';
import { createCustomKeyIssueSaga } from 'domains/customKeyIssue/sagas/createCustomKeyIssueSaga';
import { fetchConceptSearchSaga } from 'domains/customKeyIssue/sagas/fetchConceptSaga';
import { fetchFingerprintBuilderSaga } from 'domains/customKeyIssue/sagas/fetchFingerprintBuilderSaga';
import { fetchFPKnowledgeSaga } from 'domains/customKeyIssue/sagas/fetchFPKnowledgeSaga';
import { fetchTopicAndTagSuggestionsSaga } from 'domains/customKeyIssue/sagas/fetchTopicAndTagSuggestionsSaga';
import { fetchCuratedTopicSearchSaga } from 'domains/customKeyIssue/sagas/fetchTopicSearchSaga';
import { loadCustomKeyIssueSaga } from 'domains/customKeyIssue/sagas/loadCustomKeyIssueSaga';
import { TopicEventsType } from 'domains/events/actionTypes';
import { eventsReducer } from 'domains/events/reducer';
import { applyEventsSearchCriteriaSaga } from 'domains/events/sagas/applyEventsSearchCriteriaSaga';
import { FetchFilteredTopicsActionsTypes } from 'domains/filteredTopicList/actionTypes';
import { filteredTopicsReducer } from 'domains/filteredTopicList/reducer';
import { fetchFilteredTopicsSaga } from 'domains/filteredTopicList/sagas/fetchFilteredTopicsSaga';
import { IncidentTypes } from 'domains/incidents/constants/actionTypes';
import { incidentsReducer } from 'domains/incidents/reducer';
import { fetchUnresolvedIncidentsSaga } from 'domains/incidents/sagas/fetchUnresolvedIncidentsSaga';
import { storeDismissedIncidentsSaga } from 'domains/incidents/sagas/storeDismissedIncidentsSaga';
import { TopicInitiativeType } from 'domains/initiatives/actionTypes';
import { initiativesReducer } from 'domains/initiatives/reducer';
import { applyInitiativesSearchCriteriaSaga } from 'domains/initiatives/sagas/applyInitiativesSearchCriteriaSaga';
import { TopicKnowledgeDataActionTypes } from 'domains/knowledgeData/actionTypes';
import { knowledgeDataReducer } from 'domains/knowledgeData/reducer';
import { applyKnowledgeDataSearchCriteriaSaga } from 'domains/knowledgeData/sagas/applyKnowledgeDataSearchCriteriaSaga';
import { TopicLatestActionTypes } from 'domains/knowledgeFeed/actionTypes';
import { knowledgeFeedReducer } from 'domains/knowledgeFeed/reducer';
import { applyLatestSearchCriteriaSaga } from 'domains/knowledgeFeed/sagas/applyLatestSearchCriteriaSaga';
import { LanguageDomainActionType } from 'domains/language/actionTypes';
import { languageReducer } from 'domains/language/reducer';
import { fetchTermsSaga } from 'domains/language/sagas/fetchTermsSaga';
import { MembershipEventsTypes } from 'domains/membershipEvents/actionTypes';
import { membershipEventsReducer } from 'domains/membershipEvents/reducer';
import { fetchMembershipEventByIdSaga } from 'domains/membershipEvents/sagas/fetchMembershipEventByIdSaga';
import { fetchMembershipEventsSaga } from 'domains/membershipEvents/sagas/fetchMembershipEventsSaga';
import { modalReducer } from 'domains/modal/reducer';
import { EmergingThemesActionType } from 'domains/monitor/emergingThemes/actionTypes';
import { emergingThemesReducer } from 'domains/monitor/emergingThemes/reducer';
import { emergingThemesSaga } from 'domains/monitor/emergingThemes/sagas';
import { filtersReducer } from 'domains/monitor/filter/reducer';
import { LatestKnowledgeActionType } from 'domains/monitor/latestKnowledge/actionTypes';
import { latestKnowledgeReducer } from 'domains/monitor/latestKnowledge/reducer';
import { latestKnowledgeSaga } from 'domains/monitor/latestKnowledge/sagas';
import { SuggestionsActionTypes } from 'domains/monitor/suggestions/actionTypes';
import { suggestedTopicsReducer } from 'domains/monitor/suggestions/reducer';
import { suggestionsSaga } from 'domains/monitor/suggestions/sagas';
import { NotificationActionType } from 'domains/notifications/actionTypes';
import { notificationsReducer } from 'domains/notifications/reducer';
import { fetchNotificationsSaga } from 'domains/notifications/sagas/fetchNotificationsSaga';
import { markAllDoneSaga } from 'domains/notifications/sagas/markAllDoneSaga';
import { removeNotificationSaga } from 'domains/notifications/sagas/removeNotificationSaga';
import { updateNotificationSaga } from 'domains/notifications/sagas/updateNotificationSaga';
import { TopicPublicationActionTypes } from 'domains/publication/actionTypes';
import { publicationReducer } from 'domains/publication/reducer';
import { applyPublicationSearchCriteriaSaga } from 'domains/publication/sagas/applyPublicationSearchCriteriaSaga';
import { SearchType } from 'domains/search/actionTypes';
import { searchReducer } from 'domains/search/reducer';
import { fetchKnowledgeDataSearchSaga } from 'domains/search/sagas/fetchKnowledgeDataSearchSaga';
import { fetchPublicationsSearchSaga } from 'domains/search/sagas/fetchPublicationsSearchSaga';
import { fetchTopicSearchSaga } from 'domains/search/sagas/fetchTopicSearchSaga';
import { fetchVideosSearchSaga } from 'domains/search/sagas/fetchVideosSearchSaga';
import { triggerSearchesOnTypingSaga } from 'domains/search/sagas/triggerSearchesOnTypingSaga';
import { TopicStakeholderActionTypes } from 'domains/stakeholders/actionTypes';
import { stakeholdersReducer } from 'domains/stakeholders/reducer';
import { applyStakeholdersSearchCriteriaSaga } from 'domains/stakeholders/sagas/applyStakeholdersSearchCriteriaSaga';
import { ThemeType } from 'domains/theme/actionTypes';
import { themeReducer } from 'domains/theme/reducer';
import { setThemeSaga } from 'domains/theme/sagas/setThemeSaga';
import { LegacyTopicActionTypes } from 'domains/topic/actionTypes';
import { topicReducer } from 'domains/topic/reducer';
import { fetchLegacyTopicBriefingSaga } from 'domains/topic/sagas/fetchLegacyTopicBriefingSaga';
import { fetchSurveyLinkSaga } from 'domains/topic/sagas/fetchSurveyLinkSaga';
import { fetchTopicPresentationSaga } from 'domains/topic/sagas/fetchTopicPresentationSaga';
import { TopicDetailType } from 'domains/topicDetail/actionTypes';
import { getAIGeneratedMapDetailsSaga } from 'domains/topicDetail/sagas/getAIGeneratedMapDetailsSaga';
import { initTopicSaga } from 'domains/topicDetail/sagas/initTopicSaga';
import { TopicContentMetaTypes } from 'domains/topicSearchFilter/actionTypes';
import { topicContentMetaReducer } from 'domains/topicSearchFilter/reducers';
import { fetchTopicFiltersSaga } from 'domains/topicSearchFilter/sagas/fetchTopicContentMetaSaga';
import { TransformationMapActionType } from 'domains/transformationMap/actionTypes';
import { transformationMapReducer } from 'domains/transformationMap/reducers';
import { setEditModeSaga } from 'domains/transformationMap/sagas/setEditModeSaga';
import { updateKeyIssueSaga } from 'domains/transformationMap/sagas/updateKeyIssueSaga';
import { updateMapTopicSaga } from 'domains/transformationMap/sagas/updateMapTopicSaga';
import { UserActionType } from 'domains/user/actionTypes';
import { userReducer } from 'domains/user/reducer';
import { unauthenticatedErrorSaga } from 'domains/user/sagas/unauthenticatedErrorSaga';
import { uploadProfileImageSaga } from 'domains/user/sagas/uploadProfileImageSaga';
import { TopicVideoActionTypes } from 'domains/videos/actionTypes';
import { videosReducer } from 'domains/videos/reducer';
import { applyVideoSearchCriteriaSaga } from 'domains/videos/sagas/applyVideoSearchCriteriaSaga';
import { visualMediaPlayerReducer } from 'domains/visualMediaPlayer/reducer';
import { WhatsNewActionType } from 'domains/whatsNew/actionTypes';
import { whatsNewReducer } from 'domains/whatsNew/reducer';
import { fetchWhatsNewSaga } from 'domains/whatsNew/sagas/fetchWhatsNewSaga';

export const domainSagaSpawners = [
  takeEvery(CoachmarkDomainsActionType.INIT, initSaga),
  takeLatest(CoachmarkDomainsActionType.TOGGLE, toggleCoachmarksSaga),
  takeLatest(
    [CoachmarkDomainsActionType.ADD_TO_USED, CoachmarkDomainsActionType.REMOVE_FROM_USED],
    changeCoachmarkUsedStatusSaga,
  ),
  takeLatest(CoachmarkDomainsActionType.FETCH_USED, fetchUsedCoachmarksSaga),
  takeLatest(CoachmarkDomainsActionType.FETCH_VISIBILITY_STATUS, fetchCoachmarksVisibilityStatusSaga),
  takeLatest(LegacyTopicActionTypes.FETCH_BRIEFING, fetchLegacyTopicBriefingSaga),
  takeEvery(LegacyTopicActionTypes.FETCH_SURVEY_LINK, fetchSurveyLinkSaga),
  takeLatest(LegacyTopicActionTypes.FETCH_PRESENTATION, fetchTopicPresentationSaga),
  takeEvery(SearchType.FETCH_TOPICS, fetchTopicSearchSaga),
  takeEvery(SearchType.FETCH_PUBLICATIONS, fetchPublicationsSearchSaga),
  takeEvery(SearchType.FETCH_VIDEOS, fetchVideosSearchSaga),
  takeEvery(SearchType.FETCH_KNOWLEDGE_DATA, fetchKnowledgeDataSearchSaga),
  throttle(800, SearchType.UPDATE_QUERY, triggerSearchesOnTypingSaga),
  takeEvery(CreateMapTypes.UPLOAD, uploadMapSaga),
  takeEvery(CreateMapTypes.ADD_KEY_ISSUE, addKeyIssueSaga),
  takeEvery(CreateMapTypes.GET_AI_GENERATED_PROGRESS, getAIGeneratedMapProgressSaga),
  takeLatest(LanguageDomainActionType.FETCH, fetchTermsSaga),
  takeLatest(
    [TopicPublicationActionTypes.APPLY_SEARCH_CRITERIA, TopicPublicationActionTypes.CLEAR_SEARCH_CRITERIA],
    applyPublicationSearchCriteriaSaga,
  ),
  takeEvery(TopicCaseStudyActionTypes.FETCH_CASE_STUDIES, fetchCaseStudiesByIdSaga),
  takeLatest(
    [TopicVideoActionTypes.APPLY_SEARCH_CRITERIA, TopicVideoActionTypes.CLEAR_SEARCH_CRITERIA],
    applyVideoSearchCriteriaSaga,
  ),
  takeEvery(TopicKnowledgeDataActionTypes.APPLY_SEARCH_CRITERIA, applyKnowledgeDataSearchCriteriaSaga),
  takeEvery(
    [TopicEventsType.APPLY_SEARCH_CRITERIA, TopicEventsType.CLEAR_SEARCH_CRITERIA],
    applyEventsSearchCriteriaSaga,
  ),
  takeEvery(
    [TopicInitiativeType.APPLY_SEARCH_CRITERIA, TopicInitiativeType.CLEAR_SEARCH_CRITERIA],
    applyInitiativesSearchCriteriaSaga,
  ),
  takeEvery(
    [TopicStakeholderActionTypes.APPLY_SEARCH_CRITERIA, TopicStakeholderActionTypes.CLEAR_SEARCH_CRITERIA],
    applyStakeholdersSearchCriteriaSaga,
  ),
  takeEvery('*' as any, unauthenticatedErrorSaga),
  takeEvery(UserActionType.UPLOAD_PROFILE_IMAGE, uploadProfileImageSaga),
  takeLatest(WhatsNewActionType.FETCH, fetchWhatsNewSaga),
  takeEvery(MapShareType.INVITE, createInvitationSaga),
  takeEvery(MapShareType.ACCESS_LIST, getAccessListSaga),
  takeEvery(MapShareType.REMOVE_INVITE, removeInvitationSaga),
  takeEvery(MapShareType.CHANGE_INVITE_PERMISSION, changePermissionSaga),
  takeEvery(MapShareType.PUBLIC_LINK, publicLinkSaga),
  takeEvery(MapShareType.CREATE_LINK, createLinkSaga),
  takeEvery(MapShareType.OPEN_MAP_INVITATION, openLinkInvitationSaga),
  takeEvery(MapShareType.ACCEPT_INVITE, acceptMapInviteSaga),
  takeLatest(IncidentTypes.FETCH_UNRESOLVED, fetchUnresolvedIncidentsSaga),
  takeLatest([IncidentTypes.FETCH_UNRESOLVED_SUCCESS, IncidentTypes.DISMISS_INCIDENT], storeDismissedIncidentsSaga),
  takeLatest(NotificationActionType.FETCH, fetchNotificationsSaga),
  takeEvery(NotificationActionType.UPDATE, updateNotificationSaga),
  takeEvery(NotificationActionType.REMOVE, removeNotificationSaga),
  takeEvery(NotificationActionType.MARK_ALL_DONE, markAllDoneSaga),
  takeLatest(BookmarkActionTypes.BOOKMARKS_FETCH, fetchBookmarksSaga),
  takeEvery(BookmarkActionTypes.BOOKMARK_TOGGLE, toggleBookmarkSaga),
  takeLatest(EmergingThemesActionType.FETCH, emergingThemesSaga),
  takeLatest(LatestKnowledgeActionType.FETCH, latestKnowledgeSaga),
  takeEvery(ClusterActionType.FETCH, clusterItemSaga),
  takeEvery(ClusterActionType.FETCH_DIG_DEEPER, clusterKnowledgeSaga),
  takeEvery(SuggestionsActionTypes.SUGGESTIONS_FETCH, suggestionsSaga),
  takeEvery(MembershipEventsTypes.FETCH, fetchMembershipEventsSaga),
  takeEvery(MembershipEventsTypes.FETCH_BY_ID, fetchMembershipEventByIdSaga),
  takeEvery(TransformationMapActionType.SET_EDIT_MODE, setEditModeSaga),
  takeEvery(TransformationMapActionType.UPDATE_TOPIC, updateMapTopicSaga),
  takeEvery(TransformationMapActionType.SET_INNER_NODE, updateKeyIssueSaga),
  takeEvery(TopicDetailType.INIT, initTopicSaga),
  takeEvery(TopicDetailType.GET_AI_GENERATED_DETAILS, getAIGeneratedMapDetailsSaga),
  takeEvery(ThemeType.TOGGLE, setThemeSaga),
  takeLatest(AuthenticationType.LOGOUT, unauthenticatedSaga),
  // We use `takeLeading` here because this may get called multiple times on endpoints failing with 401, so this helps us only do this once.
  takeLeading(AuthenticationType.FWS_LOGIN, fwsAuthLoginSaga),
  takeLatest(CustomKeyIssueTypes.FETCH_TOPIC_SEARCH, fetchCuratedTopicSearchSaga),
  takeLatest(CustomKeyIssueTypes.FETCH_CONCEPT_SEARCH, fetchConceptSearchSaga),
  takeLatest(CustomKeyIssueTypes.FETCH_FINGERPRINT_BUILDER, fetchFingerprintBuilderSaga),
  takeEvery(CustomKeyIssueTypes.CREATE, createCustomKeyIssueSaga),
  takeEvery(CustomKeyIssueTypes.LOAD, loadCustomKeyIssueSaga),
  takeLatest(CustomKeyIssueTypes.FETCH_FP_KNOWLEDGE, fetchFPKnowledgeSaga),
  takeLatest(CustomKeyIssueTypes.FETCH_TOPIC_AND_TAG_SUGGESTIONS, fetchTopicAndTagSuggestionsSaga),
  takeLatest(CustomContentAndSourcesTypes.FETCH_TOPIC_CONTENT_LIST, getTopicContentSaga),
  takeEvery(CustomContentAndSourcesTypes.UPLOAD_CONTENT_ATTACHMENT, uploadTopicContentSaga),
  takeEvery(CustomContentAndSourcesTypes.DELETE_CONTENT, deleteTopicContentSaga),
  takeLatest(CustomContentAndSourcesTypes.SCRAPE_URL, scrapeTopicContentUrlSaga),
  takeLatest(TopicContentMetaTypes.FETCH_CONTENT_META, fetchTopicFiltersSaga),
  takeEvery(BriefingActionTypes.TRIGGER_GENERATE_BRIEFING, triggerGenerateBriefingSaga),
  takeEvery(BriefingActionTypes.TRIGGER_GENERATE_BRIEFING_SUCCESS, monitorProgressGenerateBriefingSaga),
  takeLatest(
    [TopicLatestActionTypes.APPLY_SEARCH_CRITERIA, TopicLatestActionTypes.CLEAR_SEARCH_CRITERIA],
    applyLatestSearchCriteriaSaga,
  ),
  takeEvery(CollectionActionType.DELETE_COLLECTION, deleteCollectionSaga),
  takeEvery(CollectionActionType.EDIT_COMMUNITY, editCommunitySaga),
  takeEvery(CollectionActionType.FETCH_COLLECTION_FOR_EDITING, fetchCollectionForEditingSaga),
  takeEvery(CollectionActionType.FETCH_COLLECTION_FOR_VIEWING, fetchCollectionForViewingSaga),
  takeEvery(CollectionActionType.FETCH_COLLECTIONS, fetchCollectionsSaga),
  takeEvery(CollectionActionType.FETCH_COMMUNITIES, fetchCommunitiesSaga),
  takeEvery(CollectionActionType.FETCH_COMMUNITY, fetchCommunitySaga),
  takeEvery(CollectionActionType.FOLLOW_COLLECTION, followCollectionSaga),
  takeEvery(CollectionActionType.UNFOLLOW_COLLECTION, unfollowCollectionSaga),
  takeEvery(CollectionActionType.UPDATE_COLLECTION, updateCollectionSaga),
  takeEvery(CollectionActionType.UPLOAD_IMAGE, uploadImageSaga),
  takeEvery(FetchFilteredTopicsActionsTypes.FETCH_FILTERED_TOPICS, fetchFilteredTopicsSaga),
];

export const domainsReducer = combineReducers({
  audioPlayer: audioPlayerReducer,
  authentication: authenticationReducer,
  bookmarks: bookmarksReducer,
  briefings: briefingsReducer,
  caseStudies: caseStudiesReducer,
  clusters: clustersReducer,
  coachmarks: coachmarksReducer,
  collections: collectionsReducer,
  createMap: createMapReducer,
  customContentAndSources: customContentAndSourcesReducer,
  customKeyIssue: customKeyIssueReducer,
  emergingThemes: emergingThemesReducer,
  events: eventsReducer,
  filteredTopics: filteredTopicsReducer,
  filters: filtersReducer,
  incidents: incidentsReducer,
  initiatives: initiativesReducer,
  knowledgeData: knowledgeDataReducer,
  knowledgeFeed: knowledgeFeedReducer,
  language: languageReducer,
  latestKnowledge: latestKnowledgeReducer,
  membershipEvents: membershipEventsReducer,
  modal: modalReducer,
  notifications: notificationsReducer,
  publication: publicationReducer,
  search: searchReducer,
  shareMap: shareMapReducer,
  stakeholders: stakeholdersReducer,
  suggestedTopics: suggestedTopicsReducer,
  theme: themeReducer,
  topic: topicReducer,
  topicContentMeta: topicContentMetaReducer,
  transformationMap: transformationMapReducer,
  user: userReducer,
  videos: videosReducer,
  visualMediaPlayer: visualMediaPlayerReducer,
  whatsNew: whatsNewReducer,
});
