import { NavLink } from 'react-router-dom';
import { css, styled } from 'styled-components';

import { breakpoints } from 'common/styles';

type StyleProps = { opacity?: number };

const containerStyles = css<StyleProps>`
  width: 100%;
  display: flex;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.topHeaderBarText};
  text-decoration: none;
  align-items: center;

  @media ${breakpoints.large} {
    width: auto;
    height: 100%;
    transition: opacity 0.3s;
    font-size: 1rem;
    opacity: ${({ opacity = 0.6 }) => opacity};
    font-weight: bold;
    justify-content: flex-start;
    &:hover,
    &.active {
      opacity: 1;
    }
  }
`;

export const Container = styled.div<StyleProps>`
  ${containerStyles};
`;

export const Link = styled(NavLink)<StyleProps>`
  ${containerStyles};
`;

export const Item = styled.div`
  margin: 0 1rem;
  padding: 20px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.outline};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${breakpoints.large} {
    width: auto;
    margin: 0;
    padding: 0 20px;
    border-bottom: none;
    justify-content: center;
  }
`;
