import { TokenStorage } from 'authUtils';

type InnerTokenManager = {
  signOut: () => Promise<void>;
  tokenRefresh: (refreshSilently?: boolean) => Promise<TokenStorage | null>;
};

/**
 * This class is designed to be used as a singleton to capture the
 * `signinSilent` and `signOut` functions from react-oidc-context to use
 * outside of a React context (i.e. in redux-saga, to refresh the id_token on
 * 401 responses). The underlying UserManager is created by the AuthProvider,
 * so it allows for setting the functions after the object is created.
 *
 * See AuthProvider.tsx to see the implementation details of `tokenRefresh`
 * and `signOut`.
 */
class TokenManagerClass implements InnerTokenManager {
  private resolveTokenManager: (t: InnerTokenManager) => void = () => null;
  private readonly innerTokenManager: Promise<InnerTokenManager>;
  private currentTokenRefreshPromise: Promise<TokenStorage | null> | null = null;

  constructor() {
    // This promise will eventually resolve when `setTokenManagerFunctions` is
    // called in AuthProvider.tsx.
    this.innerTokenManager = new Promise<InnerTokenManager>((resolve) => {
      this.resolveTokenManager = resolve;
    });
  }

  /**
   * Allows setting the functions we get from react-oidc-context when it loads up.
   *
   * @param tokenManager The functions from react-oidc-context to use.
   */
  setTokenManagerFunctions(tokenManager: InnerTokenManager) {
    this.resolveTokenManager(tokenManager);
  }

  /**
   * This method triggers token refreshing, but throttled; we only allow one
   * consecutive call to this to happen at any one time. If a call is already
   * in progress, we return the promise from that previous call (they
   * effectively become "subscribed" to the result from that call).
   *
   * We call this method when we get a 401 response from the API, so we need to
   * guard against multiple endpoints failing simultaneously and spamming token
   * refresh.
   *
   * @param refreshSilently If true, the token will be refreshed without
   * showing the loading screen. Defaults to true.
   * @returns A Promise for a new token, whether that was started with this
   * call or not.
   */
  async tokenRefresh(refreshSilently = true) {
    if (this.currentTokenRefreshPromise) {
      // If there's currently an ongoing refresh, we return the existing promise
      // to "subscribe" the caller to the result.
      return await this.currentTokenRefreshPromise;
    }

    const tokenManager = await this.innerTokenManager;
    this.currentTokenRefreshPromise = tokenManager.tokenRefresh(refreshSilently).finally(() => {
      // Only reset the promise after 3s to prevent accidentally triggering
      // refresh again immediately
      setTimeout(() => {
        this.currentTokenRefreshPromise = null;
      }, 3000);
    });
    return await this.currentTokenRefreshPromise;
  }

  async signOut() {
    const tokenManager = await this.innerTokenManager;
    return await tokenManager.signOut();
  }
}

export const TokenManagerSingleton = new TokenManagerClass();
