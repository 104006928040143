import { useDispatch } from 'react-redux';

import { ProgressBar } from 'common/components';
import { removeBriefing } from 'domains/briefing/actions';
import * as Styled from 'domains/briefing/components/BriefingListItem.styled';
import { Briefing } from 'domains/briefing/types';
import { useTranslations } from 'domains/language/useTranslations';

const getGenerationProgressText = (progress: number, loadingGenerationSentences: string[]) => {
  switch (true) {
    case progress < 25:
      return loadingGenerationSentences[0];
    case progress < 50:
      return loadingGenerationSentences[1];
    case progress < 75:
      return loadingGenerationSentences[2];
    case progress < 100:
      return loadingGenerationSentences[3];
    default:
      return loadingGenerationSentences[loadingGenerationSentences.length - 1];
  }
};

type BriefingListItemProps = { briefing: Briefing };

export const BriefingListItem = ({ briefing }: BriefingListItemProps) => {
  const { topicName, progress, status, briefingId } = briefing;
  const { textDictionary } = useTranslations();
  const dispatch = useDispatch();

  const generationProgressText = [
    textDictionary['app.briefing.progress_collecting_data'],
    textDictionary['app.briefing.progress_preparing_data'],
    textDictionary['app.briefing.progress_assembling_PDF'],
    textDictionary['app.briefing.progress_finalising_PDF'],
  ];

  const onRemoveClick = () => {
    dispatch(removeBriefing(briefingId));
  };

  return (
    <Styled.ItemBox status={status}>
      <Styled.BriefingTitle>{topicName}</Styled.BriefingTitle>
      {status === 'Failed' && (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Styled.Text>{textDictionary['app.briefing.error']}</Styled.Text>
        </div>
      )}
      {status === 'Pending' && (
        <ProgressBar percentage={progress} text={getGenerationProgressText(progress, generationProgressText)} />
      )}
      {status === 'Complete' && (
        <>
          <Styled.StyledCrossIcon onClick={onRemoveClick} />
          <Styled.Text>{textDictionary['app.briefing.document_downloaded']}</Styled.Text>
        </>
      )}
    </Styled.ItemBox>
  );
};
