import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

import { LeftArrow } from 'common/components/icons';
import { UIButton } from 'common/components/UIButton';

const StyledArrow = styled(LeftArrow)`
  width: 14px;
  height: 14px;
  margin-right: 0.5rem;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

type BackButtonProps = { label: string; path: string };

export const BackButton = ({ path, label }: BackButtonProps) => (
  <StyledLink to={path}>
    <UIButton variant={'neutral'}>
      <StyledArrow />
      {label}
    </UIButton>
  </StyledLink>
);
