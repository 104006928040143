import { Knowledge } from 'entities/knowledge/types';
import { ErrorMeta, ErrorPayload } from 'types';

export enum KnowledgeFeedActionTypes {
  FETCH_FOR_TOPIC = 'entities/KNOWLEDGE_FEED_FETCH_FOR_TOPIC',
  FETCH_FOR_TOPIC_SUCCESS = 'entities/KNOWLEDGE_FEED_FETCH_FOR_TOPIC_SUCCESS',
  FETCH_FOR_TOPIC_FAILURE = 'entities/KNOWLEDGE_FEED_FETCH_FOR_TOPIC_FAILURE',
}

export type KnowledgeFeedFetchForTopicPayload = {
  contentType?: string;
  fromTime?: number;
  id: string;
  language?: string;
  limit: number;
  offset: number;
  publicationType?: string;
  searchTerm?: string;
  secondaryTopicId?: string | undefined;
  toTime?: number;
};

export type KnowledgeFeedFetchForTopicAction = {
  payload: KnowledgeFeedFetchForTopicPayload;
  type: KnowledgeFeedActionTypes.FETCH_FOR_TOPIC;
};

export type KnowledgeFeedFetchForTopicSuccessAction = {
  meta: KnowledgeFeedFetchForTopicPayload;
  payload: { data: Knowledge[]; topicId: string };
  type: KnowledgeFeedActionTypes.FETCH_FOR_TOPIC_SUCCESS;
};

export type KnowledgeFeedFetchForTopicFailureAction = {
  meta: KnowledgeFeedFetchForTopicFailureMeta;
  payload: KnowledgeFeedFetchForTopicFailurePayload;
  type: KnowledgeFeedActionTypes.FETCH_FOR_TOPIC_FAILURE;
};

type KnowledgeFeedFetchForTopicFailurePayload = ErrorPayload & { topicId: string };
type KnowledgeFeedFetchForTopicFailureMeta = ErrorMeta & { searchCriteria: KnowledgeFeedFetchForTopicPayload };

export type KnowledgeFeedAction =
  | KnowledgeFeedFetchForTopicAction
  | KnowledgeFeedFetchForTopicSuccessAction
  | KnowledgeFeedFetchForTopicFailureAction;
