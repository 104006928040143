import { SVGProps } from 'react';

export const BookmarkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      d="M44.092 0H3.518A1.518 1.518 0 002 1.518V44.95a3.022 3.022 0 004.805 2.468l17.002-12.186 17.001 12.186a3.022 3.022 0 004.805-2.468V1.518A1.518 1.518 0 0044.093 0zm-1.518 44.95L24.69 32.129a1.518 1.518 0 00-1.77 0L5.036 44.949V3.023h37.538V44.95z"
      fill="currentColor"
    />
  </svg>
);
