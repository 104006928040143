import { ReactNode } from 'react';

export type VisualMediaPlayerState = { file?: VisualMediaFile; isPlaying: boolean };

export enum MediaType {
  knowledgeData,
  videoViaDirectFileLink,
  videoViaEmbeddedHtmlPlayer,
}

export type VisualMediaFile = {
  id: string;
  image?: string;
  mediaType?: MediaType;
  name: string;
  onClose?: () => void;
  splash?: ReactNode;
  url?: string;
  useReactPlayer?: boolean;
};
