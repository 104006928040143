import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { AddItemIcon, CrossCircledIcon, EditIcon, UIButton } from 'common/components';
import { COMMUNITIES_LIMIT } from 'common/constants/limit';
import { createScrollbarStyle } from 'common/styles';
import { cssResets } from 'common/styles/cssResets';
import { Setter } from 'common/types/setter';
import { Status } from 'common/types/status';
import { clearCommunities, fetchCommunities } from 'domains/collection/actions';
import { SearchDropdown } from 'domains/collection/components/edit/parts/SearchDropdown';
import { selectCollections } from 'domains/collection/selectors';
import { Community } from 'domains/collection/types';
import { useTranslations } from 'domains/language/useTranslations';
import { activateModal } from 'domains/modal/actions';

const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  overflow: clip scroll;

  ${createScrollbarStyle()}
`;

const StyledCard = styled.div`
  background-color: ${({ theme }) => theme.colors.coBrandBackground};
  display: flex;
  gap: 1rem;
  height: calc(7.875rem - 19.5px);
  padding: 1rem;
  width: 20.3125rem;
`;

const StyledImage = styled.img`
  border-radius: 0.25rem;
  height: 3.125rem;
  object-fit: cover;
  object-position: center;
  width: 3.125rem;
`;

const StyledColumn = styled.div`
  color: ${({ theme }) => theme.colors.textBright};
  display: flex;
  flex-direction: column;
  font-weight: bold;
  justify-content: space-between;
`;

const StyledName = styled.p`
  ${cssResets.p}
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  color: ${({ theme }) => theme.colors.textBright};
  display: -webkit-box;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  gap: 1.75rem;
`;

const StyledButton = styled(UIButton)`
  background-color: ${({ theme }) => theme.colors.coBrandBackground};
  padding: 0;

  &:focus-visible,
  &:hover {
    background-color: ${({ theme }) => theme.colors.coBrandBackground};
  }
`;

const StyledEditIcon = styled(EditIcon)`
  height: 1rem;
  padding-right: 1rem;
  width: 1rem;
`;

const StyledDeleteIcon = styled(CrossCircledIcon)`
  height: 1rem;
  padding-right: 1rem;
  width: 1rem;
`;

type CoCuratorTabProps = { coCurators: Community[]; setCoCurators: Setter<Community[]> };

export const CoCuratorTab = ({ coCurators, setCoCurators }: CoCuratorTabProps) => {
  const dispatch = useDispatch();
  const { communities, nextPageMayExist, status } = useSelector(selectCollections);
  const { textDictionary } = useTranslations();

  const fetchResults = (search: string, offset: number) =>
    dispatch(fetchCommunities({ meta: { limit: COMMUNITIES_LIMIT, offset }, search }));

  const updateCoCurators = (coCurator: Community) =>
    setCoCurators((value) =>
      value.reduce<Community[]>(
        (accumulator, next) => [...accumulator, next.id === coCurator.id ? coCurator : next],
        [],
      ),
    );

  const getOnClickEditCoCurator = (coCurator: Community) => () =>
    dispatch(
      activateModal({
        id: 'Collection Create Co-Curator',
        params: { coBrand: coCurator, updateCoBrands: updateCoCurators },
      }),
    );

  const getOnClickRemoveCoCurator = (id: string) => () =>
    setCoCurators((value) => value.filter((item) => item.id !== id));

  return (
    <>
      <SearchDropdown
        clearResults={() => dispatch(clearCommunities())}
        fetchResults={fetchResults}
        isLoading={status.communities === Status.Loading}
        nextPageMayExist={nextPageMayExist.communities}
        placeholder={textDictionary['app.collection.search_for_co_curators']}
      >
        {communities.map((item) => ({
          icon: coCurators.some(({ id }) => id === item.id) ? <CrossCircledIcon /> : <AddItemIcon />,
          image: item.image_url ?? undefined,
          key: item.id,
          name: item.name,
          onClick: () => setCoCurators(toggleInclusion(item)),
        }))}
      </SearchDropdown>
      <StyledContainer>
        {coCurators.map((coCurator) => (
          <StyledCard key={coCurator.id}>
            <StyledImage src={coCurator.image_url ?? undefined} />
            <StyledColumn>
              <StyledName>{coCurator.name}</StyledName>
              <StyledButtonContainer>
                <StyledButton onClick={getOnClickEditCoCurator(coCurator)} variant="neutral">
                  <StyledEditIcon />
                  <span>{textDictionary['app.create.collection.co_curator.edit']}</span>
                </StyledButton>
                <StyledButton onClick={getOnClickRemoveCoCurator(coCurator.id)} variant="neutral">
                  <StyledDeleteIcon />
                  <span>{textDictionary['app.create.collection.co_curator.remove']}</span>
                </StyledButton>
              </StyledButtonContainer>
            </StyledColumn>
          </StyledCard>
        ))}
      </StyledContainer>
    </>
  );
};

const toggleInclusion = (item: Community) => (coCurators: Community[]) =>
  coCurators.some(({ id }) => id === item.id) ? coCurators.filter(({ id }) => id !== item.id) : [...coCurators, item];
