import { css } from 'styled-components';

export const cssResets = {
  a: css`
    color: unset;
    cursor: unset;
    outline: unset;
    outline-offset: unset;
    text-decoration: unset;
  `,
  button: css`
    cursor: pointer;
    display: block;

    appearance: unset;
    background-color: unset;
    border: unset;
    color: unset;
    letter-spacing: unset;
    line-height: unset;
    margin: unset;
    padding-block: unset;
    padding-inline: unset;
    text-align: unset;
    text-indent: unset;
    text-rendering: unset;
    text-shadow: unset;
    text-transform: unset;
    word-spacing: unset;
  `,
  h1: css`
    font-size: unset;
    font-weight: unset;
    margin-block: unset;
  `,
  h2: css`
    font-size: unset;
    font-weight: unset;
    margin-block: unset;
  `,
  p: css`
    margin-block: unset;
  `,
};
