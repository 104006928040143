import { useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { StyledGrid, SeeAllButton, SectionTitleUnderlined } from 'common/components';
import { useTranslations } from 'domains/language/useTranslations';
import { ArticleItem } from 'domains/publication/components/ArticleItem';
import { getPublicationsSearchIsPending, getPublicationsSearchResults } from 'domains/search/selectors';

const StyledContainer = styled.div`
  margin-bottom: 2rem;
`;

const StyledItemContainer = styled.div`
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  border: ${({ theme }) => theme.borders.box};
  color: ${({ theme }) => theme.colors.fillPrimary};
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0 0 8px rgba(31, 27, 82, 0.05);
`;

export const PublicationsResults = () => {
  const { textDictionary } = useTranslations();
  const publications = useSelector(getPublicationsSearchResults(6));
  const isPending = useSelector(getPublicationsSearchIsPending);

  if (publications.length === 0) {
    return null;
  }

  return (
    <>
      <StyledContainer>
        <SectionTitleUnderlined
          isLoading={isPending}
          id="publications"
          rightElement={<SeeAllButton link="/search/publications" />}
        >
          {textDictionary['app.discover.topic.tabs.knowledge.title']}
        </SectionTitleUnderlined>
        <StyledGrid numberOfItems={[1, 2, 3, 3, 4]}>
          {publications.map((article) => (
            <StyledItemContainer key={article.id}>
              <ArticleItem
                knowledge={article}
                link={`/search/publications/${article.id}`}
                shareLink={`/search/publications/${article.id}`}
                type="medium"
              />
            </StyledItemContainer>
          ))}
        </StyledGrid>
      </StyledContainer>
    </>
  );
};
