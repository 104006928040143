import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toggleCustomKeyIssueForm } from 'domains/customKeyIssue/actions';
import { AddCustomKeyIssue } from 'domains/customKeyIssue/components/AddCustomKeyIssue';
import { getCustomKeyIssueIsActiveForm, getCustomKeyIssueIsUploading } from 'domains/customKeyIssue/selectors';
import { useTranslations } from 'domains/language/useTranslations';
import { deactivateModal, popModalScreen } from 'domains/modal/actions';
import { ModalHeader } from 'domains/modal/components/ModalHeader';

export const AddCustomKeyIssueModal = () => {
  const dispatch = useDispatch();
  const { textDictionary } = useTranslations();
  const isCustomKeyIssueFormOpen = useSelector(getCustomKeyIssueIsActiveForm);
  const isUploading = useSelector(getCustomKeyIssueIsUploading);

  const dismissModal = () => dispatch(deactivateModal());

  useEffect(
    () => () => {
      dispatch(toggleCustomKeyIssueForm({ isActive: false }));
    },
    [dispatch],
  );

  useEffect(() => {
    if (!isCustomKeyIssueFormOpen && !isUploading) {
      dispatch(popModalScreen());
    }
  }, [dispatch, isCustomKeyIssueFormOpen, isUploading]);

  return (
    <>
      <ModalHeader title={textDictionary['app.custom_key_issue.create']} dismissAction={dismissModal} />
      <AddCustomKeyIssue />
    </>
  );
};
