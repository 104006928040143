import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { ErrorMessage } from 'common/components';
import { CopyLinkField } from 'common/components/CopyLinkField';
import { createLink } from 'domains/createShareSettings/actions';
import { getShareLinkToken, getShareLinkError, getShareLinkIsPending } from 'domains/createShareSettings/selectors';
import { useTranslations } from 'domains/language/useTranslations';
import { ModalHeader } from 'domains/modal/components/ModalHeader';
import { getModalParams } from 'domains/modal/selectors';

const StyledText = styled.div`
  margin: 0;
  margin-bottom: 1rem;
`;

export const CreateLinkModal = () => {
  const dispatch = useDispatch();
  const { topicId, permission } = useSelector(getModalParams);
  const isPending = useSelector(getShareLinkIsPending);
  const token = useSelector(getShareLinkToken);
  const error = useSelector(getShareLinkError);

  const { textDictionary } = useTranslations();

  useEffect(() => {
    if (topicId && (permission === 'viewer' || permission === 'editor')) {
      dispatch(createLink({ topic_id: topicId, permission }));
    }
  }, [dispatch, topicId, permission]);

  const link = `${process.env.REACT_APP_WEBSITE_URL}/map-invite-link/${token}`;
  return (
    <>
      <ModalHeader title={textDictionary['app.map_share.invite_with_link']} />
      <StyledText>{textDictionary['app.map_share.invite_with_link.message']}</StyledText>
      {error ? (
        <ErrorMessage isActive={error}>{textDictionary['app.map_share.invite_with_link.failure']}</ErrorMessage>
      ) : (
        <CopyLinkField url={!token || isPending ? textDictionary['app.loading'] : link} />
      )}
    </>
  );
};
