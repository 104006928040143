import { styled } from 'styled-components';

import { DigitalMemberTag } from 'common/components';
import { breakpoints } from 'common/styles';
import { useElementIsLoaded } from 'common/utilities';
import { StyledLandingText } from 'domains/landing/components/StyledLandingText';
import { StyledLandingTitle } from 'domains/landing/components/StyledLandingTitle';
import BriefingImage from 'domains/landing/create/briefing-image.png';
import {
  StyledInfoContainer,
  SlidingContainer,
  StyledImage,
  StyledSectionContainer,
} from 'domains/landing/create/shared';
import { useTranslations } from 'domains/language/useTranslations';

const StyledBriefingInfoContainer = styled(StyledInfoContainer)`
  order: 1;
  @media ${breakpoints.medium} {
    order: 0;
  }
`;

export const BriefingSection = () => {
  const { isLoaded, elementRef } = useElementIsLoaded({ threshold: 0.5 });
  const { textDictionary } = useTranslations();

  return (
    <StyledSectionContainer ref={elementRef}>
      <StyledBriefingInfoContainer>
        <SlidingContainer isLoaded={isLoaded} isLeft={true}>
          <StyledImage src={BriefingImage} alt={textDictionary['app.topic.insight_briefing']} />
        </SlidingContainer>
      </StyledBriefingInfoContainer>
      <StyledInfoContainer>
        <div ref={elementRef}>
          <SlidingContainer isLoaded={isLoaded}>
            <DigitalMemberTag trackPage="Landing" />
            <StyledLandingTitle>{textDictionary['app.topic.actions.briefing']}</StyledLandingTitle>
            <StyledLandingText>{textDictionary['app.briefing.promo_text1']}</StyledLandingText>
            <StyledLandingText>{textDictionary['app.briefing.promo_text2']}</StyledLandingText>
          </SlidingContainer>
        </div>
      </StyledInfoContainer>
    </StyledSectionContainer>
  );
};
