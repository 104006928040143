import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { ExpandableContent, InfinityLoader, SectionTitleUnderlined } from 'common/components';
import { createScrollbarStyle } from 'common/styles';
import { DimensionItem } from 'domains/createMapDetail/components/keyIssueList/DimensionItem';
import { getCurrentMapCustomKeyIssues, getCurrentMapKeyIssues } from 'domains/createMapDetail/selectors';
import { useTranslations } from 'domains/language/useTranslations';
import { fetchKeyIssues } from 'entities/keyIssues/actions';
import {
  getDimensions,
  getDimensionsHasNextPage,
  getDimensionsIsPending,
  getDimensionsSearchTerm,
} from 'entities/keyIssues/selectors';

const TopicList = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  padding-right: 10px;

  ${createScrollbarStyle()}
`;

const StyledTitle = styled(SectionTitleUnderlined)`
  margin-top: 1rem;
`;

type DimensionsListProps = { className?: string; isLight?: boolean; onEditKeyIssueSelect?: (id: string) => void };

export const DimensionsList = ({ onEditKeyIssueSelect, className }: DimensionsListProps) => {
  const dispatch = useDispatch();
  const { textDictionary } = useTranslations();
  const isPending = useSelector(getDimensionsIsPending);
  const searchTerm = useSelector(getDimensionsSearchTerm);
  const customKeyIssues = useSelector(getCurrentMapCustomKeyIssues);
  const dimensions = useSelector(getDimensions(searchTerm));
  const issuesOnMap = useSelector(getCurrentMapKeyIssues);
  const hasNextPage = useSelector(getDimensionsHasNextPage);

  const loadNextPage = () => {
    if (!isPending && dimensions.length !== 0) {
      dispatch(fetchKeyIssues({ searchTerm, offset: dimensions.length }));
    }
  };

  return (
    <TopicList className={className}>
      <ExpandableContent isActive={customKeyIssues.length !== 0}>
        <StyledTitle>{textDictionary['app.create.my_key_issues']}</StyledTitle>
        {customKeyIssues.map((keyIssue) => (
          <DimensionItem
            {...keyIssue}
            key={keyIssue.id}
            isOnMap={issuesOnMap.some((item) => item.id === keyIssue.id)}
            onEditSelect={onEditKeyIssueSelect}
          />
        ))}
      </ExpandableContent>

      {customKeyIssues.length !== 0 && <StyledTitle>{textDictionary['app.create.all_key_issues']}</StyledTitle>}
      <InfinityLoader isPending={isPending} loadNextPage={loadNextPage} hasNextPage={hasNextPage}>
        {dimensions.map((topic) => (
          <DimensionItem
            {...topic}
            key={topic.id}
            isOnMap={issuesOnMap.some((item) => item.id === topic.id)}
            dataTestId={`dimension-list-item-${topic.id}-add-button`}
          />
        ))}
      </InfinityLoader>
    </TopicList>
  );
};
