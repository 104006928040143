import { Switch, Route } from 'react-router-dom';
import { styled } from 'styled-components';

import { TermsAndConditions } from 'domains/termsAndConditions/components/TermsAndConditions';

const StyledContainer = styled.div`
  height: 100%;
  overflow-y: hidden;
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
`;

export const TocRoutes = () => (
  <Switch>
    <StyledContainer>
      <Route path="/terms-and-conditions">
        <TermsAndConditions type="terms-and-conditions" />
      </Route>
      <Route path="/privacy-policy">
        <TermsAndConditions type="privacy-policy" />
      </Route>
      <Route path="/cookie-notice">
        <TermsAndConditions type="cookie-notice" />
      </Route>
    </StyledContainer>
  </Switch>
);
