import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { StyledModalText, UIButton } from 'common/components';
import { trackEvent } from 'domains/analytics/actions';
import { deleteCollection } from 'domains/collection/actions';
import { useTranslations } from 'domains/language/useTranslations';
import { deactivateModal } from 'domains/modal/actions';
import { ModalHeader } from 'domains/modal/components/ModalHeader';
import { getModalParams } from 'domains/modal/selectors';
import { useRouterNavigation } from 'domains/routing/utils';

const StyledCollectionName = styled.div`
  background-color: ${({ theme }) => theme.colors.modalCardBackground};
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 10px;
`;

export const CollectionDeletionConfirmationModal = () => {
  const dispatch = useDispatch();
  const goCreateCollection = useRouterNavigation({ to: 'create', params: { mode: 'collection' } });
  const { id, name } = useSelector(getModalParams);
  const { textDictionary } = useTranslations();

  const onClick = () => {
    dispatch(deleteCollection({ id }));
    dispatch(trackEvent({ entityId: id, entityName: name, entityType: 'Collection', name: 'Delete Collection' }));
    dispatch(deactivateModal());
    goCreateCollection();
  };

  return (
    <>
      <ModalHeader title={textDictionary['app.modal.delete_collection.title']} />
      <StyledModalText>{textDictionary['app.modal.delete_collection.text']}</StyledModalText>
      <StyledCollectionName>{name}</StyledCollectionName>
      <UIButton error fullwidth onClick={onClick}>
        {textDictionary['app.modal.reply.yes']}
      </UIButton>
    </>
  );
};
