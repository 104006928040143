import { AnalyticsActionMeta } from 'domains/analytics/types';
import { CollectionIdOnly, CollectionMeta } from 'domains/collection/types';
import { DestinationTypes } from 'domains/types';
import {
  FetchFollowedTopicsListDefaultAction,
  FetchFollowedTopicsListFulfilledAction,
  FetchFollowedTopicsListRejectedAction,
  FetchHighlightsDefaultAction,
  FetchHighlightsFulfilledAction,
  FetchHighlightsRejectedAction,
  FetchMyMapsAction,
  FetchMyMapsFulfilledAction,
  FetchSharedWithMeAction,
  FetchSharedWithMeFulfilledAction,
  FetchTopicDefaultAction,
  FetchTopicFulfilledAction,
  FetchTopicRejectedAction,
  FetchTopicsForCollectionAction,
  FetchTopicsForCollectionFailureAction,
  FetchTopicsForCollectionSuccessAction,
  FetchTopicsFulfilledAction,
  FetchTopicsRejectedAction,
  FollowTopicDefaultAction,
  FollowTopicFulfilledAction,
  FollowTopicRejectedAction,
  RemoveMapDefaultAction,
  RemoveMapFulfilledAction,
  RemoveMapRejectedAction,
  ToggleTopicMachineTranslationAction,
  TopicActionType,
  UnfollowTopicDefaultAction,
  UnfollowTopicFulfilledAction,
  UnfollowTopicRejectedAction,
} from 'entities/topics/actionTypes';
import {
  FetchCustomTopicsPayload,
  FetchFollowedTopicsPayload,
  FetchTopicRejectedPayload,
  FetchTopicsForCollectionPayload,
  FetchTopicsPayload,
  FollowTopicPayload,
  RemoveMapMeta,
  TopicFulfilledPayload,
  TopicListPayload,
  TopicListRejectedPayload,
  TopicPayload,
} from 'entities/topics/types';
import { ErrorMeta } from 'types';

export const fetchTopic = (payload: TopicPayload): FetchTopicDefaultAction => ({
  type: TopicActionType.FETCH_TOPIC,
  payload,
});

export const fetchTopicSuccess = (payload: TopicFulfilledPayload): FetchTopicFulfilledAction => ({
  type: TopicActionType.FETCH_TOPIC_FULFILLED,
  payload,
});

export const fetchTopicFailure = (payload: FetchTopicRejectedPayload, meta: ErrorMeta): FetchTopicRejectedAction => ({
  type: TopicActionType.FETCH_TOPIC_REJECTED,
  payload,
  meta,
});

export const fetchHighlightedTopics = (): FetchHighlightsDefaultAction => ({
  type: TopicActionType.FETCH_HIGHLIGHTS,
});

export const fetchHighlightedTopicsSuccess = (payload: FetchTopicsPayload): FetchHighlightsFulfilledAction => ({
  type: TopicActionType.FETCH_HIGHLIGHTS_FULFILLED,
  payload,
});

export const fetchHighlightedTopicsFailure = (
  payload: { error: Error },
  meta: ErrorMeta,
): FetchHighlightsRejectedAction => ({
  type: TopicActionType.FETCH_HIGHLIGHTS_REJECTED,
  payload,
  meta,
});

export enum CustomMapOwner {
  SharedWithMe = 'false',
  MyMaps = 'true',
  SharedWithMeAndMyMaps = '',
}

export const fetchTopicsSuccess = (payload: TopicListPayload): FetchTopicsFulfilledAction => ({
  type: TopicActionType.FETCH_TOPICS_FULFILLED,
  payload,
});

export const fetchTopicsFailure = (payload: TopicListRejectedPayload, meta: ErrorMeta): FetchTopicsRejectedAction => ({
  type: TopicActionType.FETCH_TOPICS_REJECTED,
  payload,
  meta,
});

export const followTopic = (payload: FollowTopicPayload, meta?: AnalyticsActionMeta): FollowTopicDefaultAction => ({
  type: TopicActionType.FOLLOW,
  payload,
  meta,
});

export const followTopicSuccess = (
  payload: FollowTopicPayload,
  meta?: AnalyticsActionMeta,
): FollowTopicFulfilledAction => ({
  type: TopicActionType.FOLLOW_FULFILLED,
  payload,
  meta,
});

export const followTopicFailure = (payload: FollowTopicPayload, meta?: ErrorMeta): FollowTopicRejectedAction => ({
  type: TopicActionType.FOLLOW_REJECTED,
  payload,
  meta,
});

export const unfollowTopic = (payload: FollowTopicPayload, meta?: AnalyticsActionMeta): UnfollowTopicDefaultAction => ({
  type: TopicActionType.UNFOLLOW,
  payload,
  meta,
});

export const unfollowTopicSuccess = (
  payload: FollowTopicPayload,
  meta?: AnalyticsActionMeta,
): UnfollowTopicFulfilledAction => ({
  type: TopicActionType.UNFOLLOW_FULFILLED,
  payload,
  meta,
});

export const unfollowTopicFailure = (payload: FollowTopicPayload, meta: ErrorMeta): UnfollowTopicRejectedAction => ({
  type: TopicActionType.UNFOLLOW_REJECTED,
  payload,
  meta,
});

export type TopicsMeta = {
  canEdit?: boolean;
  limit: number;
  offset: number;
  owner?: CustomMapOwner;
  type?: DestinationTypes;
};

export const fetchFollowedTopics = (meta: TopicsMeta): FetchFollowedTopicsListDefaultAction => ({
  type: TopicActionType.FETCH_FOLLOWED_LIST,
  meta,
});

export const fetchFollowedTopicsSuccess = (
  payload: FetchFollowedTopicsPayload,
): FetchFollowedTopicsListFulfilledAction => ({
  type: TopicActionType.FETCH_FOLLOWED_LIST_FULFILLED,
  payload,
});

export const fetchFollowedTopicsFailure = (
  payload: { error: Error },
  meta: ErrorMeta,
): FetchFollowedTopicsListRejectedAction => ({
  type: TopicActionType.FETCH_FOLLOWED_LIST_REJECTED,
  payload,
  meta,
});

export const fetchMyMaps = (meta: TopicsMeta): FetchMyMapsAction => ({
  type: TopicActionType.FETCH_MY_MAPS,
  payload: {
    limit: meta.limit,
    offset: meta.offset,
    type: DestinationTypes.CUSTOM,
    canEdit: true,
    owner: CustomMapOwner.MyMaps,
  },
});

export const fetchMyMapsSuccess = (payload: FetchCustomTopicsPayload): FetchMyMapsFulfilledAction => ({
  type: TopicActionType.FETCH_MY_MAPS_FULFILLED,
  payload,
});

export const fetchSharedWithMe = (meta: TopicsMeta): FetchSharedWithMeAction => ({
  type: TopicActionType.FETCH_SHARED_WITH_ME,
  payload: {
    limit: meta.limit,
    offset: meta.offset,
    type: DestinationTypes.CUSTOM,
    canEdit: true,
    owner: CustomMapOwner.SharedWithMe,
  },
});

export const fetchSharedWithMeSuccess = (payload: FetchCustomTopicsPayload): FetchSharedWithMeFulfilledAction => ({
  type: TopicActionType.FETCH_SHARED_WITH_ME_FULFILLED,
  payload,
});

export const removeMap = (meta: RemoveMapMeta): RemoveMapDefaultAction => ({
  type: TopicActionType.REMOVE_MAP,
  meta,
});

export const removeMapSuccess = (meta: RemoveMapMeta): RemoveMapFulfilledAction => ({
  type: TopicActionType.REMOVE_MAP_FULFILLED,
  meta,
});

export const removeMapFailure = (): RemoveMapRejectedAction => ({
  type: TopicActionType.REMOVE_MAP_REJECTED,
});

export const toggleTopicMachineTranslation = (
  meta: ToggleTopicMachineTranslationAction['meta'],
): ToggleTopicMachineTranslationAction => ({
  type: TopicActionType.TOGGLE_TOPIC_MACHINE_TRANSLATION,
  meta,
});

export const fetchTopicsForCollection = (payload: FetchTopicsForCollectionPayload): FetchTopicsForCollectionAction => ({
  type: TopicActionType.FETCH_TOPICS_FOR_COLLECTION,
  payload,
});

export const fetchTopicsForCollectionSuccess = (
  payload: CollectionIdOnly,
  meta: CollectionMeta,
): FetchTopicsForCollectionSuccessAction => ({
  type: TopicActionType.FETCH_TOPICS_FOR_COLLECTION_SUCCESS,
  payload,
  meta,
});

export const fetchTopicsForCollectionFailure = (payload: CollectionIdOnly): FetchTopicsForCollectionFailureAction => ({
  type: TopicActionType.FETCH_TOPICS_FOR_COLLECTION_FAILURE,
  payload,
});
