import { forwardRef, ComponentPropsWithoutRef } from 'react';
import { styled } from 'styled-components';

import { CrossIcon, SearchIcon } from 'common/components/icons';
import { Spinner } from 'common/components/Spinner';

const StyledContainer = styled.div`
  height: 3.375rem;
  position: relative;
  width: 100%;
`;

const StyledInput = styled.input`
  background-color: ${({ theme }) => theme.colors.inputBackground}AA;
  border: 3px solid ${({ theme }) => theme.colors.inputBorder};
  border-radius: 27px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.inputText};
  display: block;
  font-size: 1rem;
  height: 3.375rem;
  opacity: 0.8;
  padding: 0 3rem 0 4rem;
  width: 100%;

  &:focus-visible {
    background-color: ${({ theme }) => theme.colors.inputBackground};
    opacity: 1;
    outline: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.inputBackground};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.inputText};
    opacity: 0.8;
  }
`;

const StyledSearchIcon = styled(SearchIcon)`
  color: ${({ theme }) => theme.colors.inputText};
  left: 1.5rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

const StyledClearButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.inputText};
  cursor: pointer;
  display: block;
  height: 14px;
  opacity: 0.5;
  padding: 0;
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);

  &:hover {
    opacity: 1;
  }
`;

const StyledSpinner = styled(Spinner)`
  color: ${({ theme }) => theme.colors.inputText};
  opacity: 0.5;
  position: absolute;
  right: 1.5rem;
  top: calc(50% - 0.5rem);
`;

type UIInputProps = Omit<ComponentPropsWithoutRef<'input'>, 'onChange'> & {
  clear?: () => void;
  isPending?: boolean;
  onChange: (value: string) => void;
  value: string;
};

export const UIInput = forwardRef<HTMLInputElement, UIInputProps>(
  ({ className, clear, isPending = false, onChange, ...rest }, ref) => (
    <StyledContainer className={className}>
      <StyledInput
        {...rest}
        autoComplete="off"
        onChange={({ target }) => onChange(target.value)}
        ref={ref}
        role="searchbox"
      />
      <StyledSearchIcon height={24} width={24} />
      {isPending ? (
        <StyledSpinner height={16} width={16} />
      ) : (
        rest.value.length === 0 ||
        clear === undefined || (
          <StyledClearButton onClick={clear}>
            <CrossIcon height={14} width={14} />
          </StyledClearButton>
        )
      )}
    </StyledContainer>
  ),
);

UIInput.displayName = 'UIInput';
