import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { acceptMapShareInviteFailure, acceptMapShareInviteSuccess } from 'domains/createShareSettings/actions';
import { AcceptMapInviteDefaultAction } from 'domains/createShareSettings/actionTypes';
import { fetchSharedWithMe } from 'entities/topics/actions';
import { fetchTopicsMetadata } from 'entities/topicsMetadata/actions';
import { FetchError } from 'types';

export function* acceptMapInviteSaga(action: AcceptMapInviteDefaultAction) {
  try {
    yield apiFetch('mapshare', {
      queryParams: {
        token: action.meta.token,
      },
      extraHeaders: {
        Accept: 'application/json',
      },
    });
    yield put(acceptMapShareInviteSuccess(action.meta));
    yield put(fetchTopicsMetadata());
    yield put(fetchSharedWithMe({ offset: 0, limit: 8 }));
  } catch (error) {
    const fetchError = error as FetchError;
    yield put(
      acceptMapShareInviteFailure({
        ...action.meta,
        isExpired: fetchError.status === 401,
      }),
    );
  }
}
