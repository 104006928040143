import { ComponentPropsWithoutRef } from 'react';
import { styled } from 'styled-components';

import { ChevronIcon, UIButton } from 'common/components';
import { useTranslations } from 'domains/language/useTranslations';

const StyledChevron = styled(ChevronIcon)<{ $showSeeMore: boolean }>`
  transform: rotate(${({ $showSeeMore }) => ($showSeeMore ? '90deg' : '270deg')});
  height: 15px;
  width: 15px;
  margin-left: 1rem;
  transition: transform 0.3s;
`;

type LoadMoreButtonProps = ComponentPropsWithoutRef<'button'> & { filterCount?: number; showSeeMore?: boolean };

export const LoadMoreButton = ({
  children,
  filterCount: _filterCount,
  showSeeMore = true,
  ...props
}: LoadMoreButtonProps) => {
  const { textDictionary } = useTranslations();
  return (
    <UIButton variant={'neutral'} {...props} style={{ margin: '0 auto' }}>
      {children}
      {textDictionary[`app.action.${showSeeMore ? 'see_more' : 'see_less'}`]}
      <StyledChevron $showSeeMore={showSeeMore} />
    </UIButton>
  );
};
