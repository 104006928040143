import {
  FetchPermissionsDefaultAction,
  PermissionsType,
  FetchPermissionsFulfilledAction,
  FetchPermissionsRejectedAction,
} from 'entities/permissions/actionTypes';

export const fetchPermissions = (): FetchPermissionsDefaultAction => ({
  type: PermissionsType.FETCH,
});

export const fetchPermissionsSuccess = (
  payload: FetchPermissionsFulfilledAction['payload'],
): FetchPermissionsFulfilledAction => ({
  type: PermissionsType.FETCH_FULFILLED,
  payload,
});

export const fetchPermissionsFailure = (
  payload: FetchPermissionsRejectedAction['payload'],
  meta: FetchPermissionsRejectedAction['meta'],
) => ({
  type: PermissionsType.FETCH_REJECTED,
  payload,
  meta,
});
