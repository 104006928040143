import { styled } from 'styled-components';

import { UIButton } from 'common/components';
import { breakpoints } from 'common/styles';
import { useTranslations } from 'domains/language/useTranslations';
import { useRouterNavigation } from 'domains/routing/utils';

const StyledPageNotFound = styled.div<{ isCurrentLanguageRtl: boolean }>`
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
  padding: 4rem 2rem;
  direction: ${({ isCurrentLanguageRtl }) => (isCurrentLanguageRtl ? 'rtl' : 'ltr')};
`;

const StyledTag = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.textFade};
  font-weight: bold;
  line-height: 1.63;
`;

const StyledHeader = styled.h1`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.textBright};
  margin: 0 0 1rem;

  @media ${breakpoints.medium} {
    max-width: 500px;
  }
`;

export const PageNotFound = () => {
  const { textDictionary, isCurrentLanguageRtl } = useTranslations();
  const navigateToHomePage = useRouterNavigation({ to: 'landing' });

  return (
    <StyledPageNotFound isCurrentLanguageRtl={isCurrentLanguageRtl}>
      <StyledTag>{textDictionary['app.page_not_found.tag']}</StyledTag>
      <StyledHeader>{textDictionary['app.page_not_found.header']}</StyledHeader>
      <UIButton variant={'filled'} onClick={() => navigateToHomePage()}>
        {textDictionary['app.page_not_found.link']}
      </UIButton>
    </StyledPageNotFound>
  );
};
