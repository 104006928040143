import { PropsWithChildren, ReactElement } from 'react';
import { css, styled } from 'styled-components';

import { CopyIcon, EmailIcon, LinkedInIcon, TelegramIcon, TwitterIcon, WhatsAppIcon } from 'common/components';
import { ShareIconName } from 'domains/share/types';

type LinkProps = PropsWithChildren & { className?: string; href?: string; onClick?: () => void };

const Link = ({ children, href, ...props }: LinkProps) => {
  if (href) {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer" {...props}>
        {children}
      </a>
    );
  }
  return <div {...props}>{children}</div>;
};

const StyledContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 74px;
  justify-content: center;
  border: 0;
  background: none;
  font-size: 0.8rem;
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.textFade};
  transition: all 0.3s;

  &:hover {
    color: ${({ theme }) => theme.colors.textBright};
  }
`;

const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
`;

const iconStyle = css`
  width: 32px;
  height: 32px;
`;

const StyledLinkedInIcon = styled(LinkedInIcon)`
  ${iconStyle};
`;

const StyledTwitterIcon = styled(TwitterIcon)`
  ${iconStyle};
`;

const StyledWhatsAppIcon = styled(WhatsAppIcon)`
  ${iconStyle};
`;

const StyledTelegramIcon = styled(TelegramIcon)`
  height: 40px;
  width: 40px;
`;

const StyledEmailIcon = styled(EmailIcon)`
  ${iconStyle};
`;

const StyledCopyIcon = styled(CopyIcon)`
  ${iconStyle};
`;

const icons: Record<ShareIconName, ReactElement> = {
  LinkedIn: <StyledLinkedInIcon />,
  Twitter: <StyledTwitterIcon />,
  WhatsApp: <StyledWhatsAppIcon />,
  Telegram: <StyledTelegramIcon />,
  Email: <StyledEmailIcon />,
  Copy: <StyledCopyIcon />,
};

type ShareModalButtonProps = PropsWithChildren & { href?: string; name: ShareIconName; onClick?: () => void };

export const ShareModalButton = ({ children, href, name, onClick }: ShareModalButtonProps) => (
  <StyledContainer onClick={onClick} href={href}>
    <StyledIconWrapper>{icons[name]}</StyledIconWrapper>
    {children}
  </StyledContainer>
);
