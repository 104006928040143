import { ReactNode, useEffect, useRef, useState } from 'react';
import { styled } from 'styled-components';

import { ExpandableContent } from 'common/components/ExpandableContent';
import { MarkdownArea } from 'common/components/MarkdownArea';
import { useTranslations } from 'domains/language/useTranslations';

const StyledContainer = styled.div<{ isReady?: boolean }>`
  opacity: ${({ isReady }) => (isReady ? 1 : 0)};
  cursor: pointer;
`;

const StyledText = styled(MarkdownArea)`
  margin: 0;
  font-size: 0.9rem;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.textFade};
`;

export const StyledExpandButton = styled.div`
  transition: 0.3s color;
  font-weight: bold;
  font-size: 0.9rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.textBright}BB;
  margin-top: 1rem;

  &:hover {
    color: ${({ theme }) => theme.colors.textBright};
  }
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledChildContainer = styled.div`
  margin: 0.6rem 0;
`;

type ExpandableTextProps = {
  children?: ReactNode;
  className?: string;
  detailTag?: ReactNode;
  limit?: number;
  lineHeight?: number;
  maxHeight?: string;
  text?: string;
};

export const ExpandableText = ({
  limit = 15,
  lineHeight = 19,
  text,
  className,
  detailTag,
  children,
}: ExpandableTextProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { textDictionary } = useTranslations();
  const [canExpand, setCanExpand] = useState(false);
  const [isReady, setIsReady] = useState<boolean | undefined>(true);
  const [isActive, setIsActive] = useState(false);

  const toggleHandle = () => setIsActive(!isActive);

  useEffect(() => {
    setIsActive(false);
    setCanExpand(false);
    setIsReady(false);
  }, [text]);

  useEffect(() => {
    if (ref.current && text && !isReady) {
      const height = ref.current.offsetHeight;
      const maxLines = height / (lineHeight || 1);
      setCanExpand(maxLines > limit);
      setIsReady(true);
    }
  }, [isReady, text, limit, lineHeight]);

  if (!text) {
    return null;
  }
  return (
    <StyledContainer onClick={toggleHandle} className={className} isReady={isReady}>
      <ExpandableContent collapsedHeight={`${lineHeight * limit}px`} isActive={isActive}>
        <div ref={ref}>
          <StyledText>{text}</StyledText>
          {children && <StyledChildContainer>{children}</StyledChildContainer>}
        </div>
      </ExpandableContent>
      <StyledRow>
        {canExpand && (
          <StyledExpandButton data-test-id={'expand-button'}>
            {textDictionary[!isActive ? 'app.read_more' : 'app.read_less']}
          </StyledExpandButton>
        )}
        {detailTag}
      </StyledRow>
    </StyledContainer>
  );
};
