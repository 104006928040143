import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { fetchTermsFailure, fetchTermsSuccess } from 'domains/language/actions';
import { FetchLanguageTermsDefaultAction } from 'domains/language/actionTypes';
import { PayloadTerm } from 'domains/language/types';
import { isFetchError } from 'TypeCheckMethods';

const { localStorage } = window;

export function* fetchTermsSaga(action: FetchLanguageTermsDefaultAction) {
  try {
    const { language } = action.meta;
    const terms: PayloadTerm[] = yield apiFetch(`language/${language}/term`);

    yield put(fetchTermsSuccess({ terms }, action.meta));

    localStorage.setItem('language', language);
    localStorage.setItem('terms', JSON.stringify(terms));
  } catch (error) {
    if (isFetchError(error)) {
      yield put(fetchTermsFailure(error));
    }
  }
}
