import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { LockIcon } from 'common/components/icons';
import { UIButton } from 'common/components/UIButton';
import { trackEvent } from 'domains/analytics/actions';
import { TrackPage } from 'domains/analytics/types';
import { getIsAuthenticated } from 'domains/authentication/selectors';
import { useTranslations } from 'domains/language/useTranslations';
import { getUserRole } from 'entities/user/selectors';
import { getLinkToMembershipPage } from 'entities/user/utils';
import { useAuth } from 'useAuth';

const StyledLockedContent = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 1rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.fillPrimary};
`;

// const StyledLabel = styled.div`
//   position: absolute;
//   padding: 4px 8px;
//   text-transform: uppercase;
//   top: 24px;
//   left: 30px;
//   border-radius: 4px;
//   background-color: ${({ theme }) => theme.colors.lockedLabelBackground};
//   color: ${({ theme }) => theme.colors.lockedLabelText};
// `;

const StyledButton = styled(UIButton)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledCircle = styled.div`
  position: absolute;
  bottom: 24px;
  left: 30px;
  width: 40px;
  height: 40px;
  border-width: 2px;
  border-radius: 22px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.lockedLabelBackground};
`;

const StyledIcon = styled(LockIcon)`
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.colors.lockedLabelBackground};
`;

type LockedContentProps = { isFirstLocked?: boolean; trackPage: TrackPage };

export const LockedContent = ({ trackPage, isFirstLocked = true }: LockedContentProps) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const { textDictionary } = useTranslations();
  const { triggerLogin } = useAuth();
  const userRole = useSelector(getUserRole);

  const onClickHandle = () => {
    if (isAuthenticated) {
      dispatch(
        trackEvent({
          name: 'Upgrade',
          page: trackPage,
        }),
      );
      window.location.href = getLinkToMembershipPage(userRole);
    } else {
      triggerLogin();
    }
  };
  return (
    <StyledLockedContent onClick={onClickHandle} data-test-id="locked-section">
      {isFirstLocked ? (
        <>
          {/* <StyledLabel>*/}
          {/*  {textDictionary["app.content.related_content"]}*/}
          {/* </StyledLabel>*/}
          <StyledButton icon="lock" variant="outlined">
            {textDictionary[isAuthenticated ? 'app.topic.locked_content.upgrade' : 'app.topic.locked_content']}
          </StyledButton>
        </>
      ) : (
        <StyledCircle>
          <StyledIcon />
        </StyledCircle>
      )}
    </StyledLockedContent>
  );
};
