import union from 'lodash.union';
import { combineReducers } from 'redux';

import { LanguageCode } from 'common/types/languageCode';
import { unsafelyKeys } from 'common/utilities/object';
import { LanguageAction, LanguageActionTypes } from 'entities/languages/actionTypes';
import { Language, LanguageState } from 'entities/languages/types';

const initialState: LanguageState = {
  allIds: [],
  byId: {} as Record<LanguageCode, Language>,
  defaultLanguage: LanguageCode.EN,
};

const allIds = (state = initialState.allIds, action: LanguageAction): LanguageState['allIds'] => {
  switch (action.type) {
    case LanguageActionTypes.FETCH_ALL_FULFILLED:
      return union(
        state,
        action.payload.languages.map(({ id }) => id),
      );

    default:
      return state;
  }
};

const byId = (state = initialState.byId, action: LanguageAction): LanguageState['byId'] => {
  switch (action.type) {
    case LanguageActionTypes.FETCH_ALL:
      return unsafelyKeys(state).reduce(
        (byId, id) => ({
          ...byId,
          [id]: { ...state[id], isFetching: true },
        }),
        {} as Record<LanguageCode, Language>,
      );

    case LanguageActionTypes.FETCH_ALL_FULFILLED:
      return action.payload.languages.reduce<Record<string, Language>>(
        (byId, language) => ({
          ...byId,
          [language.id]: {
            ...state[language.id],
            ...language,
            isFetching: false,
          },
        }),
        state,
      );

    case LanguageActionTypes.FETCH_ALL_REJECTED:
      return unsafelyKeys(state).reduce(
        (byId, id) => ({
          ...byId,
          [id]: { ...state[id], isFetching: false },
        }),
        {} as Record<LanguageCode, Language>,
      );

    default:
      return state;
  }
};

const defaultLanguage = (state = initialState.defaultLanguage, action: LanguageAction): LanguageCode => {
  switch (action.type) {
    case LanguageActionTypes.FETCH_ALL_FULFILLED:
      return action.payload.defaultLanguage;
    default:
      return state;
  }
};

export const languagesReducer = combineReducers({ allIds, byId, defaultLanguage });
