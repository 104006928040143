import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { styled } from 'styled-components';

import { MasterDetailLayout } from 'common/components';
import { getPageId } from 'common/utilities';
import { trackEvent } from 'domains/analytics/actions';
import { useTranslations } from 'domains/language/useTranslations';
import { KnowledgeDetail } from 'domains/publication/components/KnowledgeDetail';
import { KnowledgeList } from 'domains/publication/components/KnowledgeList';
import { searchKnowledgeData } from 'domains/search/actions';
import { SearchInput } from 'domains/search/components/SearchInput';
import {
  getCurrentSearchTerm,
  getInteractiveDataSearchHasNextPage,
  getInteractiveDataSearchIsPending,
  getInteractiveDataSearchResults,
} from 'domains/search/selectors';
import { getKnowledgeById } from 'entities/knowledge/selectors';

const StyledSearch = styled(SearchInput)`
  margin-bottom: 2rem;
`;

export const InteractiveDataSearchPage = () => {
  const dispatch = useDispatch();
  const { textDictionary } = useTranslations();
  const { dataId } = useParams<{ dataId: string }>();
  const data = useSelector(getInteractiveDataSearchResults());
  const id = getPageId(dataId, data);
  const dataItem = useSelector(getKnowledgeById(id));
  const query = useSelector(getCurrentSearchTerm);
  const isPending = useSelector(getInteractiveDataSearchIsPending);
  const hasNextPage = useSelector(getInteractiveDataSearchHasNextPage);

  useEffect(() => {
    if (dataItem) {
      dispatch(
        trackEvent({
          name: 'Browse page',
          page: 'Search',
          subpage: 'Knowledge detail',
          entityId: dataItem.id,
          entityName: dataItem.title,
          entityType: 'Interactive data',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataItem?.id, dispatch]);

  const loadNextPage = useCallback(() => {
    if (query) {
      dispatch(searchKnowledgeData({ query, offset: data.length }));
    }
  }, [dispatch, query, data.length]);

  const getLink = (id: string) => `/search/interactive-data/${id}`;

  const knowledgeList = (
    <KnowledgeList
      items={data.length === 0 && dataItem ? [dataItem] : data}
      selectedId={id}
      trackPage="Discover"
      getToLink={getLink}
      isPending={isPending}
      hasNextPage={hasNextPage && !!query}
      showEmptyMessage={!!query}
      emptyMessage={textDictionary['app.search.results.no_results']}
      loadNextPage={loadNextPage}
    />
  );

  const shareLink = `${process.env.REACT_APP_WEBSITE_URL}${getLink(dataId)}`;

  return (
    <MasterDetailLayout
      headerElement={<StyledSearch type="interactiveData" />}
      listTitle={textDictionary['app.discover.topic.tabs.interactive_data.title']}
      listComponent={knowledgeList}
      listBackNavigation={{
        label: textDictionary['app.search.title'],
        path: '/search',
      }}
      detailBackNavigation={{
        label: textDictionary['app.discover.topic.tabs.interactive_data.title'],
        path: '/search/interactive-data',
      }}
      mainView={dataId ? 'right' : 'left'}
    >
      <KnowledgeDetail id={id} knowledge={dataItem} shareLink={shareLink} />
    </MasterDetailLayout>
  );
};
