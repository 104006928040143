import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { StyledModalText as ModalText, SectionTitleUnderlined } from 'common/components';
import { useFormatDate } from 'common/utilities';
import { getUnresolvedIncidents } from 'domains/incidents/selectors';
import { ModalHeader } from 'domains/modal/components/ModalHeader';

const StyledModalText = styled(ModalText)`
  margin: 0;
`;

const StyledModalTextContainer = styled.div`
  margin-bottom: 1rem;
`;

export const IncidentDetailModal = () => {
  const { getDate } = useFormatDate();
  const unresolvedIncidents = useSelector(getUnresolvedIncidents);

  return (
    <>
      <ModalHeader />
      {unresolvedIncidents.map(({ name, incidentUpdates }, incidentIndex) => (
        <Fragment key={incidentIndex}>
          <SectionTitleUnderlined>{name}</SectionTitleUnderlined>
          {incidentUpdates.map(({ displayAt, status, body }, updateIndex) => (
            <StyledModalTextContainer key={updateIndex}>
              <StyledModalText>
                <strong>
                  {status.charAt(0).toUpperCase()}
                  {status.substr(1)}
                </strong>
                {` - ${getDate({ date: displayAt })}`}
              </StyledModalText>
              <StyledModalText>{body}</StyledModalText>
            </StyledModalTextContainer>
          ))}
        </Fragment>
      ))}
    </>
  );
};
