import { Middleware } from 'redux';

import { history } from 'common/utilities';
import { AnalyticsActionType } from 'domains/analytics/actionTypes';
import { identify, initTracking, reset, track } from 'domains/analytics/mixpanel';
import { EntityType, TrackEvent, TrackMeta, TrackPage } from 'domains/analytics/types';
import { AuthenticationType } from 'domains/authentication/actionTypes';
import { BookmarkActionTypes } from 'domains/bookmarks/actionTypes';
import { CreateMapTypes } from 'domains/createMapDetail/actionTypes';
import { getCurrentMap } from 'domains/createMapDetail/selectors';
import { LanguageDomainActionType } from 'domains/language/actionTypes';
import { getSiteLanguage, getSiteLanguageLabel } from 'domains/language/selectors';
import { ThemeType } from 'domains/theme/actionTypes';
import { LegacyTopicActionTypes as TopicDomainTypes } from 'domains/topic/actionTypes';
import { TransformationMapActionType } from 'domains/transformationMap/actionTypes';
import { getTransformationMapTopic } from 'domains/transformationMap/selectors';
import { KeyIssuesActionType } from 'entities/keyIssues/actionTypes';
import { getDimensionById } from 'entities/keyIssues/selectors';
import { TopicActionType } from 'entities/topics/actionTypes';
import { getTopicById } from 'entities/topics/selectors';
import { RootState } from 'types';

initTracking();

export const createAnalyticsMiddleware: () => Middleware<object, RootState> = () => (store) => (next) => (action) => {
  const { meta = {} } = action;
  const event: TrackMeta = meta.event || {};
  const state = store.getState();

  // Add the default expected tracking metadata for events that can happen on multiple pages
  const trackWithDefaults = (name: TrackEvent, trackData?: Partial<TrackMeta>, callback?: () => void) => {
    // Detect the page we're on to see what metadata we should add
    let additionalData: Partial<TrackMeta> = {};
    const topicPaths = ['discover', 'topics', 'create'];
    const activeTopicPath = topicPaths.find((path) => history.location.pathname.includes(`/${path}/`));
    if (activeTopicPath) {
      let page: TrackPage = activeTopicPath === 'create' ? 'Create' : 'Topic detail';
      const topicId = (history.location.pathname.split(`/${activeTopicPath}/`)[1] || '').split('/')[0];
      const keyIssueId = (history.location.pathname.split(`/key-issues/`)[1] || '').split('/')[0];

      const possibleSubpages: Record<string, [TrackPage, TrackPage, EntityType]> = {
        publications: ['Publications List', 'Knowledge detail', 'Publication'],
        videos: ['Video List', 'Video detail', 'Video'],
        'interactive-data': ['Interactive Data List', 'Interactive Data Detail', 'Interactive data'],
        events: ['Event List', 'Session detail', 'Session'],
        initiatives: ['Initiative List', 'Project detail', 'Project'],
        stakeholders: ['Stakeholder List', 'Stakeholder detail', 'Stakeholder'],
      };
      const subpageRouteSlug = Object.keys(possibleSubpages).find(
        (routeSlug) => history.location.pathname.indexOf(`/${routeSlug}`) !== -1,
      );
      let subpage: TrackPage | undefined = undefined;
      let entityId = topicId;
      let entityType: EntityType = 'Topic';
      let secondaryEntityType: EntityType | undefined = undefined;
      let secondaryEntityId = undefined;
      let subpageDetails = undefined;
      const selectedTopicId = keyIssueId ?? topicId;
      if (subpageRouteSlug) {
        // We're on a /topics/:id/publications|videos|data... route
        subpageDetails = possibleSubpages[subpageRouteSlug as keyof typeof possibleSubpages];
        if (subpageDetails) {
          const pathParts = history.location.pathname.split(`/${subpageRouteSlug}/`);

          if (pathParts.length > 1 && pathParts[1]) {
            // We're on a detail route, so use the related item as the main entity
            // and the topic as the secondary entity
            subpage = page;
            page = subpageDetails[1];
            entityId = pathParts[1];
            entityType = subpageDetails[2];
            secondaryEntityType = 'Topic';
            secondaryEntityId = selectedTopicId;
          } else {
            // We're on a list route, so use the topic as the main entity
            subpage = subpageDetails[0];
          }
        }
      }

      if (!secondaryEntityId && keyIssueId) {
        // If we don't have a second entity, then use the key issue
        secondaryEntityId = keyIssueId;
        secondaryEntityType = 'Topic';
      }

      if (trackData?.entityId && !trackData?.secondaryEntityId && trackData.entityId !== selectedTopicId) {
        // If we already have an entity id as part of the action,
        // then add our selected topic as the second entity
        secondaryEntityId = selectedTopicId;
        secondaryEntityType = 'Topic';
      }
      additionalData = {
        page,
        subpage,
        entityId,
        entityType,
        secondaryEntityType,
        secondaryEntityId,
      };
    }

    if (!additionalData.page) {
      if (['', '/'].includes(history.location.pathname)) {
        additionalData.page = 'Landing';
      } else {
        const highLevelPaths: Record<string, TrackPage> = {
          discover: 'Discover',
          topics: 'Discover',
          create: 'Create landing',
          monitor: 'Monitor',
          about: 'About',
          search: 'Search',
          'terms-and-conditions': 'Terms and conditions',
          'privacy-policy': 'Terms and conditions',
          'cookie-notice': 'Terms and conditions',
        };
        const highLevelPath = Object.keys(highLevelPaths).find((highLevelPath) =>
          history.location.pathname.startsWith(`/${highLevelPath}`),
        );
        additionalData.page = highLevelPaths[highLevelPath as keyof typeof highLevelPaths];
      }
    }

    const trackingPayload = {
      language: getSiteLanguage(state),
      ...additionalData,
      ...trackData,
    };
    track(name, trackingPayload, callback);
  };

  switch (action.type) {
    case AnalyticsActionType.TRACK_EVENT: {
      const { name, callback, ...payload } = event;
      trackWithDefaults(name, payload, callback);
      break;
    }

    case AuthenticationType.LOGIN_FULFILLED:
      identify(event.userId);
      trackWithDefaults('Login', {
        userId: event.userId,
        eventType: 'successful login',
      });
      break;

    case AuthenticationType.LOGOUT:
      trackWithDefaults('Logout');
      reset();
      break;

    case TopicActionType.FOLLOW_FULFILLED: {
      const topic = getTopicById(state, action.payload.id);
      if (!topic) {
        break;
      }
      const { page, subpage } = event;
      let data: Partial<TrackMeta> = {
        page: page || 'Discover',
        entityType: 'Topic',
        entityId: topic.id,
        entityName: topic.name,
      };
      if (subpage) {
        data = {
          ...data,
          subpage,
        };
      }
      trackWithDefaults('Follow', data);
      break;
    }
    case TopicActionType.UNFOLLOW_FULFILLED: {
      const topic = getTopicById(state, action.payload.id);
      if (!topic) {
        break;
      }
      const { page, subpage } = event;
      let data: Partial<TrackMeta> = {
        page: page || 'Discover',
        entityType: 'Topic',
        entityId: topic.id,
        entityName: topic.name,
      };
      if (subpage) {
        data = { ...data, subpage };
      }
      trackWithDefaults('Unfollow', data);
      break;
    }

    case TopicDomainTypes.FETCH_BRIEFING_SUCCESS: {
      const topic = getTransformationMapTopic(state);
      if (!topic) {
        break;
      }

      trackWithDefaults('Generate briefing', {
        page: 'Topic detail',
        subpage: 'Briefing',
        entityType: 'Topic',
        entityId: topic.id,
        entityName: topic.name,
      });
      break;
    }

    case TransformationMapActionType.SET_OUTER_NODE: {
      const topic = getTransformationMapTopic(state);
      const secondaryTopic = getTopicById(state, action.meta.id);
      if (!topic || !secondaryTopic) {
        break;
      }

      trackWithDefaults('Select Advanced Analytics Secondary Topic', {
        entityType: 'Topic',
        entityId: topic.id,
        entityName: topic.name,
        secondaryEntityId: secondaryTopic.id,
        secondaryEntityName: secondaryTopic.name,
        secondaryEntityType: 'Topic',
      });
      break;
    }

    case CreateMapTypes.UPLOAD_FULFILLED: {
      const map = getCurrentMap(state);
      const { id } = action.payload;
      trackWithDefaults(map?.id ? 'Edit Map' : 'Create Map', {
        page: 'Create',
        entityId: id,
        entityName: map?.name,
      });
      break;
    }

    case CreateMapTypes.ADD_KEY_ISSUE:
    case CreateMapTypes.REMOVE_KEY_ISSUE:
    case CreateMapTypes.REMOVE_ALL_KEY_ISSUES: {
      const map = getCurrentMap(state);
      if (!map) {
        break;
      }
      const { keyIssueId } = action.meta || {};
      const keyIssue = getDimensionById(keyIssueId)(state);
      let trackMeta: Partial<TrackMeta> = {
        page: 'Create',
        entityId: map.id,
        entityName: map.name || '(new map)',
        entityType: 'Custom Map',
      };
      if (keyIssue) {
        trackMeta = {
          ...trackMeta,
          secondaryEntityId: keyIssueId,
          secondaryEntityName: keyIssue?.name,
          secondaryEntityType: 'Topic',
        };
      }
      const actionTypeToName: Partial<Record<CreateMapTypes, TrackEvent>> = {
        [CreateMapTypes.ADD_KEY_ISSUE]: 'Add Topic to Map',
        [CreateMapTypes.REMOVE_KEY_ISSUE]: 'Remove Topic from Map',
        [CreateMapTypes.REMOVE_ALL_KEY_ISSUES]: 'Clear Map',
      };
      const name = actionTypeToName[action.type as CreateMapTypes];
      if (name) {
        trackWithDefaults(name, trackMeta);
      }
      break;
    }

    case KeyIssuesActionType.FETCH: {
      if (action.meta.page === 'search') {
        break;
      }
      const { searchTerm } = action.meta;
      if (searchTerm) {
        trackWithDefaults('Search Key Issues', {
          page: 'Create',
          searchTerm,
        });
      }
      break;
    }

    case LanguageDomainActionType.FETCH: {
      const siteLanguage = getSiteLanguage(state);
      const siteLanguageLabel = getSiteLanguageLabel(state);

      if (!action.meta || !action.meta.initialising) {
        trackWithDefaults('Change Language', {
          entityId: siteLanguage,
          entityName: siteLanguageLabel,
          entityType: 'Language',
        });
      }
      break;
    }

    case BookmarkActionTypes.BOOKMARK_TOGGLE_FULFILLED: {
      if (!action.meta.isBookmarked) {
        break;
      }
      const bookmarkItem = action.meta.bookmark;
      trackWithDefaults('Bookmark', {
        entityId: bookmarkItem.id,
        entityName: bookmarkItem.title,
        entityType: action.meta.entityType,
      });
      break;
    }

    case ThemeType.UPDATE: {
      const theme = action.meta.mode;
      const capitalizedTheme = theme.charAt(0).toUpperCase() + theme.slice(1);
      trackWithDefaults('Change theme', { value: capitalizedTheme });
      break;
    }

    default:
      break;
  }

  next(action);
};
