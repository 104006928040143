import { COLLECTIONS_LIMIT } from 'common/constants/limit';
import { LanguageCode } from 'common/types/languageCode';
import {
  ClearSingleAction,
  CollectionActionType,
  DeleteCollectionAction,
  DeleteCollectionFailureAction,
  DeleteCollectionSuccessAction,
  EditCommunityAction,
  EditCommunityFailureAction,
  EditCommunitySuccessAction,
  FetchCollectionForViewingAction,
  FetchCollectionForViewingFailureAction,
  FetchCollectionForViewingSuccessAction,
  FetchCollectionsAction,
  FetchCollectionsFailureAction,
  FetchCollectionsSuccessAction,
  FetchCommunitiesAction,
  FetchCommunitiesFailureAction,
  FetchCommunitiesSuccessAction,
  FetchCommunityFailureAction,
  FetchCommunitySuccessAction,
  FollowCollectionFailureAction,
  FollowCollectionSuccessAction,
  UnfollowCollectionFailureAction,
  UnfollowCollectionSuccessAction,
  UpdateCollectionAction,
  UpdateCollectionFailureAction,
  UpdateCollectionSuccessAction,
  UploadImageAction,
  FetchCollectionForEditingAction,
  FetchCollectionForEditingFailureAction,
  FetchCollectionForEditingSuccessAction,
  ClearHeroImageUrlAction,
  ClearCommunityImageUrlAction,
  ClearCommunitiesAction,
} from 'domains/collection/actionTypes';
import {
  CollectionIdOnly,
  FetchCollectionsPayload,
  FetchCollectionsSuccessPayload,
  FetchCommunitiesPayload,
  FetchCommunitiesSuccessPayload,
  UpdateCollectionPayload,
  UploadImagePayload,
  UploadImageSuccessPayload,
} from 'domains/collection/types';
import { CollectionViewResponse, MappedCollectionEditResponse } from 'domains/collection/types/collectionResponses';
import { CollectionType } from 'domains/collection/types/collectionType';
import { CommunityEditRequest } from 'domains/collection/types/communityRequests';
import { FetchCommunityResponse } from 'domains/collection/types/communityResponses';

type CollectionId = string;

export const fetchCollectionForViewing = (id: CollectionId): FetchCollectionForViewingAction => ({
  type: CollectionActionType.FETCH_COLLECTION_FOR_VIEWING,
  id,
});

export const fetchCollectionForViewingSuccess = (
  response: CollectionViewResponse,
): FetchCollectionForViewingSuccessAction => ({
  type: CollectionActionType.FETCH_COLLECTION_FOR_VIEWING_SUCCESS,
  response,
});

export const fetchCollectionForViewingFailure = (id: string): FetchCollectionForViewingFailureAction => ({
  type: CollectionActionType.FETCH_COLLECTION_FOR_VIEWING_FAILURE,
  id,
});

export const fetchCollectionForEditing = (id: CollectionId): FetchCollectionForEditingAction => ({
  type: CollectionActionType.FETCH_COLLECTION_FOR_EDITING,
  id,
});

export const fetchCollectionForEditingSuccess = (
  response: MappedCollectionEditResponse,
): FetchCollectionForEditingSuccessAction => ({
  type: CollectionActionType.FETCH_COLLECTION_FOR_EDITING_SUCCESS,
  response,
});

export const fetchCollectionForEditingFailure = (id: CollectionId): FetchCollectionForEditingFailureAction => ({
  type: CollectionActionType.FETCH_COLLECTION_FOR_EDITING_FAILURE,
  id,
});

export const fetchCollections = (
  type: CollectionType,
  offset: number = 0,
  limit: number = COLLECTIONS_LIMIT,
): FetchCollectionsAction => ({
  type: CollectionActionType.FETCH_COLLECTIONS,
  payload: { limit, offset, type },
});

export const fetchCollectionsSuccess = (payload: FetchCollectionsSuccessPayload): FetchCollectionsSuccessAction => ({
  type: CollectionActionType.FETCH_COLLECTIONS_SUCCESS,
  payload,
});

export const fetchCollectionsFailure = (payload: FetchCollectionsPayload): FetchCollectionsFailureAction => ({
  type: CollectionActionType.FETCH_COLLECTIONS_FAILURE,
  payload,
});

export const followCollectionSuccess = (id: string): FollowCollectionSuccessAction => ({
  type: CollectionActionType.FOLLOW_COLLECTION_SUCCESS,
  payload: { id },
});

export const followCollectionFailure = (id: string): FollowCollectionFailureAction => ({
  type: CollectionActionType.FOLLOW_COLLECTION_FAILURE,
  payload: { id },
});

export const unfollowCollectionSuccess = (id: string): UnfollowCollectionSuccessAction => ({
  type: CollectionActionType.UNFOLLOW_COLLECTION_SUCCESS,
  payload: { id },
});

export const unfollowCollectionFailure = (id: string): UnfollowCollectionFailureAction => ({
  type: CollectionActionType.UNFOLLOW_COLLECTION_FAILURE,
  payload: { id },
});

export const deleteCollection = (payload: CollectionIdOnly): DeleteCollectionAction => ({
  type: CollectionActionType.DELETE_COLLECTION,
  payload,
});

export const deleteCollectionSuccess = (id: string): DeleteCollectionSuccessAction => ({
  type: CollectionActionType.DELETE_COLLECTION_SUCCESS,
  payload: { id },
});

export const deleteCollectionFailure = (): DeleteCollectionFailureAction => ({
  type: CollectionActionType.DELETE_COLLECTION_FAILURE,
});

export const updateCollection = (
  payload: UpdateCollectionPayload,
  siteLanguage: LanguageCode,
): UpdateCollectionAction => ({
  type: CollectionActionType.UPDATE_COLLECTION,
  payload,
  siteLanguage,
});

export const updateCollectionSuccess = (payload: UpdateCollectionPayload): UpdateCollectionSuccessAction => ({
  type: CollectionActionType.UPDATE_COLLECTION_SUCCESS,
  payload,
});

export const updateCollectionFailure = (id: string): UpdateCollectionFailureAction => ({
  type: CollectionActionType.UPDATE_COLLECTION_FAILURE,
  payload: { id },
});

export const fetchCommunitySuccess = (payload: FetchCommunityResponse): FetchCommunitySuccessAction => ({
  type: CollectionActionType.FETCH_COMMUNITY_SUCCESS,
  payload,
});

export const fetchCommunityFailure = (id: string): FetchCommunityFailureAction => ({
  type: CollectionActionType.FETCH_COMMUNITY_FAILURE,
  payload: { id },
});

export const editCommunity = (payload: CommunityEditRequest): EditCommunityAction => ({
  type: CollectionActionType.EDIT_COMMUNITY,
  payload,
});

export const editCommunitySuccess = (id: string): EditCommunitySuccessAction => ({
  type: CollectionActionType.EDIT_COMMUNITY_SUCCESS,
  payload: { id },
});

export const editCommunityFailure = (id: string): EditCommunityFailureAction => ({
  type: CollectionActionType.EDIT_COMMUNITY_FAILURE,
  payload: { id },
});

export const fetchCommunities = (payload: FetchCommunitiesPayload): FetchCommunitiesAction => ({
  type: CollectionActionType.FETCH_COMMUNITIES,
  payload,
});

export const fetchCommunitiesSuccess = (payload: FetchCommunitiesSuccessPayload): FetchCommunitiesSuccessAction => ({
  type: CollectionActionType.FETCH_COMMUNITIES_SUCCESS,
  payload,
});

export const fetchCommunitiesFailure = (): FetchCommunitiesFailureAction => ({
  type: CollectionActionType.FETCH_COMMUNITIES_FAILURE,
});

export const clearCommunities = (): ClearCommunitiesAction => ({
  type: CollectionActionType.CLEAR_COMMUNITIES,
});

export const uploadImage = (payload: UploadImagePayload): UploadImageAction => ({
  type: CollectionActionType.UPLOAD_IMAGE,
  payload,
});

export const uploadImageSuccess = (payload: UploadImageSuccessPayload) => ({
  type: CollectionActionType.UPLOAD_IMAGE_SUCCESS,
  payload,
});

export const uploadImageFailure = () => ({
  type: CollectionActionType.UPLOAD_IMAGE_FAILURE,
});

export const clearSingle = (): ClearSingleAction => ({
  type: CollectionActionType.CLEAR_SINGLE,
});

export const clearHeroImageUrl = (): ClearHeroImageUrlAction => ({
  type: CollectionActionType.CLEAR_HERO_IMAGE_URL,
});

export const clearCommunityImageUrl = (): ClearCommunityImageUrlAction => ({
  type: CollectionActionType.CLEAR_COMMUNITY_IMAGE_URL,
});
