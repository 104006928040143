import { getIsFetchingUserData, getIsUpdatingProfile } from 'entities/user/selectors';
import { RootState } from 'types';

export const getIsUploadingProfileImage = (state: RootState) => state.domains.user.isUploadingProfileImage;

export const getProfileImageUploadError = (state: RootState) => state.domains.user.profileImageUploadError;

export const getIsLoadingProfile = (state: RootState) => getIsFetchingUserData(state) || getIsUpdatingProfile(state);

// export const getLoginRegisterParams = (state: RootState) =>
//   state.domains.user.loginRegisterParams;
