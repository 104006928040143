import { TopicsMetadataPayload } from 'entities/topicsMetadata/types';
import { ErrorMeta } from 'types';

export enum TopicsMetadataActionTypes {
  FETCH = '[Topics Metadata] TOPICS_METADATA_FETCH',
  FETCH_FULFILLED = '[Topics Metadata] TOPICS_METADATA_FETCH_SUCCESS',
  FETCH_REJECTED = '[Topics Metadata] TOPICS_METADATA_FETCH_FAILURE',
}

export type FetchTopicsMetadataAction = { type: TopicsMetadataActionTypes.FETCH };

export type FetchTopicsMetadataFulfilledAction = {
  payload: TopicsMetadataPayload;
  type: TopicsMetadataActionTypes.FETCH_FULFILLED;
};

export type FetchTopicsMetadataRejectedAction = {
  meta: ErrorMeta;
  payload: { error: any };
  type: TopicsMetadataActionTypes.FETCH_REJECTED;
};

export type TopicsMetadataAction =
  | FetchTopicsMetadataAction
  | FetchTopicsMetadataFulfilledAction
  | FetchTopicsMetadataRejectedAction;
