import { SVGProps } from 'react';

import { constantColors } from 'domains/theme/constants/colors';

export const RadarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      d="M28.8 23.2h1.6v1.6h-1.6v-1.6zM35.2 23.2h1.6v1.6h-1.6v-1.6zM41.6 23.2h1.6v1.6h-1.6v-1.6zM17.6 23.2h1.6v1.6h-1.6v-1.6zM11.2 23.2h1.6v1.6h-1.6v-1.6zM4.8 23.2h1.6v1.6H4.8v-1.6zM23.2 17.6h1.6v1.6h-1.6v-1.6zM23.2 11.2h1.6v1.6h-1.6v-1.6zM23.2 4.8h1.6v1.6h-1.6V4.8zM23.2 28.8h1.6v1.6h-1.6v-1.6zM23.2 35.2h1.6v1.6h-1.6v-1.6zM23.2 41.6h1.6v1.6h-1.6v-1.6zM1.6 1.6h5.6V0H.8a.8.8 0 00-.8.8v6.4h1.6V1.6zM47.2 0h-6.4v1.6h5.6v5.6H48V.8a.8.8 0 00-.8-.8zM1.6 40.8H0v6.4a.8.8 0 00.8.8h6.4v-1.6H1.6v-5.6zM46.4 46.4h-5.6V48h6.4a.8.8 0 00.8-.8v-6.4h-1.6v5.6z"
      fill={constantColors.blue}
    />
    <path
      d="M39.842 8.16a.8.8 0 00-1.131 0L25.615 21.256a3.157 3.157 0 00-3.395.085 3.2 3.2 0 104.978 2.661 3.157 3.157 0 00-.454-1.617l3.436-3.435a7.903 7.903 0 011.078 8.382 3.155 3.155 0 00-.86-.132 3.2 3.2 0 00-3.2 3.2c.005.292.05.581.133.861a7.999 7.999 0 11.267-14.395l1.18-1.18a9.605 9.605 0 10-.653 16.96 3.2 3.2 0 104.52-4.522 9.482 9.482 0 00-1.334-10.31l3.395-3.397a14.4 14.4 0 11-22.442 1.28c.418.198.874.3 1.336.303a3.2 3.2 0 003.2-3.2 3.163 3.163 0 00-.216-1.127 14.31 14.31 0 0115.668.545l1.14-1.142a15.872 15.872 0 00-17.734-.705 3.183 3.183 0 00-4.629 4.317A16 16 0 1035.84 13.29l3.42-3.42A20.8 20.8 0 0124 44.8a20.529 20.529 0 01-12.384-4.114c.248-.456.38-.967.383-1.486a3.199 3.199 0 00-3.2-3.2 3.153 3.153 0 00-1.479.38A20.775 20.775 0 0136.825 7.642l1.139-1.139a22.372 22.372 0 00-31.84 30.947 3.193 3.193 0 004.415 4.427 22.108 22.108 0 0013.46 4.521A22.4 22.4 0 0039.843 8.16zM24 25.6a1.6 1.6 0 11-.003-3.2A1.6 1.6 0 0124 25.6zm6.399 6.4a1.6 1.6 0 110-3.2 1.6 1.6 0 010 3.2zm-16.8-20.8a1.6 1.6 0 110 3.2 1.6 1.6 0 010-3.2zm-4.8 29.6a1.6 1.6 0 110-3.2 1.6 1.6 0 010 3.2z"
      fill="currentColor"
    />
  </svg>
);
