import { SVGProps } from 'react';

export const AddItemIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
    <path
      d="M17 20h-3c-.56 0-1-.44-1-1s.44-1 1-1h3a.988.988 0 0 0 .707-.293A.993.993 0 0 0 18 17V3a.988.988 0 0 0-.293-.707A.993.993 0 0 0 17 2H3a.988.988 0 0 0-.707.293A.993.993 0 0 0 2 3v3c0 .56-.44 1-1 1s-1-.44-1-1V3c0-.406.079-.796.236-1.168.152-.36.366-.677.643-.953.276-.277.594-.49.953-.643A2.968 2.968 0 0 1 3 0h14c.406 0 .796.079 1.168.236.36.152.677.366.953.643.277.276.49.594.643.953.157.372.236.762.236 1.168v14c0 .406-.079.796-.236 1.168a2.975 2.975 0 0 1-.643.953c-.276.277-.594.491-.953.643A2.968 2.968 0 0 1 17 20z"
      fill={'currentColor'}
      transform="rotate(180 10 10)"
    />
    <path
      d="M17 20h-3c-.56 0-1-.44-1-1s.44-1 1-1h3a.988.988 0 0 0 .707-.293A.993.993 0 0 0 18 17V3a.988.988 0 0 0-.293-.707A.993.993 0 0 0 17 2H3a.988.988 0 0 0-.707.293A.993.993 0 0 0 2 3v3c0 .56-.44 1-1 1s-1-.44-1-1V3c0-.406.079-.796.236-1.168.152-.36.366-.677.643-.953.276-.277.594-.49.953-.643A2.968 2.968 0 0 1 3 0h14c.406 0 .796.079 1.168.236.36.152.677.366.953.643.277.276.49.594.643.953.157.372.236.762.236 1.168v14c0 .406-.079.796-.236 1.168a2.975 2.975 0 0 1-.643.953c-.276.277-.594.491-.953.643A2.968 2.968 0 0 1 17 20z"
      fill={'currentColor'}
    />
    <path
      d="M8 13h5m5 0h-5m0 0V8m0 5v5"
      transform="translate(-3 -3)"
      strokeLinecap={'round'}
      strokeLinejoin={'round'}
      stroke={'currentColor'}
      strokeWidth="2px"
    />
  </svg>
);
