import {
  EventType,
  FetchEventDefaultAction,
  FetchEventForIdAction,
  FetchEventForIdFulfilledAction,
  FetchEventForIdRejectedAction,
  FetchEventFulfilledAction,
  FetchEventRejectedAction,
} from 'entities/events/actionTypes';

export const fetchEventsForTopic = (payload: FetchEventDefaultAction['payload']): FetchEventDefaultAction => ({
  type: EventType.FETCH_FOR_TOPIC,
  payload,
});

export const fetchEventsForTopicSuccess = (
  payload: FetchEventFulfilledAction['payload'],
  meta: FetchEventFulfilledAction['meta'],
): FetchEventFulfilledAction => ({
  type: EventType.FETCH_FOR_TOPIC_SUCCESS,
  payload,
  meta,
});

export const fetchEventsForTopicFailure = (
  payload: FetchEventRejectedAction['payload'],
  meta: FetchEventRejectedAction['meta'],
): FetchEventRejectedAction => ({
  type: EventType.FETCH_FOR_TOPIC_FAILURE,
  payload,
  meta,
});

export const fetchEventById = (meta: FetchEventForIdAction['meta']): FetchEventForIdAction => ({
  type: EventType.FETCH_FOR_ID,
  meta,
});

export const fetchEventByIdSuccessful = (
  payload: FetchEventForIdFulfilledAction['payload'],
  meta: FetchEventForIdFulfilledAction['meta'],
): FetchEventForIdFulfilledAction => ({
  type: EventType.FETCH_FOR_ID_SUCCESS,
  payload,
  meta,
});

export const fetchEventByIdFailure = (
  payload: FetchEventForIdRejectedAction['payload'],
  meta: FetchEventForIdRejectedAction['meta'],
): FetchEventForIdRejectedAction => ({
  type: EventType.FETCH_FOR_ID_FAILURE,
  payload,
  meta,
});
