import { saveAs } from 'file-saver';
import { put } from 'redux-saga/effects';

import { fileApiFetch } from 'common/utilities';
import { activateModal } from 'domains/modal/actions';
import { fetchLegacyTopicBriefingFailure, fetchLegacyTopicBriefingSuccess } from 'domains/topic/actions';
import { FetchLegacyTopicBriefingAction } from 'domains/topic/actionTypes';

export function* fetchLegacyTopicBriefingSaga(action: FetchLegacyTopicBriefingAction) {
  try {
    const topicBriefing: Blob = yield fileApiFetch(`topic/${action.payload.id}/briefing`, {
      extraHeaders: { Accept: 'application/pdf' },
    });
    saveAs(topicBriefing, `Dynamic briefing - ${action.payload.name}.pdf`);
    yield put(fetchLegacyTopicBriefingSuccess());
  } catch (error) {
    yield put(fetchLegacyTopicBriefingFailure({ error }, { logoutOnAuthError: true }));
    yield put(activateModal({ id: 'Briefing Error' }));
  }
}
