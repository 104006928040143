import { IncidentAction, IncidentTypes } from 'domains/incidents/constants/actionTypes';
import { IncidentState, IncidentComponent } from 'domains/incidents/types';

export const initialState: IncidentState = {
  unresolved: {
    isFetching: false,
    data: [],
    error: false,
  },
  dismissed: [],
};

const isAcceptableImpact = (impact?: string) => impact && ['major', 'critical'].includes(impact.toLowerCase());

const impactSeverity: Record<string, number> = {
  major: 1,
  critical: 2,
};

const isAcceptableComponents = (components: IncidentComponent[]) =>
  components.some(
    ({ id }) =>
      id &&
      [
        process.env.REACT_APP_STRATEGIC_INTELLIGENCE_STATUS_PAGE_ID,
        process.env.REACT_APP_PAYMENT_PORTAL_STATUS_PAGE_ID,
      ].includes(id),
  );

export const incidentsReducer = (state: IncidentState = initialState, action: IncidentAction) => {
  switch (action.type) {
    case IncidentTypes.FETCH_UNRESOLVED_SUCCESS: {
      const acceptedIncidents = action.payload
        .filter(
          ({ impact, impact_override, components }) =>
            isAcceptableImpact(impact_override || impact) && isAcceptableComponents(components),
        )
        .map(({ updated_at, incident_updates, impact, impact_override, ...rest }) => ({
          ...rest,
          impact: (impact_override || impact).toLowerCase(),
          updatedAt: new Date(updated_at).getTime() / 1000,
          incidentUpdates: incident_updates.map(({ display_at, ...update }) => ({
            ...update,
            displayAt: new Date(display_at).getTime() / 1000,
          })),
        }));
      acceptedIncidents.sort((inc1, inc2) => {
        const severityDiff = (impactSeverity[inc2.impact] || 0) - (impactSeverity[inc1.impact] || 0);
        return severityDiff !== 0 ? severityDiff : inc2.updatedAt - inc1.updatedAt;
      });
      const acceptedIncidentIds = acceptedIncidents.map(({ id }) => id);
      return {
        ...state,
        unresolved: {
          ...state.unresolved,
          isFetching: false,
          data: acceptedIncidents,
          error: false,
        },
        dismissed: state.dismissed.filter((dismissedId) => acceptedIncidentIds.includes(dismissedId)),
      };
    }
    case IncidentTypes.FETCH_UNRESOLVED:
      return {
        ...state,
        unresolved: { ...state.unresolved, isFetching: true, error: false },
      };

    case IncidentTypes.FETCH_UNRESOLVED_FAILURE:
      return {
        ...state,
        unresolved: { ...state.unresolved, isFetching: false, error: true },
      };

    case IncidentTypes.DISMISS_INCIDENT:
      return {
        ...state,
        dismissed: [...state.dismissed, action.payload.id],
      };

    default:
      return state;
  }
};
