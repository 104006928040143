import {
  TopicFetchContentMetaAction,
  TopicFetchContentMetaFailureAction,
  TopicFetchContentMetaSuccessAction,
  TopicContentMetaTypes,
} from 'domains/topicSearchFilter/actionTypes';

export const fetchTopicContentMeta = (): TopicFetchContentMetaAction => ({
  type: TopicContentMetaTypes.FETCH_CONTENT_META,
});

export const fetchTopicContentMetaSuccess = (
  payload: TopicFetchContentMetaSuccessAction['payload'],
): TopicFetchContentMetaSuccessAction => ({
  type: TopicContentMetaTypes.FETCH_CONTENT_META_SUCCESS,
  payload,
});

export const fetchTopicContentMetaFailure = (
  payload: TopicFetchContentMetaFailureAction['payload'],
  meta: TopicFetchContentMetaFailureAction['meta'],
): TopicFetchContentMetaFailureAction => ({
  type: TopicContentMetaTypes.FETCH_CONTENT_META_FAILURE,
  payload,
  meta,
});
