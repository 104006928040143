import { put, select } from 'redux-saga/effects';

import { formDataUploadApiFetch, apiFetch } from 'common/utilities/apiFetch';
import {
  fetchTopicContentList,
  uploadTopicContentFulfilled,
  uploadTopicContentRejected,
} from 'domains/customContentAndSources/actions';
import { UploadTopicContentAction } from 'domains/customContentAndSources/actionTypes';
import { getInProgressCustomDocument, getScrapedCustomArticle } from 'domains/customContentAndSources/selectors';
import { NewCustomKnowledge, NewDocument } from 'domains/customContentAndSources/types';
import { deactivateModal } from 'domains/modal/actions';
import { getActiveModalId } from 'domains/modal/selectors';
import { ModalContentType } from 'domains/modal/types';
import { FetchError } from 'types';

export function* uploadTopicContentSaga({ payload }: UploadTopicContentAction) {
  const { topicId } = payload;
  const inProgressDocument: NewDocument | undefined = yield select(getInProgressCustomDocument);
  const scrapedArticle: NewCustomKnowledge | undefined = yield select(getScrapedCustomArticle);
  const apiUrl = `topic/${payload.topicId}/attachment`;
  try {
    let updateBody = undefined;
    if (inProgressDocument) {
      if (inProgressDocument.id) {
        updateBody = {
          filename: inProgressDocument.filename,
          id: inProgressDocument.id,
        };
        // Make a separate body to send to the API because the api likes
        // "file_name" and the state uses "filename"
        const body = { id: updateBody.id, file_name: updateBody.filename };
        yield apiFetch(`${apiUrl}/${inProgressDocument.id}`, {
          body,
          method: 'PATCH',
        });
      } else {
        const body = new FormData();
        if (inProgressDocument.file) {
          body.append('attachment', inProgressDocument.file);
        }
        if (inProgressDocument.filename) {
          body.append('file_name', inProgressDocument.filename);
        }
        yield formDataUploadApiFetch(apiUrl, {
          body,
          method: 'PUT',
        });
      }
    } else if (scrapedArticle) {
      if (scrapedArticle.id) {
        updateBody = {
          id: scrapedArticle.id,
          title: scrapedArticle.title,
          summary: scrapedArticle.summary,
          publisher: scrapedArticle.publisher,
        };
        yield apiFetch(`${apiUrl}/${scrapedArticle.id}`, {
          body: updateBody,
          method: 'PATCH',
        });
      } else {
        const body = {
          title: scrapedArticle.title,
          interactive_link: scrapedArticle.interactive_link,
          embedded_media: scrapedArticle.embedded_media,
          image: scrapedArticle.image,
          summary: scrapedArticle.summary,
          publisher: scrapedArticle.publisher,
          link: scrapedArticle.link,
          language: scrapedArticle.language,
          media: scrapedArticle.media,
          time: scrapedArticle.time,
        };
        yield apiFetch(apiUrl, { body, method: 'PUT' });
      }
    }

    yield put(fetchTopicContentList({ topicId }));
    yield put(uploadTopicContentFulfilled({ topicId, updateBody }));
    const currentModal: ModalContentType | undefined = yield select(getActiveModalId);
    if (currentModal === 'Add Custom Content') {
      yield put(deactivateModal());
    }
  } catch (error) {
    yield put(uploadTopicContentRejected({ error: error as FetchError }, { logoutOnAuthError: true, topicId }));
  }
}
