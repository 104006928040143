import { useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { lineTruncationStyle } from 'common/styles';
import { useTranslations } from 'domains/language/useTranslations';
import { UpcomingEventListItem as MembershipEventListItem } from 'domains/membershipEvents/components/MembershipEventListItem';
import { MembershipEvent } from 'domains/membershipEvents/types';
import { getTopicsFilterSelectedIds } from 'domains/monitor/filter/selectors';
import { useMedia } from 'domains/viewport/useMedia';

const StyledTitle = styled.h2`
  margin: 0;
  margin-bottom: 15px;
  font-size: 1.6rem;

  ${lineTruncationStyle(1)};
`;

type MembershipEventListProps = {
  isPending: boolean;
  items: MembershipEvent[];
  selectedId?: string;
  showSeparators: boolean;
};

export const MembershipEventList = ({ items, selectedId, isPending, showSeparators }: MembershipEventListProps) => {
  const selectedTopics = useSelector(getTopicsFilterSelectedIds);
  const { textDictionary } = useTranslations();
  const isList = useMedia({ small: true, medium: true }, false);

  if (items.length === 0 && !isPending) {
    return (
      <span>
        {
          textDictionary[
            selectedTopics.length === 0
              ? 'app.monitor.empty_message.emerging_themes'
              : 'app.monitor.empty_message_filter'
          ]
        }
      </span>
    );
  }

  const upcomingEvents = items.filter((event) => event.is_upcoming);
  const pastEvents = items.filter((event) => !event.is_upcoming);
  return (
    <>
      {upcomingEvents.map((item) => (
        <MembershipEventListItem
          key={item.id}
          isSelected={!isList && selectedId === item.id}
          event={item}
          link={`/membership-events/${item.id}`}
        />
      ))}
      {pastEvents.length !== 0 && (
        <>
          {showSeparators && <StyledTitle>{textDictionary['app.event.past_events']}</StyledTitle>}
          {pastEvents.map((item) => (
            <MembershipEventListItem
              key={item.id}
              isSelected={!isList && selectedId === item.id}
              event={item}
              link={`/membership-events/${item.id}`}
            />
          ))}
        </>
      )}
    </>
  );
};
