import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { fetchWhatsNewFailure, fetchWhatsNewSuccess } from 'domains/whatsNew/actions';
import { WhatsNewPayload } from 'domains/whatsNew/types';
import { isFetchError } from 'TypeCheckMethods';

export function* fetchWhatsNewSaga() {
  try {
    const marketingPackage: WhatsNewPayload = yield apiFetch('marketing_package');
    yield put(fetchWhatsNewSuccess(marketingPackage));
  } catch (error) {
    if (isFetchError(error)) {
      yield put(fetchWhatsNewFailure({ error }));
    }
  }
}
