import { SVGProps } from 'react';

export const StarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <path
      d="M99.8 44.181a.836.836 0 0 1-.39-.1l-4.038-2.123-4.038 2.123a.836.836 0 0 1-1.213-.881l.771-4.5-3.267-3.184a.836.836 0 0 1 .463-1.426l4.512-.65 2.019-4.091a.836.836 0 0 1 1.5 0l2.019 4.091 4.515.656a.836.836 0 0 1 .463 1.426l-3.267 3.185.771 4.5a.838.838 0 0 1-.823.978zm-4.428-3.313a.837.837 0 0 1 .389.1l3.736 1.964-.714-4.16a.837.837 0 0 1 .241-.74l3.022-2.946-4.176-.607a.836.836 0 0 1-.629-.457l-1.871-3.789-1.87 3.785a.836.836 0 0 1-.63.457l-4.176.607 3.022 2.946a.836.836 0 0 1 .24.74l-.713 4.16 3.735-1.964a.836.836 0 0 1 .389-.1zm7.131-5.719z"
      transform="translate(-87.369 -28.883)"
      fill={'currentColor'}
    />
  </svg>
);
