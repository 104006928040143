import {
  EmergingThemesDefaultAction,
  EmergingThemesFulfilledAction,
  EmergingThemesRejectedAction,
  EmergingThemesActionType,
} from 'domains/monitor/emergingThemes/actionTypes';

export const fetchEmergingThemes = (meta: EmergingThemesDefaultAction['meta']): EmergingThemesDefaultAction => ({
  type: EmergingThemesActionType.FETCH,
  meta,
});

export const fetchEmergingThemesSuccess = (
  meta: EmergingThemesFulfilledAction['meta'],
  payload: EmergingThemesFulfilledAction['payload'],
): EmergingThemesFulfilledAction => ({
  type: EmergingThemesActionType.FETCH_FULFILLED,
  meta,
  payload,
});

export const fetchEmergingThemesFailure = (
  meta: EmergingThemesRejectedAction['meta'],
): EmergingThemesRejectedAction => ({
  type: EmergingThemesActionType.FETCH_REJECTED,
  meta,
});
