import {
  TransformationMapActionType,
  TransformationMapSetTopicAction,
  TransformationMapSetEditModeAction,
  TransformationMapSetInnerNodeAction,
  TransformationMapUpdateTopicAction,
} from 'domains/transformationMap/actionTypes';

export const setTransformationMapTopic = (
  meta?: TransformationMapSetTopicAction['meta'],
): TransformationMapSetTopicAction => ({
  type: TransformationMapActionType.SET_TOPIC,
  meta,
});

export const updateTransformationMapTopic = (
  meta: TransformationMapUpdateTopicAction['meta'],
): TransformationMapUpdateTopicAction => ({
  type: TransformationMapActionType.UPDATE_TOPIC,
  meta,
});

export const setTransformationMapEditMode = (
  meta: TransformationMapSetEditModeAction['meta'],
): TransformationMapSetEditModeAction => ({
  type: TransformationMapActionType.SET_EDIT_MODE,
  meta,
});

export const setTransformationMapInnerNode = (
  meta: TransformationMapSetInnerNodeAction['meta'],
): TransformationMapSetInnerNodeAction => ({
  type: TransformationMapActionType.SET_INNER_NODE,
  meta,
});
