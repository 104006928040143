import { memo } from 'react';
import { css, styled } from 'styled-components';

import { PlayIconCircled, SourceLabel, SectionTitleUnderlined } from 'common/components';
import { ListImage, ListLink } from 'common/components/lists/ListLink';
import { SmallListItem } from 'common/components/lists/SmallListItem';
import { ListItemType } from 'common/components/lists/types';
import { breakWordStyle, lineTruncationStyle } from 'common/styles';
import { useInternalLink } from 'common/utilities';
import { CustomKnowledge } from 'domains/customContentAndSources/types';
import { ChevronLink } from 'domains/landing/components/shared';
import { KnowledgeButtons } from 'domains/publication/components/KnowledgeButtons';
import { useMedia } from 'domains/viewport/useMedia';
import { Knowledge } from 'entities/knowledge/types';

const StyledTitle = styled(SectionTitleUnderlined)`
  & > h2 {
    font-weight: bold;
    font-size: 1.1rem;
    line-height: 1.5rem;
  }
  margin-top: 8px;
  margin-bottom: 16px;

  ${lineTruncationStyle(2)}
`;

const StyledSummary = styled.p`
  font-size: 0.9rem;
  line-height: 1.4rem;
  margin: 0;
  flex-grow: 1;
  color: ${({ theme }) => theme.colors.clusterCaptionText};

  ${breakWordStyle}
  ${lineTruncationStyle(3)};
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  margin: 0;
`;

const StyledDarkenLayer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.25);
`;

// TODO theming: icon is not resizable right now
const StyledPlayIcon = styled(PlayIconCircled)<{ type?: ListItemType }>`
  position: absolute;
  width: 44px;
  height: 44px;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${({ type }) =>
    type === 'large' &&
    css`
      width: 80px;
      height: 80px;
    `}
`;

const StyledImage = styled(ListImage)`
  border-radius: 10px;
  width: 120px;
  min-width: 120px;
  height: 90px;
`;

const StyledRow = styled.div`
  display: flex;
  height: 100%;
`;

const StyledKnowledgeButtons = styled(KnowledgeButtons)`
  margin-top: 1rem;
`;

type ArticleItemProps = {
  isSelected?: boolean;
  isVideo?: boolean;
  knowledge: Knowledge | CustomKnowledge;
  link: string;
  onClick?: () => void;
  shareLink: string;
  type?: ListItemType;
};

export const ArticleItem = memo(
  ({ isSelected = false, isVideo = false, knowledge, link, onClick, shareLink, type = 'large' }: ArticleItemProps) => {
    const { title, summary, images, source, time } = knowledge;
    const linkLocation = useInternalLink(link);
    const isExtraLarge = useMedia({ small: false, medium: false, large: false }, true);

    if (type === 'small') {
      return (
        <SmallListItem
          title={title}
          isSelected={isSelected}
          image={source?.images?.x_small}
          description={summary}
          subElement={source && <SourceLabel source={source} date={time} type="small" hideImage={true} />}
        />
      );
    }

    const isWide = isExtraLarge && type === 'wide';
    const image = isVideo ? (
      <div style={{ position: 'relative', marginRight: '1rem' }}>
        <StyledImage src={images?.x_small} />
        <StyledDarkenLayer />
        <StyledPlayIcon />
      </div>
    ) : (
      <div style={{ marginRight: '1rem' }}>
        <StyledImage src={images?.x_small} />
      </div>
    );

    return (
      <StyledRow>
        {isWide && image}
        <div>
          <ListLink to={linkLocation} onClick={onClick} isSelected={isSelected}>
            {source && <SourceLabel source={source} date={time} type="small" />}
            <StyledTitle>{title}</StyledTitle>
            <StyledContent>
              {!isWide && image}
              <StyledSummary>{summary}</StyledSummary>
            </StyledContent>
          </ListLink>
          {(type === 'large' || isWide) && <StyledKnowledgeButtons knowledge={knowledge} shareLink={shareLink} />}
        </div>
        {isWide && <ChevronLink to={linkLocation} />}
      </StyledRow>
    );
  },
);

ArticleItem.displayName = 'ArticleItem';
