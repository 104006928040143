import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { StyledModalText, Link, UIButton } from 'common/components';
import { LinkButton } from 'common/components/button/LinkButton';
import { pushHistory } from 'common/utilities';
import { acceptMapShareInvite } from 'domains/createShareSettings/actions';
import { useTranslations } from 'domains/language/useTranslations';
import { deactivateModal } from 'domains/modal/actions';
import { ModalHeader } from 'domains/modal/components/ModalHeader';
import { getModalParams } from 'domains/modal/selectors';
import { removeNotification, updateNotification } from 'domains/notifications/actions';
import { getNotification } from 'domains/notifications/selectors';
import { NotificationType } from 'domains/notifications/types';

const StyledMessage = styled(StyledModalText)`
  margin: 0 0 1rem 0;
`;

const StyledButton = styled(UIButton)`
  margin-bottom: 1rem;
`;

const hasExpired = (expirationDate?: number) =>
  expirationDate ? new Date().getTime() > new Date(expirationDate * 1000).getTime() : false;

type ActionItem = { href?: string; key: string; onClick?: () => void; title: string; to?: string };

const supportedEntityTypes: NotificationType[] = ['topic', 'event', 'knowledge', 'cluster'];

export const NotificationDetailModal = () => {
  const { textDictionary, interpolate } = useTranslations();
  const dispatch = useDispatch();

  const { id } = useSelector(getModalParams);
  const notification = useSelector(getNotification(id));

  const notificationId = notification ? notification.id : undefined;

  useEffect(() => {
    if (notificationId) {
      dispatch(updateNotification({ id: notificationId, has_been_opened: true }));
    }
  }, [dispatch, notificationId]);

  if (!notification) {
    return null;
  }
  const { title, type, message, context, isUpdatePending, mapShareInviteError, inviteExpired } = notification;

  const removeNotificationHandler = () => {
    if (id) {
      dispatch(removeNotification(id));
    }
  };

  const mapShareAction = () => {
    const { has_accepted, invite_token, topic } = context;
    if (has_accepted) {
      pushHistory(`/topics/${topic}`);
      dispatch(deactivateModal());
    } else if (invite_token && id) {
      dispatch(acceptMapShareInvite({ token: invite_token, id }));
    }
  };

  const closeModal = () => dispatch(deactivateModal());

  const getEntityHref = (type: NotificationType, id?: string) => {
    if (!id) {
      return;
    }
    switch (type) {
      case 'topic':
        return `/topics/${id}`;
      case 'event':
        return `/membership-events/${id}`;
      case 'cluster':
        return `/monitor/emerging-themes/${id}`;
      case 'knowledge':
        return `/monitor/latest-knowledge/${id}`;
    }
  };

  let actions: ActionItem[] = [];
  const tokenHasExpired = inviteExpired || hasExpired(context?.expiration_date);
  if (type === 'map_share' && context && !tokenHasExpired) {
    actions = [
      {
        key: 'map_share_action',
        title: context.has_accepted
          ? textDictionary['app.topic.view_transformation_map']
          : textDictionary['app.notifications.map_invite.accept_invitation'],
        onClick: mapShareAction,
      },
    ];
  }
  if (context?.link) {
    actions = [
      ...actions,
      {
        key: 'map_open_link',
        title: textDictionary['app.topic.actions.open'],
        href: context.link,
      },
    ];
  }
  if (supportedEntityTypes.includes(type) && context.entity_id) {
    actions = [
      ...actions,
      {
        key: `${type}action`,
        title: textDictionary['app.topic.actions.open'],
        to: getEntityHref(type, context.entity_id),
        onClick: closeModal,
      },
    ];
  }
  const supportEmail = (email: string) => <Link href={`mailto:${email}`}>{email}</Link>;

  let errorMessage = undefined;
  if (tokenHasExpired) {
    errorMessage = textDictionary['app.notifications.map_invite.expired_token'];
  } else if (mapShareInviteError) {
    errorMessage = interpolate(textDictionary['app.topic.follow.error_message'], {
      supportEmail,
    });
  }

  return (
    <>
      <ModalHeader title={title || textDictionary['app.notifications.title']} />
      <StyledMessage>{errorMessage || message}</StyledMessage>
      {actions.map((item) => (
        <LinkButton href={item.href} to={item.to} key={item.key}>
          <StyledButton onClick={item.onClick} isLoading={isUpdatePending} variant={'filled'} fullwidth>
            {item.title}
          </StyledButton>
        </LinkButton>
      ))}
      <UIButton variant="outlined" onClick={removeNotificationHandler} fullwidth>
        {textDictionary['app.notification.delete_notification']}
      </UIButton>
    </>
  );
};
