import { ReactElement } from 'react';
import { css, styled } from 'styled-components';

const StyledText = styled.div`
  font-size: 0.8rem;
  text-decoration: none;
  padding-top: 5px;
`;

const StyledIconContainer = styled.div`
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  transition: background-color 0.3s;
`;

const buttonStyle = css<{ disabled?: boolean }>`
  padding: 0;
  background: none;
  cursor: pointer;
  text-align: center;
  border: none;
  width: 4rem;
  color: ${({ theme }) => theme.colors.textFade};
  position: relative;
  transition: color 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ disabled, theme }) =>
    disabled
      ? css`
          cursor: not-allowed;
        `
      : css`
          &:hover,
          &:focus-visible {
            color: ${theme.colors.textBright};
          }
          &:hover ${StyledIconContainer}, &:focus-visible ${StyledIconContainer} {
            background-color: ${theme.colors.activeButtonHoverBackground};
          }
        `}
`;

const StyledProLabel = styled.div`
  position: absolute;
  top: -10px;
  line-height: 20px;
  padding: 0 5px;
  border-radius: 5px;
  background: ${({ theme }) => theme.colors.proLabelBackground};
  color: ${({ theme }) => theme.colors.proLabelText};
  font-weight: bold;
  left: 50%;
  transform: translateX(-50%);
`;

const StyledAnchor = styled.a<{ disabled?: boolean }>`
  ${buttonStyle}
`;
const StyledButton = styled.button<{ disabled?: boolean }>`
  ${buttonStyle}
`;

type ActionButtonItemProps = {
  advertisementLabelText?: string;
  className?: string;
  dataTestId?: string;
  dataTitle?: string;
  href?: string;
  icon: ReactElement;
  isPending?: boolean;
  label: string;
  onClick?: () => void;
};

export const ActionButtonItem = ({
  href,
  label,
  icon,
  dataTitle,
  onClick,
  isPending,
  dataTestId,
  advertisementLabelText = '',
}: ActionButtonItemProps) =>
  href ? (
    <StyledAnchor href={href} target="_blank" disabled={isPending} data-test-id={dataTestId} data-title={dataTitle}>
      {advertisementLabelText && <StyledProLabel>{advertisementLabelText}</StyledProLabel>}
      <StyledIconContainer>{icon}</StyledIconContainer>
      <StyledText>{label}</StyledText>
    </StyledAnchor>
  ) : (
    <StyledButton disabled={isPending} data-test-id={dataTestId} onClick={onClick} data-title={dataTitle}>
      {advertisementLabelText && <StyledProLabel>{advertisementLabelText}</StyledProLabel>}
      <StyledIconContainer>{icon}</StyledIconContainer>
      <StyledText>{label}</StyledText>
    </StyledButton>
  );
