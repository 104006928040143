import { SVGProps } from 'react';

export const FolderIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      d="M8.77 0h16.615C36.46 0 42 5.647 42 16.941v28.235C42 47.06 41.077 48 39.23 48H8.77C6.922 48 6 47.059 6 45.176V2.825C6 .94 6.923 0 8.77 0z"
      fill="currentColor"
    />
  </svg>
);
