import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { UIButton } from 'common/components';
import { Setter } from 'common/types/setter';
import placeholderImageUrl from 'domains/about/images/placeholder_image5.png';
import { selectCollections } from 'domains/collection/selectors';
import { useTranslations } from 'domains/language/useTranslations';
import { activateModal } from 'domains/modal/actions';

const StyledContainer = styled.div<{ error: boolean }>`
  max-width: 67.5rem;
  position: relative;
  width: 100%;

  &::after {
    border: 2px solid ${({ error, theme }) => (error ? theme.colors.error : 'rgba(0, 0, 0, 0.5)')};
    border-radius: 5px;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    content: '';
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

const StyledImage = styled.img`
  aspect-ratio: 2 / 1;
  border-radius: 5px;
  display: block;
  max-width: 67.5rem;
  object-fit: cover;
  object-position: center;
  position: relative;
  width: 100%;
`;

const StyledButton = styled(UIButton)`
  border-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.white};
  left: 50%;
  outline: ${({ error, theme }) => (error ? `2px solid ${theme.colors.error}` : 'none')};
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  &:focus-visible,
  &:hover {
    border-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.white};
  }
`;

type BackgroundImageTabProps = { error: boolean; image?: string; setImage: Setter<string | undefined> };

export const BackgroundImageTab = ({ error, image, setImage }: BackgroundImageTabProps) => {
  const dispatch = useDispatch();
  const { hero } = useSelector(selectCollections).uploadedImageUrls;
  const { textDictionary } = useTranslations();

  useEffect(() => (hero === undefined ? undefined : setImage(hero)), [hero, setImage]);

  const onClick = () =>
    dispatch(activateModal({ id: 'Collection Hero Image Picker', params: { setHeroImageUrl: setImage } }));

  return (
    <StyledContainer error={error}>
      <StyledImage src={image ?? placeholderImageUrl} />
      <StyledButton onClick={onClick} variant={image === undefined ? 'filled' : 'outlined'}>
        {textDictionary[`app.create.collection.${image === undefined ? 'add_image' : 'change_image'}`]}
      </StyledButton>
    </StyledContainer>
  );
};
