import {
  KnowledgeDataActionTypes,
  KnowledgeDataFetchForTopicPayload,
  KnowledgeDataFetchForTopicAction,
  KnowledgeDataFetchForTopicFailureAction,
  KnowledgeDataFetchForTopicSuccessAction,
} from 'entities/knowledgeData/actionTypes';

export const fetchKnowledgeDataForTopic = (
  payload: KnowledgeDataFetchForTopicPayload,
): KnowledgeDataFetchForTopicAction => ({
  type: KnowledgeDataActionTypes.FETCH_FOR_TOPIC,
  payload,
});

export const fetchKnowledgeDataForTopicSuccess = (
  payload: KnowledgeDataFetchForTopicSuccessAction['payload'],
  meta: KnowledgeDataFetchForTopicSuccessAction['meta'],
): KnowledgeDataFetchForTopicSuccessAction => ({
  type: KnowledgeDataActionTypes.FETCH_FOR_TOPIC_SUCCESS,
  payload,
  meta,
});

export const fetchKnowledgeDataForTopicFailure = (
  payload: KnowledgeDataFetchForTopicFailureAction['payload'],
  meta: KnowledgeDataFetchForTopicFailureAction['meta'],
): KnowledgeDataFetchForTopicFailureAction => ({
  type: KnowledgeDataActionTypes.FETCH_FOR_TOPIC_FAILURE,
  payload,
  meta,
});
