import { useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { ChevronIcon, SettingsIcon } from 'common/components';
import { breakpoints } from 'common/styles';
import { getIsAuthenticated } from 'domains/authentication/selectors';
import { useTranslations } from 'domains/language/useTranslations';
import { Dropdown } from 'domains/navigation/components/Dropdown';
import { NavigationIconContainer } from 'domains/navigation/components/IconContainer';
import { AboutButton } from 'domains/navigation/components/settings/AboutButton';
import { CoachmarkButton } from 'domains/navigation/components/settings/CoachmarkButton';
import { ContactButton } from 'domains/navigation/components/settings/ContactButton';
import { EmbedMapButton } from 'domains/navigation/components/settings/EmbedMapButton';
import { FullScreenButton } from 'domains/navigation/components/settings/FullscreenButton';
import { LanguageButton } from 'domains/navigation/components/settings/LanguageButton';
import { ResourcesButton } from 'domains/navigation/components/settings/ResourcesButton';
import { TermsButton } from 'domains/navigation/components/settings/TermsButton';
import { ThemeButton } from 'domains/navigation/components/settings/ThemeButton';
import { WhatsNewButton } from 'domains/navigation/components/settings/WhatsNewButton';
import { TopBarItem } from 'domains/navigation/components/TopBarItem';
import { showWhatsNewIcon, showWhatsNewIconBadge } from 'domains/whatsNew/selectors';

const StyledContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  margin: 0;
  position: relative;
`;

const StyledSettingsIcon = styled(SettingsIcon)`
  width: 24px;
  height: 24px;
  position: relative;
  color: ${({ theme }) => theme.colors.topHeaderBarText};
`;

const StyledChevronIcon = styled(ChevronIcon)`
  width: 16px;
  height: 16px;
  color: ${({ theme }) => theme.colors.topHeaderBarText};
  transform: rotate(90deg);
  margin-top: 5px;

  @media ${breakpoints.large} {
    margin-top: 0;
    margin-left: 10px;
    width: 10px;
    height: 10px;
  }
`;

const StyledTopBarItem = styled(TopBarItem)`
  @media ${breakpoints.large} {
    margin-right: 0;
    padding: 0 1.5rem;
  }
`;

const StyledText = styled.p`
  margin: 0;
  display: inline-block;

  @media ${breakpoints.large} {
    display: none;
  }
`;

const StyledNotificationBadge = styled.div`
  margin-right: 0.5rem;
  width: 8px;
  height: 8px;
  opacity: 1;
  background-color: ${({ theme }) => theme.colors.red};
  border-radius: 50%;
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
`;

type SettingsButtonProps = { className?: string; onClick?: () => void };

const defaultList = [<FullScreenButton key="fullscreen" />, <LanguageButton key="language" />];

const usefulLinks = [
  <EmbedMapButton key="embed" />,
  <AboutButton key="about" />,
  <TermsButton key="terms" />,
  <ContactButton key="contact" />,
  <ThemeButton key="theme" />,
  <ResourcesButton key="resources" />,
];

export const SettingsButton = ({ className, onClick }: SettingsButtonProps) => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const displayWhatsNewIcon = useSelector(showWhatsNewIcon);
  const showBadge = useSelector(showWhatsNewIconBadge);
  const { textDictionary } = useTranslations();

  const button = (
    <StyledTopBarItem>
      <StyledRow>
        <NavigationIconContainer>
          <StyledContainer className={className}>
            {showBadge && <StyledNotificationBadge />}
            <StyledSettingsIcon />
          </StyledContainer>
        </NavigationIconContainer>
        <StyledText>{textDictionary['app.settings.title']}</StyledText>
      </StyledRow>
      <StyledChevronIcon />
    </StyledTopBarItem>
  );

  let list = defaultList;

  if (isAuthenticated) {
    list = [...list, <CoachmarkButton key="coachmark" />];
  }

  if (displayWhatsNewIcon) {
    list = [...list, <WhatsNewButton key="whats-new" />];
  }

  list = [...list, ...usefulLinks];

  return (
    <Dropdown button={button} alignment="right" onClick={onClick}>
      {list}
    </Dropdown>
  );
};
