import { memo, MouseEvent, PropsWithChildren, ReactElement } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { css, styled } from 'styled-components';

import { ArrowTileIcon } from 'common/components/icons';
import { breakpoints, breakWordStyle, lineTruncationStyle } from 'common/styles';
import { TopicImage } from 'domains/topic/components/TopicImage';

const ROUNDNESS = 8;

const StyledShadow = styled.div`
  border-radius: ${ROUNDNESS}px;
  box-shadow: ${({ theme }) => theme.shadows.tile};
`;

type LinkProps = PropsWithChildren & {
  className?: string;
  onClick?: (event: MouseEvent<HTMLAnchorElement | HTMLDivElement>) => void;
  to?: string;
};
const Link = ({ to, ...rest }: LinkProps) => (to === undefined ? <div {...rest} /> : <RouterLink {...rest} to={to} />);

const StyledLink = styled(Link)`
  display: block;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: ${ROUNDNESS}px;
  text-decoration: none;
  cursor: ${({ onClick, to }) => (onClick === undefined && to === undefined ? 'default' : 'pointer')};
  height: 170px;
  /* Fixes border radius on hover issue in safari */
  mask-image: -webkit-radial-gradient(white, black);
  color: white;
  background: linear-gradient(to bottom, #3a3b53 0%, #1a1b33 100%);

  @media ${breakpoints.medium} {
    height: 220px;
  }
`;

const StyledBackgroundImage = styled(TopicImage)`
  position: absolute;
  top: 0;
  left: 0;
  transition:
    opacity 0.375s ease,
    transform 0.375s ease;
  transform: translate3d(0, 0, 0) scale(1);
  border-radius: ${ROUNDNESS}px;
  opacity: 0.7;

  @media (hover: hover) {
    ${StyledLink}:hover & {
      transform: translate3d(0, 0, 0) scale(1.3);
      opacity: 0.3;
    }
  }
`;

const StyledContent = styled.div`
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  padding-right: 4rem;

  @media (hover: none) {
    padding-right: 1rem;
  }

  /*
    iOS Safari (13 and below? Some devices on 14 also have the issue) seems
    to render the image on top of the content and results in translucent text,
    despite the HTML ordering that states otherwise. We tell it off here by
    forcing the GPU to do the work instead with the following.
  */
  transform: translate3d(0, 0, 0);
`;

const StyledTitle = styled.h4<{ numberOfLines: number }>`
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
  opacity: 1;
  transition: opacity 0.4s ease;

  ${breakWordStyle}
  ${({ numberOfLines }) => lineTruncationStyle(numberOfLines)};

  @media ${breakpoints.medium} {
    opacity: 0.9;
    font-size: 1.5rem;
  }

  ${StyledLink}:hover & {
    opacity: 1;
  }
`;

const StyledTitleIcon = styled(ArrowTileIcon)`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  width: 17px;
  height: 17px;
  border-radius: 30px;
  padding: 9px;
  opacity: 0;
  transform: scale(0) rotate(180deg);
  transition: all 0.3s ease;
  border: 1px solid white;

  polygon {
    transform: translate3d(100%, 0, 0);
    transition: all 0.3s 0.2s ease;
  }

  ${StyledLink}:hover & {
    opacity: 1;
    transform: scale(1) rotate(180deg);

    polygon {
      transform: translate3d(0%, 0, 0);
    }
  }
`;

const StyledHiddenContent = styled.div`
  opacity: 0;
  transform: translate3d(0, 25%, 0);
  transition: all 0.3s ease;

  @media (hover: hover) {
    ${StyledLink}:hover & {
      opacity: 1;
      transform: translate3d(0, 0%, 0);
      transition: all 0.3s 0.2s ease;
    }
  }

  @media (hover: none) {
    opacity: 1;
    transform: translate3d(0, 0%, 0);
  }
`;

const StyledType = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 0rem;
  font-size: 1rem;
  font-weight: 600;
  opacity: 1;
  transition: all 0.3s 0.2s ease;
  ${lineTruncationStyle(1)};

  ${StyledLink}:hover & {
    transition: all 0.3s ease;
    height: 1.6rem;
    padding-top: 5px;
  }

  @media ${breakpoints.medium} {
    opacity: 0.8;
  }
`;

const StyledTopElementContainer = styled.div<{ isLeft?: boolean }>`
  position: absolute;
  z-index: 9999;
  top: 1rem;
  ${({ isLeft = false }) =>
    isLeft
      ? css`
          left: 1rem;
        `
      : css`
          right: 1rem;
        `}
  /* Safari hack to render it on top of the topic image */
  transform: translate3d(0, 0, 0);
`;

type TileProps = {
  className?: string;
  image?: string;
  leftElement?: ReactElement | null;
  link?: string;
  onClick?: (event: MouseEvent<HTMLAnchorElement | HTMLDivElement>) => void;
  rightElement?: ReactElement | null;
  subElement?: ReactElement;
  subtitle?: string;
  title: string;
  titleNumberOfLines?: number;
};

export const Tile = memo(
  ({
    className,
    image,
    leftElement = null,
    link,
    onClick,
    rightElement = null,
    subElement,
    subtitle,
    title,
    titleNumberOfLines = 4,
  }: TileProps) => {
    let subView;
    if (subElement) {
      subView = subElement;
    } else if (subtitle) {
      subView = (
        <StyledType>
          <span>{subtitle}</span>
        </StyledType>
      );
    }
    return (
      <StyledShadow>
        <StyledLink to={link} onClick={onClick} className={className}>
          <StyledBackgroundImage src={image} alt={title} />
          <StyledContent>
            <StyledTitle numberOfLines={titleNumberOfLines}>{title}</StyledTitle>
            {(link === undefined && onClick === undefined) || <StyledTitleIcon name="Arrow" />}
            {subView && <StyledHiddenContent>{subView}</StyledHiddenContent>}
          </StyledContent>
          {!!leftElement && <StyledTopElementContainer isLeft={true}>{leftElement}</StyledTopElementContainer>}
          {!!rightElement && <StyledTopElementContainer>{rightElement}</StyledTopElementContainer>}
        </StyledLink>
      </StyledShadow>
    );
  },
);

Tile.displayName = 'Tile';
