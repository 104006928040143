import { useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { Image, UIButton, DigitalMemberTag } from 'common/components';
import { breakpoints } from 'common/styles';
import { useElementIsLoaded } from 'common/utilities';
import { StyledLandingText } from 'domains/landing/components/StyledLandingText';
import { StyledLandingTitle } from 'domains/landing/components/StyledLandingTitle';
import MapImage from 'domains/landing/topics/map-image.png';
import { useTranslations } from 'domains/language/useTranslations';
import { useRouterLink } from 'domains/routing/utils';
import { getShouldShowBecomeMemberMarketing } from 'entities/user/selectors';

type StyledProps = { isLoaded: boolean };

const StyledOuterContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
`;

const StyledContainer = styled.div`
  display: flex;
  max-width: 1600px;
  flex-direction: column;
  margin: 2rem;

  @media ${breakpoints.medium} {
    margin: 2rem 4rem;
  }

  @media ${breakpoints.large} {
    flex-direction: row;
  }
`;

const StyledLeftContainer = styled.div``;

const StyledRightContainer = styled(StyledLeftContainer)<StyledProps>`
  padding-top: 1rem;
  transition: opacity 1s ease-in-out;
  opacity: ${({ isLoaded }) => (isLoaded ? 1 : 0)};
  display: flex;
  flex-direction: column;
`;

const StyledTMVisual = styled(Image)`
  width: 100%;
  display: none;
  height: 300px;
  object-fit: contain;

  @media ${breakpoints.medium} {
    display: block;
    padding: 1rem 1rem 1rem 0;
  }

  @media ${breakpoints.large} {
    width: 300px;
  }
`;

const StyledTitle = styled(StyledLandingTitle)<StyledProps>`
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ isLoaded }) => (isLoaded ? 1 : 0)};
`;

const StyledText = styled(StyledLandingText)<StyledProps>`
  transition: opacity 0.3s ease-in-out 0.3s;
  opacity: ${({ isLoaded }) => (isLoaded ? 1 : 0)};
`;

const StyledDigitalMemberTag = styled(DigitalMemberTag)`
  display: inline-block;
  transform: scale(0.7);
  margin: 0 -20px 0 -30px;
`;

export const TransformationMapSection = () => {
  const { textDictionary, interpolate } = useTranslations();
  const link = useRouterLink({ to: 'discover' });
  const { isLoaded, elementRef } = useElementIsLoaded({ threshold: 0.2 });
  const showAdvancedMap = useSelector(getShouldShowBecomeMemberMarketing);

  return (
    <StyledOuterContainer ref={elementRef}>
      <StyledContainer>
        <StyledLeftContainer>
          <StyledTMVisual src={MapImage} alt={textDictionary['app.settings.about.about_transformation_map']} />
        </StyledLeftContainer>
        <StyledRightContainer isLoaded={isLoaded}>
          <StyledTitle isLoaded={isLoaded}>{textDictionary['app.transformation_map.title']}</StyledTitle>
          {showAdvancedMap ? (
            <>
              <StyledText isLoaded={isLoaded}>{textDictionary['app.transformation_map.info_text']}</StyledText>
              <StyledText isLoaded={isLoaded}>
                {interpolate(textDictionary['app.transformation_map.advanced_map.info_text'], {
                  digitalMembership: () => (
                    <StyledDigitalMemberTag trackPage="Landing" text={textDictionary['app.membership.pro']} />
                  ),
                })}
              </StyledText>
            </>
          ) : (
            <StyledText isLoaded={isLoaded}>{textDictionary['app.transformation_map.info_text_long']}</StyledText>
          )}
          <UIButton as={'a'} href={link} variant="filled" icon="rightArrow">
            {textDictionary['app.discover_topics.title']}
          </UIButton>
        </StyledRightContainer>
      </StyledContainer>
    </StyledOuterContainer>
  );
};
