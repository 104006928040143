import { SVGProps } from 'react';

export const RedCircleErrorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5 -5 48 48" {...props}>
    <g>
      <g>
        <circle cx="20" cy="20" r="20" fill="rgba(38,38,38,0.05)" stroke="#df4137" strokeWidth="2" />
        <circle cx="20" cy="20" r="19.5" fill="none" />
      </g>
      <g fill="currentColor">
        <path
          d="M114.017 24H95.353a2.16 2.16 0 0 0-2.153 2.153v18.664a2.16 2.16 0 0 0 2.153 2.153h18.663a2.16 2.16 0 0 0 2.153-2.153V26.153A2.16 2.16 0 0 0 114.017 24zm.718 20.817a.72.72 0 0 1-.718.718H95.353a.72.72 0 0 1-.718-.718V26.153a.72.72 0 0 1 .718-.718h18.663a.72.72 0 0 1 .718.718"
          transform="translate(-84.505 -15.935)"
        />
        <path
          d="M333.918 165.091a.72.72 0 0 1-.718-.718v-6.855a.718.718 0 1 1 1.436 0v6.855a.72.72 0 0 1-.718.718"
          transform="translate(-313.738 -142.777)"
        />
        <path
          d="M333.918 372.588a.72.72 0 0 1-.718-.718v-.754a.718.718 0 1 1 1.436 0v.754a.72.72 0 0 1-.718.718"
          transform="translate(-313.738 -346.794)"
        />
      </g>
    </g>
  </svg>
);
