import { all, call, put, select } from 'redux-saga/effects';

import { ImageCollection } from 'common/types/imageCollection';
import { apiFetch, imageUploadApiFetch, pushHistory } from 'common/utilities';
import { uploadMapFailure, uploadMapSuccessful } from 'domains/createMapDetail/actions';
import { UploadMapDefaultAction } from 'domains/createMapDetail/actionTypes';
import {
  getCurrentCustomMapDescription,
  getCurrentCustomMapName,
  getCurrentMap,
  getCurrentMapExistingKeyIssuesIds,
  getCurrentMapNewCustomKeyIssues,
  getLocalImage,
} from 'domains/createMapDetail/selectors';
import { LocalImage } from 'domains/createMapDetail/types';
import { getAddedTopics, getRemovedTopics } from 'domains/createMapDetail/utils';
import { createCustomKeyIssueSuccess } from 'domains/customKeyIssue/actions';
import {
  convertCustomKeyIssueMetaToKeyIssue,
  getCustomKeyIssueBodyFromMeta,
} from 'domains/customKeyIssue/sagas/createCustomKeyIssueSaga';
import { CustomKeyIssueMeta } from 'domains/customKeyIssue/types';
import { pushModalScreen } from 'domains/modal/actions';
import { handleCustomTopicUpdateFailureSaga } from 'domains/topicDetail/sagas/handleCustomTopicUpdateFailureSaga';
import { KeyIssue } from 'entities/keyIssues/types';
import { fetchFollowedTopics, fetchMyMaps, fetchTopicSuccess } from 'entities/topics/actions';
import { getFollowedTopicsLimit, getFollowedTopicsOffset } from 'entities/topics/selectors';
import { Topic } from 'entities/topics/types';
import { fetchTopicsMetadata } from 'entities/topicsMetadata/actions';

type SharedUser = { email: string; permission: string };
type Invitations = { shared_users?: SharedUser[] };
type BodyType = string | string[] | Invitations | boolean | undefined;

const getBody = (map: Partial<Topic> | undefined, dimensions: string[], name?: string, description?: string) => {
  let body: Record<string, BodyType> = {
    status: 'Active',
  };
  if (name) {
    body = { ...body, name };
  }
  if (description !== undefined) {
    body = { ...body, description };
  }
  if (map?.id) {
    const savedDimensions = (map.dimensions || []).map(({ id }) => id);
    const dimensions_add = getAddedTopics(savedDimensions, dimensions);
    const dimensions_remove = getRemovedTopics(savedDimensions, dimensions);
    body = {
      ...body,
      dimensions_add,
      dimensions_remove,
    };
  } else {
    body = { ...body, dimensions };
  }
  if (map?.invitations) {
    body = {
      ...body,
      invitations: {
        shared_users: map.invitations.map((item) => ({
          email: item.email,
          permission: item.permission,
        })),
      },
    };
  }
  if (map?.is_public) {
    body = { ...body, is_public: map.is_public };
  }
  return body;
};

type ImageResponse = { images: ImageCollection };
type DimensionAPIResponse = { response: string };

function* saveNewCustomKeyIssues(newCustomKeyIssues: CustomKeyIssueMeta[], map: Topic) {
  const responses: DimensionAPIResponse[] = yield all(
    newCustomKeyIssues.map((keyIssue) => {
      const body = getCustomKeyIssueBodyFromMeta(keyIssue, map.id, keyIssue.fp, keyIssue.knowledge);
      return apiFetch(`dimension`, {
        method: 'PUT',
        body,
      });
    }),
  );

  const responseIds = responses.map(({ response: id }) => id);
  const responseKeyIssues: KeyIssue[] = yield all(
    responseIds.map((id, index) => call(convertCustomKeyIssueMetaToKeyIssue, newCustomKeyIssues[index], id, map)),
  );
  return responseKeyIssues;
}

export function* uploadMapSaga(action: UploadMapDefaultAction) {
  const map: Partial<Topic> | undefined = yield select(getCurrentMap);
  try {
    const name: string = yield select(getCurrentCustomMapName);
    const description: string = yield select(getCurrentCustomMapDescription);
    const image: LocalImage | undefined = yield select(getLocalImage);
    const dimensions: string[] = yield select(getCurrentMapExistingKeyIssuesIds);
    const shouldCreateMap = !map?.id;

    const body = getBody(map, dimensions, name, description);
    const payload: Topic = yield apiFetch(`map/${shouldCreateMap ? '' : map?.id}`, {
      method: shouldCreateMap ? 'POST' : 'PATCH',
      body,
    });
    let { images } = payload;
    if (image?.file) {
      const response: ImageResponse = yield imageUploadApiFetch(`map/${payload.id}/image`, {
        image: image.file,
      });
      images = response.images;
    }

    // now iterate through all custom key issues and call save endpoints
    const newCustomKeyIssues: CustomKeyIssueMeta[] = yield select(getCurrentMapNewCustomKeyIssues);
    const responseKeyIssues: KeyIssue[] = yield call(saveNewCustomKeyIssues, newCustomKeyIssues, payload);

    // assuming that both of those lists are in the same order
    for (let i = 0; i < newCustomKeyIssues.length; i++) {
      yield put(createCustomKeyIssueSuccess(responseKeyIssues[i], newCustomKeyIssues[i]));
    }

    // Update topic grids with new info
    const mapResponse = {
      ...payload,
      dimensions: [...(payload.dimensions || []), ...responseKeyIssues],
      images,
    };
    yield put(fetchTopicSuccess({ topic: mapResponse }));
    yield put(fetchMyMaps({ offset: 0, limit: 20 }));

    const followedTopicsLimit: number = yield select(getFollowedTopicsLimit);
    const followedTopicsOffset: number = yield select(getFollowedTopicsOffset);
    yield put(
      fetchFollowedTopics({
        limit: followedTopicsLimit,
        offset: followedTopicsOffset,
      }),
    );

    yield put(fetchTopicsMetadata());
    yield put(uploadMapSuccessful(mapResponse));

    if (action.meta?.closeEditor) {
      yield call(pushHistory, '/create');
    } else if (shouldCreateMap) {
      yield call(pushHistory, `/create/${payload.id}?editing=true`);
    }
  } catch (error) {
    yield put(uploadMapFailure({ error: error as any }, { logoutOnAuthError: true }));
    if ([403, 404].includes((error as any).status)) {
      yield handleCustomTopicUpdateFailureSaga(error as any);
    } else if ((error as any)?.status !== 401) {
      yield put(pushModalScreen({ id: 'Error' }));
    }
  }
}
