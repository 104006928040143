import { put, call, select } from 'redux-saga/effects';

import { apiFetch, replaceHistory } from 'common/utilities';
import { initiateCreateModeFulfilled } from 'domains/createMapDetail/actions';
import { InitTopicAction } from 'domains/topicDetail/actionTypes';
import { handleCustomTopicUpdateFailureSaga } from 'domains/topicDetail/sagas/handleCustomTopicUpdateFailureSaga';
import { setTransformationMapTopic } from 'domains/transformationMap/actions';
import { getTransformationMapEditMode } from 'domains/transformationMap/selectors';
import { fetchTopicFailure, fetchTopicSuccess } from 'entities/topics/actions';
import { Topic } from 'entities/topics/types';
import { FetchError } from 'types';

export function* initTopicSaga({ meta }: InitTopicAction) {
  try {
    yield put(setTransformationMapTopic(meta));
    const topic: Topic = yield apiFetch(`map/${meta.id}`);
    const isEditMode: boolean = yield select(getTransformationMapEditMode);
    yield put(fetchTopicSuccess({ topic }));
    if (isEditMode || meta.editMode) {
      if (!topic.can_edit) {
        yield call(replaceHistory, `/topics/${topic.id}`);
      } else {
        yield put(initiateCreateModeFulfilled(topic));
      }
    }
  } catch (error) {
    const fetchError = error as FetchError;
    yield put(fetchTopicFailure({ id: meta.id, error: fetchError }, { logoutOnAuthError: true }));
    yield handleCustomTopicUpdateFailureSaga(fetchError);
  }
}
