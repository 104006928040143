import {
  FetchTopicsMetadataAction,
  FetchTopicsMetadataFulfilledAction,
  FetchTopicsMetadataRejectedAction,
  TopicsMetadataActionTypes,
} from 'entities/topicsMetadata/actionTypes';
import { TopicsMetadataPayload } from 'entities/topicsMetadata/types';
import { ErrorMeta, FetchError } from 'types';

export const fetchTopicsMetadata = (): FetchTopicsMetadataAction => ({
  type: TopicsMetadataActionTypes.FETCH,
});

export const fetchTopicsMetadataSuccess = (payload: TopicsMetadataPayload): FetchTopicsMetadataFulfilledAction => ({
  type: TopicsMetadataActionTypes.FETCH_FULFILLED,
  payload,
});

export const fetchTopicsMetadataFailure = (
  payload: { error: FetchError },
  meta: ErrorMeta,
): FetchTopicsMetadataRejectedAction => ({
  type: TopicsMetadataActionTypes.FETCH_REJECTED,
  payload,
  meta,
});
