import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { fetchTopicContentListFulfilled, fetchTopicContentListRejected } from 'domains/customContentAndSources/actions';
import { TopicContentListFetchAction } from 'domains/customContentAndSources/actionTypes';
import { CustomContent } from 'domains/customContentAndSources/types';
import { FetchError } from 'types';

export function* getTopicContentSaga({ payload }: TopicContentListFetchAction) {
  try {
    const response: CustomContent[] = yield apiFetch(`topic/${payload.topicId}/attachment`, {
      queryParams: {
        offset: payload.offset ?? 0,
        limit: payload.limit ?? 20,
      },
    });
    yield put(fetchTopicContentListFulfilled(response, payload));
  } catch (error) {
    yield put(fetchTopicContentListRejected({ error: error as FetchError }, { logoutOnAuthError: true, ...payload }));
  }
}
