import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { fetchEventsForTopicSuccess, fetchEventsForTopicFailure } from 'entities/events/actions';
import { FetchEventDefaultAction } from 'entities/events/actionTypes';
import { EventSession } from 'entities/events/types';

const getStartAndEndFromYear = (year: number) => {
  const startDate = new Date(year, 0, 1, 0, 0, 0, 0);
  const endDate = new Date(year, 11, 31, 23, 59, 59, 0);

  return {
    from_time: startDate.getTime() / 1000,
    to_time: endDate.getTime() / 1000,
  };
};

export function* fetchEventsForTopicSaga(action: FetchEventDefaultAction) {
  const { id, offset, limit, searchTerm, year } = action.payload;
  try {
    let dateFilters = {};
    if (year && year !== '') {
      dateFilters = getStartAndEndFromYear(parseInt(year));
    }
    const payload: EventSession[] = yield apiFetch(`topic/${id}/session`, {
      queryParams: {
        search: searchTerm,
        ...dateFilters,
        offset,
        limit,
      },
    });

    yield put(
      fetchEventsForTopicSuccess(
        {
          topicId: id,
          data: payload,
        },
        action.payload,
      ),
    );
  } catch {
    const errorMeta = {
      searchCriteria: action.payload,
      logoutOnAuthError: true,
    };
    yield put(fetchEventsForTopicFailure({ topicId: id }, errorMeta));
  }
}
