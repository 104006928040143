import { UIButton, DigitalMemberTag } from 'common/components';
import { useElementIsLoaded } from 'common/utilities';
import { StyledLandingText } from 'domains/landing/components/StyledLandingText';
import { StyledLandingTitle } from 'domains/landing/components/StyledLandingTitle';
import CreateImage from 'domains/landing/create/create-image.png';
import {
  StyledInfoContainer,
  SlidingContainer,
  StyledImage,
  StyledSectionContainer,
} from 'domains/landing/create/shared';
import { useTranslations } from 'domains/language/useTranslations';

export const CreateSection = () => {
  const { elementRef, isLoaded } = useElementIsLoaded({ threshold: 0.5 });
  const { textDictionary } = useTranslations();

  return (
    <StyledSectionContainer ref={elementRef}>
      <StyledInfoContainer>
        <SlidingContainer isLoaded={isLoaded} isLeft={true}>
          <DigitalMemberTag trackPage="Landing" />
          <StyledLandingTitle>{textDictionary['app.create.title']}</StyledLandingTitle>
          <StyledLandingText>{textDictionary['app.create.promo_text1']}</StyledLandingText>
          <UIButton
            variant={'outlined'}
            href="https://www.youtube.com/embed/ww5WcMWOLFI"
            icon={'play'}
            as={'a'}
            target={'_blank'}
          >
            {textDictionary['app.discover.curatedBanner.button']}
          </UIButton>
        </SlidingContainer>
      </StyledInfoContainer>
      <StyledInfoContainer>
        <SlidingContainer isLoaded={isLoaded}>
          <StyledImage src={CreateImage} alt={textDictionary['app.topic.insight_briefing']} />
        </SlidingContainer>
      </StyledInfoContainer>
    </StyledSectionContainer>
  );
};
