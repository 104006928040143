import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

import { getBookmarksCount } from 'domains/bookmarks/selectors';
import { useTranslations } from 'domains/language/useTranslations';
import { TopHeaderItemBadge } from 'domains/navigation/components/TopHeaderItemBadge';
import { useRouterLink } from 'domains/routing/utils';

const StyledContainer = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: inherit;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
`;

export const BookmarksButton = () => {
  const { textDictionary } = useTranslations();
  const link = useRouterLink({ to: 'bookmarks' });
  const bookmarksBadgeCount = useSelector(getBookmarksCount);

  const bookmarksBadgeCountFormatted = bookmarksBadgeCount
    ? bookmarksBadgeCount.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
      })
    : '';

  return (
    <StyledContainer to={link}>
      {textDictionary['app.bookmarks.title']}
      <TopHeaderItemBadge>{bookmarksBadgeCountFormatted}</TopHeaderItemBadge>
    </StyledContainer>
  );
};
