export type AdvancedTopicKnowledgeSearchCriteria = {
  fromTime?: number;
  id?: string;
  secondaryTopicId?: string;
  toTime?: number;
};

export type TopicKnowledgePackage = { data: string[]; error: boolean; hasNextPage: boolean; isFetching: boolean };

export enum DestinationTypes {
  CUSTOM = 'Custom Maps',
  COLLECTION = 'Collection',
  ALL = 'All topics',
}
