import { MouseEvent, ReactElement } from 'react';
import { styled } from 'styled-components';

import { ImageBackground, SourceLabel } from 'common/components';
import { ListImage } from 'common/components/lists/ListLink';
import { CustomKnowledge } from 'domains/customContentAndSources/types';
import { StyledType, StyledTitle, StyledContent } from 'domains/knowledgeFeed/components/shared';
import { KnowledgeDetailButton } from 'domains/publication/components/KnowledgeDetailButton';
import { Knowledge } from 'entities/knowledge/types';

const StyledImage = styled(ListImage)`
  width: 100px;
  height: 60px;
  border-radius: 4px;
  margin-right: 15px;
`;

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type BrowseInnerContentProps = { knowledge: Knowledge | CustomKnowledge };

export const BrowseInnerContent = ({ knowledge }: BrowseInnerContentProps) => {
  const { title, images, source, time } = knowledge;
  return (
    <>
      <StyledImage src={images?.thumbnail} />
      <StyledContent>
        <StyledTitle>{title}</StyledTitle>
        {source && <SourceLabel source={source} date={time} type="small" />}
      </StyledContent>
    </>
  );
};

const StyledLatestViewImage = styled(ImageBackground)`
  width: 4rem;
  min-width: 4rem;
  height: 4rem;
  border-radius: 5px;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLatestViewContent = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const StyledContentWrapper = styled.div`
  display: flex;
`;

type LatestViewInnerContentProps = {
  articleAnnouncement?: ReactElement;
  knowledge: Knowledge | CustomKnowledge;
  link: string;
};

export const LatestViewInnerContent = ({ link, knowledge, articleAnnouncement }: LatestViewInnerContentProps) => {
  const onDetailLinkClick = (event: MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <>
      {articleAnnouncement}
      <StyledWrapper>
        <StyledContentWrapper>
          <StyledLatestViewImage src={knowledge.images?.thumbnail} />
          <StyledLatestViewContent>
            <StyledType>{knowledge.knowledgeType}</StyledType>
            <StyledTitle>{knowledge.title}</StyledTitle>
            {knowledge.source && <SourceLabel source={knowledge.source} date={knowledge.time} type="small" />}
          </StyledLatestViewContent>
        </StyledContentWrapper>
        <KnowledgeDetailButton link={link} onClick={onDetailLinkClick} />
      </StyledWrapper>
    </>
  );
};
