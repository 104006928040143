import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider, styled } from 'styled-components';

import { UIButton, ExpandableText, ShareIcon, EditIcon, DeleteIcon, CommunitiesIcon } from 'common/components';
import { cssResets } from 'common/styles/cssResets';
import { maxWidth } from 'common/styles/mediaQueries/breakpoints';
import { extractYoutubeVideoId } from 'common/utilities/extractYoutubeVideoId';
import { CollectionSearchableTopicList } from 'domains/collection/components/CollectionSearchableTopicList';
import { CollectionVideoCover } from 'domains/collection/components/CollectionVideoCover';
import { CollectionViewModel } from 'domains/collection/types';
import { useTranslations } from 'domains/language/useTranslations';
import { activateModal } from 'domains/modal/actions';
import { useRouterNavigation } from 'domains/routing/utils';
import { shareLinkAction } from 'domains/share/actions';
import { darkTheme } from 'domains/theme/constants/theme';
import { setVisualMediaPlayer } from 'domains/visualMediaPlayer/actions';
import { MediaType } from 'domains/visualMediaPlayer/types';
import { Topic } from 'entities/topics/types';
import { getCanEditCollections } from 'entities/user/selectors';

type CollectionProps = { collection: CollectionViewModel; loadNextPage: () => void; topics: Topic[] };

export const CollectionPage = ({ collection, topics, loadNextPage }: CollectionProps) => {
  const dispatch = useDispatch();
  const canEditCollections = useSelector(getCanEditCollections);
  const { isCurrentLanguageRtl, textDictionary } = useTranslations();

  const { co_brands, description, featured_video_url, hero_image_url, id, name, spaces_url: spacesUrl } = collection;
  const videoId = featured_video_url === null ? undefined : extractYoutubeVideoId(featured_video_url);
  const image = videoId === undefined ? undefined : `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;

  const onClickVideo = () => {
    if (featured_video_url === null) return;
    dispatch(
      setVisualMediaPlayer({
        id: 'introduction',
        image,
        mediaType: MediaType.videoViaEmbeddedHtmlPlayer,
        name,
        url: featured_video_url,
        useReactPlayer: true,
      }),
    );
  };

  const onClickShare = () =>
    dispatch(
      shareLinkAction({
        id,
        image: hero_image_url,
        link: `${process.env.REACT_APP_WEBSITE_URL}/collection/${id}`,
        name,
        type: 'Collection',
      }),
    );

  const onClickEdit = useRouterNavigation({ to: 'collection-edit', id });
  const onClickDelete = () => dispatch(activateModal({ id: 'Collection Deletion Confirmation', params: { id, name } }));

  return (
    <Container>
      <Head rtl={isCurrentLanguageRtl}>
        <HeroImage url={hero_image_url} />
        <ThemeProvider theme={darkTheme}>
          <Foreground>
            <Details>
              {co_brands.map((coBrand) => (
                <CoBrand key={coBrand.id}>
                  <CoBrandImage src={coBrand.image_url ?? undefined} />
                  <CoBrandName>{coBrand.name}</CoBrandName>
                </CoBrand>
              ))}
              <Name>{name}</Name>
              <StyledExpandableText text={description} limit={4} lineHeight={28} />
            </Details>
            {image === undefined || (
              <VideoContainer onClick={onClickVideo}>
                <CollectionVideoCover image={image} />
              </VideoContainer>
            )}
          </Foreground>
        </ThemeProvider>
      </Head>
      <CollectionSearchableTopicList
        loadNextPage={loadNextPage}
        nextPageMayExist={collection.topics.nextPageMayExist}
        status={collection.topics.status}
        topics={topics}
      >
        <Buttons>
          <Button variant="outlined" onClick={onClickShare}>
            {textDictionary['app.collection.share']}
            <StyledShareIcon />
          </Button>
          {spacesUrl === null || (
            // Ideally the pushHistory function would be updated to handle mixed casing in the URL. However, this could cause regression issues and I hope to overhaul routing at some point.
            <Button variant="outlined" onClick={() => (window.location.href = spacesUrl)}>
              {textDictionary['app.collection.community']}
              <StyledCommunitiesIcon />
            </Button>
          )}
          {canEditCollections && collection.can_edit && (
            <>
              <Button variant="outlined" onClick={onClickEdit}>
                {textDictionary['app.collection.edit']}
                <StyledEditIcon />
              </Button>
              <Button variant="outlined" onClick={onClickDelete}>
                {textDictionary['app.collection.delete']}
                <StyledDeleteIcon />
              </Button>
            </>
          )}
        </Buttons>
      </CollectionSearchableTopicList>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.collectionBackground};
`;

const Head = styled.div<{ rtl: boolean }>`
  background: ${({ theme }) => theme.colors.black};
  direction: ${({ rtl }) => (rtl ? 'rtl' : 'ltr')};
  min-height: 380px;
  position: relative;
`;

const HeroImage = styled.div<{ url: string }>`
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000), url(${({ url }) => url});
  background-position: center;
  background-size: cover;
  height: 380px;
  position: absolute;
  width: 100%;
`;

const Foreground = styled.div`
  display: flex;
  gap: 1rem;
  padding: 6rem 3rem 3rem;

  @media ${maxWidth.medium} {
    flex-direction: column;
    padding: 4rem 1rem 3rem;
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  flex: 10 0 55%;
  gap: 1rem;
  height: 100%;
  justify-content: center;
  max-width: 80vw;
  position: relative;
  width: 100%;
`;

const CoBrand = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
`;

const CoBrandImage = styled.img`
  border-radius: 5px;
  height: 54px;
  object-fit: cover;
  object-position: center;
  width: 54px;
`;

const CoBrandName = styled.div`
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
`;

const Name = styled.h1`
  ${cssResets.h1}
  color: white;
  font-size: 2rem;
  font-weight: bold;
`;

const StyledExpandableText = styled(ExpandableText)`
  p {
    color: white;
    font-size: 1.1rem;
    line-height: 28px;
    white-space: pre-line;
  }
`;

const VideoContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex: 0 0 45%;
  max-width: 40rem;
  width: 100%;
`;

const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

const Button = styled(UIButton)`
  border-radius: 1.5rem;
  padding: 0 1rem 0 1.25rem;

  > div {
    gap: 0.5rem;
  }
`;

const [StyledCommunitiesIcon, StyledDeleteIcon, StyledEditIcon, StyledShareIcon] = [
  CommunitiesIcon,
  DeleteIcon,
  EditIcon,
  ShareIcon,
].map(
  (icon) => styled(icon)`
    height: 14px;
  `,
);
