import mixpanel, { Callback, Dict, init } from 'mixpanel-browser';

const client = 'web-app';

export const initTracking = () => {
  const mixpanelKey = process.env.REACT_APP_MIXPANEL_KEY;
  if (mixpanelKey) {
    init(mixpanelKey);
  }
};

export const reset = () => {
  // eslint-disable-next-line import/no-named-as-default-member
  mixpanel.reset();
};

export const track = (name: string, payload: Dict, callback?: Callback) => {
  // eslint-disable-next-line import/no-named-as-default-member
  mixpanel.track(name, { client, ...payload }, callback);
};

export const identify = (userId?: string) => {
  // eslint-disable-next-line import/no-named-as-default-member
  mixpanel.identify(userId);
};
