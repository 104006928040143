import { call, select } from 'redux-saga/effects';

import { history, pushHistory } from 'common/utilities';
import { TransformationMapSetInnerNodeAction } from 'domains/transformationMap/actionTypes';
import { getTransformationMapId } from 'domains/transformationMap/selectors';

export function* updateKeyIssueSaga(action: TransformationMapSetInnerNodeAction) {
  const { id } = action.meta;
  const isCurrentlyKeyIssuePath = window.location.href.includes('key-issues');
  const storedTopicId: string = yield select(getTransformationMapId);
  const topicId = action.meta.topicId ?? storedTopicId;
  if (!topicId) {
    return;
  }
  const productDomain = history.location.pathname.split('/')[1];
  if (!['topics', 'discover', 'create'].includes(productDomain)) {
    return;
  }
  let url: string | undefined;
  if (id) {
    url = `/${productDomain}/${topicId}/key-issues/${id}`;
  } else if (isCurrentlyKeyIssuePath) {
    url = `/${productDomain}/${topicId}`;
  }
  if (!url) {
    return;
  }
  if (history.location.pathname !== url) {
    yield call(pushHistory, url);
  }
}
