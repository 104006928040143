import { css, styled } from 'styled-components';

import { breakpoints } from 'common/styles';

type StyledProps = { numberOfItems?: [number, number, number, number, number] };

const defaultNumberOfItems = [2, 3, 4, 5, 6];

const getGridTemplateColumns = (repeatCount = 5) => `repeat(${repeatCount}, minmax(0, 1fr))`;

export const StyledGrid = styled.div<StyledProps>`
  display: grid;
  grid-gap: 1rem;

  ${({ numberOfItems = defaultNumberOfItems }) => css`
    grid-template-columns: ${getGridTemplateColumns(numberOfItems[0])};

    @media ${breakpoints.medium} {
      grid-template-columns: ${getGridTemplateColumns(numberOfItems[1])};
    }

    @media ${breakpoints.large} {
      grid-template-columns: ${getGridTemplateColumns(numberOfItems[2])};
    }

    @media ${breakpoints.extraLarge} {
      grid-template-columns: ${getGridTemplateColumns(numberOfItems[3])};
    }

    @media ${breakpoints.enormous} {
      grid-template-columns: ${getGridTemplateColumns(numberOfItems[4])};
    }
  `}
`;
