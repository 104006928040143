import {
  InitiativesType,
  FetchInitiativeDefaultAction,
  FetchInitiativeFulfilledAction,
  FetchInitiativeRejectedAction,
  FetchInitiativeForIdAction,
  FetchInitiativeForIdFulfilledAction,
  FetchInitiativeForIdRejectedAction,
} from 'entities/initiatives/actionTypes';

export const fetchInitiativesForTopic = (
  payload: FetchInitiativeDefaultAction['payload'],
): FetchInitiativeDefaultAction => ({
  type: InitiativesType.FETCH_FOR_TOPIC,
  payload,
});

export const fetchInitiativesForTopicSuccess = (
  payload: FetchInitiativeFulfilledAction['payload'],
  meta: FetchInitiativeFulfilledAction['meta'],
): FetchInitiativeFulfilledAction => ({
  type: InitiativesType.FETCH_FOR_TOPIC_SUCCESS,
  payload,
  meta,
});

export const fetchInitiativesForTopicFailure = (
  payload: FetchInitiativeRejectedAction['payload'],
  meta: FetchInitiativeRejectedAction['meta'],
): FetchInitiativeRejectedAction => ({
  type: InitiativesType.FETCH_FOR_TOPIC_FAILURE,
  payload,
  meta,
});

export const fetchInitiativeById = (meta: FetchInitiativeForIdAction['meta']): FetchInitiativeForIdAction => ({
  type: InitiativesType.FETCH_FOR_ID,
  meta,
});

export const fetchInitiativeByIdSuccessful = (
  payload: FetchInitiativeForIdFulfilledAction['payload'],
  meta: FetchInitiativeForIdFulfilledAction['meta'],
): FetchInitiativeForIdFulfilledAction => ({
  type: InitiativesType.FETCH_FOR_ID_SUCCESS,
  payload,
  meta,
});

export const fetchInitiativeByIdFailure = (
  payload: FetchInitiativeForIdRejectedAction['payload'],
  meta: FetchInitiativeForIdRejectedAction['meta'],
): FetchInitiativeForIdRejectedAction => ({
  type: InitiativesType.FETCH_FOR_ID_FAILURE,
  payload,
  meta,
});
