import { useDispatch, useSelector } from 'react-redux';
import { styled, ThemeProvider } from 'styled-components';

import { CrossIcon, UIButton } from 'common/components';
import { trackEvent } from 'domains/analytics/actions';
import { useTranslations } from 'domains/language/useTranslations';
import { lightTheme, darkTheme } from 'domains/theme/constants/theme';
import { useThemeAppearance } from 'domains/theme/useThemeAppearance';
import { getUserRole } from 'entities/user/selectors';
import { getLinkToMembershipPage } from 'entities/user/utils';

const StyledContainer = styled.div`
  padding: 1.5rem;
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  color: ${({ theme }) => theme.colors.textBright};
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 50px;
  max-height: 600px;
  transition:
    min-height 0.3s,
    max-height 0.3s;
  border-radius: 5px;
  margin-top: 3rem;
`;

const StyledCloseIcon = styled(CrossIcon)`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
  color: ${({ theme }) => theme.colors.textFade};
  cursor: pointer;
  margin-left: 3rem;
  transition: all 0.3s;
  border-radius: 20px;
  padding: 7px;
  &:hover {
    color: ${({ theme }) => theme.colors.textBright};
    background: ${({ theme }) => theme.colors.textBright}0F;
  }
`;

const StyledLabel = styled.p`
  margin-bottom: 1rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.textFade};
`;

const StyledTitle = styled.h3`
  font-size: 1.4rem;
  margin: 0;
`;

type UpgradeAccountForCreateCallToActionProps = {
  isCallToActionVisible: boolean;
  setCallToActionVisible: (value: boolean) => void;
  type: 'publicCTA' | 'digitalMemberCTA';
};

export const UpgradeAccountForCreateCallToAction = ({
  isCallToActionVisible,
  setCallToActionVisible,
  type,
}: UpgradeAccountForCreateCallToActionProps) => {
  const theme = useThemeAppearance();
  const { textDictionary } = useTranslations();
  const dispatch = useDispatch();
  const userRole = useSelector(getUserRole);

  const onClick = () => {
    dispatch(
      trackEvent({
        name: 'Upgrade',
      }),
    );
    window.location.href = getLinkToMembershipPage(userRole);
  };

  const onDismissClick = () => setCallToActionVisible(false);

  let contentLocalisedKeys = { title: '', description: '' };
  switch (type) {
    case 'publicCTA':
      contentLocalisedKeys = {
        title: 'app.create.landing_banner.no_permissions_for_map_creation_title',
        description: 'app.create.landing_banner.no_permissions_for_map_creation_description',
      };
      break;

    case 'digitalMemberCTA':
      contentLocalisedKeys = {
        title: 'app.create.landing_banner.no_permissions_for_ai_title',
        description: 'app.create.landing_banner.no_permissions_for_ai_description',
      };

      break;
  }

  if (!isCallToActionVisible) return <> </>;

  return (
    <ThemeProvider theme={theme === 'light' ? darkTheme : lightTheme}>
      <StyledContainer>
        <StyledCloseIcon onClick={onDismissClick} />
        <StyledTitle>{textDictionary[contentLocalisedKeys.title]}</StyledTitle>
        <StyledLabel>{textDictionary[contentLocalisedKeys.description]}</StyledLabel>
        <UIButton variant={'filled'} onClick={onClick}>
          {textDictionary['app.registration.app.create.landing_banner.no_permissions_upgrade_button']}
        </UIButton>
      </StyledContainer>
    </ThemeProvider>
  );
};
