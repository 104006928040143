import { useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { RedCircleErrorIcon } from 'common/components';
import { useTranslations } from 'domains/language/useTranslations';
import { ModalHeader } from 'domains/modal/components/ModalHeader';
import { getModalParams } from 'domains/modal/selectors';

const StyledErrorContent = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledText = styled.p`
  text-align: left;
  font-size: 1.1rem;
  line-height: 1.8rem;
  margin: 0 0 0 1.3rem;
`;

const StyledRedCircleErrorIcon = styled(RedCircleErrorIcon)`
  width: 60px;
  align-self: flex-start;
  flex-shrink: 0;
  color: ${({ theme }) => theme.colors.fillPrimary};
`;

export const ErrorModal = () => {
  const { message, localisedMessageKey } = useSelector(getModalParams);
  const { textDictionary } = useTranslations();

  const localisedMessage = localisedMessageKey ? textDictionary[localisedMessageKey] : undefined;
  const errorMessage = message || localisedMessage || textDictionary['app.auth.error'];

  return (
    <>
      <ModalHeader title={textDictionary['app.error.unknown.title']} />
      <StyledErrorContent>
        <StyledRedCircleErrorIcon />
        <StyledText>{errorMessage}</StyledText>
      </StyledErrorContent>
    </>
  );
};
