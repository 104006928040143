import { Role } from 'entities/permissions/constants';
import { getTopic } from 'entities/topics/selectors';
import { TM_LICENSE_PERSONAL } from 'entities/user/constants/tmLicenses';
import { RootState } from 'types';

// USER PROFILE

export const getIsFetchingUserData = (state: RootState) => state.entities.user.isFetchingUserData;
export const getUserData = (state: RootState) => state.entities.user.userData;
export const getUserId = (state: RootState) => state.entities.user.userData.id;
export const getIsUpdatingProfile = (state: RootState) => state.entities.user.isUpdatingProfile;
export const getProfileUpdateError = (state: RootState) => state.entities.user.profileUpdateError;
export const getCanEditProfile = (state: RootState) =>
  getCanDisplayProfile(state) && !getShouldRedirectToToplink(state);

// PERMISSIONS
// TODO: Move to permissions folder

const getPermissions = (state: RootState) => state.entities.permissions;

// DISCOVER

const getDiscoverPermissions = (state: RootState) => getPermissions(state)?.discover;
const getDiscoverFeatures = (state: RootState) => getDiscoverPermissions(state)?.features;

export const getHasUnauthenticatedSectionAccess = (topicId: string) => (state: RootState) =>
  getTopic(topicId)(state)?.has_unauthenticated_content_access || false;

export const getCanAccessLatestFeed = (state: RootState) =>
  (getDiscoverFeatures(state)?.publications &&
    getDiscoverFeatures(state)?.videos &&
    getDiscoverFeatures(state)?.data) ||
  false;

export const getCanAccessTopicPublications = (state: RootState) => getDiscoverFeatures(state)?.publications || false;

export const getCanAccessTopicCustomContent = (state: RootState) => getDiscoverFeatures(state)?.custom_content || false;

export const getCanAccessTopicVideos = (state: RootState) => getDiscoverFeatures(state)?.videos || false;

export const getCanAccessTopicData = (state: RootState) => getDiscoverFeatures(state)?.data || false;

export const getCanAccessTopicEvents = (state: RootState) => getDiscoverFeatures(state)?.events || false;

export const getCanAccessTopicInitiatives = (state: RootState) => getDiscoverFeatures(state)?.initiatives || false;

export const getCanAccessTopicStakeholders = (state: RootState) => getDiscoverFeatures(state)?.stakeholders || false;

export const getCanAccessTopicSignals = (state: RootState) => getGlobalFeatures(state)?.signals || false;

export const getCanSeeSectionContent = (state: RootState) => getDiscoverFeatures(state)?.tab_content_visible || false;

export const getCanUseTopicPresentationExport = (state: RootState) =>
  getDiscoverFeatures(state)?.presentation_export || false;
export const getCanUseTopicImageExport = (state: RootState) => getDiscoverFeatures(state)?.image_export || false;

// MONITOR

const getMonitorPermissions = (state: RootState) => getPermissions(state)?.monitor;

export const getCanAccessMonitor = (state: RootState) => getMonitorPermissions(state)?.access || false;

// CREATE

const getCreatePermissions = (state: RootState) => getPermissions(state)?.create;
const getCreateFeatures = (state: RootState) => getCreatePermissions(state)?.features;

export const getCanAccessCreate = (state: RootState) => getCreatePermissions(state)?.access || false;
export const getCreateMapLimit = (state: RootState) => getCreateFeatures(state)?.map_limit || 0;
export const getIsCreateMapRestricted = (state: RootState) => {
  const mapRestriction = getCreateFeatures(state)?.map_restriction;
  return mapRestriction === false ? mapRestriction : true;
};
export const getCreateMapShareSettingsAccess = (state: RootState) => getCreateFeatures(state)?.map_set_sharing || false;
export const getCreateMapCustomKeyIssueAccess = (state: RootState) =>
  getCreateFeatures(state)?.custom_key_issues || false;
export const getCreateMapCustomContentAccess = (state: RootState) => getCreateFeatures(state)?.custom_content || false;
export const getCanAccessDiscoverTMAdvancedMode = (state: RootState) =>
  getDiscoverFeatures(state)?.advanced_mode || false;
export const getCanAccessGenerativeMaps = (state: RootState) => getCreateFeatures(state)?.generative_maps || false;
export const getCanAccessDiscoverTMBriefingLite = (state: RootState) => getDiscoverFeatures(state)?.briefing || false;
export const getCanAccessDiscoverStakeholderProfile = (state: RootState) =>
  getDiscoverFeatures(state)?.forum_experts || false;
export const getCanAccessDiscoverForumInitiatives = (state: RootState) =>
  getDiscoverFeatures(state)?.forum_initiatives || false;
export const getCanAccessDiscoverForumEvents = (state: RootState) => getDiscoverFeatures(state)?.forum_events || false;
export const getCanAccessDiscoverTopicDialogue = (state: RootState) => getDiscoverFeatures(state)?.dialogue || false;
export const getTMLicense = (state: RootState) => getDiscoverFeatures(state)?.tm_license || TM_LICENSE_PERSONAL;

// COLLECTIONS

const getCollectionsPermissions = (state: RootState) => getPermissions(state)?.collections;
export const getCanEditCollections = (state: RootState) =>
  getCollectionsPermissions(state).can_edit_collections ?? false;
export const getCanFeatureCollections = (state: RootState) => getCollectionsPermissions(state).can_feature ?? false;
export const getCanListCollections = (state: RootState) => getCollectionsPermissions(state).can_list ?? false;

// COMMUNITIES

const getCommunitiesPermissions = (state: RootState) => getPermissions(state)?.communities;
export const getCanEditCommunities = (state: RootState) =>
  getCommunitiesPermissions(state).can_edit_communities ?? false;

// GLOBAL

const getGlobalPermissions = (state: RootState) => getPermissions(state)?.global;
const getGlobalFeatures = (state: RootState) => getGlobalPermissions(state)?.features;

export const getCanFollowTopic = (state: RootState) => getGlobalFeatures(state)?.follow_topics_limit !== 0;
export const getFollowTopicLimit = (state: RootState) => getGlobalFeatures(state)?.follow_topics_limit;
export const getCanAccessPaymentPortal = (state: RootState) => getGlobalFeatures(state)?.payment_portal || false;
export const getShouldShowBecomeMemberMarketing = (state: RootState) =>
  getGlobalFeatures(state)?.become_member || false;
export const getShouldShowBecomeProMemberMarketing = (state: RootState) =>
  getGlobalFeatures(state)?.become_pro_member || false;
export const getShouldDisplayNotifications = (state: RootState) => getGlobalFeatures(state)?.notifications || false;
export const getCanDisplayAppBanner = (state: RootState) => getGlobalFeatures(state)?.app_banner || false;

// PROFILE

const getProfilePermissions = (state: RootState) => getPermissions(state)?.profile;
const getProfileFeatures = (state: RootState) => getProfilePermissions(state)?.features;

const getCanDisplayProfile = (state: RootState) => getProfilePermissions(state)?.display || false;
export const getCanAccessProfile = (state: RootState) => getProfilePermissions(state)?.access || false;
export const getShouldRedirectToToplink = (state: RootState) => getProfileFeatures(state)?.redirect_to_toplink || false;
export const getShouldDisplayPremiumBadge = (state: RootState) => getProfileFeatures(state)?.membership_badge || false;

// BOOKMARKS
const getBookmarkFeatures = (state: RootState) => getPermissions(state)?.bookmarks;

export const getCanDisplayBookmarks = (state: RootState) => getBookmarkFeatures(state)?.display || false;

// MEMBERSHIP EVENTS

const getMembershipEventsFeatures = (state: RootState) => getPermissions(state)?.membership_events;

export const getHasAccessToMembershipEvents = (state: RootState) => getMembershipEventsFeatures(state)?.access || false;

// OTHER

export const getUserRole = (state: RootState) =>
  getPermissions(state)?.user_metadata?.role || Role.UNAUTHENTICATED_ROLE;
