import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { fetchInitiativeByIdFailure, fetchInitiativeByIdSuccessful } from 'entities/initiatives/actions';
import { FetchInitiativeForIdAction } from 'entities/initiatives/actionTypes';
import { Initiative } from 'entities/initiatives/types';

export function* fetchInitiativeByIdSaga(action: FetchInitiativeForIdAction) {
  try {
    const payload: Initiative = yield apiFetch(`project/${action.meta.id}`);
    yield put(fetchInitiativeByIdSuccessful(payload, action.meta));
  } catch {
    yield put(fetchInitiativeByIdFailure(action.meta, { logoutOnAuthError: true }));
  }
}
