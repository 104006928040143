import { SVGProps } from 'react';

export const ForwardIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      d="M41.116 28.102c-1.244 0-2.264.92-2.438 2.14-1.194 8.385-9.38 14.53-18.462 12.341-5.598-1.343-9.728-5.648-10.923-11.271-2.015-9.629 5.325-18.138 14.605-18.138v6.941c0 1.12 1.344 1.668 2.115.871l9.43-9.43a1.232 1.232 0 000-1.766L26.013.36c-.77-.771-2.115-.224-2.115.896v6.941c-12.29 0-21.994 11.147-19.506 23.886 1.493 7.738 7.215 13.685 14.904 15.402 12.017 2.687 22.766-5.474 24.308-16.596.224-1.468-.995-2.787-2.488-2.787zm-19.954 7.465V24.942h-.224l-4.404 1.568v1.717l2.513-.772v8.112h2.115zm8.509-10.5c-.448-.174-.92-.249-1.468-.249-.547 0-1.02.075-1.468.249a2.63 2.63 0 00-1.12.82 3.81 3.81 0 00-.721 1.42 8.02 8.02 0 00-.25 2.04v1.84c0 .797.1 1.493.275 2.04.174.548.423 1.046.746 1.419s.697.647 1.145.821c.448.174.92.249 1.468.249.547 0 1.02-.075 1.468-.249a2.63 2.63 0 001.12-.821 4.22 4.22 0 00.72-1.418c.175-.572.25-1.244.25-2.04v-1.842c0-.796-.1-1.492-.274-2.04-.174-.547-.423-1.045-.747-1.418a3.05 3.05 0 00-1.144-.821zm.025 6.394c0 .473-.025.871-.1 1.195-.074.323-.149.597-.273.796-.125.199-.274.348-.473.423-.2.074-.398.124-.622.124a1.63 1.63 0 01-.622-.124 1.085 1.085 0 01-.473-.423c-.124-.2-.224-.473-.299-.796-.074-.324-.099-.722-.099-1.195v-2.413c0-.473.025-.871.1-1.194.074-.324.149-.573.298-.772.15-.199.274-.348.473-.423.199-.075.398-.124.622-.124.224 0 .448.05.622.124.174.075.348.224.473.423.124.2.224.448.298.771.075.324.1.722.1 1.195v2.413h-.025z"
      fill="currentColor"
    />
  </svg>
);
