import { PropsWithChildren, ReactNode } from 'react';
import { styled } from 'styled-components';

import { Spinner } from 'common/components/Spinner';

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.textBright}1F; // 1/8 opacity
  padding-bottom: 10px;
  margin-bottom: 1rem;
  width: 100%;
  align-items: center;
`;

export const StyledSectionTitle = styled.h2`
  text-transform: capitalize;
  margin: 0;
  font-size: 1.5rem;
  height: 1.5rem;
  color: ${({ theme }) => theme.colors.textBright};
  display: table;
  width: fit-content;
`;

const StyledSpinner = styled(Spinner)`
  width: 15px;
  height: 15px;
  color: ${({ theme }) => theme.colors.textFade};
`;

export type SectionTitleUnderlinedProps = PropsWithChildren & {
  className?: string;
  dataTestId?: string;
  id?: string;
  isLoading?: boolean;
  rightElement?: ReactNode;
};

export const SectionTitleUnderlined = ({
  children,
  className,
  dataTestId,
  id,
  isLoading = false,
  rightElement = null,
}: SectionTitleUnderlinedProps) => (
  <StyledContainer className={className} data-test-id={dataTestId}>
    <StyledSectionTitle id={id}>{children}</StyledSectionTitle>
    {isLoading && !rightElement && <StyledSpinner />}
    {rightElement}
  </StyledContainer>
);
