import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { fetchCommunityFailure, fetchCommunitySuccess } from 'domains/collection/actions';
import { FetchCommunityAction } from 'domains/collection/actionTypes';
import { FetchCommunityResponse } from 'domains/collection/types/communityResponses';

export function* fetchCommunitySaga({ payload }: FetchCommunityAction) {
  try {
    const response = (yield apiFetch(`community/${payload.id}`, { method: 'GET' })) as FetchCommunityResponse;
    yield put(fetchCommunitySuccess(response));
  } catch (error) {
    yield put(fetchCommunityFailure(payload.id));
    console.error(`[fetchCommunitySaga] Failed to fetch community with ID '${payload.id}': ${error}`);
  }
}
