import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { searchPublicationsRejected, searchPublicationsSuccess } from 'domains/search/actions';
import { FetchPublicationSearchDefaultAction } from 'domains/search/actionTypes';
import { Knowledge } from 'entities/knowledge/types';

export function* fetchPublicationsSearchSaga({ meta }: FetchPublicationSearchDefaultAction) {
  if (!meta.query) {
    return;
  }
  try {
    const { query, offset, limit } = meta;
    const response: Knowledge[] = yield apiFetch(`publication`, {
      queryParams: { search: query, limit, offset },
    });
    yield put(searchPublicationsSuccess(meta, response));
  } catch (error) {
    yield put(searchPublicationsRejected(meta));
  }
}
