import { CSSProperties, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { styled } from 'styled-components';

import { pushHistory } from 'common/utilities';
import { trackEvent } from 'domains/analytics/actions';
import { useTranslations } from 'domains/language/useTranslations';
import { covers } from 'domains/resources/covers';
import advancedFeatures from 'domains/resources/data/advancedFeatures.json';
import getStarted from 'domains/resources/data/getStarted.json';
import introduction from 'domains/resources/data/introduction.json';
import { SplashStyles } from 'domains/resources/SplashStyles';
import { SourceVideo } from 'domains/resources/types';
import { VideoCover } from 'domains/resources/VideoCover';
import { setVisualMediaPlayer } from 'domains/visualMediaPlayer/actions';
import { MediaType } from 'domains/visualMediaPlayer/types';

const { StyledSplash, StyledCaption, StyledColumn } = SplashStyles;

const StyledVideoCard = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.textBright};
  cursor: pointer;
  overflow: hidden;
`;

const StyledLabel = styled.div`
  color: ${({ theme }) => theme.colors.textFade};
  font-size: 18px;
  font-weight: bold;
  padding: 25px 20px 8px;
  text-transform: uppercase;
`;

const StyledTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding: 0 20px 20px;
`;

const videos = [introduction, ...getStarted, ...advancedFeatures];

export const VideoCard = ({ url, name, image, length, ...rest }: SourceVideo & { style?: CSSProperties }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { textDictionary } = useTranslations();

  const urlParams = new URLSearchParams(location.search);
  const videoUrlParam = urlParams.get('video');

  useEffect(() => {
    if (videoUrlParam !== name) return;
    const index = videos.findIndex((item) => item.url === url);
    dispatch(
      setVisualMediaPlayer({
        id: name,
        image,
        mediaType: MediaType.videoViaEmbeddedHtmlPlayer,
        name,
        url,
        onClose: () => pushHistory(location.pathname),
        splash: (
          <StyledSplash>
            {index > 0 && (
              <StyledColumn>
                <StyledCaption>{textDictionary['app.resources.video.previous']}</StyledCaption>
                <VideoCard {...videos[index - 1]} />
              </StyledColumn>
            )}
            {index < videos.length - 1 && (
              <StyledColumn>
                <StyledCaption>{textDictionary['app.resources.video.next']}</StyledCaption>
                <VideoCard {...videos[index + 1]} />
              </StyledColumn>
            )}
          </StyledSplash>
        ),
        useReactPlayer: true,
      }),
    );
  }, [dispatch, image, location.pathname, name, textDictionary, url, videoUrlParam]);

  const onClick = () => {
    dispatch(trackEvent({ name: 'Play Media', page: 'Resources', entityName: name, entityId: url }));
    pushHistory(`${location.pathname}?video=${name}`);
  };

  return (
    <StyledVideoCard {...rest} onClick={onClick}>
      <VideoCover image={covers[image as keyof typeof covers]} videoLength={length} height="175px" />
      <StyledLabel>{textDictionary['app.resources.label.video']}</StyledLabel>
      <StyledTitle>{name}</StyledTitle>
    </StyledVideoCard>
  );
};
