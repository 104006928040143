import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { UIInput } from 'common/components/form/UIInput';
import { breakpoints } from 'common/styles';
import { useTranslations } from 'domains/language/useTranslations';
import { updateSearchQuery } from 'domains/search/actions';
import { getCurrentSearchTerm } from 'domains/search/selectors';
import { SearchVisibilitySectionType } from 'domains/search/types';

const StyledContainer = styled.div`
  width: 100%;

  @media ${breakpoints.extraLarge} {
    margin-bottom: 2rem;
  }
`;

type SearchInputProps = { autoFocus?: boolean; className?: string; type?: SearchVisibilitySectionType };

export const SearchInput = ({ className, type, autoFocus = false }: SearchInputProps) => {
  const dispatch = useDispatch();
  const currentSearchTerm = useSelector(getCurrentSearchTerm);
  const { textDictionary } = useTranslations();

  useEffect(() => {
    if (currentSearchTerm) {
      // We do this to force re-searching on component mount
      dispatchSearch(currentSearchTerm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClearHandle = () => {
    dispatchSearch('');
  };

  const dispatchSearch = (query?: string) => {
    dispatch(updateSearchQuery({ query, type }));
  };

  return (
    <StyledContainer className={className}>
      <UIInput
        autoFocus={autoFocus}
        clear={onClearHandle}
        isPending={false}
        name="searchQuery"
        onChange={dispatchSearch}
        placeholder={textDictionary['app.discover.topic.tabs.knowledge.filter.search_title']}
        value={currentSearchTerm || ''}
      />
    </StyledContainer>
  );
};
