import { CarouselSlider } from 'common/components';
import { ScreenSize } from 'common/styles/mediaQueries/types';
import { TopicTile } from 'domains/topic/components/topicTile/TopicTile';
import { useMedia } from 'domains/viewport/useMedia';
import { Topic } from 'entities/topics/types';

type TopicSliderProps = {
  hideFollowAction?: boolean;
  numberOfItems?: Partial<Record<ScreenSize, number>>;
  topics: Topic[];
};

export const TopicSlider = ({
  topics = [],
  numberOfItems = { small: 1, medium: 2, large: 3, extraLarge: 4, enormous: 5 },
  hideFollowAction = false,
}: TopicSliderProps) => {
  const length = useMedia(numberOfItems, 1);
  return (
    <CarouselSlider numberOfItems={length}>
      {topics.map((item) => (
        <TopicTile key={item.id} hideFollowAction={hideFollowAction} {...item} />
      ))}
    </CarouselSlider>
  );
};
