import { styled } from 'styled-components';

import { SectionTitleUnderlined } from 'common/components';
import { Tab } from 'domains/termsAndConditions/types';

const StyledTabBody = styled.div`
  line-height: 1.2rem;
  font-size: 0.9rem;
  margin-bottom: 3rem;

  p {
    margin-top: 0;
  }

  a {
    color: ${({ theme }) => theme.colors.blueDark};
  }
`;

const StyledSection = styled.div`
  position: relative;
  margin-bottom: 3rem;
`;

const StyledSectionBody = styled.div`
  line-height: 1.2rem;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.textFade};

  a {
    color: ${({ theme }) => theme.colors.blueDark};
  }
`;

type ContentBodyProps = { tab: Tab };

export const ContentBody = ({ tab }: ContentBodyProps) => (
  <>
    {tab.body && <StyledTabBody>{tab.body}</StyledTabBody>}
    {tab.sections.map((section) => (
      <StyledSection key={section.id}>
        <SectionTitleUnderlined id={section.id}>{section.title}</SectionTitleUnderlined>
        {section.body && <StyledSectionBody>{section.body}</StyledSectionBody>}
      </StyledSection>
    ))}
  </>
);
