import { MouseEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { StyledModalText, ListButton, ListIcon, ResetIcon, SuggestionsIcon, UIButton } from 'common/components';
import {
  fetchCoachmarksVisibilityStatus,
  fetchUsedCoachmarks,
  removeCoachmarksFromUsed,
  toggleCoachmarks,
} from 'domains/coachmarks/actions';
import { getCoachmarksAreEnabled } from 'domains/coachmarks/selectors';
import { useTranslations } from 'domains/language/useTranslations';
import { pushModalScreen } from 'domains/modal/actions';
import { ModalHeader } from 'domains/modal/components/ModalHeader';
import { ModalContentType } from 'domains/modal/types';

const StyledListIcon = styled(ListIcon)`
  width: 22px;
  height: 22px;
  margin-right: 1rem;
`;

const StyledResetIcon = styled(ResetIcon)`
  width: 26px;
  height: 26px;
  margin-right: 1rem;
`;

const StyledSuggestionsIcon = styled(SuggestionsIcon)`
  width: 26px;
  height: 26px;
  margin-right: 1rem;
`;

export const CoachmarksSettingsModal = () => {
  const dispatch = useDispatch();
  const coachmarksAreEnabled = useSelector(getCoachmarksAreEnabled);
  const { textDictionary } = useTranslations();

  const handleOpenModal = (id: ModalContentType) => (event: MouseEvent) => {
    event.preventDefault();
    dispatch(pushModalScreen({ id }));
  };

  const handleResetCoachmarksButtonClick = (event: MouseEvent) => {
    event.preventDefault();
    dispatch(removeCoachmarksFromUsed());
    dispatch(
      pushModalScreen({
        id: 'Reset Coachmarks',
      }),
    );
  };

  const handleToggleCoachmarksButtonClick = (event: MouseEvent) => {
    event.preventDefault();
    dispatch(toggleCoachmarks());
  };

  useEffect(() => {
    dispatch(fetchCoachmarksVisibilityStatus());
    dispatch(fetchUsedCoachmarks());
  }, [dispatch]);

  const localizedKey = 'app.coachmarks.settings_modal';

  const getLabel = (type: string) => textDictionary[`${localizedKey}.${type}.label`];

  const getDescription = (type: string) => textDictionary[`${localizedKey}.${type}.description`];

  return (
    <>
      <ModalHeader title={textDictionary['app.coachmarks.settings_modal.title']} />
      <StyledModalText style={{ fontWeight: 'normal' }}>
        {textDictionary['app.coachmarks.settings_modal.text']}
      </StyledModalText>
      <ListButton
        descriptionText={getDescription('view_all_coachmarks_button')}
        iconElement={<StyledListIcon />}
        onClick={handleOpenModal('All Coachmarks')}
      >
        {getLabel('view_all_coachmarks_button')}
      </ListButton>
      <ListButton
        descriptionText={getDescription('reset_all_coachmarks_button')}
        iconElement={<StyledResetIcon />}
        onClick={handleResetCoachmarksButtonClick}
      >
        {getLabel('reset_all_coachmarks_button')}
      </ListButton>
      <ListButton
        descriptionText={getDescription('suggest_improvements_button')}
        iconElement={<StyledSuggestionsIcon />}
        href="mailto:intelligence@weforum.org"
      >
        {getLabel('suggest_improvements_button')}
      </ListButton>
      <UIButton
        onClick={handleToggleCoachmarksButtonClick}
        error={coachmarksAreEnabled || undefined}
        variant={'filled'}
        fullwidth
        style={{ marginTop: '1.5rem' }}
      >
        {
          textDictionary[
            coachmarksAreEnabled
              ? 'app.coachmarks.settings_modal.disable_coachmarks'
              : 'app.coachmarks.settings_modal.enable_coachmarks'
          ]
        }
      </UIButton>
    </>
  );
};
