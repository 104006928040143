import { ComponentPropsWithoutRef, useState } from 'react';
import { styled } from 'styled-components';

import { UIButton } from 'common/components/index';
import { copyTextToClipboard } from 'common/utilities';
import { useTranslations } from 'domains/language/useTranslations';

type StyledProps = { isDisabled?: boolean };

const StyledCopyContainer = styled.div<StyledProps>`
  transition: background-color 0.3s;
  border-radius: 20px;
  margin: 10px 0;
  display: flex;
  justify-content: stretch;
  flex: 1;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  border: 1px solid ${({ theme }) => theme.colors.inputText};
  & > button {
    margin-right: -1px;
    margin-top: -1px;
    margin-bottom: -1px;
  }
`;

const StyledInput = styled.input`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.inputText};
  display: inline-block;
  flex-grow: 1;
  min-width: 0;
  padding: 10px 16px;
  text-overflow: ellipsis;

  &:focus,
  &:hover {
    outline: none;
    background-color: transparent;
  }
`;

type CopyLinkFieldProps = ComponentPropsWithoutRef<'div'> & { isDisabled?: boolean; url: string };

export const CopyLinkField = ({ isDisabled, url, ...rest }: CopyLinkFieldProps) => {
  const { textDictionary } = useTranslations();
  const [isCopied, setIsCopied] = useState(false);

  const onCopyClicked = () => {
    if (isDisabled) {
      return;
    }
    setIsCopied(true);
    copyTextToClipboard(url);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <StyledCopyContainer {...rest} onClick={onCopyClicked} isDisabled={isDisabled}>
      <StyledInput value={url} disabled={true} />
      <UIButton variant={'filled'} success={isCopied} disabled={isDisabled}>
        {isCopied ? textDictionary['app.clipboard.copied'] : textDictionary['app.clipboard.copy']}
      </UIButton>
    </StyledCopyContainer>
  );
};
