import { NotificationModalMeta, NotificationPayload, UpdateNotificationMeta } from 'domains/notifications/types';
import { ErrorAction, ErrorMeta, ErrorPayload } from 'types';

export enum NotificationActionType {
  FETCH = 'domains/USER_NOTIFICATIONS',
  FETCH_FULFILLED = 'domains/USER_NOTIFICATIONS_FULFILLED',
  FETCH_REJECTED = 'domains/USER_NOTIFICATIONS_REJECTED',
  UPDATE = 'domains/USER_NOTIFICATION_UPDATE',
  UPDATE_FULFILLED = 'domains/USER_NOTIFICATION_UPDATE_FULFILLED',
  UPDATE_REJECTED = 'domains/USER_NOTIFICATION_UPDATE_REJECTED',
  REMOVE = 'domains/USER_NOTIFICATION_REMOVE',
  REMOVE_FULFILLED = 'domains/USER_NOTIFICATION_REMOVE_FULFILLED',
  REMOVE_REJECTED = 'domains/USER_NOTIFICATION_REMOVE_REJECTED',
  MARK_ALL_DONE = 'domains/USER_NOTIFICATION_MARK_ALL_DONE',
  MARK_ALL_DONE_FULFILLED = 'domains/USER_NOTIFICATION_MARK_ALL_DONE_FULFILLED',
  MARK_ALL_DONE_REJECTED = 'domains/USER_NOTIFICATION_MARK_ALL_DONE_REJECTED',
  DISPLAY_MODAL = 'domains/USER_NOTIFICATION_DISPLAY_MODAL',
}

export type FetchNotificationsDefaultAction = { meta: { offset: number }; type: NotificationActionType.FETCH };

export type FetchNotificationsFulfilledAction = {
  meta: { offset: number };
  payload: NotificationPayload;
  type: NotificationActionType.FETCH_FULFILLED;
};

export type FetchNotificationsRejectedAction = ErrorAction & { type: NotificationActionType.FETCH_REJECTED };
export type UpdateNotificationDefaultAction = { meta: UpdateNotificationMeta; type: NotificationActionType.UPDATE };

export type UpdateNotificationFulfilledAction = {
  meta: UpdateNotificationMeta;
  type: NotificationActionType.UPDATE_FULFILLED;
};

export type UpdateNotificationRejectedAction = {
  meta: ErrorMeta & UpdateNotificationMeta;
  payload: ErrorPayload;
  type: NotificationActionType.UPDATE_REJECTED;
};

export type RemoveNotificationDefaultAction = { meta: { id: string }; type: NotificationActionType.REMOVE };
export type RemoveNotificationFulfilledAction = { meta: { id: string }; type: NotificationActionType.REMOVE_FULFILLED };

export type RemoveNotificationRejectedAction = {
  meta: ErrorMeta & { id: string };
  payload: ErrorPayload;
  type: NotificationActionType.REMOVE_REJECTED;
};

export type MarkAllNotificationDoneDefaultAction = { type: NotificationActionType.MARK_ALL_DONE };
export type MarkAllNotificationDoneFulfilledAction = { type: NotificationActionType.MARK_ALL_DONE_FULFILLED };

export type MarkAllNotificationDoneRejectedAction = ErrorAction & {
  type: NotificationActionType.MARK_ALL_DONE_REJECTED;
};

type NotificationDisplayModalAction = { meta: NotificationModalMeta; type: NotificationActionType.DISPLAY_MODAL };

export type UserNotificationAction =
  | FetchNotificationsDefaultAction
  | FetchNotificationsFulfilledAction
  | FetchNotificationsRejectedAction
  | UpdateNotificationDefaultAction
  | UpdateNotificationFulfilledAction
  | UpdateNotificationRejectedAction
  | RemoveNotificationDefaultAction
  | RemoveNotificationFulfilledAction
  | RemoveNotificationRejectedAction
  | MarkAllNotificationDoneDefaultAction
  | MarkAllNotificationDoneFulfilledAction
  | MarkAllNotificationDoneRejectedAction
  | NotificationDisplayModalAction;
