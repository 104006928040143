import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { UIInput } from 'common/components/form/UIInput';
import { DimensionsList } from 'domains/createMapDetail/components/keyIssueList/DimensionsList';
import { toggleCustomKeyIssueForm } from 'domains/customKeyIssue/actions';
import { CreateCustomKeyIssueButton } from 'domains/customKeyIssue/components/CreateCustomKeyIssueButton';
import { useTranslations } from 'domains/language/useTranslations';
import { deactivateModal, pushModalScreen } from 'domains/modal/actions';
import { ModalHeader } from 'domains/modal/components/ModalHeader';
import { fetchKeyIssues, updateSearchTerm } from 'entities/keyIssues/actions';
import { getDimensionsSearchTerm } from 'entities/keyIssues/selectors';
import { getCanAccessCreate, getCreateMapCustomKeyIssueAccess } from 'entities/user/selectors';

const StyledDimensionsList = styled(DimensionsList)`
  padding-right: 0;
`;

const StyledSearchInput = styled(UIInput)`
  margin-bottom: 1rem;
`;

export const KeyIssueModal = () => {
  const dispatch = useDispatch();
  const searchTerm = useSelector(getDimensionsSearchTerm);
  const accessToCreate = useSelector(getCanAccessCreate);
  const canCreateCustomKeyIssue = useSelector(getCreateMapCustomKeyIssueAccess);
  const { textDictionary } = useTranslations();

  useEffect(() => {
    if (accessToCreate) {
      dispatch(fetchKeyIssues({ searchTerm, offset: 0 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, accessToCreate]);

  const handleSearchInputChange = (value: string) => {
    dispatch(updateSearchTerm(value || ''));
  };

  const onEditKeyIssueSelect = (id?: string) => {
    if (canCreateCustomKeyIssue) {
      dispatch(toggleCustomKeyIssueForm({ id, isActive: true }));
      dispatch(pushModalScreen({ id: 'Add Custom Key Issue' }));
    } else {
      dispatch(pushModalScreen({ id: 'Create Upgrade Membership Custom Key Issue' }));
    }
  };

  const dismissModal = () => dispatch(deactivateModal());

  const addButton = <CreateCustomKeyIssueButton onAdd={onEditKeyIssueSelect} showStarIcon={!canCreateCustomKeyIssue} />;

  return (
    <>
      <ModalHeader
        title={textDictionary['app.topic.key_issues']}
        dismissAction={dismissModal}
        rightElement={addButton}
      />
      <StyledSearchInput onChange={handleSearchInputChange} value={searchTerm ?? ''} />
      <StyledDimensionsList onEditKeyIssueSelect={onEditKeyIssueSelect} />
    </>
  );
};
