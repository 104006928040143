import { hasOwnProperty } from 'common/utilities/prototype';
import { RootState } from 'types';

export const getDimensionsIsPending = (state: RootState) => state.entities.keyIssues.isPending;

export const getDimensions = (searchTerm?: string) => (state: RootState) => {
  const { byId } = state.entities.keyIssues;
  return getSearchDimensionIds(state, searchTerm).map((id) => byId[id]);
};

export const getIsDimensionsSearchCached = ({ entities }: RootState, searchTerm?: string) =>
  !!searchTerm && hasOwnProperty(entities.keyIssues.idsForSearch, searchTerm);

export const getSearchDimensionIds = (state: RootState, searchTerm?: string) => {
  const { idsForSearch } = state.entities.keyIssues;
  searchTerm = searchTerm || '';
  const ids = hasOwnProperty(idsForSearch, searchTerm) ? idsForSearch[searchTerm] : [];
  return ids;
};

export const getDimensionsHasNextPage = (state: RootState) => state.entities.keyIssues.hasNextPage;

export const getDimensionsSearchTerm = (state: RootState) => state.entities.keyIssues.searchTerm;

export const getDimensionById = (id: string) => (state: RootState) => state.entities.keyIssues.byId[id];

export const getDimensionByIdIsFetching = (id: string) => (state: RootState) =>
  state.entities.keyIssues.byId[id]?.isFetching || false;
