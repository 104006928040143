import { styled } from 'styled-components';

import { breakpoints } from 'common/styles';

type SectionProps = { inverted?: boolean; overwrite?: boolean; secondary?: boolean };

export const Section = styled.section<SectionProps>`
  display: flex;
  flex-direction: column;

  padding: 2rem 1rem;
  background-color: ${({ theme, inverted }) =>
    inverted ? theme.colors.aboutUsBackgroundSecondary : theme.colors.aboutUsBackgroundPrimary};

  @media ${breakpoints.medium} {
    padding: 3rem 4rem;
  }

  @media ${breakpoints.large} {
    padding: 4rem 4rem;
  }
`;
