import { RootState } from 'types';

export const getUnresolvedIncidents = (state: RootState) =>
  state.domains.incidents.unresolved.data.filter(({ id }) => !state.domains.incidents.dismissed.includes(id));

export const getTopUnresolvedIncident = (state: RootState) => {
  const data = getUnresolvedIncidents(state);
  return (data && data[0]) || null;
};

export const getDismissedIncidents = (state: RootState) => state.domains.incidents.dismissed;
