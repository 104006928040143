import {
  AuthenticationType,
  LoginFulfilledAction,
  LoginRejectedAction,
  LogoutAction,
  FWSLoginAction,
  TriggeredFirstTokenRenewAction,
} from 'domains/authentication/actionTypes';

export const fwsLogin = (payload: FWSLoginAction['payload']): FWSLoginAction => ({
  type: AuthenticationType.FWS_LOGIN,
  payload,
});

export const fwsTriggeredFirstTokenRenew = (): TriggeredFirstTokenRenewAction => ({
  type: AuthenticationType.FWS_TRIGGERED_FIRST_TOKEN_RENEW,
});

export const authenticateSuccess = (
  payload: LoginFulfilledAction['payload'],
  meta: LoginFulfilledAction['meta'],
): LoginFulfilledAction => ({
  type: AuthenticationType.LOGIN_FULFILLED,
  payload,
  meta,
});

export const authenticateFailure = (payload: LoginRejectedAction['payload']): LoginRejectedAction => ({
  type: AuthenticationType.LOGIN_REJECTED,
  payload,
});

export const unauthenticate = (payload?: LogoutAction['payload']): LogoutAction => ({
  type: AuthenticationType.LOGOUT,
  payload,
});
