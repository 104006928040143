import { ReactElement, MouseEvent } from 'react';
import { styled } from 'styled-components';

import { Spinner } from 'common/components/Spinner';

const StyledSpinner = styled(Spinner)`
  color: inherit;
  width: 20px;
  height: 20px;
`;

type ListItemButtonProps = {
  ariaLabel?: string;
  href?: string;
  iconElement: ReactElement;
  isActive?: boolean;
  isLoading?: boolean;
  onClick?: (event: MouseEvent) => void;
  title?: string;
};

const StyledButton = styled.button<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  padding: 0;
  min-height: 40px;
  box-sizing: border-box;
  font-size: 1rem;
  cursor: pointer;
  transition:
    background-color 0.3s,
    color 0.3s;
  border: none;
  text-decoration: none;
  white-space: nowrap;
  background-color: unset;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.buttonListItemText};

  &:hover {
    color: ${({ theme }) => theme.colors.buttonNeutralActiveText};
  }
`;

export const ListItemButton = ({
  onClick,
  href,
  iconElement,
  isActive = false,
  isLoading = false,
  title,
}: ListItemButtonProps) => {
  const onClickHandle = (event: MouseEvent) => {
    event.stopPropagation();
    if (onClick) {
      onClick(event);
    }
  };
  return href ? (
    <StyledButton as="a" href={href} target="_blank" rel="noopener noreferrer" isActive={isActive}>
      {isLoading ? <StyledSpinner /> : iconElement}
      <span style={{ marginLeft: '10px' }}>{title}</span>
    </StyledButton>
  ) : (
    <StyledButton onClick={onClickHandle} isActive={isActive}>
      {isLoading ? <StyledSpinner /> : iconElement}
      <span style={{ marginLeft: '10px' }}>{title}</span>
    </StyledButton>
  );
};
