import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { scrapeTopicContentUrlFulfilled, scrapeTopicContentUrlRejected } from 'domains/customContentAndSources/actions';
import { TopicContentScrapeUrl } from 'domains/customContentAndSources/actionTypes';
import { NewCustomKnowledge } from 'domains/customContentAndSources/types';
import { FetchError } from 'types';

type KnowledgePreviewResponse = Omit<NewCustomKnowledge, 'media'> & { attachment?: NewCustomKnowledge['media'] };

export function* scrapeTopicContentUrlSaga({ payload }: TopicContentScrapeUrl) {
  try {
    const response: KnowledgePreviewResponse = yield apiFetch(`knowledge_preview`, {
      method: 'POST',
      body: { simple: true, force: true, link: payload.url },
    });

    const { attachment, ...rest } = response;
    let newCustomKnowledge: NewCustomKnowledge = rest;
    if (attachment) {
      newCustomKnowledge = { ...rest, media: attachment };
    }
    yield put(scrapeTopicContentUrlFulfilled(newCustomKnowledge, payload));
  } catch (error) {
    yield put(scrapeTopicContentUrlRejected({ error: error as FetchError }, { logoutOnAuthError: true, ...payload }));
  }
}
