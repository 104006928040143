import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { FilterButton, HeaderTitle, MainLayout, SectionTitleUnderlined } from 'common/components';
import { breakpoints, mediaStyles } from 'common/styles';
import { trackEvent } from 'domains/analytics/actions';
import { getIsAuthenticated } from 'domains/authentication/selectors';
import { useTranslations } from 'domains/language/useTranslations';
import { activateModal } from 'domains/modal/actions';
import { NoAccessSidebar } from 'domains/monitor/noAccess/components/NoAccessSidebar';
import { EmptySearchResult } from 'domains/search/components/EmptySearchResult';
import { InteractiveDataResults } from 'domains/search/components/interactiveData/InteractiveDataResults';
import { KeyIssueResults } from 'domains/search/components/keyIssues/KeyIssueResults';
import { PublicationsResults } from 'domains/search/components/publications/PublicationsResults';
import { SearchFilter } from 'domains/search/components/SearchFilter';
import { SearchInput } from 'domains/search/components/SearchInput';
import { TopicResults } from 'domains/search/components/topics/TopicResults';
import { VideosResults } from 'domains/search/components/videos/VideosResult';
import { getSearchHasResults } from 'domains/search/selectors';
import { useSearch } from 'domains/search/useSearchVisibility';
import { getCanAccessCreate, getCanAccessMonitor } from 'entities/user/selectors';

const StyledContent = styled.div`
  box-sizing: border-box;
  ${mediaStyles(({ spacing }) => `padding: 0 ${spacing}rem ${spacing}rem ${spacing}rem;`)};

  @media ${breakpoints.extraLarge} {
    padding: 3rem;
  }
`;

const StyledHeaderContainer = styled.div`
  width: 100%;
  margin-bottom: 1rem;

  ${mediaStyles(
    ({ spacing }) => `
      padding: 0 ${spacing}rem;
      margin-top: ${spacing}rem;
    `,
  )};
`;

const StyledSearchContainer = styled.div`
  padding: 1rem 0;
  display: flex;
  width: 100%;
`;

const StyledResultsContainer = styled.div`
  box-sizing: border-box;
  scroll-padding-top: 2rem;
`;

export const SearchPage = () => {
  const dispatch = useDispatch();
  const { textDictionary } = useTranslations();
  const accessCreate = useSelector(getCanAccessCreate);
  const accessMonitor = useSelector(getCanAccessMonitor);
  const { searchVisibility } = useSearch();
  const hasResults = useSelector(getSearchHasResults);
  const isAuthenticated = useSelector(getIsAuthenticated);

  const hideMarketing = accessMonitor && accessCreate;

  const onFilterClick = () => dispatch(activateModal({ id: 'Search Filter' }));

  const searchBar = <SearchInput autoFocus={true} />;

  const sidePanel = (
    <>
      <SectionTitleUnderlined>{textDictionary['app.search.title']}</SectionTitleUnderlined>
      {searchBar}
      <SearchFilter />
      {!hideMarketing && (
        <NoAccessSidebar
          trackPage="Search"
          text={textDictionary[isAuthenticated ? 'app.marketing.upgrade_message' : 'app.user.sign_up_message']}
        />
      )}
    </>
  );

  const titleElement = (
    <StyledHeaderContainer>
      <HeaderTitle>{textDictionary['app.search.title']}</HeaderTitle>
      <StyledSearchContainer>
        {searchBar}
        <FilterButton onClick={onFilterClick} />
      </StyledSearchContainer>
    </StyledHeaderContainer>
  );

  useEffect(() => {
    dispatch(trackEvent({ name: 'Browse page', page: 'Search' }));
  }, [dispatch]);

  return (
    <MainLayout headerElement={titleElement} sidePanel={sidePanel} hideSidePanel={false}>
      <StyledContent>
        <StyledResultsContainer>
          {!hasResults && <EmptySearchResult />}
          {searchVisibility.topics && <TopicResults />}
          {searchVisibility.keyIssues && <KeyIssueResults />}
          {searchVisibility.publications && <PublicationsResults />}
          {searchVisibility.videos && <VideosResults />}
          {searchVisibility.interactiveData && <InteractiveDataResults />}
        </StyledResultsContainer>
      </StyledContent>
    </MainLayout>
  );
};
