import { SVGProps } from 'react';

export const EmailIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 16" {...props}>
    <g fill="currentColor">
      <path
        d="M49.633,24H33.465A3.465,3.465,0,0,0,30,27.469V39.017a3.465,3.465,0,0,0,3.465,3.465H49.633A3.465,3.465,0,0,0,53.1,39.017V27.469A3.465,3.465,0,0,0,49.633,24Zm-16.168,2.31H49.633a1.155,1.155,0,0,1,1.155,1.155L41.549,33.1,32.31,27.469a1.155,1.155,0,0,1,1.155-1.155Zm17.323,12.7a1.155,1.155,0,0,1-1.155,1.155H33.465a1.155,1.155,0,0,1-1.155-1.155V30.1l8.639,5.278a1.154,1.154,0,0,0,1.155,0L50.788,30.1Z"
        transform="translate(-30 -24.004)"
      />
    </g>
  </svg>
);
