import { css, styled } from 'styled-components';

import { ChevronIcon } from 'common/components/icons';
import { mediaStyles } from 'common/styles';

const StyledArrow = styled(ChevronIcon)`
  width: 16px;
  height: 16px;
  color: white;
`;

const StyledRightArrow = styled(ChevronIcon)`
  width: 16px;
  height: 16px;
  color: white;
`;

const StyledLeftArrow = styled(StyledArrow)`
  transform: rotate(180deg);
`;

const StyledArrowContainer = styled.div`
  transition: 0.3s all;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: absolute;
  top: calc(50% - 20px);
`;

const sharedArrowStyles = (direction: 'right' | 'left') => css`
  ${mediaStyles(({ spacing }) => `${direction}: ${spacing}rem;`)};
`;

const StyledRightArrowContainer = styled(StyledArrowContainer)`
  ${sharedArrowStyles('right')}
`;

const StyledLeftArrowContainer = styled(StyledArrowContainer)`
  ${sharedArrowStyles('left')}
`;

type CarouselArrowsProps = { className?: string; scrollToNext: () => void; scrollToPrevious: () => void };

export const CarouselArrows = ({ scrollToPrevious, scrollToNext, className }: CarouselArrowsProps) => (
  <>
    <StyledLeftArrowContainer onClick={scrollToPrevious} className={className}>
      <StyledLeftArrow name="Chevron2" />
    </StyledLeftArrowContainer>
    <StyledRightArrowContainer onClick={scrollToNext} className={className}>
      <StyledRightArrow name="Chevron2" />
    </StyledRightArrowContainer>
  </>
);
