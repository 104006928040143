import { SVGProps } from 'react';

export const UserPlaceholderIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" {...props}>
    <path d="M0 30a30 30 0 1 1 30 30A30 30 0 0 1 0 30z" fill={'#474747'} />
    <path
      d="M21.37 9.596c-.66 0-1.177-.518-1.177-1.177a5.464 5.464 0 0 0-.82-2.908c-.514-.839-1.246-1.58-2.196-2.225a11.04 11.04 0 0 0-3.094-1.437 11.636 11.636 0 0 0-3.31-.494c-1.09 0-2.192.165-3.308.494A11.04 11.04 0 0 0 4.37 3.286c-.95.644-1.682 1.386-2.196 2.225a5.464 5.464 0 0 0-.82 2.908c0 .66-.518 1.177-1.178 1.177C-.482 9.596-1 9.078-1 8.42-1 6.93-.611 5.551.167 4.281c.69-1.128 1.651-2.11 2.881-2.944C4.16.583 5.41.001 6.798-.409A13.977 13.977 0 0 1 10.774-1c1.317 0 2.643.197 3.976.59 1.388.411 2.638.993 3.75 1.747 1.23.835 2.19 1.816 2.88 2.944a7.776 7.776 0 0 1 1.168 4.138c0 .66-.518 1.177-1.178 1.177z"
      fill="#fff"
      transform="translate(19 33.306)"
    />
    <path
      d="M6.476 2A4.48 4.48 0 0 0 2 6.476a4.48 4.48 0 0 0 4.476 4.475 4.48 4.48 0 0 0 4.475-4.475A4.48 4.48 0 0 0 6.476 2m0-2a6.476 6.476 0 1 1 0 12.951A6.476 6.476 0 0 1 6.476 0z"
      fill="#fff"
      stroke="none"
      transform="translate(23.298 17)"
    />
  </svg>
);
