import { useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { useTrack } from 'common/utilities/useTrack';
import { getIsAuthenticated } from 'domains/authentication/selectors';
import { BriefingSection } from 'domains/landing/create/BriefingSection';
import { CreateSection } from 'domains/landing/create/CreateSection';
import { EventsSection } from 'domains/landing/create/EventsSection';
import { Footer } from 'domains/landing/footer/Footer';
import { LandingPage } from 'domains/landing/LandingPage';
import { TopBanner } from 'domains/landing/topBanner/TopBanner';
import { HighlightsSections } from 'domains/landing/topics/HighlightsSections';
import { TransformationMapSection } from 'domains/landing/topics/TransformationMapSection';

export const Landing = () => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  useTrack({ name: 'Browse page', page: 'Landing' });

  return (
    <Container>
      {isAuthenticated ? (
        <LandingPage />
      ) : (
        <>
          <TopBanner />
          <TransformationMapSection />
          <HighlightsSections />
          <CreateSection />
          <BriefingSection />
          <EventsSection />
        </>
      )}
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  color: ${({ theme }) => theme.colors.landingText};
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
`;
