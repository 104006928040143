import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

import { ChevronIcon } from 'common/components/icons';

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  color: ${({ theme }) => theme.colors.textBright};
  text-decoration: none;
  margin-bottom: 1.5rem;
  width: fit-content;

  &:focus-visible,
  &:hover {
    text-decoration: underline;
  }
`;

const StyledContainer = styled.div`
  color: ${({ theme }) => theme.colors.textFade};
  margin-bottom: 1.5rem;
`;

const StyledSectionSubTitle = styled.div`
  font-size: 1rem;
  font-weight: bold;
`;

const StyledChevron = styled(ChevronIcon)`
  width: 14px;
  height: 14px;
`;

type SectionSubTitleProps = { seeAllLink?: string; title: string };

export const SectionSubTitle = ({ seeAllLink, title }: SectionSubTitleProps) =>
  seeAllLink ? (
    <StyledLink to={seeAllLink}>
      <StyledSectionSubTitle>{title}</StyledSectionSubTitle>
      <StyledChevron />
    </StyledLink>
  ) : (
    <StyledContainer>
      <StyledSectionSubTitle>{title}</StyledSectionSubTitle>
    </StyledContainer>
  );
