import { useSelector } from 'react-redux';

import { StyledGrid, SectionTitleUnderlined, Tile, SeeAllButton } from 'common/components';
import { useTranslations } from 'domains/language/useTranslations';
import { getInteractiveDataSearchResults, getInteractiveDataSearchIsPending } from 'domains/search/selectors';

export const InteractiveDataResults = () => {
  const { textDictionary } = useTranslations();
  const isPending = useSelector(getInteractiveDataSearchIsPending);
  const data = useSelector(getInteractiveDataSearchResults(4));

  if (data.length === 0) {
    return null;
  }

  return (
    <>
      <SectionTitleUnderlined
        isLoading={isPending}
        id="interactiveData"
        rightElement={<SeeAllButton link="/search/interactive-data" />}
      >
        {textDictionary['app.discover.topic.tabs.data.title']}
      </SectionTitleUnderlined>
      <StyledGrid numberOfItems={[1, 2, 3, 4, 5]}>
        {data.map((article) => (
          <Tile
            key={article.id}
            title={article.title}
            subtitle={article.source?.name}
            link={`/search/interactive-data/${article.id}`}
            image={article.images?.thumbnail}
          />
        ))}
      </StyledGrid>
    </>
  );
};
