export enum AuthenticationType {
  FWS_LOGIN = 'entities/FWS_LOGIN',
  FWS_TRIGGERED_FIRST_TOKEN_RENEW = 'entities/TRIGGERED_FIRST_TOKEN_RENEW',
  LOGIN = 'entities/USER_AUTHENTICATE',
  LOGIN_FULFILLED = 'entities/USER_AUTHENTICATE_SUCCESS',
  LOGIN_REJECTED = 'entities/USER_AUTHENTICATE_FAILURE',
  LOGOUT = 'entities/USER_UNAUTHENTICATE',
}

export type TriggeredFirstTokenRenewAction = { type: AuthenticationType.FWS_TRIGGERED_FIRST_TOKEN_RENEW };

export type FWSLoginAction = {
  payload: { accessToken: string; expiresIn?: number; idToken?: string; refreshToken?: string };
  type: AuthenticationType.FWS_LOGIN;
};

type LoginDefaultAction = {
  payload: { email: string; password: string; isSSO?: boolean };
  type: AuthenticationType.LOGIN;
};

export type LoginFulfilledAction = {
  meta: { event: { userId: string } };
  payload: {
    accessToken: string;
    expiresIn?: number;
    idToken?: string; // TODO: Make mandatory
    refreshToken?: string;
  };
  type: AuthenticationType.LOGIN_FULFILLED;
};

export type LoginRejectedAction = { payload: { error: boolean }; type: AuthenticationType.LOGIN_REJECTED };
export type LogoutAction = { payload?: { isUserLogout?: boolean }; type: AuthenticationType.LOGOUT };

export type AuthenticationAction =
  | FWSLoginAction
  | TriggeredFirstTokenRenewAction
  | LoginDefaultAction
  | LoginFulfilledAction
  | LoginRejectedAction
  | LogoutAction;
