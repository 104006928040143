import { styled } from 'styled-components';

import { breakpoints } from 'common/styles';

export const SectionTitle = styled.h2`
  margin: 0 0 1rem 0;
  font-weight: bold;
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.textBright};
  @media ${breakpoints.medium} {
    font-size: 3rem;
  }
`;
