import { getAllCoachmarks, getCoachmarkById } from 'entities/coachmarks/selectors';
import { RootState } from 'types';

const getFromDomain = (state: RootState) => state.domains.coachmarks;

export const getCoachmarksAreEnabled = (state: RootState) => getFromDomain(state).isEnabled;

export const getUsedCoachmarks = (state: RootState) => getFromDomain(state).usedIds;

const getUnusedCoachmarks = (state: RootState) =>
  getAllCoachmarks(state).filter((id) => !getUsedCoachmarks(state).includes(id));

export const getCoachmarksInDOM = (state: RootState) => getFromDomain(state).inDOMIds;

export const getUnusedCoachmarksInDOM = (state: RootState) =>
  getUnusedCoachmarks(state)
    .filter((value) => getCoachmarksInDOM(state).includes(value))
    .map((id) => getCoachmarkById(id)(state));

export const getCoachmarksAreShowing = (state: RootState) =>
  (getCoachmarksAreEnabled(state) && getUnusedCoachmarksInDOM(state).length !== 0) || false;
