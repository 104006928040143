import { keyframes, styled } from 'styled-components';

const BriefingProgress = styled.div`
  position: relative;
  margin-top: 10px;
  background: ${({ theme }) => theme.colors.progressBarBackground};
  border-radius: 12px;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.progressBarBorder};
  padding: 1px;
`;

const BriefingProgressText = styled.span`
  color: white;
  position: absolute;
  left: 50%;
  top: 3px;
  transform: translate(-50%);
  white-space: nowrap;
  z-index: 1000;
`;

const gradient = keyframes`
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
`;

const BriefingProgressLoaded = styled.div`
  background: ${({ theme }) => `linear-gradient(
          90deg, ${theme.colors.blue} 40%, #6592f0 50%, ${theme.colors.blue} 60%)`};
  background-size: 200% 200%;
  border-radius: 10px;
  transition: width 1s;
  height: 24px;
  animation: ${gradient} 5s ease infinite;
`;

type ProgressBarProps = { percentage: number; text: string };

export const ProgressBar = ({ percentage, text }: ProgressBarProps) => (
  <BriefingProgress>
    <BriefingProgressLoaded style={{ width: `${percentage}%` }} />
    <BriefingProgressText>{text}</BriefingProgressText>
  </BriefingProgress>
);
