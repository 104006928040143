import { PropsWithChildren, useState } from 'react';
import { styled, ThemeProvider } from 'styled-components';

import { StyledGrid, InfinityLoader } from 'common/components';
import { UIInput } from 'common/components/form/UIInput';
import { maxWidth } from 'common/styles/mediaQueries/breakpoints';
import { Status } from 'common/types/status';
import { useTranslations } from 'domains/language/useTranslations';
import { darkTheme } from 'domains/theme/constants/theme';
import { TopicTile } from 'domains/topic/components/topicTile/TopicTile';
import { Topic } from 'entities/topics/types';

type CollectionSearchableTopicListProps = PropsWithChildren & {
  loadNextPage: () => void;
  nextPageMayExist: boolean;
  status: Status;
  topics: Topic[];
};

export const CollectionSearchableTopicList = ({
  children,
  loadNextPage,
  nextPageMayExist,
  status,
  topics,
}: CollectionSearchableTopicListProps) => {
  const { textDictionary } = useTranslations();
  const [searchValue, setSearchValue] = useState<string>('');
  const filteredTopics = topics.filter((topic) => topic.name.toLowerCase().includes(searchValue.toLowerCase().trim()));

  return (
    <Container>
      <ThemeProvider theme={darkTheme}>
        <SearchBar>
          {children}
          <UIInput
            clear={() => setSearchValue('')}
            onChange={setSearchValue}
            placeholder={textDictionary['app.collection.search']}
            value={searchValue}
          />
        </SearchBar>
      </ThemeProvider>
      {status === Status.Error || (
        <Loader hasNextPage={nextPageMayExist} isPending={status === Status.Loading} loadNextPage={loadNextPage}>
          <StyledGrid>
            {filteredTopics.map((topic) => (
              <TopicTile
                {...topic}
                hideOwnershipLabel={true}
                key={topic.id}
                navigationDestination={{ params: { id: topic.id }, to: 'discover' }}
              />
            ))}
          </StyledGrid>
        </Loader>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding-bottom: 2rem;
`;

const SearchBar = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  padding: 0 3rem 1.5rem;

  & > div {
    width: fit-content;
  }

  @media ${maxWidth.medium} {
    flex-direction: column;
    padding: 1rem 1rem 1.5rem;
  }
`;

const Loader = styled(InfinityLoader)`
  box-sizing: border-box;
  padding: 0 3rem;

  @media ${maxWidth.medium} {
    padding: 0 1rem;
  }
`;
