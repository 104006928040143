import { styled } from 'styled-components';

import { breakpoints } from 'common/styles';

export const NavigationIconContainer = styled.div`
  display: none;
  justify-items: center;
  align-items: center;

  @media ${breakpoints.large} {
    display: flex;
  }
`;
