import { styled } from 'styled-components';

import { useTranslations } from 'domains/language/useTranslations';

const StyledLink = styled.a`
  color: inherit;
  text-decoration: none;
`;

export const EmbedMapButton = () => {
  const { textDictionary } = useTranslations();

  return (
    <StyledLink href={process.env.REACT_APP_WIDGET_GUIDE_URL} target="_blank" rel="noreferrer">
      {textDictionary['app.widget.embed_maps_in_your_site']}
    </StyledLink>
  );
};
