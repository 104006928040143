import { put, select } from 'redux-saga/effects';

import { TopicPublicationApplySearchCriteria } from 'domains/publication/actionTypes';
import { getPublicationSearchCriteria, getAdvancedKnowledgeCriteria } from 'domains/publication/selectors';
import { TopicPublicationSearchCriteria } from 'domains/publication/types';
import { AdvancedTopicKnowledgeSearchCriteria } from 'domains/types';
import { fetchPublicationForTopic } from 'entities/publication/actions';

export function* applyPublicationSearchCriteriaSaga(action: TopicPublicationApplySearchCriteria) {
  const searchCriteria: TopicPublicationSearchCriteria = yield select(getPublicationSearchCriteria);
  const advancedCriteria: AdvancedTopicKnowledgeSearchCriteria = yield select(getAdvancedKnowledgeCriteria);
  const id = action.payload?.topicId ?? advancedCriteria.id;
  if (!id) {
    return;
  }
  const payload = { ...advancedCriteria, ...searchCriteria, id };
  yield put(fetchPublicationForTopic(payload));
}
