import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { toggleBookmarkFulfilled, toggleBookmarkRejected } from 'domains/bookmarks/actions';
import { ToggleBookmarkAction } from 'domains/bookmarks/actionTypes';

export function* toggleBookmarkSaga({ meta }: ToggleBookmarkAction) {
  try {
    yield apiFetch(`bookmark/${meta.bookmark.id}`, {
      method: meta.isBookmarked ? 'PUT' : 'DELETE',
    });
    yield put(toggleBookmarkFulfilled(meta));
  } catch (error) {
    yield put(toggleBookmarkRejected(meta));
  }
}
