import { dataUrlToBlob } from 'common/utilities/dataUrlToBlob';

export const getImageDownloadFile = async (imageUrl: string) =>
  new Promise<{ file: Blob; dataURL: string }>((resolve, reject) => {
    try {
      const img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          return;
        }
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        const file = dataUrlToBlob(dataURL);
        resolve({ file, dataURL });
      };
      img.onerror = () => {
        reject();
      };
      img.src = imageUrl;
    } catch {
      reject();
    }
  });
