import { SVGProps } from 'react';

export const RightArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
    <g strokeLinecap="round" strokeLinejoin="round">
      <path d="M7.004 13V1v12zm0 0L1.277 7.273l4.02 4.02L7.004 13z" transform="rotate(-90 7.004 7.004)" />
      <path
        d="M7.004 0c.56 0 1 .44 1 1v9.586l4.293-4.293a.99.99 0 0 1 1.414 0 .99.99 0 0 1 0 1.414l-6 6a1.003 1.003 0 0 1-1.09.217.998.998 0 0 1-.324-.217l-6-6a.99.99 0 0 1 0-1.414.99.99 0 0 1 1.414 0l4.293 4.293V1c0-.56.44-1 1-1z"
        stroke="none"
        transform="rotate(-90 7.004 7.004)"
      />
    </g>
  </svg>
);
