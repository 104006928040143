import { useEffect, useRef, useState } from 'react';
import { css, styled, ThemeProvider } from 'styled-components';

import { ArrowIcon } from 'common/components/icons';
import { TopicPreview } from 'common/components/topicPreview/TopicPreview';
import { breakpoints } from 'common/styles';
import { darkTheme } from 'domains/theme/constants/theme';
import { Topic } from 'entities/topics/types';

const StyledCarouselContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  position: relative;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Chrome and Safari */
  }
`;

const StyledCarouselControls = styled.div`
  display: flex;
  flex-direction: row;
  background-color: black;
  padding: 0 1rem 1rem;
  justify-content: space-between;
  @media ${breakpoints.medium} {
    padding: 0 3rem 1rem;
  }
`;

const StyledArrows = styled.div`
  display: flex;
  gap: 1rem;
  position: absolute;
  top: 93%;
  justify-content: space-between;
  padding: 0 1rem;
  width: 100%;
  box-sizing: border-box;
  z-index: 100;

  @media ${breakpoints.medium} {
    top: 38%;
    padding: 0 2rem;
  }
`;

const StyledArrowContainer = styled.div`
  transition: 0.3s all;
  background-color: black;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  padding: 8px;
  opacity: 0.7;
  cursor: pointer;

  &:hover {
    opacity: 1;
    color: ${({ theme }) => theme.colors.textBright};
  }

  @media ${breakpoints.medium} {
    height: 25px;
    width: 25px;
    padding: 10px;
  }
`;

const StyledArrow = css`
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.colors.textBright};
  cursor: pointer;
  opacity: 1;
  transition: 0.3s all;

  &:hover {
    color: ${({ theme }) => theme.colors.textBright};
    opacity: 1;
  }

  @media ${breakpoints.medium} {
    height: 25px;
    width: 25px;
  }
`;

const StyledLeftArrow = styled(ArrowIcon)`
  ${StyledArrow};
`;

const StyledRightArrow = styled(ArrowIcon)`
  ${StyledArrow};
  transform: rotate(180deg);
`;

const SliderBulbRow = styled.div`
  gap: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 50%;
  transform: translateX(-50%);
`;

const SliderBulb = styled.div<{ isActive: boolean }>`
  background-color: ${({ isActive }) => (isActive ? 'white' : 'grey')};
  width: 4px;
  height: 4px;
  border-radius: 2px;
`;

type TopicPreviewCarouselProps = { header: string; topics: Topic[] };

export const TopicPreviewCarousel = ({ topics, header }: TopicPreviewCarouselProps) => {
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [width, setWidth] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);

  const updateCarouselWidth = () => {
    if (!scrollRef.current) return;
    setWidth(scrollRef.current.offsetWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateCarouselWidth);
    return () => window.removeEventListener('resize', updateCarouselWidth);
  }, []);

  useEffect(updateCarouselWidth, [topics]);

  const scrollHandler = (ref: HTMLDivElement | null) => {
    if (ref === null) return;
    scrollRef.current = ref;
    const onScroll = (event: Event) => {
      if (scrollRef.current === null || event.currentTarget === null) return;
      const container = event.currentTarget as HTMLDivElement;
      setActiveIndex(Math.round(scrollRef.current.scrollLeft / container.clientWidth));
    };
    scrollRef.current.addEventListener('scroll', onScroll, { passive: true });
    return () => scrollRef.current?.removeEventListener('scroll', onScroll);
  };

  const onArrowClick = (direction: string) => {
    let newIndex;
    if (direction === 'left') {
      newIndex = (activeIndex + topics.length - 1) % topics.length;
    } else {
      newIndex = (activeIndex + 1) % topics.length;
    }
    scrollRef.current!.scrollTo(width * newIndex, 0);
  };

  if (topics.length === 0) {
    return null;
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <div style={{ position: 'relative' }}>
        <StyledArrows>
          <StyledArrowContainer>
            <StyledLeftArrow onClick={() => onArrowClick('left')} />
          </StyledArrowContainer>
          <StyledArrowContainer>
            <StyledRightArrow onClick={() => onArrowClick('right')} />
          </StyledArrowContainer>
        </StyledArrows>
        <StyledCarouselContainer ref={scrollHandler}>
          {topics.map((topic) => (
            <TopicPreview topic={topic} header={header} key={topic.id} />
          ))}
        </StyledCarouselContainer>
        <StyledCarouselControls>
          <SliderBulbRow>
            {topics.map((topic, index) => (
              <SliderBulb key={topic.id} isActive={index === activeIndex} />
            ))}
          </SliderBulbRow>
        </StyledCarouselControls>
      </div>
    </ThemeProvider>
  );
};
