import { styled } from 'styled-components';

import { SearchIcon } from 'common/components';
import { breakpoints } from 'common/styles';
import { useTranslations } from 'domains/language/useTranslations';
import { NavigationIconContainer } from 'domains/navigation/components/IconContainer';
import { TopBarItem } from 'domains/navigation/components/TopBarItem';

const StyledSearchIcon = styled(SearchIcon)`
  width: 22px;
  position: relative;
  color: ${({ theme }) => theme.colors.topHeaderBarText};

  @media ${breakpoints.large} {
    width: 24px;
  }
`;

const StyledSearchTopBarItem = styled(TopBarItem)`
  margin-right: 0;
`;

const StyledText = styled.p`
  margin: 0;
  display: inline-block;

  @media ${breakpoints.large} {
    display: none;
  }
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
`;

type SearchItemProps = { onClick?: () => void };

export const SearchItem = ({ onClick }: SearchItemProps) => {
  const { textDictionary } = useTranslations();
  return (
    <StyledSearchTopBarItem to="/search" onClick={onClick}>
      <StyledRow>
        <NavigationIconContainer>
          <StyledSearchIcon />
        </NavigationIconContainer>
        <StyledText>{textDictionary['app.search.title']}</StyledText>
      </StyledRow>
    </StyledSearchTopBarItem>
  );
};
