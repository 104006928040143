import { useEffect, useState } from 'react';
import { css, styled } from 'styled-components';

import { Tile } from 'common/components/Tile';
import { CollectionViewModel } from 'domains/collection/types';

const StyledContainer = styled.div`
  position: relative;
`;

const StyledTileContainer = styled.div`
  z-index: 15;
  position: relative;
`;

const Shadow = css`
  border-radius: 8px;
  height: 20px;
  position: absolute;
`;

const DarkShadow = styled.div`
  ${Shadow};
  background-color: ${({ theme }) => theme.colors.collectionTileShadow1};
  bottom: -10px;
  left: 10px;
  right: 10px;
  z-index: 10;
`;

const LightShadow = styled.div`
  ${Shadow};
  background-color: ${({ theme }) => theme.colors.collectionTileShadow2};
  bottom: -20px;
  left: 20px;
  right: 20px;
  z-index: 5;
`;

type CollectionTileProps = { collection: CollectionViewModel };

export const CollectionTile = ({ collection }: CollectionTileProps) => {
  const [image, setImage] = useState<string>(collection.hero_image_url);

  useEffect(() => {
    if (getBaseUrl(collection.hero_image_url) === getBaseUrl(image)) return;
    setImage(collection.hero_image_url);
  }, [collection.hero_image_url, image]);

  return (
    <StyledContainer>
      <StyledTileContainer>
        <Tile title={collection.name} image={image} link={`collection/${collection.id}`} />
      </StyledTileContainer>
      <DarkShadow />
      <LightShadow />
    </StyledContainer>
  );
};

const getBaseUrl = (url: string) => url.split('?')[0];
