import { RootState } from 'types';

export const getTopicCustomContent = (topicId: string, limit?: number) => (state: RootState) => {
  const content = state.domains.customContentAndSources.topicContent[topicId]?.data ?? [];
  return limit !== undefined ? content.slice(0, limit) : content;
};

export const getTopicCustomContentIsPending = (topicId: string) => (state: RootState) =>
  state.domains.customContentAndSources.topicContent[topicId]?.isPending ?? false;

export const getTopicCustomContentHasNextPage = (topicId: string) => (state: RootState) =>
  state.domains.customContentAndSources.topicContent[topicId]?.hasNextPage ?? false;

export const getCustomContentIsUploading = (state: RootState) =>
  state.domains.customContentAndSources.uploadContent.isPending;

export const getCustomContentUploadError = (state: RootState) =>
  state.domains.customContentAndSources.uploadContent.error;

export const getCustomContentIsDeleting = (contentId?: string) => (state: RootState) =>
  (contentId && state.domains.customContentAndSources.deleteContent[contentId]?.isPending) || false;

export const getCustomContentDeleteError = (contentId?: string) => (state: RootState) =>
  (contentId && state.domains.customContentAndSources.deleteContent[contentId]?.error) || false;

export const getInProgressCustomDocument = (state: RootState) =>
  state.domains.customContentAndSources.uploadContent.document;

const getScrapedCustomArticleState = (state: RootState) =>
  state.domains.customContentAndSources.uploadContent.knowledge;

export const getScrapedCustomArticleIsPending = (state: RootState) =>
  getScrapedCustomArticleState(state)?.isPending || false;

export const getScrapedCustomArticleHasErrored = (state: RootState) =>
  getScrapedCustomArticleState(state)?.error || false;

export const getScrapedCustomArticle = (state: RootState) => getScrapedCustomArticleState(state)?.data;

export const getCustomContentFormValid = (state: RootState) => {
  const { document, knowledge } = state.domains.customContentAndSources.uploadContent;
  return (
    ((!!document?.file || !!document?.id) && !!document?.filename && !document?.isFileTooBig) ||
    (!!knowledge?.data?.link && !!knowledge?.data?.title)
  );
};

export const getInProgressCustomContentId = (state: RootState) => {
  const scrapedArticle = getScrapedCustomArticle(state);
  const inProgressDocument = getInProgressCustomDocument(state);
  return scrapedArticle?.id || inProgressDocument?.id;
};

export const getIsNewCustomContent = (state: RootState) => !getInProgressCustomContentId(state);
