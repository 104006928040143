import { SVGProps } from 'react';

export const ListIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      d="M48 6H16V2h32v4zm0 6H16v4h32v-4zm0 10H16v4h32v-4zm0 10H16v4h32v-4zm0 10H16v4h32v-4zM12 2H0v12h12V2zm0 16H0v12h12V18zm0 16H0v12h12V34z"
      fill="currentColor"
    />
  </svg>
);
