import { addUnauthenticatedReset } from 'domains/authentication/utils';
import { PermissionsType, FetchPermissionAction } from 'entities/permissions/actionTypes';
import { Role, TM_LICENSE_PERSONAL } from 'entities/permissions/constants';
import { PermissionData } from 'entities/permissions/types';

export const initialState: PermissionData = {
  discover: {
    display: true,
    access: true,
    subscription_required: null,
    features: {
      forum_experts: false,
      tab_content_visible: false,
      publications: true,
      videos: true,
      data: true,
      stakeholders: false,
      podcast: false,
      briefing: false,
      co_curator_profile: false,
      events: false,
      initiatives: false,
      custom_content: false,
      dialogue: false,
      advanced_mode: false,
      uncurated_topics: false,
      graph: false,
      sharing: true,
      forum_initiatives: false,
      forum_events: false,
      tm_license: TM_LICENSE_PERSONAL,
      presentation_export: false,
      image_export: false,
    },
  },
  collections: {
    can_edit_collections: false,
    can_feature: false,
    can_list: false,
    collection_limit: 0,
    collection_restriction: true,
  },
  communities: {
    can_edit_communities: false,
  },
  monitor: {
    display: true,
    access: false,
    subscription_required: Role.PREMIUM_ROLE,
    features: {
      clusters: false,
      overview: false,
      dig_deeper_display: false,
      dig_deeper_access: false,
    },
  },
  create: {
    display: false,
    access: false,
    subscription_required: Role.PREMIUM_ROLE,
    features: {
      map_limit: 0,
      map_restriction: true,
      map_set_sharing: false,
      custom_key_issues: false,
      custom_content: false,
      generative_maps: false,
    },
  },
  profile: {
    display: false,
    access: false,
    subscription_required: Role.PUBLIC_ROLE,
    features: {
      redirect_to_toplink: false,
      membership_badge: false,
    },
  },
  bookmarks: {
    display: false,
    access: false,
    subscription_required: Role.PREMIUM_ROLE,
    features: {
      limit: 0,
    },
  },
  membership_events: {
    display: false,
    access: false,
    subscription_required: Role.PREMIUM_ROLE,
    features: {
      event_register: false,
    },
  },
  global: {
    features: {
      follow_topics: false,
      follow_topics_limit: 0,
      marketing: false,
      payment_portal: false,
      push_notifications: false,
      notifications: false,
      become_member: false,
      app_banner: false,
      signals: false,
      become_pro_member: false,
      sso_version: 1,
    },
  },
};

const reducer = (state = initialState, action: FetchPermissionAction) => {
  switch (action.type) {
    case PermissionsType.FETCH_FULFILLED:
      return action.payload || null;

    case PermissionsType.FETCH_REJECTED:
      return initialState;

    default:
      return state;
  }
};

export const permissionsReducer = addUnauthenticatedReset(reducer, initialState);
