import { LatestKnowledgeAction, LatestKnowledgeActionType } from 'domains/monitor/latestKnowledge/actionTypes';
import { LATEST_KNOWLEDGE_LIMIT } from 'domains/monitor/latestKnowledge/sagas';
import { LatestKnowledgeState } from 'domains/monitor/latestKnowledge/types';
import { addUserChangeReset } from 'entities/user/utils';

const initialState: LatestKnowledgeState = {
  isPending: false,
  hasNextPage: false,
  offset: 0,
  items: [],
};

const reducer = (state = initialState, action: LatestKnowledgeAction): LatestKnowledgeState => {
  switch (action.type) {
    case LatestKnowledgeActionType.FETCH:
      return {
        ...state,
        isPending: true,
        offset: action.meta.offset,
      };
    case LatestKnowledgeActionType.FETCH_FULFILLED: {
      const oldIds = state.items;
      const newIds = action.payload.map((item) => item.id);
      return {
        ...state,
        isPending: false,
        hasNextPage: newIds.length === LATEST_KNOWLEDGE_LIMIT,
        items: action.meta.offset === 0 ? newIds : [...oldIds, ...newIds],
      };
    }
    case LatestKnowledgeActionType.FETCH_REJECTED:
      return {
        ...state,
        isPending: false,
      };
    default:
      return state;
  }
};

export const latestKnowledgeReducer = addUserChangeReset(reducer, initialState);
