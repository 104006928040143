import { put, select } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import {
  searchTopicForCustomKeyIssueFulfilled,
  searchTopicForCustomKeyIssueRejected,
} from 'domains/customKeyIssue/actions';
import { CustomKeyIssueTopicSearchDefaultAction } from 'domains/customKeyIssue/actionTypes';
import { getCustomKeyIssueTopicListForQuery } from 'domains/customKeyIssue/selectors';
import { TopicSearchResult } from 'domains/search/types';
import { Topic } from 'entities/topics/types';

type Response = { result: TopicSearchResult[] };

export function* fetchCuratedTopicSearchSaga({ meta }: CustomKeyIssueTopicSearchDefaultAction) {
  try {
    const { context, limit = 5, query } = meta;
    const results: Topic[] = yield select(getCustomKeyIssueTopicListForQuery(query));
    let topicSearchResults: TopicSearchResult[];

    if (results) {
      topicSearchResults = results.map((item) => ({
        name: item.name,
        id: item.id,
        images: item.images,
      }));
    } else {
      const response: Response = yield apiFetch(`topic/search/${encodeURIComponent(query)}`, {
        queryParams: { context, limit, only_published: true },
      });
      topicSearchResults = response.result;
    }
    yield put(searchTopicForCustomKeyIssueFulfilled(meta, topicSearchResults));
  } catch (error) {
    yield put(searchTopicForCustomKeyIssueRejected(meta));
  }
}
