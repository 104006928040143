import {
  CustomKeyIssueTopicSearchDefaultAction,
  CustomKeyIssueTopicSearchRejectedAction,
  CustomKeyIssueTopicSearchFulfilledAction,
  CustomKeyIssueConceptSearchDefaultAction,
  CustomKeyIssueConceptSearchFulfilledAction,
  CustomKeyIssueConceptSearchRejectedAction,
  CreateCustomKeyIssueDefaultAction,
  CreateCustomKeyIssueFulfilledAction,
  CreateCustomKeyIssueRejectedAction,
  CustomKeyIssueTypes,
  LoadCustomKeyIssueDefaultAction,
  LoadCustomKeyIssueFulfilledAction,
  LoadCustomKeyIssueRejectedAction,
  ActivateCreateFormAction,
  CustomKeyIssueFingerprintBuilderAction,
  CustomKeyIssueFingerprintBuilderFulfilledAction,
  CustomKeyIssueFingerprintBuilderRejectedAction,
  CustomKeyIssueFPKnowledgeAction,
  CustomKeyIssueFPKnowledgeFulfilledAction,
  CustomKeyIssueFPKnowledgeRejectedAction,
  CustomKeyIssueTopicAndTagSuggestionsAction,
  CustomKeyIssueTopicAndTagSuggestionsFulfilledAction,
  CustomKeyIssueTopicAndTagSuggestionsRejectedAction,
  CustomKeyIssueResetTopicSearchIdsForQueryAction,
} from 'domains/customKeyIssue/actionTypes';

export const searchTopicForCustomKeyIssue = (
  meta: CustomKeyIssueTopicSearchDefaultAction['meta'],
): CustomKeyIssueTopicSearchDefaultAction => ({
  type: CustomKeyIssueTypes.FETCH_TOPIC_SEARCH,
  meta,
});

export const searchTopicForCustomKeyIssueFulfilled = (
  meta: CustomKeyIssueTopicSearchFulfilledAction['meta'],
  payload: CustomKeyIssueTopicSearchFulfilledAction['payload'],
): CustomKeyIssueTopicSearchFulfilledAction => ({
  type: CustomKeyIssueTypes.FETCH_TOPIC_SEARCH_FULFILLED,
  meta,
  payload,
});

export const searchTopicForCustomKeyIssueRejected = (
  meta: CustomKeyIssueTopicSearchRejectedAction['meta'],
): CustomKeyIssueTopicSearchRejectedAction => ({
  type: CustomKeyIssueTypes.FETCH_TOPIC_SEARCH_REJECTED,
  meta,
});

export const searchConcept = (
  meta: CustomKeyIssueConceptSearchDefaultAction['meta'],
): CustomKeyIssueConceptSearchDefaultAction => ({
  type: CustomKeyIssueTypes.FETCH_CONCEPT_SEARCH,
  meta,
});

export const searchConceptFulfilled = (
  meta: CustomKeyIssueConceptSearchFulfilledAction['meta'],
  payload: CustomKeyIssueConceptSearchFulfilledAction['payload'],
): CustomKeyIssueConceptSearchFulfilledAction => ({
  type: CustomKeyIssueTypes.FETCH_CONCEPT_SEARCH_FULFILLED,
  meta,
  payload,
});

export const searchConceptRejected = (
  meta: CustomKeyIssueConceptSearchRejectedAction['meta'],
): CustomKeyIssueConceptSearchRejectedAction => ({
  type: CustomKeyIssueTypes.FETCH_CONCEPT_SEARCH_REJECTED,
  meta,
});

export const buildFingerprint = (
  payload: CustomKeyIssueFingerprintBuilderAction['payload'],
): CustomKeyIssueFingerprintBuilderAction => ({
  type: CustomKeyIssueTypes.FETCH_FINGERPRINT_BUILDER,
  payload,
});

export const buildFingerprintFulfilled = (
  payload: CustomKeyIssueFingerprintBuilderFulfilledAction['payload'],
  meta: CustomKeyIssueFingerprintBuilderFulfilledAction['meta'],
): CustomKeyIssueFingerprintBuilderFulfilledAction => ({
  type: CustomKeyIssueTypes.FETCH_FINGERPRINT_BUILDER_FULFILLED,
  payload,
  meta,
});

export const buildFingerprintRejected = (
  meta: CustomKeyIssueFingerprintBuilderRejectedAction['meta'],
): CustomKeyIssueFingerprintBuilderRejectedAction => ({
  type: CustomKeyIssueTypes.FETCH_FINGERPRINT_BUILDER_REJECTED,
  meta,
});

export const fetchFPKnowledge = (
  payload: CustomKeyIssueFPKnowledgeAction['payload'],
): CustomKeyIssueFPKnowledgeAction => ({
  type: CustomKeyIssueTypes.FETCH_FP_KNOWLEDGE,
  payload,
});

export const fetchFPKnowledgeFulfilled = (
  payload: CustomKeyIssueFPKnowledgeFulfilledAction['payload'],
): CustomKeyIssueFPKnowledgeFulfilledAction => ({
  type: CustomKeyIssueTypes.FETCH_FP_KNOWLEDGE_FULFILLED,
  payload,
});

export const fetchFPKnowledgeRejected = (): CustomKeyIssueFPKnowledgeRejectedAction => ({
  type: CustomKeyIssueTypes.FETCH_FP_KNOWLEDGE_REJECTED,
});

export const createCustomKeyIssue = (
  meta: CreateCustomKeyIssueDefaultAction['meta'],
): CreateCustomKeyIssueDefaultAction => ({
  type: CustomKeyIssueTypes.CREATE,
  meta,
});

export const createCustomKeyIssueSuccess = (
  payload: CreateCustomKeyIssueFulfilledAction['payload'],
  meta: CreateCustomKeyIssueFulfilledAction['meta'],
): CreateCustomKeyIssueFulfilledAction => ({
  type: CustomKeyIssueTypes.CREATE_FULFILLED,
  payload,
  meta,
});

export const createCustomKeyIssueFailure = (
  meta: CreateCustomKeyIssueRejectedAction['meta'],
): CreateCustomKeyIssueRejectedAction => ({
  type: CustomKeyIssueTypes.CREATE_REJECTED,
  meta,
});

export const loadCustomKeyIssue = (meta: LoadCustomKeyIssueDefaultAction['meta']): LoadCustomKeyIssueDefaultAction => ({
  type: CustomKeyIssueTypes.LOAD,
  meta,
});

export const loadCustomKeyIssueSuccess = (
  payload: LoadCustomKeyIssueFulfilledAction['payload'],
  meta: LoadCustomKeyIssueFulfilledAction['meta'],
): LoadCustomKeyIssueFulfilledAction => ({
  type: CustomKeyIssueTypes.LOAD_FULFILLED,
  payload,
  meta,
});

export const loadCustomKeyIssueFailure = (
  meta: LoadCustomKeyIssueRejectedAction['meta'],
): LoadCustomKeyIssueRejectedAction => ({
  type: CustomKeyIssueTypes.LOAD_REJECTED,
  meta,
});

export const toggleCustomKeyIssueForm = (meta?: ActivateCreateFormAction['meta']): ActivateCreateFormAction => ({
  type: CustomKeyIssueTypes.ACTIVATE_FORM,
  meta,
});

export const fetchTopicAndTagSuggestions = (
  payload: CustomKeyIssueTopicAndTagSuggestionsAction['payload'],
): CustomKeyIssueTopicAndTagSuggestionsAction => ({
  type: CustomKeyIssueTypes.FETCH_TOPIC_AND_TAG_SUGGESTIONS,
  payload,
});

export const fetchTopicAndTagSuggestionsFulfilled = (
  payload: CustomKeyIssueTopicAndTagSuggestionsFulfilledAction['payload'],
): CustomKeyIssueTopicAndTagSuggestionsFulfilledAction => ({
  type: CustomKeyIssueTypes.FETCH_TOPIC_AND_TAG_SUGGESTIONS_FULFILLED,
  payload,
});

export const fetchTopicAndTagSuggestionsRejected = (): CustomKeyIssueTopicAndTagSuggestionsRejectedAction => ({
  type: CustomKeyIssueTypes.FETCH_TOPIC_AND_TAG_SUGGESTIONS_REJECTED,
});

export const resetTopicSearchIdsForQuery = (): CustomKeyIssueResetTopicSearchIdsForQueryAction => ({
  type: CustomKeyIssueTypes.RESET_TOPIC_SEARCH_IDS_FOR_QUERY,
});
