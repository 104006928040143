import {
  NotificationActionType,
  FetchNotificationsDefaultAction,
  FetchNotificationsFulfilledAction,
  FetchNotificationsRejectedAction,
  UpdateNotificationDefaultAction,
  UpdateNotificationFulfilledAction,
  UpdateNotificationRejectedAction,
  RemoveNotificationDefaultAction,
  RemoveNotificationFulfilledAction,
  RemoveNotificationRejectedAction,
  MarkAllNotificationDoneDefaultAction,
  MarkAllNotificationDoneFulfilledAction,
  MarkAllNotificationDoneRejectedAction,
} from 'domains/notifications/actionTypes';
import { UpdateNotificationMeta } from 'domains/notifications/types';
import { ErrorMeta, ErrorPayload } from 'types';

export const fetchNotifications = ({ offset = 0 }): FetchNotificationsDefaultAction => ({
  type: NotificationActionType.FETCH,
  meta: { offset },
});

export const fetchNotificationsSuccess = (
  meta: { offset: number },
  payload: any,
): FetchNotificationsFulfilledAction => ({
  type: NotificationActionType.FETCH_FULFILLED,
  meta,
  payload,
});

export const fetchNotificationsFailure = (
  payload: ErrorPayload,
  meta: ErrorMeta,
): FetchNotificationsRejectedAction => ({
  type: NotificationActionType.FETCH_REJECTED,
  payload,
  meta,
});

export const updateNotification = (meta: UpdateNotificationMeta): UpdateNotificationDefaultAction => ({
  type: NotificationActionType.UPDATE,
  meta,
});

export const updateNotificationSuccess = (meta: UpdateNotificationMeta): UpdateNotificationFulfilledAction => ({
  type: NotificationActionType.UPDATE_FULFILLED,
  meta,
});

export const updateNotificationFailure = (
  payload: ErrorPayload,
  meta: ErrorMeta & { id: string },
): UpdateNotificationRejectedAction => ({
  type: NotificationActionType.UPDATE_REJECTED,
  payload,
  meta,
});

export const removeNotification = (id: string): RemoveNotificationDefaultAction => ({
  type: NotificationActionType.REMOVE,
  meta: { id },
});

export const removeNotificationSuccess = (id: string): RemoveNotificationFulfilledAction => ({
  type: NotificationActionType.REMOVE_FULFILLED,
  meta: { id },
});

export const removeNotificationFailure = (
  payload: ErrorPayload,
  meta: ErrorMeta & { id: string },
): RemoveNotificationRejectedAction => ({
  type: NotificationActionType.REMOVE_REJECTED,
  payload,
  meta,
});

export const markAllNotificationDone = (): MarkAllNotificationDoneDefaultAction => ({
  type: NotificationActionType.MARK_ALL_DONE,
});

export const markAllNotificationDoneSuccess = (): MarkAllNotificationDoneFulfilledAction => ({
  type: NotificationActionType.MARK_ALL_DONE_FULFILLED,
});

export const markAllNotificationDoneFailure = (
  payload: ErrorPayload,
  meta: ErrorMeta,
): MarkAllNotificationDoneRejectedAction => ({
  type: NotificationActionType.MARK_ALL_DONE_REJECTED,
  payload,
  meta,
});
