import { ChangeEvent, ComponentPropsWithoutRef, FocusEvent, useEffect, useRef, useState } from 'react';
import { css, styled } from 'styled-components';

import { CrossIcon } from 'common/components/icons';
import { Spinner } from 'common/components/Spinner';
import { createScrollbarStyle } from 'common/styles';

type StyledProps = {
  $disabled?: boolean;
  hasLeftIcon?: boolean;
  hasRightIcon?: boolean;
  hasValue?: boolean;
  isFocused?: boolean;
};

const StyledContainer = styled.div<Pick<StyledProps, '$disabled' | 'isFocused'>>`
  position: relative;
  transition:
    background-color 0.3s,
    border-color 0.3s;
`;

export const StyledInput = styled.textarea`
  padding: 10px 48px 10px 14px;
  color: ${({ theme }) => theme.colors.inputText};
  border: 1px solid ${({ theme }) => theme.colors.inputBorder}AA;
  background-color: transparent;
  font-size: 1em;
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
  height: 8em;
  overflow-y: auto;
  resize: none;
  outline: none;

  &:focus {
    color: ${({ theme }) => theme.colors.inputTextFocused};
    border-color: ${({ theme }) => theme.colors.inputBorder};
  }

  ${createScrollbarStyle()}
`;

const StyledClearButton = styled.button`
  position: absolute;
  right: 10px;
  top: 49px;
  width: 20px;
  height: 20px;
  background: none;
  border: 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.fillPrimary};
  outline: none;
  opacity: 0.5;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    opacity: 1;
  }
`;

const StyledClearButtonIcon = styled(CrossIcon)`
  width: 14px;
  height: 14px;
`;

const StyledSpinner = styled(Spinner)`
  margin-right: 1rem;
  width: 1rem;
  height: 1rem;
  color: ${({ theme }) => theme.colors.fillPrimary};
`;

const hasValueStyles = css``;

const StyledLabel = styled.label<StyledProps>`
  transition: all 0.3s ease;
  font-weight: bold;
  margin-bottom: 16px;
  display: block;

  ${({ theme, hasValue, isFocused }) => css`
    color: ${isFocused ? theme.colors.inputTextFocused : theme.colors.inputText};

    ${StyledInput}:-webkit-autofill + & {
      ${hasValueStyles}
    }

    ${(hasValue || isFocused) && hasValueStyles}
    ${StyledInput}:focus + & {
      color: ${theme.colors.inputTextFocused};
    }
  `}
`;

type TextAreaProps = ComponentPropsWithoutRef<typeof StyledInput> & {
  className?: string;
  dataTestId?: string;
  isClearable?: boolean;
  isPending?: boolean;
  onValueChange: (value: string) => void;
};

export const TextArea = ({
  className,
  dataTestId,
  defaultValue,
  isClearable = true,
  isPending = false,
  onBlur,
  onFocus,
  onValueChange,
  placeholder,
  ...props
}: TextAreaProps) => {
  const [hasValue, setHasValue] = useState(props.value !== undefined ? !!props.value : !!defaultValue);
  const [isFocused, setIsFocused] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const onBlurHandle = (event: FocusEvent<HTMLTextAreaElement>) => {
    setIsFocused(false);
    if (props.value === undefined) {
      setHasValue(!!event.target.value);
    }
    if (onBlur) {
      onBlur(event);
    }
  };

  const onFocusHandle = (event: FocusEvent<HTMLTextAreaElement>) => {
    setIsFocused(true);
    if (onFocus) {
      onFocus(event);
    }
  };

  const emitChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (onValueChange) {
      const text = event.target.value;
      onValueChange(text);
    }
  };

  const clear = () => {
    onValueChange('');
    setIsFocused(false);
    setHasValue(false);
  };

  const onLabelClick = () => {
    textAreaRef.current?.focus();
  };

  useEffect(() => {
    setHasValue(props.value !== undefined ? !!props.value : !!defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  return (
    <StyledContainer $disabled={props.disabled} isFocused={isFocused} className={className}>
      {placeholder === undefined || (
        <StyledLabel
          hasValue={hasValue}
          isFocused={isFocused}
          htmlFor={props.name}
          $disabled={props.disabled}
          onClick={onLabelClick}
        >
          {placeholder}
        </StyledLabel>
      )}
      <StyledInput
        {...props}
        ref={textAreaRef}
        onChange={emitChange}
        onFocus={onFocusHandle}
        onBlur={onBlurHandle}
        data-test-id={dataTestId}
      />
      {((hasValue && isClearable) || isPending) && (
        <StyledClearButton>
          {isPending ? <StyledSpinner /> : <StyledClearButtonIcon onClick={clear} />}
        </StyledClearButton>
      )}
    </StyledContainer>
  );
};
