import { combineReducers } from 'redux';

import { searchCriteriaReducer as buildSearchCriteriaReducer } from 'common/reducer/searchCriteriaReducer';
import { TopicInitiativeType } from 'domains/initiatives/actionTypes';
import {
  FullTopicInitiativeSearchCriteria,
  TopicInitiativePackage,
  TopicInitiativeState,
} from 'domains/initiatives/types';
import { findMatchingCriteriaAndOthers } from 'domains/publication/reducer';
import { addTopicSelectionReset, addUserOrMapChangeReset } from 'domains/topic/reducerHelpers';
import { InitiativeAction, InitiativesType } from 'entities/initiatives/actionTypes';

const initialState: TopicInitiativeState = {
  forTopic: {},
  searchCriteria: {
    searchTerm: '',
    type: '',
    offset: 0,
    limit: 20,
  },
};

const initiativesForTopicReducer = (
  state = initialState.forTopic,
  action: InitiativeAction,
): TopicInitiativeState['forTopic'] => {
  switch (action.type) {
    case InitiativesType.FETCH_FOR_TOPIC: {
      const { offset: _offset, limit: _limit, ...searchCriteria } = action.payload;
      const [matchingInitiativePackage, others] = findMatchingCriteriaAndOthers<
        TopicInitiativePackage,
        FullTopicInitiativeSearchCriteria
      >(state, searchCriteria);
      const newInitiativePackage = {
        ...(matchingInitiativePackage || {}),
        isFetching: true,
        hasNextPage: true,
        error: false,
        data: matchingInitiativePackage?.data || [],
        searchCriteria,
      };
      return {
        ...state,
        [searchCriteria.id]: [...others, newInitiativePackage],
      };
    }

    case InitiativesType.FETCH_FOR_TOPIC_SUCCESS: {
      const { data } = action.payload;
      const { offset, limit, ...searchCriteria } = action.meta;
      const [matchingInitiativePackage, others] = findMatchingCriteriaAndOthers<
        TopicInitiativePackage,
        FullTopicInitiativeSearchCriteria
      >(state, searchCriteria);

      const dataToKeep = matchingInitiativePackage?.data.slice(0, offset) || [];
      const newInitiativePackage = {
        ...(matchingInitiativePackage || {}),
        isFetching: false,
        hasNextPage: data.length >= limit,
        error: false,
        data: dataToKeep.concat(data.map(({ id }) => id)),
        searchCriteria,
      };
      return {
        ...state,
        [searchCriteria.id]: [...others, newInitiativePackage],
      };
    }

    case InitiativesType.FETCH_FOR_TOPIC_FAILURE: {
      const { searchCriteria } = action.meta;
      const [matchingInitiativePackage, others] = findMatchingCriteriaAndOthers<
        TopicInitiativePackage,
        FullTopicInitiativeSearchCriteria
      >(state, searchCriteria);
      const newInitiativePackage = {
        ...(matchingInitiativePackage || {}),
        isFetching: false,
        hasNextPage: false,
        error: true,
        data: matchingInitiativePackage?.data || [],
        searchCriteria,
      };
      return {
        ...state,
        [searchCriteria.id]: [...others, newInitiativePackage],
      };
    }

    default:
      return state;
  }
};

const searchCriteriaReducer = buildSearchCriteriaReducer(
  initialState.searchCriteria,
  TopicInitiativeType.APPLY_SEARCH_CRITERIA,
  TopicInitiativeType.CLEAR_SEARCH_CRITERIA,
);

export const initiativesReducer = combineReducers({
  forTopic: addUserOrMapChangeReset(initiativesForTopicReducer, initialState.forTopic),
  searchCriteria: addTopicSelectionReset(searchCriteriaReducer, initialState.searchCriteria),
});
