import { TopicSearchResult, SearchVisibilitySectionType } from 'domains/search/types';
import { Knowledge } from 'entities/knowledge/types';

export enum SearchType {
  UPDATE_QUERY = 'domains/UPDATE_SEARCH_QUERY',
  UPDATE_SECTION_VISIBILITY = 'domains/UPDATE_SECTION_VISIBILITY',
  FETCH_TOPICS = 'domains/FETCH_TOPICS',
  FETCH_TOPICS_FULFILLED = 'domains/FETCH_FULFILLED_TOPICS',
  FETCH_TOPICS_REJECTED = 'domains/FETCH_REJECTED_TOPICS',
  FETCH_PUBLICATIONS = 'domains/FETCH_PUBLICATIONS',
  FETCH_PUBLICATIONS_FULFILLED = 'domains/FETCH_PUBLICATIONS_FULFILLED',
  FETCH_PUBLICATIONS_REJECTED = 'domains/FETCH_PUBLICATIONS_REJECTED',
  FETCH_VIDEOS = 'domains/FETCH_VIDEOS',
  FETCH_VIDEOS_FULFILLED = 'domains/FETCH_VIDEOS_FULFILLED',
  FETCH_VIDEOS_REJECTED = 'domains/FETCH_VIDEOS_REJECTED',
  FETCH_KNOWLEDGE_DATA = 'domains/KNOWLEDGE_DATA',
  FETCH_KNOWLEDGE_DATA_FULFILLED = 'domains/KNOWLEDGE_DATA_FULFILLED',
  FETCH_KNOWLEDGE_DATA_REJECTED = 'domains/KNOWLEDGE_DATA_REJECTED',
}

export type SearchMeta = { limit?: number; offset?: number; query?: string; timestamp?: number };

export type UpdateSearchQueryAction = {
  meta: { query?: string; type?: SearchVisibilitySectionType };
  type: SearchType.UPDATE_QUERY;
};

export type UpdateSearchSectionVisibilityAction = {
  meta: { section: SearchVisibilitySectionType };
  type: SearchType.UPDATE_SECTION_VISIBILITY;
};

export type FetchTopicsSearchDefaultAction = { meta: SearchMeta; type: SearchType.FETCH_TOPICS };

export type FetchTopicsSearchFulfilledAction = {
  meta: SearchMeta;
  payload: TopicSearchResult[];
  type: SearchType.FETCH_TOPICS_FULFILLED;
};

export type FetchTopicsSearchRejectedAction = { meta: SearchMeta; type: SearchType.FETCH_TOPICS_REJECTED };
export type FetchPublicationSearchDefaultAction = { meta: SearchMeta; type: SearchType.FETCH_PUBLICATIONS };

export type FetchPublicationSearchFulfilledAction = {
  meta: SearchMeta;
  payload: Knowledge[];
  type: SearchType.FETCH_PUBLICATIONS_FULFILLED;
};

export type FetchPublicationSearchRejectedAction = { meta: SearchMeta; type: SearchType.FETCH_PUBLICATIONS_REJECTED };
export type FetchVideosSearchDefaultAction = { meta: SearchMeta; type: SearchType.FETCH_VIDEOS };

export type FetchVideosSearchFulfilledAction = {
  meta: SearchMeta;
  payload: Knowledge[];
  type: SearchType.FETCH_VIDEOS_FULFILLED;
};

export type FetchVideosSearchRejectedAction = { meta: SearchMeta; type: SearchType.FETCH_VIDEOS_REJECTED };
export type FetchKnowledgeDataSearchDefaultAction = { meta: SearchMeta; type: SearchType.FETCH_KNOWLEDGE_DATA };

export type FetchKnowledgeDataSearchFulfilledAction = {
  meta: SearchMeta;
  payload: Knowledge[];
  type: SearchType.FETCH_KNOWLEDGE_DATA_FULFILLED;
};

export type FetchKnowledgeDataSearchRejectedAction = {
  meta: SearchMeta;
  type: SearchType.FETCH_KNOWLEDGE_DATA_REJECTED;
};

export type TopicSearchAction =
  | UpdateSearchQueryAction
  | FetchTopicsSearchDefaultAction
  | FetchTopicsSearchFulfilledAction
  | FetchTopicsSearchRejectedAction
  | FetchPublicationSearchDefaultAction
  | FetchPublicationSearchFulfilledAction
  | FetchPublicationSearchRejectedAction
  | UpdateSearchSectionVisibilityAction
  | FetchVideosSearchDefaultAction
  | FetchVideosSearchFulfilledAction
  | FetchVideosSearchRejectedAction
  | FetchKnowledgeDataSearchDefaultAction
  | FetchKnowledgeDataSearchFulfilledAction
  | FetchKnowledgeDataSearchRejectedAction;
