import { isSameSearchCriteria } from 'domains/publication/reducer';
import { RootState } from 'types';

const getStakeholdersStateForTopic = (state: RootState, topicId: string) => {
  const { offset: _offset, limit: _limit, ...initialCriteria } = getStakeholdersSearchCriteria(state);
  const searchCriteria = {
    ...initialCriteria,
    id: topicId,
  };
  const matchingStakeholderPackage = state.domains.stakeholders.forTopic[topicId]?.find((stakeholderPackage) =>
    isSameSearchCriteria(stakeholderPackage.searchCriteria, searchCriteria),
  );
  return matchingStakeholderPackage;
};

export const getStakeholdersForTopic = (topicId: string) => (state: RootState) =>
  (getStakeholdersStateForTopic(state, topicId)?.data || []).map((id) => state.entities.stakeholders.byId[id]);

export const isFetchingStakeholdersForTopic = (topicId: string) => (state: RootState) =>
  getStakeholdersStateForTopic(state, topicId)?.isFetching || false;

export const hasNextStakeholdersForTopicPage = (topicId: string) => (state: RootState) => {
  const stakeholdersState = getStakeholdersStateForTopic(state, topicId);
  return stakeholdersState ? stakeholdersState.hasNextPage : true;
};

export const getStakeholdersSearchCriteria = (state: RootState) => state.domains.stakeholders.searchCriteria;

export const getIsStakeholdersFiltered = (state: RootState) => {
  const filter = state.domains.stakeholders.searchCriteria;
  return !!filter.type || !!filter.role || !!filter.searchTerm;
};

export const getStakeholderFilterCount = (state: RootState) => {
  const { type, role, searchTerm } = state.domains.stakeholders.searchCriteria;
  const count = [type, role, searchTerm].filter((item) => !!item).length;
  return count;
};
