import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

import { StyledGrid, SectionTitleUnderlined } from 'common/components';
import { pushHistory } from 'common/utilities';
import { trackEvent } from 'domains/analytics/actions';
import { useTranslations } from 'domains/language/useTranslations';
import { getCurrentSearchTerm, getSearchKeyIssuesIsPending, getSearchKeyIssuesResults } from 'domains/search/selectors';
import { KeyIssueTile } from 'domains/topic/components/KeyIssueTile';
import { KeyIssue } from 'entities/keyIssues/types';

const StyledContainer = styled.div`
  margin-bottom: 2rem;
`;

const StyledItem = styled(Link)`
  cursor: pointer;
  text-decoration: none;
`;

export const KeyIssueResults = () => {
  const { textDictionary } = useTranslations();
  const dispatch = useDispatch();
  const isPending = useSelector(getSearchKeyIssuesIsPending);
  const issues = useSelector(getSearchKeyIssuesResults);
  const currentSearchTerm = useSelector(getCurrentSearchTerm);

  if (issues.length === 0) {
    return null;
  }

  const onKeyIssueClickHandler = (issue: KeyIssue) => (event: any) => {
    event.preventDefault();
    if (!issue.primary_topic) {
      return;
    }
    dispatch(
      trackEvent({
        name: 'Browse page',
        page: 'Search',
        subpage: 'Topic detail',
        entityId: issue.primary_topic.id,
        entityName: issue.primary_topic.name,
        entityType: 'Topic',
        secondaryEntityId: issue.id,
        secondaryEntityName: issue.name,
        secondaryEntityType: 'Key Issue',
      }),
    );
    pushHistory(`/topics/${issue.primary_topic.id}/key-issues/${issue.id}`);
  };

  return (
    <StyledContainer>
      <SectionTitleUnderlined isLoading={isPending} id="keyIssues">
        {textDictionary['app.topic.key_issues']}
      </SectionTitleUnderlined>
      <StyledGrid numberOfItems={[1, 2, 3, 3, 4]}>
        {issues.map((issue) => (
          <StyledItem key={issue.id} onClick={onKeyIssueClickHandler(issue)} to={`/topics/${issue.primary_topic?.id}`}>
            <KeyIssueTile
              name={issue.name}
              images={issue.images}
              primary_topic={issue.primary_topic}
              description={issue.description}
              highlightedTerm={currentSearchTerm || undefined}
            />
          </StyledItem>
        ))}
      </StyledGrid>
    </StyledContainer>
  );
};
