import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { fetchNotificationsFailure, fetchNotificationsSuccess } from 'domains/notifications/actions';
import { FetchNotificationsDefaultAction } from 'domains/notifications/actionTypes';
import { NotificationPayload } from 'domains/notifications/types';
import { isFetchError } from 'TypeCheckMethods';

export const NOTIFICATION_LIMIT = 20;

export function* fetchNotificationsSaga(action: FetchNotificationsDefaultAction) {
  try {
    const notifications: NotificationPayload = yield apiFetch('notification', {
      queryParams: {
        offset: action.meta.offset * NOTIFICATION_LIMIT,
        limit: NOTIFICATION_LIMIT,
      },
    });
    yield put(fetchNotificationsSuccess(action.meta, notifications));
  } catch (error) {
    if (isFetchError(error)) {
      yield put(fetchNotificationsFailure({ error }, { logoutOnAuthError: true }));
    }
  }
}
