import { put } from 'redux-saga/effects';

import { baseApiFetch } from 'common/utilities/apiFetch';
import { deleteCollectionFailure, deleteCollectionSuccess, fetchCollections } from 'domains/collection/actions';
import { DeleteCollectionAction } from 'domains/collection/actionTypes';
import { CollectionType } from 'domains/collection/types/collectionType';

export function* deleteCollectionSaga({ payload }: DeleteCollectionAction) {
  try {
    yield baseApiFetch(`collection/${payload.id}`, { method: 'DELETE' });
    yield put(deleteCollectionSuccess(payload.id));
    yield put(fetchCollections(CollectionType.Editable));
  } catch (error) {
    yield put(deleteCollectionFailure());
    console.error(`[deleteCollectionSaga] Failed to delete collection with ID '${payload.id}': ${error}`);
  }
}
