import { useSelector } from 'react-redux';

import { getSiteLanguage } from 'domains/language/selectors';
import { useTranslations, interpolate } from 'domains/language/useTranslations';

const formatDate = (
  date: number,
  language = 'en-US',
  onlyDate = false,
  showWeekday = true,
  dateFormatOptions?: Intl.DateTimeFormatOptions,
) => {
  if (!dateFormatOptions) {
    dateFormatOptions = getFormatOptions(onlyDate, showWeekday);
  }
  const formattedDate = new Date(date * 1000).toLocaleDateString(language, dateFormatOptions);
  return formattedDate;
};

type DateProps = { date: number; onlyDate?: boolean; options?: Intl.DateTimeFormatOptions; showWeekday?: boolean };

const difference = (firstDate: Date, secondDate: Date, type: 'hours' | 'days') => {
  const factor = (type === 'days' ? 24 : 1) * 60 * 60 * 1000;
  return Math.round((firstDate.getTime() - secondDate.getTime()) / factor);
};

const getString = (terms: Record<string, string>, diff: number, type: 'days' | 'hours') => {
  let localizedKey;
  if (diff > 0) {
    localizedKey = `app.date.format.difference_in_${type}`;
  } else {
    localizedKey = `app.date.format.difference_in_future_${type}`;
  }

  if (Math.abs(diff) !== 1) {
    localizedKey = localizedKey.slice(0, -1);
  }
  let interpolateKeys: Record<string, () => string> = {
    days: () => `${Math.abs(diff)}`,
  };

  if (type === 'hours') {
    if (Math.abs(diff) < 1) {
      localizedKey = 'app.now.title';
    } else {
      interpolateKeys = { hours: () => `${Math.abs(diff)}` };
    }
  }
  return interpolate(terms[localizedKey], interpolateKeys);
};

const formatRelativeDate = (
  timestamp: number,
  locales = 'en',
  textDictionary: Record<string, string>,
  precision: 'hour' | 'day' = 'hour',
  onlyDate?: boolean,
  showWeekday?: boolean,
) => {
  const today = new Date();
  const time = new Date(timestamp * 1000);
  const diffDays = difference(today, time, 'days');

  if (Math.abs(diffDays) > 6) {
    return formatDate(timestamp, locales, onlyDate, showWeekday);
  }

  if (Math.abs(diffDays) < 1) {
    if (precision === 'day') {
      return textDictionary['app.time.today'];
    }

    const diffHours = difference(today, time, 'hours');
    return getString(textDictionary, diffHours, 'hours');
  }

  return getString(textDictionary, diffDays, 'days');
};

const getFormatOptions = (onlyDate = false, showWeekday = true): Intl.DateTimeFormatOptions => {
  let dateFormatOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  if (showWeekday) {
    dateFormatOptions = {
      ...dateFormatOptions,
      weekday: 'short',
    };
  }
  if (!onlyDate) {
    dateFormatOptions = {
      ...dateFormatOptions,
      hour: 'numeric',
      minute: 'numeric',
    };
  }
  return dateFormatOptions;
};

type RelativeDateProps = { date: number; onlyDate?: boolean; precision?: 'hour' | 'day'; showWeekday?: boolean };

export const useFormatDate = () => {
  const language = useSelector(getSiteLanguage);
  const { textDictionary } = useTranslations();

  const getDate = ({ date, onlyDate = false, showWeekday = false, options }: DateProps) =>
    formatDate(date, language, onlyDate, showWeekday, options);

  const getRelativeDate = ({ date, precision = 'hour', onlyDate = false, showWeekday = false }: RelativeDateProps) =>
    formatRelativeDate(date, language, textDictionary, precision, onlyDate, showWeekday);

  return { getDate, getRelativeDate };
};

export const getTimestampFromDate = (date: string): number => {
  if (!date) {
    return 0;
  }
  return new Date(date).getTime() / 1000;
};
