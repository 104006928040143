import { styled } from 'styled-components';

import { UIButton } from 'common/components/index';
import { Setter } from 'common/types/setter';
import { urlWithProtocolRegex } from 'common/utilities/urlWithProtocolRegex';
import { useTranslations } from 'domains/language/useTranslations';

type CommunitiesTabProps = { setSpacesUrl: Setter<string | undefined>; spacesUrl?: string };

export const CommunitiesTab = ({ setSpacesUrl, spacesUrl }: CommunitiesTabProps) => {
  const { textDictionary } = useTranslations();

  const anchorProps = { as: 'a', href: spacesUrl, rel: 'noopener noreferrer', target: '_blank' };
  const buttonProps =
    spacesUrl === undefined || !urlWithProtocolRegex.test(spacesUrl) ? { disabled: true } : anchorProps;

  return (
    <Label>
      {textDictionary['app.custom_attachment.url.title']}
      <Field>
        <Input
          onChange={(event) => setSpacesUrl(event.target.value.length > 0 ? event.target.value : undefined)}
          type="url"
          value={spacesUrl ?? ''}
        />
        <Button {...buttonProps} variant="filled">
          {textDictionary['app.collection.test_this_link']}
        </Button>
      </Field>
    </Label>
  );
};

const Label = styled.label`
  color: ${({ theme }) => theme.colors.textBright};
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Field = styled.div`
  border-radius: 1.25rem;
  border: 1px solid ${({ theme }) => theme.colors.inputText};
  box-sizing: border-box;
  display: flex;
  gap: 0.25rem;
  height: 2.5rem;
`;

const Input = styled.input`
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.inputText};
  display: inline-block;
  flex-grow: 1;
  font-size: 1rem;
  height: calc(2.5rem - 2px);
  min-width: 0;
  padding: 9px 0 9px 1rem;
  text-overflow: ellipsis;
  width: 100%;

  &:focus-visible,
  &:hover {
    background-color: transparent;
    outline: none;
  }
`;

const Button = styled(UIButton)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.blue};
  border: none;
  border-radius: 1.25rem;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  display: flex;
  font-weight: bold;
  height: 2.5rem;
  justify-content: center;
  margin: -1px -4px -1px 0;
  padding: 0 1.8rem;
  text-decoration: none;
  transition: all 300ms;
  white-space: nowrap;
  width: fit-content;

  &:disabled {
    background-color: ${({ theme }) => theme.colors.activeButtonHoverBackground};
    color: ${({ theme }) => theme.colors.chipBorder};
    cursor: not-allowed;
    opacity: 1;

    &:focus-visible,
    &:hover {
      background-color: ${({ theme }) => theme.colors.activeButtonHoverBackground};
    }
  }

  &:focus-visible,
  &:hover {
    background-color: ${({ theme }) => theme.colors.blueDark};
  }
`;
