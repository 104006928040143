import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { fetchStakeholderByIdFailure, fetchStakeholderByIdSuccessful } from 'entities/stakeholders/actions';
import { FetchStakeholderForIdAction } from 'entities/stakeholders/actionTypes';
import { Stakeholder } from 'entities/stakeholders/types';

export function* fetchStakeholderByIdSaga(action: FetchStakeholderForIdAction) {
  try {
    const payload: Stakeholder = yield apiFetch(`stakeholder/${action.meta.id}`);
    yield put(fetchStakeholderByIdSuccessful(payload, action.meta));
  } catch {
    yield put(fetchStakeholderByIdFailure(action.meta, { logoutOnAuthError: true }));
  }
}
