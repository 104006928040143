type BaseState = { [x: string]: any; limit: number; offset: number; searchTerm?: string };

type BaseAction<State extends BaseState> = { payload?: State; type: string };

export const searchCriteriaReducer =
  <State extends BaseState>(initialState: State, APPLY_FILTER_TYPE: string, CLEAR_FILTER_TYPE?: string) =>
  (state = initialState, action: BaseAction<State>): State => {
    switch (action.type) {
      case APPLY_FILTER_TYPE: {
        if (!action.payload) {
          return state;
        }
        const { offset, limit, topicId: _, ...filters } = action.payload;
        let newOffset = offset;
        let newLimit = limit;
        if (offset === null || offset === undefined) {
          newOffset = state.offset;
          const searchCriteriaHasChanged = Object.keys(filters).some((key) => filters[key] !== state[key]);
          if (searchCriteriaHasChanged) {
            newOffset = initialState.offset;
          }
        }

        if (limit === null || limit === undefined) {
          newLimit = state.limit;
        }

        return { ...state, ...filters, offset: newOffset, limit: newLimit };
      }

      case CLEAR_FILTER_TYPE: {
        const { searchTerm: _searchTerm, ...other } = state;

        return Object.keys(other).reduce((newState, key) => ({ ...newState, [key]: initialState[key] }), state);
      }

      default:
        return state;
    }
  };
