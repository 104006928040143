import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { styled } from 'styled-components';

import { UIToggle } from 'common/components';
import { replaceHistory } from 'common/utilities';
import { trackEvent } from 'domains/analytics/actions';
import { CreateCollectionsTab } from 'domains/create/landing/CreateCollectionsTab';
import { CreateMapsTab } from 'domains/create/landing/CreateMapsTab';
import { PublicCreateLanding } from 'domains/create/landing/PublicCreateLanding';
import { useTranslations } from 'domains/language/useTranslations';
import { useRouterNavigation } from 'domains/routing/utils';
import { getCanAccessCreate, getCanEditCollections } from 'entities/user/selectors';

const StyledContainer = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const StyledToggleContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding-top: 2rem;
  width: 100%;
`;

const StyledToggle = styled(UIToggle)`
  margin: 0 1rem;
  width: 32rem;
`;

export const CreateLanding = () => {
  const dispatch = useDispatch();
  const mode = new URLSearchParams(useLocation().search).get('mode') === 'collection' ? 'collection' : 'map';
  const onChange = useRouterNavigation({ to: 'create', params: { mode: mode === 'map' ? 'collection' : 'map' } });
  const canAccessCreate = useSelector(getCanAccessCreate);
  const canEditCollections = useSelector(getCanEditCollections);
  const { textDictionary } = useTranslations();

  useEffect(() => {
    dispatch(trackEvent({ name: 'Browse page', page: 'Create landing' }));
  }, [dispatch]);

  useEffect(() => {
    if (canEditCollections || mode === 'map') return;
    replaceHistory('/create');
  });

  const items = [
    { text: textDictionary['app.create.landing_toggle.maps'], value: 'map' },
    { text: textDictionary['app.create.landing_toggle.collections'], value: 'collection' },
  ];

  return canAccessCreate ? (
    <StyledContainer>
      {canEditCollections && (
        <StyledToggleContainer>
          <StyledToggle onChange={onChange} value={mode} name="toggleCreateMode" items={items} />
        </StyledToggleContainer>
      )}
      {mode === 'map' || !canEditCollections ? <CreateMapsTab /> : <CreateCollectionsTab />}
    </StyledContainer>
  ) : (
    <PublicCreateLanding />
  );
};
