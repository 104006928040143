import { SVGProps } from 'react';

export const ImageFileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
    <g id="Group_95330" data-name="Group 95330" transform="translate(12138 18575)">
      <g id="Rectangle_41723" data-name="Rectangle 41723" transform="translate(-12138 -18575)" fill="#2a63e9">
        <rect width="64" height="64" stroke="none" />
      </g>
      <g id="Group_95317" data-name="Group 95317" transform="translate(-12126.943 -18564)">
        <path
          id="Path_245110"
          data-name="Path 245110"
          d="M177.873,27.283,165.8,15.213A4.1,4.1,0,0,0,162.937,14h-21.66a4.141,4.141,0,0,0-4.079,4.079v34.36a4.069,4.069,0,0,0,4.079,4.079h33.731a4.069,4.069,0,0,0,4.079-4.079V30.149a4.1,4.1,0,0,0-1.213-2.866Zm-2.315.992H166.63a1.835,1.835,0,0,1-1.819-1.819V17.582Zm-.551,25.873H141.277a1.717,1.717,0,0,1-1.708-1.708V18.078a1.694,1.694,0,0,1,1.708-1.708h21.164V26.456a4.187,4.187,0,0,0,4.189,4.189h10.086V52.439a1.717,1.717,0,0,1-1.708,1.708Z"
          transform="translate(-137.198 -14)"
          fill="#fff"
        />
        <path
          id="Path_245111"
          data-name="Path 245111"
          d="M245.274,320.18l-4.905-8.212a2.664,2.664,0,0,0-2.2-1.268,2.523,2.523,0,0,0-2.2,1.268l-1.378,2.315-3.031-5.016a2.664,2.664,0,0,0-2.2-1.268,2.524,2.524,0,0,0-2.2,1.268l-6.614,10.913a2.571,2.571,0,0,0,2.2,3.913h20.283a2.616,2.616,0,0,0,2.26-1.323,2.332,2.332,0,0,0,0-2.59Zm-22.652,1.212,6.5-10.913a.212.212,0,0,1,.386,0l4.023,6.724a1.188,1.188,0,0,0,2.039,0l2.37-3.968a.212.212,0,0,1,.386,0l4.905,8.157c.11.165,0,.331-.22.331H222.787a.2.2,0,0,1-.165-.331Z"
          transform="translate(-215.703 -285.949)"
          fill="#fff"
        />
        <path
          id="Path_245112"
          data-name="Path 245112"
          d="M343.375,227.439a4.519,4.519,0,1,0,0-9.039,4.52,4.52,0,1,0,0,9.039Zm0-6.669a2.149,2.149,0,1,1-2.15,2.149A2.085,2.085,0,0,1,343.375,220.77Z"
          transform="translate(-322.678 -203.2)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);
