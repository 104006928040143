import { PropsWithChildren } from 'react';
import { css, styled } from 'styled-components';

import { LockedContent } from 'common/components/LockedContent';
import { TrackPage } from 'domains/analytics/types';

type StyledProps = { isLocked?: boolean };

const StyledOuterContainer = styled.div<{ isOnCard?: boolean; isSelected?: boolean }>`
  position: relative;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  ${({ isOnCard, isSelected, theme }) =>
    isOnCard &&
    css`
      background-color: ${theme.colors.dropdownBackground};
      padding: 1rem;
      border-style: solid;
      border-width: 2px;
      border-color: ${isSelected ? theme.colors.activeCardBorder : 'transparent'};
    `}

  ${({ isOnCard, isSelected, theme }) =>
    isOnCard &&
    isSelected &&
    css`
      border-color: ${theme.colors.activeCardBorder};
    `}
`;

const StyledContainer = styled.div<StyledProps>`
  cursor: pointer;

  ${({ isLocked }) =>
    isLocked &&
    css`
      filter: blur(5px) opacity(35%);
      pointer-events: none;
      user-select: none;
      /* Hack to speed it up on safari */
      -webkit-transform: translateZ(0);
      -webkit-perspective: 1000;
      -webkit-backface-visibility: hidden;
    `}
`;

type ListItemWrapperProps = PropsWithChildren &
  StyledProps & { isFirstLocked?: boolean; isOnCard?: boolean; isSelected?: boolean; trackPage: TrackPage };

export const ListItemWrapper = ({
  children,
  isFirstLocked = false,
  isLocked = false,
  isOnCard = true,
  isSelected,
  trackPage,
}: ListItemWrapperProps) => (
  <StyledOuterContainer isOnCard={isOnCard} isSelected={isSelected}>
    <StyledContainer isLocked={isLocked}>{children}</StyledContainer>
    {isLocked && <LockedContent trackPage={trackPage} isFirstLocked={isFirstLocked} />}
  </StyledOuterContainer>
);
