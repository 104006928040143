import { MouseEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { ChevronIcon, UserProfileImage, UIButton } from 'common/components';
import { breakpoints, breakpointValues } from 'common/styles';
import { trackEvent } from 'domains/analytics/actions';
import { TrackEvent } from 'domains/analytics/types';
import { getIsAuthenticated } from 'domains/authentication/selectors';
import { useTranslations } from 'domains/language/useTranslations';
import { Dropdown } from 'domains/navigation/components/Dropdown';
import { NavigationIconContainer } from 'domains/navigation/components/IconContainer';
import { BookmarksButton } from 'domains/navigation/components/profile/BookmarksButton';
import { LogoutButton } from 'domains/navigation/components/profile/LogoutButton';
import { ManageMembershipButton } from 'domains/navigation/components/profile/ManageMembershipButton';
import { MyAccountButton } from 'domains/navigation/components/profile/MyAccountButton';
import { NotificationButton } from 'domains/navigation/components/profile/NotificationButton';
import { TopBarItem } from 'domains/navigation/components/TopBarItem';
import { fetchNotifications } from 'domains/notifications/actions';
import { getNotificationBadgeCount } from 'domains/notifications/selectors';
import { fetchProfile } from 'entities/user/actions';
import {
  getCanAccessPaymentPortal,
  getCanDisplayBookmarks,
  getShouldDisplayNotifications,
  getUserData,
} from 'entities/user/selectors';
import { useAuth } from 'useAuth';

const StyledNotificationBadge = styled.div`
  position: absolute;
  left: -3px;
  bottom: -3px;
  width: 14px;
  height: 14px;
  background-color: ${({ theme }) => theme.colors.red};
  border: 2px solid ${({ theme }) => theme.colors.backgroundSecondary};
  border-radius: 50%;
`;

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-right: 2rem;

  @media (max-width: ${breakpointValues.large - 1}px) {
    flex-direction: column;
    margin-top: 1rem;
    & > button {
      min-width: 70%;
    }
  }
`;

const StyledUserProfileImage = styled(UserProfileImage)`
  width: 36px;
  height: 36px;
`;

const StyledTopBarItem = styled(TopBarItem)`
  border-right: none;

  @media ${breakpoints.large} {
    padding: 0 1.5rem;
    margin-right: 0;
  }
`;

const StyledText = styled.p`
  margin: 0;
  display: inline-block;

  @media ${breakpoints.large} {
    display: none;
  }
`;

const StyledChevronIcon = styled(ChevronIcon)`
  width: 16px;
  height: 16px;
  color: ${({ theme }) => theme.colors.fillPrimary};
  transform: rotate(90deg);
  margin-top: 5px;

  @media ${breakpoints.large} {
    margin-top: 0;
    margin-left: 10px;
    width: 10px;
    height: 10px;
  }
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
`;

type UserProfileButtonProps = { onClick?: () => void; showRegister?: boolean };

export const UserProfileButton = ({ onClick, showRegister = true }: UserProfileButtonProps) => {
  const dispatch = useDispatch();
  const auth = useAuth(true);
  const notificationBadgeCount = useSelector(getNotificationBadgeCount);
  const shouldShowNotifications = useSelector(getShouldDisplayNotifications);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const userData = useSelector(getUserData);
  const accessToNotifications = useSelector(getShouldDisplayNotifications);
  const accessToBookmarks = useSelector(getCanDisplayBookmarks);
  const canAccessPaymentPortal = useSelector(getCanAccessPaymentPortal);
  const { textDictionary } = useTranslations();

  const openLoginRegister = (name: TrackEvent) => (event: MouseEvent) => {
    dispatch(trackEvent({ name, eventType: 'initialize modal' }));
    if (onClick) {
      onClick();
    }

    auth.triggerLogin();
    event.preventDefault();
  };

  useEffect(() => {
    if (isAuthenticated && !userData?.id) {
      dispatch(fetchProfile());
    }
  }, [dispatch, isAuthenticated, userData]);

  useEffect(() => {
    if (shouldShowNotifications) {
      dispatch(fetchNotifications({ offset: 0 }));
    }
  }, [dispatch, shouldShowNotifications]);

  if (!isAuthenticated) {
    return (
      <StyledWrapper>
        {showRegister && (
          <UIButton onClick={openLoginRegister('Signup')} variant={'filled'}>
            {textDictionary['app.registration.join_us']}
          </UIButton>
        )}
        <UIButton onClick={openLoginRegister('Login')} data-test-id="trigger-login-modal-button" variant={'outlined'}>
          {textDictionary['app.user.sign_in']}
        </UIButton>
      </StyledWrapper>
    );
  }

  const profileButton = (
    <StyledTopBarItem opacity={1}>
      <StyledRow>
        <NavigationIconContainer>
          <StyledUserProfileImage>{notificationBadgeCount !== 0 && <StyledNotificationBadge />}</StyledUserProfileImage>
        </NavigationIconContainer>
        <StyledText>{textDictionary['app.profile.title']}</StyledText>
      </StyledRow>
      <StyledChevronIcon />
    </StyledTopBarItem>
  );

  return (
    <Dropdown button={profileButton} alignment="right" onClick={onClick}>
      <MyAccountButton />
      {canAccessPaymentPortal && <ManageMembershipButton />}
      {accessToNotifications && <NotificationButton />}
      {accessToBookmarks && <BookmarksButton />}
      <LogoutButton />
    </Dropdown>
  );
};
