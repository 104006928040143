import { isSameSearchCriteria } from 'domains/publication/reducer';
import { RootState } from 'types';

const getInitiativesStateForTopic = (state: RootState, topicId: string) => {
  const { offset: _offset, limit: _limit, ...initialCriteria } = getInitiativesSearchCriteria(state);
  const searchCriteria = {
    ...initialCriteria,
    id: topicId,
  };
  const matchingInitiativePackage = state.domains.initiatives.forTopic[topicId]?.find((initiativePackage) =>
    isSameSearchCriteria(initiativePackage.searchCriteria, searchCriteria),
  );
  return matchingInitiativePackage;
};

export const getInitiativesForTopic = (topicId: string, limit?: number) => (state: RootState) => {
  const result = (getInitiativesStateForTopic(state, topicId)?.data || []).map(
    (id) => state.entities.initiatives.byId[id],
  );
  return limit ? result.slice(0, limit) : result;
};

export const isFetchingInitiativesForTopic = (topicId: string) => (state: RootState) =>
  getInitiativesStateForTopic(state, topicId)?.isFetching || false;

export const hasNextInitiativesForTopicPage = (topicId: string) => (state: RootState) => {
  const eventState = getInitiativesStateForTopic(state, topicId);
  return eventState ? eventState.hasNextPage : true;
};

export const getIsInitiativesFiltered = (state: RootState) => {
  const filter = state.domains.initiatives.searchCriteria;
  return !!filter.type || !!filter.searchTerm;
};

export const getInitiativesSearchCriteria = (state: RootState) => state.domains.initiatives.searchCriteria;

export const getInitiativesFilterCount = (state: RootState) => {
  const { type, searchTerm } = state.domains.initiatives.searchCriteria;
  const count = [type, searchTerm].filter((item) => !!item).length;
  return count;
};
