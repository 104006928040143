import { MouseEvent, ReactNode } from 'react';
import { styled } from 'styled-components';

import { ImagePlaceholder } from 'common/components/image/ImagePlaceholder';
import { imageFadeInStyle } from 'common/components/image/imageStyles';

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const StyledImagePlaceholder = styled(ImagePlaceholder)`
  ${imageFadeInStyle}
`;

type ImageFallbackProps = {
  children?: ReactNode;
  className?: string;
  dataTestId?: string;
  onClick?: (event: MouseEvent) => void;
  renderPlaceholder?: () => ReactNode;
  src?: string;
};

export const ImageFallback = ({ className, onClick, renderPlaceholder, dataTestId }: ImageFallbackProps) => (
  <StyledContainer className={className} onClick={onClick} data-test-id={dataTestId}>
    {renderPlaceholder ? renderPlaceholder() : <StyledImagePlaceholder />}
  </StyledContainer>
);
