import { ComponentPropsWithoutRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { css, styled } from 'styled-components';

export const linkStyles = css`
  cursor: pointer;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.textBright};
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const StyledLink = styled.a`
  ${linkStyles}
`;

const StyledRouterLink = styled(RouterLink)`
  ${linkStyles}
`;

type LinkProps = Omit<Parameters<typeof RouterLink>[0], 'ref'> | ComponentPropsWithoutRef<'a'>;
export const Link = (props: LinkProps) => ('to' in props ? <StyledRouterLink {...props} /> : <StyledLink {...props} />);
