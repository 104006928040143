import {
  ClearFilteredTopicsAction,
  FetchFilteredTopicMeta,
  FetchFilteredTopicsAction,
  FetchFilteredTopicsActionsTypes,
  FetchFilteredTopicsFailureAction,
  FetchFilteredTopicSuccessAction,
  FetchFilteredTopicSuccessMeta,
} from 'domains/filteredTopicList/actionTypes';

export const fetchFilteredTopics = (meta: FetchFilteredTopicMeta): FetchFilteredTopicsAction => ({
  type: FetchFilteredTopicsActionsTypes.FETCH_FILTERED_TOPICS,
  meta,
});

export const fetchFilteredTopicsSuccess = (meta: FetchFilteredTopicSuccessMeta): FetchFilteredTopicSuccessAction => ({
  type: FetchFilteredTopicsActionsTypes.FETCH_FILTERED_TOPICS_SUCCESS,
  meta,
});

export const fetchFilteredTopicsFailure = (): FetchFilteredTopicsFailureAction => ({
  type: FetchFilteredTopicsActionsTypes.FETCH_FILTERED_TOPICS_FAILURE,
});

export const clearFilteredTopics = (): ClearFilteredTopicsAction => ({
  type: FetchFilteredTopicsActionsTypes.CLEAR_FILTERED_TOPICS,
});
