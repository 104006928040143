import { useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { StarIcon } from 'common/components';
import { useTranslations } from 'domains/language/useTranslations';
import { TopBarItem } from 'domains/navigation/components/TopBarItem';
import { getCanAccessMonitor } from 'entities/user/selectors';

const StyledStarIcon = styled(StarIcon)`
  height: 16px;
  margin-left: 10px;
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
`;

type MonitorItemProps = { onClick?: () => void };

export const MonitorItem = ({ onClick }: MonitorItemProps) => {
  const { textDictionary } = useTranslations();
  const accessToMonitor = useSelector(getCanAccessMonitor);

  return (
    <TopBarItem to="/monitor" onClick={onClick}>
      <StyledRow>{textDictionary['app.monitor.title']}</StyledRow>
      {!accessToMonitor && <StyledStarIcon />}
    </TopBarItem>
  );
};
