import { put, select } from 'redux-saga/effects';

import { getAdvancedKnowledgeCriteria } from 'domains/publication/selectors';
import { AdvancedTopicKnowledgeSearchCriteria } from 'domains/types';
import { TopicVideoApplySearchCriteriaAction } from 'domains/videos/actionTypes';
import { getVideoSearchCriteria } from 'domains/videos/selectors';
import { fetchVideosForTopic } from 'entities/videos/actions';
import { VideosFetchForTopicPayload } from 'entities/videos/actionTypes';

export function* applyVideoSearchCriteriaSaga(action: TopicVideoApplySearchCriteriaAction) {
  const searchCriteria: VideosFetchForTopicPayload = yield select(getVideoSearchCriteria);
  const advancedCriteria: AdvancedTopicKnowledgeSearchCriteria = yield select(getAdvancedKnowledgeCriteria);
  const id = action.payload?.topicId ?? advancedCriteria.id;
  if (!id) {
    return;
  }
  const payload = { ...advancedCriteria, ...searchCriteria, id };
  yield put(fetchVideosForTopic(payload));
}
