import { css } from 'styled-components';

export const createScrollbarStyle = () => css`
  &::-webkit-scrollbar {
    width: 3px;
    -webkit-appearance: none;
    appearance: none;
    background-color: inherit;
  }

  &::-webkit-scrollbar-track {
    opacity: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 4px;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.textFade};
    }
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.textBright};
  }
`;
