import { styled } from 'styled-components';

import { ImageBackground } from 'common/components';
import { breakWordStyle } from 'common/styles';
import { Topic } from 'entities/topics/types';

const StyledRow = styled.div`
  display: flex;
  margin-left: 8px;
`;

const StyledPopoverContainer = styled.div`
  position: relative;
  margin: 0 0 0 -8px;
`;

const StyledPopover = styled.div`
  box-sizing: border-box;
  transition: opacity 0.3s;
  opacity: 0;
  position: absolute;
  padding: 10px;
  width: 120px;
  text-align: center;
  left: -40px;
  bottom: calc(100% + 10px);
  background: ${({ theme }) => theme.colors.blue};
  border-radius: 6px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  border: ${({ theme }) => theme.borders.normal};
  z-index: 100;
  color: white;

  visibility: hidden;

  ${breakWordStyle}

  &:before {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid ${({ theme }) => theme.colors.blue};
    content: '';
    display: block;
    left: calc(50% - 5px);
    top: 100%;
    margin-left: -7px;
    position: absolute;
  }
`;

const StyledImage = styled(ImageBackground)`
  box-sizing: border-box;
  width: 36px;
  min-width: 36px;
  height: 36px;
  border-radius: 50%;
  position: relative;

  &:first-child {
    margin-left: 0;
  }

  &:hover + ${StyledPopover} {
    opacity: 1;
    visibility: visible;
  }
`;

const StyledMoreLabel = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  color: #8788a0;
  background-color: #e4eaed;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LENGTH = 6;

type TopicIconsProps = { className?: string; topics?: Topic[] };

export const TopicIcons = ({ topics = [], className }: TopicIconsProps) => {
  if (!topics) {
    return null;
  }

  const undisplayedTopicCount = topics.length - LENGTH;
  const displayedItems = topics.slice(0, LENGTH);

  return (
    <StyledRow className={className}>
      {displayedItems.map(({ id, images, name }) => (
        <StyledPopoverContainer key={id}>
          <StyledImage src={images?.thumbnail} />
          <StyledPopover>{name}</StyledPopover>
        </StyledPopoverContainer>
      ))}
      {undisplayedTopicCount > 0 && <StyledMoreLabel>{undisplayedTopicCount}</StyledMoreLabel>}
    </StyledRow>
  );
};
