import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { createLinkFailure, createLinkSuccess } from 'domains/createShareSettings/actions';
import { CreateInviteLinkDefaultAction } from 'domains/createShareSettings/actionTypes';
import { CreateLinkPayload } from 'domains/createShareSettings/types';

const ENDPOINT_URL = 'mapshare/open_invite';

export function* createLinkSaga({ meta }: CreateInviteLinkDefaultAction) {
  try {
    const payload: CreateLinkPayload = yield apiFetch(ENDPOINT_URL, {
      method: 'POST',
      body: {
        topic_id: meta.topic_id,
        permission: meta.permission,
      },
    });
    yield put(createLinkSuccess(payload, meta));
  } catch (e) {
    yield put(createLinkFailure(meta));
  }
}
