import {
  AcceptMapInviteDefaultAction,
  AcceptMapInviteFulfilledAction,
  AcceptMapInviteRejectedAction,
  MapShareType,
} from 'domains/createShareSettings/actionTypes';
import { NotificationActionType, UserNotificationAction } from 'domains/notifications/actionTypes';
import { NOTIFICATION_LIMIT } from 'domains/notifications/sagas/fetchNotificationsSaga';
import { NotificationState } from 'domains/notifications/types';

const initialState: NotificationState = {
  offset: 0,
  data: {},
  list: [],
  isPending: false,
  error: false,
  badgeCount: 0,
  hasNextPage: false,
  displayNotificationsModal: false,
};

type Action =
  | UserNotificationAction
  | AcceptMapInviteDefaultAction
  | AcceptMapInviteFulfilledAction
  | AcceptMapInviteRejectedAction;

export const notificationsReducer = (state = initialState, action: Action): NotificationState => {
  switch (action.type) {
    case NotificationActionType.FETCH: {
      return {
        ...state,
        isPending: true,
      };
    }
    case NotificationActionType.FETCH_FULFILLED: {
      const data = { ...state.data };
      action.payload.notifications.forEach((item) => {
        data[item.id] = item;
      });
      const newItems = action.payload.notifications.map((item) => item.id);
      return {
        ...state,
        isPending: false,
        offset: action.meta.offset,
        list: action.meta.offset === 0 ? newItems : [...state.list, ...newItems],
        data,
        badgeCount: action.payload.unopened_total,
        hasNextPage: action.payload.notifications.length === NOTIFICATION_LIMIT,
      };
    }
    case NotificationActionType.FETCH_REJECTED: {
      return {
        ...state,
        isPending: false,
        error: true,
      };
    }
    case NotificationActionType.UPDATE: {
      const badgeCount = action.meta.has_been_opened && state.badgeCount > 0 ? state.badgeCount - 1 : state.badgeCount;
      return {
        ...state,
        badgeCount,
        data: {
          ...state.data,
          [action.meta.id]: {
            ...state.data[action.meta.id],
            isUpdatePending: true,
          },
        },
      };
    }
    case NotificationActionType.UPDATE_FULFILLED: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.id]: {
            ...state.data[action.meta.id],
            isUpdatePending: false,
            has_been_opened: action.meta.has_been_opened,
          },
        },
      };
    }
    case NotificationActionType.UPDATE_REJECTED: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.id]: {
            ...state.data[action.meta.id],
            isUpdatePending: false,
            error: true,
          },
        },
      };
    }
    case NotificationActionType.REMOVE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.id]: {
            ...state.data[action.meta.id],
            isUpdatePending: true,
          },
        },
      };
    }
    case NotificationActionType.REMOVE_FULFILLED: {
      const { [action.meta.id]: _, ...data } = state.data;
      return {
        ...state,
        list: state.list.filter((id) => id !== action.meta.id),
        data,
      };
    }
    case NotificationActionType.REMOVE_REJECTED: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.id]: {
            ...state.data[action.meta.id],
            isUpdatePending: false,
            errorRemoving: true,
          },
        },
      };
    }
    case NotificationActionType.MARK_ALL_DONE: {
      const data = { ...state.data };
      Object.keys(state.data).forEach((id) => {
        data[id] = { ...state.data[id], has_been_opened: true };
      });
      return {
        ...state,
        data,
      };
    }
    case MapShareType.ACCEPT_INVITE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.id]: {
            ...state.data[action.meta.id],
            isUpdatePending: true,
            mapShareInviteError: false,
            inviteExpired: false,
          },
        },
      };
    }
    case MapShareType.ACCEPT_INVITE_FULFILLED: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.id]: {
            ...state.data[action.meta.id],
            isUpdatePending: false,
            mapShareInviteError: false,
            inviteExpired: false,
            context: {
              ...state.data[action.meta.id].context,
              has_accepted: true,
            },
          },
        },
      };
    }
    case MapShareType.ACCEPT_INVITE_REJECTED: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.id]: {
            ...state.data[action.meta.id],
            isUpdatePending: false,
            mapShareInviteError: true,
            inviteExpired: action.meta.isExpired,
          },
        },
      };
    }
    case NotificationActionType.DISPLAY_MODAL: {
      if (!action.meta) {
        return {
          ...state,
          displayNotificationsModal: false,
          notificationId: undefined,
        };
      }
      return {
        ...state,
        ...action.meta,
      };
    }
    default:
      return state;
  }
};
