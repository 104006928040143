export enum LanguageCode {
  AR = 'ar',
  DE = 'de',
  EN = 'en',
  ES = 'es',
  FR = 'fr',
  HI = 'hi',
  JA = 'ja',
  KO = 'ko',
  PT = 'pt',
  ZH = 'zh',
}
