import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { FilterButton, HeaderTitle, SectionTitleUnderlined, MainLayout } from 'common/components';
import { mediaStyles, breakpoints } from 'common/styles';
import { trackEvent } from 'domains/analytics/actions';
import { useTranslations } from 'domains/language/useTranslations';
import { activateModal } from 'domains/modal/actions';
import { EmergingThemes } from 'domains/monitor/emergingThemes/components/EmergingThemes';
import { Filter } from 'domains/monitor/filter/components/Filter';
import { getTopicsFilterSelectedIds } from 'domains/monitor/filter/selectors';
import { LatestKnowledge } from 'domains/monitor/latestKnowledge/components/LatestKnowledge';
import { NoAccessSidebar } from 'domains/monitor/noAccess/components/NoAccessSidebar';
import { TopicSuggestions } from 'domains/monitor/suggestions/components/TopicSuggestions';
import { fetchFollowedTopics } from 'entities/topics/actions';
import { getCanAccessMonitor } from 'entities/user/selectors';

const StyledContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1850px;
  box-sizing: border-box;
  ${mediaStyles(({ spacing }) => `padding: 10px 0 ${spacing}rem 0;`)};

  @media ${breakpoints.medium} {
    flex-direction: row;
  }
  @media ${breakpoints.extraLarge} {
    padding-top: 3rem;
  }
`;

const StyledHeaderContainer = styled.div`
  width: 100%;
  padding: 1rem 0;
  display: flex;
  width: 100%;
  justify-content: space-between;

  ${mediaStyles(
    ({ spacing }) => `
      padding: 0 ${spacing}rem;
      margin-top: ${spacing}rem;
    `,
  )};
`;

export const Monitor = () => {
  const dispatch = useDispatch();
  const { textDictionary } = useTranslations();
  const hasAccessToMonitor = useSelector(getCanAccessMonitor);
  const selectedTopics = useSelector(getTopicsFilterSelectedIds);

  useEffect(() => {
    dispatch(fetchFollowedTopics({ limit: 48, offset: 0 }));
  }, [dispatch]);

  const onFilterClick = () =>
    dispatch(
      activateModal({
        id: 'Monitor filter',
      }),
    );

  const sidePanel = hasAccessToMonitor ? (
    <div>
      <Filter />
      <TopicSuggestions />
    </div>
  ) : (
    <>
      <SectionTitleUnderlined>{textDictionary['mp.1.1.title']}</SectionTitleUnderlined>
      <NoAccessSidebar trackPage="Monitor" />
    </>
  );

  const headerElement = (
    <StyledHeaderContainer>
      <HeaderTitle>{textDictionary['app.monitor.title']}</HeaderTitle>
      {hasAccessToMonitor && <FilterButton onClick={onFilterClick} count={selectedTopics.length} />}
    </StyledHeaderContainer>
  );

  useEffect(() => {
    dispatch(trackEvent({ name: 'Browse page', page: 'Monitor' }));
  }, [dispatch]);

  return (
    <StyledContainer>
      <MainLayout headerElement={headerElement} sidePanel={sidePanel}>
        <StyledContent>
          <EmergingThemes />
          <LatestKnowledge />
        </StyledContent>
      </MainLayout>
    </StyledContainer>
  );
};
