import { useDispatch } from 'react-redux';
import { styled } from 'styled-components';

import { Spinner, Image, FollowIcon, CheckIcon } from 'common/components';
import { ImageCollection } from 'common/types/imageCollection';
import { AnalyticsActionMeta } from 'domains/analytics/types';
import { followTopic, unfollowTopic } from 'entities/topics/actions';

const StyledContainer = styled.div`
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledText = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.fillPrimary};
  font-weight: 500;
`;

const StyledImage = styled(Image)`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 15px;
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
`;

const StyledFollowIcon = styled(FollowIcon)`
  color: ${({ theme }) => theme.colors.actionIcon};
  opacity: 0.8;
  cursor: pointer;
  width: 40px;
  height: 40px;
  transition: 0.3s all;
  &:hover {
    opacity: 1;
  }
`;

const StyledUnfollow = styled.div`
  transition: 0.3s all;
  display: flex;
  color: ${({ theme }) => theme.colors.backgroundPrimary};
  background-color: ${({ theme }) => theme.colors.actionIcon};
  opacity: 0.8;
  border-radius: 20px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  &:hover {
    opacity: 1;
  }
`;

const StyledUnfollowIcon = styled(CheckIcon)`
  width: 12px;
  height: 12px;
  fill: none;
`;

const StyledSpinner = styled(Spinner)`
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.colors.blue};
`;

type ManageTopicItemProps = {
  id: string;
  images?: ImageCollection;
  isFollowing: boolean;
  isFollowingUpdating: boolean | undefined;
  name: string;
};

export const ManageTopicItem = ({ id, name, images, isFollowing, isFollowingUpdating }: ManageTopicItemProps) => {
  const dispatch = useDispatch();

  const getIcon = () => {
    if (isFollowingUpdating) return <StyledSpinner />;

    const meta: AnalyticsActionMeta = {
      event: { page: 'Monitor', subpage: 'Manage Topics' },
    };
    if (isFollowing) {
      return (
        <StyledUnfollow
          onClick={() => {
            dispatch(unfollowTopic({ id }, meta));
          }}
        >
          <StyledUnfollowIcon />
        </StyledUnfollow>
      );
    }

    return (
      <StyledFollowIcon
        onClick={() => {
          dispatch(followTopic({ id }, meta));
        }}
      />
    );
  };

  return (
    <StyledContainer>
      <StyledRow>
        <StyledImage src={images && images.x_small} alt={name} />
        <StyledText>{name}</StyledText>
      </StyledRow>
      {getIcon()}
    </StyledContainer>
  );
};
