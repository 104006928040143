import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { updateNotificationFailure, updateNotificationSuccess } from 'domains/notifications/actions';
import { UpdateNotificationDefaultAction } from 'domains/notifications/actionTypes';
import { isFetchError } from 'TypeCheckMethods';

export function* updateNotificationSaga(action: UpdateNotificationDefaultAction) {
  try {
    yield apiFetch(`notification/${action.meta.id}`, {
      method: 'PUT',
      body: { has_been_opened: action.meta.has_been_opened },
    });
    yield put(updateNotificationSuccess(action.meta));
  } catch (error) {
    if (isFetchError(error)) {
      yield put(updateNotificationFailure({ error }, { id: action.meta.id, logoutOnAuthError: true }));
    }
  }
}
