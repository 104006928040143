import { RootState } from 'types';

export const getInvitationPending = (state: RootState) => state.domains.shareMap.invitation.isPending;

export const getInvitationError = (state: RootState) => state.domains.shareMap.invitation.error;

const getAccessList = (state: RootState) =>
  (state.domains.shareMap.collaborators.list || []).map((id) => state.domains.shareMap.collaborators.data[id]);

export const getCollaborators = (state: RootState) => getAccessList(state).filter((user) => !user.pending);

export const getInvitees = (state: RootState) => getAccessList(state).filter((user) => user.pending);

export const getCollaboratorsPending = (state: RootState) => state.domains.shareMap.collaborators.isPending || false;

export const getCollaboratorsError = (state: RootState) => state.domains.shareMap.collaborators.error || false;

export const getShareLinkToken = (state: RootState) => state.domains.shareMap.shareLink.token;

export const getShareLinkIsPending = (state: RootState) => state.domains.shareMap.shareLink.isPending;

export const getShareLinkError = (state: RootState) => state.domains.shareMap.shareLink.error;

export const getIsOpenLinkPending = (state: RootState) => state.domains.shareMap.openLink.isPending;

export const getOpenLinkError = (state: RootState) => state.domains.shareMap.openLink.error;

export const getOpenLinkInvite = (state: RootState) => state.domains.shareMap.openLink.data;
