import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

import { SectionTitleUnderlined } from 'common/components';
import { mediaStyles, breakpoints } from 'common/styles';
import { getSiteLanguage } from 'domains/language/selectors';
import { useTranslations } from 'domains/language/useTranslations';
import { getEmergingThemesHasNextPage } from 'domains/monitor/emergingThemes/selectors';
import { getSelectedTopicFiltersDependency, getTopicsFilterSelectedIds } from 'domains/monitor/filter/selectors';
import { fetchLatestKnowledge } from 'domains/monitor/latestKnowledge/actions';
import {
  getLatestKnowledgeItems,
  getLatestKnowledgeIsPending,
  getLatestKnowledgeHasNextPage,
  getLatestKnowledgeOffset,
} from 'domains/monitor/latestKnowledge/selectors';
import { KnowledgeList } from 'domains/publication/components/KnowledgeList';
import { useIsPhone } from 'domains/viewport/useMedia';
import { getFollowingTopicsDependency } from 'entities/topics/selectors';
import { getCanAccessMonitor, getUserId } from 'entities/user/selectors';

const StyledContainer = styled.div`
  display: flex;
  flex: 5;
  flex-direction: column;
  box-sizing: border-box;
  ${mediaStyles(({ spacing }) => `margin: 0 ${spacing}rem;`)};

  @media ${breakpoints.extraLarge} {
    flex: 4;
  }
`;

const StyledSeeAllButton = styled(Link)`
  margin: 0 0 2rem 0;
  text-align: center;
  color: ${({ theme }) => theme.colors.blue};
  font-weight: bold;
  text-decoration: none;
`;

export const LatestKnowledge = () => {
  const dispatch = useDispatch();
  const { textDictionary } = useTranslations();
  const isMobile = useIsPhone();
  const items = useSelector(getLatestKnowledgeItems);
  const selectedTopics = useSelector(getTopicsFilterSelectedIds);
  const isPending = useSelector(getLatestKnowledgeIsPending);
  const offset = useSelector(getLatestKnowledgeOffset);
  const hasNextPage = useSelector(getLatestKnowledgeHasNextPage);
  const emergingThemesHasNextPage = useSelector(getEmergingThemesHasNextPage);
  const language = useSelector(getSiteLanguage);
  const hasAccessToMonitor = useSelector(getCanAccessMonitor);
  const followingTopicsDependency = useSelector(getFollowingTopicsDependency);
  const selectedTopicsDependency = useSelector(getSelectedTopicFiltersDependency);
  const userId = useSelector(getUserId);

  const displaySeeAllButton = isMobile || !emergingThemesHasNextPage;

  useEffect(() => {
    dispatch(fetchLatestKnowledge({ offset: 0, filters: { topic: selectedTopics } }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTopicsDependency, language, userId, followingTopicsDependency]);

  const loadNextPage = useCallback(() => {
    if (!displaySeeAllButton) {
      dispatch(
        fetchLatestKnowledge({
          offset: offset + 1,
          filters: { topic: selectedTopics },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, selectedTopicsDependency, displaySeeAllButton]);

  const getToLink = (knowledgeId: string) => `/monitor/latest-knowledge/${knowledgeId}`;

  return (
    <StyledContainer>
      <SectionTitleUnderlined isLoading={isPending}>
        {textDictionary['app.monitor.latest_knowledge']}
      </SectionTitleUnderlined>
      {items.length === 0 && !isPending && hasAccessToMonitor ? (
        <span>
          {
            textDictionary[
              selectedTopics.length === 0 ? 'app.monitor.empty_message' : 'app.monitor.empty_message_filter'
            ]
          }
        </span>
      ) : (
        <KnowledgeList
          items={items}
          isPending={isPending}
          hasNextPage={hasNextPage && !displaySeeAllButton}
          loadNextPage={loadNextPage}
          getToLink={getToLink}
          trackPage="Monitor"
          lockTiles={!hasAccessToMonitor}
        />
      )}
      {/* To prevent one infinite list while the other is loaded */}
      {displaySeeAllButton && items.length > 0 && hasNextPage && (
        <StyledSeeAllButton to="/monitor/latest-knowledge">{textDictionary['app.topic.see_all']}</StyledSeeAllButton>
      )}
    </StyledContainer>
  );
};
