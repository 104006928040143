import { styled } from 'styled-components';

type StyledModalTextProps = { small?: boolean };

export const StyledModalText = styled.p<StyledModalTextProps>`
  margin: 0 0 1rem 0;
  font-size: ${({ small }) => (small ? 1 : 1.1)}rem;
  line-height: ${({ small }) => (small ? 1.5 : 1.6)}rem;
  color: ${({ theme }) => theme.colors.textBright};
`;
