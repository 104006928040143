import { useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { StarIcon } from 'common/components';
import { useTranslations } from 'domains/language/useTranslations';
import { TopBarItem } from 'domains/navigation/components/TopBarItem';
import { getCanAccessCreate } from 'entities/user/selectors';

const StyledStarIcon = styled(StarIcon)`
  height: 16px;
  margin-left: 10px;
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
`;

type CreateItemProps = { onClick?: () => void };

export const CreateItem = ({ onClick }: CreateItemProps) => {
  const { textDictionary } = useTranslations();
  const accessToCreate = useSelector(getCanAccessCreate);

  return (
    <TopBarItem to="/create" onClick={onClick}>
      <StyledRow>{textDictionary['app.create.title']}</StyledRow>
      {!accessToCreate && <StyledStarIcon />}
    </TopBarItem>
  );
};
