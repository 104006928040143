import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

const StyledAnchor = styled.a`
  text-decoration: none;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

type LinkButtonProps = PropsWithChildren & { href?: string; target?: string; to?: string };

export const LinkButton = ({ children, href, to }: LinkButtonProps) => {
  if (href) {
    const website = process.env.REACT_APP_WEBSITE_URL;
    const target = website && href.includes(website) ? undefined : '_blank';
    return (
      <StyledAnchor href={href} target={target} rel="noopener noreferrer">
        {children}
      </StyledAnchor>
    );
  }
  if (to) {
    return <StyledLink to={to}>{children}</StyledLink>;
  }
  return <>{children}</>;
};
