import { useEffect, useLayoutEffect, useCallback, useRef, useState, MouseEvent } from 'react';
import { css, styled } from 'styled-components';

import { RightArrow, CrossIcon, Image, UIButton } from 'common/components';
import { breakpoints } from 'common/styles';
import { ImageCollection } from 'common/types/imageCollection';
import { useTranslations } from 'domains/language/useTranslations';

type StyledProps = { activeIndex: number; arrowStyles: Record<string, string>; displayAsRich: boolean; index: number };

const StyledWrapper = styled.aside<StyledProps>`
  position: absolute;
  padding: 1.75rem;
  box-sizing: border-box;
  width: 100%;
  left: 50%;
  transition: all 0.3s ease;

  ${({ theme, activeIndex, index, displayAsRich, arrowStyles }) => css`
    box-shadow: ${theme.shadows.box};
    background-color: ${theme.colors.backgroundSecondary};
    color: ${theme.colors.fillPrimary};
    opacity: ${activeIndex === index ? 1 : 0};
    z-index: ${activeIndex === index ? 9999 : 9998};
    transform: translateX(${-50 + -100 * (activeIndex - index)}%);

    @media ${breakpoints.medium} {
      top: 10rem;
      max-width: 30rem;
      border-radius: 8px;

      ${!displayAsRich &&
      css`
        background-color: ${theme.colors.blue};
        color: white;
        border-radius: 3px;
        transform: none;

        &:before {
          content: '';
          display: block;
          border: 10px solid transparent;
          border-bottom-color: ${theme.colors.blue};
          position: absolute;
          bottom: ${arrowStyles.bottom};
          right: ${arrowStyles.right};
          ${arrowStyles.rotate &&
          css`
            transform: rotate(${arrowStyles.rotate});
          `}
        }
      `}
    }
  `}
`;

const StyledTitle = styled.h3`
  margin: 0 0 1rem;
  padding: 0 3rem 0 0;
  font-weight: bold;
  font-size: 1.3rem;
  color: ${({ theme }) => theme.colors.textBright};
`;

const StyledDescription = styled.p`
  margin: 0 0 1rem 0;
  padding: 0;
  font-size: 0.9rem;
  line-height: 1.3rem;
  color: ${({ theme }) => theme.colors.textFade};
`;

const StyledImage = styled(Image)`
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
`;

const StyledCloseButton = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  position: absolute;
  right: 2rem;
  top: 2rem;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
`;

const StyledCloseButtonIcon = styled(CrossIcon)`
  width: 20px;
  height: 20px;
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  padding-top: 1.5rem;
  gap: 1rem;
`;

const StyledPreviousButtonIcon = styled(RightArrow)`
  margin: 0 0.5rem 0 0;
  padding: 0;
  transform: rotate(180deg);
  width: 14px;
  height: 14px;
`;

const StyledNextButtonIcon = styled(StyledPreviousButtonIcon)`
  margin: 0 0 0 0.5rem;
  transform: rotate(0deg);
`;

const StyledNav = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  text-align: center;
  margin-top: 1rem;
  font-weight: bold;
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.white};
`;

const keepInBounds = (value: number, lowerBound: number, upperBound: number) =>
  Math.min(upperBound, Math.max(lowerBound, value));

type CoachmarkProps = {
  activeIndex: number;
  coachmarkCount: number;
  description: string;
  images: ImageCollection;
  index: number;
  onClose: () => void;
  onNextClick: () => void;
  onPrevClick: () => void;
  target_selector: string;
  title: string;
  viewportHeight: number;
  viewportWidth: number;
};

export const Coachmark = ({
  activeIndex,
  coachmarkCount,
  description,
  images,
  index,
  onClose,
  onNextClick,
  onPrevClick,
  target_selector,
  title,
  viewportHeight,
  viewportWidth,
}: CoachmarkProps) => {
  const { textDictionary } = useTranslations();
  const [wrapperStyles, setWrapperStyles] = useState({});
  const [wrapperArrowStyles, setWrapperArrowStyles] = useState({});
  const wrapperRef = useRef<HTMLDivElement>(null);
  const isInRange = Math.abs(activeIndex - index) <= 1;

  const handleCloseButtonClick = (event: MouseEvent) => {
    event.preventDefault();
    onClose();
  };

  const calculatePositions = useCallback(() => {
    // Position the coachmark and its arrow to highlight the target element
    const targetElement = document.querySelector(target_selector);
    if (images || !targetElement || !wrapperRef.current) {
      setWrapperStyles({});
      return;
    }
    const wrapperBounds = wrapperRef.current.getBoundingClientRect();
    const targetBounds = targetElement.getBoundingClientRect();

    if (targetBounds.height < targetBounds.top) {
      // Target isn't too big, so position somewhat horizonal central of target
      const centralLeft = targetBounds.left + targetBounds.width / 2 - wrapperBounds.width / 2 + 10;
      const maxLeft = viewportWidth - wrapperBounds.width - 20;
      const left = keepInBounds(centralLeft, 20, maxLeft);

      // Put the arrow in the middle of the target, adjusting
      // for the case where the coachmark can't be central
      let newArrowStyles: Record<string, string> = { right: '50%' };
      if (centralLeft !== left) {
        const wrapperRight = left + wrapperBounds.width;
        const arrowRightPixels = wrapperRight - 10 - (targetBounds.left + targetBounds.width / 2);
        newArrowStyles = {
          right: `${arrowRightPixels}px`,
        };
      }

      // Default to positioning below, but put above if not enough space
      let top = targetBounds.top + targetBounds.height + 20;
      newArrowStyles = {
        ...newArrowStyles,
        bottom: '100%',
      };
      if (top + wrapperBounds.height >= viewportHeight) {
        top = targetBounds.top - wrapperBounds.height - 20;
        newArrowStyles = {
          ...newArrowStyles,
          bottom: '0%',
          rotate: '-180deg',
        };
      }
      setWrapperStyles({
        left,
        top,
        right: 'auto',
      });
      setWrapperArrowStyles(newArrowStyles);
    } else {
      // Target is big, so position vertically centre of target and to the left/right
      const top = targetBounds.top + targetBounds.height / 2 - wrapperBounds.height / 2;
      let left, right, maxWidth;
      if (targetBounds.left < viewportWidth / 2) {
        left = targetBounds.right + 20;
        right = 'auto';
        maxWidth = `${viewportWidth - left - 20}px`;
      } else {
        left = 'auto';
        right = targetBounds.left - 20;
        maxWidth = `${viewportWidth - right}px`;
      }
      setWrapperStyles({
        top,
        left,
        right,
        maxWidth,
      });
      setWrapperArrowStyles({
        right: '100%',
        bottom: '50%',
        rotate: '-90deg',
      });
    }
  }, [images, target_selector, viewportHeight, viewportWidth]);

  useEffect(() => {
    const targetElement = document.querySelector(target_selector);
    if (!targetElement || images || !isInRange) {
      return;
    }
    const resizeObserver = new ResizeObserver(calculatePositions);
    resizeObserver.observe(targetElement);

    return () => {
      resizeObserver.unobserve(targetElement);
    };
  }, [target_selector, images, calculatePositions, isInRange]);

  useLayoutEffect(() => {
    if (!isInRange) {
      return;
    }

    calculatePositions();
  }, [calculatePositions, isInRange]);

  const isLast = index + 1 >= coachmarkCount;

  return (
    <StyledWrapper
      displayAsRich={!!images}
      style={wrapperStyles}
      activeIndex={activeIndex}
      index={index}
      arrowStyles={wrapperArrowStyles}
      ref={wrapperRef}
    >
      <StyledTitle>{title}</StyledTitle>
      <StyledCloseButton onClick={handleCloseButtonClick}>
        <StyledCloseButtonIcon />
      </StyledCloseButton>
      <StyledDescription>{description}</StyledDescription>
      {images?.medium && <StyledImage src={images.medium} alt={title} />}
      {coachmarkCount > 1 && (
        <>
          <StyledButtonWrapper>
            {index !== 0 && (
              <UIButton onClick={onPrevClick} variant="outlined">
                <StyledPreviousButtonIcon />
                {textDictionary['app.coachmarks.previous']}
              </UIButton>
            )}
            <UIButton onClick={isLast ? handleCloseButtonClick : onNextClick} variant="filled">
              {textDictionary[isLast ? 'app.monitor2.following.done' : 'app.coachmarks.next']}
              {!isLast && <StyledNextButtonIcon />}
            </UIButton>
          </StyledButtonWrapper>
          <StyledNav>
            {activeIndex + 1} / {coachmarkCount}
          </StyledNav>
        </>
      )}
    </StyledWrapper>
  );
};
