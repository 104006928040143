import { useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { MenuIcon } from 'common/components';
import { getNotificationBadgeCount } from 'domains/notifications/selectors';

const StyledContainer = styled.div`
  position: relative;
`;

const StyledMenuIcon = styled(MenuIcon)`
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.colors.fillPrimary};
  cursor: pointer;
`;

const StyledNotificationBadge = styled.div`
  position: absolute;
  left: -4px;
  bottom: 4px;
  width: 10px;
  height: 10px;
  background-color: ${({ theme }) => theme.colors.red};
  border: 2px solid ${({ theme }) => theme.colors.backgroundSecondary};
  border-radius: 50%;
`;

type MobileMenuButtonProps = { onClick: () => void };

export const MobileMenuButton = ({ onClick }: MobileMenuButtonProps) => {
  const count = useSelector(getNotificationBadgeCount);

  return (
    <StyledContainer>
      <StyledMenuIcon onClick={onClick} />
      {count !== 0 && <StyledNotificationBadge />}
    </StyledContainer>
  );
};
