import { combineReducers } from 'redux';

import { searchCriteriaReducer as buildSearchCriteriaReducer } from 'common/reducer/searchCriteriaReducer';
import { TopicKnowledgeDataActionTypes } from 'domains/knowledgeData/actionTypes';
import {
  FullTopicKnowledgeDataCriteria,
  TopicKnowledgeDataPackage,
  TopicKnowledgeDataState,
} from 'domains/knowledgeData/types';
import { findMatchingCriteriaAndOthers } from 'domains/publication/reducer';
import { addTopicSelectionReset, addUserOrMapChangeReset } from 'domains/topic/reducerHelpers';
import { KnowledgeDataAction, KnowledgeDataActionTypes } from 'entities/knowledgeData/actionTypes';

const initialState: TopicKnowledgeDataState = {
  forTopic: {},
  searchCriteria: { searchTerm: '', limit: 20, offset: 0, publicationType: '' },
};

// Convenience function to capture the generic type args
const findKnowledgeDataPackageAndOthers = (
  forTopic: TopicKnowledgeDataState['forTopic'],
  searchCriteria: FullTopicKnowledgeDataCriteria,
) => findMatchingCriteriaAndOthers<TopicKnowledgeDataPackage, FullTopicKnowledgeDataCriteria>(forTopic, searchCriteria);

const knowledgeDataForTopicReducer = (state = initialState.forTopic, action: KnowledgeDataAction) => {
  switch (action.type) {
    case KnowledgeDataActionTypes.FETCH_FOR_TOPIC: {
      const { offset: _offset, limit: _limit, ...searchCriteria } = action.payload;
      const [matchingKnowledgeDataPackage, others] = findKnowledgeDataPackageAndOthers(state, searchCriteria);
      const newKnowledgeDataPackage = {
        ...(matchingKnowledgeDataPackage || {}),
        isFetching: true,
        hasNextPage: true,
        error: false,
        data: matchingKnowledgeDataPackage?.data || [],
        searchCriteria,
      };
      return {
        ...state,
        [searchCriteria.id]: [...others, newKnowledgeDataPackage],
      };
    }

    case KnowledgeDataActionTypes.FETCH_FOR_TOPIC_SUCCESS: {
      const { data } = action.payload;
      const { offset, limit, ...searchCriteria } = action.meta;
      const [matchingKnowledgeDataPackage, others] = findKnowledgeDataPackageAndOthers(state, searchCriteria);

      const dataToKeep = matchingKnowledgeDataPackage?.data.slice(0, offset) || [];
      const newKnowledgeDataPackage = {
        ...(matchingKnowledgeDataPackage || {}),
        isFetching: false,
        hasNextPage: data.length >= limit,
        error: false,
        data: dataToKeep.concat(data.map(({ id }) => id)),
        searchCriteria,
      };
      return {
        ...state,
        [searchCriteria.id]: [...others, newKnowledgeDataPackage],
      };
    }

    case KnowledgeDataActionTypes.FETCH_FOR_TOPIC_FAILURE: {
      const { searchCriteria } = action.meta;
      const [matchingKnowledgeDataPackage, others] = findKnowledgeDataPackageAndOthers(state, searchCriteria);
      const newKnowledgeDataPackage = {
        ...(matchingKnowledgeDataPackage || {}),
        isFetching: false,
        hasNextPage: false,
        error: true,
        data: matchingKnowledgeDataPackage?.data || [],
        searchCriteria,
      };
      return {
        ...state,
        [searchCriteria.id]: [...others, newKnowledgeDataPackage],
      };
    }

    default:
      return state;
  }
};

const searchCriteriaReducer = buildSearchCriteriaReducer(
  initialState.searchCriteria,
  TopicKnowledgeDataActionTypes.APPLY_SEARCH_CRITERIA,
  TopicKnowledgeDataActionTypes.CLEAR_SEARCH_CRITERIA,
);

export const knowledgeDataReducer = combineReducers({
  forTopic: addUserOrMapChangeReset(knowledgeDataForTopicReducer, initialState.forTopic),
  searchCriteria: addTopicSelectionReset(searchCriteriaReducer, initialState.searchCriteria),
});
