import { ReactNode } from 'react';
import { styled } from 'styled-components';

import { ListItemBox } from 'common/components/lists/ListItemBox';

const StyledContainer = styled(ListItemBox)<{ $height: number }>`
  width: 100%;
  height: ${({ $height }) => $height}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
`;

const StyledImagePlaceholder = styled.div<{ $height: number; $width: number }>`
  width: ${({ $width }) => $width}px;
  min-width: ${({ $width }) => $width}px;
  height: ${({ $height }) => $height}px;
  border-radius: 5px;
  margin-right: 1rem;
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSeeDetailButton = styled.div`
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  height: 30px;
  width: 35px;
  margin-left: 1rem;
`;

const StyledColumn = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledLine = styled.div<{ $width: number; $lineMargin?: number }>`
  height: 1.3rem;
  width: ${({ $width }) => $width}%;
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  border-radius: 5px;
  margin: ${({ $lineMargin = 3 }) => $lineMargin}px 0;
`;

type LoadingCardsProps = {
  cardHeight: number;
  displayDetailButton?: boolean;
  imageHeight: number;
  imageIcon?: ReactNode;
  imageWidth: number;
  lineMargin?: number;
  lines: number[];
  numberOfItems?: number;
};

const getArray = (n: number) => Array.from(Array(n).keys());

export const LoadingCards = ({
  numberOfItems = 3,
  cardHeight,
  imageHeight,
  imageWidth,
  lines,
  lineMargin,
  imageIcon,
  displayDetailButton = false,
}: LoadingCardsProps) => (
  <>
    {getArray(numberOfItems).map((index) => (
      <StyledContainer key={index} $height={cardHeight}>
        <StyledImagePlaceholder $height={imageHeight} $width={imageWidth}>
          {imageIcon}
        </StyledImagePlaceholder>
        <StyledColumn>
          {lines.map((width, innerIndex) => (
            <StyledLine key={`${index}${innerIndex}`} $width={width} $lineMargin={lineMargin} />
          ))}
        </StyledColumn>
        {displayDetailButton && <StyledSeeDetailButton />}
      </StyledContainer>
    ))}
  </>
);
