import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { MasterDetailLayout } from 'common/components';
import { getPageId } from 'common/utilities';
import { getSiteLanguage } from 'domains/language/selectors';
import { useTranslations } from 'domains/language/useTranslations';
import { fetchMembershipEventById, fetchMembershipEvents } from 'domains/membershipEvents/actions';
import { MembershipEventDetail } from 'domains/membershipEvents/components/MembershipEventDetail';
import { MembershipEventList } from 'domains/membershipEvents/components/MembershipEventList';
import {
  getMembershipEvents,
  getMembershipEventsById,
  getMembershipEventsIsPending,
} from 'domains/membershipEvents/selectors';
import { MembershipEvent } from 'domains/membershipEvents/types';
import { getHasAccessToMembershipEvents } from 'entities/user/selectors';

const getEventList = (list: MembershipEvent[], event: MembershipEvent) => {
  if (list.length === 0 && event) {
    return [event];
  }
  return list || [];
};

export const UpcomingEventsPage = () => {
  const dispatch = useDispatch();
  const { eventId } = useParams<{ eventId: string }>();
  const { textDictionary } = useTranslations();
  const items = useSelector(getMembershipEvents(0));
  const isPending = useSelector(getMembershipEventsIsPending);

  const id = getPageId(eventId, items);
  const hasAccess = useSelector(getHasAccessToMembershipEvents);
  const event = useSelector(getMembershipEventsById(id));
  const language = useSelector(getSiteLanguage);

  useEffect(() => {
    if (items.length === 0 && hasAccess) {
      dispatch(fetchMembershipEvents());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, language, hasAccess]);

  useEffect(() => {
    if (id) {
      dispatch(fetchMembershipEventById({ id }));
    }
  }, [id, dispatch]);

  if (!event) {
    return null;
  }

  const list = (
    <MembershipEventList
      items={getEventList(items, event)}
      isPending={isPending}
      selectedId={id}
      showSeparators={hasAccess}
    />
  );

  return (
    <MasterDetailLayout
      listTitle={textDictionary['app.event.upcoming_events']}
      listComponent={list}
      mainView={eventId ? 'right' : 'left'}
      listBackNavigation={{
        label: textDictionary['app.navigation.back'],
        path: '/',
      }}
      detailBackNavigation={{
        label: textDictionary['app.event.upcoming_events'],
        path: '/membership-events',
      }}
    >
      <MembershipEventDetail event={event} />
    </MasterDetailLayout>
  );
};
