import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { activateModal } from 'domains/modal/actions';

export const WhatsNewRoute = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(activateModal({ id: 'Whats New' }));
  }, [dispatch]);

  return <Redirect to="/" />;
};
