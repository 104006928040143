import { KeyboardEventHandler, MouseEvent, useEffect, useRef, useState } from 'react';
import { css, styled } from 'styled-components';

import { ExpandableContent } from 'common/components/ExpandableContent';
import { UIInput } from 'common/components/form/UIInput';
import { CrossIcon, PlusIcon } from 'common/components/icons';
import { imageFadeInStyle } from 'common/components/image/imageStyles';
import { UIChip } from 'common/components/UIChip';
import { lineTruncationStyle } from 'common/styles';
import { ImageCollection } from 'common/types/imageCollection';
import { useOutsideClickDetection } from 'common/utilities';
import { useTranslations } from 'domains/language/useTranslations';
import { TopicImage } from 'domains/topic/components/TopicImage';

const StyledContainer = styled.div`
  position: relative;
`;

const StyledDropdown = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  border-radius: 5px;
  z-index: 4;
  background-color: ${({ theme }) => theme.colors.selectItemBackground};
  color: ${({ theme }) => theme.colors.textBright};
`;

const StyledDropdownItem = styled.div<{ isHighlighted: boolean }>`
  transition: color 0.3s;
  padding: 0.8rem;
  font-size: 0.9rem;
  color: ${({ theme, isHighlighted }) => (isHighlighted ? theme.colors.textBright : theme.colors.textFade)};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`;

const StyledDropdownItemDescription = styled.p`
  font-size: 0.75rem;
  margin: 3px 0 0;
  ${lineTruncationStyle(1)}
`;

const StyledDropdownItemSubtitle = styled(StyledDropdownItemDescription)`
  font-weight: 500;
  margin-top: 5px;
`;

const StyledDropdownItemImage = styled(TopicImage)`
  ${imageFadeInStyle}
  width: 60px;
  height: 40px;
  border-radius: 4px;
  margin-left: 0.8rem;
`;

const StyledPlaceholder = styled.div`
  padding: 1rem;
`;

const StyledTagContainer = styled.div<{ hasTags: boolean }>`
  transition: margin-bottom 0.3s;
  margin-bottom: ${({ hasTags }) => (hasTags ? 'calc(1rem - 10px)' : '1rem')};
`;

const StyledTag = styled(UIChip)`
  margin: 0 10px 10px 0;
  white-space: nowrap;
  display: inline-flex;
  height: 19px;
`;

const iconStyle = css`
  width: 14px;
  height: 14px;
  margin-left: 10px;
  cursor: pointer;
`;

const StyledAddIcon = styled(PlusIcon)`
  ${iconStyle}
`;

const StyledCrossIcon = styled(CrossIcon)`
  ${iconStyle}
`;

const StyledTagLabel = styled.div`
  display: inline-block;
  max-width: 230px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export type SelectInputOption = {
  description?: string;
  images?: ImageCollection;
  label: string;
  subtitle?: string;
  value: string;
};

type SelectInputProps = {
  className?: string;
  dataTestId?: string;
  defaultItems?: SelectInputOption[];
  disabled?: boolean;
  dropdownOptions: SelectInputOption[];
  isPending?: boolean;
  label: string;
  onAddSuggestion?: (value: SelectInputOption) => void;
  onInputChange?: (value: string) => void;
  onItemRemove: (value: SelectInputOption) => void;
  onItemSelect: (value: SelectInputOption) => void;
  placeholder: string;
  suggestionOptions?: SelectInputOption[];
};

export const SelectInput = ({
  label,
  dropdownOptions,
  suggestionOptions,
  isPending,
  placeholder,
  defaultItems = [],
  onItemSelect,
  onAddSuggestion,
  onItemRemove,
  onInputChange,
  className,
  disabled,
  dataTestId,
}: SelectInputProps) => {
  const textInputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [selectedItems, setSelectedItems] = useState<SelectInputOption[]>(defaultItems);
  const containerRef = useRef<HTMLDivElement>(null);
  const [highlightedItemIndex, setHighlightedItemIndex] = useState(0);
  const selectedItemValues = selectedItems.map(({ value }) => value);
  const unselectedItems = dropdownOptions.filter(({ value }) => !selectedItemValues.includes(value));

  useOutsideClickDetection(containerRef, () => {
    setIsActive(false);
  });

  const onValueChange = (value: string) => {
    setValue(value);
    if (onInputChange) {
      onInputChange(value);
    }
  };

  const onFocus = () => setIsActive(true);

  const onBlur = () => setIsActive(false);

  const onSelect = (item: SelectInputOption) => {
    setSelectedItems([...selectedItems, item]);
    onItemSelect(item);
    textInputRef.current?.focus();
    setValue('');
  };

  const removeItem = (item: SelectInputOption) => () => {
    const newItems = selectedItems.filter((selectedItem) => selectedItem.value !== item.value);
    setSelectedItems(newItems);
    onItemRemove(item);
    setHighlightedItemIndex(Math.min(0, highlightedItemIndex - 1));
  };

  const addSuggestionHandler = (item: SelectInputOption) => () => {
    if (!onAddSuggestion) {
      return;
    }

    onAddSuggestion(item);
  };

  useEffect(() => {
    setSelectedItems(defaultItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultItems.length]);

  const onInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (!unselectedItems.length) {
      return;
    }
    switch (event.key) {
      case 'ArrowDown': {
        event.preventDefault();
        const newIndex = highlightedItemIndex + 1 >= unselectedItems.length ? 0 : highlightedItemIndex + 1;
        setHighlightedItemIndex(newIndex);
        break;
      }
      case 'ArrowUp': {
        event.preventDefault();
        const newIndex = highlightedItemIndex <= 0 ? unselectedItems.length - 1 : highlightedItemIndex - 1;
        setHighlightedItemIndex(newIndex);
        break;
      }
      case 'Enter': {
        event.preventDefault();
        if (unselectedItems[highlightedItemIndex]) {
          onSelect(unselectedItems[highlightedItemIndex]);
        }
        break;
      }
    }
  };

  const onItemHover = (item: SelectInputOption) => {
    const newIndex = unselectedItems.indexOf(item);
    setHighlightedItemIndex(Math.max(newIndex, 0));
  };

  const onClear = () => {
    setValue('');
    textInputRef.current?.focus();
  };

  return (
    <StyledContainer data-test-id={dataTestId} ref={containerRef} className={className}>
      {suggestionOptions && (
        <ExpandableContent isActive={suggestionOptions.length !== 0}>
          <StyledTagContainer hasTags={suggestionOptions.length !== 0}>
            {suggestionOptions.map((item) => (
              <StyledTag
                key={item.value}
                dataTestId={`${dataTestId || ''}-suggestion-tag`}
                disabled={disabled}
                onClick={!disabled ? addSuggestionHandler(item) : undefined}
              >
                {item.label}
                {!disabled && <StyledAddIcon />}
              </StyledTag>
            ))}
          </StyledTagContainer>
        </ExpandableContent>
      )}
      <ExpandableContent isActive={selectedItems.length !== 0}>
        <StyledTagContainer hasTags={selectedItems.length !== 0}>
          {selectedItems.map((item) => (
            <StyledTag key={item.value} isActive={!disabled}>
              <StyledTagLabel>{item.label}</StyledTagLabel>
              {!disabled && <StyledCrossIcon onClick={removeItem(item)} />}
            </StyledTag>
          ))}
        </StyledTagContainer>
      </ExpandableContent>
      <UIInput
        clear={onClear}
        disabled={disabled}
        isPending={isPending}
        name="select"
        onBlur={onBlur}
        onChange={onValueChange}
        onFocus={onFocus}
        onKeyDown={onInputKeyDown}
        placeholder={label}
        ref={textInputRef}
        value={value}
      />
      {isActive && (
        <SelectInputDropdown
          highlightedItem={unselectedItems[highlightedItemIndex]}
          placeholder={placeholder}
          value={value}
          items={unselectedItems}
          isPending={isPending}
          onSelect={onSelect}
          onHighlight={onItemHover}
        />
      )}
    </StyledContainer>
  );
};

type DropdownProps = Pick<SelectInputProps, 'placeholder' | 'isPending'> & {
  highlightedItem: SelectInputOption;
  items: SelectInputOption[];
  onHighlight: (item: SelectInputOption) => void;
  onSelect: (item: SelectInputOption) => void;
  value: string;
};

const SelectInputDropdown = ({
  placeholder,
  items,
  value,
  isPending,
  onSelect,
  highlightedItem,
  onHighlight,
}: DropdownProps) => {
  const { textDictionary } = useTranslations();

  if (items.length === 0 || value === '') {
    // Render placeholder
    const searchingPlaceholder = textDictionary[isPending ? 'app.search.searching' : 'app.search.results.no_results'];
    return (
      <StyledDropdown>
        <StyledPlaceholder>{value === '' ? placeholder : searchingPlaceholder}</StyledPlaceholder>
      </StyledDropdown>
    );
  }

  const onClick = (item: SelectInputOption) => (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    onSelect(item);
  };

  const onMouseEnter = (item: SelectInputOption) => () => {
    onHighlight(item);
  };

  return (
    <StyledDropdown>
      {items.map((item) => (
        <StyledDropdownItem
          isHighlighted={highlightedItem === item}
          key={item.value}
          onMouseDown={onClick(item)}
          onMouseEnter={onMouseEnter(item)}
        >
          <div>
            {item.label}
            {item.subtitle && <StyledDropdownItemSubtitle>{item.subtitle}</StyledDropdownItemSubtitle>}
            {item.description && <StyledDropdownItemDescription>{item.description}</StyledDropdownItemDescription>}
          </div>
          {item.images?.x_small && <StyledDropdownItemImage src={item.images?.x_small} alt={item.label} />}
        </StyledDropdownItem>
      ))}
    </StyledDropdown>
  );
};
