import {
  getTransformationMapSelectedId,
  getTransformationMapSelectedOuterNode,
} from 'domains/transformationMap/selectors';
import { AdvancedTopicKnowledgeSearchCriteria } from 'domains/types';
import { RootState } from 'types';

export const getKnowledgeById = (id?: string) => (state: RootState) =>
  id ? state.entities.knowledge.byId[id] : undefined;

export const getAdvancedKnowledgeCriteria = (state: RootState): AdvancedTopicKnowledgeSearchCriteria => {
  const id = getTransformationMapSelectedId(state);
  const secondaryTopic = getTransformationMapSelectedOuterNode(state);

  return {
    id,
    secondaryTopicId: secondaryTopic?.id,
  };
};
