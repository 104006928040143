import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { Spinner, UISwitch } from 'common/components';
import { useTranslations } from 'domains/language/useTranslations';
import { ModalHeader } from 'domains/modal/components/ModalHeader';
import { getIsLoadingProfile } from 'domains/user/selectors';
import { updateProfile } from 'entities/user/actions';
import { getUserData } from 'entities/user/selectors';

const StyledMessage = styled.p`
  margin: 0 0 1rem 0;
  color: ${({ theme }) => theme.colors.textBright};
  font-weight: bold;
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.textBright};
  margin-bottom: 3rem;
`;

const StyledInnerRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledToggleLabel = styled.p`
  font-size: 1rem;
  display: inline-block;
  margin: 0;
`;

const StyledLoadingIndicator = styled(Spinner)`
  width: 20px;
  height: 20px;
  margin-right: 15px;
`;

export const NotificationSettingsModal = () => {
  const { textDictionary } = useTranslations();
  const { notifications } = useSelector(getUserData);
  const isPending = useSelector(getIsLoadingProfile);
  const [isOn, setIsOn] = useState(notifications && notifications.email);
  const dispatch = useDispatch();

  const onToggleHandle = () => {
    const email = !isOn;
    setIsOn(email);
    dispatch(updateProfile({ notifications: { email } }));
  };

  return (
    <>
      <ModalHeader title={textDictionary['app.settings.title']} />
      <StyledMessage>{textDictionary['app.notification.settings.message']}</StyledMessage>
      <StyledRow>
        <StyledToggleLabel>{textDictionary['app.notifications.settings.email']}</StyledToggleLabel>
        <StyledInnerRow>
          {isPending && <StyledLoadingIndicator />}
          <UISwitch isOn={isOn} onClick={onToggleHandle} isDisabled={isPending} />
        </StyledInnerRow>
      </StyledRow>
    </>
  );
};
