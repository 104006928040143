import { MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { StarIcon } from 'common/components';
import { useTranslations } from 'domains/language/useTranslations';
import { getShouldShowBecomeMemberMarketing, getUserRole } from 'entities/user/selectors';
import { getLinkToMembershipPage } from 'entities/user/utils';

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledStarIcon = styled(StarIcon)`
  height: 16px;
  margin-left: 1rem;
`;

export const ManageMembershipButton = () => {
  const { textDictionary } = useTranslations();
  const showBecomeMember = useSelector(getShouldShowBecomeMemberMarketing);
  const userRole = useSelector(getUserRole);

  const onClick = (event: MouseEvent) => {
    event.stopPropagation();
    window.location.href = getLinkToMembershipPage(userRole);
  };

  // todo auth make anchor tag ?!
  return (
    <StyledContainer onClick={onClick}>
      {showBecomeMember ? textDictionary['app.navigation.upgrade.button'] : textDictionary['app.membership.manage']}
      <StyledStarIcon />
    </StyledContainer>
  );
};
