import { RootState } from 'types';

export const getEmergingThemesItems = (state: RootState) =>
  state.domains.emergingThemes.items.map((id) => state.domains.clusters.data[id]);

export const getEmergingThemesIsPending = (state: RootState) => state.domains.emergingThemes.isPending;

export const getEmergingThemesOffset = (state: RootState) => state.domains.emergingThemes.offset;

export const getEmergingThemesHasNextPage = (state: RootState) => state.domains.emergingThemes.hasNextPage;
