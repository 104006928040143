import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { ResponsiveImage, UIButton } from 'common/components';
import { breakpoints } from 'common/styles';
import { AboutUsMainImageSet } from 'domains/about/images/intro';
import { trackEvent } from 'domains/analytics/actions';
import { IncidentBanner } from 'domains/incidents/components/IncidentBanner';
import { UpgradeVideo } from 'domains/landing/topBanner/UpgradeVideo';
import { useTranslations } from 'domains/language/useTranslations';
import { getUserRole } from 'entities/user/selectors';
import { getLinkToMembershipPage } from 'entities/user/utils';

const StyledContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 2rem;

  @media ${breakpoints.medium} {
    height: 580px;
    margin-bottom: 0;
  }
`;

const StyledLinearGradient = styled.div`
  height: 100%;
  width: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
`;

const StyledTitle = styled.h1`
  width: 100%;
  font-size: 2.4rem;
  margin: 0;
`;

const StyledBannerContent = styled.div`
  position: relative;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding-right: 2rem;
  padding-top: 2rem;

  @media ${breakpoints.large} {
    flex: 2;
    height: 100%;
    padding: 0;
  }
`;

const StyledRow = styled.div`
  display: flex;
  max-width: 1600px;
  margin: 0 2rem;
  flex-direction: column;
  flex: 1;
  height: 100%;
  gap: 2rem;
  padding-bottom: 1rem;

  @media ${breakpoints.medium} {
    padding: 0;
  }

  @media ${breakpoints.large} {
    flex-direction: row;
    margin: 0 4rem;
  }
`;

const StyledRightContainer = styled.div`
  position: relative;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: none;

  @media ${breakpoints.large} {
    display: flex;
  }
`;

const StyledIncidentBannerWithinUpgrade = styled(IncidentBanner)`
  flex-grow: 0;
  margin: 1rem 1rem 0rem 1rem;

  @media ${breakpoints.large} {
    margin: 2rem 6rem 0rem 6rem;
  }
`;

const StyledIntroImage = styled(ResponsiveImage)`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
`;

const StyledSubTitleText = styled.p`
  font-size: 1.5rem;
  line-height: 1.25;
`;

const StyledText = styled.p`
  font-size: 0.9rem;
  line-height: 1.5;
`;

const StyledUIButton = styled(UIButton)`
  margin-top: 1rem;
`;

export const UpgradeBanner = () => {
  const dispatch = useDispatch();
  const { textDictionary } = useTranslations();
  const userRole = useSelector(getUserRole);

  const onClick = () => {
    dispatch(
      trackEvent({
        name: 'Upgrade',
        page: 'Landing',
      }),
    );
    window.location.href = getLinkToMembershipPage(userRole);
  };

  return (
    <StyledContainer>
      <StyledLinearGradient>
        <StyledRow>
          <StyledIncidentBannerWithinUpgrade />
          <StyledIntroImage srcSet={AboutUsMainImageSet} alt="" />
          <StyledBannerContent>
            <StyledTitle>Strategic Intelligence</StyledTitle>
            <StyledSubTitleText>{textDictionary['app.digital_membership.promo_text2']}</StyledSubTitleText>
            <StyledText>{textDictionary['app.digital_membership.promo_text1']}</StyledText>
            <StyledUIButton variant={'filled'} onClick={onClick} icon={'rightArrow'}>
              {textDictionary['app.join_forum_community.title_short']}
            </StyledUIButton>
          </StyledBannerContent>
          <StyledRightContainer>
            <UpgradeVideo />
          </StyledRightContainer>
        </StyledRow>
      </StyledLinearGradient>
    </StyledContainer>
  );
};
