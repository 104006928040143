import { useEffect, useRef, useState, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css, styled } from 'styled-components';

import { CrossIcon } from 'common/components';
import { BackgroundVideo } from 'common/components/BackgroundVideo';
import { breakpoints } from 'common/styles';
import { trackEvent } from 'domains/analytics/actions';
import { getCoachmarksAreShowing } from 'domains/coachmarks/selectors';
import { VideoOverlayContent } from 'domains/landing/topBanner/VideoOverlayContent';
import { getIsModalActive } from 'domains/modal/selectors';

const VIDEO_URL = 'https://www.youtube.com/embed/16vVIzxprzw';

type StyledProps = { videoIsActive: boolean };

const StyledBackgroundVideo = styled(BackgroundVideo)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  // Edge doesn't support "cover" for video,
  // so unset the height to create a similar effect
  _:-ms-lang(x),
  & {
    height: unset;
  }
`;

const StyledForegroundVideo = styled.iframe<StyledProps>`
  position: absolute;
  top: -60px;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  opacity: 0;
  transition: opacity 0.5s ease;

  ${({ videoIsActive }) =>
    videoIsActive &&
    css`
      opacity: 1;
      z-index: 99;
    `};
`;

const StyledCrossIcon = styled(CrossIcon)<StyledProps>`
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 40px;
  height: 40px;
  cursor: pointer;
  color: white;
  opacity: 0;
  z-index: 100;
  transition:
    color 0.3s,
    opacity 0.3s;

  &:hover {
    color: #1a2f59;
  }

  ${({ videoIsActive }) =>
    videoIsActive &&
    css`
      opacity: 1;
    `};
`;

type BannerProps = { firstWatchVideoClick: boolean };

const StyledCuratedBanner = styled.div<BannerProps>`
  display: flex;
  position: relative;
  box-sizing: border-box;
  color: white;
  overflow: hidden;
  padding: 4rem 1rem 2rem;

  @media ${breakpoints.medium} {
    margin-bottom: -4rem;
    padding: 8rem 3rem 2rem;
  }

  ${({ firstWatchVideoClick }) =>
    firstWatchVideoClick &&
    css`
      transition: all 0.5s ease;
    `}
`;

const StyledBannerVideoOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000);
  }
`;

const StyledVideoOverlayContent = styled(VideoOverlayContent)`
  flex: 1;
`;

type VideoBannerProps = { videoIsPaused: boolean };

export const VideoBanner = ({ videoIsPaused }: VideoBannerProps) => {
  const dispatch = useDispatch();
  const modalIsActive = useSelector(getIsModalActive);
  const coachmarksAreShowing = useSelector(getCoachmarksAreShowing);
  const [firstWatchVideoClick, setFirstWatchVideoClick] = useState(false);
  const [videoIsActive, setVideoIsActive] = useState(false);
  const _contentRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLIFrameElement>(null);

  const _pauseYoutubeVideo = () => {
    if (videoRef.current) {
      videoRef.current.contentWindow?.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
    }
  };

  const _playYoutubeVideo = () => {
    if (videoRef.current) {
      videoRef.current.contentWindow?.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
    }
  };

  const handleWatchVideoClick = (event: MouseEvent) => {
    event.preventDefault();

    dispatch(trackEvent({ name: 'Watch Video' }));
    setVideoIsActive(true);
    setFirstWatchVideoClick(true);
  };

  const _handleCloseVideoClick = (event: MouseEvent) => {
    event.preventDefault();

    _pauseYoutubeVideo();
    setVideoIsActive(false);
  };

  useEffect(() => {
    if (modalIsActive || coachmarksAreShowing) {
      if (videoIsActive) {
        _pauseYoutubeVideo();
      }
    } else {
      if (videoIsActive) {
        _playYoutubeVideo();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coachmarksAreShowing, modalIsActive]);

  return (
    <StyledCuratedBanner
      firstWatchVideoClick={firstWatchVideoClick}
      style={{
        height: videoIsActive ? window.innerHeight : 'unset',
      }}
    >
      <StyledBackgroundVideo shouldPauseVideo={modalIsActive || coachmarksAreShowing || videoIsPaused} />

      <StyledForegroundVideo
        ref={videoRef}
        videoIsActive={videoIsActive}
        src={videoIsActive ? `${VIDEO_URL}?enablejsapi=1` : undefined}
        frameBorder={1}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;"
        onLoad={_playYoutubeVideo}
      />

      <StyledCrossIcon onClick={_handleCloseVideoClick} videoIsActive={videoIsActive} />
      <StyledBannerVideoOverlay />
      <StyledVideoOverlayContent ref={_contentRef} handleWatchVideoClick={handleWatchVideoClick} />
    </StyledCuratedBanner>
  );
};
