import { SVGProps } from 'react';

export const FollowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" {...props}>
    <g stroke="currentColor" strokeWidth="2px">
      <rect x="1" y="1" width="38" height="38" rx="19" fill="none" />
    </g>
    <g fill="currentColor" strokeLinecap="round" strokeLinejoin="round">
      <path d="M6 0v-12 6h6-6v6z" stroke="none" transform="rotate(-90 26 0)" />
      <path
        d="M6 1c-.56 0-1-.44-1-1v-5H0c-.56 0-1-.44-1-1s.44-1 1-1h5v-5c0-.56.44-1 1-1s1 .44 1 1v5h5c.56 0 1 .44 1 1s-.44 1-1 1H7v5c0 .56-.44 1-1 1z"
        fill="currentColor"
        transform="rotate(-90 26 0)"
      />
    </g>
  </svg>
);
