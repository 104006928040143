import { Fragment } from 'react';
import { styled } from 'styled-components';

const StyledUIToggle = styled.div`
  background-color: ${({ theme }) => theme.colors.toggleBackground};
  color: ${({ theme }) => theme.colors.toggleText}BB;
  font-weight: bold;
  padding: 8px;
  border-radius: 27px;
  display: flex;
  justify-content: space-between;
  gap: 8px;

  input:checked + label {
    background-color: ${({ theme }) => theme.colors.toggleActiveBackground};
    color: ${({ theme }) => theme.colors.toggleActiveText};
  }
`;

const StyledUIToggleLabel = styled.label`
  flex-grow: 1;
  padding: 10px 0;
  text-align: center;
  border-radius: 20px;
  transition:
    0.3s color,
    0.3s background-color;
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.toggleText};
  }
`;

const StyledUIToggleInput = styled.input`
  display: none;
`;

type UIToggleItem = { text: string; value: string };

type UIToggleProps = {
  className?: string;
  isActive?: boolean;
  items: UIToggleItem[];
  name: string;
  onChange: () => void;
  value: string;
};

export const UIToggle = ({ className, items, name, onChange, value }: UIToggleProps) => (
  <StyledUIToggle className={className}>
    {items.map((item) => {
      const { text, value: itemValue } = item;
      return (
        <Fragment key={`${name}-${itemValue}`}>
          <StyledUIToggleInput
            type="radio"
            name={name}
            value={itemValue}
            checked={value === itemValue}
            onChange={onChange}
            id={`${name}-${itemValue}`}
          />
          <StyledUIToggleLabel htmlFor={`${name}-${itemValue}`} data-test-id={`${name}-${itemValue}`}>
            {text}
          </StyledUIToggleLabel>
        </Fragment>
      );
    })}
  </StyledUIToggle>
);
