import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from 'styled-components';

import { BellIcon, SettingsIcon, UIButton } from 'common/components';
import { useTranslations } from 'domains/language/useTranslations';
import { pushModalScreen } from 'domains/modal/actions';
import { ModalHeader } from 'domains/modal/components/ModalHeader';
import { fetchNotifications, markAllNotificationDone } from 'domains/notifications/actions';
import { NotificationItem } from 'domains/notifications/components/NotificationItem';
import {
  getNotificationList,
  getNotificationListOffset,
  getNotificationIsPending,
  getNotificationHasNextPage,
} from 'domains/notifications/selectors';

const StyledEmptyList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.colors.textBright};
`;

const StyledEmptyListIcon = styled(BellIcon)`
  width: 30px;
  height: 30px;
  margin-right: 1rem;
`;

const StyledText = styled.span`
  font-weight: bold;
`;

const StyledSettingsIcon = styled(SettingsIcon)`
  color: ${({ theme }) => theme.colors.textFade};
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: color 0.3s;
  &:hover {
    color: ${({ theme }) => theme.colors.textBright};
  }
`;

export const NotificationModal = () => {
  const skipMarking = useRef(false);
  const { textDictionary } = useTranslations();
  const items = useSelector(getNotificationList);
  const userNotificationOffset = useSelector(getNotificationListOffset);
  const isPending = useSelector(getNotificationIsPending);
  const hasNextPage = useSelector(getNotificationHasNextPage);
  const dispatch = useDispatch();

  const loadMore = () => {
    dispatch(
      fetchNotifications({
        offset: userNotificationOffset + 1,
      }),
    );
  };

  useEffect(() => {
    dispatch(fetchNotifications({ offset: 0 }));
    return () => {
      // Prevent marking when switching to the detail notification modal
      if (!skipMarking.current) {
        dispatch(markAllNotificationDone());
      }
      skipMarking.current = false;
    };
  }, [dispatch]);

  const settingsOnClickHandle = () => {
    dispatch(
      pushModalScreen({
        id: 'Notifications Settings',
        // addToHistory: MODAL_NAME,
      }),
    );
  };

  const notificationOnClickHandle = (id: string) => () => {
    skipMarking.current = true;
    dispatch(
      pushModalScreen({
        id: 'Notifications Detail',
        params: { id },
      }),
    );
  };

  return (
    <>
      <ModalHeader
        title={textDictionary['app.notifications.title']}
        rightElement={<StyledSettingsIcon onClick={settingsOnClickHandle} />}
      />
      <>
        {items.length === 0 ? (
          <StyledEmptyList>
            <StyledEmptyListIcon />
            <StyledText>{textDictionary['app.notifications.empty_message']}</StyledText>
          </StyledEmptyList>
        ) : (
          <>
            {items.map((notification) => (
              <NotificationItem key={notification.id} notification={notification} onClick={notificationOnClickHandle} />
            ))}
          </>
        )}
        {hasNextPage && (
          <UIButton onClick={loadMore} variant="outlined" fullwidth isLoading={isPending}>
            {textDictionary['app.action.load_more']}
          </UIButton>
        )}
      </>
    </>
  );
};
