import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { StyledGrid, SectionTitleUnderlined } from 'common/components';
import { trackEvent } from 'domains/analytics/actions';
import { useTranslations } from 'domains/language/useTranslations';
import { getCurrentSearchTerm, getTopicSearchResults, getTopicSearchIsPending } from 'domains/search/selectors';
import { TopicTile } from 'domains/topic/components/topicTile/TopicTile';
import { Topic } from 'entities/topics/types';

const StyledContainer = styled.div`
  padding-bottom: 2rem;
`;

export const TopicResults = () => {
  const dispatch = useDispatch();
  const { textDictionary } = useTranslations();
  const topics = useSelector(getTopicSearchResults) || [];
  const isPending = useSelector(getTopicSearchIsPending);
  const currentSearchTerm = useSelector(getCurrentSearchTerm);

  const title = !currentSearchTerm ? 'app.search.results.title' : 'app.discover.topics.title';

  if (topics.length === 0) {
    return null;
  }

  const onClick = (topic: Topic) => () =>
    dispatch(
      trackEvent({
        name: 'Browse page',
        page: 'Search',
        subpage: 'Topic detail',
        entityId: topic.id,
        entityName: topic.name,
        entityType: 'Topic',
      }),
    );

  return (
    <StyledContainer>
      <SectionTitleUnderlined isLoading={isPending} id="topics">
        {textDictionary[title]}
      </SectionTitleUnderlined>
      <StyledGrid numberOfItems={[2, 3, 4, 4, 5]}>
        {topics.map((topic) => (
          <TopicTile key={topic.id} onClick={onClick(topic)} {...topic} />
        ))}
      </StyledGrid>
    </StyledContainer>
  );
};
