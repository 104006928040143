import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { addKeyIssueToMapFailure, addKeyIssueToMapSuccess } from 'domains/createMapDetail/actions';
import { AddKeyIssueToMapDefaultAction } from 'domains/createMapDetail/actionTypes';
import { AddKeyIssuePayload } from 'domains/createMapDetail/types';

export function* addKeyIssueSaga(action: AddKeyIssueToMapDefaultAction) {
  try {
    const map: AddKeyIssuePayload = yield apiFetch(`topic/${action.meta.keyIssueId}`);
    yield put(addKeyIssueToMapSuccess(map, action.meta));
  } catch (error) {
    yield put(addKeyIssueToMapFailure(action.meta));
  }
}
