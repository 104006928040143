import { useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { SectionTitleUnderlined } from 'common/components';
import { useTranslations } from 'domains/language/useTranslations';
import { getSearchIsPending, getSearchTerm } from 'domains/search/selectors';

const StyledEmptyText = styled.p`
  color: ${({ theme }) => theme.colors.fillPrimary};
`;

export const EmptySearchResult = () => {
  const { textDictionary } = useTranslations();
  const isPending = useSelector(getSearchIsPending);
  const query = useSelector(getSearchTerm);

  let emptyText = 'app.search.placeholder';
  if (query) {
    emptyText = isPending ? 'app.search.searching' : 'app.search.results.no_results';
  }

  return (
    <>
      <SectionTitleUnderlined>{textDictionary['app.search.results.title']}</SectionTitleUnderlined>
      <StyledEmptyText>{textDictionary[emptyText]}</StyledEmptyText>
    </>
  );
};
