import { styled } from 'styled-components';

import { breakpoints } from 'common/styles';

export const HeaderTitle = styled.h2`
  display: block;
  margin: 0;
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.textBright};

  @media ${breakpoints.extraLarge} {
    display: none;
  }
`;
