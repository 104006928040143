import { useDispatch, useSelector } from 'react-redux';
import { css, CSSProperties, styled } from 'styled-components';

import { UIButton } from 'common/components';
import { breakpoints } from 'common/styles';
import { trackEvent } from 'domains/analytics/actions';
import { getIsAuthenticated } from 'domains/authentication/selectors';
import { StyledLandingButton } from 'domains/landing/components/StyledLandingButton';
import { FooterListItem } from 'domains/landing/footer/FooterListItem';
import { useTranslations } from 'domains/language/useTranslations';
import {
  getShouldShowBecomeMemberMarketing,
  getShouldShowBecomeProMemberMarketing,
  getUserRole,
} from 'entities/user/selectors';
import { getLinkToMembershipPage } from 'entities/user/utils';
import { useAuth } from 'useAuth';

const StyledContainer = styled.div`
  margin-top: 6rem;
  padding-top: 4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  color: ${({ theme }) => theme.colors.fillPrimary};
  padding-bottom: 4rem;
`;

const StyledInnerContainer = styled.div`
  max-width: 1600px;
  display: flex;
  flex-direction: column-reverse;
  margin: 0 2rem;

  @media ${breakpoints.large} {
    margin: 0 4rem;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const StyledColumn = styled.div<{ alignment?: CSSProperties['textAlign'] }>`
  margin-right: 4rem;
  max-width: 400px;
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;

  &:last-child {
    margin-right: 0;
  }

  @media ${breakpoints.large} {
    margin-bottom: 0;

    ${({ alignment }) =>
      alignment === 'right' &&
      css`
        margin-right: 0;
        margin-left: 4rem;
      `}

    ${({ alignment }) =>
      alignment === 'center' &&
      css`
        margin-right: 0;
      `}
  }
`;

const StyledTitle = styled.h3`
  text-transform: uppercase;
  font-size: 1.1rem;
  margin-top: 0;
`;

const StyledList = styled.div`
  padding: 0;
`;

const StyledMainTitle = styled.h1`
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
  font-weight: normal;
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const StyledUpgradeButton = styled(StyledLandingButton)`
  max-width: 250px;
`;

const links = [
  { localisedKey: 'app.navigation.about_link', to: '/about' },
  {
    localisedKey: 'app.settings.feedback',
    href: 'mailto:intelligence@weforum.org',
  },
  { localisedKey: 'app.settings.terms_of_use', to: '/terms-and-conditions' },
  { localisedKey: 'app.registration.privacy_policy', to: '/privacy-policy' },
  { localisedKey: 'app.settings.cookie_notice', to: '/cookie-notice' },
  {
    localisedKey: 'Embed maps on your site',
    href: 'https://widget.intelligence.weforum.org/',
  },
];

const socialMedia = [
  { label: 'Twitter', href: 'https://twitter.com/WEF_Intel' },
  { label: 'Instagram', href: 'https://www.instagram.com/worldeconomicforum/' },
  {
    label: 'LinkedIn',
    href: 'https://www.linkedin.com/company/world-economic-forum/',
  },
];

export const Footer = () => {
  const dispatch = useDispatch();
  const showMemberUpgrade = useSelector(getShouldShowBecomeMemberMarketing);
  const showProMemberUpgrade = useSelector(getShouldShowBecomeProMemberMarketing);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const { textDictionary } = useTranslations();
  const { triggerLogin } = useAuth();
  const userRole = useSelector(getUserRole);

  const onDigitalMembersClick = () => {
    dispatch(
      trackEvent({
        name: showMemberUpgrade ? 'Upgrade' : 'Upgrade - Pro',
        page: 'Footer',
      }),
    );
    window.location.href = getLinkToMembershipPage(userRole);
  };

  const onLoginClick = () => triggerLogin();

  const showUpgradeColumn = !isAuthenticated || showMemberUpgrade || showProMemberUpgrade;

  return (
    <StyledContainer>
      <StyledInnerContainer>
        <StyledColumn>
          <StyledTitle>Strategic Intelligence</StyledTitle>
          <StyledList>
            {links.map((item, index) => (
              <FooterListItem key={index} label={textDictionary[item.localisedKey]} href={item.href} to={item.to} />
            ))}
          </StyledList>
        </StyledColumn>
        <StyledColumn alignment={showUpgradeColumn ? 'center' : 'right'}>
          <StyledTitle>{textDictionary['app.social_media.follow_us']}</StyledTitle>
          <StyledList>
            {socialMedia.map((item, index) => (
              <FooterListItem key={index} {...item} />
            ))}
          </StyledList>
        </StyledColumn>
        {showUpgradeColumn ? (
          <StyledColumn alignment="right">
            <StyledMainTitle>{textDictionary['app.upgrade_banner.title']}</StyledMainTitle>
            {isAuthenticated && (
              <StyledUpgradeButton onClick={onDigitalMembersClick}>
                {
                  textDictionary[
                    showMemberUpgrade ? 'app.join_forum_community.title_short' : 'app.join_forum_community.pro'
                  ]
                }
              </StyledUpgradeButton>
            )}
            {!isAuthenticated && (
              <StyledButtonsContainer>
                <UIButton variant={'outlined'} onClick={onLoginClick} icon={'rightArrow'}>
                  {textDictionary['app.user.sign_in']}
                </UIButton>
                <UIButton variant={'filled'} onClick={onLoginClick} icon={'rightArrow'}>
                  {textDictionary['app.registration.join_us']}
                </UIButton>
              </StyledButtonsContainer>
            )}
          </StyledColumn>
        ) : (
          <div style={{ width: '33%' }}></div>
        )}
      </StyledInnerContainer>
    </StyledContainer>
  );
};
