import { styled } from 'styled-components';

import VideoThumbnail from 'assets/images/PromoVideoThumbnail.jpg';
import { ImageBackground } from 'common/components';
import { PlayIconCircled } from 'common/components/icons';

const StyledContainer = styled.a`
  text-decoration: none;
  height: 170px;
  display: block;
`;

const StyledPlayIcon = styled(PlayIconCircled)`
  transition: transform 0.3s;
  width: 45px;
  height: 45px;
  color: white;
  position: absolute;
  left: 1rem;
  bottom: 1rem;
`;

const StyledVideoPreview = styled(ImageBackground)`
  width: 100%;
  height: 100%;
  border-radius: 10px;
`;

const StyledVideoLengthText = styled.span`
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 7px 8px;
  border-radius: 5px;
  position: absolute;
  right: 1rem;
  bottom: 1rem;
`;

const StyledOverlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 10px;

  &:hover ${StyledPlayIcon} {
    transform: scale(1.1);
  }
`;

type MembershipPromoVideoProps = { className?: string };

export const MembershipPromoVideo = ({ className }: MembershipPromoVideoProps) => (
  <StyledContainer href="https://www.youtube.com/embed/ww5WcMWOLFI" target="_blank" className={className}>
    <StyledVideoPreview src={VideoThumbnail}>
      <StyledOverlay>
        <StyledPlayIcon />
        <StyledVideoLengthText>2:29</StyledVideoLengthText>
      </StyledOverlay>
    </StyledVideoPreview>
  </StyledContainer>
);
