import { PropsWithChildren, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { styled } from 'styled-components';

import { ChevronIcon, ExpandableContent } from 'common/components';
import { breakpoints, createScrollbarStyle } from 'common/styles';
import { Tab } from 'domains/termsAndConditions/types';

const StyledContent = styled.div`
  height: calc(100% - 9rem);
  overflow: auto;

  ${createScrollbarStyle()};
`;

const StyledTitle = styled.div<{ isActive: boolean }>`
  display: flex;
  justify-content: space-between;
  border-radius: 20px;
  transition: color 0.3s;
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  color: ${({ isActive, theme }) => (isActive ? theme.colors.textBright : theme.colors.textFade)};
  margin: 0;
  border-bottom: none;
  padding: 10px 20px;
  &:hover {
    color: ${({ theme }) => theme.colors.textBright};
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
`;

type IconProps = { $isActive: boolean; $isOpen: boolean };

const StyledChevron = styled(ChevronIcon)<IconProps>`
  transition: transform 0.3s;
  width: 16px;
  height: 16px;
  transform: ${({ $isActive, $isOpen }) => `rotate(${$isActive ? ($isOpen ? -90 : 90) : 0}deg)`};
`;

const StyledSectionsDropdownItem = styled.a`
  padding-left: 20px;
  display: block;
  color: ${({ theme }) => theme.colors.textFade};
  font-size: 0.8rem;
  line-height: 1.2rem;
  text-decoration: none;
  margin-top: 1.5rem;

  &:hover {
    color: ${({ theme }) => theme.colors.textBright};
  }
  @media ${breakpoints.medium} {
    font-size: 0.9rem;
  }
`;

const StyledItemContainer = styled.div`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const StyledSectionsDropdownContent = styled.div`
  height: calc(100% - 9rem);
  overflow: auto;
`;

const StyledExpandItem = styled.div`
  cursor: pointer;
`;

type StyledLinkContainerProps = PropsWithChildren & { isActive: boolean; onClick: () => void; to: string };

const StyledLinkContainer = ({ isActive, onClick, to, ...props }: StyledLinkContainerProps) => {
  if (!isActive) {
    return <StyledLink to={to} {...props} />;
  }
  return <StyledExpandItem onClick={onClick} {...props} />;
};

type TableOfContentsProps = { basePath?: string; tabs: Tab[] };

export const TableOfContents = ({ tabs, basePath }: TableOfContentsProps) => {
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const onClick = () => setIsOpen(!isOpen);

  return (
    <StyledContent>
      {tabs.map((item) => {
        const isActive = pathname.includes(item.id);
        return (
          <StyledItemContainer key={item.id}>
            <StyledLinkContainer isActive={isActive} to={`${basePath || ''}/${item.id}`} onClick={onClick}>
              <StyledTitle isActive={isActive}>
                {item.label}
                <StyledChevron $isActive={isActive} $isOpen={isOpen} />
              </StyledTitle>
            </StyledLinkContainer>
            {isActive && (
              <ExpandableContent isActive={isOpen}>
                <StyledSectionsDropdownContent>
                  {item.sections.map((section) => (
                    <StyledSectionsDropdownItem key={section.id} href={`#${section.id}`}>
                      {section.title}
                    </StyledSectionsDropdownItem>
                  ))}
                </StyledSectionsDropdownContent>
              </ExpandableContent>
            )}
          </StyledItemContainer>
        );
      })}
    </StyledContent>
  );
};
