import { put } from 'redux-saga/effects';

import { TOPICS_FOR_COLLECTION_LIMIT } from 'common/constants/limit';
import { apiFetch } from 'common/utilities';
import { fetchCollectionForViewingFailure, fetchCollectionForViewingSuccess } from 'domains/collection/actions';
import { FetchCollectionForViewingAction } from 'domains/collection/actionTypes';
import { CollectionViewResponse } from 'domains/collection/types/collectionResponses';
import { fetchTopicsForCollection } from 'entities/topics/actions';

export function* fetchCollectionForViewingSaga({ id }: FetchCollectionForViewingAction) {
  try {
    yield put(fetchTopicsForCollection({ id, limit: TOPICS_FOR_COLLECTION_LIMIT, offset: 0 }));

    const response: CollectionViewResponse = yield apiFetch(`collection/${id}`);
    yield put(fetchCollectionForViewingSuccess(response));
  } catch (error) {
    yield put(fetchCollectionForViewingFailure(id));
    console.error(`[${fetchCollectionForViewingSaga.name}] Failed to fetch collection with ID '${id}': ${error}`);
  }
}
