import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { fetchLanguagesSuccess, fetchLanguagesFailure } from 'entities/languages/actions';
import { LanguageRequestPayload } from 'entities/languages/types';

export function* fetchLanguagesSaga() {
  try {
    const payload: LanguageRequestPayload = yield apiFetch('language');
    yield put(
      fetchLanguagesSuccess({
        languages: payload.languages.map(({ code, native_name, choose_label }) => ({
          id: code,
          label: native_name,
          chooseLabel: choose_label,
        })),
        defaultLanguage: payload.default_language,
      }),
    );
  } catch {
    yield put(fetchLanguagesFailure());
  }
}
