import {
  TopicKnowledgeDataActionTypes,
  TopicKnowledgeDataApplySearchCriteriaAction,
  TopicKnowledgeDataClearSearchCriteriaAction,
} from 'domains/knowledgeData/actionTypes';

export const applyKnowledgeDataSearchCriteria = (
  payload?: TopicKnowledgeDataApplySearchCriteriaAction['payload'],
): TopicKnowledgeDataApplySearchCriteriaAction => ({
  type: TopicKnowledgeDataActionTypes.APPLY_SEARCH_CRITERIA,
  payload,
});

export const clearKnowledgeDataSearchCriteria = (): TopicKnowledgeDataClearSearchCriteriaAction => ({
  type: TopicKnowledgeDataActionTypes.CLEAR_SEARCH_CRITERIA,
});
