import { css, styled } from 'styled-components';

import { ResponsiveImage, Link, TwitterIcon, LinkedInIcon, AppStoreiOSIcon } from 'common/components';
import { breakpoints } from 'common/styles';
import { SIAppLogoImageSet, GooglePlayStoreLogoImageSet, HuaweiStoreLogoImageSet } from 'domains/about/images/footer';
import { Section } from 'domains/about/sections/Section';
import { SectionPreTitle } from 'domains/about/sections/SectionPreTitle';
import { SectionText } from 'domains/about/sections/SectionText';
import { SectionTitle } from 'domains/about/sections/SectionTitle';
import { SectionTitleBlock } from 'domains/about/sections/SectionTitleBlock';
import { useTranslations } from 'domains/language/useTranslations';

const StyledSection = styled(Section)`
  text-align: center;
  align-items: center;
  justify-content: center;
`;

const StyledSectionText = styled(SectionText)`
  color: ${({ theme }) => theme.colors.textFade};
  margin-bottom: 3rem;
`;

const StyledSectionTitleBlock = styled(SectionTitleBlock)`
  margin-bottom: 1rem;
`;

const StyledContent = styled.div`
  width: 100%;
  max-width: 600px;
`;

const SIAppLogoImage = styled(ResponsiveImage)`
  width: 10rem;
  border-radius: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 0 0.5rem 0.1rem #3b4353;
`;

const StyledLogos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;

  @media ${breakpoints.medium} {
    flex-direction: row;
  }
`;

const StyledLogo = styled.a`
  width: 60%;
  width: auto;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  @media ${breakpoints.medium} {
    &:not(:last-child) {
      margin-right: 2rem;
      margin-bottom: 0;
    }
  }
`;

const GooglePlayStoreLogoImage = styled(ResponsiveImage)`
  width: auto;
  height: 3rem;
`;

const StyledSocialText = styled(SectionText)`
  font-size: 20px;
  margin-top: 2rem;
`;

const iconStyle = css`
  color: ${({ theme }) => theme.colors.textBright};
  width: 27px;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.blue};
  &:hover {
    color: ${({ theme }) => theme.colors.blueDark};
  }
`;

const StyledTwitterIcon = styled(TwitterIcon)`
  ${iconStyle};
`;

const StyledLinkedInIcon = styled(LinkedInIcon)`
  ${iconStyle};
`;

const StyledAppStoreIcon = styled(AppStoreiOSIcon)`
  height: 3rem;
`;

type FooterProps = { className?: string };

export const Footer = ({ className }: FooterProps) => {
  const { interpolate, textDictionary } = useTranslations();

  return (
    <StyledSection className={className}>
      <StyledContent>
        <SIAppLogoImage srcSet={SIAppLogoImageSet} alt="App store" />
        <SectionPreTitle>{textDictionary['app.about.footer.title']}</SectionPreTitle>
        <StyledSectionTitleBlock>
          <SectionTitle style={{ fontSize: '2rem' }}>{textDictionary['app.about.footer.sub_title']}</SectionTitle>
          <StyledSectionText>{textDictionary['app.about.footer.text']}</StyledSectionText>
        </StyledSectionTitleBlock>
        <StyledLogos>
          <StyledLogo href="https://apps.apple.com/gb/app/strategic-iq/id1454211238" target="_blank">
            <StyledAppStoreIcon />
          </StyledLogo>
          <StyledLogo
            href="https://play.google.com/store/apps/details?id=org.weforum.intelligence&hl=en_GB"
            target="_blank"
          >
            <GooglePlayStoreLogoImage srcSet={GooglePlayStoreLogoImageSet} alt="Google Play Store" />
          </StyledLogo>
          <StyledLogo href="https://appgallery.cloud.huawei.com/marketshare/app/C100941449" target="_blank">
            <ResponsiveImage srcSet={HuaweiStoreLogoImageSet} alt="Huawei store" />
          </StyledLogo>
        </StyledLogos>
        <SectionText>
          {interpolate(textDictionary['app.about.footer.support_text'], {
            emailLink: (emailLink: string) => (
              <StyledLink href="mailto:intelligence@weforum.org">{emailLink}</StyledLink>
            ),
          })}
        </SectionText>
        <StyledSocialText>
          <Link href="https://twitter.com/WEF_Intel" target="_blank" rel="noopener">
            <StyledTwitterIcon />
          </Link>
          <Link href="https://www.linkedin.com/company/world-economic-forum/" target="_blank" rel="noopener">
            <StyledLinkedInIcon />
          </Link>
        </StyledSocialText>
      </StyledContent>
    </StyledSection>
  );
};
