import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

import { ChevronIcon, linkStyles } from 'common/components';
import { TOPICS_FOR_COLLECTION_LIMIT } from 'common/constants/limit';
import { Status } from 'common/types/status';
import { CollectionViewModel } from 'domains/collection/types';
import { TopicSection } from 'domains/discover/components/TopicSection';
import { getSiteLanguage } from 'domains/language/selectors';
import { fetchTopicsForCollection } from 'entities/topics/actions';
import { getTopics } from 'entities/topics/selectors';

const StyledLink = styled(Link)`
  ${linkStyles}
`;

const StyledArrow = styled(ChevronIcon)`
  width: 16px;
  height: 16px;
  color: ${({ theme }) => theme.colors.textBright};
  padding-left: 0.5rem;
`;

type FeaturedCollectionProps = { collection: CollectionViewModel };

export const FeaturedCollection = ({ collection }: FeaturedCollectionProps) => {
  const { id, name, topics, topic_ids } = collection;
  const dispatch = useDispatch();
  const collectionTopics = useSelector(getTopics(topic_ids, TOPICS_FOR_COLLECTION_LIMIT));
  const siteLanguage = useSelector(getSiteLanguage);

  useEffect(() => {
    dispatch(fetchTopicsForCollection({ id, offset: 0, limit: TOPICS_FOR_COLLECTION_LIMIT }));
  }, [dispatch, id, siteLanguage]);

  const title = (
    <StyledLink to={`collection/${id}`}>
      {name}
      <StyledArrow />
    </StyledLink>
  );

  return <TopicSection title={title} topics={collectionTopics} isPending={topics.status === Status.Loading} />;
};
