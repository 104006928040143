import {
  TopicVideoActionTypes,
  TopicVideoApplySearchCriteriaAction,
  TopicVideoClearSearchCriteriaAction,
} from 'domains/videos/actionTypes';

export const applyVideoSearchCriteria = (
  payload?: TopicVideoApplySearchCriteriaAction['payload'],
): TopicVideoApplySearchCriteriaAction => ({
  type: TopicVideoActionTypes.APPLY_SEARCH_CRITERIA,
  payload,
});

export const clearVideoSearchCriteria = (): TopicVideoClearSearchCriteriaAction => ({
  type: TopicVideoActionTypes.CLEAR_SEARCH_CRITERIA,
});
