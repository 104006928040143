import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { CarouselSlider } from 'common/components';
import { breakpoints } from 'common/styles';
import { ClusterCard } from 'domains/clusters/components/ClusterCard';
import { CLUSTER_LIMIT } from 'domains/clusters/components/ClusterList';
import { PlaceholderClusterCard } from 'domains/clusters/components/PlaceholderClusterCard';
import { useTranslations } from 'domains/language/useTranslations';
import { activateModal } from 'domains/modal/actions';
import { getEmergingThemesIsPending, getEmergingThemesItems } from 'domains/monitor/emergingThemes/selectors';
import { getTopicsFilterSelectedIds } from 'domains/monitor/filter/selectors';
import { useMedia } from 'domains/viewport/useMedia';
import { getCanAccessMonitor } from 'entities/user/selectors';

const StyledContainer = styled.div`
  display: flex;
  margin: 1rem;
  height: 350px;

  @media ${breakpoints.medium} {
    display: none;
  }
`;

const StyledCarousel = styled(StyledContainer)`
  height: initial;
  margin-bottom: 2rem;
`;

const StyledButton = styled.div`
  background-color: ${({ theme }) => theme.colors.blue};
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  margin-top: 1rem;
`;

export const EmergingThemeCarousel = () => {
  const dispatch = useDispatch();
  const hasAccessToMonitor = useSelector(getCanAccessMonitor);
  const items = useSelector(getEmergingThemesItems);
  const isPending = useSelector(getEmergingThemesIsPending);
  const { textDictionary } = useTranslations();
  const selectedTopics = useSelector(getTopicsFilterSelectedIds);
  const length = useMedia(
    {
      small: 1,
      medium: 2,
      large: 3,
      extraLarge: 4,
      enormous: 4,
    },
    1,
  );

  if (items.length === 0 && !isPending) {
    const message =
      textDictionary[
        selectedTopics.length === 0 ? 'app.monitor.empty_message.emerging_themes' : 'app.monitor.empty_message_filter'
      ];
    const onClick = () =>
      dispatch(
        activateModal({
          id: 'Manage topics',
        }),
      );

    return (
      <StyledContainer>
        <PlaceholderClusterCard
          message={message}
          onClick={onClick}
          icon={<StyledButton>{textDictionary['app.discover.manage_topics']}</StyledButton>}
        />
      </StyledContainer>
    );
  }

  return (
    <StyledCarousel>
      <CarouselSlider numberOfItems={length}>
        {items.map((item, index) => (
          <ClusterCard
            key={item.id}
            toLink={`/monitor/emerging-themes/${item.id}`}
            isLocked={!hasAccessToMonitor && index >= CLUSTER_LIMIT}
            cluster={item}
            trackPage="Monitor"
          />
        ))}
      </CarouselSlider>
    </StyledCarousel>
  );
};
