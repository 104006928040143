import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { followCollectionSuccess, followCollectionFailure } from 'domains/collection/actions';
import { FollowCollectionAction } from 'domains/collection/actionTypes';

export function* followCollectionSaga({ payload }: FollowCollectionAction) {
  try {
    yield apiFetch(`collection/${payload.id}/follow`, { method: 'PUT' });
    yield put(followCollectionSuccess(payload.id));
  } catch (error) {
    yield put(followCollectionFailure(payload.id));
    console.error(`[followCollectionSaga] Failed to fetch follow collection with id '${payload.id}': ${error}`);
  }
}
