import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { getModalParams } from 'domains/modal/selectors';

const StyledAuthWidgetWrapper = styled.div`
  display: block !important;
  position: relative;
  padding-top: 6rem;
  height: 100%;

  .aw-back-button {
    top: 86px;
    left: -12px;
  }

  meter {
    border-width: 0;
  }
`;

const INIT_AUTH_WIDGET_TIMEOUT = 300;

export const AuthenticationModal = () => {
  const params = useSelector(getModalParams);

  useEffect(() => {
    setTimeout(() => window.AuthWidget.render(), INIT_AUTH_WIDGET_TIMEOUT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledAuthWidgetWrapper data-test-id={'authentication-modal'} id={params.authWidgetId}></StyledAuthWidgetWrapper>
  );
};
