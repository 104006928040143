import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { fetchFilteredTopicsFailure, fetchFilteredTopicsSuccess } from 'domains/filteredTopicList/actions';
import { FetchFilteredTopicsAction } from 'domains/filteredTopicList/actionTypes';
import { TopicSearchResult } from 'domains/search/types';
import { DestinationTypes } from 'domains/types';
import { fetchTopicsSuccess } from 'entities/topics/actions';
import { Topic } from 'entities/topics/types';

export function* fetchFilteredTopicsSaga(action: FetchFilteredTopicsAction) {
  const { limit, offset, filterTerm } = action.meta;

  try {
    let topics: Topic[] = [];
    if (filterTerm === '') {
      topics = yield apiFetch('topic/curated', {
        queryParams: {
          types: DestinationTypes.ALL,
          offset,
          limit,
          can_edit: 'false',
        },
      });
    } else {
      const response: {
        result: TopicSearchResult[];
      } = yield apiFetch(`topic/search/${encodeURIComponent(filterTerm!)}`, {
        queryParams: { limit, offset },
      });
      topics = response.result;
    }

    const topicIds = topics.map((topic) => topic.id);
    yield put(fetchFilteredTopicsSuccess({ topicIds, offset, limit, filterTerm }));
    yield put(
      fetchTopicsSuccess({
        type: DestinationTypes.ALL,
        topics,
        limit,
        offset,
      }),
    );
  } catch (e) {
    yield put(fetchFilteredTopicsFailure());
  }
}
