import { SVGProps } from 'react';

export const FilterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <g fill="currentColor">
      <path d="M30.087 22.142a5.581 5.581 0 00-.288 1.777c0 .622.101 1.22.288 1.777H0v-3.554h30.087zm10.537 0H48v3.554h-7.376a5.58 5.58 0 00.289-1.777c0-.621-.102-1.219-.289-1.777z" />
      <path d="M35.356 30.704c-3.737 0-6.765-3.038-6.765-6.785 0-3.747 3.028-6.784 6.765-6.784 3.736 0 6.765 3.037 6.765 6.784s-3.029 6.785-6.765 6.785zm0-2.423a4.355 4.355 0 004.349-4.362 4.355 4.355 0 00-4.35-4.361 4.355 4.355 0 00-4.348 4.361 4.355 4.355 0 004.349 4.362zM7.698 36.358a5.581 5.581 0 00-.289 1.777c0 .62.102 1.218.289 1.776H0v-3.553h7.698zm10.537 0H48v3.553H18.235a5.583 5.583 0 00.288-1.776c0-.622-.1-1.22-.288-1.777z" />
      <path d="M12.966 44.92c-3.736 0-6.765-3.038-6.765-6.785 0-3.747 3.03-6.785 6.765-6.785 3.737 0 6.766 3.038 6.766 6.785 0 3.747-3.03 6.784-6.766 6.784zm0-2.424a4.355 4.355 0 004.35-4.361 4.355 4.355 0 00-4.35-4.362 4.355 4.355 0 00-4.349 4.362 4.355 4.355 0 004.35 4.361zM6.414 8.088C7.165 5.162 9.814 3 12.966 3c3.153 0 5.801 2.162 6.552 5.088H48v3.554H19.475c-.806 2.844-3.415 4.927-6.509 4.927-3.094 0-5.702-2.083-6.508-4.927H0V8.088h6.414zm6.472 6.058a4.355 4.355 0 004.349-4.361 4.355 4.355 0 00-4.35-4.362 4.355 4.355 0 00-4.348 4.362 4.355 4.355 0 004.349 4.361z" />
    </g>
  </svg>
);
