import { SVGProps } from 'react';

export const SourceIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      d="M48 24A23.999 23.999 0 0014.34 2.01a24 24 0 00-6.55 39.67l-.022.02.778.657c.05.043.106.077.156.12.414.342.842.668 1.278.982.142.102.283.204.427.304.466.321.944.626 1.433.915.106.063.214.123.321.185.536.304 1.082.591 1.641.855.041.019.083.036.124.058a23.787 23.787 0 005.804 1.837l.16.029c.633.109 1.272.196 1.918.255l.237.018c.643.053 1.295.087 1.955.087.66 0 1.298-.034 1.94-.085l.242-.019a23.925 23.925 0 001.901-.25c.055-.01.11-.019.163-.03a23.786 23.786 0 005.721-1.79c.067-.032.134-.059.2-.092a23.154 23.154 0 001.964-1.038c.47-.276.931-.565 1.38-.872.161-.11.32-.226.48-.341.382-.276.758-.56 1.124-.858.081-.065.168-.122.248-.19l.801-.67-.023-.021A23.937 23.937 0 0048 24z"
      fill="currentColor"
    />
    <path
      d="M41.352 14.99l-.17 21.892c-1.379 2.795-8.134 9.073-16.84 9.106-8.707.033-16.296-5.928-17.985-8.97V14.99h34.995z"
      fill="#B8CEF9"
    />
    <path
      d="M41.352 13.784v2.414H6.357v-2.414A2.413 2.413 0 018.77 11.37h30.167a2.412 2.412 0 012.415 2.414z"
      fill="#89ADF5"
    />
    <path
      d="M9.978 13.18h-.604a.604.604 0 000 1.207h.604a.603.603 0 000-1.207zM12.62 13.228a.552.552 0 00-.46 0 .696.696 0 00-.199.127.612.612 0 000 .857.695.695 0 00.2.126.57.57 0 00.459 0 .695.695 0 00.199-.126.612.612 0 000-.857.69.69 0 00-.2-.127zM14.375 13.355a.699.699 0 00-.126.2.568.568 0 000 .458c.03.073.073.14.126.2a.612.612 0 00.857 0 .7.7 0 00.127-.2.603.603 0 00-.983-.66v.002zM38.336 13.18H17.82a.603.603 0 000 1.207h20.516a.604.604 0 000-1.207zM17.38 32.3a.654.654 0 00.545-.462l1.503-5.023a.654.654 0 10-1.254-.376l-1.078 3.603-1.04-1.734a.654.654 0 00-1.123 0l-1.04 1.734-1.08-3.602a.654.654 0 10-1.253.375l1.503 5.023a.654.654 0 001.188.148l1.24-2.071 1.242 2.071a.653.653 0 00.647.314zM22.407 32.298a.654.654 0 00.642-.313l1.241-2.072 1.242 2.072a.654.654 0 001.187-.148l1.503-5.023a.654.654 0 00-1.254-.376L25.89 30.04l-1.04-1.734a.654.654 0 00-1.123 0l-1.04 1.734-1.08-3.603a.654.654 0 10-1.253.376l1.503 5.023a.654.654 0 00.55.46zM36.58 25.999a.654.654 0 00-.814.438l-1.078 3.603-1.04-1.734a.655.655 0 00-1.122 0l-1.04 1.734-1.08-3.602a.654.654 0 00-1.253.376l1.503 5.022a.654.654 0 001.187.149l1.241-2.072 1.242 2.072a.655.655 0 001.187-.149l1.503-5.023a.654.654 0 00-.436-.814z"
      fill="currentColor"
    />
  </svg>
);
