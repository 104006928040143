import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { fetchInitiativesForTopicSuccess, fetchInitiativesForTopicFailure } from 'entities/initiatives/actions';
import { FetchInitiativeDefaultAction } from 'entities/initiatives/actionTypes';
import { Initiative } from 'entities/initiatives/types';

export function* fetchInitiativesForTopicSaga(action: FetchInitiativeDefaultAction) {
  const { id, offset, limit, searchTerm, ...filters } = action.payload;
  try {
    const payload: Initiative[] = yield apiFetch(`topic/${id}/project`, {
      queryParams: {
        offset,
        limit,
        name: searchTerm,
        owner: filters.type,
      },
    });

    yield put(
      fetchInitiativesForTopicSuccess(
        {
          topicId: id,
          data: payload,
        },
        action.payload,
      ),
    );
  } catch {
    const errorMeta = {
      searchCriteria: action.payload,
      logoutOnAuthError: true,
    };
    yield put(fetchInitiativesForTopicFailure({ topicId: id }, errorMeta));
  }
}
