import { useSelector } from 'react-redux';

import { Status } from 'common/types/status';
import { selectCollections } from 'domains/collection/selectors';
import { CollectionViewModel } from 'domains/collection/types';
import { useTranslations } from 'domains/language/useTranslations';
import * as Styled from 'domains/navigation/components/DiscoverButton.styled';
import { Dropdown } from 'domains/navigation/components/Dropdown';
import { TopBarItem } from 'domains/navigation/components/TopBarItem';
import { DestinationTypes } from 'domains/types';
import { getAllTopicsCount } from 'entities/topics/selectors';
import { getCustomTopicCount } from 'entities/topicsMetadata/selectors';
import { getCanAccessCreate } from 'entities/user/selectors';

const getLink = (type: DestinationTypes, canAccessCreate: boolean, id?: string) => {
  if (type === DestinationTypes.ALL || !id) {
    return '/topics';
  }
  if (type === DestinationTypes.CUSTOM) {
    return canAccessCreate ? `/create` : '/shared';
  }
  return `/collection/${id}`;
};

type ItemProps = {
  count: number;
  id?: string;
  isIndented?: boolean;
  label: string;
  link: string;
  onClick?: () => void;
};

const StyledItem = ({ id, label, link, count, isIndented, onClick }: ItemProps) => (
  <Styled.StyledLink key={id} to={link} onClick={onClick}>
    <Styled.Label $isIndented={isIndented}>{label}</Styled.Label>
    <Styled.Wrapper>
      <Styled.Badge>{count}</Styled.Badge>
      <Styled.StyledChevronIcon />
    </Styled.Wrapper>
  </Styled.StyledLink>
);

type DiscoverButtonProps = { onClick?: () => void };

export const DiscoverButton = ({ onClick }: DiscoverButtonProps) => {
  const { listed, status } = useSelector(selectCollections);
  const customTopicCount = useSelector(getCustomTopicCount);
  const { textDictionary } = useTranslations();
  const canAccessCreate = useSelector(getCanAccessCreate);

  const allTopicsCount = useSelector(getAllTopicsCount);
  const isPending = status.listed === Status.Loading;

  const list = [
    <StyledItem
      label={textDictionary['app.topic.all_topics']}
      count={allTopicsCount}
      key="all"
      onClick={onClick}
      link={getLink(DestinationTypes.ALL, canAccessCreate)}
    />,
  ];

  const collectionList = listed.map((collection) => (
    <CollectionItem collection={collection} key={collection.id} onClick={onClick} />
  ));

  let totalList = [...list, ...collectionList];

  if (customTopicCount) {
    totalList.push(
      <StyledItem
        key={'custom_map_key'}
        id={'Custom Maps'}
        label={textDictionary['app.topic.custom_maps']}
        count={customTopicCount}
        onClick={onClick}
        link={getLink(DestinationTypes.CUSTOM, canAccessCreate, 'Custom Maps')}
      />,
    );
  }

  if (totalList.length <= 1) {
    totalList = [];
  }

  return (
    <Dropdown
      button={
        <TopBarItem to="/topics">
          <Styled.Row>
            <span>{textDictionary['app.discover.title']}</span>
          </Styled.Row>
          {isPending ? <Styled.StyledSpinner /> : <Styled.ChevronDownIcon />}
        </TopBarItem>
      }
    >
      {totalList}
    </Dropdown>
  );
};

const CollectionItem = ({
  collection,
  onClick,
}: {
  collection: CollectionViewModel;
  onClick: ItemProps['onClick'];
}) => {
  const canAccessCreate = useSelector(getCanAccessCreate);
  return (
    <StyledItem
      count={collection.topic_ids.length}
      id={collection.id}
      isIndented={true}
      key={collection.id}
      label={collection.name}
      link={getLink(DestinationTypes.COLLECTION, canAccessCreate, collection.id)}
      onClick={onClick}
    />
  );
};
