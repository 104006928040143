import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import {
  HiddenImageUpload,
  ModalFormError,
  Spinner,
  UploadImageIcon,
  UserProfileImage,
  DigitalMemberTag,
} from 'common/components';
import { breakpoints } from 'common/styles';
import { LocalImage } from 'domains/createMapDetail/types';
import { useTranslations } from 'domains/language/useTranslations';
import { uploadProfileImage } from 'domains/user/actions';
import { getIsLoadingProfile, getIsUploadingProfileImage, getProfileImageUploadError } from 'domains/user/selectors';
import { getCanEditProfile, getShouldDisplayPremiumBadge, getUserData } from 'entities/user/selectors';

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
`;

const StyledUserDetails = styled.div`
  margin-left: 1rem;
`;

const StyledImageWrapper = styled.div`
  position: relative;
  width: 4rem;
  height: 4rem;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
`;

const StyledImage = styled(UserProfileImage)`
  width: 100%;
  height: 100%;
`;

const StyledFullNameText = styled.h2`
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.6rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media ${breakpoints.medium} {
    font-size: 1.6rem;
    line-height: 1.9rem;
  }
`;

// const StyledOrganizationText = styled(ModalText)`
//   margin: 0;
//   font-size: 0.9rem;
//   line-height: 1rem;
//
//   @media ${breakpoints.medium} {
//     font-size: 1rem;
//     line-height: 1.2rem;
//   }
// `;

const StyledImageUploadIndicator = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  position: absolute;
  bottom: -5px;
  right: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.blue};
`;

const StyledImageUploadIcon = styled(UploadImageIcon)`
  width: 16px;
  color: white;
`;

const StyledLoadingIndicator = styled(Spinner)`
  height: 22px;
  width: 22px;
  color: white;
`;

const StyledRoleTag = styled(DigitalMemberTag)`
  margin-top: 5px;
`;

type UserProfileInfoProps = { isActive?: boolean };

export const UserProfileInfo = ({ isActive }: UserProfileInfoProps) => {
  const dispatch = useDispatch();
  const canEditProfile = useSelector(getCanEditProfile);
  const isUploadingProfileImage = useSelector(getIsUploadingProfileImage);
  const imageUploadError = useSelector(getProfileImageUploadError);
  const isLoading = useSelector(getIsLoadingProfile);
  const userData = useSelector(getUserData);
  const showRoleTag = useSelector(getShouldDisplayPremiumBadge);
  const { textDictionary } = useTranslations();
  const [hasUploadErrored, setHasUploadErrored] = useState(false);
  const hiddenImageUploadRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (hasUploadErrored) {
      setHasUploadErrored(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  useEffect(() => {
    const newHasErrorUploaded = Boolean(imageUploadError);
    if (newHasErrorUploaded !== hasUploadErrored && isActive) {
      setHasUploadErrored(newHasErrorUploaded);
      const hiddenImageUpload = hiddenImageUploadRef.current;
      if (newHasErrorUploaded && hiddenImageUpload) {
        hiddenImageUpload.value = '';
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUploadError]);

  const handleImageSelected = (imageToUpload: LocalImage) => dispatch(uploadProfileImage(imageToUpload.file));

  const onImageClick = () => {
    setHasUploadErrored(false);
    hiddenImageUploadRef.current?.click();
  };

  const handleInvalidImageSelected = () => setHasUploadErrored(true);

  let icon = null;
  if (isLoading || isUploadingProfileImage) {
    icon = <StyledLoadingIndicator />;
  } else if (canEditProfile) {
    icon = <StyledImageUploadIcon name="ImageUpload" />;
  }

  return (
    <>
      <StyledHeader>
        <StyledImageWrapper onClick={canEditProfile ? onImageClick : undefined}>
          <HiddenImageUpload
            onSetImage={handleImageSelected}
            onInvalidImage={handleInvalidImageSelected}
            ref={hiddenImageUploadRef}
            accept="image/jpeg, image/jpg, image/png"
            name="image"
          />
          <StyledImage>{icon && <StyledImageUploadIndicator>{icon}</StyledImageUploadIndicator>}</StyledImage>
        </StyledImageWrapper>
        {!isLoading && (
          <StyledUserDetails>
            <StyledFullNameText>{userData.fullName}</StyledFullNameText>
            {/* TODO: ask Esther */}
            {/* <StyledOrganizationText>*/}
            {/*  {userData.organization}*/}
            {/* </StyledOrganizationText>*/}
            {userData?.localizedRole && showRoleTag && (
              <StyledRoleTag text={userData.localizedRole} trackPage="User Profile" />
            )}
          </StyledUserDetails>
        )}
      </StyledHeader>
      <ModalFormError isActive={hasUploadErrored}>{textDictionary['app.profile.upload_image.error']}</ModalFormError>
    </>
  );
};
