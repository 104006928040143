import { styled } from 'styled-components';

import { UIButton } from 'common/components';
import { Link } from 'common/components/Link';
import { breakpoints } from 'common/styles';
import { pushHistory } from 'common/utilities';
import { useTranslations } from 'domains/language/useTranslations';

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  color: ${({ theme }) => theme.colors.fillPrimary};
  justify-content: center;

  @media ${breakpoints.medium} {
    align-items: center;
  }
`;

const StyledInnerContainer = styled.div`
  max-width: 600px;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  padding: 3rem;
  border-radius: 10px;
  margin: 1rem;
`;

const StyledTitle = styled.h1`
  margin-top: 0;
`;

type FallbackProps = { error?: boolean };

export const Fallback = ({ error }: FallbackProps) => {
  const { textDictionary, interpolate } = useTranslations();

  const supportEmail = (supportEmail: string) => <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>;

  const errorText = interpolate(textDictionary['app.topic.follow.error_message'], {
    supportEmail,
  });

  const onClick = () => {
    pushHistory('/');
    window.location.reload();
  };

  return (
    <StyledContainer>
      {error && (
        <StyledInnerContainer>
          <StyledTitle>{textDictionary['app.error.unknown.title']}</StyledTitle>
          <p>{errorText}</p>
          <UIButton onClick={onClick} variant={'filled'} fullwidth>
            {textDictionary['app.auth.go_back']}
          </UIButton>
        </StyledInnerContainer>
      )}
    </StyledContainer>
  );
};
