import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';

import { getTokensFromStorage } from 'authUtils';
import { LanguageCode } from 'common/types/languageCode';
import { createAnalyticsMiddleware } from 'domains/analytics/middleware';
import { initialState as authenticationInitialState } from 'domains/authentication/reducers';
import { domainsReducer, domainSagaSpawners } from 'domains/domainSagas';
import { initialState as incidentsInitialState } from 'domains/incidents/reducer';
import { retrieveDismissedIncidents } from 'domains/incidents/storage';
import { fetchTermsSuccess } from 'domains/language/actions';
import { initialState as languageInitialState, languageReducer } from 'domains/language/reducer';
import { initialState as themeInitialState } from 'domains/theme/reducer';
import { ThemeMode } from 'domains/theme/types';
import { entitiesReducer, entitySagaSpawners } from 'entities/entitySagas';
import { initialState as permissionsInitialState } from 'entities/permissions/reducer';

const { localStorage } = window;

const sagaMiddleware = createSagaMiddleware();
const analyticsMiddleware = createAnalyticsMiddleware();

const composeEnhancers = composeWithDevTools({
  name: 'Strategic Intelligence',
});

let dismissedIncidents: string[] = [];
let terms = [];
try {
  dismissedIncidents = retrieveDismissedIncidents();
  terms = JSON.parse(localStorage.getItem('terms') || '[]') || [];
} catch (error) {
  console.error(error);
}

const storedUser = getTokensFromStorage();

// Clean up after the old token storage
// TODO: Remove when enough time has passed
if (localStorage.getItem('accessToken')) {
  localStorage.removeItem('accessToken');
}

let initialState: any = {
  domains: {
    language: languageReducer(
      languageInitialState,
      fetchTermsSuccess(
        { terms },
        { language: (localStorage.getItem('language') as LanguageCode | null) ?? LanguageCode.EN },
      ),
    ),
    incidents: {
      ...incidentsInitialState,
      dismissed: dismissedIncidents,
    },
    authentication: {
      ...authenticationInitialState,
      tokens: { ...authenticationInitialState.tokens, ...storedUser },
    },
    theme: {
      ...themeInitialState,
      currentMode: (localStorage.getItem('theme') as ThemeMode) || 'default',
    },
  },
};

const storedPermissions = localStorage.getItem('permissions');
if (storedPermissions) {
  const permissionStoredState = JSON.parse(storedPermissions) || {};
  initialState = {
    ...initialState,
    entities: {
      permissions: {
        ...permissionsInitialState,
        ...permissionStoredState,
      },
    },
  };
}

export const store = createStore(
  combineReducers({ entities: entitiesReducer, domains: domainsReducer }),
  initialState,
  composeEnhancers(applyMiddleware(sagaMiddleware, analyticsMiddleware)),
);

function* saga() {
  yield all([...domainSagaSpawners, ...entitySagaSpawners]);
}

sagaMiddleware.run(saga);
