import { combineReducers } from 'redux';

import { searchCriteriaReducer as buildSearchCriteriaReducer } from 'common/reducer/searchCriteriaReducer';
import { findMatchingCriteriaAndOthers } from 'domains/publication/reducer';
import { TopicStakeholderActionTypes } from 'domains/stakeholders/actionTypes';
import {
  FullTopicStakeholdersSearchCriteria,
  TopicStakeholderPackage,
  TopicStakeholdersState,
} from 'domains/stakeholders/types';
import { addTopicSelectionReset, addUserOrMapChangeReset } from 'domains/topic/reducerHelpers';
import { StakeholderAction, StakeholderActionTypes } from 'entities/stakeholders/actionTypes';

const initialState: TopicStakeholdersState = {
  forTopic: {},
  searchCriteria: { searchTerm: '', type: '', role: '', offset: 0, limit: 20 },
};

const stakeholdersForTopicReducer = (state = initialState.forTopic, action: StakeholderAction) => {
  switch (action.type) {
    case StakeholderActionTypes.FETCH_FOR_TOPIC: {
      const { offset: _offset, limit: _limit, ...searchCriteria } = action.payload;
      const [matchingStakeholderPackage, others] = findMatchingCriteriaAndOthers<
        TopicStakeholderPackage,
        FullTopicStakeholdersSearchCriteria
      >(state, searchCriteria);
      const newStakeholderPackage = {
        ...(matchingStakeholderPackage || {}),
        isFetching: true,
        hasNextPage: true,
        error: false,
        data: matchingStakeholderPackage?.data || [],
        searchCriteria,
      };
      return {
        ...state,
        [searchCriteria.id]: [...others, newStakeholderPackage],
      };
    }

    case StakeholderActionTypes.FETCH_FOR_TOPIC_SUCCESS: {
      const { data } = action.payload;
      const { offset, limit, ...searchCriteria } = action.meta;
      const [matchingStakeholderPackage, others] = findMatchingCriteriaAndOthers<
        TopicStakeholderPackage,
        FullTopicStakeholdersSearchCriteria
      >(state, searchCriteria);

      const dataToKeep = matchingStakeholderPackage?.data.slice(0, offset) || [];
      const newStakeholderPackage = {
        ...(matchingStakeholderPackage || {}),
        isFetching: false,
        hasNextPage: data.length >= limit,
        error: false,
        data: dataToKeep.concat(data.map(({ id }) => id)),
        searchCriteria,
      };
      return {
        ...state,
        [searchCriteria.id]: [...others, newStakeholderPackage],
      };
    }

    case StakeholderActionTypes.FETCH_FOR_TOPIC_FAILURE: {
      const { searchCriteria } = action.meta;
      const [matchingKnowledgePackage, others] = findMatchingCriteriaAndOthers<
        TopicStakeholderPackage,
        FullTopicStakeholdersSearchCriteria
      >(state, searchCriteria);
      const newKnowledgePackage = {
        ...(matchingKnowledgePackage || {}),
        isFetching: false,
        hasNextPage: false,
        error: true,
        data: matchingKnowledgePackage?.data || [],
        searchCriteria,
      };
      return {
        ...state,
        [searchCriteria.id]: [...others, newKnowledgePackage],
      };
    }

    default:
      return state;
  }
};

const searchCriteriaReducer = buildSearchCriteriaReducer(
  initialState.searchCriteria,
  TopicStakeholderActionTypes.APPLY_SEARCH_CRITERIA,
  TopicStakeholderActionTypes.CLEAR_SEARCH_CRITERIA,
);

export const stakeholdersReducer = combineReducers({
  forTopic: addUserOrMapChangeReset(stakeholdersForTopicReducer, initialState.forTopic),
  searchCriteria: addTopicSelectionReset(searchCriteriaReducer, initialState.searchCriteria),
});
