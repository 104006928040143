import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { fetchMembershipEventsSuccess, fetchMembershipEventsFailure } from 'domains/membershipEvents/actions';
import { MembershipEvent } from 'domains/membershipEvents/types';

export function* fetchMembershipEventsSaga() {
  try {
    const response: { events: MembershipEvent[] } = yield apiFetch('profile/events');
    yield put(fetchMembershipEventsSuccess(response));
  } catch (error) {
    yield put(fetchMembershipEventsFailure());
  }
}
