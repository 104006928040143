import {
  BookmarksFetchAction,
  BookmarksFetchFulfilledAction,
  BookmarksFetchRejectedAction,
  ToggleBookmarkAction,
  ToggleBookmarkFulfilledAction,
  ToggleBookmarkRejectedAction,
  BookmarkActionTypes,
} from 'domains/bookmarks/actionTypes';
import { FetchBookmarksMeta, ToggleBookmarkMeta } from 'domains/bookmarks/types';

export const fetchBookmarks = ({ offset = 0 }): BookmarksFetchAction => ({
  type: BookmarkActionTypes.BOOKMARKS_FETCH,
  meta: { offset },
});

export const fetchBookmarksFulfilled = (
  meta: BookmarksFetchFulfilledAction['meta'],
  payload: BookmarksFetchFulfilledAction['payload'],
): BookmarksFetchFulfilledAction => ({
  type: BookmarkActionTypes.BOOKMARKS_FETCH_FULFILLED,
  meta,
  payload,
});

export const fetchBookmarksRejected = (meta: FetchBookmarksMeta): BookmarksFetchRejectedAction => ({
  type: BookmarkActionTypes.BOOKMARKS_FETCH_REJECTED,
  meta,
});

export const toggleBookmark = (meta: ToggleBookmarkMeta): ToggleBookmarkAction => ({
  type: BookmarkActionTypes.BOOKMARK_TOGGLE,
  meta,
});

export const toggleBookmarkFulfilled = (meta: ToggleBookmarkMeta): ToggleBookmarkFulfilledAction => ({
  type: BookmarkActionTypes.BOOKMARK_TOGGLE_FULFILLED,
  meta,
});

export const toggleBookmarkRejected = (meta: ToggleBookmarkMeta): ToggleBookmarkRejectedAction => ({
  type: BookmarkActionTypes.BOOKMARK_TOGGLE_REJECTED,
  meta,
});
