import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { fetchTopicFailure, fetchTopicSuccess } from 'entities/topics/actions';
import { FetchTopicDefaultAction } from 'entities/topics/actionTypes';
import { Topic } from 'entities/topics/types';
import { isFetchError } from 'TypeCheckMethods';

export function* fetchTopicSaga({ payload }: FetchTopicDefaultAction) {
  try {
    const topic: Topic = yield apiFetch(`map/${payload.id}`);
    yield put(fetchTopicSuccess({ topic }));
  } catch (error) {
    if (isFetchError(error)) {
      yield put(fetchTopicFailure({ id: payload.id, error }, { logoutOnAuthError: true }));
    }
  }
}
