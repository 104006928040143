import { CustomKeyIssueMeta } from 'domains/customKeyIssue/types';
import { PrimaryTopic } from 'entities/keyIssues/types';
import { Topic } from 'entities/topics/types';

export type CreateMapState = {
  AIGeneratedMapErrorCode: number | null;
  AIGeneratedMapIgnoreResponse: boolean;
  AIGeneratedMapProgress: AIGeneratedMapProgress;
  data?: Partial<Topic>;
  description: string;
  error: boolean;
  image?: LocalImage;
  isAIGeneratedMapFetching: boolean;
  isAIGeneratedModeOn: boolean;
  isDeleting?: boolean;
  isDraggingKeyIssue: boolean;
  isPending: boolean;
  isUploadFulfilled: boolean;
  keyIssues: string[];
  name: string;
  newCustomKeyIssues: CustomKeyIssueMeta[];
};

type Url = { url: string | ArrayBuffer | null };
export type LocalImage = Url & { file: Blob };
export type LocalFile = Url & { file: File };
export type AddKeyIssuePayload = Omit<Topic, 'parent_topic'> & { parent_topic?: PrimaryTopic; topics: Topic[] };
export type UploadMapMeta = { closeEditor?: boolean };

export enum FakeProgressStatuses {
  'Not started' = 'Not started',
  'Preparing' = 'Preparing',
  'Selecting' = 'Selecting',
  'Finalizing' = 'Finalizing',
  'Finish' = 'Finish',
}

export type AIGeneratedMapProgress = { status: FakeProgressStatuses };
