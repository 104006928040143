import { SVGProps } from 'react';

export const FullscreenExitIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      fill="currentColor"
      d="M3.693 32.846h9.905L0 46.43l1.57 1.57 13.586-13.595v9.915h2.211V30.634H3.693v2.212zM44.318 15.158h-9.914L48.002 1.572 46.43.002 32.844 13.599V3.694h-2.211V17.37h13.685v-2.211zM15.156 13.599L1.57 0 0 1.571l13.598 13.586H3.693v2.211h13.674V3.694h-2.211v9.905zM44.318 32.846v-2.212H30.633V44.32h2.211v-9.913L46.43 48.003l1.57-1.57-13.596-13.587h9.914z"
    />
  </svg>
);
