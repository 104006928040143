import { SVGProps } from 'react';

export const EmbedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 15" {...props}>
    <path transform="translate(1 1.342)" fill="none" d="M0 0h26v12H0z" />
    <g>
      <path transform="translate(1 1.342)" fill="none" d="M0 0h26v12H0z" />
      <g stroke="none" fill={'none'}>
        <g>
          <path
            d="M.3 6.293a.99.99 0 0 0 0 1.414.99.99 0 0 0 1.414 0L7 2.414l5.3 5.293a1 1 0 0 0 1.414-1.414l-6-6a1 1 0 0 0-1.414 0l-6 6z"
            transform="rotate(90 13.831 14.169)"
          />
          <path
            d="M13.004 8.004a.997.997 0 0 1-.707-.297L7.004 2.414 1.711 7.707a.99.99 0 0 1-1.414 0 .99.99 0 0 1 0-1.414l6-6A.99.99 0 0 1 7.004 0a.99.99 0 0 1 .707.293l6 6a.99.99 0 0 1 0 1.414.997.997 0 0 1-.707.297z"
            fill={'currentColor'}
            transform="rotate(90 13.831 14.169)"
          />
        </g>
        <g>
          <path
            d="M1 0a.99.99 0 0 0-1 1 .99.99 0 0 0 1 1h13.416a.99.99 0 0 0 1-1 .99.99 0 0 0-1-1z"
            transform="rotate(116.565 8.895 6.115)"
          />
          <path
            d="M1 0h13.416c.56 0 1 .44 1 1s-.44 1-1 1H1c-.56 0-1-.44-1-1s.44-1 1-1z"
            fill={'currentColor'}
            transform="rotate(116.565 8.895 6.115)"
          />
        </g>
        <g>
          <path
            d="M.3 1.711A.99.99 0 0 1 .3.3a.99.99 0 0 1 1.411 0L7 5.59 12.3.3a.99.99 0 0 1 1.414 0 .99.99 0 0 1 0 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6z"
            transform="rotate(90 3.833 4.171)"
          />
          <path
            d="M1.004 0c.255 0 .51.099.707.297L7.004 5.59 12.297.297a.99.99 0 0 1 1.414 0 .99.99 0 0 1 0 1.414l-6 6a.99.99 0 0 1-.707.293.99.99 0 0 1-.707-.293l-6-6a.99.99 0 0 1 0-1.414A.997.997 0 0 1 1.004 0z"
            fill={'currentColor'}
            transform="rotate(90 3.833 4.171)"
          />
        </g>
      </g>
    </g>
  </svg>
);
