import { MembershipEvent } from 'domains/membershipEvents/types';

export enum MembershipEventsTypes {
  FETCH = '[Membership Events] - Fetch',
  FETCH_FULFILLED = '[Membership Events] - Fetch - Fulfilled',
  FETCH_REJECTED = '[Membership Events] - Fetch - Rejected',
  FETCH_BY_ID = '[Membership Events] - Fetch by ID',
  FETCH_BY_ID_FULFILLED = '[Membership Events] - Fetch by ID - Fulfilled',
  FETCH_BY_ID_REJECTED = '[Membership Events] - Fetch by ID - Rejected',
}

export type FetchMembershipEventsAction = { type: MembershipEventsTypes.FETCH };

export type FetchMembershipEventsSuccessAction = {
  payload: { events: MembershipEvent[] };
  type: MembershipEventsTypes.FETCH_FULFILLED;
};

export type FetchMembershipEventsFailureAction = { type: MembershipEventsTypes.FETCH_REJECTED };
export type FetchMembershipEventByIdAction = { meta: { id: string }; type: MembershipEventsTypes.FETCH_BY_ID };

export type FetchMembershipEventByIdSuccessAction = {
  meta: { id: string };
  payload: MembershipEvent;
  type: MembershipEventsTypes.FETCH_BY_ID_FULFILLED;
};

export type FetchMembershipEventByIdFailureAction = {
  meta: { id: string };
  type: MembershipEventsTypes.FETCH_BY_ID_REJECTED;
};

export type MembershipEventsAction =
  | FetchMembershipEventsAction
  | FetchMembershipEventsSuccessAction
  | FetchMembershipEventsFailureAction
  | FetchMembershipEventByIdAction
  | FetchMembershipEventByIdSuccessAction
  | FetchMembershipEventByIdFailureAction;
