import {
  PublicationFetchForTopicFailurePayload,
  PublicationFetchForTopicPayload,
  PublicationFetchForTopicSuccessAction,
} from 'entities/publication/actionTypes';
import { ErrorMeta } from 'types';

export enum KnowledgeDataActionTypes {
  FETCH_FOR_TOPIC = 'entities/KNOWLEDGE_DATA_FETCH_FOR_TOPIC',
  FETCH_FOR_TOPIC_SUCCESS = 'entities/KNOWLEDGE_DATA_FETCH_FOR_TOPIC_SUCCESS',
  FETCH_FOR_TOPIC_FAILURE = 'entities/KNOWLEDGE_DATA_FETCH_FOR_TOPIC_FAILURE',
}

export type KnowledgeDataFetchForTopicPayload = Omit<PublicationFetchForTopicPayload, 'language' | 'publicationType'>;

export type KnowledgeDataFetchForTopicAction = {
  payload: KnowledgeDataFetchForTopicPayload;
  type: KnowledgeDataActionTypes.FETCH_FOR_TOPIC;
};

export type KnowledgeDataFetchForTopicSuccessAction = Omit<PublicationFetchForTopicSuccessAction, 'type'> & {
  type: KnowledgeDataActionTypes.FETCH_FOR_TOPIC_SUCCESS;
};

type KnowledgeDataFetchForTopicFailureMeta = ErrorMeta & { searchCriteria: PublicationFetchForTopicPayload };

export type KnowledgeDataFetchForTopicFailureAction = {
  meta: KnowledgeDataFetchForTopicFailureMeta;
  payload: PublicationFetchForTopicFailurePayload;
  type: KnowledgeDataActionTypes.FETCH_FOR_TOPIC_FAILURE;
};

export type KnowledgeDataAction =
  | KnowledgeDataFetchForTopicAction
  | KnowledgeDataFetchForTopicSuccessAction
  | KnowledgeDataFetchForTopicFailureAction;
