import { put, select } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { activateModal } from 'domains/modal/actions';
import { followTopicFailure, followTopicSuccess, fetchFollowedTopics } from 'entities/topics/actions';
import { FollowTopicDefaultAction } from 'entities/topics/actionTypes';
import { getFollowedTopics, getFollowedTopicsLimit } from 'entities/topics/selectors';
import { Topic } from 'entities/topics/types';
import { getFollowTopicLimit } from 'entities/user/selectors';
import { isFetchError } from 'TypeCheckMethods';

export function* followTopicSaga(action: FollowTopicDefaultAction) {
  const { id } = action.payload;

  try {
    const topics: Topic[] = yield select(getFollowedTopics);
    const limit: number | undefined = yield select(getFollowTopicLimit);
    const followedTopicsLimit: number = yield select(getFollowedTopicsLimit);
    if (limit && topics.length >= limit) {
      yield put(activateModal({ id: 'Follow Topics Limit' }));
      yield put(followTopicFailure({ id }));
    } else {
      yield apiFetch(`topic/${id}/follow`, { method: 'PUT' });
      yield put(followTopicSuccess({ id, isFollowing: true }, action.meta));
      yield put(
        fetchFollowedTopics({
          limit: followedTopicsLimit,
          offset: 0,
        }),
      );
    }
  } catch (error) {
    if (isFetchError(error)) {
      yield put(followTopicFailure({ id, error }, { logoutOnAuthError: true }));
    }
  }
}
