const UNSPLASH_API = 'https://api.unsplash.com/search/photos';

const unsplashHeader = {
  Authorization: `Client-ID ${process.env.REACT_APP_UNSPLASH_KEY}`,
};

export const fetchUnsplashAPI = async (url: string) => {
  const response = await fetch(url, { headers: unsplashHeader });
  if (!response.ok) {
    throw new Error();
  }
  return response.json();
};

export const fetchUnsplashImages = (query: string) => fetchUnsplashAPI(`${UNSPLASH_API}?page=1&query=${query}`);
