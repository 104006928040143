import {
  VideoActionTypes,
  VideosFetchForTopicAction,
  VideosFetchForTopicSuccessAction,
  VideosFetchForTopicFailureAction,
} from 'entities/videos/actionTypes';

export const fetchVideosForTopic = (payload: VideosFetchForTopicAction['payload']): VideosFetchForTopicAction => ({
  type: VideoActionTypes.FETCH_FOR_TOPIC,
  payload,
});

export const fetchVideosForTopicSuccess = (
  payload: VideosFetchForTopicSuccessAction['payload'],
  meta: VideosFetchForTopicSuccessAction['meta'],
): VideosFetchForTopicSuccessAction => ({
  type: VideoActionTypes.FETCH_FOR_TOPIC_SUCCESS,
  payload,
  meta,
});

export const fetchVideosForTopicFailure = (
  payload: VideosFetchForTopicFailureAction['payload'],
  meta: VideosFetchForTopicFailureAction['meta'],
): VideosFetchForTopicFailureAction => ({
  type: VideoActionTypes.FETCH_FOR_TOPIC_FAILURE,
  payload,
  meta,
});
