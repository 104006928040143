import { TopicKnowledgeDataSearchCriteria } from 'domains/knowledgeData/types';

export enum TopicKnowledgeDataActionTypes {
  APPLY_SEARCH_CRITERIA = 'domains/KNOWLEDGE_DATA_APPLY_SEARCH_CRITERIA',
  CLEAR_SEARCH_CRITERIA = 'domains/KNOWLEDGE_DATA_CLEAR_SEARCH_CRITERIA',
}

export type TopicKnowledgeDataApplySearchCriteriaPayload = Partial<TopicKnowledgeDataSearchCriteria> & {
  topicId?: string;
};

export type TopicKnowledgeDataApplySearchCriteriaAction = {
  payload?: TopicKnowledgeDataApplySearchCriteriaPayload;
  type: TopicKnowledgeDataActionTypes.APPLY_SEARCH_CRITERIA;
};

export type TopicKnowledgeDataClearSearchCriteriaAction = { type: TopicKnowledgeDataActionTypes.CLEAR_SEARCH_CRITERIA };
