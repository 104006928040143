import { useState } from 'react';
import { useSelector } from 'react-redux';
import { css, styled } from 'styled-components';

import { UIButton, UISwitch } from 'common/components';
import { copyTextToClipboard } from 'common/utilities';
import { useTranslations } from 'domains/language/useTranslations';
import { ModalHeader } from 'domains/modal/components/ModalHeader';
import { getTransformationMapId } from 'domains/transformationMap/selectors';

const StyledCodeContainer = styled.div`
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 2rem;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
`;

const StyledSwitchContainer = styled.div`
  padding: 0.5rem 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
`;

const StyledText = styled.p`
  color: ${({ theme }) => theme.colors.fillPrimary};
`;

const StyledCode = styled.p`
  font-family: 'Courier New';
  font-size: 0.9rem;
  margin: 0;
`;

const StyledLink = styled.a`
  cursor: pointer;
  text-decoration: underline;
  font-size: 1.1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.blue};
`;

const StyledButton = styled(UIButton)<{ isCopied: boolean }>`
  ${({ theme, isCopied }) =>
    isCopied &&
    css`
      background-color: ${theme.colors.green};

      &:hover {
        background-color: ${theme.colors.greenDark};
      }
    `}
`;

const scriptTag = `
  <script
    type="text/javascript"
    src="${process.env.REACT_APP_TOPIC_WIDGET_URL}/insiw/v1/latest.js">
  </script>
`;

const generateWidget = (topicId: string | null = '') =>
  `<insi-widget apikey="YOUR_API_KEY_HERE" topicid="${topicId}"></insi-widget>`;

const generateIframe = (topicId: string | null = '') => `
  <iframe
    src="${process.env.REACT_APP_TOPIC_WIDGET_URL}/insiw/v1/iframe.html?topicid=${topicId}&apikey=YOUR_API_KEY_HERE"
    style="border:0 solid white;"
    name="intelligenceWidgetIframe"
    height="600px"
    width="450px"
  />
`;

export const EmbedMapWidgetModal = () => {
  const { textDictionary, interpolate } = useTranslations();
  const topicId = useSelector(getTransformationMapId);
  const [isCopied, setIsCopied] = useState(false);
  const [isIframe, setIsIframe] = useState(true);

  const copyCode = () => {
    setIsCopied(true);
    if (isIframe) {
      copyTextToClipboard(generateIframe(topicId));
    } else {
      copyTextToClipboard(scriptTag + generateWidget(topicId));
    }
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  const toggleIframe = () => setIsIframe(!isIframe);
  return (
    <>
      <ModalHeader title={textDictionary['app.widget.embed_maps_in_your_site']} />
      <StyledText data-test-id={'embed-modal-text'}>
        {interpolate(textDictionary['app.widget.embed.learn_more'], {
          here: (here: string) => (
            <StyledLink href={process.env.REACT_APP_WIDGET_GUIDE_URL} target="_blank" rel="noopener">
              {here}
            </StyledLink>
          ),
        })}
      </StyledText>
      <StyledSwitchContainer>
        <UISwitch isOn={isIframe} onClick={toggleIframe} dataTestId={'embed-widget-switch'}>
          {isIframe ? 'iFrame' : 'Web component'}
        </UISwitch>
      </StyledSwitchContainer>
      <StyledCodeContainer>
        {isIframe ? (
          <StyledCode data-test-id={'embed-iframe-text'}>{generateIframe(topicId)}</StyledCode>
        ) : (
          <>
            <StyledCode>{scriptTag}</StyledCode>
            <StyledCode data-test-id={'embed-webcomponent-text'}>{generateWidget(topicId)}</StyledCode>
          </>
        )}
      </StyledCodeContainer>
      <StyledButton onClick={copyCode} isCopied={isCopied} fullwidth variant={'filled'}>
        {textDictionary[isCopied ? 'app.clipboard.copied' : 'app.clipboard.copy']}
      </StyledButton>
    </>
  );
};
