import { combineReducers } from 'redux';

import { TopicSearchAction, SearchType, SearchMeta } from 'domains/search/actionTypes';
import { SearchState, SearchSection } from 'domains/search/types';
import { KeyIssuesAction, KeyIssuesActionType } from 'entities/keyIssues/actionTypes';
import { addUserOrLanguageChangeReset } from 'entities/topics/reducerHelpers';

const initialSectionState: SearchSection = {
  idsForQuery: {},
  isPending: false,
  hasNextPage: false,
};

const initialState: SearchState = {
  currentSearchTerm: '',
  topics: initialSectionState,
  publications: initialSectionState,
  videos: initialSectionState,
  interactiveData: initialSectionState,
  keyIssues: {
    isPending: false,
  },
  sectionVisibility: {
    topics: true,
    keyIssues: true,
    publications: true,
    emergingThemes: false,
    videos: true,
    interactiveData: true,
    events: false,
    initiatives: false,
    stakeholders: false,
  },
};

const searchTermReducer = (state = initialState.currentSearchTerm, action: TopicSearchAction) => {
  switch (action.type) {
    case SearchType.UPDATE_QUERY: {
      return action.meta.query;
    }
    default:
      return state;
  }
};

const sectionPending = (state: SearchSection) => ({
  ...state,
  isPending: true,
});

const sectionFulfilled = (state: SearchSection, ids: string[], meta: SearchMeta): SearchSection => {
  const { query, limit, offset = 0 } = meta;
  if (!query) {
    return { ...state, isPending: false };
  }
  const previousIds = state.idsForQuery[query];
  return {
    ...state,
    isPending: false,
    hasNextPage: ids.length === limit,
    idsForQuery: {
      ...state.idsForQuery,
      [query]: offset === 0 ? ids : [...previousIds, ...ids],
    },
  };
};

const sectionRejected = (state: SearchSection) => ({
  ...state,
  isPending: false,
});

const topicsReducer = (state = initialState.topics, action: TopicSearchAction): SearchSection => {
  switch (action.type) {
    case SearchType.FETCH_TOPICS:
      return sectionPending(state);
    case SearchType.FETCH_TOPICS_FULFILLED:
      return sectionFulfilled(
        state,
        action.payload.map((item) => item.id),
        action.meta,
      );
    case SearchType.FETCH_TOPICS_REJECTED:
      return sectionRejected(state);
    default:
      return state;
  }
};

const publicationsReducer = (state = initialState.publications, action: TopicSearchAction): SearchSection => {
  switch (action.type) {
    case SearchType.FETCH_PUBLICATIONS:
      return sectionPending(state);
    case SearchType.FETCH_PUBLICATIONS_FULFILLED:
      return sectionFulfilled(
        state,
        action.payload.map((item) => item.id),
        action.meta,
      );
    case SearchType.FETCH_PUBLICATIONS_REJECTED:
      return sectionRejected(state);
    default:
      return state;
  }
};

const keyIssuesReducer = (state = initialState.publications, action: KeyIssuesAction): SearchSection => {
  switch (action.type) {
    case KeyIssuesActionType.FETCH:
      return sectionPending(state);
    case KeyIssuesActionType.FETCH_FULFILLED:
      return { ...state, isPending: false };
    case KeyIssuesActionType.FETCH_REJECTED:
      return sectionRejected(state);
    default:
      return state;
  }
};

const videosReducer = (state = initialState.videos, action: TopicSearchAction): SearchSection => {
  switch (action.type) {
    case SearchType.FETCH_VIDEOS:
      return sectionPending(state);
    case SearchType.FETCH_VIDEOS_FULFILLED:
      return sectionFulfilled(
        state,
        action.payload.map((item) => item.id),
        action.meta,
      );
    case SearchType.FETCH_VIDEOS_REJECTED:
      return sectionRejected(state);
    default:
      return state;
  }
};

const knowledgeDataReducer = (state = initialState.interactiveData, action: TopicSearchAction): SearchSection => {
  switch (action.type) {
    case SearchType.FETCH_KNOWLEDGE_DATA:
      return sectionPending(state);
    case SearchType.FETCH_KNOWLEDGE_DATA_FULFILLED:
      return sectionFulfilled(
        state,
        action.payload.map((item) => item.id),
        action.meta,
      );
    case SearchType.FETCH_KNOWLEDGE_DATA_REJECTED:
      return sectionRejected(state);
    default:
      return state;
  }
};

const sectionVisibilityReducer = (state = initialState.sectionVisibility, action: TopicSearchAction) => {
  switch (action.type) {
    case SearchType.UPDATE_SECTION_VISIBILITY: {
      return {
        ...state,
        [action.meta.section]: !state[action.meta.section],
      };
    }
    default:
      return state;
  }
};

export const searchReducer = addUserOrLanguageChangeReset(
  combineReducers({
    currentSearchTerm: searchTermReducer,
    topics: topicsReducer,
    keyIssues: keyIssuesReducer,
    publications: publicationsReducer,
    videos: videosReducer,
    sectionVisibility: sectionVisibilityReducer,
    interactiveData: knowledgeDataReducer,
  }),
  initialState,
);
