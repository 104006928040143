import { Redirect, useParams } from 'react-router-dom';

/**
 * A component that exists to migrate users from old Create routes to new ones:
 *  - /create/map/:topicId -> /create/<topicId>?editing=true
 *  - /create/map -> /create/new
 *
 * TODO: Remove when enough time has passed (introduced on 16/02/2021)
 */
export const CreateMigrateToNewRoutes = () => {
  const { topicId } = useParams<{ topicId?: string }>();

  if (topicId) {
    return <Redirect to={`/create/${topicId}?editing=true`} />;
  }
  return <Redirect to="/create/new" />;
};
