import { styled } from 'styled-components';

import { UIChip } from 'common/components';
import { WhatsNewStory } from 'domains/whatsNew/types';

const StyledContainer = styled.div`
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.colors.textBright};

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledStory = styled.div`
  display: flex;
`;

const StyledHeader = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`;

const StyledDescription = styled.p`
  font-size: 0.9rem;
  line-height: 1.3rem;
  margin: 0;
  white-space: pre-line;
`;

const StyledTag = styled(UIChip)``;

const StyledData = styled.div`
  display: block;
`;

export const WhatsNewStoryItem = ({ title, description, dependencies }: WhatsNewStory) => (
  <StyledContainer>
    <StyledHeader>{title}</StyledHeader>
    <StyledStory>
      <StyledData>
        <StyledDescription>{description}</StyledDescription>
        {dependencies.map(({ label }) => (
          <StyledTag key={label} disabled>
            {label}
          </StyledTag>
        ))}
      </StyledData>
    </StyledStory>
  </StyledContainer>
);
