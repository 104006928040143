export type GenericReducer = (state: any, action: any) => any;

export const addStateReset =
  (...types: string[]) =>
  (reducerFunc: GenericReducer, initialState: any) =>
  (state: any, action: any) => {
    if (types.includes(action.type)) {
      return initialState;
    }
    return reducerFunc(state, action);
  };

export const combineStateResets =
  (...stateResets: ReturnType<typeof addStateReset>[]) =>
  (reducer: GenericReducer, initialState: any) =>
    stateResets.reduce((newReducer, stateReset) => stateReset(newReducer, initialState), reducer);
