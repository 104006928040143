import {
  FetchLanguageTermsDefaultAction,
  FetchLanguageTermsFulfilledAction,
  FetchLanguageTermsRejectedAction,
  LanguageDomainActionType,
} from 'domains/language/actionTypes';

export const fetchTerms = (meta: FetchLanguageTermsDefaultAction['meta']): FetchLanguageTermsDefaultAction => ({
  type: LanguageDomainActionType.FETCH,
  meta,
});

export const fetchTermsSuccess = (
  payload: FetchLanguageTermsFulfilledAction['payload'],
  meta: FetchLanguageTermsFulfilledAction['meta'],
): FetchLanguageTermsFulfilledAction => ({
  type: LanguageDomainActionType.FETCH_FULFILLED,
  payload,
  meta,
});

export const fetchTermsFailure = (
  payload: FetchLanguageTermsRejectedAction['payload'],
): FetchLanguageTermsRejectedAction => ({
  type: LanguageDomainActionType.FETCH_REJECTED,
  payload,
});
