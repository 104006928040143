import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { fetchSurveyLinkSuccess, fetchSurveyLinkFailure } from 'domains/topic/actions';
import { FetchSurveyLinkSuccessPayload, FetchSurveyLinkAction } from 'domains/topic/actionTypes';

export function* fetchSurveyLinkSaga(action: FetchSurveyLinkAction) {
  try {
    const response: FetchSurveyLinkSuccessPayload = yield apiFetch(`topic/${action.payload}/survey_link`);
    yield put(fetchSurveyLinkSuccess(response));
  } catch (error) {
    yield put(fetchSurveyLinkFailure());
  }
}
