import {
  CustomContentAndSourcesTypes,
  DeleteTopicContentAction,
  DeleteTopicContentFulfilledAction,
  DeleteTopicContentRejectedAction,
  SetTopicContentFile,
  SetTopicContentFileContent,
  TopicContentListFetchAction,
  TopicContentListFetchFulfilledAction,
  TopicContentListFetchPayload,
  TopicContentListFetchRejectedAction,
  TopicContentScrapeUrl,
  TopicContentScrapeUrlFulfilled,
  TopicContentScrapeUrlRejected,
  TopicContentUpdateScrapedArticle,
  UploadTopicContentAction,
  UploadTopicContentFulfilledAction,
  UploadTopicContentRejectedAction,
} from 'domains/customContentAndSources/actionTypes';
import { CustomContent } from 'domains/customContentAndSources/types';
import { ErrorPayload } from 'types';

export const fetchTopicContentList = (payload: TopicContentListFetchPayload): TopicContentListFetchAction => ({
  type: CustomContentAndSourcesTypes.FETCH_TOPIC_CONTENT_LIST,
  payload,
});

export const fetchTopicContentListFulfilled = (
  payload: CustomContent[],
  meta: TopicContentListFetchPayload,
): TopicContentListFetchFulfilledAction => ({
  type: CustomContentAndSourcesTypes.FETCH_TOPIC_CONTENT_LIST_FULFILLED,
  payload,
  meta,
});

export const fetchTopicContentListRejected = (
  payload: ErrorPayload,
  meta: TopicContentListFetchRejectedAction['meta'],
): TopicContentListFetchRejectedAction => ({
  type: CustomContentAndSourcesTypes.FETCH_TOPIC_CONTENT_LIST_REJECTED,
  payload,
  meta,
});

export const setTopicContentFile = (payload: SetTopicContentFile['payload']): SetTopicContentFile => ({
  type: CustomContentAndSourcesTypes.SET_FILE,
  payload,
});

// Used for setting existing content in the form to edit
export const setTopicContentFileContent = (
  payload: SetTopicContentFileContent['payload'],
): SetTopicContentFileContent => ({
  type: CustomContentAndSourcesTypes.SET_FILE_CONTENT,
  payload,
});

export const uploadTopicContent = (payload: UploadTopicContentAction['payload']): UploadTopicContentAction => ({
  type: CustomContentAndSourcesTypes.UPLOAD_CONTENT_ATTACHMENT,
  payload,
});

export const uploadTopicContentFulfilled = (
  meta: UploadTopicContentFulfilledAction['meta'],
): UploadTopicContentFulfilledAction => ({
  type: CustomContentAndSourcesTypes.UPLOAD_CONTENT_ATTACHMENT_FULFILLED,
  meta,
});

export const uploadTopicContentRejected = (
  payload: ErrorPayload,
  meta: TopicContentListFetchRejectedAction['meta'],
): UploadTopicContentRejectedAction => ({
  type: CustomContentAndSourcesTypes.UPLOAD_CONTENT_ATTACHMENT_REJECTED,
  payload,
  meta,
});

export const deleteTopicContent = (payload: DeleteTopicContentAction['payload']): DeleteTopicContentAction => ({
  type: CustomContentAndSourcesTypes.DELETE_CONTENT,
  payload,
});

export const deleteTopicContentFulfilled = (
  meta: DeleteTopicContentFulfilledAction['meta'],
): DeleteTopicContentFulfilledAction => ({
  type: CustomContentAndSourcesTypes.DELETE_CONTENT_FULFILLED,
  meta,
});

export const deleteTopicContentRejected = (
  payload: DeleteTopicContentRejectedAction['payload'],
  meta: DeleteTopicContentRejectedAction['meta'],
): DeleteTopicContentRejectedAction => ({
  type: CustomContentAndSourcesTypes.DELETE_CONTENT_REJECTED,
  payload,
  meta,
});

export const scrapeTopicContentUrl = (payload: TopicContentScrapeUrl['payload']): TopicContentScrapeUrl => ({
  type: CustomContentAndSourcesTypes.SCRAPE_URL,
  payload,
});

export const scrapeTopicContentUrlFulfilled = (
  payload: TopicContentScrapeUrlFulfilled['payload'],
  meta: TopicContentScrapeUrlFulfilled['meta'],
): TopicContentScrapeUrlFulfilled => ({
  type: CustomContentAndSourcesTypes.SCRAPE_URL_FULFILLED,
  payload,
  meta,
});

export const scrapeTopicContentUrlRejected = (
  payload: TopicContentScrapeUrlRejected['payload'],
  meta: TopicContentScrapeUrlRejected['meta'],
): TopicContentScrapeUrlRejected => ({
  type: CustomContentAndSourcesTypes.SCRAPE_URL_REJECTED,
  payload,
  meta,
});

export const updateTopicContentScrapedArticle = (payload: TopicContentUpdateScrapedArticle['payload']) => ({
  type: CustomContentAndSourcesTypes.UPDATE_SCRAPED_ARTICLE,
  payload,
});
