import { PropsWithChildren } from 'react';
import { css, styled } from 'styled-components';

type StyledProps = { clickable: boolean; isActive: boolean };

const StyledUIChip = styled.div<StyledProps>`
  transition: background-color 0.3s ease;
  color: ${({ isActive, theme }) => (isActive ? theme.colors.chipActiveText : theme.colors.chipText)};
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.chipActiveBackground : theme.colors.chipBackground};
  padding: 10px 20px;
  border-radius: 20px;
  margin: 10px 10px 0 0;
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
  border: 1px solid ${({ theme }) => theme.colors.chipBorder};
  display: inline-block;
  align-items: center;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  white-space: nowrap;
  text-overflow: ellipsis;
  /* we need to provide an explicit width, otherwise ellipsis wouldn't work */
  max-width: 250px;
  overflow: hidden;
  text-decoration: none;

  & > svg {
    margin-left: 0.5rem;
  }

  ${({ clickable, isActive, theme }) =>
    clickable &&
    css`
      &:hover {
        background-color: ${isActive ? theme.colors.chipActiveHoverBackground : theme.colors.chipHoverBackground};
      }
    `}
`;

type UIChipProps = PropsWithChildren & {
  className?: string;
  dataTestId?: string;
  disabled?: boolean;
  isActive?: boolean;
  onClick?: () => void;
};

export const UIChip = ({
  children,
  className,
  dataTestId,
  disabled = false,
  isActive = false,
  onClick,
}: UIChipProps) => (
  <StyledUIChip
    className={className}
    isActive={isActive}
    onClick={onClick}
    clickable={!disabled || !!onClick}
    data-test-id={dataTestId}
    data-test-is-active={isActive}
  >
    {children}
  </StyledUIChip>
);
