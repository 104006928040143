import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  html,
  body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  html,
  body,
  button,
  select,
  input,
  textarea {
    font-family: Akkurat, Helvetica, Arial, sans-serif;
  }

  body:fullscreen {
    overflow-y: auto;
  }
  body:-ms-fullscreen {
    overflow-y: auto;
  }
  body:-webkit-full-screen {
    overflow-y: auto;
  }
  body:-moz-full-screen {
    overflow-y: auto;
  }
  #root {
    height: 100%;
    position: inherit;
  }
`;
