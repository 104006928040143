import { put, select } from 'redux-saga/effects';

import { updateThemeMode } from 'domains/theme/actions';
import { getCurrentThemeMode } from 'domains/theme/selectors';
import { ThemeMode } from 'domains/theme/types';

const { localStorage } = window;

const getNextTheme = (currentTheme: ThemeMode): ThemeMode => {
  if (currentTheme === 'default') {
    return 'light';
  }
  return currentTheme === 'light' ? 'dark' : 'default';
};

export function* setThemeSaga() {
  try {
    const currentTheme: ThemeMode = yield select(getCurrentThemeMode);
    const newTheme = getNextTheme(currentTheme);
    localStorage.setItem('theme', newTheme);
    yield put(updateThemeMode({ mode: newTheme }));
  } catch {
    yield put(updateThemeMode({ mode: 'default' }));
  }
}
