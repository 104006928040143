import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { suggestionsFulfilled, suggestionsRejected } from 'domains/monitor/suggestions/actions';
import { Topic } from 'entities/topics/types';

// TODO: Replace suggestions with real suggestions endpoint,
// based on create-users already following topics, if no following topics
// default to highlights

export function* suggestionsSaga() {
  try {
    const payload: Topic[] = yield apiFetch('topic/curated/highlighted');
    yield put(suggestionsFulfilled(payload));
  } catch (e) {
    yield put(suggestionsRejected());
  }
}
