import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { togglePublicLinkSuccess, togglePublicLinkFailure } from 'domains/createShareSettings/actions';
import { TogglePublicLinkDefaultAction } from 'domains/createShareSettings/actionTypes';
import { activateModal } from 'domains/modal/actions';

export function* publicLinkSaga(action: TogglePublicLinkDefaultAction) {
  const { topicId, permission } = action.meta;
  const body = { setmap: permission, topic_id: topicId };
  try {
    yield apiFetch('mapshare', {
      method: 'PUT',
      body,
    });
    yield put(togglePublicLinkSuccess(action.meta));
  } catch (error) {
    yield put(togglePublicLinkFailure(action.meta));
    yield put(activateModal({ id: 'Error' }));
  }
}
