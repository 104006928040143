import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';

import { FilterButton, MasterDetailLayout } from 'common/components';
import { getPageId } from 'common/utilities';
import { trackEvent } from 'domains/analytics/actions';
import { getSiteLanguage } from 'domains/language/selectors';
import { useTranslations } from 'domains/language/useTranslations';
import { activateModal } from 'domains/modal/actions';
import { getTopicsFilterSelectedIds } from 'domains/monitor/filter/selectors';
import { fetchLatestKnowledge } from 'domains/monitor/latestKnowledge/actions';
import {
  getLatestKnowledgeHasNextPage,
  getLatestKnowledgeIsPending,
  getLatestKnowledgeItems,
  getLatestKnowledgeOffset,
} from 'domains/monitor/latestKnowledge/selectors';
import { KnowledgeDetail } from 'domains/publication/components/KnowledgeDetail';
import { KnowledgeList } from 'domains/publication/components/KnowledgeList';
import { getKnowledgeById } from 'entities/knowledge/selectors';
import { getFollowedTopics } from 'entities/topics/selectors';
import { getCanAccessMonitor } from 'entities/user/selectors';

export const LatestKnowledgePage = () => {
  const dispatch = useDispatch();
  const params = useParams<{ id?: string }>();
  const { textDictionary } = useTranslations();
  const items = useSelector(getLatestKnowledgeItems);
  const isPending = useSelector(getLatestKnowledgeIsPending);
  const offset = useSelector(getLatestKnowledgeOffset);
  const hasNextPage = useSelector(getLatestKnowledgeHasNextPage);
  const selectedTopics = useSelector(getTopicsFilterSelectedIds);
  const hasAccessToMonitor = useSelector(getCanAccessMonitor);
  const followingTopics = useSelector(getFollowedTopics);

  const language = useSelector(getSiteLanguage);

  const id = getPageId(params.id, items);
  const knowledge = useSelector(getKnowledgeById(id));

  useEffect(() => {
    if (items.length === 0) {
      dispatch(fetchLatestKnowledge({ offset: 0, filters: { topic: selectedTopics } }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, items.length, selectedTopics.length]);

  useEffect(() => {
    if (items.length !== 0) {
      dispatch(fetchLatestKnowledge({ offset: 0, filters: { topic: selectedTopics } }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedTopics.length, hasAccessToMonitor, language]);

  const knowledgeTitle = knowledge && knowledge.title;
  useEffect(() => {
    if (!params.id || !knowledgeTitle) {
      return;
    }
    dispatch(
      trackEvent({
        name: 'Browse page',
        page: 'Monitor',
        subpage: 'Knowledge detail',
        entityId: params.id,
        entityName: knowledgeTitle,
        entityType: 'Knowledge',
      }),
    );
  }, [dispatch, params.id, knowledgeTitle]);

  useEffect(() => {
    if (params.id) {
      return;
    }
    dispatch(
      trackEvent({
        name: 'Browse page',
        page: 'Monitor',
        subpage: 'Latest Knowledge',
      }),
    );
  }, [dispatch, params.id]);

  const loadNextPage = useCallback(() => {
    dispatch(
      fetchLatestKnowledge({
        offset: offset + 1,
        filters: { topic: selectedTopics },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, offset, selectedTopics]);

  if (!hasAccessToMonitor && !id) {
    return <Redirect to="/monitor" />;
  }

  const getToLink = (knowledgeId: string) => `/monitor/latest-knowledge/${knowledgeId}`;

  const list = (
    <KnowledgeList
      items={items}
      isPending={isPending}
      hasNextPage={hasNextPage}
      loadNextPage={loadNextPage}
      getToLink={getToLink}
      selectedId={id}
      lockTiles={!hasAccessToMonitor}
      trackPage="Knowledge detail"
      type="medium"
    />
  );

  const shareLink = knowledge?.id ? `${process.env.REACT_APP_WEBSITE_URL}${getToLink(knowledge.id)}` : undefined;

  const openFilterModal = () =>
    dispatch(
      activateModal({
        id: 'Monitor filter',
      }),
    );

  const filterButton =
    followingTopics.length !== 0 && hasAccessToMonitor ? (
      <FilterButton count={selectedTopics.length} onClick={openFilterModal} />
    ) : undefined;

  return (
    <MasterDetailLayout
      listTitle={textDictionary['app.monitor.latest_knowledge']}
      listComponent={list}
      listBackNavigation={{
        label: textDictionary['app.monitor.title'],
        path: '/monitor',
      }}
      listHeaderRightElement={filterButton}
      detailBackNavigation={{
        label: textDictionary['app.monitor.latest_knowledge'],
        path: '/monitor/latest-knowledge',
      }}
      mainView={params.id ? 'right' : 'left'}
    >
      <KnowledgeDetail knowledge={knowledge} shareLink={shareLink} id={id} />
    </MasterDetailLayout>
  );
};
