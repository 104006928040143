import { getTopicById } from 'entities/topics/selectors';
import { Topic } from 'entities/topics/types';
import { RootState } from 'types';

export const getFilteredTopics = (state: RootState): Topic[] =>
  state.domains.filteredTopics.ids
    .map((topicId) => getTopicById(state, topicId))
    .filter((topic) => topic !== undefined);

export const getFilteredTopicsAreFetching = (state: RootState) => state.domains.filteredTopics.isFetching;

export const getFilteredTopicsIsPossibleNextPage = (state: RootState) =>
  state.domains.filteredTopics.hasPossibleNextPage;
