import { ChangeEvent } from 'react';
import { css, styled } from 'styled-components';

const StyledContainer = styled.div`
  display: flex;
  padding-top: 1rem;
  align-items: center;
  width: 100%;
`;

const StyledTimingText = styled.span`
  font-weight: bold;
  font-size: 0.9rem;
`;

const thumbStyle = css`
  transition:
    background-color 0.3s,
    height 0.3s,
    width 0.3s;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.buttonOutlinedText};
  border-width: 2px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.topHeaderBarBackground};
  cursor: pointer;
`;

const thumbHoverStyle = css`
  width: 18px;
  height: 18px;
`;

type TrackProps = { bufferedValue: number; rangeValue: number };

const StyledTrack = styled.input.attrs<TrackProps>(({ rangeValue, bufferedValue, theme }) => ({
  style: {
    background: `linear-gradient(
      to right,
      ${theme.colors.buttonOutlinedText} 0%,
      ${theme.colors.buttonOutlinedText} ${rangeValue}%,
      ${theme.colors.whiteDark} ${rangeValue}%,
      ${theme.colors.whiteDark} ${bufferedValue}%,
      ${theme.colors.textDark} ${bufferedValue}%,
      ${theme.colors.textDark} 100%
    )`,
  },
}))<TrackProps>`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
  appearance: none;
  outline: none;
  border-radius: 5px;
  height: 10px;
  width: 100%;

  &::-webkit-slider-thumb {
    ${thumbStyle};
    appearance: none;
  }

  &::-moz-range-thumb {
    ${thumbStyle}
  }

  &::-ms-thumb {
    ${thumbStyle}
  }

  &:hover {
    &::-webkit-slider-thumb {
      ${thumbHoverStyle}
    }
    &::-moz-range-thumb {
      ${thumbHoverStyle}
    }
    &::-ms-thumb {
      ${thumbHoverStyle}
    }
  }
`;

const formatSecondsToTime = (time: number) => {
  const totalSeconds = Math.floor(time);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  return `${getIntAsTimeString(minutes)}:${getIntAsTimeString(seconds)}`;
};

const getIntAsTimeString = (time: number) => (time < 10 ? '0' : '') + time;

type AudioTrackProps = {
  bufferedTime?: number;
  currentTime: number;
  duration: number;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const AudioTrack = ({ currentTime, duration, onChange, bufferedTime }: AudioTrackProps) => {
  let modifierValue = 0;
  if (currentTime / duration < 0.2) {
    modifierValue = 1;
  } else if (currentTime / duration > 0.8) {
    modifierValue = -1;
  }
  const rangeValue = Math.floor((currentTime / duration) * 100 + modifierValue);
  const bufferedValue = bufferedTime
    ? Math.floor((bufferedTime / duration) * 100 + modifierValue)
    : Math.floor((currentTime / duration) * 100 + modifierValue);
  return (
    <StyledContainer>
      <StyledTimingText>{formatSecondsToTime(currentTime)}</StyledTimingText>
      <StyledTrack
        type="range"
        rangeValue={rangeValue}
        bufferedValue={bufferedValue}
        min={0}
        max={Math.floor(duration)}
        step={1}
        value={currentTime}
        onChange={onChange}
      />
      <StyledTimingText>{formatSecondsToTime(duration)}</StyledTimingText>
    </StyledContainer>
  );
};
