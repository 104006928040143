import { CaseStudyResponseObject, TopicCaseStudyPayload } from 'domains/caseStudy/types';

export enum TopicCaseStudyActionTypes {
  FETCH_CASE_STUDIES = '[Case Studies] Fetch Case Studies',
  FETCH_CASE_STUDIES_SUCCESS = '[Case Studies] Fetch Case Studies Success',
  FETCH_CASE_STUDIES_FAILURE = '[Case Studies] Fetch Case Studies Failure',
}

export type CaseStudyFetch = { payload: TopicCaseStudyPayload; type: TopicCaseStudyActionTypes.FETCH_CASE_STUDIES };

type CaseStudySuccess = {
  meta: TopicCaseStudyPayload;
  payload: { data: CaseStudyResponseObject[]; topicId: string };
  type: TopicCaseStudyActionTypes.FETCH_CASE_STUDIES_SUCCESS;
};

type CaseStudyFailure = { payload: { topicId: string }; type: TopicCaseStudyActionTypes.FETCH_CASE_STUDIES_FAILURE };
export type CaseStudyAction = CaseStudyFetch | CaseStudySuccess | CaseStudyFailure;
