import { SVGProps } from 'react';

import { constantColors } from 'domains/theme/constants/colors';

export const LabyrinthIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      d="M10.07 14.5a.703.703 0 00.703-.703v-2.941a3.454 3.454 0 013.45-3.45h6.25v7.656h-4.631v-3.891a.704.704 0 10-1.407 0v8.064a.705.705 0 101.407 0v-2.767h5.334a.703.703 0 00.703-.704V7.407h9.582v3.124h-6.236a.703.703 0 00-.703.704v12.408a.703.703 0 101.406 0V11.938h6.236a.702.702 0 00.704-.704V7.407h.92a3.454 3.454 0 013.45 3.449v1.187a.703.703 0 101.407 0v-1.187A4.862 4.862 0 0033.79 6H14.223a4.862 4.862 0 00-4.857 4.856v2.941a.703.703 0 00.704.703zM10.07 29.716a.703.703 0 00.703-.703V16.61a.703.703 0 00-1.406 0v12.403a.703.703 0 00.703.703z"
      fill="currentColor"
    />
    <path
      d="M37.942 17.382a.703.703 0 00-.703.704v2.04h-3.762a.703.703 0 00-.704.703v11.014h-10.83a.704.704 0 000 1.406h11.534a.704.704 0 00.704-.703V21.533h3.058v14.709a3.453 3.453 0 01-3.45 3.449h-8.217v-3.295a.705.705 0 00-.703-.704h-6.683v-9.096a.705.705 0 10-1.407 0v9.8a.702.702 0 00.703.703h6.683v2.592h-9.942a3.454 3.454 0 01-3.45-3.45v-1.187a.703.703 0 00-1.407 0v1.188a4.862 4.862 0 004.857 4.855H33.79a4.862 4.862 0 004.856-4.855V18.086a.704.704 0 00-.703-.704z"
      fill="currentColor"
    />
    <path
      d="M40.013 14.361H29.13a.703.703 0 00-.703.704v12.657h-6.53V22.39a.704.704 0 00-.704-.703h-7.42a.704.704 0 00-.704.703v8.938h-5.07A.704.704 0 108 32.735h5.772a.703.703 0 00.704-.704v-8.937h6.014v5.331a.703.703 0 00.703.703h7.937a.703.703 0 00.704-.703V15.768h10.179a.702.702 0 00.274-1.358.704.704 0 00-.274-.049z"
      fill="currentColor"
    />
    <path
      d="M2.984 28.811a2.985 2.985 0 100 5.97 2.985 2.985 0 000-5.97zm0 4.563a1.578 1.578 0 110-3.156 1.578 1.578 0 010 3.156zM46.496 14.828l1.31-1.31a.703.703 0 00-.994-.995l-1.31 1.31-1.31-1.31a.702.702 0 00-1.214.494.704.704 0 00.219.5l1.31 1.31-1.31 1.31a.704.704 0 00.994.995l1.31-1.31 1.31 1.31a.704.704 0 00.995-.994l-1.31-1.31z"
      fill={constantColors.blue}
    />
  </svg>
);
