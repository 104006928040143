import { PropsWithChildren } from 'react';
import { styled } from 'styled-components';

const StyledUITag = styled.div`
  background-color: ${({ theme }) => theme.colors.tagBackground};
  padding: 5px 9px;
  color: ${({ theme }) => theme.colors.black};
  margin: 0;
  font-weight: bold;
  border-radius: 5px;
  width: fit-content;
`;

type UITagProps = PropsWithChildren & {
  'data-test-id'?: string;
  'data-test-is-active'?: boolean;
  className?: string;
  onClick?: () => void;
};

export const UITag = (props: UITagProps) => <StyledUITag {...props} />;
