import { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';

import { AuthProvider } from 'AuthProvider';
import { darkTheme, lightTheme } from 'domains/theme/constants/theme';
import { useThemeAppearance } from 'domains/theme/useThemeAppearance';
import { ViewportProvider } from 'domains/viewport/components/ViewportProvider';
import { Main } from 'Main';

const initialisePingdom = () => {
  if (process.env.REACT_APP_PINGDOM_INSIGHTS_SRC) {
    const script = document.createElement('script');

    script.src = process.env.REACT_APP_PINGDOM_INSIGHTS_SRC;
    script.async = true;

    document.body.appendChild(script);
  }
};

export const App = () => {
  const theme = useThemeAppearance();

  useEffect(initialisePingdom, []);

  return (
    <ViewportProvider>
      <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
        <AuthProvider>
          <Main />
        </AuthProvider>
      </ThemeProvider>
    </ViewportProvider>
  );
};
