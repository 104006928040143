import { Link } from 'react-router-dom';
import { css, styled } from 'styled-components';

import { ImageBackground } from 'common/components';

export const ListImage = styled(ImageBackground)`
  transition: opacity;
  opacity: 1;
`;

export const ListLink = styled(Link)<{ isSelected?: boolean }>`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.textBright};
  display: block;

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      border: 1px solid ${theme.colors.textBright};
    `}
`;
