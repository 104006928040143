import { useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { UISwitch } from 'common/components';
import { Setter } from 'common/types/setter';
import { InfoTooltip } from 'domains/collection/components/edit/parts/Tooltip';
import { Permissions } from 'domains/collection/components/edit/types/permissions';
import { useTranslations } from 'domains/language/useTranslations';
import { getCanFeatureCollections, getCanListCollections } from 'entities/user/selectors';

const StyledRow = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.textBright};
  display: flex;
  font-weight: bold;
  gap: 1rem;
  justify-content: space-between;
  width: 100%;
`;

const StyledLabel = styled.div`
  display: flex;
  gap: 1rem;
  position: relative;
`;

const StyledInfoTooltip = styled(InfoTooltip)`
  font-weight: normal;
  height: 18px;
`;

type CollectionUpdatePermissionsProps = { permissions: Permissions; setPermissions: Setter<Permissions> };

export const CollectionUpdatePermissions = ({ permissions, setPermissions }: CollectionUpdatePermissionsProps) => {
  const canFeature = useSelector(getCanFeatureCollections);
  const canList = useSelector(getCanListCollections);
  const { textDictionary } = useTranslations();

  const { isFeatured, isListed, isPublic } = permissions;

  const toggleIsFeatured = () =>
    setPermissions({ isFeatured: !isFeatured, isListed, isPublic: !(isFeatured || isListed) || isPublic });

  const toggleIsListed = () =>
    setPermissions({ isFeatured, isListed: !isListed, isPublic: !(isFeatured || isListed) || isPublic });

  const toggleIsPublic = () =>
    setPermissions({ isFeatured: !isPublic && isFeatured, isListed: !isPublic && isListed, isPublic: !isPublic });

  return (
    <>
      {canFeature && (
        <StyledRow>
          <StyledLabel>
            <div>{textDictionary['app.create.collection.featured']}</div>
            <StyledInfoTooltip maxTooltipWidth={240}>
              {textDictionary['app.create.collection.featured.tooltip']}
            </StyledInfoTooltip>
          </StyledLabel>
          <UISwitch isOn={isFeatured} onClick={toggleIsFeatured}></UISwitch>
        </StyledRow>
      )}
      {canList && (
        <StyledRow>
          <StyledLabel>
            <div>{textDictionary['app.create.collection.listed']}</div>
            <StyledInfoTooltip maxTooltipWidth={240}>
              {textDictionary['app.create.collection.listed.tooltip']}
            </StyledInfoTooltip>
          </StyledLabel>
          <UISwitch isOn={isListed} onClick={toggleIsListed}></UISwitch>
        </StyledRow>
      )}
      <StyledRow>
        <StyledLabel>
          <div>{textDictionary['app.create.collection.public']}</div>
          <StyledInfoTooltip maxTooltipWidth={272}>
            {textDictionary['app.create.collection.public.tooltip']}
          </StyledInfoTooltip>
        </StyledLabel>
        <UISwitch isOn={isPublic} onClick={toggleIsPublic}></UISwitch>
      </StyledRow>
    </>
  );
};
