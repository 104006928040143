import {
  TopicPublicationActionTypes,
  TopicPublicationApplySearchCriteria,
  TopicPublicationClearSearchCriteria,
} from 'domains/publication/actionTypes';

export const applyPublicationSearchCriteria = (
  payload?: TopicPublicationApplySearchCriteria['payload'],
): TopicPublicationApplySearchCriteria => ({
  type: TopicPublicationActionTypes.APPLY_SEARCH_CRITERIA,
  payload,
});

export const clearPublicationSearchCriteria = (): TopicPublicationClearSearchCriteria => ({
  type: TopicPublicationActionTypes.CLEAR_SEARCH_CRITERIA,
});
