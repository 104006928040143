import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { searchTopicSuccess, searchTopicRejected } from 'domains/search/actions';
import { FetchTopicsSearchDefaultAction } from 'domains/search/actionTypes';
import { TopicSearchResult } from 'domains/search/types';

export function* fetchTopicSearchSaga({ meta }: FetchTopicsSearchDefaultAction) {
  if (!meta.query) {
    return;
  }
  try {
    const { query, limit = 8 } = meta;
    const response: {
      result: TopicSearchResult[];
    } = yield apiFetch(`topic/search/${encodeURIComponent(query)}`, {
      queryParams: { limit },
    });
    yield put(searchTopicSuccess(meta, response.result));
  } catch (error) {
    yield put(searchTopicRejected(meta));
  }
}
