import { SVGProps } from 'react';

export const ThemeDarkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      d="M18 4.8c10.584 0 19.2 8.616 19.2 19.2S28.584 43.2 18 43.2c-.816 0-1.632-.048-2.424-.168C20.16 37.848 22.8 31.08 22.8 24s-2.64-13.848-7.224-19.032c.792-.12 1.608-.168 2.424-.168zM18 0C13.632 0 9.528 1.2 6 3.24 13.176 7.392 18 15.12 18 24S13.176 40.608 6 44.76A23.936 23.936 0 0018 48c13.248 0 24-10.752 24-24S31.248 0 18 0z"
      fill="currentColor"
    />
  </svg>
);
