import { RefObject, useEffect } from 'react';

export const useOutsideClickDetection = (ref: RefObject<any>, callback: () => void) => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (event.target && !ref.current?.contains(event.target)) {
        callback();
      }
    }
    document.addEventListener('click', handleClickOutside, { capture: true });
    return () => {
      document.removeEventListener('click', handleClickOutside, {
        capture: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
