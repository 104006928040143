import { CustomContent, FileContent, NewCustomKnowledge, NewDocument } from 'domains/customContentAndSources/types';
import { ErrorAction, ErrorMeta } from 'types';

export enum CustomContentAndSourcesTypes {
  FETCH_TOPIC_CONTENT_LIST = '[Custom Content] - Fetch Topic Content List',
  FETCH_TOPIC_CONTENT_LIST_FULFILLED = '[Custom Content] - Fetch Topic Content List Fulfilled',
  FETCH_TOPIC_CONTENT_LIST_REJECTED = '[Custom Content] - Fetch Topic Content List Rejected',
  UPLOAD_CONTENT_ATTACHMENT = '[Custom Content] - Upload Topic Content',
  UPLOAD_CONTENT_ATTACHMENT_FULFILLED = '[Custom Content] - Upload Topic Content Fulfilled',
  UPLOAD_CONTENT_ATTACHMENT_REJECTED = '[Custom Content] - Upload Topic Content Rejected',
  DELETE_CONTENT = '[Custom Content] - Delete Content Item',
  DELETE_CONTENT_FULFILLED = '[Custom Content] - Delete Item Fulfilled',
  DELETE_CONTENT_REJECTED = '[Custom Content] - Delete Item Rejected',
  SET_FILE = '[Custom Content] - Set File',
  SET_FILE_CONTENT = '[Custom Content] - Set File Content',
  SCRAPE_URL = '[Custom Content] - Scrape Url',
  SCRAPE_URL_FULFILLED = '[Custom Content] - Scrape Url Fulfilled',
  SCRAPE_URL_REJECTED = '[Custom Content] - Scrape Url Rejected',
  UPDATE_SCRAPED_ARTICLE = '[Custom Content] - Update Scraped Article',
}

export type TopicContentListFetchPayload = { limit?: number; offset?: number; topicId: string };

export type TopicContentListFetchAction = {
  payload: TopicContentListFetchPayload;
  type: CustomContentAndSourcesTypes.FETCH_TOPIC_CONTENT_LIST;
};

export type TopicContentListFetchFulfilledAction = {
  meta: TopicContentListFetchPayload;
  payload: CustomContent[];
  type: CustomContentAndSourcesTypes.FETCH_TOPIC_CONTENT_LIST_FULFILLED;
};

export type TopicContentListFetchRejectedAction = ErrorAction & {
  meta: ErrorMeta & TopicContentListFetchPayload;
  type: CustomContentAndSourcesTypes.FETCH_TOPIC_CONTENT_LIST_REJECTED;
};

export type SetTopicContentFile = {
  payload:
    | Pick<NewDocument, 'id' | 'file' | 'filename' | 'fileType' | 'isFileTooBig'>
    | Pick<NewDocument, 'file'>
    | Pick<NewDocument, 'filename'>
    | undefined;
  type: CustomContentAndSourcesTypes.SET_FILE;
};

export type SetTopicContentFileContent = { payload: FileContent; type: CustomContentAndSourcesTypes.SET_FILE_CONTENT };

export type UploadTopicContentAction = {
  payload: { topicId: string };
  type: CustomContentAndSourcesTypes.UPLOAD_CONTENT_ATTACHMENT;
};

type KnowledgeContentUpdateBody = { id: string; publisher?: string; summary?: string; title?: string };
type FileContentUpdateBody = { filename: string; id: string };

export type UploadTopicContentFulfilledAction = {
  meta: { topicId: string; updateBody?: FileContentUpdateBody | KnowledgeContentUpdateBody };
  type: CustomContentAndSourcesTypes.UPLOAD_CONTENT_ATTACHMENT_FULFILLED;
};

export type UploadTopicContentRejectedAction = ErrorAction & {
  meta: ErrorMeta & { topicId: string };
  type: CustomContentAndSourcesTypes.UPLOAD_CONTENT_ATTACHMENT_REJECTED;
};

export type DeleteTopicContentAction = {
  payload: { id: string; topicId: string };
  type: CustomContentAndSourcesTypes.DELETE_CONTENT;
};

export type DeleteTopicContentFulfilledAction = {
  meta: { id: string; topicId: string };
  type: CustomContentAndSourcesTypes.DELETE_CONTENT_FULFILLED;
};

export type DeleteTopicContentRejectedAction = ErrorAction & {
  meta: ErrorMeta & { id: string; topicId: string };
  type: CustomContentAndSourcesTypes.DELETE_CONTENT_REJECTED;
};

export type TopicContentScrapeUrl = { payload: { url: string }; type: CustomContentAndSourcesTypes.SCRAPE_URL };

export type TopicContentScrapeUrlFulfilled = {
  meta: { url: string };
  payload: NewCustomKnowledge;
  type: CustomContentAndSourcesTypes.SCRAPE_URL_FULFILLED;
};

export type TopicContentScrapeUrlRejected = ErrorAction & {
  meta: ErrorMeta & { url: string };
  type: CustomContentAndSourcesTypes.SCRAPE_URL_REJECTED;
};

export type TopicContentUpdateScrapedArticle = {
  payload: Partial<Pick<NewCustomKnowledge, 'title' | 'publisher' | 'summary'>> | undefined;
  type: CustomContentAndSourcesTypes.UPDATE_SCRAPED_ARTICLE;
};
