import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { fetchFollowedTopicsFailure, fetchFollowedTopicsSuccess } from 'entities/topics/actions';
import { FetchFollowedTopicsListDefaultAction } from 'entities/topics/actionTypes';
import { Topic } from 'entities/topics/types';
import { isFetchError } from 'TypeCheckMethods';

export function* fetchFollowedTopicsSaga(action: FetchFollowedTopicsListDefaultAction) {
  try {
    const { limit, offset } = action.meta;
    const topics: Topic[] = yield apiFetch(`topic/following`, {
      queryParams: { offset, limit },
    });
    yield put(fetchFollowedTopicsSuccess({ topics, limit, offset }));
  } catch (error) {
    if (isFetchError(error)) {
      yield put(fetchFollowedTopicsFailure({ error }, { logoutOnAuthError: true }));
    }
  }
}
