import { DigDeeperKnowledge } from 'domains/clusters/types';
import { Knowledge } from 'entities/knowledge/types';
import { RootState } from 'types';

export const getClusterById = (id?: string) => (state: RootState) => (id ? state.domains.clusters.data[id] : undefined);

const getDigDeeperForCluster = (state: RootState, id: string): DigDeeperKnowledge => {
  const cluster = state.domains.clusters.data[id];
  return (cluster && cluster.digDeeperKnowledge) || {};
};

export const getDigDeeperItems =
  (id?: string) =>
  (state: RootState): Knowledge[] => {
    if (!id) {
      return [];
    }
    const digDeeper = getDigDeeperForCluster(state, id);
    return (digDeeper.ids || []).map((knowledgeId) => state.entities.knowledge.byId[knowledgeId]);
  };

export const getDigDeeperIsPending = (id?: string) => (state: RootState) => {
  if (!id) {
    return false;
  }
  const digDeeper = getDigDeeperForCluster(state, id);
  return digDeeper.isPending || false;
};

export const getDigDeeperOffset = (id?: string) => (state: RootState) => {
  if (!id) {
    return 0;
  }
  const digDeeper = getDigDeeperForCluster(state, id);
  return digDeeper.offset || 0;
};

export const getDigDeeperHasNextPage = (id?: string) => (state: RootState) => {
  if (!id) {
    return false;
  }
  const digDeeper = getDigDeeperForCluster(state, id);
  return digDeeper.hasNextPage || false;
};
