import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

import { ChevronIcon, StyledGrid, InfinityLoader, linkStyles, SectionTitleUnderlined } from 'common/components';
import { breakpoints, mediaStyles } from 'common/styles';
import { CreateNewMapSection } from 'domains/create/landing/CreateNewMapSection';
import { TopicSection } from 'domains/discover/components/TopicSection';
import { getSiteLanguage } from 'domains/language/selectors';
import { useTranslations } from 'domains/language/useTranslations';
import { TopicTile } from 'domains/topic/components/topicTile/TopicTile';
import { fetchMyMaps, fetchSharedWithMe } from 'entities/topics/actions';
import {
  getCustomTopicsCreatedByUser,
  getCustomTopicsSharedWithUser,
  getMyMapsTopicsHasNextPage,
  getMyMapsTopicsIsPending,
} from 'entities/topics/selectors';
import { getCanAccessCreate } from 'entities/user/selectors';

const StyledCarouselContainer = styled.div`
  ${mediaStyles(({ spacing }) => `margin: 2rem ${spacing}rem 0;`)};
`;

const StyledLink = styled(Link)`
  ${linkStyles}
`;

const StyledArrow = styled(ChevronIcon)`
  color: ${({ theme }) => theme.colors.textBright};
  height: 16px;
  padding-left: 0.5rem;
  width: 16px;
`;

const StyledGridContainer = styled.div`
  margin: 1rem;
  padding-bottom: 1rem;

  @media ${breakpoints.medium} {
    margin: 1rem 2rem 2rem;
  }

  @media ${breakpoints.extraLarge} {
    margin: 1rem 3rem 3rem;
  }
`;

export const CreateMapsTab = () => {
  const dispatch = useDispatch();
  const canAccessCreate = useSelector(getCanAccessCreate);
  const myTopics = useSelector(getCustomTopicsCreatedByUser());
  const sharedTopics = useSelector(getCustomTopicsSharedWithUser(8));
  const hasNextPage = useSelector(getMyMapsTopicsHasNextPage);
  const isPending = useSelector(getMyMapsTopicsIsPending);
  const selectedLanguage = useSelector(getSiteLanguage);
  const { textDictionary } = useTranslations();

  const fetchMyMapsTopics = (shouldInvalidate: boolean = false) =>
    isPending ? undefined : dispatch(fetchMyMaps({ offset: shouldInvalidate ? 0 : myTopics.length, limit: 20 }));

  useEffect(() => {
    if (!canAccessCreate) return;
    fetchMyMapsTopics(true);
    dispatch(fetchSharedWithMe({ limit: 8, offset: 0 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canAccessCreate, selectedLanguage]);

  return (
    <>
      <CreateNewMapSection />
      {sharedTopics.length > 0 && (
        <StyledCarouselContainer>
          <TopicSection
            title={
              <StyledLink to="/shared">
                {textDictionary['app.create.shared_with_me.title']}
                <StyledArrow />
              </StyledLink>
            }
            topics={sharedTopics}
          />
        </StyledCarouselContainer>
      )}
      {myTopics.length > 0 && (
        <InfinityLoader isPending={isPending} loadNextPage={fetchMyMapsTopics} hasNextPage={hasNextPage}>
          <StyledGridContainer>
            <SectionTitleUnderlined>{textDictionary['app.create.my_maps.title']}</SectionTitleUnderlined>
            <StyledGrid numberOfItems={[1, 2, 3, 4, 5]}>
              {myTopics.map(({ id, ...rest }) => (
                <TopicTile
                  {...rest}
                  hideOwnershipLabel={true}
                  id={id}
                  key={id}
                  navigationDestination={{ params: { id }, to: 'create' }}
                />
              ))}
            </StyledGrid>
          </StyledGridContainer>
        </InfinityLoader>
      )}
    </>
  );
};
