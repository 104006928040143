export enum FetchFilteredTopicsActionsTypes {
  FETCH_FILTERED_TOPICS = '[Topic Search] Fetch Filtered Topics',
  FETCH_FILTERED_TOPICS_SUCCESS = '[Topic Search] Fetch Filtered Topics Success',
  FETCH_FILTERED_TOPICS_FAILURE = '[Topic Search] Fetch Filtered Topics Failure',
  CLEAR_FILTERED_TOPICS = '[Topic Search] Clear Filtered Topics',
}

export type FetchFilteredTopicMeta = { filterTerm: string; limit: number; offset: number };
export type FetchFilteredTopicSuccessMeta = { filterTerm: string; limit: number; offset: number; topicIds: string[] };

export type FetchFilteredTopicsAction = {
  meta: FetchFilteredTopicMeta;
  type: FetchFilteredTopicsActionsTypes.FETCH_FILTERED_TOPICS;
};

export type FetchFilteredTopicSuccessAction = {
  meta: FetchFilteredTopicSuccessMeta;
  type: FetchFilteredTopicsActionsTypes.FETCH_FILTERED_TOPICS_SUCCESS;
};

export type FetchFilteredTopicsFailureAction = { type: FetchFilteredTopicsActionsTypes.FETCH_FILTERED_TOPICS_FAILURE };
export type ClearFilteredTopicsAction = { type: FetchFilteredTopicsActionsTypes.CLEAR_FILTERED_TOPICS };

export type FetchFilteredTopicsActions =
  | FetchFilteredTopicsAction
  | FetchFilteredTopicSuccessAction
  | FetchFilteredTopicsFailureAction
  | ClearFilteredTopicsAction;
