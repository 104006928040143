import { InfinityLoader } from 'common/components';
import { BookmarkItem } from 'domains/bookmarks/components/BookmarkItem';
import { Cluster } from 'domains/clusters/types';
import { Knowledge } from 'entities/knowledge/types';

type BookmarkListProps = {
  hasNextPage: boolean;
  isPending: boolean;
  items: (Cluster | Knowledge)[];
  loadNextPage: () => void;
  selectedId?: string;
};

export const BookmarkList = ({ items, hasNextPage, isPending, selectedId, loadNextPage }: BookmarkListProps) => (
  <InfinityLoader isPending={isPending} hasNextPage={hasNextPage} loadNextPage={loadNextPage}>
    {items.map((item) => (
      <BookmarkItem key={item.id} isSelected={selectedId === item.id} bookmark={item} />
    ))}
  </InfinityLoader>
);
