import { styled } from 'styled-components';

import { breakpoints } from 'common/styles';

const StyledSplash = styled.div`
  display: flex;
  gap: 1rem;

  @media ${breakpoints.medium} {
    gap: 3rem;
  }
`;

const StyledCaption = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.textBright};
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  > :last-child {
    max-width: 320px;
  }
`;

export const SplashStyles = { StyledSplash, StyledCaption, StyledColumn };
