import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { loadCustomKeyIssueFailure, loadCustomKeyIssueSuccess } from 'domains/customKeyIssue/actions';
import { LoadCustomKeyIssueDefaultAction } from 'domains/customKeyIssue/actionTypes';
import { KeyIssue } from 'entities/keyIssues/types';

export function* loadCustomKeyIssueSaga({ meta }: LoadCustomKeyIssueDefaultAction) {
  try {
    const keyIssue: KeyIssue = yield apiFetch(`dimension/${meta.id}`);
    yield put(loadCustomKeyIssueSuccess(keyIssue, meta));
  } catch (error) {
    yield put(loadCustomKeyIssueFailure(meta));
  }
}
