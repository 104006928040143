import { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { styled } from 'styled-components';

import { LazyComponent } from 'common/components/lazy/LazyComponent';
import { CreateMigrateToNewRoutes } from 'domains/create/CreateMigrateToNewRoutes';
import { CreateLanding } from 'domains/create/landing/CreateLanding';
import { DiscoverPage } from 'domains/discover/components/DiscoverPage';

const TopicDetailRoutes = lazy(() => import('domains/topicDetail/components/TopicDetailRoutes'));

const StyledContainer = styled.div`
  height: 100%;
  overflow-y: hidden;
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
`;

export const TopicRoutes = () => (
  <StyledContainer>
    <Switch>
      <Route exact={true} path="/create/map/:topicId?">
        <CreateMigrateToNewRoutes />
      </Route>
      <Route path={['/topics/:topicId', '/discover/:topicId', '/create/new', '/create/:topicId']}>
        <LazyComponent>
          <TopicDetailRoutes />
        </LazyComponent>
      </Route>
      <Route exact={true} path={['/discover', '/topics']}>
        <DiscoverPage />
      </Route>
      <Route exact={true} path="/create">
        <CreateLanding />
      </Route>
    </Switch>
  </StyledContainer>
);
