import debounce from 'lodash.debounce';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { ErrorMessage, UIButton, SectionTitleUnderlined, SelectInput } from 'common/components';
import { ClearInput } from 'common/components/form/ClearInput';
import { SelectInputOption } from 'common/components/form/SelectInput';
import { TextArea } from 'common/components/form/TextArea';
import { addNewCustomKeyIssueToMap } from 'domains/createMapDetail/actions';
import { getCurrentMap, getCurrentMapIsNew } from 'domains/createMapDetail/selectors';
import {
  createCustomKeyIssue,
  fetchTopicAndTagSuggestions,
  resetTopicSearchIdsForQuery,
  searchTopicForCustomKeyIssue,
} from 'domains/customKeyIssue/actions';
import { CustomKeyIssueTopicAndTagSuggestionsAction } from 'domains/customKeyIssue/actionTypes';
import { FingerprintBuilder } from 'domains/customKeyIssue/components/FingerprintBuilder';
import { StyledSubtitle } from 'domains/customKeyIssue/components/shared';
import {
  getCurrentCustomKeyIssueIsPending,
  getCustomKeyIssueFingerprint,
  getCustomKeyIssueFingerprintHasErrored,
  getCustomKeyIssueFingerprintIsPending,
  getCustomKeyIssueFPKnowledgeIds,
  getCustomKeyIssueFPKnowledgeIsPending,
  getCustomKeyIssueIsUploading,
  getCustomKeyIssueTopicList,
  getCustomKeyIssueTopicListIsPending,
  getCustomKeyIssueTopicSuggestions,
  getCustomKeyIssueUploadHasErrored,
  getPreEditedCustomKeyIssue,
} from 'domains/customKeyIssue/selectors';
import { CustomKeyIssueMeta } from 'domains/customKeyIssue/types';
import { useTranslations } from 'domains/language/useTranslations';

const StyledCharLimitLabel = styled.p<{ valid: boolean }>`
  display: flex;
  justify-content: flex-end;
  font-size: 0.8rem;
  color: ${({ theme, valid }) => (valid ? theme.colors.textFade : theme.colors.red)};
`;

const StyledInput = styled(ClearInput)`
  margin-bottom: 1rem;
`;

const StyledSectionTitleUnderlined = styled(SectionTitleUnderlined)`
  margin-top: 2rem;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-bottom: 0;
`;

const TITLE_CHAR_LIMIT = 60;

export const AddCustomKeyIssue = () => {
  const dispatch = useDispatch();
  const { textDictionary } = useTranslations();
  const currentMap = useSelector(getCurrentMap);
  const keyIssue = useSelector(getPreEditedCustomKeyIssue);
  const isFetchingFingerprint = useSelector(getCustomKeyIssueFingerprintIsPending);
  const hasFingerprintFailed = useSelector(getCustomKeyIssueFingerprintHasErrored);
  const hasUploadFailed = useSelector(getCustomKeyIssueUploadHasErrored);

  const [title, setTitle] = useState(keyIssue?.name || '');
  const [subtitle, setSubtitle] = useState(keyIssue?.subtitle || '');
  const [description, setDescription] = useState(keyIssue?.description || '');
  const [selectedTopics, setSelectedTopics] = useState<SelectInputOption[]>(() =>
    (keyIssue?.topics || []).map((topic) => ({
      label: topic.name,
      value: topic.id,
    })),
  );
  const [selectedTags, setSelectedTags] = useState<SelectInputOption[]>(() =>
    (keyIssue?.mappings || []).map(({ id, name }) => ({
      label: name,
      value: id,
    })),
  );

  const topics = useSelector(getCustomKeyIssueTopicList);
  const isTopicsPending = useSelector(getCustomKeyIssueTopicListIsPending);
  const isUploading = useSelector(getCustomKeyIssueIsUploading);
  const isPending = useSelector(getCurrentCustomKeyIssueIsPending);
  const isFetchingFPKnowledge = useSelector(getCustomKeyIssueFPKnowledgeIsPending);
  const fingerprint = useSelector(getCustomKeyIssueFingerprint);
  const fpKnowledge = useSelector(getCustomKeyIssueFPKnowledgeIds);
  const isNewCustomMap = useSelector(getCurrentMapIsNew);
  const storedTopicSuggestions = useSelector(getCustomKeyIssueTopicSuggestions);
  const topicSuggestions = storedTopicSuggestions
    .filter(({ id }) => !selectedTopics.some(({ value }) => value === id))
    .slice(0, 5);

  const validateTitleCharLength = title.length <= TITLE_CHAR_LIMIT;

  const onTextChange = (type: 'title' | 'subtitle' | 'description', value: string, shouldDebounce: boolean = true) => {
    if (type === 'title') {
      setTitle(value);
    } else if (type === 'subtitle') {
      setSubtitle(value);
    } else {
      setDescription(value);
    }
    const payload = {
      title,
      subtitle,
      description,
      [type]: value,
    };
    if (shouldDebounce) {
      debouncedTopicAndTagSuggestion(payload);
    } else {
      dispatch(fetchTopicAndTagSuggestions(payload));
    }
  };

  const onChange = (type: 'title' | 'subtitle') => (event: ChangeEvent<HTMLInputElement>) => {
    onTextChange(type, event.target.value);
  };

  const onDescriptionChange = (value: string) => {
    onTextChange('description', value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedTopicAndTagSuggestion = useCallback(
    debounce((payload: CustomKeyIssueTopicAndTagSuggestionsAction['payload']) => {
      dispatch(fetchTopicAndTagSuggestions(payload));
    }, 500),
    [dispatch],
  );

  const onAddTopicSuggestion = (item: SelectInputOption) => {
    const selectedTopicsWithoutItem = selectedTopics.filter(({ value }) => value !== item.value);
    setSelectedTopics([...selectedTopicsWithoutItem, item]);
  };

  const onClearTitle = () => onTextChange('title', '', false);
  const onClearSubtitle = () => onTextChange('subtitle', '', false);

  const onMapSelected = (value: SelectInputOption) => {
    setSelectedTopics([...selectedTopics, value]);
  };

  const onTopicsRemoved = (removedItem: SelectInputOption) => {
    setSelectedTopics(selectedTopics.filter((item) => item.value !== removedItem.value));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchTopicsHandle = useCallback(
    debounce((query: string) => {
      if (!query) {
        return;
      }
      dispatch(searchTopicForCustomKeyIssue({ context: currentMap?.id, query }));
    }, 500),
    [dispatch],
  );

  const onSubmit = () => {
    const body: CustomKeyIssueMeta = {
      id: keyIssue?.id || `${Math.random()}`.replace('.', '_'),
      name: title,
      subtitle,
      description,
      topics: selectedTopics.map((option) => ({
        id: option.value,
        name: option.label,
      })),
      mappings: selectedTags.map((item) => ({
        id: item.value,
        name: item.label,
      })),
      isCustomDimensionLoaded: true,
    };

    if (isNewCustomMap) {
      const newBody = {
        ...body,
        fp: fingerprint,
        knowledge: fpKnowledge,
      };
      dispatch(addNewCustomKeyIssueToMap(newBody));
    } else {
      dispatch(createCustomKeyIssue(body));
    }
  };

  useEffect(() => {
    dispatch(resetTopicSearchIdsForQuery());
  }, [dispatch]);

  useEffect(() => {
    if (!keyIssue?.id || !keyIssue?.isCustomDimensionLoaded) {
      return;
    }
    dispatch(
      fetchTopicAndTagSuggestions({
        title: keyIssue.name || '',
        subtitle: keyIssue.subtitle || '',
        description: keyIssue.description || '',
      }),
    );
    setSelectedTopics(
      (keyIssue.topics || []).map((topic) => ({
        label: topic.name,
        value: topic.id,
      })),
    );
    setSelectedTags(
      (keyIssue.mappings || []).map(({ id, name }) => ({
        label: name,
        value: id,
      })),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyIssue?.id, keyIssue?.isCustomDimensionLoaded]);

  const isValid =
    !!title &&
    validateTitleCharLength &&
    selectedTopics.length !== 0 &&
    selectedTags.length !== 0 &&
    !isFetchingFingerprint &&
    !isFetchingFPKnowledge &&
    !hasFingerprintFailed;

  const isFieldsDisabled = isPending || isUploading;

  const getButtonText = () => {
    if (isNewCustomMap) {
      const buttonText = keyIssue?.id ? 'app.create.create_map.update' : 'app.create.create_map.add';

      return textDictionary[buttonText];
    }
    const buttonText = keyIssue?.id ? 'app.create.create_map.save' : 'app.custom_key_issue.add_and_save';

    return textDictionary[buttonText];
  };

  return (
    <>
      <StyledCharLimitLabel valid={validateTitleCharLength}>
        {title.length}/{TITLE_CHAR_LIMIT}
      </StyledCharLimitLabel>
      <StyledInput
        name="key-issue-title"
        label={textDictionary['app.custom_key_issue.title']}
        value={title}
        onChange={onChange('title')}
        autoComplete="off"
        onClearHandle={onClearTitle}
        disabled={isFieldsDisabled}
        dataTestId={'add-custom-key-issue-title-input'}
      />
      <StyledInput
        name="subtitle"
        label={textDictionary['app.custom_key_issue.subtitle']}
        value={subtitle}
        onChange={onChange('subtitle')}
        onClearHandle={onClearSubtitle}
        disabled={isFieldsDisabled}
        dataTestId={'add-custom-key-issue-subtitle-input'}
      />
      <TextArea
        placeholder={textDictionary['app.custom_key_issue.description']}
        value={description}
        onValueChange={onDescriptionChange}
        disabled={isFieldsDisabled}
        dataTestId={'add-custom-key-issue-description-textarea'}
      />
      <StyledSectionTitleUnderlined>
        {textDictionary['app.custom_key_issue.connect_to_maps']}
      </StyledSectionTitleUnderlined>
      <StyledSubtitle>{textDictionary['app.custom_key_issue.search_topics.help_text']}</StyledSubtitle>
      <SelectInput
        label={textDictionary['app.search.placeholder']}
        defaultItems={selectedTopics}
        dropdownOptions={topics.map((topic) => ({
          label: topic.name,
          value: topic.id,
          description: topic.description,
          images: topic.images,
        }))}
        suggestionOptions={topicSuggestions.map(({ id, name }) => ({
          label: name,
          value: id,
        }))}
        onAddSuggestion={onAddTopicSuggestion}
        placeholder={textDictionary['app.custom_key_issue.search_topics.explainer']}
        isPending={isTopicsPending}
        onItemSelect={onMapSelected}
        onInputChange={searchTopicsHandle}
        onItemRemove={onTopicsRemoved}
        disabled={isFieldsDisabled}
        dataTestId={'add-custom-key-issue-connected-map-selector'}
      />
      <FingerprintBuilder
        name={title}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        disabled={isFieldsDisabled}
      />
      <StyledErrorMessage isActive={hasUploadFailed}>{textDictionary['app.auth.error']}</StyledErrorMessage>
      <UIButton
        data-test-id="submit-new-custom-key-issue-button"
        disabled={!isValid || isPending}
        fullwidth
        isLoading={isUploading || isFetchingFingerprint || isFetchingFPKnowledge}
        onClick={onSubmit}
        style={{ margin: '1rem 0' }}
      >
        {getButtonText()}
      </UIButton>
    </>
  );
};
