import { SVGProps } from 'react';

export const ShareIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props} fill="currentColor" stroke="none">
    <path
      d="M18 1c-.56 0-1-.44-1-1v-6a.988.988 0 0 0-.293-.707A.993.993 0 0 0 16-7H2a.988.988 0 0 0-.707.293A.993.993 0 0 0 1-6v6C1 .56.56 1 0 1s-1-.44-1-1v-6c0-.406.079-.796.236-1.168.152-.36.366-.677.643-.953.276-.277.594-.491.953-.643A2.968 2.968 0 0 1 2-9h14c.406 0 .796.079 1.168.236.36.152.677.366.953.643.277.276.49.594.643.953.157.372.236.762.236 1.168v6c0 .56-.44 1-1 1z"
      transform="rotate(180 9.5 5.5)"
    />
    <path
      d="M6 1c-.56 0-1-.44-1-1v-9.586L.707-5.293a.99.99 0 0 1-1.414 0 .99.99 0 0 1 0-1.414l6-6a1.002 1.002 0 0 1 1.414 0l6 6a.99.99 0 0 1 0 1.414.99.99 0 0 1-1.414 0L7-9.586V0c0 .56-.44 1-1 1z"
      transform="translate(4 13)"
    />
  </svg>
);
