import { ReactNode, memo, MouseEvent } from 'react';
import { styled } from 'styled-components';

import { ImageFallback } from 'common/components/image/ImageFallback';
import { useImageLoader } from 'common/components/image/useImageLoader';

type StyledProps = { error?: boolean; isImageLoaded?: boolean; src?: string };

const StyledImage = styled.img<StyledProps>`
  opacity: ${({ isImageLoaded }) => (isImageLoaded ? 1 : 0)};
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: opacity 0.3s ease;
`;

export type ImageProps = {
  alt: string;
  className?: string;
  onClick?: (event: MouseEvent) => void;
  renderPlaceholder?: () => ReactNode;
  src?: string;
};

export const Image = memo(({ src, alt, renderPlaceholder, className, onClick }: ImageProps) => {
  const { internalSrc, useFallback, isImageLoaded } = useImageLoader({ src });

  if (useFallback) {
    return <ImageFallback renderPlaceholder={renderPlaceholder} onClick={onClick} className={className} />;
  } else if (isImageLoaded) {
    return (
      <StyledImage alt={alt} src={internalSrc} isImageLoaded={isImageLoaded} className={className} onClick={onClick} />
    );
  }
  return null;
});

Image.displayName = 'Image';
