import { isSameSearchCriteria } from 'domains/publication/reducer';
import { getAdvancedKnowledgeCriteria } from 'domains/publication/selectors';
import { getTransformationMapSelectedOuterNode } from 'domains/transformationMap/selectors';
import { Knowledge } from 'entities/knowledge/types';
import { RootState } from 'types';

const getKnowledgeDataStateForTopic = (state: RootState, topicId: string) => {
  const { offset: _offset, limit: _limit, ...initialCriteria } = getKnowledgeDataSearchCriteria(state);
  const searchCriteria = {
    ...initialCriteria,
    ...getAdvancedKnowledgeCriteria(state),
    id: topicId,
  };
  const matchingKnowledgeDataPackage = state.domains.knowledgeData.forTopic[topicId]?.find((knowledgeDataPackage) =>
    isSameSearchCriteria(knowledgeDataPackage.searchCriteria, searchCriteria),
  );
  return matchingKnowledgeDataPackage;
};

export const getInteractiveDataForTopic =
  (topicId: string, limit?: number) =>
  (state: RootState): Knowledge[] => {
    const data = (getKnowledgeDataStateForTopic(state, topicId)?.data || []).map(
      (id) => state.entities.knowledge.byId[id],
    );
    if (limit) {
      return data.slice(0, limit);
    }
    return data;
  };

export const getInteractiveDataForTopicIsPending = (topicId: string) => (state: RootState) =>
  getKnowledgeDataStateForTopic(state, topicId)?.isFetching || false;

export const getInteractiveDataForTopicHasNextPage = (topicId: string) => (state: RootState) =>
  getKnowledgeDataStateForTopic(state, topicId)?.hasNextPage || false;

export const getKnowledgeDataSearchCriteria = (state: RootState) => state.domains.knowledgeData.searchCriteria;

export const getKnowledgeDataSearchTerm = (state: RootState) => state.domains.knowledgeData.searchCriteria.searchTerm;

export const getIsKnowledgeDataFiltered = (state: RootState) => {
  const filter = state.domains.knowledgeData.searchCriteria;
  return !!filter.publicationType || !!filter.searchTerm || !!getTransformationMapSelectedOuterNode(state)?.id;
};

export const getInteractiveDataFilterCount = (state: RootState) => {
  const { publicationType, searchTerm } = state.domains.knowledgeData.searchCriteria;
  const count = [publicationType, searchTerm].filter((item) => !!item).length;
  return count;
};
