import { styled } from 'styled-components';

export const SectionPreTitle = styled.h3`
  margin: 0 0 0.8rem 0;
  font-weight: bold;
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  color: ${({ theme }) => theme.colors.textBright};
`;
