import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { UIButton } from 'common/components';
import { trackEvent } from 'domains/analytics/actions';
import { TrackEvent, TrackPage } from 'domains/analytics/types';
import { getIsAuthenticated } from 'domains/authentication/selectors';
import { useTranslations } from 'domains/language/useTranslations';
import { getUserRole } from 'entities/user/selectors';
import { getLinkToMembershipPage } from 'entities/user/utils';
import { useAuth } from 'useAuth';

const StyledContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  border-radius: 5px;
  padding: 1rem;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
`;

const StyledText = styled.p`
  margin: 0 0 22px 0;
  color: ${({ theme }) => theme.colors.textBright};
  font-size: 1rem;
  line-height: 1.5rem;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
`;

const StyledButtonText = styled.div`
  color: ${({ theme }) => theme.colors.blue};
  margin-right: 10px;
  font-weight: 500;
  cursor: pointer;
  display: inline-block;
`;

type NoAccessSidebarProps = { text?: string; trackPage: TrackPage };

export const NoAccessSidebar = ({ trackPage, text }: NoAccessSidebarProps) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const { textDictionary } = useTranslations();
  const { triggerLogin } = useAuth();
  const userRole = useSelector(getUserRole);

  const onLoginRegisterHandle = (name: TrackEvent) => () => {
    dispatch(trackEvent({ name, eventType: 'initialize modal' }));
    triggerLogin();
  };

  const onMembershipHandle = () => {
    dispatch(
      trackEvent({
        name: 'Upgrade',
        page: trackPage,
        subpage: 'Message banner',
      }),
    );
    window.location.href = getLinkToMembershipPage(userRole);
  };

  return (
    <StyledContainer>
      <StyledText>{text || textDictionary['app.monitor.unauthorized_description']}</StyledText>
      {isAuthenticated ? (
        <StyledButtonText onClick={onMembershipHandle}>
          {textDictionary['app.join_forum_community.title']}
        </StyledButtonText>
      ) : (
        <StyledButtonContainer>
          <UIButton onClick={onLoginRegisterHandle('Signup')} variant="filled">
            {textDictionary['app.registration.join_us']}
          </UIButton>
          <UIButton variant="outlined" onClick={onLoginRegisterHandle('Login')}>
            {textDictionary['app.user.sign_in']}
          </UIButton>
        </StyledButtonContainer>
      )}
    </StyledContainer>
  );
};
