import isEqual from 'lodash.isequal';
import sortBy from 'lodash.sortby';
import { put, select, delay } from 'redux-saga/effects';

import { breakpointValues } from 'common/styles';
import { addCoachmarksToInDOM } from 'domains/coachmarks/actions';
import { getCoachmarksAreEnabled, getCoachmarksInDOM } from 'domains/coachmarks/selectors';
import { getCoachmarks } from 'entities/coachmarks/selectors';
import { Coachmark } from 'entities/coachmarks/types';

export function* initSaga() {
  let coachmarksAreActive = true;

  const mobileBreakpoint = window.matchMedia(`(max-width: ${breakpointValues.medium}px)`);

  while (coachmarksAreActive) {
    const coachmarks: Coachmark[] = yield select(getCoachmarks);
    const coachmarksInDOM: string[] = yield select(getCoachmarksInDOM);

    const isMobile = mobileBreakpoint.matches;

    const currentUnusedCoachmarksInDOM = coachmarks.reduce<any>(
      (ids, { target_selector, images, id }) =>
        document.querySelector(target_selector) !== null && (!isMobile || !!images) ? [...ids, id] : ids,
      [],
    );

    const coachmarksInDOMHaveChanged = !isEqual(sortBy(coachmarksInDOM), sortBy(currentUnusedCoachmarksInDOM));

    if (coachmarksInDOMHaveChanged) {
      yield put(addCoachmarksToInDOM({ ids: currentUnusedCoachmarksInDOM }));
    }
    yield delay(1000);
    coachmarksAreActive = yield select(getCoachmarksAreEnabled);
  }
}
