import { put, select } from 'redux-saga/effects';

import { TopicStakeholdersApplySearchCriteriaAction } from 'domains/stakeholders/actionTypes';
import { getStakeholdersSearchCriteria } from 'domains/stakeholders/selectors';
import { getTransformationMapSelectedId } from 'domains/transformationMap/selectors';
import { fetchStakeholdersForTopic } from 'entities/stakeholders/actions';
import { StakeholdersFetchForTopicPayload } from 'entities/stakeholders/actionTypes';

export function* applyStakeholdersSearchCriteriaSaga(action: TopicStakeholdersApplySearchCriteriaAction) {
  const searchCriteria: Omit<StakeholdersFetchForTopicPayload, 'id'> = yield select(getStakeholdersSearchCriteria);
  const tmSelectedId: string = yield select(getTransformationMapSelectedId);
  const id = action.payload?.topicId ?? tmSelectedId;
  if (!id) {
    return;
  }
  yield put(fetchStakeholdersForTopic({ id, ...searchCriteria }));
}
