import { SVGProps } from 'react';

export const PlayIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15.634" height="17.323" viewBox="0 0 15.634 17.323" {...props}>
    <path
      data-name="Path 210204"
      d="M1 15.955V2.773a1.05 1.05 0 0 1 1.571-.912L14.1 8.452a1.05 1.05 0 0 1 0 1.823L2.571 16.866A1.05 1.05 0 0 1 1 15.955z"
      transform="translate(0 -.702)"
      stroke={'currentColor'}
      strokeWidth={'2px'}
      fill={'none'}
    />
  </svg>
);
