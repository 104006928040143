import { SVGProps } from 'react';

export const SuggestionsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      d="M24 6c11.028 0 20 7.184 20 16.014 0 9.834-10.29 15.922-19.82 15.922-3.874 0-6.766-.794-8.788-1.288-2 1.226-3.19 2.074-8.544 3.64 1.07-2.746 1.446-5.496 1.204-8.53-1.676-2-4.05-4.8-4.05-9.744C4 13.184 12.972 6 24 6zm0-4C11.324 2 0 10.452 0 22.014c0 4.1 1.476 8.126 4.094 11.25.11 3.66-2.046 8.912-3.986 12.736 5.204-.94 12.602-3.016 15.956-5.072 2.836.69 5.55 1.006 8.118 1.006 14.168 0 23.82-9.674 23.82-19.922C48 10.39 36.598 2 24 2zm2.048 27.95a2.049 2.049 0 11-4.097.003 2.049 2.049 0 014.097-.003zm2.282-16.384C27.334 12.556 25.848 12 24.15 12c-3.572 0-5.882 2.542-5.882 6.474h3.294c0-2.434 1.36-3.298 2.522-3.298 1.038 0 2.14.69 2.234 2.008.104 1.388-.638 2.092-1.576 2.986-2.314 2.2-2.358 3.266-2.346 5.684h3.286c-.02-1.088.05-1.972 1.532-3.57 1.11-1.196 2.49-2.684 2.518-4.954.016-1.516-.466-2.818-1.402-3.764z"
      fill="currentColor"
    />
  </svg>
);
