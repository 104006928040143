import { Switch, Route } from 'react-router-dom';
import { css, styled } from 'styled-components';

import { UpcomingEventsPage } from 'domains/membershipEvents/components/MembershipEventsPage';

const StyledContainer = styled.div`
  height: 100%;

  ${({ theme }) => css`
    background-color: ${theme.colors.backgroundPrimary};
    border-top: 1px solid ${theme.colors.outline};
    color: ${theme.colors.fillPrimary};
  `};
`;

export const MembershipEventsRoutes = () => (
  <StyledContainer>
    <Switch>
      <Route path="/membership-events/:eventId?" component={UpcomingEventsPage} />
    </Switch>
  </StyledContainer>
);
