import { ChangeEvent, PropsWithChildren } from 'react';
import { styled } from 'styled-components';

import { CheckIcon } from 'common/components';

const StyledIcon = styled(CheckIcon)`
  position: absolute;
  display: none;
  bottom: 14px;
  right: 14px;
  width: 12px;
  height: 12px;
  color: ${({ theme }) => theme.colors.checkboxIcon};
`;

const StyledInput = styled.input`
  visibility: hidden;
  width: 40px;
  height: 40px;
  margin: 0;
  &:after {
    visibility: visible;
    content: '';
    width: 36px;
    height: 36px;
    border-color: ${({ theme }) => theme.colors.checkboxBorder};
    border-style: solid;
    border-width: 2px;
    border-radius: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  &:checked:after {
    background-color: ${({ theme }) => theme.colors.checkboxBackground};
  }
  &:checked + ${StyledIcon} {
    display: block;
  }
`;

const StyledText = styled.span`
  padding-left: 20px;
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
`;

type CheckboxProps = PropsWithChildren & {
  className?: string;
  id: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  text: string;
  value: boolean;
};

export const Checkbox = ({ className, id, onChange, text, value }: CheckboxProps) => (
  <StyledLabel className={className}>
    <StyledText>{text}</StyledText>
    <StyledInput type="checkbox" id={id} name={id} checked={value} onChange={onChange} />
    <StyledIcon />
  </StyledLabel>
);
