import { Link } from 'react-router-dom';
import { css, styled } from 'styled-components';

import { UIButton } from 'common/components';
import { breakpoints } from 'common/styles';
import { useInternalLink, useLocalStorageState } from 'common/utilities';
import { useTranslations } from 'domains/language/useTranslations';

const StyledBanner = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 1050;
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  width: 100%;
  text-align: center;
  flex-direction: column;
  padding: 1.25rem;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-left-color: ${({ theme }) => theme.colors.blue};
  border-left-width: 9px;
  border-left-style: solid;
  border-radius: 10px;

  @media ${breakpoints.medium} {
    display: flex;
    flex-direction: row;
  }
`;

const StyledBannerText = styled.p`
  margin: 0;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.textBright};
`;

const buttonStyles = css`
  width: 100%;
  margin-top: 1rem;

  @media ${breakpoints.medium} {
    width: initial;
    margin-top: 0;
    margin-left: 1rem;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  display: block;
  ${buttonStyles};
`;

const StyledAcceptButton = styled(UIButton)`
  ${buttonStyles};
`;

export const CookieBanner = () => {
  const { textDictionary } = useTranslations();
  const [hasAcceptedCookies, setHasAcceptedCookies] = useLocalStorageState('hasAcceptedCookies', null);
  const cookieLink = useInternalLink('/cookie-notice');

  const onClickHandler = () => setHasAcceptedCookies('true');

  if (!textDictionary || !Object.keys(textDictionary).length || hasAcceptedCookies) {
    return null;
  }

  return (
    <StyledBanner data-test-id="cookie-banner">
      <StyledBannerText>{textDictionary['app.cookie.inform_user_message']}</StyledBannerText>
      <StyledLink to={cookieLink}>
        <UIButton variant={'outlined'} fullwidth>
          {textDictionary['app.cookie.learn_more']}
        </UIButton>
      </StyledLink>
      <StyledAcceptButton onClick={onClickHandler} data-test-id="accept-cookie-button">
        {textDictionary['app.cookie.accept_action']}
      </StyledAcceptButton>
    </StyledBanner>
  );
};
