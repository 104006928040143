import {
  BriefingActionTypes,
  BriefingFailurePayload,
  GenerateBriefingButtonActionTypes,
  MonitorProgressGenerateBriefingSuccessPayload,
  TriggerGenerateBriefing,
  TriggerGenerateBriefingPayload,
  TriggerGenerateBriefingSuccessPayload,
} from 'domains/briefing/actionTypes';

export const triggerGenerateBriefing = (payload: TriggerGenerateBriefingPayload): TriggerGenerateBriefing => ({
  type: BriefingActionTypes.TRIGGER_GENERATE_BRIEFING,
  payload,
});

export const disableGenerateButton = () => ({
  type: GenerateBriefingButtonActionTypes.DISABLE_GENERATE_BUTTON,
});

export const enableGenerateButton = () => ({
  type: GenerateBriefingButtonActionTypes.ENABLE_GENERATE_BUTTON,
});

export const triggerGenerateBriefingSuccess = (payload: TriggerGenerateBriefingSuccessPayload) => ({
  type: BriefingActionTypes.TRIGGER_GENERATE_BRIEFING_SUCCESS,
  payload,
});

export const monitorProgressGenerateBriefingSuccess = (payload: MonitorProgressGenerateBriefingSuccessPayload) => ({
  type: BriefingActionTypes.MONITOR_PROGRESS_GENERATE_BRIEFING_SUCCESS,
  payload,
});

export const monitorProgressGenerateBriefingFailure = (payload: BriefingFailurePayload) => ({
  type: BriefingActionTypes.MONITOR_PROGRESS_GENERATE_BRIEFING_FAILURE,
  payload,
});

export const fetchBriefingSuccess = (briefingId: string) => ({
  type: BriefingActionTypes.FETCH_BRIEFING_SUCCESS,
  payload: { briefingId },
});

export const fetchBriefingError = (briefingId: string) => ({
  type: BriefingActionTypes.FETCH_BRIEFING_FAILURE,
  payload: { briefingId },
});

export const removeBriefing = (briefingId: string) => ({
  type: BriefingActionTypes.REMOVE_BRIEFING,
  payload: { briefingId },
});
