import { BookmarkItem } from 'domains/bookmarks/types';
import { RootState } from 'types';

const getBookmarks = (state: RootState) => state.domains.bookmarks;

export const getBookmarksCount = (state: RootState) => getBookmarks(state).total;

export const getBookmarkOffset = (state: RootState) => getBookmarks(state).offset;

export const getBookmarkIsPending = (state: RootState) => getBookmarks(state).isPending;

export const getBookmarkHasNextPage = (state: RootState) => getBookmarks(state).hasNextPage;

const getBookmark = (state: RootState, item: BookmarkItem) => {
  if (item.type === 'article') {
    return state.entities.knowledge.byId[item.id];
  }
  return state.domains.clusters.data[item.id];
};

export const getBookmarksList = (limit?: number) => (state: RootState) => {
  const bookmarks = getBookmarks(state);
  const items = bookmarks.items || [];
  const slicedItems = limit ? items.slice(0, limit) : items;
  return slicedItems.map((item) => getBookmark(state, item));
};

export const getBookmarkById = (id?: string) => (state: RootState) => {
  if (!id) {
    return undefined;
  }
  const bookmark = state.domains.bookmarks.items.find((item) => item.id === id);
  if (!bookmark) {
    return undefined;
  }
  return getBookmark(state, bookmark);
};
