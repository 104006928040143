import { call, put } from 'redux-saga/effects';

import { fetchCaseStudiesByIdSuccess, fetchCaseStudiesByIdSuccessFailure } from 'domains/caseStudy/actions';
import { CaseStudyFetch } from 'domains/caseStudy/actionTypes';
import { CaseStudyResponse, TopicCaseStudyPayload } from 'domains/caseStudy/types';
import { isFetchError } from 'TypeCheckMethods';

const apiFetchPublic = async ({ topicId, offset, limit }: TopicCaseStudyPayload) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise<Response>(async (resolve, reject) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_CASE_STUDIES_ENDPOINT}?topicsContext=${topicId}&from=${offset}&size=${limit}`,
      );
      if (!response.ok) {
        reject(response);
        return;
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });

export function* fetchCaseStudiesByIdSaga(action: CaseStudyFetch) {
  const { topicId } = action.payload;
  try {
    const response: Response = yield call(apiFetchPublic, action.payload);
    const responseJson: CaseStudyResponse = yield call(() => response.json());
    yield put(fetchCaseStudiesByIdSuccess({ topicId: topicId, data: responseJson.data }, action.payload));
  } catch (error) {
    if (isFetchError(error)) {
      yield put(fetchCaseStudiesByIdSuccessFailure({ topicId }));
    }
  }
}
