import { PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { styled } from 'styled-components';

import { mapOIDCUserToTokenStorage } from 'authUtils';
import { Spinner } from 'common/components';
import { pushHistory, replaceHistory } from 'common/utilities';
import { getCookie, setCookie } from 'common/utilities/cookies';
import { fwsLogin, unauthenticate } from 'domains/authentication/actions';
import { getIsAuthenticated } from 'domains/authentication/selectors';
import { isComingFromPaymentPortal } from 'entities/user/utils';
import { StyledAppContainer } from 'StyledAppContainer';
import { IS_AUTHENTICATING_COOKIE, useAuth } from 'useAuth';
import { setRefreshTokenCookie } from 'WithRemoteRefreshTokenStorage';

const StyledLoadingIndicator = styled(Spinner)`
  display: inline-block;
  vertical-align: middle;
  width: 75px;
  height: 75px;
  position: absolute;
  right: calc(50% - 37.5px);
  top: calc(50% - 37.5px);
  color: ${({ theme }) => theme.colors.textBright};
`;

type AuthLoaderProps = PropsWithChildren & { isAuthLoading: boolean };

/**
 * AuthLoader is a component that displays a page-wide loading indicator while
 * we're currently running any blocking authentication actions, preventing the
 * rest of the app from loading until we're done.
 */
export const AuthLoader = ({ children, isAuthLoading }: AuthLoaderProps) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const { pathname, search } = useLocation();
  const { triggerLogin, signinSilent, removeUser, signinRedirect } = useAuth();
  const [skipFWSLoginCheck, setSkipFWSLoginCheck] = useState(false);

  const [checkedIfIsLoggedInInWeforum, setCheckedIfIsLoggedInInWeforum] = useState(false);

  const values = new URLSearchParams(search);
  const doesPathMatchForOIDCAuth: boolean = Boolean(
    pathname !== '/app-redirect' && values.get('code') && values.get('state'),
  );

  const handleCookieRefreshToken = async (cookieRefreshToken: string, localStoreRefreshToken: string) => {
    // there is some inconsistency, so get rid of whatever we have locally
    if (cookieRefreshToken !== localStoreRefreshToken) {
      // we might need to call with true here
      removeLocalUser(false);
    }
    // the remove user method calls the WithRemoteRefreshTokenStorage remove method, which deletes the cookie - we need to put it back, because for this flow it should not be removed
    // if its removed, the signinSilent will not sign in (as there is no refresh token anymore)
    setRefreshTokenCookie(cookieRefreshToken);
    await signinSilent()
      .then((user) => {
        if (!user || !user.refresh_token || !user.id_token) {
          // most of this can be removed..
          console.error('Something went wrong with the signin callback');
        }
        dispatch(fwsLogin(mapOIDCUserToTokenStorage(user!)));
      })
      .catch((e) => {
        console.error('Failed to sign in silent:', e);
      });
  };

  const removeLocalUser = (unauthenticateUser: boolean = false) => {
    if (unauthenticateUser) {
      dispatch(unauthenticate({ isUserLogout: true }));
    }
    removeUser().catch(console.error);
  };

  const fetchIsRemotelyLoggedIn = async () => {
    const res = await fetch(`${process.env.REACT_APP_FWS_LOGIN_BASE_URL}/ssologin/login`, {
      credentials: 'include',
    });
    const responseJson = await res.json();
    return responseJson.isauthenticated;
  };

  const getDestinationAndTokens = () => {
    const destination = values.get('destination');
    const localStoreOIDCToken = localStorage.getItem(
      `oidc.user:${process.env.REACT_APP_SSO_URL}/authentication/v1/sso:${process.env.REACT_APP_AUTH_WIDGET_CLIENT_ID}`,
    );
    const localStoreRefreshToken = JSON.parse(localStoreOIDCToken || '{}').refresh_token;
    const cookieRefreshToken = getCookie(process.env.REACT_APP_REFRESH_TOKEN_COOKIE_KEY!);
    return {
      destination,
      localStoreRefreshToken,
      cookieRefreshToken,
    };
  };

  useEffect(() => {
    /*
    check refresh token.
      if we have one, silent sign in and done
      if not
        check remote endpoint
          if authenticated, redirect login AND set the refresh token in the cookie, then done
          it not
            check local user
              if we have one, delete it and log the user out => we should not be logged in
              if not
                end flow
     */

    const ssoHandler = async () => {
      // do not autologin if you are coming from app-redirect! This is to prevent logging in when the auth session is intended for the app
      if (pathname === '/app-redirect') {
        setSkipFWSLoginCheck(true);
        return;
      }
      // you arrived from the payment portal and are logged in, so we remove the local user and a login by refreshing
      if (isComingFromPaymentPortal() && isAuthenticated) {
        removeLocalUser(false);
        window.location.reload();
      }
      // get variables that we need later on
      const { destination, localStoreRefreshToken, cookieRefreshToken } = getDestinationAndTokens();

      // if the cookie is there, log in and cancel the rest of the flow
      if (cookieRefreshToken) {
        await handleCookieRefreshToken(cookieRefreshToken, localStoreRefreshToken);
        setCheckedIfIsLoggedInInWeforum(true);
        setCookie(IS_AUTHENTICATING_COOKIE, 'false', 'path=/');
        return;
      }

      // if we are currently not doing a login (see docs/ADR/IS_AUTHENTICATING.md)
      if (getCookie(IS_AUTHENTICATING_COOKIE) !== 'true') {
        const isRemotelyLoggedIn = await fetchIsRemotelyLoggedIn();
        if (isRemotelyLoggedIn) {
          signinRedirect();
          return;
        }
        if (localStoreRefreshToken) {
          removeLocalUser(true);
          setCheckedIfIsLoggedInInWeforum(true);
          setCookie(IS_AUTHENTICATING_COOKIE, 'false', 'path=/');
        }
      }

      setCheckedIfIsLoggedInInWeforum(true);
      if (destination) {
        pushHistory(destination);
      }
    };

    ssoHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // For the registration process only (all other flows return authorization
    // `code` as url parameters)
    const originalState = values.get('original_state');
    const code = values.get('code');
    if (pathname === '/app-redirect' || !originalState || !!code) {
      return;
    }

    // We get an `original_state` only with no `code`, so we need to go back
    // to FWS to get an authorization `code` for token exchange.
    const parsedUrl = new URL(window.location.href);
    parsedUrl.searchParams.delete('original_state');
    triggerLogin();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      // We've authenticated now, so let's strip the authentication parameters
      // ("code" and "state") to allow the frontend to move beyond the loading
      // screen
      const newSearch: string[] = [];
      values.forEach((value, key) => {
        if (!['code', 'state'].includes(key)) {
          newSearch.push(`${key}=${value}`);
        }
      });
      replaceHistory(`${pathname}?${newSearch.join('&')}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isAuthenticated, doesPathMatchForOIDCAuth]);

  if (skipFWSLoginCheck) {
    return <>{children}</>;
  }

  if (!checkedIfIsLoggedInInWeforum || isAuthLoading || doesPathMatchForOIDCAuth) {
    return (
      <StyledAppContainer>
        <StyledLoadingIndicator />
      </StyledAppContainer>
    );
  }

  return <>{children}</>;
};
