import { LanguageCode } from 'common/types/languageCode';
import {
  CollectionIdOnly,
  FetchCollectionsPayload,
  FetchCollectionsSuccessPayload,
  FetchCommunitiesPayload,
  FetchCommunitiesSuccessPayload,
  UpdateCollectionPayload,
  UploadImagePayload,
  UploadImageSuccessPayload,
} from 'domains/collection/types';
import { CollectionViewResponse, MappedCollectionEditResponse } from 'domains/collection/types/collectionResponses';
import { CommunityEditRequest } from 'domains/collection/types/communityRequests';

export enum CollectionActionType {
  FETCH_COLLECTION_FOR_VIEWING = '[Collection] Fetch Collection for Viewing',
  FETCH_COLLECTION_FOR_VIEWING_SUCCESS = '[Collection] Fetch Collection for Viewing Success',
  FETCH_COLLECTION_FOR_VIEWING_FAILURE = '[Collection] Fetch Collection for Viewing Failure',
  FETCH_COLLECTION_FOR_EDITING = '[Collection] Fetch Collection for Editing',
  FETCH_COLLECTION_FOR_EDITING_SUCCESS = '[Collection] Fetch Collection for Editing Success',
  FETCH_COLLECTION_FOR_EDITING_FAILURE = '[Collection] Fetch Collection for Editing Failure',
  FETCH_COLLECTIONS = '[Collection] Fetch Collections',
  FETCH_COLLECTIONS_SUCCESS = '[Collection] Fetch Collections Success',
  FETCH_COLLECTIONS_FAILURE = '[Collection] Fetch Collections Failure',
  FOLLOW_COLLECTION = '[Collection] Follow Collection',
  FOLLOW_COLLECTION_SUCCESS = '[Collection] Follow Collection Success',
  FOLLOW_COLLECTION_FAILURE = '[Collection] Follow Collection Failure',
  UNFOLLOW_COLLECTION = '[Collection] Unfollow Collection',
  UNFOLLOW_COLLECTION_SUCCESS = '[Collection] Unfollow Collection Success',
  UNFOLLOW_COLLECTION_FAILURE = '[Collection] Unfollow Collection Failure',
  DELETE_COLLECTION = '[Collection] Delete Collection',
  DELETE_COLLECTION_SUCCESS = '[Collection] Delete Collection Success',
  DELETE_COLLECTION_FAILURE = '[Collection] Delete Collection Failure',
  UPDATE_COLLECTION = '[Collection] Update Collection',
  UPDATE_COLLECTION_SUCCESS = '[Collection] Update Collection Success',
  UPDATE_COLLECTION_FAILURE = '[Collection] Update Collection Failure',
  FETCH_COMMUNITY = '[Collection] Fetch Community',
  FETCH_COMMUNITY_SUCCESS = '[Collection] Fetch Community Success',
  FETCH_COMMUNITY_FAILURE = '[Collection] Fetch Community Failure',
  EDIT_COMMUNITY = '[Collection] Edit Community',
  EDIT_COMMUNITY_SUCCESS = '[Collection] Edit Community Success',
  EDIT_COMMUNITY_FAILURE = '[Collection] Edit Community Failure',
  FETCH_COMMUNITIES = '[Collection] Fetch Communities',
  FETCH_COMMUNITIES_SUCCESS = '[Collection] Fetch Communities Success',
  FETCH_COMMUNITIES_FAILURE = '[Collection] Fetch Communities Failure',
  CLEAR_COMMUNITIES = '[Collection] Clear Communities',
  UPLOAD_IMAGE = '[Collection] Upload Image',
  UPLOAD_IMAGE_SUCCESS = '[Collection] Upload Image Success',
  UPLOAD_IMAGE_FAILURE = '[Collection] Upload Image Failure',
  CLEAR_SINGLE = '[Collection] Clear Single',
  CLEAR_HERO_IMAGE_URL = '[Collection] Clear Hero Image URL',
  CLEAR_COMMUNITY_IMAGE_URL = '[Collection] Clear Community Image URL',
}

type CollectionId = string;

export type FetchCollectionForViewingAction = {
  type: CollectionActionType.FETCH_COLLECTION_FOR_VIEWING;
  id: CollectionId;
};

export type FetchCollectionForViewingSuccessAction = {
  type: CollectionActionType.FETCH_COLLECTION_FOR_VIEWING_SUCCESS;
  response: CollectionViewResponse;
};

export type FetchCollectionForViewingFailureAction = {
  type: CollectionActionType.FETCH_COLLECTION_FOR_VIEWING_FAILURE;
  id: CollectionId;
};

export type FetchCollectionForEditingAction = {
  type: CollectionActionType.FETCH_COLLECTION_FOR_EDITING;
  id: CollectionId;
};

export type FetchCollectionForEditingSuccessAction = {
  type: CollectionActionType.FETCH_COLLECTION_FOR_EDITING_SUCCESS;
  response: MappedCollectionEditResponse;
};

export type FetchCollectionForEditingFailureAction = {
  type: CollectionActionType.FETCH_COLLECTION_FOR_EDITING_FAILURE;
  id: CollectionId;
};

export type FetchCollectionsAction = {
  type: CollectionActionType.FETCH_COLLECTIONS;
  payload: FetchCollectionsPayload;
};

export type FetchCollectionsSuccessAction = {
  type: CollectionActionType.FETCH_COLLECTIONS_SUCCESS;
  payload: FetchCollectionsSuccessPayload;
};

export type FetchCollectionsFailureAction = {
  type: CollectionActionType.FETCH_COLLECTIONS_FAILURE;
  payload: FetchCollectionsPayload;
};

export type FollowCollectionAction = {
  type: CollectionActionType.FOLLOW_COLLECTION;
  payload: CollectionIdOnly;
};

export type FollowCollectionSuccessAction = {
  type: CollectionActionType.FOLLOW_COLLECTION_SUCCESS;
  payload: CollectionIdOnly;
};

export type FollowCollectionFailureAction = {
  type: CollectionActionType.FOLLOW_COLLECTION_FAILURE;
  payload: CollectionIdOnly;
};

export type UnfollowCollectionAction = {
  type: CollectionActionType.UNFOLLOW_COLLECTION;
  payload: CollectionIdOnly;
};

export type UnfollowCollectionSuccessAction = {
  type: CollectionActionType.UNFOLLOW_COLLECTION_SUCCESS;
  payload: CollectionIdOnly;
};

export type UnfollowCollectionFailureAction = {
  type: CollectionActionType.UNFOLLOW_COLLECTION_FAILURE;
  payload: CollectionIdOnly;
};

export type DeleteCollectionAction = {
  type: CollectionActionType.DELETE_COLLECTION;
  payload: CollectionIdOnly;
};

export type DeleteCollectionSuccessAction = {
  type: CollectionActionType.DELETE_COLLECTION_SUCCESS;
  payload: CollectionIdOnly;
};

export type DeleteCollectionFailureAction = {
  type: CollectionActionType.DELETE_COLLECTION_FAILURE;
};

export type UpdateCollectionAction = {
  type: CollectionActionType.UPDATE_COLLECTION;
  payload: UpdateCollectionPayload;
  siteLanguage: LanguageCode;
};

export type UpdateCollectionSuccessAction = {
  type: CollectionActionType.UPDATE_COLLECTION_SUCCESS;
  payload: UpdateCollectionPayload;
};

export type UpdateCollectionFailureAction = {
  type: CollectionActionType.UPDATE_COLLECTION_FAILURE;
  payload: CollectionIdOnly;
};

export type FetchCommunityAction = {
  type: CollectionActionType.FETCH_COMMUNITY;
  payload: { id: string };
};

export type FetchCommunitySuccessAction = {
  type: CollectionActionType.FETCH_COMMUNITY_SUCCESS;
  payload: { id: string };
};

export type FetchCommunityFailureAction = {
  type: CollectionActionType.FETCH_COMMUNITY_FAILURE;
  payload: { id: string };
};

export type EditCommunityAction = {
  type: CollectionActionType.EDIT_COMMUNITY;
  payload: CommunityEditRequest;
};

export type EditCommunitySuccessAction = {
  type: CollectionActionType.EDIT_COMMUNITY_SUCCESS;
  payload: { id: string };
};

export type EditCommunityFailureAction = {
  type: CollectionActionType.EDIT_COMMUNITY_FAILURE;
  payload: { id: string };
};

export type FetchCommunitiesAction = {
  type: CollectionActionType.FETCH_COMMUNITIES;
  payload: FetchCommunitiesPayload;
};

export type FetchCommunitiesSuccessAction = {
  type: CollectionActionType.FETCH_COMMUNITIES_SUCCESS;
  payload: FetchCommunitiesSuccessPayload;
};

export type FetchCommunitiesFailureAction = {
  type: CollectionActionType.FETCH_COMMUNITIES_FAILURE;
};

export type ClearCommunitiesAction = {
  type: CollectionActionType.CLEAR_COMMUNITIES;
};

export type UploadImageAction = {
  type: CollectionActionType.UPLOAD_IMAGE;
  payload: UploadImagePayload;
};

type UploadImageSuccessAction = {
  type: CollectionActionType.UPLOAD_IMAGE_SUCCESS;
  payload: UploadImageSuccessPayload;
};

type UploadImageFailureAction = {
  type: CollectionActionType.UPLOAD_IMAGE_FAILURE;
};

export type ClearSingleAction = {
  type: CollectionActionType.CLEAR_SINGLE;
};

export type ClearHeroImageUrlAction = {
  type: CollectionActionType.CLEAR_HERO_IMAGE_URL;
};

export type ClearCommunityImageUrlAction = {
  type: CollectionActionType.CLEAR_COMMUNITY_IMAGE_URL;
};

export type CollectionAction =
  | FetchCollectionForViewingAction
  | FetchCollectionForViewingSuccessAction
  | FetchCollectionForViewingFailureAction
  | FetchCollectionForEditingAction
  | FetchCollectionForEditingSuccessAction
  | FetchCollectionForEditingFailureAction
  | FetchCollectionsAction
  | FetchCollectionsSuccessAction
  | FetchCollectionsFailureAction
  | FollowCollectionAction
  | FollowCollectionSuccessAction
  | FollowCollectionFailureAction
  | UnfollowCollectionAction
  | UnfollowCollectionSuccessAction
  | UnfollowCollectionFailureAction
  | DeleteCollectionAction
  | DeleteCollectionSuccessAction
  | DeleteCollectionFailureAction
  | UpdateCollectionAction
  | UpdateCollectionSuccessAction
  | UpdateCollectionFailureAction
  | FetchCommunityAction
  | FetchCommunitySuccessAction
  | FetchCommunityFailureAction
  | EditCommunityAction
  | EditCommunitySuccessAction
  | EditCommunityFailureAction
  | FetchCommunitiesAction
  | FetchCommunitiesSuccessAction
  | FetchCommunitiesFailureAction
  | ClearCommunitiesAction
  | UploadImageAction
  | UploadImageSuccessAction
  | UploadImageFailureAction
  | ClearSingleAction
  | ClearHeroImageUrlAction
  | ClearCommunityImageUrlAction;
