import { Link } from 'react-router-dom';
import { styled, ThemeProvider } from 'styled-components';

import { linkStyles, UIButton, UIButtonsRow, UIFollowButton } from 'common/components';
import { breakpoints, mediaStyles } from 'common/styles';
import { useTranslations } from 'domains/language/useTranslations';
import { DiscoverNavigation } from 'domains/routing/types';
import { useRouterLink } from 'domains/routing/utils';
import { darkTheme } from 'domains/theme/constants/theme';
import { useTopicFollowing } from 'domains/topic/components/useTopicFollowing';
import { Topic } from 'entities/topics/types';

const StyledInfo = styled.div<{ imageUrl?: string }>`
  position: relative;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000), url(${({ imageUrl }) => imageUrl});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: ${({ theme }) => theme.colors.textBright};
  width: 100%;
  ${mediaStyles(({ spacing }) => `padding: 0 ${spacing}rem`)};
  height: 500px;

  scroll-snap-align: start;
  flex-shrink: 0;
  transform-origin: center center;

  @media ${breakpoints.medium} {
    height: 600px;
  }
`;

const StyledInfoHeader = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
`;

const StyledCoCurator = styled.div`
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const StyledDescription = styled.div`
  line-height: 1.6rem;
  margin-bottom: 2rem;
  max-width: 80%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media ${breakpoints.medium} {
    max-width: 60%;
  }
`;

const StyledLink = styled(Link)`
  width: fit-content;
  font-size: 2rem;
  margin-bottom: 0.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${linkStyles}

  @media ${breakpoints.medium} {
    font-size: 3rem;
  }
`;

const StyledUIButtonsRow = styled(UIButtonsRow)`
  margin-bottom: 2rem;
  gap: 15px 30px;
`;

type TopicPreviewProps = { header: string; topic: Topic };

export const TopicPreview = ({ topic, header }: TopicPreviewProps) => {
  const { id, name, co_curator, description, images } = topic;

  const { interpolate, textDictionary } = useTranslations();

  const defaultDestination: DiscoverNavigation = {
    to: 'discover',
    params: { id },
  };

  const { isFollowing, isFollowingUpdating, canFollow, toggleFollow } = useTopicFollowing(id);

  const link = useRouterLink(defaultDestination);

  const onFollowClick = () =>
    toggleFollow({
      event: {
        name: isFollowing ? 'Unfollow' : 'Follow',
        page: 'Discover',
        entityId: id,
        entityName: name,
        entityType: 'Topic',
      },
    });

  return (
    <ThemeProvider theme={darkTheme}>
      <StyledInfo imageUrl={images?.large} key={id}>
        <StyledInfoHeader>{header}</StyledInfoHeader>
        <StyledLink to={link}>{name}</StyledLink>
        <StyledCoCurator>
          {interpolate(textDictionary['app.discover.topic.banner.co_curated_by'], {
            coCurator: () => co_curator,
          })}
        </StyledCoCurator>
        <StyledDescription>{description}</StyledDescription>
        <StyledUIButtonsRow>
          <UIButton as={'a'} href={link} variant={'outlined'}>
            {textDictionary['app.topics_highlight.view_details']}
          </UIButton>
          {canFollow && (
            <UIFollowButton
              variant={'open'}
              isFollowing={isFollowing || false}
              onClick={onFollowClick}
              isLoading={isFollowingUpdating}
            />
          )}
        </StyledUIButtonsRow>
      </StyledInfo>
    </ThemeProvider>
  );
};
