import { SVGProps } from 'react';

export const VideoFileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
    <g id="Group_95328" data-name="Group 95328" transform="translate(12279 18575)">
      <g id="Rectangle_41721" data-name="Rectangle 41721" transform="translate(-12279 -18575)" fill="#2a63e9">
        <rect width="64" height="64" stroke="none" />
      </g>
      <g id="Group_95315" data-name="Group 95315" transform="translate(-12268 -18564)">
        <path
          id="Path_245107"
          data-name="Path 245107"
          d="M177.873,27.283,165.8,15.213A4.1,4.1,0,0,0,162.937,14h-21.66a4.141,4.141,0,0,0-4.079,4.079v34.36a4.069,4.069,0,0,0,4.079,4.079h33.731a4.069,4.069,0,0,0,4.079-4.079V30.149a4.1,4.1,0,0,0-1.213-2.866Zm-2.315.992H166.63a1.835,1.835,0,0,1-1.819-1.819V17.582Zm-.551,25.873H141.277a1.717,1.717,0,0,1-1.708-1.708V18.078a1.694,1.694,0,0,1,1.708-1.708h21.164V26.456a4.187,4.187,0,0,0,4.189,4.189h10.086V52.439a1.717,1.717,0,0,1-1.708,1.708Z"
          transform="translate(-137.198 -14)"
          fill="#fff"
        />
        <g id="Group_95300" data-name="Group 95300" transform="translate(4.386 14.962)">
          <path
            id="Path_245109"
            data-name="Path 245109"
            d="M242.964,239.12A11.684,11.684,0,1,0,254.649,250.8,11.69,11.69,0,0,0,242.964,239.12Zm0,21a9.315,9.315,0,1,1,9.315-9.315A9.338,9.338,0,0,1,242.964,260.119Z"
            transform="translate(-231.28 -239.12)"
            fill="#fff"
          />
          <path
            id="Path_245108"
            data-name="Path 245108"
            d="M320.909,314.34a1.114,1.114,0,0,0-1.708.937v6.393a1.106,1.106,0,0,0,1.708.937l5.126-3.2a1.134,1.134,0,0,0,0-1.929Z"
            transform="translate(-310.548 -306.79)"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  </svg>
);
