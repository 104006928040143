import { useDispatch, useSelector } from 'react-redux';

import { applyEventsSearchCriteria, clearEventsSearchCriteria } from 'domains/events/actions';
import { getEventsSearchCriteria } from 'domains/events/selectors';
import { useTranslations } from 'domains/language/useTranslations';
import { TopicFilterModal } from 'domains/topicSearchFilter/components/TopicFilterModal';
import { getTopicFilters } from 'domains/topicSearchFilter/selectors';

export const TopicEventFilterModal = () => {
  const dispatch = useDispatch();
  const searchCriteria = useSelector(getEventsSearchCriteria);
  const { textDictionary } = useTranslations();
  const filters = useSelector(getTopicFilters('events'));

  const handleApplyFilter = (payload: Partial<any>) => () => dispatch(applyEventsSearchCriteria(payload));

  const handleClear = () => dispatch(clearEventsSearchCriteria());

  return (
    <TopicFilterModal
      title={textDictionary['app.events.filter.title']}
      fields={filters}
      searchCriteria={searchCriteria as any}
      handleApplyFilter={handleApplyFilter}
      handleClear={handleClear}
    />
  );
};
