import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { styled } from 'styled-components';

import { HiddenImageUpload, UIButton } from 'common/components';
import { uploadImage } from 'domains/collection/actions';
import { LocalFile } from 'domains/createMapDetail/types';
import { useTranslations } from 'domains/language/useTranslations';
import { deactivateModal } from 'domains/modal/actions';
import { ModalHeader } from 'domains/modal/components/ModalHeader';

const StyledTitle = styled.p`
  color: ${({ theme }) => theme.colors.textBright};
`;

export const CollectionHeroImagePickerModal = () => {
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const { textDictionary } = useTranslations();

  const onSetImage = ({ file, url }: LocalFile) => {
    if (typeof url !== 'string') throw Error('Image URL is not a string');
    dispatch(uploadImage({ image: file, type: 'collection' }));
    dispatch(deactivateModal());
  };

  return (
    <>
      <ModalHeader title={textDictionary['app.create.collection.modal.change_background_image']} />
      <StyledTitle>{textDictionary['app.create.create_map.add_image']}</StyledTitle>
      <HiddenImageUpload accept="image/*" onSetImage={onSetImage} ref={inputRef} />
      <UIButton fullwidth onClick={() => inputRef.current?.click()} variant="filled">
        {textDictionary['app.profile.upload_image.title']}
      </UIButton>
    </>
  );
};
