import { useEffect, useRef, useState } from 'react';

type ElementIsLoadedProps = { rootFactor?: number; rootMargin?: string; threshold?: number };

const getRootMargin = (rootMargin?: string, factor?: number) => {
  if (rootMargin) {
    return rootMargin;
  }
  return factor ? `0% 0% -${factor * 100}% 0%` : '0px';
};

export const useElementIsLoaded = (props?: ElementIsLoadedProps) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const element = elementRef.current;
    if (!element) {
      return;
    }
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsLoaded(true);
          observer.unobserve(element);
        }
      },
      {
        root: null,
        rootMargin: getRootMargin(props?.rootMargin, props?.rootFactor),
        threshold: props?.threshold || 1,
      },
    );
    observer.observe(element);
    return () => {
      observer.unobserve(element);
    };
  }, [props?.rootMargin, props?.threshold, props?.rootFactor]);

  return { isLoaded, elementRef };
};
