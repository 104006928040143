import {
  TopicLatestActionTypes,
  TopicLatestApplySearchCriteria,
  TopicLatestClearSearchCriteria,
} from 'domains/knowledgeFeed/actionTypes';

export const applyLatestSearchCriteria = (
  payload?: TopicLatestApplySearchCriteria['payload'],
): TopicLatestApplySearchCriteria => ({
  type: TopicLatestActionTypes.APPLY_SEARCH_CRITERIA,
  payload,
});

export const clearLatestSearchCriteria = (): TopicLatestClearSearchCriteria => ({
  type: TopicLatestActionTypes.CLEAR_SEARCH_CRITERIA,
});
