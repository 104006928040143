import { Concept, CustomKeyIssueMeta, FPItem } from 'domains/customKeyIssue/types';
import { TopicSearchResult } from 'domains/search/types';
import { KeyIssue, Mapping } from 'entities/keyIssues/types';
import { Knowledge } from 'entities/knowledge/types';
import { Topic } from 'entities/topics/types';

export enum CustomKeyIssueTypes {
  FETCH_TOPIC_SEARCH = '[Custom Key Issue] - Topic Search',
  FETCH_TOPIC_SEARCH_FULFILLED = '[Custom Key Issue] - Topic Search - Fulfilled',
  FETCH_TOPIC_SEARCH_REJECTED = '[Custom Key Issue] - Topic Search - Rejected',
  FETCH_CONCEPT_SEARCH = '[Custom Key Issue] - Concept Search',
  FETCH_CONCEPT_SEARCH_FULFILLED = '[Custom Key Issue] - Concept Search - Fulfilled',
  FETCH_CONCEPT_SEARCH_REJECTED = '[Custom Key Issue] - Concept Search - Rejected',
  FETCH_FINGERPRINT_BUILDER = '[Custom Key Issue] - Fingerprint Builder',
  FETCH_FINGERPRINT_BUILDER_FULFILLED = '[Custom Key Issue] - Fingerprint Builder - Fulfilled',
  FETCH_FINGERPRINT_BUILDER_REJECTED = '[Custom Key Issue] - Fingerprint Builder - Rejected',
  FETCH_FP_KNOWLEDGE = '[Custom Key Issue] - FP Knowledge',
  FETCH_FP_KNOWLEDGE_FULFILLED = '[Custom Key Issue] - FP Knowledge - Fulfilled',
  FETCH_FP_KNOWLEDGE_REJECTED = '[Custom Key Issue] - FP Knowledge - Rejected',
  CREATE = '[Custom Key Issue] - Create',
  CREATE_FULFILLED = '[Custom Key Issue] - Create - Fulfilled',
  CREATE_REJECTED = '[Custom Key Issue] - Create - Rejected',
  LOAD = '[Custom Key Issue] - Load',
  LOAD_FULFILLED = '[Custom Key Issue] - Load - Fulfilled',
  LOAD_REJECTED = '[Custom Key Issue] - Load - Rejected',
  ACTIVATE_FORM = '[Custom Key Issue] - Activate Form',
  FETCH_TOPIC_AND_TAG_SUGGESTIONS = '[Custom Key Issue] - Topic and Tag Suggestions',
  FETCH_TOPIC_AND_TAG_SUGGESTIONS_FULFILLED = '[Custom Key Issue] - Topic and Tag Suggestions - Fulfilled',
  FETCH_TOPIC_AND_TAG_SUGGESTIONS_REJECTED = '[Custom Key Issue] - Topic and Tag Suggestions - Rejected',
  RESET_TOPIC_SEARCH_IDS_FOR_QUERY = 'Custom Key Issue] - Reset Topic Search Ids For Query',
}

export type CustomKeyIssueTopicSearchDefaultAction = {
  meta: { context?: string; limit?: number; query: string };
  type: CustomKeyIssueTypes.FETCH_TOPIC_SEARCH;
};

export type CustomKeyIssueTopicSearchFulfilledAction = {
  meta: { query: string };
  payload: TopicSearchResult[];
  type: CustomKeyIssueTypes.FETCH_TOPIC_SEARCH_FULFILLED;
};

export type CustomKeyIssueTopicSearchRejectedAction = {
  meta: { query: string };
  type: CustomKeyIssueTypes.FETCH_TOPIC_SEARCH_REJECTED;
};

export type CustomKeyIssueConceptSearchDefaultAction = {
  meta: { query: string; limit?: number };
  type: CustomKeyIssueTypes.FETCH_CONCEPT_SEARCH;
};

export type CustomKeyIssueConceptSearchFulfilledAction = {
  meta: { query: string };
  payload: Concept[];
  type: CustomKeyIssueTypes.FETCH_CONCEPT_SEARCH_FULFILLED;
};

export type CustomKeyIssueConceptSearchRejectedAction = {
  meta: { query: string };
  type: CustomKeyIssueTypes.FETCH_CONCEPT_SEARCH_REJECTED;
};

export type CustomKeyIssueFingerprintBuilderAction = {
  payload: { mappings: string[]; name: string };
  type: CustomKeyIssueTypes.FETCH_FINGERPRINT_BUILDER;
};

export type CustomKeyIssueFingerprintBuilderFulfilledAction = {
  meta: { mappings: string[]; name: string };
  payload: FPItem[];
  type: CustomKeyIssueTypes.FETCH_FINGERPRINT_BUILDER_FULFILLED;
};

export type CustomKeyIssueFingerprintBuilderRejectedAction = {
  meta: { mappings: string[]; name: string };
  type: CustomKeyIssueTypes.FETCH_FINGERPRINT_BUILDER_REJECTED;
};

export type CustomKeyIssueFPKnowledgeAction = {
  payload: { fp: FPItem[] };
  type: CustomKeyIssueTypes.FETCH_FP_KNOWLEDGE;
};

export type CustomKeyIssueFPKnowledgeFulfilledAction = {
  payload: Knowledge[];
  type: CustomKeyIssueTypes.FETCH_FP_KNOWLEDGE_FULFILLED;
};

export type CustomKeyIssueFPKnowledgeRejectedAction = { type: CustomKeyIssueTypes.FETCH_FP_KNOWLEDGE_REJECTED };
export type CreateCustomKeyIssueDefaultAction = { meta: CustomKeyIssueMeta; type: CustomKeyIssueTypes.CREATE };

export type CreateCustomKeyIssueFulfilledAction = {
  meta: CustomKeyIssueMeta;
  payload: KeyIssue;
  type: CustomKeyIssueTypes.CREATE_FULFILLED;
};

export type CreateCustomKeyIssueRejectedAction = {
  meta: CustomKeyIssueMeta;
  type: CustomKeyIssueTypes.CREATE_REJECTED;
};

export type LoadCustomKeyIssueDefaultAction = { meta: { id: string }; type: CustomKeyIssueTypes.LOAD };

export type LoadCustomKeyIssueFulfilledAction = {
  meta: { id: string };
  payload: KeyIssue;
  type: CustomKeyIssueTypes.LOAD_FULFILLED;
};

export type LoadCustomKeyIssueRejectedAction = { meta: { id: string }; type: CustomKeyIssueTypes.LOAD_REJECTED };

export type ActivateCreateFormAction = {
  meta?: { id?: string; isActive?: boolean };
  type: CustomKeyIssueTypes.ACTIVATE_FORM;
};

export type CustomKeyIssueTopicAndTagSuggestionsAction = {
  payload: { description: string; subtitle: string; title: string };
  type: CustomKeyIssueTypes.FETCH_TOPIC_AND_TAG_SUGGESTIONS;
};

export type CustomKeyIssueTopicAndTagSuggestionsFulfilledAction = {
  payload: { fp: Mapping[]; topics: Topic[] };
  type: CustomKeyIssueTypes.FETCH_TOPIC_AND_TAG_SUGGESTIONS_FULFILLED;
};

export type CustomKeyIssueTopicAndTagSuggestionsRejectedAction = {
  type: CustomKeyIssueTypes.FETCH_TOPIC_AND_TAG_SUGGESTIONS_REJECTED;
};

export type CustomKeyIssueResetTopicSearchIdsForQueryAction = {
  type: CustomKeyIssueTypes.RESET_TOPIC_SEARCH_IDS_FOR_QUERY;
};

export type CustomKeyIssueAction =
  | CustomKeyIssueTopicSearchDefaultAction
  | CustomKeyIssueTopicSearchFulfilledAction
  | CustomKeyIssueTopicSearchRejectedAction
  | CustomKeyIssueConceptSearchDefaultAction
  | CustomKeyIssueConceptSearchFulfilledAction
  | CustomKeyIssueConceptSearchRejectedAction
  | CustomKeyIssueFingerprintBuilderAction
  | CustomKeyIssueFingerprintBuilderFulfilledAction
  | CustomKeyIssueFingerprintBuilderRejectedAction
  | CustomKeyIssueFPKnowledgeAction
  | CustomKeyIssueFPKnowledgeFulfilledAction
  | CustomKeyIssueFPKnowledgeRejectedAction
  | CreateCustomKeyIssueDefaultAction
  | CreateCustomKeyIssueFulfilledAction
  | CreateCustomKeyIssueRejectedAction
  | LoadCustomKeyIssueDefaultAction
  | LoadCustomKeyIssueFulfilledAction
  | LoadCustomKeyIssueRejectedAction
  | ActivateCreateFormAction
  | CustomKeyIssueTopicAndTagSuggestionsAction
  | CustomKeyIssueTopicAndTagSuggestionsFulfilledAction
  | CustomKeyIssueTopicAndTagSuggestionsRejectedAction
  | CustomKeyIssueResetTopicSearchIdsForQueryAction;
