import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { css, styled } from 'styled-components';

import { linkStyles, RoundedButton, SectionTitleUnderlined } from 'common/components';
import { ChevronIcon } from 'common/components/icons';
import { breakpoints, mediaStyles } from 'common/styles';
import { StyledLandingCard } from 'domains/landing/components/StyledLandingCard';
import { getSiteLanguage } from 'domains/language/selectors';
import { useTranslations } from 'domains/language/useTranslations';
import { useRouterLink } from 'domains/routing/utils';
import { TopicSlider } from 'domains/topic/components/TopicSlider';
import { fetchFollowedTopics } from 'entities/topics/actions';
import { getFollowedTopicsIsPending, getNFollowedTopics } from 'entities/topics/selectors';
import { getCanFollowTopic } from 'entities/user/selectors';

const StyledContainer = styled.div`
  ${mediaStyles(({ spacing }) => `margin: 1rem ${spacing}rem;`)};
`;

const StyledTitle = styled(SectionTitleUnderlined)`
  ${mediaStyles(({ spacing }) => `margin: 2rem ${spacing}rem 1rem;`)};
`;

const StyledPlaceholderCard = styled(StyledLandingCard)`
  display: flex;
  align-items: center;
  flex-direction: column;

  @media ${breakpoints.medium} {
    flex-direction: row;
  }
`;

const StyledPlaceholderText = styled.p`
  margin: 1rem 0;

  @media ${breakpoints.medium} {
    margin: 0 2rem;
  }
`;

const StyledButton = styled(RoundedButton)`
  color: white;
  ${({ theme }) => css`
    background-color: ${theme.colors.blue};

    &:hover {
      background-color: ${theme.colors.blueDark};
    }
  `};
`;

const StyledLink = styled(Link)`
  ${linkStyles}
`;

const StyledArrow = styled(ChevronIcon)`
  width: 16px;
  height: 16px;
  color: ${({ theme }) => theme.colors.textBright};
  padding-left: 0.5rem;
`;

export const FollowingTopicsSection = () => {
  const dispatch = useDispatch();
  const { textDictionary } = useTranslations();
  const followingTopics = useSelector(getNFollowedTopics(8));
  const isPending = useSelector(getFollowedTopicsIsPending);
  const link = useRouterLink({ to: 'discover' });
  const selectedLanguage = useSelector(getSiteLanguage);
  const accessToFollowTopics = useSelector(getCanFollowTopic);

  useEffect(() => {
    if (accessToFollowTopics) {
      dispatch(fetchFollowedTopics({ offset: 0, limit: 8 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedLanguage, accessToFollowTopics]);

  if (!accessToFollowTopics) return null;

  return (
    <>
      <StyledTitle isLoading={isPending}>
        <StyledLink to={'/followed'}>
          {textDictionary['app.monitor.following_topics']}
          <StyledArrow />
        </StyledLink>
      </StyledTitle>
      {followingTopics.length === 0 && !isPending ? (
        <StyledContainer>
          <StyledPlaceholderCard>
            <StyledPlaceholderText>
              {textDictionary['app.about.key_points.personalized_content.text']}
            </StyledPlaceholderText>
            <StyledButton to={link}>{textDictionary['app.discover_topics.title']}</StyledButton>
          </StyledPlaceholderCard>
        </StyledContainer>
      ) : (
        <StyledContainer>
          <TopicSlider topics={followingTopics} />
        </StyledContainer>
      )}
    </>
  );
};
