import { combineReducers } from 'redux';

import { getTimestampFromDate } from 'common/utilities/formatDate';
import { CaseStudyAction, TopicCaseStudyActionTypes } from 'domains/caseStudy/actionTypes';
import { CaseStudy, CaseStudyState } from 'domains/caseStudy/types';
import { KnowledgeType } from 'entities/knowledge/types';
import { addUserChangeReset } from 'entities/user/utils';

const initialState: CaseStudyState = {
  forTopic: {},
  byId: {},
};

const caseStudiesById = (state = initialState.byId, action: CaseStudyAction): CaseStudyState['byId'] => {
  switch (action.type) {
    case TopicCaseStudyActionTypes.FETCH_CASE_STUDIES_SUCCESS: {
      const { topicId, data } = action.payload;

      // transforms the date from response to how we want to save it
      const caseStudiesWithTopicId: CaseStudy[] = data.map((caseStudy) => ({
        topicId: topicId,
        title: caseStudy.document.name,
        authors: caseStudy.document.authors,
        datePublished: caseStudy.document.datePublished,
        summary: caseStudy.document.plainDescription,
        outcome: caseStudy.document.outcome,
        name: caseStudy.document.name,
        link: caseStudy.document.sourceLink,
        id: caseStudy.metadata.id,
        source: undefined,
        time: getTimestampFromDate(caseStudy.document.datePublished),
        language: 'en',
        knowledgeType: KnowledgeType.publication,
        type: 'article',
        images: {
          x_small: caseStudy.document.photoUrl,
          thumbnail: caseStudy.document.photoUrl,
          medium: caseStudy.document.photoUrl,
          large: caseStudy.document.photoUrl,
        },
      }));

      const newCaseStudiesState: Record<string, CaseStudy> = {};
      caseStudiesWithTopicId.forEach((caseStudy) => {
        const useCaseId = caseStudy.id;
        newCaseStudiesState[useCaseId] = caseStudy;
      });

      return {
        ...state,
        ...newCaseStudiesState,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const caseStudiesForTopic = (state = initialState.forTopic, action: CaseStudyAction): CaseStudyState['forTopic'] => {
  switch (action.type) {
    case TopicCaseStudyActionTypes.FETCH_CASE_STUDIES: {
      return {
        ...state,
        [action.payload.topicId]: {
          ...state[action.payload.topicId],
          isPending: true,
          hasErrored: false,
        },
      };
    }
    case TopicCaseStudyActionTypes.FETCH_CASE_STUDIES_SUCCESS: {
      const caseStudiesForTopic = new Set(action.payload.data.map((caseStudy) => caseStudy.metadata.id));
      const { limit } = action.meta;
      state[action.payload.topicId]?.caseStudyIds?.forEach((id) => caseStudiesForTopic.add(id));

      return {
        ...state,
        [action.payload.topicId]: {
          caseStudyIds: Array.from(caseStudiesForTopic),
          isPending: false,
          hasErrored: false,
          hasNextPage: !(action.payload.data.length < limit),
        },
      };
    }
    case TopicCaseStudyActionTypes.FETCH_CASE_STUDIES_FAILURE: {
      return {
        ...state,
        [action.payload.topicId]: {
          ...state[action.payload.topicId],
          isPending: false,
          hasErrored: true,
        },
      };
    }
    default: {
      return { ...state };
    }
  }
};

export const caseStudiesReducer = combineReducers({
  forTopic: addUserChangeReset(caseStudiesForTopic, initialState),
  byId: addUserChangeReset(caseStudiesById, initialState),
});
