import { PropsWithChildren } from 'react';
import { styled } from 'styled-components';

import { Image, SourceIcon } from 'common/components';
import { lineTruncationStyle, breakpoints } from 'common/styles';
import { Source } from 'domains/clusters/types';
import { useTranslations } from 'domains/language/useTranslations';

const StyledNumbers = styled.p`
  margin: 0;
  font-size: 1rem;

  @media (${breakpoints.medium}) {
    margin: 1.125rem 0 0 0;
  }
`;

const StyledPublisherIcons = styled.div`
  margin: 0.5rem 0;
`;

const StyledPlaceholder = styled(Image)`
  margin-right: 0.3rem;
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  display: inline-block;
`;

const StyledNames = styled.p`
  margin: 0;
  max-height: 2.75rem;
  font-size: 1rem;
  width: 100%;
  ${lineTruncationStyle(1)};
`;

const StyledSourceIcon = styled(SourceIcon)`
  width: 2rem;
  height: 2rem;
  margin-right: 0.3rem;
  display: inline-block;
  color: ${({ theme }) => theme.colors.backgroundSecondary};
`;

type ClusterSourcesProps = PropsWithChildren & { daysCount: number; sources?: Source[]; sourcesCount: number };

export const ClusterSources = ({ daysCount, sources = [], sourcesCount }: ClusterSourcesProps) => {
  const { textDictionary, interpolate } = useTranslations();

  if (!sources) {
    return null;
  }

  const selectSourceNames = (sources: Source[], terms: Record<string, string>) => {
    const names = sources.map((source) => source.name);
    if (names.length <= 2) {
      return names.join(' and ');
    }
    return interpolate(terms['app.monitor.including_sources'], {
      source_one: () => names[0],
      source_two: () => names[1],
      count: () => names.length - 2,
    });
  };

  const sourceNames = selectSourceNames(sources, textDictionary);
  let sourcesText = null;

  if (sourcesCount && daysCount) {
    sourcesText = interpolate(textDictionary['app.monitor.source_over_time'], {
      sourcesCount: () => sourcesCount,
      daysCount: () => daysCount,
    });
  }

  return (
    <>
      {sourcesText && <StyledNumbers>{sourcesText}</StyledNumbers>}
      <StyledPublisherIcons>
        {sources.map((source, index) => (
          <StyledPlaceholder
            key={index}
            src={source.favicon}
            renderPlaceholder={() => <StyledSourceIcon />}
            alt={source.name}
          />
        ))}
      </StyledPublisherIcons>
      <StyledNames>{sourceNames}</StyledNames>
    </>
  );
};
