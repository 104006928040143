import { put } from 'redux-saga/effects';

import { fetchUsedCoachmarksSuccess } from 'domains/coachmarks/actions';
import { COACHMARKS_VIEWED_ITEMS_KEY } from 'domains/coachmarks/localStorageKeys';

export function* fetchUsedCoachmarksSaga() {
  let usedCoachmarks: string[] = [];
  try {
    const storedValue = localStorage.getItem(COACHMARKS_VIEWED_ITEMS_KEY);
    if (storedValue) {
      usedCoachmarks = JSON.parse(storedValue);
    }
  } catch (error) {
    console.error(error);
  }

  yield put(fetchUsedCoachmarksSuccess({ ids: usedCoachmarks }));
}
