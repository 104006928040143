import union from 'lodash.union';
import { combineReducers } from 'redux';

import { LanguageCode } from 'common/types/languageCode';
import { FetchLanguageAction, LanguageDomainActionType } from 'domains/language/actionTypes';
import { LanguageDomainState } from 'domains/language/types';

export const initialState: LanguageDomainState = {
  allIds: [],
  byId: {},
  isFetching: false,
  siteLanguage: LanguageCode.EN,
};

const allIds = (state = initialState.allIds, action: FetchLanguageAction) => {
  switch (action.type) {
    case LanguageDomainActionType.FETCH_FULFILLED:
      return union(
        state,
        action.payload.terms.map(({ term }) => term),
      );

    default:
      return state;
  }
};

const byId = (state = initialState.byId, action: FetchLanguageAction) => {
  switch (action.type) {
    case LanguageDomainActionType.FETCH_FULFILLED:
      return action.payload.terms.reduce(
        (byId, term) => ({
          ...byId,
          [term.term]: term.value,
        }),
        {},
      );
    default:
      return state;
  }
};

const isFetching = (state = initialState.isFetching, action: FetchLanguageAction) => {
  switch (action.type) {
    case LanguageDomainActionType.FETCH:
      return true;

    case LanguageDomainActionType.FETCH_FULFILLED:
    case LanguageDomainActionType.FETCH_REJECTED:
      return false;

    default:
      return state;
  }
};

const siteLanguage = (state = initialState.siteLanguage, action: FetchLanguageAction) => {
  switch (action.type) {
    case LanguageDomainActionType.FETCH:
    case LanguageDomainActionType.FETCH_FULFILLED:
      return action.meta.language;

    default:
      return state;
  }
};

export const languageReducer = combineReducers({ allIds, byId, isFetching, siteLanguage });
