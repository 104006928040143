import { Log, User } from 'oidc-client-ts';
import { PropsWithChildren, useEffect, useState } from 'react';
import { AuthProvider as OIDCAuthProvider, useAuth } from 'react-oidc-context';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { AuthLoader } from 'AuthLoader';
import { initialStoredUser, mapOIDCUserToTokenStorage, oidcConfig } from 'authUtils';
import { setCookie } from 'common/utilities/cookies';
import { fwsLogin, fwsTriggeredFirstTokenRenew, unauthenticate } from 'domains/authentication/actions';
import { getIsAuthenticating } from 'domains/authentication/selectors';
import { activateModal } from 'domains/modal/actions'; // Add logging of the oidc-client-ts library when in development
import { TokenManagerSingleton } from 'TokenManager';
import { IS_AUTHENTICATING_COOKIE } from 'useAuth';
import { WithRemoteRefreshTokenStorage } from 'WithRemoteRefreshTokenStorage';

// Add logging of the oidc-client-ts library when in development
if (process.env.NODE_ENV !== 'production') {
  Log.setLogger(console);
  // Uncomment this for super logging of the oidc-client-ts library
  // Log.setLevel(Log.DEBUG);
}

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();

  const onSigninCallback = (user: User | void) => {
    if (!user || !user.refresh_token || !user.id_token) {
      console.error('Something went wrong with the signin callback');
      setCookie(IS_AUTHENTICATING_COOKIE, 'false', 'path=/');
      return;
    }
    dispatch(fwsLogin(mapOIDCUserToTokenStorage(user)));
  };

  return (
    <OIDCAuthProvider
      {...oidcConfig}
      redirect_uri={`${process.env.REACT_APP_WEBSITE_URL}${pathname}${search}`}
      onSigninCallback={onSigninCallback}
      // The app-redirect route is for processing information just about
      // enough that we can send a state and one-time code onto the app,
      // so we don't want to accidentally consume the code for use on web.
      skipSigninCallback={pathname === '/app-redirect'}
      // We want to control this ourselves so we can tell the AuthLoader to
      // not display the spinner when silently renewing while the app is in use.
      automaticSilentRenew={false}
      userStore={new WithRemoteRefreshTokenStorage()}
    >
      <AuthTokenExpiryWrapper>{children}</AuthTokenExpiryWrapper>
    </OIDCAuthProvider>
  );
};

// A wrapper component so we can use `useAuth` to trigger the expiry
const AuthTokenExpiryWrapper = ({ children }: PropsWithChildren) => {
  const dispatch = useDispatch();
  const { events, isLoading, signinSilent, revokeTokens, removeUser } = useAuth();
  const isAuthenticating = useSelector(getIsAuthenticating);

  const [isSilentRenewing, setIsSilentRenewing] = useState(false);
  const [isInitialTokensExpired, setIsInitialTokensExpired] = useState(initialStoredUser?.expired || false);

  const tokenRefresh = async (refreshSilently = true) => {
    if (refreshSilently) {
      setIsSilentRenewing(true);
    }

    try {
      const user = await signinSilent();
      if (!user) {
        throw new Error('No user returned from silent refresh');
      }
      const tokenStorage = mapOIDCUserToTokenStorage(user);
      dispatch(fwsLogin(tokenStorage));
      return tokenStorage;
    } catch (e) {
      console.error(e);
      dispatch(unauthenticate());
      dispatch(activateModal({ id: 'Unauthenticated' }));
    } finally {
      setIsInitialTokensExpired(false);
      if (refreshSilently) {
        setIsSilentRenewing(false);
      }
    }
    return null;
  };

  const [deleteTokenEndpoint, setDeleteTokenEndpoint] = useState<string>('');

  const signOut = async () => {
    setDeleteTokenEndpoint(`${process.env.REACT_APP_DELETE_LOGINRADIUS_TOKENS_URL}`);

    await fetch(`${process.env.REACT_APP_FWS_LOGIN_BASE_URL}/ssologin/logout`, {
      credentials: 'include',
    });

    // Revoke tokens with FWS, then remove user
    // Even if revoking fails, we carry on with removing the user (since it
    // would be weird to stop the user from logging out)
    await revokeTokens(['access_token']).catch(console.error);
    await removeUser().catch(console.error);
  };

  useEffect(() => {
    // Capture the `tokenRefresh` and `signOut` function so we can use it
    // outside of React.
    TokenManagerSingleton.setTokenManagerFunctions({ tokenRefresh, signOut });

    // We do token refreshing immediately to ensure we don't load the app if we
    // know that we need to refresh the token first - the token expiry callback
    // works off a timer so it doesn't fire immediately.
    const runThroughTokenRefresh = async () => {
      if (isInitialTokensExpired) {
        dispatch(fwsTriggeredFirstTokenRenew());
        await TokenManagerSingleton.tokenRefresh(false);
      }
    };
    runThroughTokenRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isInitialTokensExpired) {
      return;
    }

    const tokenRefreshCallback = async () => {
      await TokenManagerSingleton.tokenRefresh();
    };
    const removeTokenExpiringCallback = events.addAccessTokenExpiring(tokenRefreshCallback);

    return () => {
      removeTokenExpiringCallback();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialTokensExpired]);

  const isAuthLoading = (isLoading || isInitialTokensExpired || isAuthenticating) && !isSilentRenewing;

  return (
    <AuthLoader isAuthLoading={isAuthLoading}>
      {children}
      <iframe
        style={{ height: 1, width: 1, border: 'none', position: 'absolute' }}
        src={deleteTokenEndpoint}
        title={'logout user'}
      />
    </AuthLoader>
  );
};
