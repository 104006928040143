import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import {
  CustomMapOwner,
  fetchMyMapsSuccess,
  fetchSharedWithMeSuccess,
  fetchTopicsFailure,
  fetchTopicsSuccess,
} from 'entities/topics/actions';
import { FetchTopicsDefaultAction } from 'entities/topics/actionTypes';
import { Topic } from 'entities/topics/types';
import { isFetchError } from 'TypeCheckMethods';

export function* fetchTopicsOfTypeSaga(action: FetchTopicsDefaultAction) {
  const { type, limit, offset, canEdit, owner } = action.payload;
  try {
    const topics: Topic[] = yield apiFetch('topic/curated', {
      queryParams: {
        types: type,
        offset,
        limit,
        can_edit: canEdit ? 'true' : 'false',
        owner,
      },
    });

    yield put(
      fetchTopicsSuccess({
        topics,
        type,
        limit,
        offset,
        canEdit,
      }),
    );

    const topicIds = topics.map((topic) => topic.id);
    if (owner === CustomMapOwner.MyMaps) {
      yield put(fetchMyMapsSuccess({ topicIds, limit, offset }));
    }
    if (owner === CustomMapOwner.SharedWithMe) {
      yield put(fetchSharedWithMeSuccess({ topicIds, limit, offset }));
    }
  } catch (error) {
    if (isFetchError(error)) {
      yield put(fetchTopicsFailure({ type, error }, { logoutOnAuthError: true }));
    }
  }
}
