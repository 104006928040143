import { useDispatch, useSelector } from 'react-redux';

import { SectionTitleUnderlined, UIButton } from 'common/components';
import { useTranslations } from 'domains/language/useTranslations';
import { pushModalScreen } from 'domains/modal/actions';
import { ModalHeader } from 'domains/modal/components/ModalHeader';
import { FilterTags } from 'domains/monitor/filter/components/FilterTags';
import { getTopicsFilterSelectedIds } from 'domains/monitor/filter/selectors';
import { getFollowedTopics } from 'entities/topics/selectors';

export const MonitorFilterModal = () => {
  const dispatch = useDispatch();
  const { textDictionary } = useTranslations();
  const items = useSelector(getFollowedTopics);
  const selectedItems = useSelector(getTopicsFilterSelectedIds);

  const manageTopicHandle = () =>
    dispatch(
      pushModalScreen({
        id: 'Manage topics',
      }),
    );

  return (
    <>
      <ModalHeader title={textDictionary['app.filter.title']} />
      <SectionTitleUnderlined>{textDictionary['app.monitor.following_topics']}</SectionTitleUnderlined>
      <FilterTags items={items} selectedItems={selectedItems} />
      <UIButton onClick={manageTopicHandle} style={{ marginTop: '2rem' }} variant={'filled'}>
        {textDictionary['app.discover.manage_topics']}
      </UIButton>
    </>
  );
};
