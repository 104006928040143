import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { FilterButton, MasterDetailLayout } from 'common/components';
import { getPageId } from 'common/utilities';
import { trackEvent } from 'domains/analytics/actions';
import { ClusterDetail } from 'domains/clusters/components/ClusterDetail';
import { ClusterList } from 'domains/clusters/components/ClusterList';
import { getClusterById } from 'domains/clusters/selectors';
import { getSiteLanguage } from 'domains/language/selectors';
import { useTranslations } from 'domains/language/useTranslations';
import { activateModal } from 'domains/modal/actions';
import { fetchEmergingThemes } from 'domains/monitor/emergingThemes/actions';
import {
  getEmergingThemesItems,
  getEmergingThemesIsPending,
  getEmergingThemesOffset,
  getEmergingThemesHasNextPage,
} from 'domains/monitor/emergingThemes/selectors';
import { getTopicsFilterSelectedIds } from 'domains/monitor/filter/selectors';
import { getFollowedTopics } from 'entities/topics/selectors';
import { getCanAccessMonitor } from 'entities/user/selectors';

export const ClusterPage = () => {
  const dispatch = useDispatch();
  const { clusterId } = useParams<{ clusterId: string }>();
  const { textDictionary } = useTranslations();
  const items = useSelector(getEmergingThemesItems);
  const selectedTopics = useSelector(getTopicsFilterSelectedIds);
  const isPending = useSelector(getEmergingThemesIsPending);
  const offset = useSelector(getEmergingThemesOffset);
  const hasNextPage = useSelector(getEmergingThemesHasNextPage);
  const id = getPageId(clusterId, items);
  const hasAccessToMonitor = useSelector(getCanAccessMonitor);
  const cluster = useSelector(getClusterById(id));
  const followingTopics = useSelector(getFollowedTopics);
  const language = useSelector(getSiteLanguage);

  const selectedTopicsDependency = selectedTopics.join();

  useEffect(() => {
    if (items.length === 0) {
      dispatch(
        fetchEmergingThemes({
          offset: 0,
          filters: { topic: selectedTopics },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, items.length, selectedTopicsDependency]);

  useEffect(() => {
    if (items.length !== 0) {
      dispatch(
        fetchEmergingThemes({
          offset: 0,
          filters: { topic: selectedTopics },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, hasAccessToMonitor, language, selectedTopicsDependency]);

  const clusterTitle = cluster && cluster.title;
  useEffect(() => {
    if (!clusterId || !clusterTitle) {
      return;
    }
    dispatch(
      trackEvent({
        name: 'Browse page',
        page: 'Monitor',
        subpage: 'Cluster detail',
        entityId: clusterId,
        entityName: clusterTitle,
        entityType: 'Cluster',
      }),
    );
  }, [dispatch, clusterId, clusterTitle]);

  useEffect(() => {
    if (clusterId) {
      return;
    }
    dispatch(
      trackEvent({
        name: 'Browse page',
        page: 'Monitor',
        subpage: 'Emerging Themes',
      }),
    );
  }, [dispatch, clusterId]);

  const loadNextPage = useCallback(() => {
    dispatch(
      fetchEmergingThemes({
        offset: offset + 1,
        filters: { topic: selectedTopics },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, offset, selectedTopics.length]);

  const list = (
    <ClusterList
      items={items}
      hasNextPage={hasNextPage && hasAccessToMonitor}
      isPending={isPending}
      isCompact={true}
      selectedId={id}
      loadNextPage={loadNextPage}
      trackPage="Cluster detail"
      isOnCard={true}
    />
  );

  const openFilterModal = () =>
    dispatch(
      activateModal({
        id: 'Monitor filter',
      }),
    );

  const filterButton =
    followingTopics.length !== 0 && hasAccessToMonitor ? (
      <FilterButton count={selectedTopics.length} onClick={openFilterModal} />
    ) : undefined;

  return (
    <MasterDetailLayout
      listTitle={textDictionary['app.monitor.emerging_themes']}
      listComponent={list}
      listHeaderRightElement={filterButton}
      mainView={clusterId ? 'right' : 'left'}
      listBackNavigation={{
        label: textDictionary['app.monitor.title'],
        path: '/monitor',
      }}
      detailBackNavigation={{
        label: textDictionary['app.monitor.emerging_themes'],
        path: '/monitor/emerging-themes',
      }}
    >
      <ClusterDetail cluster={cluster} id={id} />
    </MasterDetailLayout>
  );
};
