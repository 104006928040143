import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { fetchFPKnowledgeFulfilled, fetchFPKnowledgeRejected } from 'domains/customKeyIssue/actions';
import { CustomKeyIssueFPKnowledgeAction } from 'domains/customKeyIssue/actionTypes';
import { Knowledge } from 'entities/knowledge/types';

export function* fetchFPKnowledgeSaga({ payload }: CustomKeyIssueFPKnowledgeAction) {
  try {
    if (!payload.fp.length) {
      yield put(fetchFPKnowledgeFulfilled([]));
      return;
    }
    const response: Knowledge[] = yield apiFetch(`topic/fp/knowledge`, {
      body: payload,
      method: 'POST',
      queryParams: { limit: 30 },
    });
    yield put(fetchFPKnowledgeFulfilled(response));
  } catch (error) {
    yield put(fetchFPKnowledgeRejected());
  }
}
