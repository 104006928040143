import { Cluster } from 'domains/clusters/types';

export enum EmergingThemesActionType {
  FETCH = '[Emerging themes] - Fetch',
  FETCH_FULFILLED = '[Emerging themes] - Fetch - Fulfilled',
  FETCH_REJECTED = '[Emerging themes] - Fetch - Rejected',
}

type EmergingThemesMeta = { filters?: Record<string, string[]>; offset: number };
export type EmergingThemesDefaultAction = { meta: EmergingThemesMeta; type: EmergingThemesActionType.FETCH };

export type EmergingThemesFulfilledAction = {
  meta: EmergingThemesMeta;
  payload: Cluster[];
  type: EmergingThemesActionType.FETCH_FULFILLED;
};

export type EmergingThemesRejectedAction = { meta: EmergingThemesMeta; type: EmergingThemesActionType.FETCH_REJECTED };

export type EmergingThemesAction =
  | EmergingThemesDefaultAction
  | EmergingThemesFulfilledAction
  | EmergingThemesRejectedAction;
