import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { UISwitch } from 'common/components';
import { useTranslations } from 'domains/language/useTranslations';
import { updateSearchSectionVisibility } from 'domains/search/actions';
import { getSearchSectionVisibility } from 'domains/search/selectors';
import { SearchVisibilitySectionType } from 'domains/search/types';
import { getCanAccessCreate, getCanSeeSectionContent } from 'entities/user/selectors';

const StyledItem = styled.a<{ hasAccess?: boolean }>`
  margin-bottom: 10px;
  text-decoration: none;
  display: block;
  position: relative;
  filter: ${({ hasAccess }) => (hasAccess ? 'none' : 'blur(3px)')};
`;

const filters: Partial<Record<SearchVisibilitySectionType, string>> = {
  topics: 'app.discover.topics.title',
  keyIssues: 'app.topic.key_issues',
  publications: 'app.discover.topic.tabs.knowledge.title',
  videos: 'app.discover.topic.tabs.videos.title',
  interactiveData: 'app.discover.topic.tabs.interactive_data.title',

  // TODO: Add when endpoints are available

  // emergingThemes: "Emerging Themes",
  // events: "Events",
  // initiatives: "Initiatives",
  // stakeholders: "Stakeholders",
};

export const SearchFilter = () => {
  const dispatch = useDispatch();
  const { textDictionary } = useTranslations();
  const sectionVisibility = useSelector(getSearchSectionVisibility);
  const accessToKeyIssueList = useSelector(getCanAccessCreate);
  const allFilters = Object.keys(filters) as SearchVisibilitySectionType[];
  const accessToTabContent = useSelector(getCanSeeSectionContent);

  const accessRights: Partial<Record<SearchVisibilitySectionType, boolean>> = {
    topics: true,
    keyIssues: accessToKeyIssueList,
    publications: accessToTabContent,
    videos: accessToTabContent,
    interactiveData: accessToTabContent,
  };

  const onClick = (id: SearchVisibilitySectionType) => () => dispatch(updateSearchSectionVisibility({ section: id }));

  return (
    <div style={{ marginBottom: '1rem' }}>
      {allFilters.map((key) => {
        const localizedKey = filters[key];
        return (
          <StyledItem key={key} href={`#${key}`} hasAccess={accessRights[key]}>
            <UISwitch
              isOn={!!accessRights[key] && sectionVisibility[key]}
              onClick={onClick(key)}
              isDisabled={accessRights[key] === false}
            >
              {localizedKey && <>{textDictionary[localizedKey]}</>}
            </UISwitch>
          </StyledItem>
        );
      })}
    </div>
  );
};
