import { CreateMapTypes, CustomMapAction, EditMapAction } from 'domains/createMapDetail/actionTypes';
import { CreateMapState, FakeProgressStatuses } from 'domains/createMapDetail/types';
import { LoadCustomKeyIssueFulfilledAction } from 'domains/customKeyIssue/actionTypes';
import { TopicDetailAction, TopicDetailType } from 'domains/topicDetail/actionTypes';

export const initialState: CreateMapState = {
  isPending: false,
  error: false,
  name: '',
  description: '',
  keyIssues: [],
  newCustomKeyIssues: [],
  isDraggingKeyIssue: false,
  isUploadFulfilled: false,
  isAIGeneratedModeOn: false,
  AIGeneratedMapProgress: { status: FakeProgressStatuses['Not started'] },
  isAIGeneratedMapFetching: false,
  AIGeneratedMapErrorCode: null,
  AIGeneratedMapIgnoreResponse: false,
};

export const createMapReducer = (
  state = initialState,
  action: CustomMapAction | EditMapAction | LoadCustomKeyIssueFulfilledAction | TopicDetailAction,
): CreateMapState => {
  switch (action.type) {
    case CreateMapTypes.INIT_FULFILLED: {
      return {
        ...state,
        data: action.payload,
        keyIssues: (action.payload.dimensions || []).map((topic) => topic.id),
        name: action.payload.name || '',
        description: action.payload.description || '',
        newCustomKeyIssues: [],
      };
    }
    case CreateMapTypes.SET: {
      const { name, description, invitations, is_public } = action.meta;
      return {
        ...state,
        name: name !== undefined ? name : state.name,
        description: description !== undefined ? description : state.description,
        data: {
          ...state.data,
          invitations: invitations || state.data?.invitations,
          is_public: is_public || state.data?.is_public,
        },
      };
    }
    case CreateMapTypes.SET_IMAGE: {
      // Ignore response variable will be set tot false in
      // GET_AI_GENERATED_DETAILS_SUCCESS reducer case, which runs right after
      if (state.AIGeneratedMapIgnoreResponse) {
        return state;
      }
      return {
        ...state,
        image: action.meta.image,
      };
    }
    case CreateMapTypes.UPLOAD: {
      return {
        ...state,
        isPending: true,
        isUploadFulfilled: false,
      };
    }
    case CreateMapTypes.UPLOAD_FULFILLED: {
      return {
        ...state,
        isPending: false,
        error: false,
        isUploadFulfilled: true,
        image: undefined,
        data: {
          ...(state.data || {}),
          ...(action.payload as any),
        },
        newCustomKeyIssues: [],
      };
    }
    case CreateMapTypes.UPLOAD_REJECTED: {
      return {
        ...state,
        isPending: false,
        error: true,
      };
    }
    case CreateMapTypes.ADD_KEY_ISSUE_FULFILLED: {
      const issues = state.keyIssues.filter((id) => id !== action.payload.id);
      return {
        ...state,
        keyIssues: [...issues, action.payload.id],
      };
    }
    case CreateMapTypes.REMOVE_KEY_ISSUE: {
      return {
        ...state,
        keyIssues: state.keyIssues.filter((id) => id !== action.payload.keyIssueId),
        newCustomKeyIssues: state.newCustomKeyIssues.filter(({ id }) => id !== action.payload.keyIssueId),
      };
    }
    case CreateMapTypes.REMOVE_ALL_KEY_ISSUES: {
      return {
        ...state,
        keyIssues: [],
        newCustomKeyIssues: [],
      };
    }
    case CreateMapTypes.ADD_NEW_CUSTOM_KEY_ISSUE: {
      const keyIssue = action.payload;
      const otherNewCustomKeyIssues = state.newCustomKeyIssues.filter(({ id }) => id !== keyIssue.id);
      const otherNewKeyIssueIds = state.keyIssues.filter((id) => id !== keyIssue.id);

      return {
        ...state,
        keyIssues: [...otherNewKeyIssueIds, keyIssue.id],
        newCustomKeyIssues: [...otherNewCustomKeyIssues, keyIssue],
      };
    }
    case CreateMapTypes.SET_DRAGGING: {
      return {
        ...state,
        isDraggingKeyIssue: action.meta.isDragging,
      };
    }
    case CreateMapTypes.ENTER_AI_GENERATED_MODE: {
      return {
        ...state,
        isAIGeneratedModeOn: true,
        AIGeneratedMapErrorCode: null,
      };
    }
    case CreateMapTypes.EXIT_AI_GENERATED_MODE: {
      return {
        ...state,
        isAIGeneratedModeOn: false,
        isAIGeneratedMapFetching: false,
        AIGeneratedMapErrorCode: null,
        AIGeneratedMapIgnoreResponse: state.isAIGeneratedMapFetching,
      };
    }
    case CreateMapTypes.GET_AI_GENERATED_PROGRESS_SUCCESS: {
      if (state.AIGeneratedMapIgnoreResponse) {
        return state;
      }
      return {
        ...state,
        AIGeneratedMapProgress: action.meta,
      };
    }
    case TopicDetailType.INIT: {
      return {
        ...state,
        AIGeneratedMapProgress: { status: FakeProgressStatuses['Not started'] },
      };
    }
    case CreateMapTypes.RESET:
      return initialState;
    case TopicDetailType.GET_AI_GENERATED_DETAILS: {
      return {
        ...state,
        isAIGeneratedMapFetching: true,
        AIGeneratedMapErrorCode: null,
      };
    }
    case TopicDetailType.GET_AI_GENERATED_DETAILS_SUCCESS: {
      if (state.AIGeneratedMapIgnoreResponse) {
        return {
          ...state,
          isAIGeneratedMapFetching: false,
          AIGeneratedMapIgnoreResponse: false,
        };
      }
      return {
        ...state,
        keyIssues: (action.payload.dimensions || []).map((topic) => topic.id),
        name: action.payload.name || '',
        description: action.payload.description || '',
        newCustomKeyIssues: [],
        isAIGeneratedMapFetching: false,
        isAIGeneratedModeOn: false,
      };
    }
    case TopicDetailType.GET_AI_GENERATED_DETAILS_FAILURE: {
      return {
        ...state,
        isAIGeneratedMapFetching: false,
      };
    }
    case TopicDetailType.GET_AI_GENERATED_DETAILS_NOT_VALID_PROMPT: {
      if (state.AIGeneratedMapIgnoreResponse) {
        return {
          ...state,
          isAIGeneratedMapFetching: false,
        };
      }
      return {
        ...state,
        isAIGeneratedMapFetching: false,
        AIGeneratedMapErrorCode: action.errorCode,
      };
    }
    default:
      return state;
  }
};
