import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from 'styled-components';

import { breakpoints } from 'common/styles';
import { ClusterList } from 'domains/clusters/components/ClusterList';
import { fetchEmergingThemes } from 'domains/monitor/emergingThemes/actions';
import {
  getEmergingThemesItems,
  getEmergingThemesIsPending,
  getEmergingThemesOffset,
  getEmergingThemesHasNextPage,
} from 'domains/monitor/emergingThemes/selectors';
import { getTopicsFilterSelectedIds } from 'domains/monitor/filter/selectors';
import { getCanAccessMonitor } from 'entities/user/selectors';

const StyledList = styled.div`
  display: none;
  margin: 0;

  @media ${breakpoints.medium} {
    display: initial;
  }
`;

export const EmergingThemesList = () => {
  const dispatch = useDispatch();
  const items = useSelector(getEmergingThemesItems);
  const selectedTopics = useSelector(getTopicsFilterSelectedIds);
  const isPending = useSelector(getEmergingThemesIsPending);
  const offset = useSelector(getEmergingThemesOffset);
  const hasNextPage = useSelector(getEmergingThemesHasNextPage);
  const hasAccessToMonitor = useSelector(getCanAccessMonitor);

  const selectedTopicsDependency = selectedTopics.join();
  const loadNextPage = useCallback(() => {
    dispatch(
      fetchEmergingThemes({
        offset: offset + 1,
        filters: { topic: selectedTopics },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, offset, selectedTopicsDependency]);

  return (
    <StyledList>
      <ClusterList
        items={items}
        hasNextPage={hasNextPage && hasAccessToMonitor}
        isPending={isPending}
        loadNextPage={loadNextPage}
        trackPage="Monitor"
        isOnCard={false}
      />
    </StyledList>
  );
};
