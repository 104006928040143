export const logResponseError = (
  requestData: Record<string, any>,
  response: Response,
  acceptableErrorCodes = [401],
) => {
  if (acceptableErrorCodes.includes(response.status)) {
    return;
  }
  const responseData = {
    url: response.url,
    status: response.status,
    headers: response.headers,
  };
  console.error(responseData);
};
