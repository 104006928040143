import { styled } from 'styled-components';

const StyledHighlight = styled.span`
  color: white;
  background-color: ${({ theme }) => theme.colors.blue};
`;

const highlightText = (text: string, highlightWord?: string) => {
  if (!highlightWord) {
    return text;
  }

  const chunks = [];
  const lowercaseHighlightWord = highlightWord.toLowerCase();

  while (text.length > 0) {
    const matchIndex = text.toLowerCase().indexOf(lowercaseHighlightWord);

    if (matchIndex < 0) {
      chunks.push(<span key={`${chunks.length}span`}>{text}</span>);
      break;
    }

    // Slice up to the highlight word and add it to the chunks
    const chunk = text.substr(0, matchIndex);
    chunks.push(<span key={`${chunks.length}span`}>{chunk}</span>);

    // Remove text from text
    text = text.substr(matchIndex);

    // Add the highlight word to the chunks
    chunks.push(
      <StyledHighlight key={`${chunks.length}highlight`}>{text.substr(0, highlightWord.length)}</StyledHighlight>,
    );

    // Remove highlight word from text
    text = text.substr(highlightWord.length);
  }

  return chunks.length ? chunks : text;
};

type HighlightedTextProps = { highlightedTerm?: string; text: string };

export const HighlightedText = ({ text, highlightedTerm }: HighlightedTextProps) => (
  <>{highlightText(text, highlightedTerm)}</>
);
