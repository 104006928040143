import { Permissions } from 'domains/collection/components/edit/types/permissions';
import { Community } from 'domains/collection/types';
import { Translation } from 'domains/collection/types/translation';

export const coBrandsEqual = (a: Community[], b: Community[]): boolean =>
  a.length === b.length && a.every((item) => b.some(({ id }) => item.id === id));

export const permissionsEqual = (a: Permissions, b: Permissions) =>
  a.isFeatured === b.isFeatured && a.isListed === b.isListed && a.isPublic === b.isPublic;

export const topicIdsEqual = (a: string[], b: string[]) =>
  a.length === b.length && a.every((x) => b.some((y) => x === y));

export const translationsEqual = (a: Required<Translation>, b: Required<Translation>) => {
  for (const untypedKey in a) {
    const key = untypedKey as keyof typeof a;
    if (a[key].text !== b[key].text) return false;
  }

  return true;
};
