import { SVGProps } from 'react';

export const StatisticsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <g fill="currentColor">
      <path d="M28.257 11.943h-7.233a1.601 1.601 0 00-1.6 1.6v3.685h-6.743a1.602 1.602 0 00-1.6 1.599v11.195H9.686a1.043 1.043 0 000 2.086h29.91a1.043 1.043 0 100-2.086H38.2v-5.91a1.601 1.601 0 00-1.599-1.6h-6.744v-8.97a1.602 1.602 0 00-1.6-1.599zm-15.09 7.37h6.249v10.709h-6.249V19.313zm8.344-5.284h6.257v15.993h-6.257V14.029zm14.603 10.569v5.424h-6.258v-5.424h6.258z" />
      <path d="M24 0a24 24 0 1024 24A24.026 24.026 0 0024 0zm0 45.912A21.911 21.911 0 1145.912 24 21.936 21.936 0 0124 45.912z" />
    </g>
  </svg>
);
