import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { styled } from 'styled-components';

import { MasterDetailLayout } from 'common/components';
import { getPageId } from 'common/utilities';
import { fetchBookmarks } from 'domains/bookmarks/actions';
import { BookmarkList } from 'domains/bookmarks/components/BookmarksList';
import {
  getBookmarksList,
  getBookmarkOffset,
  getBookmarkIsPending,
  getBookmarkHasNextPage,
  getBookmarkById,
} from 'domains/bookmarks/selectors';
import { ClusterDetail } from 'domains/clusters/components/ClusterDetail';
import { useTranslations } from 'domains/language/useTranslations';
import { KnowledgeDetail } from 'domains/publication/components/KnowledgeDetail';
import { useRouterLink } from 'domains/routing/utils';

const StyledContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  height: 100%;
  color: ${({ theme }) => theme.colors.fillPrimary};
  overflow: hidden;
`;

const StyledLink = styled(Link)`
  transition: color 0.3s;
  text-decoration: none;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.blue};

  &:hover {
    color: ${({ theme }) => theme.colors.blueDark};
  }
`;

export const BookmarksPage = () => {
  const dispatch = useDispatch();
  const { textDictionary, interpolate } = useTranslations();
  const items = useSelector(getBookmarksList());
  const offset = useSelector(getBookmarkOffset);
  const isPending = useSelector(getBookmarkIsPending);
  const hasNextPage = useSelector(getBookmarkHasNextPage);
  const { bookmarkId } = useParams<{ bookmarkId?: string }>();
  const id = getPageId(bookmarkId, items);
  const bookmark = useSelector(getBookmarkById(id));
  const monitorLink = useRouterLink({ to: 'monitor' });

  const loadNextPage = useCallback(() => dispatch(fetchBookmarks({ offset: offset + 1 })), [dispatch, offset]);

  useEffect(() => {
    dispatch(fetchBookmarks({ offset: 0 }));
  }, [dispatch]);

  const list =
    items.length === 0 && !isPending ? (
      <p>
        {interpolate(textDictionary['app.bookmarks.empty_message'], {
          monitor: (value) => <StyledLink to={monitorLink}>{value}</StyledLink>,
        })}
      </p>
    ) : (
      <BookmarkList
        items={items}
        hasNextPage={hasNextPage}
        isPending={isPending}
        selectedId={id}
        loadNextPage={loadNextPage}
      />
    );

  return (
    <StyledContainer>
      <MasterDetailLayout
        listTitle={textDictionary['app.bookmarks.title']}
        listComponent={list}
        mainView={bookmarkId ? 'right' : 'left'}
        listBackNavigation={{
          label: textDictionary['app.navigation.back'],
          path: '/',
        }}
        detailBackNavigation={{
          label: textDictionary['app.bookmarks.title'],
          path: '/bookmarks',
        }}
      >
        <>
          {bookmark?.type === 'cluster' && <ClusterDetail id={id} cluster={bookmark} />}
          {bookmark?.type === 'article' && (
            <KnowledgeDetail
              id={id}
              knowledge={bookmark}
              shareLink={`${process.env.REACT_APP_WEBSITE_URL}/monitor/latest-knowledge/${bookmark?.id}`}
            />
          )}
        </>
      </MasterDetailLayout>
    </StyledContainer>
  );
};
