import { SVGProps } from 'react';

export const UploadImageIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16" {...props}>
    <path
      d="M182.422 112.526a2.391 2.391 0 0 0 0-3.379l-1.258-1.257a2.389 2.389 0 0 0-3.379 0l-9.612 9.613a2.387 2.387 0 0 0-.675 1.351l-.36 2.518a1.592 1.592 0 0 0 1.8 1.8l2.518-.359a2.392 2.392 0 0 0 1.351-.676zm-5.7-1.319 2.384 2.384-7.422 7.422a.8.8 0 0 1-.45.225l-2.518.359.36-2.517a.792.792 0 0 1 .225-.451zm3.51 1.258-2.384-2.384 1.066-1.065a.8.8 0 0 1 1.126 0l1.258 1.257a.8.8 0 0 1 0 1.127z"
      transform="translate(-167.122 -107.19)"
      fillRule="evenodd"
    />
  </svg>
);
