export enum CoachmarkDomainsActionType {
  TOGGLE = 'domains/COACHMARKS_TOGGLE',
  INIT = 'domains/COACHMARKS_INIT',
  ADD_TO_USED = 'domains/COACHMARKS_ADD_TO_USED',
  REMOVE_FROM_USED = 'domains/COACHMARKS_REMOVE_FROM_USED',
  ADD_TO_IN_DOM = 'domains/COACHMARKS_ADD_TO_IN_DOM',
  FETCH_USED = 'domains/COACHMARKS_FETCH_USED',
  FETCH_USED_SUCCESS = 'domains/COACHMARKS_FETCH_USED_SUCCESS',
  FETCH_VISIBILITY_STATUS = 'domains/COACHMARKS_FETCH_VISIBILITY_STATUS',
  FETCH_VISIBILITY_STATUS_SUCCESS = 'domains/COACHMARKS_FETCH_VISIBILITY_STATUS_SUCCESS',
}

export type ToggleCoachmarksAction = { type: CoachmarkDomainsActionType.TOGGLE };
export type InitCoachmarksAction = { type: CoachmarkDomainsActionType.INIT };
export type AddCoachmarksToUsedAction = { payload: { ids: string[] }; type: CoachmarkDomainsActionType.ADD_TO_USED };

export type RemoveCoachmarksFromUsedAction = {
  payload?: { ids: string[] };
  type: CoachmarkDomainsActionType.REMOVE_FROM_USED;
};

export type AddCoachmarksToInDOMAction = { payload: { ids: string[] }; type: CoachmarkDomainsActionType.ADD_TO_IN_DOM };
export type FetchUsedCoachmarksAction = { type: CoachmarkDomainsActionType.FETCH_USED };

export type FetchUsedCoachmarksSuccessAction = {
  payload: { ids: string[] };
  type: CoachmarkDomainsActionType.FETCH_USED_SUCCESS;
};

export type FetchCoachmarksVisibilityStatusAction = { type: CoachmarkDomainsActionType.FETCH_VISIBILITY_STATUS };

export type FetchCoachmarksVisibilityStatusSuccessAction = {
  payload: boolean;
  type: CoachmarkDomainsActionType.FETCH_VISIBILITY_STATUS_SUCCESS;
};

export type CoachmarkDomainAction =
  | ToggleCoachmarksAction
  | InitCoachmarksAction
  | AddCoachmarksToUsedAction
  | RemoveCoachmarksFromUsedAction
  | AddCoachmarksToInDOMAction
  | FetchUsedCoachmarksAction
  | FetchUsedCoachmarksSuccessAction
  | FetchCoachmarksVisibilityStatusAction
  | FetchCoachmarksVisibilityStatusSuccessAction;
