import { AnalyticsActionMeta } from 'domains/analytics/types';
import { CollectionIdOnly, CollectionMeta } from 'domains/collection/types';
import { TopicsMeta } from 'entities/topics/actions';
import {
  FetchCustomTopicsPayload,
  FetchFollowedTopicsPayload,
  FetchTopicRejectedPayload,
  FetchTopicsForCollectionPayload,
  FetchTopicsPayload,
  FollowTopicPayload,
  RemoveMapMeta,
  TopicFulfilledPayload,
  TopicListPayload,
  TopicListRejectedPayload,
  TopicPayload,
} from 'entities/topics/types';
import { ErrorMeta } from 'types';

export enum TopicActionType {
  FETCH_TOPIC = 'entities/TOPICS_FETCH',
  FETCH_TOPIC_FULFILLED = 'entities/TOPICS_FETCH_SUCCESS',
  FETCH_TOPIC_REJECTED = 'entities/TOPICS_FETCH_FAILURE',
  FETCH_HIGHLIGHTS = 'entities/TOPICS_FETCH_HIGHLIGHTED',
  FETCH_HIGHLIGHTS_FULFILLED = 'entities/TOPICS_FETCH_HIGHLIGHTED_SUCCESS',
  FETCH_HIGHLIGHTS_REJECTED = 'entities/TOPICS_FETCH_HIGHLIGHTED_FAILURE',
  FETCH_OF_TYPE = 'entities/TOPICS_FETCH_OF_TYPE',
  FETCH_TOPICS_FULFILLED = 'entities/TOPICS_FETCH_OF_TYPE_SUCCESS',
  FETCH_TOPICS_REJECTED = 'entities/TOPICS_FETCH_OF_TYPE_FAILURE',
  FOLLOW = '[Follow] TOPICS_FOLLOW',
  FOLLOW_FULFILLED = '[Follow] TOPICS_FOLLOW_SUCCESS',
  FOLLOW_REJECTED = '[Follow] TOPICS_FOLLOW_FAILURE',
  UNFOLLOW = '[Follow] TOPICS_UNFOLLOW',
  UNFOLLOW_FULFILLED = '[Follow] TOPICS_UNFOLLOW_SUCCESS',
  UNFOLLOW_REJECTED = '[Follow] TOPICS_UNFOLLOW_FAILURE',
  FETCH_FOLLOWED_LIST = '[Follow] TOPICS_FETCH_FOLLOWED',
  FETCH_FOLLOWED_LIST_FULFILLED = '[Follow] TOPICS_FETCH_FOLLOWED_SUCCESS',
  FETCH_FOLLOWED_LIST_REJECTED = '[Follow] TOPICS_FETCH_FOLLOWED_FAILURE',
  REMOVE_MAP = 'entities/TOPICS_REMOVE_MAP',
  REMOVE_MAP_FULFILLED = 'entities/TOPICS_REMOVE_MAP_SUCCESS',
  REMOVE_MAP_REJECTED = 'entities/TOPICS_REMOVE_MAP_FAILURE',
  TOGGLE_TOPIC_MACHINE_TRANSLATION = '[Topics] - Toggle Machine Translations',
  FETCH_TOPICS_FOR_COLLECTION = '[Collections] Fetch Topics for Collection',
  FETCH_TOPICS_FOR_COLLECTION_SUCCESS = '[Collection] Fetch Topics for Collection Success',
  FETCH_TOPICS_FOR_COLLECTION_FAILURE = '[Collection] Fetch Topics for Collection Failure',
  FETCH_MY_MAPS = '[My Maps] FETCH_MY_MAPS',
  FETCH_MY_MAPS_FULFILLED = '[My Maps] FETCH_MY_MAPS_FULFILLED',
  FETCH_MY_MAPS_REJECTED = '[My Maps] FETCH_MY_MAPS_REJECTED',
  FETCH_SHARED_WITH_ME = '[Shared with me] FETCH_SHARED_WITH_ME',
  FETCH_SHARED_WITH_ME_FULFILLED = '[Shared with me] FETCH_SHARED_WITH_ME_FULFILLED',
  FETCH_SHARED_WITH_ME_REJECTED = '[Shared with me] FETCH_SHARED_WITH_ME_REJECTED',
}

export type FetchTopicDefaultAction = { payload: TopicPayload; type: TopicActionType.FETCH_TOPIC };
export type FetchTopicFulfilledAction = { payload: TopicFulfilledPayload; type: TopicActionType.FETCH_TOPIC_FULFILLED };

export type FetchTopicRejectedAction = {
  meta: ErrorMeta;
  payload: FetchTopicRejectedPayload;
  type: TopicActionType.FETCH_TOPIC_REJECTED;
};

export type FetchHighlightsDefaultAction = { type: TopicActionType.FETCH_HIGHLIGHTS };

export type FetchHighlightsFulfilledAction = {
  payload: FetchTopicsPayload;
  type: TopicActionType.FETCH_HIGHLIGHTS_FULFILLED;
};

export type FetchHighlightsRejectedAction = {
  meta: ErrorMeta;
  payload: { error: Error };
  type: TopicActionType.FETCH_HIGHLIGHTS_REJECTED;
};

export type FetchTopicsDefaultAction = { payload: TopicListPayload; type: TopicActionType.FETCH_OF_TYPE };
export type FetchTopicsFulfilledAction = { payload: TopicListPayload; type: TopicActionType.FETCH_TOPICS_FULFILLED };

export type FetchTopicsRejectedAction = {
  meta: ErrorMeta;
  payload: TopicListRejectedPayload;
  type: TopicActionType.FETCH_TOPICS_REJECTED;
};

export type FollowTopicDefaultAction = {
  meta?: AnalyticsActionMeta;
  payload: FollowTopicPayload;
  type: TopicActionType.FOLLOW;
};

export type FollowTopicFulfilledAction = {
  meta?: AnalyticsActionMeta;
  payload: FollowTopicPayload;
  type: TopicActionType.FOLLOW_FULFILLED;
};

export type FollowTopicRejectedAction = {
  meta?: ErrorMeta;
  payload: FollowTopicPayload;
  type: TopicActionType.FOLLOW_REJECTED;
};

export type UnfollowTopicDefaultAction = {
  meta?: AnalyticsActionMeta;
  payload: FollowTopicPayload;
  type: TopicActionType.UNFOLLOW;
};

export type UnfollowTopicFulfilledAction = {
  meta?: AnalyticsActionMeta;
  payload: FollowTopicPayload;
  type: TopicActionType.UNFOLLOW_FULFILLED;
};

export type UnfollowTopicRejectedAction = {
  meta: ErrorMeta;
  payload: FollowTopicPayload;
  type: TopicActionType.UNFOLLOW_REJECTED;
};

export type FetchFollowedTopicsListDefaultAction = { meta: TopicsMeta; type: TopicActionType.FETCH_FOLLOWED_LIST };

export type FetchFollowedTopicsListFulfilledAction = {
  payload: FetchFollowedTopicsPayload;
  type: TopicActionType.FETCH_FOLLOWED_LIST_FULFILLED;
};

export type FetchFollowedTopicsListRejectedAction = {
  meta: ErrorMeta;
  payload: { error: Error };
  type: TopicActionType.FETCH_FOLLOWED_LIST_REJECTED;
};

export type FetchMyMapsAction = { payload: TopicsMeta; type: TopicActionType.FETCH_MY_MAPS };

export type FetchMyMapsFulfilledAction = {
  payload: FetchCustomTopicsPayload;
  type: TopicActionType.FETCH_MY_MAPS_FULFILLED;
};

type FetchMyMapsRejectedAction = {
  meta: ErrorMeta;
  payload: { error: Error };
  type: TopicActionType.FETCH_MY_MAPS_REJECTED;
};

export type FetchSharedWithMeAction = { payload: TopicsMeta; type: TopicActionType.FETCH_SHARED_WITH_ME };

export type FetchSharedWithMeFulfilledAction = {
  payload: FetchCustomTopicsPayload;
  type: TopicActionType.FETCH_SHARED_WITH_ME_FULFILLED;
};

type FetchSharedWithMeRejectedAction = {
  meta: ErrorMeta;
  payload: { error: Error };
  type: TopicActionType.FETCH_SHARED_WITH_ME_REJECTED;
};

export type RemoveMapDefaultAction = { meta: RemoveMapMeta; type: TopicActionType.REMOVE_MAP };
export type RemoveMapFulfilledAction = { meta: RemoveMapMeta; type: TopicActionType.REMOVE_MAP_FULFILLED };
export type RemoveMapRejectedAction = { type: TopicActionType.REMOVE_MAP_REJECTED };

export type ToggleTopicMachineTranslationAction = {
  meta: { id: string };
  type: TopicActionType.TOGGLE_TOPIC_MACHINE_TRANSLATION;
};

export type FetchTopicsForCollectionAction = {
  payload: FetchTopicsForCollectionPayload;
  type: TopicActionType.FETCH_TOPICS_FOR_COLLECTION;
};

export type FetchTopicsForCollectionSuccessAction = {
  meta: CollectionMeta;
  payload: CollectionIdOnly;
  type: TopicActionType.FETCH_TOPICS_FOR_COLLECTION_SUCCESS;
};

export type FetchTopicsForCollectionFailureAction = {
  payload: CollectionIdOnly;
  type: TopicActionType.FETCH_TOPICS_FOR_COLLECTION_FAILURE;
};

export type FollowTopicAction =
  | FollowTopicDefaultAction
  | FollowTopicFulfilledAction
  | FollowTopicRejectedAction
  | UnfollowTopicDefaultAction
  | UnfollowTopicFulfilledAction
  | UnfollowTopicRejectedAction;

export type TopicAction =
  | FetchTopicDefaultAction
  | FetchTopicFulfilledAction
  | FetchTopicRejectedAction
  | FetchHighlightsDefaultAction
  | FetchHighlightsFulfilledAction
  | FetchHighlightsRejectedAction
  | FetchTopicsDefaultAction
  | FetchTopicsFulfilledAction
  | FetchTopicsRejectedAction
  | FetchFollowedTopicsListDefaultAction
  | FetchFollowedTopicsListFulfilledAction
  | FetchFollowedTopicsListRejectedAction
  | FetchMyMapsAction
  | FetchMyMapsFulfilledAction
  | FetchMyMapsRejectedAction
  | FetchSharedWithMeAction
  | FetchSharedWithMeFulfilledAction
  | FetchSharedWithMeRejectedAction
  | RemoveMapDefaultAction
  | RemoveMapFulfilledAction
  | RemoveMapRejectedAction
  | FollowTopicAction
  | ToggleTopicMachineTranslationAction
  | FetchTopicsForCollectionAction
  | FetchTopicsForCollectionSuccessAction
  | FetchTopicsForCollectionFailureAction;
