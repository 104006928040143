import {
  AcceptMapInviteDefaultAction,
  AcceptMapInviteFulfilledAction,
  AcceptMapInviteRejectedAction,
  ChangeInvitePermissionDefaultAction,
  ChangeInvitePermissionFulfilledAction,
  ChangeInvitePermissionRejectedAction,
  CreateInviteLinkDefaultAction,
  CreateInviteLinkFulfilledAction,
  CreateInviteLinkRejectedAction,
  MapAccessListDefaultAction,
  MapAccessListFulfilledAction,
  MapAccessListRejectedAction,
  MapInviteDefaultAction,
  MapInviteFulfilledAction,
  MapInviteRejectedAction,
  MapRemoveInviteDefaultAction,
  MapRemoveInviteFulfilledAction,
  MapRemoveInviteRejectedAction,
  MapShareType,
  OpenMapInvitationDefaultAction,
  OpenMapInvitationFulfilledAction,
  OpenMapInvitationRejectedAction,
  TogglePublicLinkDefaultAction,
  TogglePublicLinkFulfilledAction,
  TogglePublicLinkRejectedAction,
} from 'domains/createShareSettings/actionTypes';
import {
  AcceptInviteMeta,
  ChangeInvitePermissionMeta,
  CreateLinkMeta,
  CreateLinkPayload,
  InviteMeta,
  InviteUser,
  MapRemoveInviteMeta,
  OpenLinkInvite,
  TogglePublicLinkMeta,
} from 'domains/createShareSettings/types';
import { ErrorMeta, ErrorPayload } from 'types';

export const togglePublicLink = (meta: TogglePublicLinkMeta): TogglePublicLinkDefaultAction => ({
  type: MapShareType.PUBLIC_LINK,
  meta,
});

export const togglePublicLinkSuccess = (meta: TogglePublicLinkMeta): TogglePublicLinkFulfilledAction => ({
  type: MapShareType.PUBLIC_LINK_FULFILLED,
  meta,
});

export const togglePublicLinkFailure = (meta: TogglePublicLinkMeta): TogglePublicLinkRejectedAction => ({
  type: MapShareType.PUBLIC_LINK_REJECTED,
  meta,
});

export const inviteMapSharing = (meta: InviteMeta): MapInviteDefaultAction => ({
  type: MapShareType.INVITE,
  meta,
});

export const inviteMapSharingSuccess = (payload: InviteUser[], meta: InviteMeta): MapInviteFulfilledAction => ({
  type: MapShareType.INVITE_FULFILLED,
  meta,
  payload,
});

export const inviteMapSharingFailure = (meta: InviteMeta): MapInviteRejectedAction => ({
  type: MapShareType.INVITE_REJECTED,
  meta,
});

export const getMapAccessList = (id: string): MapAccessListDefaultAction => ({
  type: MapShareType.ACCESS_LIST,
  meta: { id },
});

export const getMapAccessListSuccess = (id: string, payload: InviteUser[]): MapAccessListFulfilledAction => ({
  type: MapShareType.ACCESS_LIST_FULFILLED,
  meta: { id },
  payload,
});

export const getMapAccessListFailure = (id: string): MapAccessListRejectedAction => ({
  type: MapShareType.ACCESS_LIST_REJECTED,
  meta: { id },
});

export const removeInviteMapSharing = (meta: MapRemoveInviteMeta): MapRemoveInviteDefaultAction => ({
  type: MapShareType.REMOVE_INVITE,
  meta,
});

export const removeInviteMapSharingSuccess = (meta: MapRemoveInviteMeta): MapRemoveInviteFulfilledAction => ({
  type: MapShareType.REMOVE_INVITE_FULFILLED,
  meta,
});

export const removeInviteMapSharingFailure = (meta: MapRemoveInviteMeta): MapRemoveInviteRejectedAction => ({
  type: MapShareType.REMOVE_INVITE_REJECTED,
  meta,
});

export const changePermission = (meta: ChangeInvitePermissionMeta): ChangeInvitePermissionDefaultAction => ({
  type: MapShareType.CHANGE_INVITE_PERMISSION,
  meta,
});

export const changePermissionSuccess = (meta: ChangeInvitePermissionMeta): ChangeInvitePermissionFulfilledAction => ({
  type: MapShareType.CHANGE_INVITE_PERMISSION_FULFILLED,
  meta,
});

export const changePermissionFailure = (meta: ChangeInvitePermissionMeta): ChangeInvitePermissionRejectedAction => ({
  type: MapShareType.CHANGE_INVITE_PERMISSION_REJECTED,
  meta,
});

export const createLink = (meta: CreateLinkMeta): CreateInviteLinkDefaultAction => ({
  type: MapShareType.CREATE_LINK,
  meta,
});

export const createLinkSuccess = (
  payload: CreateLinkPayload,
  meta: CreateLinkMeta,
): CreateInviteLinkFulfilledAction => ({
  type: MapShareType.CREATE_LINK_FULFILLED,
  meta,
  payload,
});

export const createLinkFailure = (meta: CreateLinkMeta): CreateInviteLinkRejectedAction => ({
  type: MapShareType.CREATE_LINK_REJECTED,
  meta,
});

export const openMapInvitation = (token: string): OpenMapInvitationDefaultAction => ({
  type: MapShareType.OPEN_MAP_INVITATION,
  meta: { token },
});

export const openMapInvitationSuccess = (payload: OpenLinkInvite): OpenMapInvitationFulfilledAction => ({
  type: MapShareType.OPEN_MAP_INVITATION_FULFILLED,
  payload,
});

export const openMapInvitationFailure = (payload: ErrorPayload, meta: ErrorMeta): OpenMapInvitationRejectedAction => ({
  type: MapShareType.OPEN_MAP_INVITATION_REJECTED,
  payload,
  meta,
});

export const acceptMapShareInvite = (meta: AcceptInviteMeta): AcceptMapInviteDefaultAction => ({
  type: MapShareType.ACCEPT_INVITE,
  meta,
});

export const acceptMapShareInviteSuccess = (meta: AcceptInviteMeta): AcceptMapInviteFulfilledAction => ({
  type: MapShareType.ACCEPT_INVITE_FULFILLED,
  meta,
});

export const acceptMapShareInviteFailure = (meta: AcceptInviteMeta): AcceptMapInviteRejectedAction => ({
  type: MapShareType.ACCEPT_INVITE_REJECTED,
  meta,
});
