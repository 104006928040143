import { styled } from 'styled-components';

import { useFormatDate } from 'common/utilities';
import { useTranslations } from 'domains/language/useTranslations';

const StyledCaption = styled.p`
  margin: 0 0 1rem;
  color: ${({ theme }) => theme.colors.clusterCaptionText};
`;

const StyledSpan = styled.span`
  margin-left: 10px;
`;

const StyledCount = styled.span`
  //color: #b3b3b3;
  font-weight: bold;
`;

type ClusterCaptionProps = { knowledgeCount: number; textColor?: string; time: number };

export const ClusterCaption = ({ time, knowledgeCount }: ClusterCaptionProps) => {
  const { textDictionary, interpolate } = useTranslations();
  const { getRelativeDate } = useFormatDate();

  if (!time) {
    return null;
  }

  let caption = null;
  if (knowledgeCount) {
    caption = interpolate(textDictionary['app.monitor.articles_count'], {
      count: () => knowledgeCount,
    });
  }

  return (
    <StyledCaption>
      <StyledCount>{caption || ''}</StyledCount>
      <StyledSpan>{getRelativeDate({ date: time })}</StyledSpan>
    </StyledCaption>
  );
};
