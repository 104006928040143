import { LanguageCode } from 'common/types/languageCode';
import { Language } from 'entities/languages/types';
import { RootState } from 'types';

export const getLanguageById = (state: RootState, id: LanguageCode): Language => state.entities.languages.byId[id];

export const getDefaultLanguage = (state: RootState): LanguageCode => state.entities.languages.defaultLanguage;

export const getLanguages = (state: RootState): Language[] =>
  state.entities.languages.allIds.map((id) => state.entities.languages.byId[id]);
