import { PropsWithChildren } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css, styled } from 'styled-components';

import { CrossIcon, ImageBackground } from 'common/components';
import { breakpoints, easing } from 'common/styles';
import { setAudioPlayer } from 'domains/audioPlayer/actions';
import { AudioPlayer } from 'domains/audioPlayer/components/AudioPlayer';
import { getAudioPlayerFile } from 'domains/audioPlayer/selectors';

type StyledProps = { isOpen: boolean };

const StyledContainer = styled.div<StyledProps>`
  width: 100%;
  height: 100%;

  ${({ isOpen }) =>
    isOpen &&
    css`
      display: flex;
      flex-direction: column;
    `}

  @media ${breakpoints.medium} {
    position: relative;
    display: block;
  }
`;

const StyledAudioPlayer = styled.div<StyledProps>`
  width: 100%;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: sticky;
  bottom: 0;

  ${({ theme }) => css`
    background-color: ${theme.colors.backgroundSecondary};
  `};

  @media ${breakpoints.medium} {
    display: block;
    transform: translate3d(${({ isOpen }) => (isOpen ? 0 : -120)}%, 0, 0);
    position: absolute;
    width: 450px;
    border-radius: 5px;
    z-index: 1000;
    transition: transform 0.3s ${easing.slightBounce};
    bottom: 2rem;
    left: 2rem;
    box-shadow: ${({ theme }) => theme.shadows.box};
  }
`;

const StyledPage = styled.div<StyledProps>`
  transition: height 0.3s;
  height: ${({ isOpen }) => (isOpen ? 'calc(100% - 150px)' : '100%')};
  width: 100%;

  @media ${breakpoints.medium} {
    height: 100%;
  }
`;

const StyledContent = styled.div`
  padding: 1rem;
  gap: 1rem;
  display: flex;
`;

const StyledImage = styled(ImageBackground)`
  height: 100px;
  width: 100px;
  border-radius: 5px;
  overflow: hidden;
`;

const StyledCloseIcon = styled(CrossIcon)`
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 15px;
  height: 15px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.textFade};
  transition: color 0.3s;
  &:hover {
    color: ${({ theme }) => theme.colors.textBright};
  }
`;

export const AudioPlayerProvider = ({ children }: PropsWithChildren) => {
  const dispatch = useDispatch();
  const audioFile = useSelector(getAudioPlayerFile);

  const closePlayer = () => dispatch(setAudioPlayer(undefined));

  return (
    <StyledContainer isOpen={!!audioFile}>
      <StyledPage isOpen={!!audioFile}>{children}</StyledPage>
      <StyledAudioPlayer isOpen={!!audioFile}>
        {audioFile && (
          <StyledContent>
            <StyledImage src={audioFile.image}></StyledImage>
            <AudioPlayer audioFile={audioFile} />
            <StyledCloseIcon onClick={closePlayer} />
          </StyledContent>
        )}
      </StyledAudioPlayer>
    </StyledContainer>
  );
};
