import { SVGProps } from 'react';
import { styled } from 'styled-components';

const StyledPath = styled.path`
  fill: ${({ theme }) => theme.colors.textBright};
`;

export const ErrorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20.103" height="20.103" viewBox="0 0 20.103 20.103">
    <g data-name="Group 93596">
      <StyledPath
        data-name="Path 239900"
        d="M111.418 24H95.085a1.89 1.89 0 0 0-1.885 1.885v16.333a1.89 1.89 0 0 0 1.885 1.882h16.333a1.89 1.89 0 0 0 1.885-1.885v-16.33A1.89 1.89 0 0 0 111.418 24zm.628 18.218a.63.63 0 0 1-.628.628H95.085a.63.63 0 0 1-.628-.628V25.885a.63.63 0 0 1 .628-.628h16.333a.63.63 0 0 1 .628.628z"
        transform="translate(-93.2 -24)"
      />
      <StyledPath
        data-name="Path 239901"
        d="M333.828 164.056a.63.63 0 0 1-.628-.628v-6a.628.628 0 1 1 1.256 0v6a.63.63 0 0 1-.628.628z"
        transform="translate(-323.776 -151.586)"
      />
      <StyledPath
        data-name="Path 239902"
        d="M333.828 372.315a.63.63 0 0 1-.628-.628v-.66a.628.628 0 1 1 1.256 0v.66a.63.63 0 0 1-.628.628z"
        transform="translate(-323.776 -356.799)"
      />
    </g>
  </svg>
);
