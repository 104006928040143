import { EmergingThemesAction, EmergingThemesActionType } from 'domains/monitor/emergingThemes/actionTypes';
import { EMERGING_THEMES_LIMIT } from 'domains/monitor/emergingThemes/sagas';
import { EmergingThemeState } from 'domains/monitor/emergingThemes/types';
import { addUserChangeReset } from 'entities/user/utils';

const initialState: EmergingThemeState = {
  isPending: false,
  hasNextPage: false,
  offset: 0,
  items: [],
};

const reducer = (state = initialState, action: EmergingThemesAction): EmergingThemeState => {
  switch (action.type) {
    case EmergingThemesActionType.FETCH:
      return {
        ...state,
        isPending: true,
        offset: action.meta.offset,
      };

    case EmergingThemesActionType.FETCH_FULFILLED: {
      const oldIds = state.items;
      const newIds = action.payload.map((item) => item.id);
      return {
        ...state,
        isPending: false,
        hasNextPage: newIds.length === EMERGING_THEMES_LIMIT,
        items: action.meta.offset === 0 ? newIds : [...oldIds, ...newIds],
      };
    }
    case EmergingThemesActionType.FETCH_REJECTED:
      return {
        ...state,
        isPending: false,
      };
    default:
      return state;
  }
};

export const emergingThemesReducer = addUserChangeReset(reducer, initialState);
