// pass the path option to set the cookie for the whole website ('path=/'). This way you will have access to the cookie throughout the page and dont need to worry about accidentally writing the same cookie to different paths
export const setCookie = (key: string, value: string, options?: string) => {
  document.cookie = `${key}=${value};${options}`;
};

export const getCookie = (key: string) =>
  document.cookie
    .split('; ')
    .find((row) => row.startsWith(`${key}=`))
    ?.split('=')[1];
