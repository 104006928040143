import { MediaContainer, WebFrameProps, WebFrame } from 'common/components/WebFrame';

type MediaPlayerProps = WebFrameProps & { isVideo?: boolean };

export const MediaPlayer = ({ isVideo, ...props }: MediaPlayerProps) => {
  if (isVideo) {
    return (
      <MediaContainer className={props.className}>
        <video src={props.url} controls />
      </MediaContainer>
    );
  }
  return <WebFrame {...props} />;
};
