import { ChangeEvent, forwardRef, RefObject } from 'react';
import { styled } from 'styled-components';

import { LocalFile } from 'domains/createMapDetail/types';

const StyledInput = styled.input`
  display: none;
`;

const handleFileChange =
  (
    setImageCallback: (options: LocalFile) => void,
    inputRef?: RefObject<HTMLInputElement>,
    accept = 'image/*',
    invalidImageCallback?: (image: File) => void,
  ) =>
  (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }
    const [file] = event.target.files;
    const reader = new FileReader();
    let hasMatchingFileType = true;
    if (accept) {
      const acceptedFileTypes = accept.split(',').map((acceptedType) => acceptedType.trim());
      hasMatchingFileType = acceptedFileTypes.some((acceptedType) => file.type.match(acceptedType));
    }
    if (!file || (file.type && !hasMatchingFileType)) {
      if (invalidImageCallback) {
        invalidImageCallback(file);
      }
      if (inputRef && inputRef.current) {
        inputRef.current.value = '';
      }

      return;
    }

    reader.onload = () => {
      setImageCallback({
        file,
        url: reader.result,
      });
    };
    reader.onerror = () => {
      if (invalidImageCallback) {
        invalidImageCallback(file);
      }
      if (inputRef && inputRef.current) {
        inputRef.current.value = '';
      }
    };

    reader.readAsDataURL(file);
  };

type HiddenImageUploadProps = {
  accept?: string;
  disabled?: boolean;
  name?: string;
  onInvalidImage?: (file: File) => void;
  onSetImage: (options: LocalFile) => void;
};

export const HiddenImageUpload = forwardRef<HTMLInputElement, HiddenImageUploadProps>(
  ({ onSetImage, onInvalidImage, name, accept, disabled }, ref) => (
    <StyledInput
      type="file"
      accept={accept}
      name={name}
      disabled={disabled}
      onChange={handleFileChange(onSetImage, ref as RefObject<HTMLInputElement>, accept, onInvalidImage)}
      ref={ref}
    />
  ),
);

HiddenImageUpload.displayName = 'HiddenImageUpload';
