import { PropsWithChildren, ReactElement, useEffect, useRef } from 'react';
import { styled } from 'styled-components';

import { Spinner } from 'common/components/Spinner';

const StyledContainer = styled.div`
  width: 100%;
`;

const StyledFooter = styled.div<{ hasNextPage: boolean }>`
  height: ${({ hasNextPage }) => (hasNextPage ? 100 : 0)}px;
  width: 100%;
  text-align: center;
`;

const StyledSpinner = styled(Spinner)`
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.colors.fillPrimary};
`;

type InfinityLoaderProps = PropsWithChildren & {
  className?: string;
  dataTestId?: string;
  footerComponent?: ReactElement;
  hasNextPage: boolean;
  isPending: boolean;
  loadNextPage?: () => void;
};

export const InfinityLoader = ({
  children,
  className,
  dataTestId,
  footerComponent,
  hasNextPage,
  isPending,
  loadNextPage,
}: InfinityLoaderProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = ref.current;
    if (!element || !hasNextPage || isPending) {
      return;
    }
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          loadNextPage && loadNextPage();
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      },
    );
    observer.observe(element);
    return () => {
      observer.unobserve(element);
    };
  }, [loadNextPage, hasNextPage, isPending]);

  if (!loadNextPage) {
    return <>{children}</>;
  }

  return (
    <StyledContainer data-test-id={dataTestId} className={className}>
      {children}
      <StyledFooter ref={ref} hasNextPage={hasNextPage}>
        {hasNextPage && (footerComponent || <StyledSpinner />)}
      </StyledFooter>
    </StyledContainer>
  );
};
