import { combineReducers } from 'redux';

import { EventAction, EventType } from 'entities/events/actionTypes';
import { EventState } from 'entities/events/types';
import { addUserChangeReset } from 'entities/user/utils';

const initialState: EventState = {
  byId: {},
};

const byId = (state = initialState.byId, action: EventAction): EventState['byId'] => {
  switch (action.type) {
    case EventType.FETCH_FOR_TOPIC_SUCCESS: {
      return action.payload.data.reduce(
        (byId, event) => ({
          ...byId,
          [event.id]: {
            ...state[event.id],
            ...event,
          },
        }),
        state,
      );
    }
    case EventType.FETCH_FOR_ID: {
      return {
        ...state,
        [action.meta.id]: {
          ...state[action.meta.id],
          isPending: true,
        },
      };
    }
    case EventType.FETCH_FOR_ID_SUCCESS: {
      return {
        ...state,
        [action.meta.id]: {
          ...state[action.meta.id],
          ...action.payload,
          isPending: false,
          error: false,
        },
      };
    }
    case EventType.FETCH_FOR_ID_FAILURE: {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isPending: false,
          error: true,
        },
      };
    }
    default:
      return state;
  }
};

export const eventsReducer = combineReducers({
  byId: addUserChangeReset(byId, initialState.byId),
});
