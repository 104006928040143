import { Tab, TOCType } from 'domains/termsAndConditions/types';

export const data: Record<TOCType, Tab> = {
  'terms-and-conditions': {
    id: 'terms-and-conditions',
    label: 'Terms of Use',
    title: 'World Economic Forum Strategic Intelligence Terms of Use',
    sections: [
      {
        id: 'last-updated-toc',
        title: 'Last updated',
        body: (
          <>
            <p>Last updated: 08/04/2024</p>
            <p>
              World Economic Forum, with registered offices at 91-93 route de la Capite, CH-1223 Cologny/Geneva,
              Switzerland (the “Forum”, “we”, “our” or “us”) is an independent international organization committed to
              improving the state of the world by engaging business, political, academic and other leaders of society to
              shape global, regional and industry agendas. It is committedly neutral and impartial with no political,
              partisan or national interests and, with the explicit intention of creating a platform for open dialogue
              between all stakeholders. The Forum owns, operates and makes available the website www.weforum.org as well
              as subdomain websites and digital platforms such as Strategic Intelligence (hereinafter
              “Website”/“Websites” and collectively “Digital Content”). The Forum makes them available to the users of
              the Digital Content (individually “you” or “Visitor” and collectively “Visitors”) under the following
              Terms of Use:
            </p>
          </>
        ),
      },
      {
        id: 'agreement-to-the-terms-of-use',
        title: 'Agreement to the Terms of Use',
        body: (
          <p>
            Digital Content may contain features including blog, report and newsletter services, event, issue and
            community pages, member areas, digital platforms (including features for purposes of analysing our Digital
            Content, its availability and accesses to it) and other applications that may be available from time to
            time.{' '}
            <strong>
              Please read these Terms of Use carefully before accessing to Digital Content. These Terms of Use contain
              conditions, disclaimers and other provisions, some of which limit the Forum’s liability. By registering
              and accessing Digital Content you explicitly agree to be bound by these Terms of Use.
            </strong>
          </p>
        ),
      },
      {
        id: 'account-registration',
        title: 'Account Registration',
        body: (
          <>
            <p>
              Before accessing Digital Content, you are required to create an account with us. You will need to provide
              certain information as part of the registration to access and use the features of the Digital Content as a
              Visitor. Information provided for registration must be accurate, correct and up-to-date. We reserve the
              sole right to verify and deny access where information you provide us is incomplete, incorrect,
              inappropriate and/or missing.
            </p>
            <p>
              You agree to be responsible for maintaining the confidentiality of your passwords or other account
              identifiers which you choose and all activities that occur under your account. By registering you agree
              that:
              <br />
              (i) your account and password are personal to you and may not be used by anyone else to access the Digital
              Content;
              <br />
              (ii) you will not do anything which would assist anyone who is not a registered user to gain access to any
              registration area of the Digital Content; and
              <br />
              (iii) you will not create registration accounts for the purpose of abusing the functionality of the site,
              or other users; nor will you seek to pass yourself off as another user.
            </p>
            <p>
              You agree to notify us immediately if you become aware of any unauthorized use of your password or account
              identifiers by others.
            </p>
          </>
        ),
      },
      {
        id: 'paid-subscription-to-digital-membership',
        title: 'Paid Subscription to Digital Membership',
        body: (
          <>
            <p>
              You may also access to advanced features and exclusive community engagement on Digital Content (“Digital
              Membership”) that is subject to the fee indicated next to each type of engagement on this page{' '}
              <a
                href={`${process.env.REACT_APP_PAYMENT_PORTAL_BASE_URL}join-us/pricing`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {process.env.REACT_APP_PAYMENT_PORTAL_BASE_URL}join-us/pricing
              </a>
              . The Digital Membership is available to all Visitors with professional affiliations. Digital Membership
              will renew automatically every month, unless you decide to terminate the Digital Membership by cancelling
              your subscription. The termination will be executed by Stripe (see below) and you will still be able to
              use Digital Membership until end of your current paid subscription.
            </p>
            <p>
              The Forum may change the price for the Digital Membership from time to time and will communicate any price
              changes to you in advance and, if applicable, how to accept those changes. Price changes will take effect
              at the start of the next subscription period following the date of the price change. If you do not agree
              with a price change, you have the right to reject the change by cancelling your paid subscription prior to
              the price change going into effect.
            </p>
            <p>
              The Forum uses third party payment platform, Stripe, the Stripe API, and in conjunction, your Stripe
              account to process credit card transactions.{' '}
              <strong>
                By accessing Stripe you agree to be bound by Stripe’s Terms of Service{' '}
                <a href="https://stripe.com/ch/ssa" target="_blank" rel="noopener noreferrer">
                  https://stripe.com/ch/ssa.
                </a>
              </strong>{' '}
              You are responsible for all transactions (one-time, recurring, and refunds) processed through Stripe. The
              Forum is not liable for any loss or damage when using your Stripe account, including errant or invalid
              transactions. This includes transactions that were not processed due to a network communication error, or
              any other reason. If you process a transaction, it is your responsibility to verify that the transaction
              was successfully processed.
            </p>
            <p>
              You have 7 days of trial period that can be used for Digital Membership or Digital Membership Pro. In case
              you decide to upgrade during your Digital Membership trial period to Digital Membership Pro you will not
              receive additional trial period days. The membership continues after the trial period for the full price.
            </p>
            <p>
              You may receive coupons from the Forum that can be used for the Digital Membership or Digital Membership
              Pro. If you redeem a coupon, the conditions of the respective coupon apply for the coupon duration, and
              offered discount. After the coupon expires, the existing paid membership will be resumed automatically.
            </p>
          </>
        ),
      },
      {
        id: 'export-control',
        title: 'Export control',
        body: (
          <p>
            When accessing paid subscription, you agree to comply with all applicable export control laws and economic
            sanctions programs as they relate to access to and use of the Digital Membership. You shall not access or
            use Digital Membership if you are located in any jurisdiction in which the provision of Digital Membership
            is prohibited under European Union, United Nations, Swiss or United States’ sanctions lists (a “Prohibited
            Jurisdiction”) and you shall not provide access to the Digital Membership to any government, entity or
            individual located in any Prohibited Jurisdiction. You represent, warrant and covenant that (a) you are not
            named on any U.S. government, European Union, United Nations and Swiss sanctions list of persons, entities
            or countries (this may include U.S. export control laws such as the Export Administration Regulations and
            the International Traffic in Arms Regulations, and U.S. Economic Sanctions programs that are or may be
            maintained by the U.S. Government, including Specially Designated Nationals and Blocked Persons program);
            (b) you are not a national of, or a company registered in, any Prohibited Jurisdiction; (c) you shall not
            permit any third party to access or use the Digital Membership in violation of any U.S. or other applicable
            export embargoes, prohibitions or restrictions.
          </p>
        ),
      },
      {
        id: 'termination-and-suspension-of-digital-content-account',
        title: 'Termination and Suspension of Digital Content account',
        body: (
          <>
            <p>
              If you no longer wish to be subscribed to the free version of Digital Content, you may terminate your
              account by sending an email to <a href="mailto:intelligence@weforum.org">intelligence@weforum.org</a>. If
              you no longer accept these Terms of Use, or any future modification to these Terms of Use, you must cease
              using the Digital Content. Continued use of the Digital Content indicates your continued acceptance of
              these Terms of Use.
            </p>
            <p>
              If, for any reason, we believe that you have not complied with these Terms of Use, we may, at our sole
              discretion, cancel your access to the Digital Content immediately and without prior notice. We may also
              terminate your account, at our sole discretion, by emailing you at the address you have registered stating
              that account has been terminated.
            </p>
          </>
        ),
      },
      {
        id: 'privacy',
        title: 'Privacy',
        body: (
          <>
            <p>
              Your privacy and the protection of your personal data are very important to us. The Forum works with
              Stripe to provide the payment service, and both the Forum and Stripe may collect or receive certain
              personal data about you. For more information about the {`Forum's`} privacy practices, you should review
              our privacy policy:{' '}
              <a href="/privacy-policy" rel="noopener noreferrer">
                {process.env.REACT_APP_WEBSITE_URL}/privacy-policy
              </a>
              , and for more information about {`Stripe's`} privacy practices, you should review{' '}
              <a href="https://stripe.com/ch/privacy" target="_blank" rel="noopener noreferrer">
                https://stripe.com/ch/privacy
              </a>
              .
              <br />
              Reviewing these policies will help you understand how we collect, use and safeguard the information you
              provide to us.
            </p>
            <p>
              The Forum owns, operates, and makes available to Digital Members the website{' '}
              <a href="https://toplink.weforum.org" target="_blank" rel="noopener noreferrer">
                https://toplink.weforum.org
              </a>{' '}
              (“TopLink”). Should you access TopLink, the Forum will process your personal data as a data controller in
              accordance with the
              {`Forum's`} TopLink Privacy Notice which can be found here:{' '}
              <a href="https://toplink.weforum.org/page/terms" target="_blank" rel="noopener noreferrer">
                https://toplink.weforum.org/page/terms
              </a>
              .
            </p>
          </>
        ),
      },
      {
        id: 'appropriate-use',
        title: 'Appropriate Use',
        body: (
          <>
            <p>
              Visitors shall not use the Digital Content to (i) post, submit or otherwise distribute unlawful,
              defamatory, abusive, obscene or offensive materials (ii) attempt to decipher, decompile, disassemble or
              reverse engineer transformation maps, (iii) violate Forum’s or third party rights including, but not
              limited to intellectual property or privacy rights (iv) access or attempt to access the Digital Content
              using interfaces aside from the ones provided by the Digital Content, (v) limit or constrict the
              availability or quality of the Digital Contents’ features.
            </p>
            <p>
              In case of violation of this Section 4, the Forum reserves the sole right to take any actions, including
              but not limited to preclude your access to the Digital Content.
            </p>
          </>
        ),
      },
      {
        id: 'disclaimer',
        title: 'Disclaimer',
        body: (
          <>
            <p>
              THE DIGITAL CONTENT IS PROVIDED “AS IS” AND “AS AVAILABLE” WITH NO EXPRESS OR IMPLIED WARRANTIES OF ANY
              KIND INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF FITNESS FOR A PARTICULAR PURPOSE,
              MERCHANTABILITY, NON-INFRINGEMENT, AND TITLE. THE FORUM DISCLAIMS ANY AND ALL LIABILITY FOR THE ACTS,
              OMISSIONS AND CONDUCT OF ANY THIRD PARTIES IN CONNECTION WITH OR RELATED TO VISITORS’ USE OF THE SITE.
              VISITORS ASSUME TOTAL RESPONSIBILITY FOR THEIR USE OF THE SITE AND ANY LINKED SITES. IF THIS DISCLAIMER IS
              NOT ENFORCEABLE UNDER APPLICABLE LAW, IT MAY BE MODIFIED AT THE FORUM’S SOLE DISCRETION TO A FORM THAT IS
              ENFORCEABLE.
            </p>
            <p>
              THE FORUM DOES NOT TAKE RESPONSIBILITY FOR THE CORRECTNESS, COMPLETENESS, UP-TO-DATEDNESS OR QUALITY OF
              THE INFORMATION PROVIDED IN THE DIGITAL CONTENT. ESPECIALLY, THE OPINIONS EXPRESSED IN THE CONTENT
              CONTAINED IN THE DIGITAL CONTENT ARE THOSE OF THE RESPECTIVE AUTHORS FOR THE DIGITAL CONTENT AS SHOWN.
              THEY DO NOT NECESSARILY REFLECT THE POSITIONS OF THE FORUM. THE FORUM EXPRESSLY DISCLAIMS ANY AND ALL
              LIABILITY TO ANY PERSON IN RESPECT OF ANYTHING DONE OR OMITTED TO BE DONE WHOLLY OR PARTIALLY IN RELIANCE
              UPON THE CONTENTS OF THE DIGITAL CONTENT.
            </p>
            <p>
              FURTHERMORE, THE FORUM IS NOT LIABLE FOR ANY DAMAGE OR CORRUPTION CAUSED BY VIRUSES (OR SIMILAR MECHANISMS
              SUCH AS WORMS, LOCK OUTS, METERING, OR SELF-DESTRUCT MECHANISMS) ON COMPUTERS OR OTHER TERMINAL EQUIPMENT
              IN CONNECTION WITH VISITING, USING AND/OR DOWNLOADING DATA OF ANY KIND FROM THE DIGITAL CONTENT. THE
              VISITORS ARE PERSONALLY RESPONSIBLE FOR PROTECTING THEIR EQUIPMENT APPROPRIATELY FROM VIRUSES AND OTHER
              RISKS ON THE INTERNET.THE FORUM RESERVES THE RIGHT, AT ITS SOLE DISCRETION, BUT DISCLAIMS THE OBLIGATION,
              TO MONITOR AND/OR REMOVE CONTENT FROM ANY BLOG.
            </p>{' '}
          </>
        ),
      },
      {
        id: 'limitation-of-liabilities',
        title: 'Limitation of Liabilities',
        body: (
          <p>
            The Forum will not accept responsibility for any indirect, incidental, collateral, or punitive damages that
            may incur from usage of the Digital Content, even if the Forum is already aware of the potential risks. If
            your use of Digital Content results in any form of damage or loss, the complete and maximum liability that
            the Forum assumes shall not exceed the total amount of the monthly subscription fees of your Digital
            Membership.
          </p>
        ),
      },
      {
        id: 'intellectual-property-rights',
        title: 'Intellectual Property Rights',
        body: (
          <>
            <p>
              The Forum publishes its own content as well as links, content, photographs, graphics, logos and resources
              provided by third parties and content that has been specifically commissioned by the Forum for publication
              on the Digital Content.
            </p>
            <p>
              The Forum is using the proprietary rights of third parties (including but not limited to the photographs
              and graphics). Due to limited space on the Digital Content, Reuters Photography, iStock and Shutterstock
              are the third parties who are credited herein. All proprietary rights relating to any third party links,
              content, photographs, graphics, logos or resources published on the Digital Content remain with the
              original source or the author(s) of that material and where any content has been commissioned by the Forum
              for publication on the Digital Content, any proprietary rights in such content remain with the relevant
              author, unless otherwise agreed or specified.
            </p>
            <p>
              For all other content published on the Digital Content, the Forum reserves all proprietary rights
              including, but not limited to, copyrights, trademarks and other intellectual property rights in and to all
              content on the Digital Content; this includes all text, graphics, photographs, logos and/or other items
              that appear on the Digital Content. The Forum also reserves its rights over the Digital Contents’
              template, including its layout, image and structure.
            </p>
            <p>
              Transformation Maps are Forum’s dynamic knowledge visualization tools helping Visitors to explore and
              understand complex and interlinked forces that are transforming economies, industries and global issues.
              Visitor may download and create customized Transformation Maps as instructed on the Website and only
              strictly in accordance with the terms of Creative Commons Attribution-NonCommercial (“CC BY-NC-ND 4.0”).
              The Forum reserves the right to revoke the CC BY-NC-ND 4.0 any time at its sole discretion.
            </p>
            <p>
              Under the CC BY-NC-ND 4.0 license, Visitors may copy and redistribute the Transformation Maps, in any
              medium or format provided that the Transformation Maps is properly attributed to the Forum in the manner
              specified by the Forum (but not in any way that suggests that the Forum endorses Visitor or their use of
              Transformation Maps), and that any such use is for non-commercial purposes.
            </p>
            <p>
              For the avoidance of doubt, the CC BY-NC-ND 4.0 does not apply to the Forum name and logo, third-party
              materials, trademarks, names and logos, Strategic Intelligence interface elements, graphic files (other
              than what is permitted specifically under this Section) and source code, photographs and videos or any
              content that is herein excluded from the terms of CC BY-NC-ND 4.0. The Forum reserves all rights which
              have not been expressly granted.
            </p>
            <p>
              In addition, Visitors who have an active Digital Membership Pro may download from the Website and use
              Transformation Maps as part of their commercial activities but in no event edit, remix, adapt or build
              upon the Transformation Maps.
            </p>
            <p>
              The Forum will retain all (including where applicable, ownership) rights of any comments, ideas, and
              feedback provided by Visitors to the Forum in relation to the use of Digital Content (“Visitor’s
              Feedback”).
            </p>
            <p>
              The Forum’s name and logo are registered trademarks of the Forum. Visitors are not authorized to use the
              Forum’s name, logo or likeness without prior consent.
            </p>
          </>
        ),
      },
      {
        id: 'publications',
        title: 'Publications',
        body: (
          <>
            <p>
              Visitors may make use of the content of the articles published to this particular feed only: 
              <a href="http://wef.ch/feed" target="_blank" rel="noopener noreferrer">
                http://wef.ch/feed
              </a>
              {` (the "`}
              <strong>Feed</strong>
              {`")`} under the terms of Creative Commons Attribution-NonCommercial-NoDerivs 4.0 Unported License (“
              <strong>CC BY-NC-ND 4.0</strong>”). To view a copy of this licence please see 
              <a
                href="https://creativecommons.org/licenses/by-nc-nd/4.0/legalcode"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://creativecommons.org/licenses/by-nc-nd/4.0/legalcode
              </a>
              . All rights are reserved with respect to other Articles published in the Digital Content which are
              protected by copyright.
            </p>
            <p>
              Under the CCPL license, Visitors may copy, distribute and transmit the articles, in any medium or format
              provided the work is properly attributed to the author and the Forum, that any such use is for
              non-commercial purposes and it is not altered, transformed or built upon. That means (excerpt from 
              <a href="https://creativecommons.org/licenses/by-nc-nd/4.0/" target="_blank" rel="noopener noreferrer">
                https://creativecommons.org/licenses/by-nc-nd/4.0/
              </a>
              ):
              <br />
              <strong>Attribution</strong>: Visitor must attribute the work in the manner specified by the author or
              licensor (but not in any way that suggests that they endorse Visitor or their use of the work).
              <br />
              <strong>Noncommercial</strong>: Visitor may not use this work for commercial purposes.
              <br />
              <strong>No Derivatives</strong>: Visitor may not alter, transform, or build upon this work.
            </p>
            <p>
              This CCPL shall not apply to the Forum name and logo, third-party materials, trademarks, names and logos,
              Digital Content interface elements, graphic files and source code, photographs and videos. The Forum
              reserves all rights which have not been expressly granted.
            </p>
            `
          </>
        ),
      },
      {
        id: 'links-to-other-websites',
        title: 'Links to other websites',
        body: (
          <p>
            Links in the Digital Content may connect Visitors to external or third-party websites that are not related
            to the Forum or under its control. The Forum is not responsible for the content of external or third-party
            websites that may be accessible or displayed through external links in the Digital Content. The presence of
            these external links does not represent the Forum’s endorsement of the views or content of these third-party
            websites. Navigating to external websites through links on the Digital Content is done at the sole risk of
            the Visitor, and the Forum expressly disclaims any liability for any content that appears on any external
            site.
          </p>
        ),
      },
      {
        id: 'links-to-this-digital-content',
        title: 'Links to this Digital Content',
        body: (
          <p>
            The Visitor may link to the Digital Content provided the Visitor does so in a way that indicates that the
            link is to the Forum Digital Content and is fair and not misleading. Visitors may not integrate or make use
            of all or part of the Digital Content in ways that would confuse or mislead other Visitors as to the nature
            and origin of the Digital Contents’ content.
          </p>
        ),
      },
      {
        id: 'amendments',
        title: 'Amendments',
        body: (
          <p>
            The Forum may amend these Terms of Use at any time, and such amendments will be effective immediately upon
            notice being provided to the Visitor. Notice may be provided on the Digital Content itself, and after
            posting of the notice, the Visitor’s continued use of the Digital Content will be deemed as acceptance of
            the modified Terms of Use.
          </p>
        ),
      },
      {
        id: 'waiver-and-serverability-of-terms-of-use',
        title: 'Waiver and Severability of Terms of Use',
        body: (
          <p>
            The failure of the Forum to exercise or enforce any right or provision of these Terms of Use shall not
            constitute a waiver of such right or provision. If any provision of these Terms of Use is found by a court
            of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to
            give effect to the parties’ intention as reflected in the provision and the other provisions of these Terms
            of Use shall remain in full force and effect.
          </p>
        ),
      },
      {
        id: 'applicable-law',
        title: 'Applicable law',
        body: (
          <p>
            These Terms of Use shall be governed by and interpreted in accordance with the substantive laws of
            Switzerland, under the exclusion of any international treaty and under exclusion of any conflicts of laws
            principles. Exclusive place of jurisdiction shall be Geneva, Switzerland.
          </p>
        ),
      },
      {
        id: 'notices',
        title: 'Notices',
        body: <p>All the notices to the Forum shall be sent to the e-mail address below (see under Contact).</p>,
      },
      {
        id: 'contact-q',
        title: 'Contact',
        body: (
          <p>
            For questions regarding these Terms of Use, please contact the Forum at{' '}
            <a href="mailto:intelligence@weforum.org" target="_blank" rel="noopener noreferrer">
              intelligence@weforum.org
            </a>
            . The address of World Economic Forum is 91-93 Route de la Capite, CH-1223 Cologny/Geneva, Switzerland.
          </p>
        ),
      },
    ],
  },
  'privacy-policy': {
    id: 'privacy-policy',
    label: 'Privacy Policy',
    title: 'World Economic Forum Strategic Intelligence Privacy Policy',
    sections: [
      {
        id: 'last-updated-pp',
        title: 'Last updated',
        body: (
          <>
            <p>Last updated: 26/10/2020</p>
            <p>
              {`The World Economic Forum (hereafter the "Forum") is committed to
              complying with data protection laws and to the protection and
              confidentiality of the personal data of users of the Strategic
              Intelligence online platform (hereinafter also referred to as "User",
              "you" or "your"). In this Strategic Intelligence Privacy Policy (the
              "Policy"), we explain how we collect, share and use personal data we
              obtain from you when you register, access or use our Strategic
              Intelligence platform (“Strategic Intelligence”) and how you can
              exercise your data privacy rights.`}
            </p>
            <p>
              The Forum wants you to be well informed of the gathering and use of your personal data through  Strategic
              Intelligence. Please read this Policy carefully prior to entering your personal data and using Strategic
              Intelligence.
            </p>
            <p>
              If you have any questions or concerns about our use of your personal data, then please contact us using
              the contact details provided at the bottom of this Policy.
            </p>
          </>
        ),
      },
      {
        id: 'identification-of-the-controller',
        title: 'Identification of the controller',
        body: (
          <p>
            {`The World Economic Forum, with registered offices at 91-93 route de
            la Capite, CH-1223 Cologny/Geneva, Switzerland, processes your
            personal data as a controller in accordance with applicable
            legislation with regard to the processing of personal data, namely
            Regulation (EU) 2016/679 of the European Parliament and of the
            Council of 27 April 2016 on the protection of natural persons with
            regard to the processing of personal data and on the free movement
            of such data, and repealing Directive 95/46/EC (hereinafter "GDPR")
            and any applicable national data protection acts, including the
            Swiss Federal Act on Data Protection of 19 June 1992 (Status as of 1
            January 2014) (together referred to as "Data Protection Law").`}
          </p>
        ),
      },
      {
        id: 'what-types-of-personal-data-do-we-collect',
        title: 'What types of personal data do we collect?',
        body: (
          <>
            <p>The Forum may collect and process the following types of personal data about you:</p>
            <ul>
              <li>
                <em>
                  <strong>Information that you provide voluntarily</strong>
                </em>
                <ul>
                  <li>Identification details (including your name, first name, company or affiliation)</li>
                  <li>Contact details (including email address)</li>
                </ul>
              </li>
              <li>
                <em>
                  <strong>Information that we collect automatically</strong>
                </em>
                <ul>
                  <li>
                    When you visit Strategic Intelligence, we may collect certain information automatically from your
                    device. In some countries, including countries in the European Economic Area, this information may
                    be considered personal data under applicable Data Protection Law.
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              Specifically, the information we collect automatically may include information like your IP address,
              device type, unique device identification numbers, browser-type, preferences, broad geographic location
              (e.g. country or city-level location) and other technical information. We may also collect information
              about how your device has interacted with Strategic Intelligence, including the pages accessed and links
              clicked.
            </p>
             
            <p>
              For further information about the types of cookies and other tracking technology we use on Strategic
              Intelligence, please see our{' '}
              <a
                href="https://toplink.weforum.org/page/terms#Staticpageslist-Cookies"
                target="_blank"
                rel="noopener noreferrer"
              >
                Cookie Notice.
              </a>
            </p>
          </>
        ),
      },
      {
        id: 'how-to-we-use-your-data-and-for-what-purposes',
        title: 'How do we use your data and for what purposes?',
        body: (
          <>
            <p>The Forum processes your personal data for the following purposes:</p>
            <ul>
              <li>To analyse and monitor general trends and patterns in the use of Strategic Intelligence;</li>
              <li>To improve the content, performance and organization of Strategic Intelligence;</li>
              <li>{`To promote the Forum's image and activities on our website or social media to a broader public;`}</li>
              <li>To internal data analytics;</li>
              <li>
                To send you information related to your membership, such as Digital Membership confirmation, trial
                series emails to introduce you to the Digital Membership referred to in the Terms of Use under Section
                3, or other membership-related communications;
              </li>
              <li>To send you invitations to future activities or events;</li>
              <li>
                To send you Strategic Intelligence newsletters (weekly and/or monthly) and quarterly content updates;
              </li>
              <li>To inform you about Strategic Intelligence product updates and special membership offers;</li>
              <li>To communicate with you, i.e. to respond to your questions.</li>
              <li>To facilitate platform engagement within the Forum’s various platforms.</li>
            </ul>
            <p>
              We will use the personal data we collect from you only for the purposes described in this Policy or for
              purposes that we explain to you at the time we collect your personal data. However, we may also use your
              personal data for other purposes that are not incompatible with the purposes we have disclosed to you
              (such as archiving purposes in the public interest, scientific or historical research purposes, or
              statistical purposes) in accordance with Data Protection Law.
            </p>
          </>
        ),
      },
      {
        id: 'on-what-legal-grounds-do-we-process-your-personal-data',
        title: 'On what legal grounds do we process your personal data?',
        body: (
          <>
            <p>
              In general, we will process your personal data in order to manage our relationship with you and to execute
              the Terms of Use of the Strategic Intelligence platform. We may also be required to process your personal
              data in order to comply with local laws and regulations, Otherwise, we will process your personal data in
              order to carry out our business legitimate interests, where such interests do not override your
              fundamental rights and liberties. Where required, we will ask you to provide your consent for certain
              processing activities.
            </p>
            <p>
              Where the provision of personal data is based on a statutory or contractual requirement, or a requirement
              necessary to enter into a contract, you are obliged to provide us with this personal data. If you refuse
              or do not allow us to process your personal data, you may not be able to benefit from all the service
              offered by the Forum. 
            </p>
          </>
        ),
      },
      {
        id: 'who-do-we-share-your-data-with',
        title: 'Who do we share your data with? ',
        body: (
          <>
            <p>The Forum may share your personal data with the following parties:</p>
            <ol>
              <li>
                <h4>Forum Entities</h4>
                <p>
                  The Forum may share your personal data with its Forum Entities (as defined below) in other locations
                  around the world, for example, to answer your questions adequately.
                </p>
              </li>
              <li>
                <h4>Third Party Service Providers</h4>
                <p>
                  The Forum shares your personal data with third party service providers, e.g. to host your data or for
                  security purposes. The Forum will ensure that third parties accessing the personal data of Users shall
                  comply with the same data protection requirements as the Forum and that the personal data will be used
                  exclusively for the purposes set out in this Policy.
                </p>
              </li>
              <li>
                <h4>Public authorities</h4>
                <p>
                  The Forum may share your personal data with local authorities to the extent deemed necessary by the
                  Forum for the purposes set forth in this Policy, in particular for security reasons or as required by
                  mandatory laws and regulations.
                </p>
              </li>
            </ol>
          </>
        ),
      },
      {
        id: 'how-we-protect-your-privacy',
        title: 'How we protect your privacy',
        body: (
          <>
            <p>We will process personal data in accordance with this Policy, as follows:</p>
            <ul>
              <li>
                Fairness: We will process your personal data fairly. This means that we are transparent about how we
                process personal data and that we will process it in accordance with Data Protection Law.
              </li>
              <li>
                Purpose limitation: We will process your personal data for specified and lawful purposes, and will not
                process it in a manner that is incompatible with those purposes.
              </li>
              <li>
                Proportionality: We will process your personal data in a way that is proportionate to the purposes which
                the processing is intended to achieve.
              </li>
              <li>
                Data accuracy: We take appropriate measures to ensure that personal data that we hold about you is
                accurate, complete and, where necessary, kept up to date. However, it is also your responsibility to
                ensure that your personal data is kept as accurate, complete and current as possible by informing the
                Forum promptly of any changes or errors. You should notify us of any changes to the personal data that
                we hold about you (e.g. a change of email address).
              </li>
              <li>
                Data security: We use appropriate technical and organisational measures to protect the personal data
                that we collect and process about you. The measures we use are designed to provide a level of security
                appropriate to the risk of processing your personal data. In particular, any personal data provided to
                the Forum by Users of  Strategic Intelligence is handled with due care and security, and will not be
                used in ways other than as set forth in this Policy, or in any site/area-specific policies, or in ways
                that are not compliant with Data Protection Law. Furthermore, the Forum employs a range of technologies
                and security measures to protect the personal data maintained on Forum systems from loss, misuse,
                unauthorized access or disclosure, alteration or destruction. Finally, all Forum employees who have
                access to and are associated with the processing of personal data are obliged to respect the
                confidentiality of all such personal data.
              </li>
            </ul>
          </>
        ),
      },
      {
        id: 'international-data-transfers',
        title: 'International data transfers',
        body: (
          <>
            <p>
              Personal data uploaded into Strategic Intelligence will be processed by the Forum in Switzerland, and if
              applicable, in the United States by the World Economic Forum LLC (USA) and/or in any other country where
              the Forum or its affiliates, subsidiaries or offices has its facilities (hereinafter jointly the “Forum
              Entities”).
            </p>
            <p>
              The Forum takes steps to ensure that the data collected with regards to your sign-up is processed
              according to the provisions of Data Protection Law wherever the personal data is located. When the Forum
              transfers personal data from the European Economic Area to other countries, we use a variety of legal
              mechanisms in accordance with Data protection law, including contracts, to help ensure your rights and
              protections travel with your data.
            </p>
            <p>
              If you want to obtain more information in this respect, please contact us by filling out the form at{' '}
              <a href="https://wef.ch/MyData" target="_blank" rel="noopener noreferrer">
                wef.ch/MyData
              </a>
              .
            </p>
          </>
        ),
      },
      {
        id: 'how-long-do-we-keep-your-personal-data',
        title: 'How long do we keep your personal data?',
        body: (
          <>
            <p>
              The Forum will retain your personal information for the period necessary to fulfil the purposes outlined
              in this Policy.
            </p>
            <p>
              For example, we may retain personal data we obtain from you where we have an ongoing legitimate business
              need to do so (for example, to provide you with a service you have requested or to comply with applicable
              legal, tax or accounting requirements).
            </p>
          </>
        ),
      },
      {
        id: 'your-data-protection-rights',
        title: 'Your data protection rights',
        body: (
          <>
            <p>You have the following data protection rights:</p>
            <ul>
              <li>You may request access to and obtain a copy of your personal data.</li>
              <li>
                You may also request that any personal data that is inaccurate or incomplete be rectified or completed.
              </li>
              <li>
                You may request that your personal data be erased under certain legal conditions (for example, where the
                personal data is no longer needed to achieve the purposes for which the information was initially
                collected). 
              </li>
              <li>
                In addition, you may object to the processing of your personal data, ask us to restrict processing of
                your personal data or request portability of your personal data under certain legal conditions.
              </li>
              <li>
                You have the right to opt-out of electronic marketing communications we send you at any time and free of
                charge.  You can exercise this right by clicking on the “unsubscribe” or “opt-out” link in the marketing
                e-mails we send you.  To opt-out of other forms of marketing (such as postal marketing or
                telemarketing), then please contact us using the contact details provided under the “How to contact us”
                heading below.
              </li>
              <li>
                Similarly, if we have collected and process your personal data with your consent, then you can withdraw
                your consent at any time.  Withdrawing your consent will not affect the lawfulness of any processing we
                conducted prior to your withdrawal, nor will it affect processing of your personal data conducted in
                reliance on lawful processing grounds other than consent.
              </li>
              <li>
                You have the right to complain to a data protection authority about our collection and use of your
                personal data.  For more information, please contact your local data protection authority. Contact
                details for data protection authorities in the European Economic Area, Switzerland and certain
                non-European countries (including the US and Canada) are available{' '}
                <a
                  href="http://ec.europa.eu/justice/data-protection/article-29/structure/data-protection-authorities/index_en.htm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here.
                </a>
              </li>
              <li>
                You may exercise any of the rights above at any time by contacting us as described under the “How to
                contact us” section below. We will respond to your request in accordance with applicable Data Protection
                Law.
              </li>
            </ul>
          </>
        ),
      },
      {
        id: 'minors',
        title: 'Minors',
        body: (
          <p>
            Certain services we provide on the Strategic Intelligence platform are also available for individuals below
            the age of 18. Such access will be provided by the Forum on a case-by-case basis and a separate privacy
            policy will be provided accordingly.
          </p>
        ),
      },
      {
        id: 'linking-to-other-websites',
        title: 'Linking to other websites',
        body: (
          <>
            <p>
              {`The Strategic Intelligence platform may include links to other
              websites that are not operated or maintained by the Forum and are
              not covered by this Policy. The processing of personal data on
              these third party websites including by use of cookies, is subject
              to these third party's notices and terms and conditions. Please
              review the notices and terms and conditions of each website you
              visit.`}
            </p>
            <p>
              We do not accept any responsibility or liability for the privacy practices of such third party websites
              and your use of such websites is at your own risk.
            </p>
          </>
        ),
      },
      {
        id: 'updates-to-this-policy',
        title: 'Updates to this Policy',
        body: (
          <>
            <p>
              We may update this Policy from time to time in response to changing legal, technical or business
              developments. When we update our Policy, we will take appropriate measures to inform you, consistent with
              the significance of the changes we make.  We will obtain your consent to any material Policy changes if
              and where this is required by applicable data protection laws.
            </p>
            <p>
              You can see when this Policy was last updated by checking the “last updated” date displayed at the top of
              this Policy.
            </p>
          </>
        ),
      },
      {
        id: 'how-to-contact-us',
        title: 'How to contact us',
        body: (
          <p>
            If you have any questions or concerns concerning this Policy or the way we process your personal data, or if
            you wish to exercise your data privacy rights as described above, please contact us by filling out the form
            at:{' '}
            <a href="https://wef.ch/MyData" target="_blank" rel="noopener noreferrer">
              wef.ch/MyData
            </a>
            .
          </p>
        ),
      },
    ],
  },
  'cookie-notice': {
    id: 'cookie-notice',
    label: 'Cookie Notice',
    title: 'World Economic Forum Strategic Intelligence Cookie Notice',
    sections: [
      {
        id: 'last-updated-cn',
        title: 'Last updated',
        body: <p>Last updated: 29/03/2019</p>,
      },
      {
        id: 'about-cookies',
        title: 'About cookies',
        body: (
          <ul>
            <li>
              A cookie is a small piece of information that a web server asks the user’s browser to store on the user’s
              local computer, and then later their browser presents this information to the web server. In its simplest
              form, a cookie is an identifying number.
            </li>
          </ul>
        ),
      },
      {
        id: 'what-type-of-cookies-do-we-use',
        title: 'What type of cookies do we use?',
        body: (
          <>
            <ul>
              <li>
                {`We use two types of cookies on Strategic Intelligence:
                persistent cookies and session cookies. A persistent cookie
                helps us recognize you as an existing user, so it's easier to
                return to Strategic Intelligence or interact with our services
                without signing in again. In the event you sign up to one of our
                platforms, a persistent cookie stays in your browser and will be
                read by the service when you return to one of our sites. Session
                cookies only last as long as the session (usually the current
                visit to a website or a browser session).`}
              </li>
            </ul>
            <p>
              {`We use cookies for anonymous user tracking. The Forum web server
              assigns each user a unique numerical ID ("ID"). All requests for
              pages are tagged with that ID, and by analysing the log files, the
              Forum can determine how many unique users use Strategic
              Intelligence.`}
            </p>
          </>
        ),
      },
      {
        id: 'how-do-we-use-cookies',
        title: 'How do we use cookies?',
        body: (
          <>
            <ul>
              <li>
                The Forum does not know the identity of a person based on the ID. The cookies used by the Forum do not
                contain any personal data about you nor are they used to look up your personal data. 
              </li>
            </ul>
            <p>
              When you have entered personal data about yourself in one of the Forum’s registration forms, the Forum may
              relate that information to the cookie data. This information together with cookie data may be used to
              contact you and send you new product trials.
            </p>
            <p>
              Strategic Intelligence may use cookies for analytics and to improve your user experience. Strategic
              Intelligence will usually not natively use cookies, but our analytics providers do use these cookies to
              improve user experience.
            </p>
            <p>Our analytics providers include:</p>
            <ul>
              <li>
                Google{' '}
                <a href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noopener noreferrer">
                  [Privacy Policy]
                </a>
              </li>
              <li>
                parse.ly{' '}
                <a href="https://www.parse.ly/privacy-policy/" target="_blank" rel="noopener noreferrer">
                  [Privacy Policy]
                </a>
              </li>
              <li>
                chart beat{' '}
                <a href="https://chartbeat.com/privacy/" target="_blank" rel="noopener noreferrer">
                  [Privacy Policy]
                </a>
              </li>
              <li>
                mix panel {' '}
                <a href="https://mixpanel.com/legal/privacy-policy/" target="_blank" rel="noopener noreferrer">
                  [Privacy Policy]
                </a>
              </li>
            </ul>
            <p>
              These providers use cookies to tailor your user experience, gather statistics on usage and engagement and
              help better inform the Forum’s efforts to deliver world-class engagement, knowledge and events.
            </p>
            <p>
              You are free to decline the cookies if your browser or browser add-on allows, except if the cookies are
              required to prevent fraud or ensure the security of Strategic Intelligence that the Forum controls.
              However, disabling the use of cookies will result in a less user-friendly experience of the Strategic
              Intelligence or can fully prevent you from using  Strategic Intelligence.
            </p>
          </>
        ),
      },
    ],
  },
};
