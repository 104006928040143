type Entity = { id: string };

export const getPageId = (id?: string, items?: Entity[]) => {
  if (id) {
    return id;
  }
  if (items && items.length > 0) {
    return items[0].id;
  }
  return;
};
