// Adapted from https://gist.github.com/gaearon/830490fc17d3fccc88c9

import { forwardRef, HTMLProps, SyntheticEvent, useCallback, useEffect, useImperativeHandle, useRef } from 'react';

type AudioProps = Omit<HTMLProps<HTMLAudioElement>, 'onProgress' | 'onTimeUpdate'> & {
  defaultTime: number;
  isPlaying: boolean;
  onEnd: () => void;
  onProgress: (progress: OnProgressProps) => void;
  onTimeUpdate: (value: OnTimeUpdateProps) => void;
  source: string;
};

export type OnTimeUpdateProps = { buffered: TimeRanges; currentTime: number; trackDuration: number };
export type OnProgressProps = { buffered: TimeRanges; trackDuration: number };
export type AudioRef = { updateTimeBySeconds: (time: number) => void };

export const Audio = forwardRef<AudioRef, AudioProps>(
  (
    {
      source,
      isPlaying,
      defaultTime,
      onProgress = () => null,
      onTimeUpdate = () => null,
      onEnd = () => null,
      onError,
      ...props
    },
    ref,
  ) => {
    useImperativeHandle(ref, () => ({
      updateTimeBySeconds,
    }));

    const elementRef = useRef<HTMLAudioElement>(null);
    const playPausePromiseRef = useRef<any>(null);

    const handleProgress = useCallback(() => {
      const node = elementRef.current;
      if (!node || !onProgress) {
        return;
      }

      const { buffered } = node;
      const trackDuration = node.duration;
      onProgress({ buffered, trackDuration });
    }, [onProgress]);

    const handleTimeUpdate = useCallback(() => {
      const node = elementRef.current;
      if (!node || !onTimeUpdate) {
        return;
      }

      const { currentTime } = node;
      const trackDuration = node.duration;
      const { buffered } = node;
      onTimeUpdate({ currentTime, trackDuration, buffered });
    }, [onTimeUpdate]);

    const handleMediaEnd = useCallback(() => {
      const node = elementRef.current;
      if (!node) {
        return;
      }

      node.currentTime = 0;
      if (onEnd) {
        onEnd();
      }
    }, [onEnd]);

    const updateCurrentTime = useCallback(() => {
      const node = elementRef.current;
      if (!node || !node.readyState) {
        return;
      }

      node.currentTime = defaultTime;
    }, [defaultTime]);

    const _play = () =>
      forcePromise(() => {
        const node = elementRef.current;
        if (!node) {
          return;
        }

        return node.play();
      });

    const _pause = () =>
      forcePromise(() => {
        const node = elementRef.current;
        if (!node) {
          return;
        }

        return node.pause();
      });

    const _load = () =>
      forcePromise(() => {
        const node = elementRef.current;
        if (!node) {
          return;
        }

        return node.load();
      });

    const playPause = useCallback(() => {
      const node = elementRef.current;
      if (!node) {
        return;
      }

      let playPausePromise = forcePromise();
      if (node && !node.readyState) {
        playPausePromise = _load();
      }

      if (isPlaying) {
        playPausePromise = playPausePromise.then(_play);
      } else {
        playPausePromise = playPausePromise.then(_pause);
      }
      playPausePromiseRef.current = playPausePromise.catch((error) => {
        if (onError) {
          onError(error);
        }
      });
      return playPausePromiseRef.current;
    }, [isPlaying, onError]);

    const updateIsPlaying = useCallback(() => {
      const node = elementRef.current;
      if (!node) {
        return;
      }

      if (playPausePromiseRef.current) {
        playPausePromiseRef.current = playPausePromiseRef.current.then(playPause);
        return playPausePromiseRef.current;
      }
      return playPause();
    }, [playPause]);

    const updateTimeBySeconds = (newTime: number) => {
      if (elementRef.current) {
        elementRef.current.currentTime = elementRef.current.currentTime + newTime;
      }
    };

    const updateSource = useCallback(() => {
      const node = elementRef.current;
      if (!node || !node.readyState) {
        return;
      }

      try {
        let playPausePromise;
        if (playPausePromiseRef.current) {
          playPausePromise = playPausePromiseRef.current.then(_pause);
        } else {
          playPausePromise = _pause();
        }
        playPausePromise
          .then(() => {
            if (onTimeUpdate) {
              onTimeUpdate({
                currentTime: 0,
                trackDuration: node.duration,
                buffered: node.buffered,
              });
            }
            return _load().then(updateIsPlaying);
          })
          .catch((error: any) => {
            if (onError) {
              onError(error);
            }
          });
        playPausePromiseRef.current = playPausePromise;
      } catch (error) {
        onError?.(error as SyntheticEvent<HTMLAudioElement>);
      }
    }, [updateIsPlaying, onTimeUpdate, onError]);

    useEffect(() => {
      // Store a reference to currentElement to be able to access
      // the same node later in the cleanup (in case it has changed)
      const node = elementRef.current;
      if (!node) {
        return;
      }

      node.addEventListener('progress', handleProgress);
      node.addEventListener('timeupdate', handleTimeUpdate);
      node.addEventListener('ended', handleMediaEnd);

      updateIsPlaying();

      return () => {
        node.removeEventListener('progress', handleProgress);
        node.removeEventListener('timeupdate', handleTimeUpdate);
        node.removeEventListener('ended', handleMediaEnd);
      };
    }, [handleProgress, handleTimeUpdate, handleMediaEnd, updateIsPlaying]);

    useEffect(() => {
      updateSource();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [source]);

    useEffect(() => {
      updateIsPlaying();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPlaying]);

    useEffect(updateCurrentTime, [updateCurrentTime]);

    return <audio ref={elementRef} {...props} src={source} />;
  },
);

Audio.displayName = 'Audio';

const forcePromise = (nodeFunction: any = () => null) =>
  new Promise((resolve: (value?: any) => void, reject) => {
    try {
      const result = nodeFunction();
      if (result) {
        return result.then(resolve).catch(reject);
      }
      resolve();
    } catch (error) {
      reject(error);
    }
  });
