import { SVGProps } from 'react';

export const OpenLinkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props} fill="currentColor">
    <path
      d="M16 1h-3c-.56 0-1-.44-1-1s.44-1 1-1h3a.988.988 0 0 0 .707-.293A.993.993 0 0 0 17-2v-14a.988.988 0 0 0-.293-.707A.993.993 0 0 0 16-17H2a.988.988 0 0 0-.707.293A.993.993 0 0 0 1-16v3c0 .56-.44 1-1 1s-1-.44-1-1v-3c0-.406.079-.796.236-1.168.152-.36.366-.677.643-.953.276-.277.594-.49.953-.643A2.968 2.968 0 0 1 2-19h14c.406 0 .796.079 1.168.236.36.152.677.366.953.643.277.276.49.594.643.953.157.372.236.762.236 1.168v14c0 .406-.079.796-.236 1.168a2.975 2.975 0 0 1-.643.953c-.276.277-.594.491-.953.643A2.968 2.968 0 0 1 16 1z"
      transform="rotate(180 9.5 .5)"
    />
    <path
      d="M0-1h9a.998.998 0 0 1 1 1v9c0 .56-.44 1-1 1s-1-.44-1-1V2.414L.707 9.707a.99.99 0 0 1-1.414 0 .99.99 0 0 1 0-1.414L6.586 1H0c-.56 0-1-.44-1-1s.44-1 1-1z"
      transform="translate(10 1)"
    />
  </svg>
);
