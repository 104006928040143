import { dark, light } from 'domains/theme/constants/colors';

export const darkBorders = {
  normal: `1px solid ${dark.outline}`,
  box: `1px solid ${dark.outline}`,
};

export const lightBorders = {
  normal: `1px solid ${light.outline}`,
  box: 'none',
};
