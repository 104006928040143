import { useEffect, useRef } from 'react';
import { useAuth as oidcUseAuth } from 'react-oidc-context';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { LanguageCode } from 'common/types/languageCode';
import { setCookie } from 'common/utilities/cookies';
import { trackEvent } from 'domains/analytics/actions';
import { getSiteLanguage } from 'domains/language/selectors';
import { activateModal } from 'domains/modal/actions';

const VTYPE = 'vtype';
const VTOKEN = 'vtoken';
export const IS_AUTHENTICATING_COOKIE = 'IS_AUTHENTICATING';

export const useAuth = (shouldInit = false) => {
  const { signinRedirect: oidcSigninRedirect, revokeTokens, ...authParams } = oidcUseAuth();
  const dispatch = useDispatch();
  const { search } = useLocation();

  const languageRef = useRef('');
  let languageId: LanguageCode | 'cn' = useSelector(getSiteLanguage);
  // the Speedy Sign In widget uses 'cn' instead of 'zh'
  if (languageId === 'zh') {
    languageId = 'cn';
  }
  const renderCallback = useRef();
  const AUTH_WIDGET_ID = 'auth-widget';

  const signinRedirect = () => {
    const searchParameters = new URLSearchParams(search);
    const destinationParameter = searchParameters.get('destination');
    const hasVtypeAndVtoken = searchParameters.has(VTOKEN) && searchParameters.has(VTYPE);
    let redirectUri = window.location.href;
    if (destinationParameter) {
      // https://qa.intelligence.weforum.org/sso&desitnation=topics/asdfqwer&token=312532 => https://qa.intelligence.weforum.org/topics/asdfqwer
      redirectUri = `${process.env.REACT_APP_WEBSITE_URL}/${destinationParameter}`;
    }
    if (hasVtypeAndVtoken) {
      // more information about this in docs/ADR/authentication-vtype-vtoken.md
      const vTypeValue = searchParameters.get(VTYPE);
      const vTokenValue = searchParameters.get(VTOKEN);
      redirectUri = window.location.href
        .replace(`${VTYPE}=${vTypeValue}`, '')
        .replace(`${VTOKEN}=${vTokenValue}`, '')
        .replace('?&', '');
    }
    const resetPasswordFlow = new URL(window.location.href).pathname.includes('reset-password-login');
    if (resetPasswordFlow) {
      if (!process.env.REACT_APP_WEBSITE_URL) {
        console.error('unset environment variable REACT_APP_WEBSITE_URL');
      } else {
        redirectUri = process.env.REACT_APP_WEBSITE_URL;
      }
    }
    // more about IS_AUTHENTICATING_COOKIE in docs/ADR/IS_AUTHENTICATING-cookie.md
    setCookie(IS_AUTHENTICATING_COOKIE, 'true', 'path=/;max-age=60'); // 60 seconds
    oidcSigninRedirect({ redirect_uri: redirectUri });
  };

  useEffect(() => {
    if (languageId === languageRef.current) {
      return;
    }

    languageRef.current = languageId;

    // Remove this and just pass the process.env.REACT_APP_AUTH_WIDGET_SOCIAL_SIGNUP_URL once we are ready to roll out, and remove REACT_APP_AUTH_WIDGET_USE_SOCIAL_SIGNUP_URL env var
    const socialSignupConfiguration =
      process.env.REACT_APP_AUTH_WIDGET_USE_SOCIAL_SIGNUP_URL === 'true'
        ? {
            socialSignupUrl: process.env.REACT_APP_AUTH_WIDGET_SOCIAL_SIGNUP_URL,
          }
        : {};

    const config = {
      rootSelector: `#${AUTH_WIDGET_ID}`,
      apiKey: process.env.REACT_APP_AUTH_WIDGET_API_KEY,
      appName: process.env.REACT_APP_AUTH_WIDGET_APP_NAME,
      apiBaseUrl: process.env.REACT_APP_FWS_LOGIN_BASE_URL,
      clientId: process.env.REACT_APP_AUTH_WIDGET_CLIENT_ID,
      lang: languageRef.current,
      debug: false,
      resetPasswordUrl: process.env.REACT_APP_AUTH_WIDGET_RESET_PASSWORD_URL,
      verificationUrl: window.location.href,
      fwsPasswordlessUrl: process.env.REACT_APP_AUTH_WIDGET_FWS_PASSWORDLESS_URL,
      passwordlessRedirectUrl: process.env.REACT_APP_WEBSITE_URL,
      accountStateUrl: process.env.REACT_APP_AUTH_WIDGET_ACCOUNT_STATE_URL,
      ...socialSignupConfiguration,
    };

    if (shouldInit) {
      // the AuthWidget should be made available via scripts added to the <head>, in the public/index.html file
      const { initAuthentication } = window.AuthWidget;
      window.loginRadius = initAuthentication(config);

      // these are events fired by the speedy auth widget
      window.addEventListener('registrationsuccess', () => {
        dispatch(trackEvent({ name: 'Signup', eventType: 'success' }));
      });

      window.addEventListener('loginsuccess', () => {
        dispatch(trackEvent({ name: 'Login', eventType: 'logging in' }));
        signinRedirect();
      });
    }

    // when the modal opens/ closes, the html is rerendered which seems to make the google captcha used by the auth widget to go balistic.
    // this stops that from happening by preventing the call from being made if it already has once
    const handleCaptchaError = (event: ErrorEvent) => {
      if (event.message.toLowerCase().includes('captcha')) {
        event.preventDefault();
        event.stopImmediatePropagation();

        const captchaQuery = "script[src*='captcha'], iframe[src*='captcha']";
        const captchaEls = document.querySelectorAll(captchaQuery);

        window.grecaptcha = undefined;
        captchaEls.forEach((e) => e.remove());
        console.warn(event.message);
      }
    };

    window.addEventListener('error', handleCaptchaError);

    return () => {
      window.removeEventListener('error', handleCaptchaError);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageId]);

  const triggerLogin = () => {
    dispatch(
      activateModal({
        id: 'Authenticate',
        params: {
          callback: renderCallback.current,
          authWidgetId: AUTH_WIDGET_ID,
          hasDelay: 'true',
          isAlwaysWhite: 'true',
        },
      }),
    );
  };

  return { revokeTokens, ...authParams, triggerLogin, signinRedirect };
};
