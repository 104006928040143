import { css, styled } from 'styled-components';

import { EmailIcon, EventIcon, MapIcon, BellIcon, ChevronIcon } from 'common/components';
import { breakWordStyle } from 'common/styles';
import { useFormatDate } from 'common/utilities';
import { Notification } from 'domains/notifications/types';

const StyledContainer = styled.div`
  padding: 1rem;
  color: ${({ theme }) => theme.colors.textFade};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  transition: all 0.3s;
  margin-bottom: 10px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.modalCardBackground};
    color: ${({ theme }) => theme.colors.textBright};
  }
`;

const StyledIconContainer = styled.div`
  width: 24px;
  height: 24px;
  position: relative;
`;

const StyledBadge = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.red};
  position: absolute;
  right: -3px;
  bottom: -3px;
`;

const StyledTextContainer = styled.div`
  margin: 0 15px;
  width: 100%;
`;

const StyledDate = styled.div`
  white-space: nowrap;
  font-size: 0.8rem;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.textFade};
`;

const StyledTitle = styled.h3`
  font-size: 1rem;
  margin: 0 0 10px;
  color: ${({ theme }) => theme.colors.textBright};
  ${breakWordStyle}
`;

const StyledMessage = styled.div`
  font-size: 0.9rem;
  line-height: 1.3rem;
  color: ${({ theme }) => theme.colors.textFade};
  ${breakWordStyle}
`;

const iconStyle = css`
  width: 24px;
  height: 24px;
`;

const StyledNotificationIcon = styled(BellIcon)`
  ${iconStyle};
`;

const StyledEventIcon = styled(EventIcon)`
  ${iconStyle};
`;

const StyledMapIcon = styled(MapIcon)`
  ${iconStyle};
`;

const StyledInviteIcon = styled(EmailIcon)`
  ${iconStyle};
`;

const StyledChevron = styled(ChevronIcon)`
  width: 14px;
`;

// cluster, topic, knowledge
const getIcon = (name: string) => {
  switch (name) {
    case 'event':
      return <StyledEventIcon />;
    case 'map_share':
      return <StyledInviteIcon />;
    case 'new_map':
      return <StyledMapIcon />;
    case 'alert':
    default:
      return <StyledNotificationIcon />;
  }
};

type NotificationItemProps = { notification: Notification; onClick: (id: string) => () => void };

export const NotificationItem = ({ notification, onClick }: NotificationItemProps) => {
  const { getRelativeDate } = useFormatDate();

  const { id, title, time_set, has_been_opened, message, type } = notification;

  return (
    <StyledContainer onClick={onClick(id)}>
      <StyledIconContainer>
        {getIcon(type)}
        {!has_been_opened && <StyledBadge />}
      </StyledIconContainer>
      <StyledTextContainer>
        <StyledDate>{getRelativeDate({ date: time_set, precision: 'hour' })}</StyledDate>
        <StyledTitle>{title}</StyledTitle>
        <StyledMessage>{message}</StyledMessage>
      </StyledTextContainer>
      <StyledChevron />
    </StyledContainer>
  );
};
