import { PermissionData } from 'entities/permissions/types';
import { ErrorMeta } from 'types';

export enum PermissionsType {
  FETCH = 'entities/USER_FETCH_PERMISSIONS',
  FETCH_FULFILLED = 'entities/USER_FETCH_PERMISSIONS_SUCCESS',
  FETCH_REJECTED = 'entities/USER_FETCH_PERMISSIONS_FAILURE',
}

export type FetchPermissionsDefaultAction = { type: PermissionsType.FETCH };
export type FetchPermissionsFulfilledAction = { payload: PermissionData; type: PermissionsType.FETCH_FULFILLED };
export type FetchPermissionsRejectedAction = { meta: ErrorMeta; payload: string; type: PermissionsType.FETCH_REJECTED };

export type FetchPermissionAction =
  | FetchPermissionsDefaultAction
  | FetchPermissionsFulfilledAction
  | FetchPermissionsRejectedAction;
