import {
  ApplyEventsSearchCriteriaAction,
  TopicEventsType,
  ClearEventsSearchCriteriaAction,
} from 'domains/events/actionTypes';

export const applyEventsSearchCriteria = (payload: any): ApplyEventsSearchCriteriaAction => ({
  type: TopicEventsType.APPLY_SEARCH_CRITERIA,
  payload,
});

export const clearEventsSearchCriteria = (): ClearEventsSearchCriteriaAction => ({
  type: TopicEventsType.CLEAR_SEARCH_CRITERIA,
});
