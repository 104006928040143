import { styled } from 'styled-components';

import { CopyLinkField } from 'common/components/CopyLinkField';
import { Separator } from 'common/components/Separator';
import { Setter } from 'common/types/setter';
import { CollectionUpdatePermissions } from 'domains/collection/components/edit/parts/CollectionUpdatePermissions';
import { Permissions } from 'domains/collection/components/edit/types/permissions';
import { useTranslations } from 'domains/language/useTranslations';

const StyledHeading = styled.div`
  color: ${({ theme }) => theme.colors.textBright};
  font-weight: bold;
`;

const StyledCopyLinkField = styled(CopyLinkField)`
  flex: 0;
  margin: 0;
`;

type PermissionSettingsTabProps = { id: string; permissions: Permissions; setPermissions: Setter<Permissions> };

export const PermissionSettingsTab = ({ id, permissions, setPermissions }: PermissionSettingsTabProps) => {
  const { textDictionary } = useTranslations();

  return (
    <>
      <CollectionUpdatePermissions permissions={permissions} setPermissions={setPermissions} />
      <Separator />
      <StyledHeading>{textDictionary['app.create.collection.public_access']}</StyledHeading>
      <StyledCopyLinkField
        isDisabled={!permissions.isPublic}
        url={`${process.env.REACT_APP_WEBSITE_URL}/collection/${id}`}
      />
    </>
  );
};
