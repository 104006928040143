import { PropsWithChildren, Suspense } from 'react';

import { ErrorBoundary } from 'common/components/lazy/ErrorBoundary';
import { Fallback } from 'common/components/lazy/Fallback';

export const LazyComponent = ({ children }: PropsWithChildren) => (
  <ErrorBoundary>
    <Suspense fallback={<Fallback />}>{children}</Suspense>
  </ErrorBoundary>
);
