import { UIButton, StyledModalText } from 'common/components';
import { useTranslations } from 'domains/language/useTranslations';
import { ModalHeader } from 'domains/modal/components/ModalHeader';
import { useAuth } from 'useAuth';

export const UnauthenticatedModal = () => {
  const { triggerLogin } = useAuth();
  const { textDictionary } = useTranslations();
  return (
    <>
      <ModalHeader title={textDictionary['app.login.unauthenticated.title']} />
      <StyledModalText>{textDictionary['app.login.unauthenticated.message']}</StyledModalText>
      <UIButton onClick={() => triggerLogin()} variant={'filled'} fullwidth>
        {textDictionary['app.registration.join_us']}
      </UIButton>
    </>
  );
};
