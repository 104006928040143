export type UpgradeLocation = 'premium' | 'pro' | 'overview';

export const getUpgradeRedirectUrl = (location: UpgradeLocation) => {
  if (location === 'premium') {
    return process.env.REACT_APP_UPGRADE_PREMIUM_URL;
  } else if (location === 'pro') {
    return process.env.REACT_APP_UPGRADE_PRO_URL;
  }
  return null;
};
