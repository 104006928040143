import { SVGProps } from 'react';

export const PDFFileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
    <g id="Group_95327" data-name="Group 95327" transform="translate(12279 18488)">
      <g id="Rectangle_41726" data-name="Rectangle 41726" transform="translate(-12279 -18488)" fill="#2a63e9">
        <rect width="64" height="64" stroke="none" />
      </g>
      <g id="Group_95320" data-name="Group 95320" transform="translate(-12267.943 -18477)">
        <path
          id="Path_245113"
          data-name="Path 245113"
          d="M177.873,27.283,165.8,15.213A4.1,4.1,0,0,0,162.937,14h-21.66a4.141,4.141,0,0,0-4.079,4.079v34.36a4.069,4.069,0,0,0,4.079,4.079h33.731a4.069,4.069,0,0,0,4.079-4.079V30.149a4.1,4.1,0,0,0-1.213-2.866Zm-2.315.992H166.63a1.835,1.835,0,0,1-1.819-1.819V17.582Zm-.551,25.873H141.277a1.717,1.717,0,0,1-1.708-1.708V18.078a1.694,1.694,0,0,1,1.708-1.708h21.164V26.456a4.187,4.187,0,0,0,4.189,4.189h10.086V52.439a1.717,1.717,0,0,1-1.708,1.708Z"
          transform="translate(-137.198 -14)"
          fill="#fff"
        />
        <path
          id="Path_246198"
          data-name="Path 246198"
          d="M234.287,217.176c-.668-.572-1.335-1.287-2.1-2.05a12.278,12.278,0,0,1-1.573-2.241c.906-3.1,1.335-6.055.381-7.486a2.183,2.183,0,0,0-1.955-1,2.01,2.01,0,0,0-2,1.24c-.763,1.716.381,4.863,1.764,7.438-.381,1.24-.858,2.575-1.43,4.053-.334.811-.715,1.621-1.1,2.384-3.91,1.526-6.294,3.242-6.628,4.863a1.9,1.9,0,0,0,.763,1.955,2.141,2.141,0,0,0,1.287.477,2.381,2.381,0,0,0,.572-.1c1.812-.477,3.767-3.147,5.2-5.865a27.017,27.017,0,0,1,2.956-.954q1.931-.572,3.433-.858c1.86,1.478,3.433,2.289,4.816,2.289a3.154,3.154,0,0,0,2.527-1.049,1.717,1.717,0,0,0,.477-2.193c-.858-1.335-3.48-1.621-7.39-.906Zm-12.492,7.867a.473.473,0,0,1-.381-.048c-.191-.143-.191-.238-.143-.334.1-.572,1.192-1.621,3.576-2.765-1.144,1.716-2.289,2.956-3.052,3.147Zm6.771-18.738c.048-.1.1-.238.477-.238a.713.713,0,0,1,.572.238c.429.62.382,2.193-.143,4.291C228.661,208.785,228.231,207.068,228.565,206.305Zm1.335,11.92-1.431.429c.143-.334.286-.668.429-.954.334-.906.715-1.812,1.049-2.765A11.871,11.871,0,0,0,231,216.27c.429.477.858.906,1.287,1.335-.763.191-1.573.381-2.384.62Zm10.06.858a1.594,1.594,0,0,1-1.287.524,4.914,4.914,0,0,1-2.575-1C238.864,218.225,240.437,218.511,239.961,219.083Z"
          transform="translate(-215.321 -188.477)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);
