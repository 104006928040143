import { RootState } from 'types';

export const getLatestKnowledgeItems = (state: RootState) =>
  state.domains.latestKnowledge.items.map((id) => state.entities.knowledge.byId[id]);

export const getLatestKnowledgeIsPending = (state: RootState) => state.domains.latestKnowledge.isPending;

export const getLatestKnowledgeOffset = (state: RootState) => state.domains.latestKnowledge.offset;

export const getLatestKnowledgeHasNextPage = (state: RootState) => state.domains.latestKnowledge.hasNextPage;
