import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { changePermissionSuccess, changePermissionFailure } from 'domains/createShareSettings/actions';
import { ChangeInvitePermissionDefaultAction } from 'domains/createShareSettings/actionTypes';
import { activateModal } from 'domains/modal/actions';

const ENDPOINT_URL = 'mapshare/access';

export function* changePermissionSaga(action: ChangeInvitePermissionDefaultAction) {
  const { email, topicId, permission } = action.meta;
  const body = { topic_id: topicId, shared_users: [{ email, permission }] };
  try {
    yield apiFetch(ENDPOINT_URL, {
      method: 'PUT',
      body,
    });
    yield put(changePermissionSuccess(action.meta));
  } catch (error) {
    yield put(changePermissionFailure(action.meta));
    yield put(activateModal({ id: 'Error' }));
  }
}
