import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { Overlay } from 'common/components';
import {
  fetchCoachmarksVisibilityStatus,
  fetchUsedCoachmarks,
  addCoachmarksToUsed,
  initCoachmarks,
} from 'domains/coachmarks/actions';
import { Coachmark } from 'domains/coachmarks/components/Coachmark';
import { CoachmarkOverlay } from 'domains/coachmarks/components/CoachmarkOverlay';
import { getCoachmarksAreEnabled, getUnusedCoachmarksInDOM } from 'domains/coachmarks/selectors';
import { getSiteLanguage } from 'domains/language/selectors';
import { getIsModalActive } from 'domains/modal/selectors';
import { fetchCoachmarks } from 'entities/coachmarks/actions';

const StyledWrapper = styled(Overlay)`
  top: 0;
  z-index: 9999;
`;

export const CoachmarkStack = () => {
  const dispatch = useDispatch();
  const coachmarksInDOM = useSelector(getUnusedCoachmarksInDOM);
  const coachmarksAreEnabled = useSelector(getCoachmarksAreEnabled);
  const modalIsActive = useSelector(getIsModalActive);
  const language = useSelector(getSiteLanguage);
  const [activeIndex, setActiveIndex] = useState(0);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const activeCoachmark = coachmarksInDOM[activeIndex];

  let targetElement: HTMLElement | null = null;
  if (activeCoachmark?.target_selector) {
    targetElement = document.querySelector(activeCoachmark?.target_selector);
  }

  const handlePrevCoachmark = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    }
  };

  const handleNextCoachmark = () => {
    if (activeIndex < coachmarksInDOM.length - 1) {
      setActiveIndex(activeIndex + 1);
    }
  };

  const handleCoachmarkClose = () => {
    dispatch(
      addCoachmarksToUsed({
        ids: coachmarksInDOM.map(({ id }) => id),
      }),
    );
  };

  const handleWindowResize = useCallback(() => {
    setViewportWidth(window.innerWidth);
    setViewportHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    dispatch(fetchCoachmarks());
  }, [language, dispatch]);

  useEffect(() => {
    if (coachmarksAreEnabled === null) {
      dispatch(fetchCoachmarksVisibilityStatus());
      dispatch(fetchUsedCoachmarks());
    }
  }, [coachmarksAreEnabled, dispatch]);

  useEffect(() => {
    dispatch(initCoachmarks());
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [dispatch, handleWindowResize]);

  useEffect(() => {
    setActiveIndex(0);
  }, [dispatch, coachmarksInDOM]);

  if (!coachmarksInDOM.length || !coachmarksAreEnabled || modalIsActive) {
    return null;
  }
  return (
    <StyledWrapper isActive={true}>
      <CoachmarkOverlay
        targetElement={targetElement || undefined}
        viewportHeight={viewportHeight}
        viewportWidth={viewportWidth}
        shouldHighlightElement={activeCoachmark?.highlight_element}
        isActive={!!activeCoachmark}
        cutoutSpacing={activeCoachmark?.cutout_spacing}
        onClose={handleCoachmarkClose}
      />
      {coachmarksInDOM.map((coachmark, index) => (
        <Coachmark
          {...coachmark}
          index={index}
          key={coachmark.id}
          coachmarkCount={coachmarksInDOM.length}
          activeIndex={activeIndex}
          onClose={handleCoachmarkClose}
          onNextClick={handleNextCoachmark}
          onPrevClick={handlePrevCoachmark}
          viewportHeight={viewportHeight}
          viewportWidth={viewportWidth}
        />
      ))}
    </StyledWrapper>
  );
};
