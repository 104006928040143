import debounce from 'lodash.debounce';
import { useCallback, useEffect, useState, useRef, ChangeEvent } from 'react';
import { css, styled } from 'styled-components';

import { ForwardIcon, PauseIcon, PlayIcon, ReplayIcon, Spinner } from 'common/components';
import { AudioRef, OnProgressProps, OnTimeUpdateProps, Audio } from 'domains/audioPlayer/components/Audio';
import { AudioTrack } from 'domains/audioPlayer/components/AudioTrack';
import { AudioFile } from 'domains/audioPlayer/types';
import { useTranslations } from 'domains/language/useTranslations';

const StyledContent = styled.div`
  color: ${({ theme }) => theme.colors.textFade};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
`;

const StyledTitle = styled.div`
  font-size: 0.8rem;
  line-height: 1rem;
  text-transform: uppercase;
`;

const StyledName = styled.div`
  font-size: 0.8rem;
  line-height: 0.9rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.textBright};
`;

const StyledControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
`;

const iconStyles = css`
  width: 42px;
  height: 42px;
  cursor: pointer;
  transition: color 0.3s;
  &:hover {
    color: ${({ theme }) => theme.colors.textBright};
  }
`;

const StyledPlayIcon = styled(PlayIcon)`
  ${iconStyles};
`;

const StyledPauseIcon = styled(PauseIcon)`
  ${iconStyles};
`;

const StyledPlayToggleButton = styled.div`
  margin: 0 2rem;
`;

const iconSmallStyles = css`
  width: 32px;
  height: 32px;
  cursor: pointer;
  transition: color 0.3s;
  &:hover {
    color: ${({ theme }) => theme.colors.textBright};
  }
`;

const StyledReplayIcon = styled(ReplayIcon)`
  ${iconSmallStyles};
`;

const StyledForwardIcon = styled(ForwardIcon)`
  ${iconSmallStyles};
`;

const StyledSpinner = styled(Spinner)`
  ${iconStyles};
`;

type AudioPlayerProps = { audioFile: AudioFile };

export const AudioPlayer = ({ audioFile }: AudioPlayerProps) => {
  const { textDictionary } = useTranslations();
  const audioRef = useRef<AudioRef>(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [defaultTime, setDefaultTime] = useState(0);
  const [hasErrorOccurred, setHasErrorOccurred] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [buffered, setBufferedAmounts] = useState<TimeRanges | undefined>(undefined);
  const bufferedTime = buffered && buffered.length ? buffered.end(buffered.length - 1) : 0;

  const togglePlaying = () => {
    setHasErrorOccurred(false);
    if (!isPlaying) {
      setIsLoading(true);
    }
    setIsPlaying(!isPlaying);
  };

  const onProgress = useCallback(({ trackDuration, buffered }: OnProgressProps) => {
    if (Number.isFinite(trackDuration)) {
      setDuration(Math.floor(trackDuration));
    }
    setBufferedAmounts(buffered);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    if (bufferedTime >= currentTime) {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buffered]);

  const onTimeUpdate = useCallback(
    ({ currentTime, trackDuration, buffered }: OnTimeUpdateProps) => {
      const isCurrentTimeValid = Number.isFinite(currentTime);
      const isTrackDurationValid = Number.isFinite(trackDuration);
      if (isCurrentTimeValid) {
        setCurrentTime(Math.floor(currentTime));
      }
      if (isTrackDurationValid) {
        onProgress({ trackDuration, buffered });
      }
      if (isCurrentTimeValid && isTrackDurationValid && currentTime > defaultTime) {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultTime, onProgress],
  );

  const debouncedSetDefaultTime = debounce((value: number) => {
    if (value > currentTime) {
      setIsLoading(true);
    }
    setDefaultTime(value);
  }, 100);

  const onTrackChange = (event: ChangeEvent<HTMLInputElement>) => {
    const time = parseInt(event.target.value);
    setCurrentTime(time);
    debouncedSetDefaultTime(time);
  };

  const onError = useCallback(() => {
    setHasErrorOccurred(true);
    setIsPlaying(false);
    setIsLoading(false);
    setDefaultTime(0);
  }, []);

  const onEnd = useCallback(() => setIsPlaying(false), []);

  const onSetTime = (seconds: number) => () => {
    audioRef?.current?.updateTimeBySeconds(seconds);
  };

  useEffect(() => {
    setIsLoading(true);
  }, [audioFile.url]);

  if (hasErrorOccurred) {
    return null;
  }

  return (
    <>
      <StyledContent>
        <StyledTitle>{textDictionary['app.topics.podcast.overview_of']}</StyledTitle>
        <StyledName>{audioFile.name}</StyledName>
        <StyledControls>
          <StyledReplayIcon onClick={onSetTime(-10)} />
          <StyledPlayToggleButton onClick={togglePlaying}>
            {isLoading ? <StyledSpinner /> : <>{isPlaying ? <StyledPauseIcon /> : <StyledPlayIcon />}</>}
          </StyledPlayToggleButton>
          <StyledForwardIcon onClick={onSetTime(10)} />
        </StyledControls>
        <AudioTrack
          currentTime={currentTime}
          duration={duration}
          onChange={onTrackChange}
          bufferedTime={bufferedTime}
        />
      </StyledContent>
      <Audio
        ref={audioRef}
        isPlaying={isPlaying}
        onTimeUpdate={onTimeUpdate}
        onProgress={onProgress}
        onEnd={onEnd}
        onError={onError}
        source={audioFile.url}
        defaultTime={defaultTime}
        hidden={true}
      />
    </>
  );
};
