import { Knowledge } from 'entities/knowledge/types';
import { ErrorMeta, ErrorPayload } from 'types';

export enum PublicationActionTypes {
  FETCH_FOR_TOPIC = 'entities/PUBLICATION_FETCH_FOR_TOPIC',
  FETCH_FOR_TOPIC_SUCCESS = 'entities/PUBLICATION_FETCH_FOR_TOPIC_SUCCESS',
  FETCH_FOR_TOPIC_FAILURE = 'entities/PUBLICATION_FETCH_FOR_TOPIC_FAILURE',
}

export type PublicationFetchForTopicPayload = {
  fromTime?: number;
  id: string;
  language?: string;
  limit: number;
  offset: number;
  publicationType?: string;
  searchTerm?: string;
  secondaryTopicId?: string | undefined;
  toTime?: number;
};

export type PublicationFetchForTopicAction = {
  payload: PublicationFetchForTopicPayload;
  type: PublicationActionTypes.FETCH_FOR_TOPIC;
};

export type PublicationFetchForTopicSuccessAction = {
  meta: PublicationFetchForTopicPayload;
  payload: { data: Knowledge[]; topicId: string };
  type: PublicationActionTypes.FETCH_FOR_TOPIC_SUCCESS;
};

export type PublicationFetchForTopicFailurePayload = ErrorPayload & { topicId: string };
type PublicationFetchForTopicFailureMeta = ErrorMeta & { searchCriteria: PublicationFetchForTopicPayload };

export type PublicationFetchForTopicFailureAction = {
  meta: PublicationFetchForTopicFailureMeta;
  payload: PublicationFetchForTopicFailurePayload;
  type: PublicationActionTypes.FETCH_FOR_TOPIC_FAILURE;
};

export type PublicationAction =
  | PublicationFetchForTopicAction
  | PublicationFetchForTopicSuccessAction
  | PublicationFetchForTopicFailureAction;
