import { useDispatch } from 'react-redux';

import { UIChip } from 'common/components';
import { useTranslations } from 'domains/language/useTranslations';
import { updateTopicsFilter } from 'domains/monitor/filter/actions';
import { Topic } from 'entities/topics/types';

type FilterTagsProps = { items: Topic[]; selectedItems: string[] };

export const FilterTags = ({ items, selectedItems }: FilterTagsProps) => {
  const dispatch = useDispatch();
  const { textDictionary } = useTranslations();

  const onClick = (id: string) => () => {
    const removeFilter = selectedItems.includes(id);

    const newItems = removeFilter ? selectedItems.filter((item) => item !== id) : [id, ...selectedItems];
    dispatch(updateTopicsFilter(newItems));
  };

  const clear = () => dispatch(updateTopicsFilter([]));

  return (
    <>
      <UIChip isActive={selectedItems.length === 0} onClick={clear}>
        {textDictionary['app.discover.topic.tabs.knowledge.filter.options.all']}
      </UIChip>
      {items.map((item) => (
        <UIChip key={item.id} isActive={selectedItems.includes(item.id)} onClick={onClick(item.id)}>
          {item.name}
        </UIChip>
      ))}
    </>
  );
};
