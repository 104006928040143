/*
 * CookieStorage class implements custom Oauth2 metadata storage strategy
 * the StateStore wants all Promises to be returned, hence they are wrapped in an
 * extra promise layer here
 */
import { WebStorageStateStore } from 'oidc-client-ts';

import { getCookie } from 'common/utilities/cookies';

const REFRESH_TOKEN_COOKIE_KEY = process.env.REACT_APP_REFRESH_TOKEN_COOKIE_KEY!;
const OIDC_LOCAL_STORAGE_PREFIX = 'oidc.';
const OIDC_REFRESH_TOKEN_KEY = 'refresh_token';

export const setRefreshTokenCookie = (token: string) => {
  const date = new Date();
  // the refresh token lasts for 30 days
  date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
  document.cookie = `${REFRESH_TOKEN_COOKIE_KEY}=${token};expires=${date.toUTCString()};path=/;domain=weforum.org;`;
};

export class WithRemoteRefreshTokenStorage extends WebStorageStateStore {
  override set(key: string, data: string): Promise<void> {
    // set local storage and remote cookie
    localStorage.setItem(`${OIDC_LOCAL_STORAGE_PREFIX}${key}`, data);
    setRefreshTokenCookie(JSON.parse(data)[OIDC_REFRESH_TOKEN_KEY]);
    fetch(
      `${process.env.REACT_APP_FWS_LOGIN_BASE_URL}/ssologin/setToken?token=${
        JSON.parse(data).access_token
      }&apikey=${process.env.REACT_APP_AUTH_WIDGET_API_KEY}`,
      { method: 'GET', credentials: 'include' },
    );
    return Promise.resolve();
  }

  override get(key: string): Promise<string | null> {
    const refreshToken = this.getRefreshTokenFromCookie();
    // if there is no remote token, we are considered logged out (even if we have tokens in localhost)
    if (!refreshToken) {
      return Promise.resolve(null);
    }
    const oidcToken = localStorage.getItem(`${OIDC_LOCAL_STORAGE_PREFIX}${key}`);
    // we found a refresh token that we can use, we make sure to overwrite the local one in case its outdated
    // and then return the new set of tokens
    const oidcTokenJson = JSON.parse(oidcToken || '{}');
    oidcTokenJson[OIDC_REFRESH_TOKEN_KEY] = refreshToken;
    return Promise.resolve(JSON.stringify(oidcTokenJson));
  }

  override remove(key: string): Promise<string | null> {
    // the remove method is called on logout,so we need to make sure to delete from local host as well
    // remove from local host, and delete remote cookie
    const item = localStorage.getItem(`${OIDC_LOCAL_STORAGE_PREFIX}${key}`);
    localStorage.removeItem(`${OIDC_LOCAL_STORAGE_PREFIX}${key}`);
    this.removeRefreshTokenFromCookie();
    return Promise.resolve(item);
  }

  override getAllKeys(): Promise<string[]> {
    // stays the same
    const keys = [];
    for (let index = 0; index < localStorage.length; index++) {
      const key = localStorage.key(index);
      if (key) {
        keys.push(key);
      }
    }
    return Promise.resolve(keys);
  }

  // we remove the cookie by expiring it
  removeRefreshTokenFromCookie = () => {
    document.cookie = `${REFRESH_TOKEN_COOKIE_KEY}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=weforum.org;`;
  };

  getRefreshTokenFromCookie = () => getCookie(REFRESH_TOKEN_COOKIE_KEY);
}
