import { PropsWithChildren, ReactElement, useCallback, useEffect, useState } from 'react';
import { css, styled } from 'styled-components';

import { ExpandButton } from 'common/components';
import { breakpoints } from 'common/styles';

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

type PanelProps = { show: boolean; showLeftPanel?: boolean };

const StyledContent = styled.div`
  height: 100%;
  display: none;
  overflow: hidden;
  flex: 3;

  @media ${breakpoints.large} {
    display: flex;
  }
`;

const panelStyle = css`
  position: relative;
  transition:
    flex 0.3s,
    max-width 0.3s;
  width: 0;
`;

const getFlex = ({ show }: PanelProps) => (show ? 2 : 0);

const StyledLeftPanel = styled.div<PanelProps>`
  max-width: 450px;
  display: none;
  flex: ${(props) => getFlex(props)};
  ${panelStyle};

  @media ${breakpoints.enormous} {
    display: flex;
  }
`;

const StyledRightPanel = styled.div<PanelProps>`
  flex: ${(props) => getFlex(props)};
  ${panelStyle};

  @media ${breakpoints.medium} {
    display: flex;
  }
  @media ${breakpoints.large} {
    max-width: ${({ showLeftPanel }) => (showLeftPanel ? 650 : 800)}px;
  }
`;

const StyledInnerPanel = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

type VisibilityChange = { right: boolean } | { left: boolean };

type PanelContainerProps = PropsWithChildren & {
  onPanelVisibilityToggle: (visibilityChange: VisibilityChange) => void;
  panelLeft?: ReactElement | null;
  panelRight: ReactElement;
};

export const PanelContainer = ({
  children,
  onPanelVisibilityToggle,
  panelLeft = null,
  panelRight,
}: PanelContainerProps) => {
  const [showLeftPanel, setShowLeftPanel] = useState(!!panelLeft);
  const [showRightPanel, setShowRightPanel] = useState(!!panelRight);

  const toggleLeftPanel = useCallback(
    (isOpen: boolean) => {
      setShowLeftPanel(isOpen);
      if (onPanelVisibilityToggle) {
        onPanelVisibilityToggle({ left: isOpen });
      }
    },
    [onPanelVisibilityToggle],
  );

  const toggleRightPanel = useCallback(
    (isOpen: boolean) => {
      setShowRightPanel(isOpen);
      if (onPanelVisibilityToggle) {
        onPanelVisibilityToggle({ right: isOpen });
      }
    },
    [onPanelVisibilityToggle],
  );

  const isPanelLeftAvailable = !!panelLeft;
  useEffect(() => {
    toggleLeftPanel(isPanelLeftAvailable);
  }, [isPanelLeftAvailable, toggleLeftPanel]);

  const isPanelRightAvailable = !!panelRight;
  useEffect(() => {
    toggleRightPanel(isPanelRightAvailable);
  }, [isPanelRightAvailable, toggleRightPanel]);

  return (
    <StyledContainer>
      {panelLeft && (
        <StyledLeftPanel show={showLeftPanel}>
          <ExpandButton isOpen={showLeftPanel} onClick={() => toggleLeftPanel(!showLeftPanel)} />
          <StyledInnerPanel>{panelLeft}</StyledInnerPanel>
        </StyledLeftPanel>
      )}
      <StyledContent>{children}</StyledContent>
      {panelRight && (
        <StyledRightPanel show={showRightPanel} showLeftPanel={showLeftPanel}>
          <ExpandButton isOpen={showRightPanel} onClick={() => toggleRightPanel(!showRightPanel)} isRightPanel={true} />
          <StyledInnerPanel>{panelRight}</StyledInnerPanel>
        </StyledRightPanel>
      )}
    </StyledContainer>
  );
};
