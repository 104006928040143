import { APIIncident } from 'domains/incidents/types';

export enum IncidentTypes {
  FETCH_UNRESOLVED = 'domains/INCIDENTS_FETCH_UNRESOLVED',
  FETCH_UNRESOLVED_SUCCESS = 'domains/INCIDENTS_FETCH_UNRESOLVED_SUCCESS',
  FETCH_UNRESOLVED_FAILURE = 'domains/INCIDENTS_FETCH_UNRESOLVED_FAILURE',
  DISMISS_INCIDENT = 'domains/INCIDENTS_DISMISS_INCIDENT',
}

export type FetchIncidentsAction = { type: IncidentTypes.FETCH_UNRESOLVED };
export type FetchIncidentsSuccessAction = { payload: APIIncident[]; type: IncidentTypes.FETCH_UNRESOLVED_SUCCESS };
export type FetchIncidentsFailureAction = { payload: { error: any }; type: IncidentTypes.FETCH_UNRESOLVED_FAILURE };
export type DismissIncidentAction = { payload: { id: string }; type: IncidentTypes.DISMISS_INCIDENT };

export type IncidentAction =
  | FetchIncidentsAction
  | FetchIncidentsSuccessAction
  | FetchIncidentsFailureAction
  | DismissIncidentAction;
