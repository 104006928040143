import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { LeftArrow, CrossIcon } from 'common/components/icons';
import { deactivateModal, popModalScreen } from 'domains/modal/actions';
import { getShouldDisplayBackButton } from 'domains/modal/selectors';

const StyledTitleContainer = styled.div<{ rightAlign: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ rightAlign }) => (rightAlign ? 'flex-end' : 'space-between')};
  margin-bottom: 3rem;
  padding: 1.5rem 0;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.textBright}0F; // 1/16 opacity

  @media (max-height: 480px) {
    margin-bottom: 1.5rem;
  }
`;

const StyledModalTitle = styled.h2`
  margin: 0;
  font-weight: bold;
  font-size: 1.5rem;
  height: 1.5rem;
  color: ${({ theme }) => theme.colors.textBright};
`;

const StyledCrossIcon = styled(CrossIcon)`
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.colors.textFade};
  cursor: pointer;
  margin-left: 3rem;
  transition: all 0.3s;
  border-radius: 20px;
  padding: 10px;
  &:hover {
    color: ${({ theme }) => theme.colors.textBright};
    background: ${({ theme }) => theme.colors.modalCardBackground};
  }
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTitleRow = styled(StyledRow)`
  cursor: pointer;
`;

const StyledChevron = styled(LeftArrow)`
  width: 20px;
  height: 14px;
  margin-right: 1rem;
  color: ${({ theme }) => theme.colors.textBright};
`;

type ModalHeaderProps = { className?: string; dismissAction?: () => void; rightElement?: ReactElement; title?: string };

export const ModalHeader = ({ title, rightElement, className, dismissAction }: ModalHeaderProps) => {
  const dispatch = useDispatch();
  const shouldDisplayBackButton = useSelector(getShouldDisplayBackButton);

  const handleDeactivateModal = () => dispatch(deactivateModal());
  const handlePopModal = () => dispatch(popModalScreen());

  return (
    <StyledTitleContainer rightAlign={!title} className={className}>
      <StyledTitleRow onClick={shouldDisplayBackButton ? handlePopModal : undefined}>
        {shouldDisplayBackButton && <StyledChevron />}
        {title && <StyledModalTitle data-test-id="styled-modal-title">{title}</StyledModalTitle>}
      </StyledTitleRow>
      <StyledRow>
        {rightElement}
        <StyledCrossIcon onClick={dismissAction || handleDeactivateModal} />
      </StyledRow>
    </StyledTitleContainer>
  );
};
