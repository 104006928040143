import { memo, ReactElement, useState } from 'react';
import { css, styled } from 'styled-components';

import { Image } from 'common/components';
import { ExpandableContent } from 'common/components/ExpandableContent';
import { breakWordStyle, lineTruncationStyle } from 'common/styles';

const StyledListLink = styled.a<{ isExpandable: boolean; isSelected: boolean }>`
  color: ${({ theme }) => theme.colors.textBright};
  cursor: ${({ isExpandable }) => (isExpandable ? 'pointer' : 'default')};
  display: flex;
  flex-direction: column;
  text-decoration: none;

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      border: 1px solid ${theme.colors.textBright};
    `}
`;

const StyledTitle = styled.div`
  font-weight: 500;
  font-size: 1rem;
  margin: 0;
  margin-top: 5px;

  ${breakWordStyle}
  ${lineTruncationStyle(1)}
`;

const StyledRow = styled.div`
  display: flex;
`;

const StyledImage = styled(Image)`
  width: 50px;
  min-width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 1rem;
`;

const StyledContent = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const StyledDescription = styled.p`
  margin: 0;
  border-top: ${({ theme }) => theme.borders.normal};
  padding-top: 10px;
`;

const StyledTextContent = styled.div`
  padding-top: 10px;
`;

type SmallListItemProps = {
  description?: string;
  image?: string;
  isSelected?: boolean;
  subElement?: ReactElement | null;
  title: string;
};

export const SmallListItem = memo(
  ({ isSelected = false, title, description, subElement = null, image }: SmallListItemProps) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const toggleExpansion = () => setIsExpanded(!isExpanded);
    return (
      <StyledListLink
        isSelected={isSelected}
        onClick={description ? toggleExpansion : undefined}
        isExpandable={!!description}
      >
        <StyledRow>
          {image && <StyledImage src={image} alt={title} />}
          <StyledContent>
            {subElement}
            <StyledTitle>{title}</StyledTitle>
          </StyledContent>
        </StyledRow>
        {description && (
          <ExpandableContent isActive={isExpanded}>
            <StyledTextContent>
              <StyledDescription>{description}</StyledDescription>
            </StyledTextContent>
          </ExpandableContent>
        )}
      </StyledListLink>
    );
  },
);

SmallListItem.displayName = 'SmallListItem';
