import { css } from 'styled-components';

import { breakpoints } from 'common/styles/mediaQueries/breakpoints';
import { ScreenSize } from 'common/styles/mediaQueries/types';

type SupportedStyles = {
  spacing: number;
};

export const styles: Record<ScreenSize, SupportedStyles> = {
  small: {
    spacing: 1,
  },
  medium: {
    spacing: 2,
  },
  large: {
    spacing: 2,
  },
  extraLarge: {
    spacing: 3,
  },
  enormous: {
    spacing: 3,
  },
};

export const mediaStyles = (
  getValue: (style: SupportedStyles) => any,
  breakpointStyles: Partial<Record<ScreenSize, SupportedStyles>> = styles,
) => css`
  ${breakpointStyles.small ? getValue(breakpointStyles.small) : undefined};
  ${Object.keys(breakpointStyles)
    .map((size) => {
      const style = breakpointStyles[size as ScreenSize];
      if (!style || size === 'small') {
        return undefined;
      }
      return css`
        @media ${breakpoints[size as ScreenSize]} {
          ${getValue(style)}
        }
      `;
    })
    .filter((style) => !!style)};
`;
