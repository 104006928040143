import { HTMLProps } from 'react';
import ReactPlayer from 'react-player/youtube';
import { styled } from 'styled-components';

export const MediaContainer = styled.div`
  position: relative;
  background-color: black;
  max-height: 100%;

  // padding-top: 50% magically allows the video player to size itself
  // according to its actual size. However, it also means that because this
  // element doesn't have any actual height then it doesn't get constrained by
  // the max-height from above. Hence we use a trick from StackOverflow
  // (https://stackoverflow.com/a/51973507/4489717) to put the padding in a
  // pseudo-element so that it does form part of the element's height, letting
  // the max-height of parent elements affect it.

  &::before {
    content: '';
    display: block;
    padding-top: 50%;
  }

  iframe,
  video {
    border-radius: 5px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
  }
`;

export type WebFrameProps = HTMLProps<HTMLIFrameElement> & {
  className?: string;
  height?: number;
  html?: string;
  onEnded?: () => void;
  onPauseOrPlay?: () => void;
  title: string;
  url: string;
  useReactPlayer?: boolean;
  width?: number;
};

export const WebFrame = ({
  className,
  url,
  html,
  title,
  onEnded,
  onPauseOrPlay,
  useReactPlayer,
  ...props
}: WebFrameProps) => {
  if (html) {
    const htmlMinusFullscreen = html.replace('allowfullscreen', '');
    return <MediaContainer className={className} dangerouslySetInnerHTML={{ __html: htmlMinusFullscreen }} />;
  } else if (useReactPlayer) {
    return (
      <MediaContainer className={className}>
        <ReactPlayer
          config={{ playerVars: { rel: 0 } }}
          controls
          onEnded={onEnded}
          onPause={onPauseOrPlay}
          onPlay={onPauseOrPlay}
          url={url}
        />
      </MediaContainer>
    );
  }

  return (
    <MediaContainer className={className}>
      <iframe
        src={url}
        title={title}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        {...props}
      />
    </MediaContainer>
  );
};
