import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { styled } from 'styled-components';

import { ChevronIcon } from 'common/components';

const StyledArrowContainer = styled.div<{ side: 'left' | 'right'; visible: boolean }>`
  ${({ side }) => `${side}: 0.25rem;`}
  align-items: center;
  background-color: ${({ theme }) => theme.colors.carouselButton};
  border-radius: 50%;
  cursor: pointer;
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  justify-content: center;
  opacity: 0.5;
  padding: 0.75rem;
  position: absolute;
  top: calc(50% - 20px);
`;

const StyledContainer = styled.div`
  position: relative;
  width: 100%;

  &:hover {
    ${StyledArrowContainer} {
      opacity: 1;
    }
  }
`;

const StyledChildrenContainer = styled.div`
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  display: flex;
  overflow-x: auto;
  padding: 0;
  scroll-behavior: smooth;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledRightArrow = styled(ChevronIcon)`
  color: ${({ theme }) => theme.colors.carouselButtonFill};
  height: 16px;
  width: 16px;
`;

const StyledLeftArrow = styled(StyledRightArrow)`
  transform: rotate(180deg);
`;

export const LanguagesCarousel = ({ children }: PropsWithChildren) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [canScroll, setCanScroll] = useState<{ left: boolean; right: boolean }>({ left: true, right: true });

  const onScroll = () => {
    const container = containerRef.current;
    if (container === null) return;
    setCanScroll({
      left: container.scrollLeft > 0,
      right: container.offsetWidth + container.scrollLeft + 1 < container.scrollWidth,
    });
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container === null) return;
    const observer = new ResizeObserver(onScroll);
    observer.observe(container);
    return () => observer.unobserve(container);
  }, []);

  const scroll = (direction: 'left' | 'right') =>
    containerRef.current?.scrollBy(containerRef.current.clientWidth * 0.8 * (direction === 'left' ? -1 : 1), 0);

  return (
    <StyledContainer>
      <StyledChildrenContainer ref={containerRef} onScroll={onScroll}>
        {children}
      </StyledChildrenContainer>
      <StyledArrowContainer onClick={() => scroll('left')} side="left" visible={!isMobile && canScroll.left}>
        <StyledLeftArrow />
      </StyledArrowContainer>
      <StyledArrowContainer onClick={() => scroll('right')} side="right" visible={!isMobile && canScroll.right}>
        <StyledRightArrow />
      </StyledArrowContainer>
    </StyledContainer>
  );
};
