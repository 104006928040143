import { styled } from 'styled-components';

import { LandingCarousel } from 'domains/landing/components/StyledLandingCarousel';
import { StyledLandingText } from 'domains/landing/components/StyledLandingText';
import { StyledLandingTitle } from 'domains/landing/components/StyledLandingTitle';
import { TopicTile } from 'domains/topic/components/topicTile/TopicTile';
import { Topic } from 'entities/topics/types';

type StyledProps = { index?: number; isLoaded: boolean; itemWidth?: number };

const ITEM_WIDTH = 300;

const StyledContainer = styled.div`
  max-width: 1600px;
  box-sizing: border-box;
  width: 100%;
`;

const StyledContent = styled.div<StyledProps>`
  transition: opacity 1s ease-in-out;
  opacity: ${({ isLoaded }) => (isLoaded ? 1 : 0)};
`;

const StyledCard = styled.div<StyledProps>`
  width: ${ITEM_WIDTH}px;
  padding-right: 20px;
  border-radius: 10px;
  flex-shrink: 0;
  scroll-snap-align: start;
  transition: opacity 1s ease-in-out ${({ index = 0 }) => 0.3 + 0.1 * index}s;
  opacity: ${({ isLoaded }) => (isLoaded ? 1 : 0)};
`;

const StyledText = styled(StyledLandingText)`
  margin: 0;
`;

type TopicSliderProps = { isLoaded: boolean; message?: string; title: string; topics: Topic[] };

export const TopicSlider = ({ title, message, topics, isLoaded }: TopicSliderProps) => {
  if (topics.length === 0) {
    return null;
  }

  return (
    <StyledContainer>
      <StyledContent isLoaded={isLoaded}>
        <StyledLandingTitle>{title}</StyledLandingTitle>
        <StyledText>{message}</StyledText>
      </StyledContent>
      <LandingCarousel count={topics.length} isLoaded={isLoaded} itemWidth={ITEM_WIDTH}>
        {topics.map((topic, index) => (
          <StyledCard key={topic.id} isLoaded={isLoaded} index={index}>
            <TopicTile
              id={topic.id}
              name={topic.name}
              images={topic.images}
              attachment={topic.attachment}
              is_new={topic.is_new}
            />
          </StyledCard>
        ))}
      </LandingCarousel>
    </StyledContainer>
  );
};
