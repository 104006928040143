import { useDispatch, useSelector } from 'react-redux';

import { trackEvent } from 'domains/analytics/actions';
import { getSiteLanguage, getSiteLanguageLabel } from 'domains/language/selectors';
import { useTranslations } from 'domains/language/useTranslations';
import { Knowledge } from 'entities/knowledge/types';

export const useTranslationLink = (knowledge?: Knowledge) => {
  const dispatch = useDispatch();
  const siteLanguage = useSelector(getSiteLanguage);
  const siteLanguageLabel = useSelector(getSiteLanguageLabel);
  const { interpolate, textDictionary } = useTranslations();

  return knowledge === undefined
    ? { displayTranslationLink: false }
    : {
        displayTranslationLink: knowledge.language !== siteLanguage,
        onTranslationLinkClick: () =>
          dispatch(
            trackEvent({
              articleLanguage: knowledge.language,
              entityId: knowledge.id,
              entityName: knowledge.title,
              entityType: 'Knowledge',
              name: 'Open Translation Link',
            }),
          ),
        translationLinkLabel: interpolate(textDictionary['app.view_article.translated'], {
          language: () => siteLanguageLabel,
        }),
        translationUrl: `https://translate.google.com/translate?sl=auto&tl=${siteLanguage}&u=${encodeURIComponent(knowledge.link)}`,
      };
};
