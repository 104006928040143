import { useEffect } from 'react';
import { isIOS } from 'react-device-detect';
import { styled } from 'styled-components';

import { Footer } from 'domains/about/components/Footer';

const StyledMobileAppPage = styled(Footer)`
  height: 100%;
`;

export const DownloadMobileApp = () => {
  useEffect(() => {
    if (isIOS) {
      window.location.replace('https://apps.apple.com/gb/app/strategic-iq/id1454211238');
    }
  }, []);

  return <StyledMobileAppPage />;
};
