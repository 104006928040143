import { useEffect, useState } from 'react';

/**
 * A hook for manipulating localStorage as if it were useState
 *
 * @param key The string key to store the value under
 * @param defaultValue The value to return if there is no stored value
 */
export const useLocalStorageState = (
  key: string,
  defaultValue: string | null,
): [string | null, (newValue: string | null) => void] => {
  const [value, setValue] = useState(() => localStorage.getItem(key) || defaultValue);

  useEffect(() => {
    if (value !== null) {
      localStorage.setItem(key, value);
    } else {
      localStorage.removeItem(key);
    }
  }, [key, value]);

  return [value, setValue];
};
