import { SVGProps } from 'react';

export const LinkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" {...props}>
    <g transform="translate(-1204 -986)">
      <path
        d="M75.565,14.56a1.32,1.32,0,0,1-.937-.4l-2.643-2.614a6.747,6.747,0,0,1,9.534-9.551l2.643,2.647a1.349,1.349,0,1,1-1.907,1.91L79.612,3.9A4.076,4.076,0,0,0,76.735,2.7,4.018,4.018,0,0,0,73.859,3.9a4.051,4.051,0,0,0,0,5.764L76.5,12.315a1.337,1.337,0,0,1,0,1.91,1.52,1.52,0,0,1-.937.335Zm10.771,8.546a6.813,6.813,0,0,1-4.783-1.977L78.91,18.481a1.349,1.349,0,0,1,1.907-1.91l2.643,2.648a4.072,4.072,0,0,0,5.754-5.764L86.57,10.807A1.349,1.349,0,1,1,88.477,8.9l2.643,2.647a6.786,6.786,0,0,1-4.784,11.562Zm-3.579-8.479a1.32,1.32,0,0,0,.937.4,1.456,1.456,0,0,0,.97-.369,1.337,1.337,0,0,0,0-1.91L80.348,8.427a1.349,1.349,0,0,0-1.907,1.91Z"
        transform="translate(1141.996 993.992)"
        fill="currentColor"
        fillRule="evenodd"
      />
    </g>{' '}
  </svg>
);
