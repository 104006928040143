import { memo, ReactElement } from 'react';
import { styled } from 'styled-components';

import { ChevronIcon, HighlightedText, Image } from 'common/components';
import { breakWordStyle, createScrollbarStyle, lineTruncationStyle } from 'common/styles';
import { ImageCollection } from 'common/types/imageCollection';

type StyledProps = { isExpanded: boolean };

const StyledDescription = styled.p<StyledProps>`
  margin: 10px 0 0;
  max-height: ${({ isExpanded }) => (isExpanded ? '1000px' : '2.5rem')};
  min-height: ${({ isExpanded }) => (isExpanded ? '98px' : '2.5rem')};
  font-size: 0.9rem;
  overflow-y: ${({ isExpanded }) => (isExpanded ? 'auto' : 'hidden')};
  transition:
    max-height 0.3s ease,
    min-height 0.3s ease;
  white-space: pre-line;
  line-height: 1.2rem;
  color: ${({ theme }) => theme.colors.textFade};

  ${({ isExpanded }) => !isExpanded && lineTruncationStyle(2)};
  ${createScrollbarStyle()};
`;

const StyledRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  & + ${StyledDescription} {
    margin-top: 1rem;
  }
`;

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 1rem;
`;

const StyledImage = styled(Image)`
  border-radius: 4px;
  width: 64px;
  height: 64px;
`;

const Title = styled.span`
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.textBright};
  ${lineTruncationStyle(2)}
  ${breakWordStyle}
`;

const Subtitle = styled.span`
  min-height: 18px;
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.textFade};
  font-weight: bold;
  ${lineTruncationStyle(1)}
`;

const StyledTitleTextContainer = styled.div``;

const StyledContentContainer = styled.div`
  margin-right: 0.5rem;
`;

const StyledButtonsContainer = styled.div`
  margin: 0 -16px -16px -16px;
  height: 60px;
`;

const StyledChevron = styled(ChevronIcon)<{ isExpanded: boolean }>`
  margin-top: 32px;
  width: 14px;
  height: 14px;
  align-self: center;
  transform: ${({ isExpanded }) => `rotate(${isExpanded ? 270 : 90}deg)`};
  transition: transform 0.3s ease;
  flex-shrink: 0;
`;

export type EditListItemProps = {
  actionElements?: ReactElement | null;
  actionElementsPosition?: 'inline' | 'below';
  description?: string;
  highlightedTerm?: string;
  icon?: ReactElement;
  images?: ImageCollection;
  isDescriptionExpanded?: boolean;
  name: string;
  rightElement?: ReactElement | null;
  subtitle?: string;
};

export const EditListItem = memo(
  ({
    actionElements = null,
    actionElementsPosition = 'inline',
    description,
    highlightedTerm,
    icon,
    images,
    isDescriptionExpanded = false,
    name,
    rightElement = null,
    subtitle,
  }: EditListItemProps) => (
    <>
      <StyledRowContainer>
        <StyledContentContainer>
          <StyledTitleContainer>
            {icon ?? <StyledImage src={images?.x_small} alt={name} />}
            <StyledTitleTextContainer>
              <Title>
                <HighlightedText text={name} highlightedTerm={highlightedTerm} />
              </Title>
              {subtitle && (
                <Subtitle>
                  <HighlightedText text={subtitle} highlightedTerm={highlightedTerm} />
                </Subtitle>
              )}
            </StyledTitleTextContainer>
          </StyledTitleContainer>
          {description && (
            <StyledDescription isExpanded={isDescriptionExpanded}>
              <HighlightedText text={description} highlightedTerm={highlightedTerm} />
            </StyledDescription>
          )}
        </StyledContentContainer>
        <div style={{ alignSelf: 'center', justifySelf: 'end' }}>{rightElement}</div>

        {description && <StyledChevron isExpanded={isDescriptionExpanded} />}
        {actionElements && actionElementsPosition === 'inline' ? (
          <StyledButtonsContainer>{actionElements}</StyledButtonsContainer>
        ) : null}
      </StyledRowContainer>
      {actionElements && actionElementsPosition === 'below' ? (
        <StyledButtonsContainer>{actionElements}</StyledButtonsContainer>
      ) : null}
    </>
  ),
);

EditListItem.displayName = 'EditListItem';
