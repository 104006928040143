import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { applyLatestSearchCriteria, clearLatestSearchCriteria } from 'domains/knowledgeFeed/actions';
import { TopicLatestApplySearchCriteriaPayload } from 'domains/knowledgeFeed/actionTypes';
import { getLatestSearchCriteria } from 'domains/knowledgeFeed/selectors';
import { TopicFilterModal } from 'domains/topicSearchFilter/components/TopicFilterModal';
import { getTopicFilters } from 'domains/topicSearchFilter/selectors';

export const TopicLatestFilterModal = () => {
  const dispatch = useDispatch();
  const searchCriteria = useSelector(getLatestSearchCriteria);
  const filters = useSelector(getTopicFilters('latest'));

  useEffect(() => {
    dispatch(applyLatestSearchCriteria());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleApplyFilter = (payload: TopicLatestApplySearchCriteriaPayload) => () =>
    dispatch(applyLatestSearchCriteria(payload));

  const handleClear = () => dispatch(clearLatestSearchCriteria());

  return (
    <TopicFilterModal
      fields={filters}
      searchCriteria={searchCriteria as any}
      handleApplyFilter={handleApplyFilter}
      handleClear={handleClear}
      dataTestId={'latest-search-filters-sidebar'}
    />
  );
};
