import { SVGProps } from 'react';

export const DocFileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
    <g id="Group_95326" data-name="Group 95326" transform="translate(12209 18488)">
      <g id="Rectangle_41725" data-name="Rectangle 41725" transform="translate(-12209 -18488)" fill="#2a63e9">
        <rect width="64" height="64" stroke="none" />
      </g>
      <g id="Group_95321" data-name="Group 95321" transform="translate(-12197.943 -18477)">
        <path
          id="Path_245113"
          data-name="Path 245113"
          d="M177.873,27.283,165.8,15.213A4.1,4.1,0,0,0,162.937,14h-21.66a4.141,4.141,0,0,0-4.079,4.079v34.36a4.069,4.069,0,0,0,4.079,4.079h33.731a4.069,4.069,0,0,0,4.079-4.079V30.149a4.1,4.1,0,0,0-1.213-2.866Zm-2.315.992H166.63a1.835,1.835,0,0,1-1.819-1.819V17.582Zm-.551,25.873H141.277a1.717,1.717,0,0,1-1.708-1.708V18.078a1.694,1.694,0,0,1,1.708-1.708h21.164V26.456a4.187,4.187,0,0,0,4.189,4.189h10.086V52.439a1.717,1.717,0,0,1-1.708,1.708Z"
          transform="translate(-137.198 -14)"
          fill="#fff"
        />
        <path
          id="Path_246199"
          data-name="Path 246199"
          d="M245.268,431.2H228.307a1.25,1.25,0,0,0,0,2.425h17a1.115,1.115,0,0,0,.947-1.213c-.043-.661-.474-1.213-.99-1.213Z"
          transform="translate(-222.974 -395.393)"
          fill="#fff"
        />
        <path
          id="Path_246200"
          data-name="Path 246200"
          d="M245.268,340.48H228.307a1.25,1.25,0,0,0,0,2.425h17a1.115,1.115,0,0,0,.947-1.213,1.146,1.146,0,0,0-.99-1.213Z"
          transform="translate(-222.974 -309.665)"
          fill="#fff"
        />
        <path
          id="Path_246201"
          data-name="Path 246201"
          d="M245.268,249.76H228.307a1.25,1.25,0,0,0,0,2.425h17a1.115,1.115,0,0,0,.947-1.213,1.146,1.146,0,0,0-.99-1.213Z"
          transform="translate(-222.974 -223.936)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);
