import { styled } from 'styled-components';

import { Image } from 'common/components';
import { breakpoints } from 'common/styles';

const getPosition = (isLoaded = false, isLeft = false) => {
  if (isLoaded) {
    return 0;
  }
  return isLeft ? -200 : 200;
};

export const StyledSectionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  max-width: 1600px;
  gap: 1rem;
  padding: 0 2rem;
  margin-bottom: 3rem;

  @media ${breakpoints.medium} {
    gap: 3rem;
    padding: 0 4rem;
    margin: 0 auto;
    flex-direction: row;
  }
`;

export const StyledInfoContainer = styled.div<{ isLoaded?: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`;

export const StyledImage = styled(Image)`
  width: 100%;
  height: 400px;
  object-fit: contain;

  @media ${breakpoints.medium} {
    height: 476px;
  }
`;

export const SlidingContainer = styled.div<{
  isLoaded?: boolean;
  isLeft?: boolean;
}>`
  width: 100%;
  transition:
    opacity 0.3s ease-in-out 0.5s,
    transform 0.3s ease-in-out 0.5s;
  opacity: ${({ isLoaded }) => (isLoaded ? 1 : 0)};
  transform: ${({ isLoaded, isLeft }) => `translate3d(${getPosition(isLoaded, isLeft)}px, 0, 0)`};
`;
