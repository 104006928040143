import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { searchVideosRejected, searchVideosSuccess } from 'domains/search/actions';
import { FetchVideosSearchDefaultAction } from 'domains/search/actionTypes';
import { Knowledge } from 'entities/knowledge/types';

export function* fetchVideosSearchSaga({ meta }: FetchVideosSearchDefaultAction) {
  if (!meta.query) {
    return;
  }
  try {
    const { query, limit, offset } = meta;
    const response: Knowledge[] = yield apiFetch(`media`, {
      queryParams: {
        content_type: 'Videos',
        search: query,
        limit,
        offset,
      },
    });
    yield put(searchVideosSuccess(meta, response));
  } catch (error) {
    yield put(searchVideosRejected(meta));
  }
}
