import { DestinationTypes } from 'domains/types';
import { Topic } from 'entities/topics/types';
import { RootState } from 'types';

const topicEntities = (state: RootState) => state.entities.topics;

const getOriginalTopicProperties = (topic: Topic) => ({
  name: topic.name_original || topic.name,
  subtitle: topic.subtitle_original || topic.subtitle,
  description: topic.description_original || topic.description,
});

const getTopicTranslatedProperties = (topic: Topic, state: RootState, parentTopicId?: string) => {
  let isMachineTranslationsOff = topic?.id ? getIsMachineTranslationsOffForTopic(topic.id)(state) : false;
  const parentId = parentTopicId ?? topic?.parent_topic?.id;
  if (parentId) {
    isMachineTranslationsOff = isMachineTranslationsOff || getIsMachineTranslationsOffForTopic(parentId)(state);
  }

  if (isMachineTranslationsOff) {
    if (topic?.is_machine_translated) {
      topic = { ...topic, ...getOriginalTopicProperties(topic) };
    }
    if (topic?.dimensions) {
      const dimensions = (topic.dimensions || []).map((issue) =>
        getTopicTranslatedProperties(issue, state, topic?.id),
      ) as Topic[];
      topic = { ...topic, dimensions };
    }
  }
  return topic;
};

export const getTopic = (id?: string) => (state: RootState) =>
  id ? getTopicTranslatedProperties(state.entities.topics.byId[id], state) : undefined;

export const getTopicById = (state: RootState, id: string, parentTopicId?: string) =>
  getTopicTranslatedProperties(state.entities.topics.byId[id], state, parentTopicId);

export const getTopicsByIds =
  (ids: string[]) =>
  (state: RootState): Topic[] =>
    ids.reduce<Topic[]>((accumulator, next) => {
      const topic = getTopic(next)(state);
      if (topic !== undefined) accumulator.push(topic);
      return accumulator;
    }, []);

export const getAllTopicsCount = (state: RootState) => state.entities.topicsMetadata.allTopicsCount;

export const getHighlightedTopics = (state: RootState) =>
  topicEntities(state).highlightedIds.map((id) => getTopicById(state, id));

export const getCustomTopicsCreatedByUser = (limit?: number) => (state: RootState) => {
  const topics = state.entities.topics.myMaps.ids
    .map((id) => getTopicById(state, id))
    .filter((topic) => topic !== undefined);
  return limit ? topics.slice(0, limit) : topics;
};

export const getCustomTopicsSharedWithUser = (limit?: number) => (state: RootState) => {
  const topics = state.entities.topics.sharedWithMe.ids
    .map((id) => getTopicById(state, id))
    .filter((topic) => topic !== undefined);
  return limit ? topics.slice(0, limit) : topics;
  // const userId = getUserId(state);
  // return getTopicsOfType(
  //   DestinationTypes.CUSTOM,
  //   (topic) => topic?.provider?.id !== userId,
  // )(state);
};

export const getSharedWithMeTopicsIsFetching = (state: RootState) => state.entities.topics.sharedWithMe.isPending;

export const getSharedWithMeTopicsHasNextPage = (state: RootState) =>
  state.entities.topics.sharedWithMe.hasNextPage || false;

export const getMyMapsTopicsIsPending = (state: RootState) => state.entities.topics.myMaps.isPending || false;

export const getMyMapsTopicsHasNextPage = (state: RootState) => state.entities.topics.myMaps.hasNextPage || false;

export const getCustomTopicsIsPending = (state: RootState) => getIsFetching(state).byType[DestinationTypes.CUSTOM];

const getIsFetching = (state: RootState) => topicEntities(state).isFetching;

export const getFollowedTopicsIds = (state: RootState) => state.entities.topics.following.ids || [];

export const getNFollowedTopics = (n: number) => (state: RootState) => getFollowedTopics(state).slice(0, n);

export const getFollowedTopics = (state: RootState) => getFollowedTopicsIds(state).map((id) => getTopicById(state, id));

export const getFollowedTopicsIsPending = (state: RootState) => state.entities.topics.following.isPending;

export const getFollowedTopicsHasPossibleNextPage = (state: RootState) =>
  state.entities.topics.following.hasNextPage || false;

export const getFollowedTopicsLimit = (state: RootState) => state.entities.topics.following.limit;

export const getFollowedTopicsOffset = (state: RootState) => state.entities.topics.following.offset;

export const getFollowingTopicsDependency = (state: RootState) => getFollowedTopicsIds(state).join();

export const getTopicIsFollowed = (id: string) => (state: RootState) => {
  const topic = getTopicById(state, id);
  return topic && topic.is_following;
};

export const getTopicIsFollowedUpdating = (id: string) => (state: RootState) => {
  const topic = getTopicById(state, id);
  return (topic && topic.isFollowingUpdating) || false;
};

export const getIsMachineTranslationsOffForTopic = (id?: string) => (state: RootState) =>
  id ? state.entities.topics.machineTranslationsOffIds.includes(id) : false;

export const getTopics = (topicIds: string[], limit: number) => (state: RootState) =>
  topicIds
    .map((topicId) => getTopicById(state, topicId))
    .filter((topic) => topic !== undefined)
    .slice(0, limit);
