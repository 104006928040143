import { combineReducers } from 'redux';
import { takeEvery, takeLatest, throttle } from 'redux-saga/effects';

import { LanguageDomainActionType } from 'domains/language/actionTypes';
import { CoachmarkEntityActionType } from 'entities/coachmarks/actionTypes';
import { coachmarksReducer } from 'entities/coachmarks/reducer';
import { fetchCoachmarksSaga } from 'entities/coachmarks/sagas/fetchCoachmarksSaga';
import { EventType } from 'entities/events/actionTypes';
import { eventsReducer } from 'entities/events/reducer';
import { fetchEventsByIdSaga } from 'entities/events/sagas/fetchEventByIdSaga';
import { fetchEventsForTopicSaga } from 'entities/events/sagas/fetchEventsForTopicSaga';
import { InitiativesType } from 'entities/initiatives/actionTypes';
import { initiativesReducer } from 'entities/initiatives/reducer';
import { fetchInitiativeByIdSaga } from 'entities/initiatives/sagas/fetchInitiativeByIdSaga';
import { fetchInitiativesForTopicSaga } from 'entities/initiatives/sagas/fetchInitiativesForTopicSaga';
import { KeyIssuesActionType } from 'entities/keyIssues/actionTypes';
import { keyIssuesReducer } from 'entities/keyIssues/reducer';
import { fetchKeyIssuesOnSearch } from 'entities/keyIssues/sagas/fetchKeyIssuesOnSearchSaga';
import { fetchDimensionsSaga } from 'entities/keyIssues/sagas/fetchKeyIssuesSaga';
import { KnowledgeActionTypes } from 'entities/knowledge/actionTypes';
import { knowledgeReducer } from 'entities/knowledge/reducers';
import { knowledgeItemSaga } from 'entities/knowledge/sagas/fetchKnowledgeByIdSaga';
import { KnowledgeDataActionTypes } from 'entities/knowledgeData/actionTypes';
import { fetchKnowledgeDataForTopicSaga } from 'entities/knowledgeData/sagas/fetchKnowledgeDataForTopicSaga';
import { KnowledgeFeedActionTypes } from 'entities/knowledgeFeed/actionTypes';
import { fetchKnowledgeFeedForTopicSaga } from 'entities/knowledgeFeed/sagas/fetchKnowledgeFeedForTopicSaga';
import { LanguageActionTypes } from 'entities/languages/actionTypes';
import { languagesReducer } from 'entities/languages/reducer';
import { fetchLanguagesSaga } from 'entities/languages/sagas/fetchLanguagesSaga';
import { PermissionsType } from 'entities/permissions/actionTypes';
import { permissionsReducer } from 'entities/permissions/reducer';
import { fetchPermissionsSaga } from 'entities/permissions/sagas/fetchPermissionsSaga';
import { PublicationActionTypes } from 'entities/publication/actionTypes';
import { fetchPublicationForTopicSaga } from 'entities/publication/sagas/fetchPublicationForTopicSaga';
import { StakeholderActionTypes } from 'entities/stakeholders/actionTypes';
import { stakeholdersReducer } from 'entities/stakeholders/reducer';
import { fetchStakeholderByIdSaga } from 'entities/stakeholders/sagas/fetchStakeholderByIdSaga';
import { fetchStakeholdersForTopicSaga } from 'entities/stakeholders/sagas/fetchStakeholdersForTopicSaga';
import { TopicActionType } from 'entities/topics/actionTypes';
import { topicsReducer } from 'entities/topics/reducer';
import { fetchFollowedTopicsSaga } from 'entities/topics/sagas/fetchFollowedTopicsSaga';
import { fetchHighlightedTopicsSaga } from 'entities/topics/sagas/fetchHighlightedTopicsSaga';
import { fetchTopicSaga } from 'entities/topics/sagas/fetchTopicSaga';
import { fetchTopicsForCollectionSaga } from 'entities/topics/sagas/fetchTopicsForCollectionSaga';
import { fetchTopicsOfTypeSaga } from 'entities/topics/sagas/fetchTopicsOfTypeSaga';
import { followTopicSaga } from 'entities/topics/sagas/followTopicSaga';
import { removeMapSaga } from 'entities/topics/sagas/removeMapSaga';
import { unfollowTopicSaga } from 'entities/topics/sagas/unfollowTopicSaga';
import { TopicsMetadataActionTypes } from 'entities/topicsMetadata/actionTypes';
import { topicsMetadataReducer } from 'entities/topicsMetadata/reducer';
import { fetchTopicsMetadataSaga } from 'entities/topicsMetadata/sagas/fetchTopicsMetadataSaga';
import { UserProfileType } from 'entities/user/actionTypes';
import { userReducer } from 'entities/user/reducers';
import { fetchProfileSaga } from 'entities/user/sagas/fetchProfileSaga';
import { updateProfileSaga } from 'entities/user/sagas/updateProfileSaga';
import { VideoActionTypes } from 'entities/videos/actionTypes';
import { fetchVideosForTopicSaga } from 'entities/videos/sagas/fetchVideosForTopicSaga';

export const entitySagaSpawners = [
  takeLatest(CoachmarkEntityActionType.FETCH, fetchCoachmarksSaga),
  takeEvery(PublicationActionTypes.FETCH_FOR_TOPIC, fetchPublicationForTopicSaga),
  takeEvery(KnowledgeFeedActionTypes.FETCH_FOR_TOPIC, fetchKnowledgeFeedForTopicSaga),
  takeEvery(KnowledgeActionTypes.FETCH_FOR_ID, knowledgeItemSaga),
  takeEvery(InitiativesType.FETCH_FOR_ID, fetchInitiativeByIdSaga),
  takeEvery(InitiativesType.FETCH_FOR_TOPIC, fetchInitiativesForTopicSaga),
  takeEvery(EventType.FETCH_FOR_ID, fetchEventsByIdSaga),
  takeEvery(EventType.FETCH_FOR_TOPIC, fetchEventsForTopicSaga),
  takeEvery(StakeholderActionTypes.FETCH_FOR_TOPIC, fetchStakeholdersForTopicSaga),
  takeEvery(StakeholderActionTypes.FETCH_FOR_ID, fetchStakeholderByIdSaga),
  takeEvery(KeyIssuesActionType.FETCH, fetchDimensionsSaga),
  throttle(400, KeyIssuesActionType.UPDATE_SEARCH_TERM, fetchKeyIssuesOnSearch),
  takeEvery(KnowledgeDataActionTypes.FETCH_FOR_TOPIC, fetchKnowledgeDataForTopicSaga),
  takeEvery(VideoActionTypes.FETCH_FOR_TOPIC, fetchVideosForTopicSaga),
  takeLatest(LanguageActionTypes.FETCH_ALL, fetchLanguagesSaga),
  takeLatest(TopicActionType.FETCH_HIGHLIGHTS, fetchHighlightedTopicsSaga),
  takeEvery(TopicActionType.FETCH_FOLLOWED_LIST, fetchFollowedTopicsSaga),
  takeEvery(
    [TopicActionType.FETCH_OF_TYPE, TopicActionType.FETCH_SHARED_WITH_ME, TopicActionType.FETCH_MY_MAPS],
    fetchTopicsOfTypeSaga,
  ),
  takeEvery(TopicActionType.FETCH_TOPIC, fetchTopicSaga),
  takeEvery(TopicActionType.FOLLOW, followTopicSaga),
  takeEvery(TopicActionType.UNFOLLOW, unfollowTopicSaga),
  takeEvery(TopicActionType.REMOVE_MAP, removeMapSaga),
  takeEvery(TopicActionType.FETCH_TOPICS_FOR_COLLECTION, fetchTopicsForCollectionSaga),
  takeLatest(TopicsMetadataActionTypes.FETCH, fetchTopicsMetadataSaga),
  takeLatest([LanguageDomainActionType.FETCH, UserProfileType.FETCH], fetchProfileSaga),
  takeLatest(UserProfileType.UPDATE_PROFILE, updateProfileSaga),
  takeLatest(PermissionsType.FETCH, fetchPermissionsSaga),
];

export const entitiesReducer = combineReducers({
  coachmarks: coachmarksReducer,
  events: eventsReducer,
  initiatives: initiativesReducer,
  keyIssues: keyIssuesReducer,
  knowledge: knowledgeReducer,
  languages: languagesReducer,
  permissions: permissionsReducer,
  stakeholders: stakeholdersReducer,
  topics: topicsReducer,
  topicsMetadata: topicsMetadataReducer,
  user: userReducer,
});
