import { useLocation } from 'react-router-dom';

export const useQueryParams = () => {
  const { search } = useLocation();

  const parsedParams = new URLSearchParams(search);
  const result: Record<string, string> = {};
  parsedParams.forEach((value, key) => {
    result[key] = value;
  });
  return result;
};
