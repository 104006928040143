import { MouseEvent, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from 'styled-components';

import {
  Image,
  SectionTitleUnderlined,
  UIActionButton,
  UIButtonsRow,
  JoinUsUpgradeBanner,
  MasterDetailContentWrapper,
} from 'common/components';
import { toggleBookmark } from 'domains/bookmarks/actions';
import { fetchClusterById } from 'domains/clusters/actions';
import { ClusterCaption } from 'domains/clusters/components/ClusterCaption';
import { ClusterSources } from 'domains/clusters/components/ClusterSources';
import { DigDeeperList } from 'domains/clusters/components/DigDeeperList';
import { Cluster } from 'domains/clusters/types';
import { getSiteLanguage } from 'domains/language/selectors';
import { useTranslations } from 'domains/language/useTranslations';
import { shareLinkAction } from 'domains/share/actions';
import { TopicSlider } from 'domains/topic/components/TopicSlider';
import { getCanAccessMonitor, getCanDisplayBookmarks, getUserId } from 'entities/user/selectors';

const StyledImage = styled(Image)`
  width: 100%;
  height: 250px;
  border-radius: 5px;
  margin-bottom: 1rem;
  box-shadow: ${({ theme }) => theme.shadows.box};
`;

const StyledTitle = styled.h1`
  font-size: 2rem;
  margin: 0;
`;

const StyledSummary = styled.p`
  margin-top: 1rem;
  font-size: 1.1rem;
  line-height: 1.75rem;
  white-space: pre-line;
`;

const StyledDigDeeperList = styled.div`
  flex: 1;
`;

const StyledButtons = styled(UIButtonsRow)`
  margin: 1rem 0;
`;

const StyledDigDeeperSection = styled(MasterDetailContentWrapper)`
  margin-top: 2rem;
`;

type ClusterDetailProps = { cluster?: Cluster; id?: string };

export const ClusterDetail = ({ cluster, id }: ClusterDetailProps) => {
  const dispatch = useDispatch();
  const hasAccessToBookmarks = useSelector(getCanDisplayBookmarks);
  const hasAccessToMonitor = useSelector(getCanAccessMonitor);
  const language = useSelector(getSiteLanguage);
  const userId = useSelector(getUserId);
  const { textDictionary } = useTranslations();

  useEffect(() => {
    if (id) {
      dispatch(fetchClusterById(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, userId, language]);

  if (!cluster) {
    return null;
  }

  const {
    title,
    description,
    time,
    knowledge_count,
    images,
    sources_count,
    days_count,
    sources,
    related_topics,
    is_bookmarked,
    isBookmarkPending,
  } = cluster;

  const onBookmarkHandle = (event?: MouseEvent) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    dispatch(
      toggleBookmark({
        bookmark: cluster,
        isBookmarked: !is_bookmarked,
        entityType: 'Cluster',
        trackPage: 'Cluster detail',
      }),
    );
  };

  const shareLink = id ? `${process.env.REACT_APP_WEBSITE_URL}/monitor/emerging-themes/${id}` : undefined;

  const onShareClick = () =>
    dispatch(
      shareLinkAction({
        id: id || '',
        name: title,
        link: shareLink || '',
        type: 'Cluster',
      }),
    );

  return (
    <>
      <MasterDetailContentWrapper>
        <StyledImage src={images?.large} alt={title} />
        <ClusterCaption time={time} knowledgeCount={knowledge_count} />
        <StyledTitle>{title}</StyledTitle>
        <StyledSummary>{description}</StyledSummary>
        <ClusterSources sourcesCount={sources_count} daysCount={days_count} sources={sources} />
        <StyledButtons>
          {id && shareLink && <UIActionButton type="share" onClick={onShareClick} />}
          {hasAccessToBookmarks && (
            <UIActionButton
              type="bookmark"
              isActive={is_bookmarked}
              isLoading={isBookmarkPending}
              onClick={onBookmarkHandle}
            />
          )}
        </StyledButtons>
      </MasterDetailContentWrapper>
      {hasAccessToMonitor ? (
        <>
          <MasterDetailContentWrapper>
            <SectionTitleUnderlined>{textDictionary['app.topic.related_topics']}</SectionTitleUnderlined>
            <TopicSlider
              topics={related_topics}
              hideFollowAction={true}
              numberOfItems={{
                small: 1,
                medium: 3,
                large: 2,
                extraLarge: 3,
                enormous: 4,
              }}
            />
          </MasterDetailContentWrapper>
          <StyledDigDeeperSection>
            <SectionTitleUnderlined>{textDictionary['app.cluster.dig_deeper']}</SectionTitleUnderlined>
            <StyledDigDeeperList>
              <DigDeeperList clusterId={cluster.id} />
            </StyledDigDeeperList>
          </StyledDigDeeperSection>
        </>
      ) : (
        <MasterDetailContentWrapper>
          <JoinUsUpgradeBanner trackPage="Cluster detail" />
        </MasterDetailContentWrapper>
      )}
    </>
  );
};
