import { SVGProps } from 'react';

export const FileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
    <g id="Group_95332" data-name="Group 95332" transform="translate(11988 18488)">
      <g id="Rectangle_41727" data-name="Rectangle 41727" transform="translate(-11988 -18488)" fill="#2a63e9">
        <rect width="64" height="64" stroke="none" />
      </g>
      <path
        id="Path_245113"
        data-name="Path 245113"
        d="M177.873,27.283,165.8,15.213A4.1,4.1,0,0,0,162.937,14h-21.66a4.141,4.141,0,0,0-4.079,4.079v34.36a4.069,4.069,0,0,0,4.079,4.079h33.731a4.069,4.069,0,0,0,4.079-4.079V30.149a4.1,4.1,0,0,0-1.213-2.866Zm-2.315.992H166.63a1.835,1.835,0,0,1-1.819-1.819V17.582Zm-.551,25.873H141.277a1.717,1.717,0,0,1-1.708-1.708V18.078a1.694,1.694,0,0,1,1.708-1.708h21.164V26.456a4.187,4.187,0,0,0,4.189,4.189h10.086V52.439a1.717,1.717,0,0,1-1.708,1.708Z"
        transform="translate(-12114.142 -18491)"
        fill="#fff"
      />
      <path
        id="Path_246202"
        data-name="Path 246202"
        d="M11.548,0H.584C.265,0,0,.551,0,1.213S.265,2.425.584,2.425H11.574c.318,0,.584-.551.584-1.213S11.866,0,11.548,0Z"
        transform="translate(-11969.133 -18457.963) rotate(90)"
        fill="#fff"
      />
      <path
        id="Path_246211"
        data-name="Path 246211"
        d="M11.548,0H.584C.265,0,0,.551,0,1.213S.265,2.425.584,2.425H11.574c.318,0,.584-.551.584-1.213S11.866,0,11.548,0Z"
        transform="translate(-11950.133 -18457.963) rotate(90)"
        fill="#fff"
      />
      <path
        id="Path_246210"
        data-name="Path 246210"
        d="M22.625,0H.947A1.115,1.115,0,0,0,0,1.213,1.115,1.115,0,0,0,.947,2.425H22.668a1.115,1.115,0,0,0,.947-1.213A1.146,1.146,0,0,0,22.625,0Z"
        transform="translate(-11949.063 -18456.75) rotate(180)"
        fill="#fff"
      />
      <path
        id="Path_246215"
        data-name="Path 246215"
        d="M7.625,0H3.947A1.115,1.115,0,0,0,3,1.213a1.115,1.115,0,0,0,.947,1.213H7.668a1.115,1.115,0,0,0,.947-1.213A1.146,1.146,0,0,0,7.625,0Z"
        transform="translate(-11965.483 -18445.125) rotate(-90)"
        fill="#fff"
      />
      <path
        id="Path_246218"
        data-name="Path 246218"
        d="M7.625,0H3.947A1.115,1.115,0,0,0,3,1.213a1.115,1.115,0,0,0,.947,1.213H7.668a1.115,1.115,0,0,0,.947-1.213A1.146,1.146,0,0,0,7.625,0Z"
        transform="translate(-11962.279 -18437.125) rotate(-90)"
        fill="#fff"
      />
      <path
        id="Path_246219"
        data-name="Path 246219"
        d="M7.625,0H-.053A1.115,1.115,0,0,0-1,1.213,1.115,1.115,0,0,0-.053,2.425H7.668a1.115,1.115,0,0,0,.947-1.213A1.146,1.146,0,0,0,7.625,0Z"
        transform="translate(-11964.875 -18441.338)"
        fill="#fff"
      />
      <path
        id="Path_246216"
        data-name="Path 246216"
        d="M8.625,0H3.947A1.115,1.115,0,0,0,3,1.213a1.115,1.115,0,0,0,.947,1.213H8.668a1.115,1.115,0,0,0,.947-1.213A1.146,1.146,0,0,0,8.625,0Z"
        transform="translate(-11962.279 -18445.125) rotate(-90)"
        fill="#fff"
      />
      <path
        id="Path_246217"
        data-name="Path 246217"
        d="M9.625,0H3.947A1.115,1.115,0,0,0,3,1.213a1.115,1.115,0,0,0,.947,1.213H9.668a1.115,1.115,0,0,0,.947-1.213A1.146,1.146,0,0,0,9.625,0Z"
        transform="translate(-11959.075 -18445.125) rotate(-90)"
        fill="#fff"
      />
      <path
        id="Path_246212"
        data-name="Path 246212"
        d="M22.625,0H.947A1.115,1.115,0,0,0,0,1.213,1.115,1.115,0,0,0,.947,2.425H22.668a1.115,1.115,0,0,0,.947-1.213A1.146,1.146,0,0,0,22.625,0Z"
        transform="translate(-11949.063 -18444.592) rotate(180)"
        fill="#fff"
      />
    </g>
  </svg>
);
