import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import {
  fetchNotifications,
  markAllNotificationDoneFailure,
  markAllNotificationDoneSuccess,
} from 'domains/notifications/actions';
import { isFetchError } from 'TypeCheckMethods';

export function* markAllDoneSaga() {
  try {
    yield apiFetch('notification', {
      queryParams: { mark: 'all' },
      method: 'PUT',
    });
    yield put(markAllNotificationDoneSuccess());
    yield put(fetchNotifications({ offset: 0 }));
  } catch (error) {
    if (isFetchError(error)) {
      yield put(markAllNotificationDoneFailure({ error }, { logoutOnAuthError: true }));
    }
  }
}
