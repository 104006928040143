import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { buildFingerprintFulfilled, buildFingerprintRejected, fetchFPKnowledge } from 'domains/customKeyIssue/actions';
import { CustomKeyIssueFingerprintBuilderAction } from 'domains/customKeyIssue/actionTypes';
import { FPBuilderResponse } from 'domains/customKeyIssue/types';

export function* fetchFingerprintBuilderSaga({ payload }: CustomKeyIssueFingerprintBuilderAction) {
  try {
    if (!payload.mappings.length) {
      yield put(buildFingerprintFulfilled([], payload));
      yield put(fetchFPKnowledge({ fp: [] }));
      return;
    }
    const response: FPBuilderResponse = yield apiFetch(`topic/fp_builder`, {
      body: payload,
      method: 'POST',
    });
    yield put(buildFingerprintFulfilled(response.fp, payload));
    yield put(fetchFPKnowledge({ fp: response.fp }));
  } catch (error) {
    yield put(buildFingerprintRejected(payload));
  }
}
