import { memo } from 'react';
import { styled } from 'styled-components';

import { ListLink } from 'common/components/lists/ListLink';
import { useInternalLink } from 'common/utilities';
import { CustomKnowledge } from 'domains/customContentAndSources/types';
import { BrowseInnerContent, StyledWrapper } from 'domains/knowledgeData/components/SharedComponents';
import { KnowledgeDetailButton } from 'domains/publication/components/KnowledgeDetailButton';
import { Knowledge } from 'entities/knowledge/types';

const StyledLink = styled(ListLink)`
  text-decoration: none;
  display: flex;
`;

type KnowledgeDataNavigationItemBrowseViewProps = {
  className?: string;
  isSelected?: boolean;
  knowledge: Knowledge | CustomKnowledge;
  link: string;
  showCaret?: boolean;
};

export const KnowledgeDataNavigationItemBrowseView = memo(
  ({
    className,
    isSelected = false,
    knowledge,
    link,
    showCaret = false,
  }: KnowledgeDataNavigationItemBrowseViewProps) => {
    const linkLocation = useInternalLink(link);

    return (
      <StyledWrapper>
        <StyledLink className={className} isSelected={isSelected} to={linkLocation}>
          <BrowseInnerContent knowledge={knowledge} />
        </StyledLink>
        {showCaret && <KnowledgeDetailButton link={linkLocation} />}
      </StyledWrapper>
    );
  },
);

KnowledgeDataNavigationItemBrowseView.displayName = 'KnowledgeDataNavigationItemBrowseView';
