import { LanguageCode } from 'common/types/languageCode';
import { Translation } from 'domains/collection/types/translation';

type DisplayFields = { description: string; name: string };

export const getDisplayFields = (
  collection: { description: Translation; id: string; name: Translation } & (
    | { default_language: LanguageCode }
    | { defaultLanguage: LanguageCode }
  ),
  siteLanguage: LanguageCode,
): DisplayFields => {
  const { description, id, name } = collection;
  const defaultLanguage = 'default_language' in collection ? collection.default_language : collection.defaultLanguage;
  const defaultDescription = description[defaultLanguage];
  const defaultName = name[defaultLanguage];
  if (defaultDescription === undefined)
    throw new Error(`Collection '${id}' has no description in its default language '${defaultLanguage}'.`);
  if (defaultName === undefined)
    throw new Error(`Collection '${id}' has no name in its default language '${defaultLanguage}'.`);
  return {
    description: (description[siteLanguage] ?? defaultDescription).text,
    name: (name[siteLanguage] ?? defaultName).text,
  };
};
