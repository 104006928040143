import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { fetchTopicContentMetaFailure, fetchTopicContentMetaSuccess } from 'domains/topicSearchFilter/actions';
import { TopicFetchContentMetaSuccessAction } from 'domains/topicSearchFilter/actionTypes';
import { FetchError } from 'types';

export function* fetchTopicFiltersSaga() {
  try {
    const payload: TopicFetchContentMetaSuccessAction['payload'] = yield apiFetch('topic/content_meta');
    yield put(fetchTopicContentMetaSuccess(payload));
  } catch (error) {
    const fetchError = error as FetchError;
    yield put(fetchTopicContentMetaFailure({ error: fetchError }, { logoutOnAuthError: true }));
  }
}
