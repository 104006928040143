import { useRef, useState, ReactElement, useCallback, useEffect } from 'react';
import { styled } from 'styled-components';

import { CarouselArrows } from 'common/components/CarouselArrows';

type StyledProps = { count: number; isLoaded: boolean; itemWidth: number };

const StyledOuterContainer = styled.div`
  position: relative;
  width: 100%;
`;

const StyledCarouselArrows = styled(CarouselArrows)<StyledProps>`
  display: flex;
  opacity: ${({ isLoaded }) => (isLoaded ? 1 : 0)};
`;

const StyledLandingCarousel = styled.div<StyledProps>`
  margin-top: 1rem;
  max-width: 1600px;
  width: 100%;
  height: 100%;
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scroll-padding: 10%;
  scroll-behavior: smooth;
  padding: 10px 0;
  -ms-overflow-style: none; /* Edge */
  scrollbar-width: none; /* Firefox */
  box-sizing: content-box;
  transition: transform 1s ease-in-out 0s;
  transform: ${({ isLoaded }) => `translate3d(${isLoaded ? 0 : 20}%, 0, 0)`};
  align-items: center;

  &::-webkit-scrollbar {
    display: none;
  }
`;

type LandingCarouselProps = StyledProps & { children: ReactElement[] };

export const LandingCarousel = ({ children, ...props }: LandingCarouselProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const { count, itemWidth } = props;

  useEffect(() => {
    if (scrollRef.current) {
      setWidth(scrollRef.current.offsetWidth);
    }
  }, [count]);

  const scrollToPrevious = useCallback(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy(-itemWidth, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, count]);

  const scrollToNext = useCallback(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy(itemWidth, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, count]);

  return (
    <StyledOuterContainer>
      <StyledLandingCarousel {...props} ref={scrollRef}>
        {children}
      </StyledLandingCarousel>
      <StyledCarouselArrows {...props} scrollToPrevious={scrollToPrevious} scrollToNext={scrollToNext} />
    </StyledOuterContainer>
  );
};
