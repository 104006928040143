import { FetchFilteredTopicsActions, FetchFilteredTopicsActionsTypes } from 'domains/filteredTopicList/actionTypes';

export type FilteredTopicsState = {
  hasError: boolean;
  hasPossibleNextPage: boolean;
  ids: string[];
  isFetching: boolean;
  offset: number;
};

const initialState: FilteredTopicsState = {
  ids: [],
  isFetching: false,
  hasError: false,
  offset: 0,
  hasPossibleNextPage: true,
};

export const filteredTopicsReducer = (
  state: FilteredTopicsState = initialState,
  action: FetchFilteredTopicsActions,
) => {
  switch (action.type) {
    case FetchFilteredTopicsActionsTypes.FETCH_FILTERED_TOPICS: {
      return {
        ...state,
        isFetching: true,
        hasError: false,
      };
    }
    case FetchFilteredTopicsActionsTypes.FETCH_FILTERED_TOPICS_FAILURE: {
      return {
        ...state,
        isFetching: false,
        hasError: true,
      };
    }
    case FetchFilteredTopicsActionsTypes.FETCH_FILTERED_TOPICS_SUCCESS: {
      const newOffset = action.meta.topicIds.length + action.meta.offset;
      // offset = 0 means that we are doing a new search,
      // so remove all the old ids in state and just use the new ones returned
      const newIds = action.meta.offset ? [...state.ids, ...action.meta.topicIds] : action.meta.topicIds;
      return {
        ...state,
        isFetching: false,
        hasError: false,
        ids: newIds,
        offset: newOffset,
        hasPossibleNextPage: action.meta.topicIds.length === action.meta.limit,
      };
    }
    case FetchFilteredTopicsActionsTypes.CLEAR_FILTERED_TOPICS: {
      return {
        ids: [],
        isFetching: false,
        hasError: false,
        offset: 0,
        hasPossibleNextPage: true,
      };
    }
    default:
      return state;
  }
};
