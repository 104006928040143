import { QueryValue } from 'common/utilities/apiFetch';
import { PublicationFetchForTopicPayload } from 'entities/publication/actionTypes';

export const getPublicationFetchParams = ({
  searchTerm,
  secondaryTopicId,
  fromTime,
  toTime,
  language,
  publicationType,
  limit,
  offset,
}: Partial<PublicationFetchForTopicPayload>) => {
  const topics = secondaryTopicId ? [secondaryTopicId] : [];
  let filter: Record<string, QueryValue> = {
    from_time: fromTime,
    to_time: toTime,
    topics,
    limit,
    offset,
  };
  if (searchTerm) {
    filter = { ...filter, search: searchTerm };
  }
  if (language) {
    filter = { ...filter, language };
  }
  if (publicationType) {
    filter = { ...filter, publication_type: publicationType };
  }
  return filter;
};
