import { SVGProps } from 'react';

export const ThemeLightIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      d="M12.567 9.403L8.64 5.479 5.564 8.57l3.905 3.924 3.098-3.09zM0 21.808h6.545v4.384H0v-4.384zM21.818 0h4.364v6.466h-4.364V0zM39.36 5.468l3.072 3.084-3.906 3.924-3.07-3.086 3.904-3.922zm-3.927 33.13l3.905 3.944 3.076-3.09-3.927-3.923-3.054 3.068zm6.022-16.79H48v4.384h-6.545v-4.384zM24 10.85c-7.222 0-13.09 5.896-13.09 13.151S16.777 37.15 24 37.15c7.222 0 13.09-5.895 13.09-13.15 0-7.255-5.868-13.15-13.09-13.15zm0 21.918c-4.822 0-8.727-3.923-8.727-8.767 0-4.844 3.905-8.767 8.727-8.767 4.822 0 8.727 3.923 8.727 8.767 0 4.844-3.905 8.767-8.727 8.767zm-2.182 8.767h4.364V48h-4.364v-6.466zM5.564 39.43l3.076 3.09 3.905-3.945-3.076-3.09-3.905 3.945z"
      fill="currentColor"
    />
  </svg>
);
