import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { SectionTitleUnderlined, StyledGrid as Grid, InfinityLoader } from 'common/components';
import { CollectionTile } from 'common/components/collectionTile/CollectionTile';
import { breakpoints } from 'common/styles';
import { Status } from 'common/types/status';
import { trackEvent } from 'domains/analytics/actions';
import { fetchCollections } from 'domains/collection/actions';
import { selectCollections } from 'domains/collection/selectors';
import { CollectionType } from 'domains/collection/types/collectionType';
import { CreateNewCollectionSection } from 'domains/create/landing/CreateNewCollectionSection';
import { useTranslations } from 'domains/language/useTranslations';

const StyledGridContainer = styled.div`
  padding-bottom: 1rem;
  margin: 1rem;

  @media ${breakpoints.medium} {
    margin: 1rem 2rem 2rem;
  }

  @media ${breakpoints.extraLarge} {
    margin: 1rem 3rem 3rem;
  }
`;

const StyledGrid = styled(Grid)`
  grid-gap: 3rem 1rem;
`;

export const CreateCollectionsTab = () => {
  const dispatch = useDispatch();
  const { editable, nextPageMayExist, offset, status } = useSelector(selectCollections);
  const { textDictionary } = useTranslations();

  useEffect(() => {
    dispatch(fetchCollections(CollectionType.Editable));
  }, [dispatch]);

  useEffect(() => {
    dispatch(trackEvent({ name: 'Browse page', page: 'Create landing', subpage: 'Create landing - collections tab' }));
  }, [dispatch]);

  const fetchNextPage = () => dispatch(fetchCollections(CollectionType.Editable, offset.editable));

  return (
    <>
      <CreateNewCollectionSection />
      <StyledGridContainer>
        <SectionTitleUnderlined>{textDictionary['app.create.collection.title']}</SectionTitleUnderlined>
        {editable.length > 0 && (
          <InfinityLoader
            isPending={status.editable === Status.Loading}
            loadNextPage={fetchNextPage}
            hasNextPage={nextPageMayExist.editable}
          >
            <StyledGrid numberOfItems={[1, 2, 3, 4, 5]}>
              {editable.map((collection) => (
                <CollectionTile collection={collection} key={collection.id} />
              ))}
            </StyledGrid>
          </InfinityLoader>
        )}
      </StyledGridContainer>
    </>
  );
};
