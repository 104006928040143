export const constantColors = {
  blue: '#0065F2', // new
  black: '#000000', // new
  blueDark: '#2355C7',
  blueDisabled: '#2D457F',
  yellow: '#EEDD81', // changed
  yellowDark: '#C79816',
  red: '#FB5252',
  redDark: '#DC0007',
  green: '#00B573',
  greenDark: '#11995E',
  grey: '#7F7F7F',
  white: '#FFFFFF',
  whiteDark: '#DDDDDD',

  tagBackground: '#F2B253', // new
  textDark: '#B3B3B3',
  error: '#F32A2A',
};

const palette = {
  blue: '#0065F2',
  blueDark: '#2355C7',

  white: '#FFFFFF',
  greyLightest: '#F2F2F2',
  greyLighter: '#E5E5E5',
  greyLight: '#B3B3B3',

  greyDark: '#666666',
  greyDarkest: '#262626',
  activeDark: '#1C1C1C',
  black: '#000000',

  yellow: '#F7DB5E',
  yellowActive: '#E5D16D',
};

type Colors = typeof light;

// TODO: one object two props

export const light = {
  topHeaderBarBackground: palette.white,
  topHeaderBarText: palette.black,
  topHeaderBarActiveBackground: palette.greyLightest,

  landingSubTitle: palette.black,

  buttonOutlinedText: palette.blue,
  buttonOutlinedActiveText: palette.blueDark,
  buttonOutlinedActiveBorder: palette.blueDark,
  buttonOutlinedActiveBackground: `${palette.black}10`,
  buttonNeutralBackground: palette.greyLightest,
  buttonNeutralText: palette.black,
  buttonNeutralActiveText: palette.black,
  buttonNeutralActiveBackground: palette.white,
  buttonListItemText: palette.greyDark,
  buttonFollowPrimary: palette.black,
  buttonFollowSecondary: palette.white,

  dropdownBackground: palette.white,
  dropdownActiveBackground: palette.greyLighter,

  clusterCaptionText: palette.greyDark,

  chipText: palette.black,
  chipActiveText: palette.white,
  chipBackground: palette.greyLightest,
  chipActiveBackground: palette.black,
  chipBorder: palette.greyLight,
  chipHoverBackground: palette.white,
  chipActiveHoverBackground: palette.greyDarkest,

  inputBackground: palette.greyLighter,
  inputBorder: palette.greyLighter,
  inputText: palette.greyDark,
  inputTextFocused: palette.black,

  toggleBackground: palette.greyLighter,
  toggleText: palette.black,
  toggleActiveBackground: palette.white,
  toggleActiveText: palette.black,

  textBright: palette.black,
  textFade: palette.greyDark,

  activeButtonHoverBackground: palette.greyLighter,

  selectItemBackground: palette.white,

  proLabelBackground: palette.blue,
  proLabelBackgroundActive: palette.blueDark,
  proLabelText: palette.white,

  actionIcon: palette.blueDark,

  lockedLabelText: palette.white, // unused - commented out code
  lockedLabelBackground: palette.black,

  collectionBackground: palette.greyLightest,

  activeCardBorder: palette.black,

  checkboxBackground: palette.blue,
  checkboxBorder: palette.blue,
  checkboxIcon: palette.white,

  progressBarBorder: palette.greyLight,
  progressBarBackground: palette.greyLight,

  modalBackground: palette.greyLightest,
  modalCardBackground: palette.white,

  landingText: palette.greyDark,
  landingLatestCardBackground: palette.white,
  landingEmergingCardBackground: palette.white,
  landingEmergingCardBorder: palette.greyDark,

  aboutUsBackgroundPrimary: palette.white,
  aboutUsBackgroundSecondary: palette.greyLightest,

  linkIconBackground: palette.greyDark,

  landingPageVideoText: palette.greyDark,

  collectionTileShadow1: '#B3B3B3',
  collectionTileShadow2: '#CCCCCC',

  referenceTextBackground: '#e6e6e6',
  separator: 'rgba(0, 0, 0, 0.05)',
  tooltipBackground: palette.white,
  tooltipForeground: palette.black,
  translationChipBackground: palette.white,
  translationChipText: palette.blue,
  translationIndicatorPrimary: palette.white,
  translationIndicatorSecondary: palette.black,
  coBrandBackground: palette.white,
  searchDropdownBackground: palette.greyLighter,
  tabSelected: palette.white,
  tabHover: palette.greyLighter,
  tabUnselectedText: palette.greyDark,

  collectionCancelButtonText: 'rgba(0, 0, 0, 0.6)',
  collectionSaveButton: palette.blue,
  languageChipBorder: '#ccc',
  readonlyText: palette.greyDark,
  readonlyBorder: '#ccc',
  writableBorder: '#ccc',
  background: palette.white,

  carouselButton: 'rgba(0, 0, 0, 0.8)',
  carouselButtonFill: palette.white,

  fillPrimary: '#4A4B63',
  fillTertiary: '#8788a0',
  backgroundPrimary: '#F2F2F2', // new
  backgroundSecondary: '#FFFFFF', // new
  backgroundError: 'hsl(0, 100%, 98%)', // TODO: investigate error modals
  outline: '#E3E8F3',
  wefLogoText: '#141414',
  ...constantColors,
};

export const dark: Colors = {
  topHeaderBarBackground: palette.black,
  topHeaderBarText: palette.white,
  topHeaderBarActiveBackground: palette.activeDark,

  landingSubTitle: palette.greyLight,

  buttonOutlinedText: palette.white,
  buttonOutlinedActiveText: palette.white,
  buttonOutlinedActiveBorder: palette.white,
  buttonOutlinedActiveBackground: `${palette.white}30`,
  buttonNeutralBackground: palette.greyDarkest,
  buttonNeutralText: palette.greyLight,
  buttonNeutralActiveText: palette.white,
  buttonNeutralActiveBackground: palette.greyDarkest,
  buttonListItemText: palette.greyLight,
  buttonFollowPrimary: palette.white,
  buttonFollowSecondary: palette.black,

  dropdownBackground: palette.greyDarkest,
  dropdownActiveBackground: palette.activeDark,

  clusterCaptionText: palette.greyLight,

  chipText: palette.white,
  chipActiveText: palette.black,
  chipBackground: palette.black,
  chipActiveBackground: palette.white,
  chipBorder: palette.greyDark,
  chipHoverBackground: palette.greyDarkest,
  chipActiveHoverBackground: palette.greyLight,

  inputBackground: palette.greyDarkest,
  inputBorder: palette.white,
  inputText: palette.greyLight,
  inputTextFocused: palette.white,

  toggleBackground: palette.greyDarkest,
  toggleText: palette.white,
  toggleActiveBackground: palette.white,
  toggleActiveText: palette.black,

  textBright: palette.white,
  textFade: palette.greyLight,

  activeButtonHoverBackground: palette.activeDark,

  selectItemBackground: palette.activeDark,

  proLabelBackground: palette.yellow,
  proLabelBackgroundActive: palette.yellowActive,
  proLabelText: palette.black,

  actionIcon: palette.white,

  lockedLabelText: palette.black,
  lockedLabelBackground: palette.white,

  collectionBackground: palette.black,

  activeCardBorder: palette.white,

  checkboxBackground: palette.white,
  checkboxBorder: palette.white,
  checkboxIcon: palette.black,

  progressBarBorder: palette.greyLight,
  progressBarBackground: palette.greyDarkest,

  modalBackground: palette.greyDarkest,
  modalCardBackground: palette.black,

  landingText: palette.white,
  landingLatestCardBackground: palette.black,
  landingEmergingCardBackground: palette.greyDarkest,
  landingEmergingCardBorder: palette.greyDarkest,

  aboutUsBackgroundPrimary: palette.black,
  aboutUsBackgroundSecondary: palette.greyDarkest,

  linkIconBackground: palette.white,

  landingPageVideoText: palette.white,

  collectionTileShadow1: '#313131',
  collectionTileShadow2: '#4D4D4D',

  referenceTextBackground: 'rgba(38, 38, 38, 0.6)',
  separator: 'rgba(255, 255, 255, 0.15)',
  tooltipBackground: palette.black,
  tooltipForeground: palette.white,
  translationChipBackground: palette.blue,
  translationChipText: palette.white,
  translationIndicatorPrimary: palette.black,
  translationIndicatorSecondary: palette.white,
  coBrandBackground: '#2b2b2b',
  searchDropdownBackground: '#2b2b2b',
  tabSelected: '#464646',
  tabHover: '#232323',
  tabUnselectedText: 'rgba(255, 255, 255, 0.6)',

  collectionCancelButtonText: palette.white,
  collectionSaveButton: palette.blue,
  languageChipBorder: '#757575',
  readonlyText: palette.greyLight,
  readonlyBorder: '#757575',
  writableBorder: '#ccc',
  background: palette.black,

  carouselButton: 'rgba(0, 0, 0, 0.8)',
  carouselButtonFill: palette.white,

  fillPrimary: '#ffffff',
  fillTertiary: '#8788a0',
  backgroundPrimary: '#000000', // new
  backgroundSecondary: '#262626', // new
  backgroundError: 'hsl(0, 100%, 93%)',
  outline: '#303745',
  wefLogoText: palette.white,
  ...constantColors,
};
