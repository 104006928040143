import { useDispatch, useSelector } from 'react-redux';

import { useTranslations } from 'domains/language/useTranslations';
import { applyStakeholdersSearchCriteria, clearStakeholdersSearchCriteria } from 'domains/stakeholders/actions';
import { getStakeholdersSearchCriteria } from 'domains/stakeholders/selectors';
import { TopicFilterModal } from 'domains/topicSearchFilter/components/TopicFilterModal';
import { getTopicFilters } from 'domains/topicSearchFilter/selectors';

export const TopicStakeholderFilterModal = () => {
  const dispatch = useDispatch();
  const { textDictionary } = useTranslations();
  const searchCriteria = useSelector(getStakeholdersSearchCriteria);
  const filters = useSelector(getTopicFilters('stakeholders'));

  const handleApplyFilter = (payload: Partial<any>) => () => dispatch(applyStakeholdersSearchCriteria(payload));

  const handleClear = () => dispatch(clearStakeholdersSearchCriteria());

  return (
    <TopicFilterModal
      title={textDictionary['app.stakeholders.filter.title']}
      fields={filters}
      searchCriteria={searchCriteria as any}
      handleApplyFilter={handleApplyFilter}
      handleClear={handleClear}
    />
  );
};
