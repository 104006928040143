import { css, styled } from 'styled-components';

export const Card = styled.div`
  width: 100%;
  border-radius: 5px;
  margin-bottom: 1rem;
  padding: 1.5rem;
  box-sizing: border-box;

  ${({ theme }) => css`
    background-color: ${theme.colors.backgroundSecondary};
    box-shadow: ${theme.shadows.box};
    border: ${theme.borders.box};
  `};
`;
