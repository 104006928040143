import { useTranslations } from 'domains/language/useTranslations';
import { UpgradeModal } from 'domains/modal/components/UpgradeModal';

export const CustomKeyIssueUpgradeModal = () => {
  const { textDictionary } = useTranslations();

  return (
    <UpgradeModal
      title={textDictionary['app.create.upgrade.custom_key_issue.title']}
      message={textDictionary['app.create.upgrade.custom_key_issue.text']}
      primaryButtonText={textDictionary['app.create.upgrade.custom_key_issue.primary_button']}
      additionalPrimaryButtonTrackMeta={{
        page: 'Create',
        subpage: 'Create custom key issue',
      }}
      upgradeLocation="pro"
    />
  );
};
