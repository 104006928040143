import { ModalAction, ModalTypes } from 'domains/modal/actionTypes';
import { ModalState } from 'domains/modal/types';

const initialState: ModalState = {
  activeModals: [],
  paramsForId: {},
};

export const modalReducer = (state = initialState, action: ModalAction): ModalState => {
  switch (action.type) {
    case ModalTypes.ACTIVATE: {
      return {
        ...state,
        activeModals: [action.meta.id],
        paramsForId: {
          ...state.paramsForId,
          [action.meta.id]: action.meta.params,
        },
      };
    }
    case ModalTypes.DEACTIVATE: {
      return {
        ...state,
        activeModals: [],
      };
    }
    case ModalTypes.PUSH_SCREEN: {
      return {
        ...state,
        activeModals: [...(state.activeModals || []), action.meta.id],
        paramsForId: {
          ...state.paramsForId,
          [action.meta.id]: action.meta.params,
        },
      };
    }
    case ModalTypes.POP_SCREEN: {
      const items = state.activeModals || [];
      return {
        ...state,
        activeModals: items.length > 0 ? items.slice(0, items.length - 1) : [],
      };
    }
    default:
      return state;
  }
};
