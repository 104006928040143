import { TransformationMapAction, TransformationMapActionType } from 'domains/transformationMap/actionTypes';
import { TransformationMapState } from 'domains/transformationMap/types';
import { addUserChangeReset } from 'entities/user/utils';

const initialState: TransformationMapState = {
  isEditingMode: false,
  isPending: false,
  history: [],
};

const getHistory = (previousHistory: string[], id?: string) => {
  if (!id) {
    return previousHistory;
  }
  const filteredList = previousHistory.filter((previousId) => id !== previousId);
  return [id, ...filteredList];
};

const reducer = (state = initialState, action: TransformationMapAction): TransformationMapState => {
  switch (action.type) {
    case TransformationMapActionType.SET_TOPIC: {
      return {
        ...state,
        id: action.meta?.id,
        isPending: false,
        history: getHistory(state.history, action.meta?.id),
        selectedOuterNodeId: undefined,
      };
    }
    case TransformationMapActionType.UPDATE_TOPIC: {
      return {
        ...state,
        isPending: true,
        selectedInnerNodeId: undefined,
      };
    }
    case TransformationMapActionType.SET_INNER_NODE: {
      return {
        ...state,
        selectedInnerNodeId: action.meta.id,
        selectedOuterNodeId: undefined,
      };
    }
    case TransformationMapActionType.SET_OUTER_NODE: {
      return {
        ...state,
        selectedInnerNodeId: undefined,
        selectedOuterNodeId: action.meta.id,
      };
    }
    case TransformationMapActionType.SET_EDIT_MODE: {
      return {
        ...state,
        isEditingMode: action.meta.mode,
      };
    }
    default:
      return state;
  }
};

export const transformationMapReducer = addUserChangeReset(reducer, initialState);
