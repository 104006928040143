import { call, put, select } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { addKeyIssueToMapSuccess } from 'domains/createMapDetail/actions';
import { getCurrentMap, getCurrentMapKeyIssuesIds } from 'domains/createMapDetail/selectors';
import { createCustomKeyIssueFailure, createCustomKeyIssueSuccess } from 'domains/customKeyIssue/actions';
import { CreateCustomKeyIssueDefaultAction } from 'domains/customKeyIssue/actionTypes';
import {
  getCustomKeyIssueFingerprint,
  getCustomKeyIssueFPKnowledge,
  getCustomKeyIssueFPKnowledgeHasErrored,
  getPreEditedCustomKeyIssue,
} from 'domains/customKeyIssue/selectors';
import { CustomKeyIssueMeta, FPItem } from 'domains/customKeyIssue/types';
import { KeyIssue, PrimaryTopic } from 'entities/keyIssues/types';
import { Knowledge } from 'entities/knowledge/types';
import { getTopic, getTopicsByIds } from 'entities/topics/selectors';
import { Topic } from 'entities/topics/types';

const getDiffOfTopics = (currentTopics: string[], selectedTopics: string[]) => {
  const addList = selectedTopics.filter((x) => !currentTopics.includes(x));
  const removeList = currentTopics.filter((x) => !selectedTopics.includes(x));

  return {
    add: addList.map((id) => ({ id })),
    remove: removeList.map((id) => ({ id })),
  };
};

export const getCustomKeyIssueBodyFromMeta = (
  meta: CustomKeyIssueMeta,
  parentMapId: string,
  fingerprint?: FPItem[],
  fpKnowledge?: string[],
  existingKeyIssue?: KeyIssue | undefined,
) => {
  const { topics: newTopics, mappings: newKnowledgeMappings, ...bodyProperties } = meta;
  const newKnowledgeMappingIds = newKnowledgeMappings.map(({ id }) => id);
  const newTopicIds = newTopics.map(({ id }) => id);

  let body: Record<string, any> = bodyProperties;
  if (!existingKeyIssue?.id) {
    body = { ...body, parent_topic_id: parentMapId };
  }

  const topicsMeta = getDiffOfTopics(
    (existingKeyIssue?.topics || []).map(({ id }) => id),
    newTopicIds,
  );
  if (topicsMeta.add.length || topicsMeta.remove.length) {
    body = { ...body, topics: topicsMeta };
  }

  // If the mappings have changed, then add fingerprint and knowledge
  const oldKnowledgeMappings = existingKeyIssue?.mappings || [];
  const oldKnowledgeMappingIds = oldKnowledgeMappings.map(({ id }) => id);
  const mappingDiff = getDiffOfTopics(oldKnowledgeMappingIds, newKnowledgeMappingIds);

  if (!!mappingDiff.remove.length || !!mappingDiff.add.length) {
    body = {
      ...body,
      mappings: mappingDiff,
      fp: fingerprint,
      knowledge: fpKnowledge,
    };
  }

  return body;
};

export function* convertCustomKeyIssueMetaToKeyIssue(
  meta: CustomKeyIssueMeta,
  keyIssueId: string,
  primaryTopic?: PrimaryTopic,
) {
  const outerTopics: Topic[] = yield select(getTopicsByIds(meta.topics.map(({ id }) => id)));
  const updatedKeyIssue: KeyIssue = {
    id: keyIssueId,
    name: meta.name,
    subtitle: meta.subtitle,
    description: meta.description,
    primary_topic: primaryTopic,
    parent_topic: primaryTopic,
    isPending: false,
    is_custom_dimension: true,
    topics: outerTopics,
  };
  return updatedKeyIssue;
}

export function* createCustomKeyIssueSaga({ meta }: CreateCustomKeyIssueDefaultAction) {
  try {
    const currentMap: Partial<Topic> | undefined = yield select(getCurrentMap);
    if (!currentMap?.id) {
      return;
    }

    const preEditedKeyIssue: KeyIssue | undefined = yield select(getPreEditedCustomKeyIssue);
    const fingerprint: FPItem[] = yield select(getCustomKeyIssueFingerprint);
    const hasFPKnowledgeErrored: boolean = yield select(getCustomKeyIssueFPKnowledgeHasErrored);
    let fpKnowledge: Knowledge[] = yield select(getCustomKeyIssueFPKnowledge);
    if (hasFPKnowledgeErrored) {
      fpKnowledge = [];
    }
    const body = getCustomKeyIssueBodyFromMeta(
      meta,
      currentMap.id,
      fingerprint,
      fpKnowledge.map(({ id }) => id),
      preEditedKeyIssue,
    );

    // Save the key issue
    const { response }: { response: string } = yield apiFetch(
      `dimension${preEditedKeyIssue?.id ? `/${preEditedKeyIssue.id}` : ''}`,
      {
        method: preEditedKeyIssue?.id ? 'PATCH' : 'PUT',
        body,
      },
    );

    // Make an updated key issue object and use this to update state
    const primaryTopic: Topic = yield select(getTopic(currentMap.id));
    const keyIssueId = preEditedKeyIssue?.id || response;
    const updatedKeyIssue: KeyIssue = yield call(convertCustomKeyIssueMetaToKeyIssue, meta, keyIssueId, primaryTopic);

    const idsOfKeyIssuesOnMap: string[] = yield select(getCurrentMapKeyIssuesIds);
    const isKeyIssueNotOnMap = !idsOfKeyIssuesOnMap.includes(keyIssueId);
    if (isKeyIssueNotOnMap) {
      yield put(addKeyIssueToMapSuccess(updatedKeyIssue, { keyIssueId: response }));
    }
    yield put(createCustomKeyIssueSuccess(updatedKeyIssue, meta));
  } catch (error) {
    yield put(createCustomKeyIssueFailure(meta));
  }
}
