import { SVGProps } from 'react';

export const ArrowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      d="M48 21.815H8.368L19.64 10.544l-3.092-3.092L0 24l16.547 16.548 3.093-3.087L8.368 26.185H48v-4.37z"
      fill="currentColor"
    />
  </svg>
);
