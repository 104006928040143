import { RootState } from 'types';

export const getShouldDisplayBackButton = (state: RootState) => (state.domains.modal.activeModals || []).length > 1;

export const getActiveModalId = (state: RootState) => {
  const list = state.domains.modal.activeModals || [];
  if (list.length === 0) {
    return undefined;
  }
  return list[list.length - 1];
};

export const getModalParams = (state: RootState) => {
  const id = getActiveModalId(state);
  if (!id) {
    return {};
  }
  return state.domains.modal.paramsForId[id] || {};
};

export const getActiveModals = (state: RootState) => state.domains.modal.activeModals || [];

export const getIsModalActive = (state: RootState) => (state.domains.modal.activeModals || []).length !== 0;
