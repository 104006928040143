import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled, ThemeProvider } from 'styled-components';

import { CrossIcon, UIButton } from 'common/components';
import { fetchUnresolvedIncidents, dismissIncident } from 'domains/incidents/actions';
import { getTopUnresolvedIncident } from 'domains/incidents/selectors';
import { retrieveLatestDismissedIncidentKey } from 'domains/incidents/storage';
import { useTranslations } from 'domains/language/useTranslations';
import { activateModal } from 'domains/modal/actions';
import { lightTheme } from 'domains/theme/constants/theme';

type IncidentBannerProps = { className?: string };

const StyledContainer = styled.div`
  padding: 2rem;
  background-color: ${({ theme }) => theme.colors.yellow};
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 50px;
  max-height: 600px;
  transition:
    min-height 0.3s,
    max-height 0.3s;
  border-radius: 4px;
  overflow: hidden;
`;

const StyledMessageContainer = styled.div`
  margin-right: 20px;
  margin-bottom: 20px;
`;

const StyledCloseIcon = styled(CrossIcon)`
  display: inline-block;
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.colors.textBright};
  opacity: 0.6;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  &:hover {
    opacity: 1;
  }
`;

export const IncidentBanner = ({ className }: IncidentBannerProps) => {
  const { textDictionary, interpolate } = useTranslations();
  const dispatch = useDispatch();
  const unresolvedIncident = useSelector(getTopUnresolvedIncident);

  useEffect(() => {
    dispatch(fetchUnresolvedIncidents());
  }, [dispatch]);

  const item = retrieveLatestDismissedIncidentKey();
  if (!unresolvedIncident || item === unresolvedIncident.id) {
    return null;
  }

  const handleViewMoreClick = () => dispatch(activateModal({ id: 'Incidents' }));

  const onDismissClick = () => dispatch(dismissIncident({ id: unresolvedIncident.id }));

  const IncidentName = () => <strong>{unresolvedIncident.name}</strong>;

  return (
    <ThemeProvider theme={lightTheme}>
      <StyledContainer className={className}>
        <StyledMessageContainer>
          {interpolate(textDictionary['app.incident.banner.text'], {
            issueName: IncidentName,
          })}
        </StyledMessageContainer>
        <UIButton variant={'outlined'} onClick={handleViewMoreClick}>
          {textDictionary['app.incident.banner.button']}
        </UIButton>
        <StyledCloseIcon onClick={onDismissClick} />
      </StyledContainer>
    </ThemeProvider>
  );
};
