/**
 * Regular expression pattern to match YouTube video IDs.
 * Matches all known YouTube video URL formats (as of June 2024) with the exception of URLs containing an attribution link.
 *
 * First group matches protocol.
 *
 * Second group matches subdomain.
 *
 * Third group matches domain and path.
 *
 * Fourth group captures the YouTube video ID which must be exactly 11 characters long.
 */
export const youtubeVideoUrlRegex =
  /(?:https?:\/\/)?(?:www\.|m\.|youtube-nocookie\.com\/)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?|live|shorts)\/|watch\/?[^?]*\S*?[?&]v?=?)|youtu\.be\/|youtube-nocookie\.com\/embed\/|youtube\.com\/watch\/|youtube\.com\/oembed\?.*v%3D)(?:3D-)?([a-zA-Z0-9_-]{11})(?![a-zA-Z0-9_-])/i;
