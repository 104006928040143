import { styled } from 'styled-components';

import { MainLayout } from 'common/components';
import { breakpoints, mediaStyles } from 'common/styles';
import { tabData } from 'domains/faqs/tabsData';
import { ContentBody } from 'domains/termsAndConditions/components/ContentBody';
import { TableOfContents } from 'domains/termsAndConditions/components/TableOfContents';

const StyledContainer = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const StyledContent = styled.div`
  box-sizing: border-box;
  margin-top: 1rem;

  ${mediaStyles(({ spacing }) => `padding: 0 ${spacing}rem ${spacing}rem ${spacing}rem;`)};

  @media ${breakpoints.large} {
    margin-top: 2rem;
  }

  @media ${breakpoints.extraLarge} {
    padding: 3rem;
    margin-top: 0;
  }
`;

const StyledTopBar = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 2rem;

  @media ${breakpoints.extraLarge} {
    display: none;
  }
`;

const tabItems = ['general', 'access', 'membership', 'payments-and-billing'];

type FAQsProps = { type: string };

export const FAQs = ({ type }: FAQsProps) => {
  const tabs = tabItems.map((item) => tabData[item]);

  const sidePanel = <TableOfContents tabs={tabs} basePath="/faq" />;

  return (
    <StyledContainer>
      <MainLayout sidePanel={sidePanel}>
        <StyledContent>
          <StyledTopBar>{sidePanel}</StyledTopBar>
          <ContentBody tab={tabData[type]} />
        </StyledContent>
      </MainLayout>
    </StyledContainer>
  );
};
