import { ImageCollection } from 'common/types/imageCollection';
import { Signal } from 'domains/signals/types';
import { Topic } from 'entities/topics/types';

export type Knowledge = {
  isPending?: boolean;
  id: string;
  type: 'article';
  images?: ImageCollection;
  time: number;
  link: string;
  source: Source;
  summary: string;
  title: string;
  language: string;
  is_bookmarked?: boolean;
  isBookmarkPending?: boolean;
  topics: Topic[];
  interactive_link?: string;
  embedded_media?: EmbeddedMedia;
  attachment?: Attachment;
  knowledgeType: KnowledgeType;
  signals?: Signal[];
};

type Attachment = { id: string; url: string; image_url?: string; type: 'video' | 'podcast' | 'audio' };
type EmbeddedMedia = { url: string; width?: number; height?: number; title?: string; html?: string };
export type Source = { id: string; name: string; images?: ImageCollection; content_type: ContentType };

export enum ContentType {
  publication = 'Articles and Publication',
  video = 'Videos',
  data = 'Data Visualizations',
  case_study = 'Case Study',
}

export enum KnowledgeType {
  publication = 'publication',
  video = 'video',
  data = 'data',
}

export type KnowledgeState = { byId: Record<string, Knowledge> };
