import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';

import { pushHistory } from 'common/utilities';
import { unauthenticate } from 'domains/authentication/actions';
import { getIsAuthenticated } from 'domains/authentication/selectors';
import { activateModal } from 'domains/modal/actions';
import { getCanAccessProfile, getShouldDisplayNotifications } from 'entities/user/selectors';
import { useAuth } from 'useAuth';

export const UserRoutes = () => {
  const dispatch = useDispatch();
  const canAccessProfile = useSelector(getCanAccessProfile);
  const canAccessNotifications = useSelector(getShouldDisplayNotifications);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const { path } = useRouteMatch();
  const { id: notificationId, token: inviteLinkToken } = useParams<{
    id: string;
    token: string;
  }>();
  const { triggerLogin } = useAuth();

  useEffect(() => {
    const dispatchUnauthenticate = () => dispatch(unauthenticate({ isUserLogout: true }));

    switch (path) {
      case '/login':
      case '/register':
        if (isAuthenticated) {
          break;
        }
        triggerLogin();
        return;

      case '/reset-password-login':
        triggerLogin();
        return;

      case '/profile':
        if (!isAuthenticated) {
          triggerLogin();
          return;
        }
        if (canAccessProfile) {
          dispatch(activateModal({ id: 'User Profile' }));
        }
        break;

      case '/notifications/:id':
      case '/notifications':
        // TODO: Check this works with logging in and then trying this again
        if (canAccessNotifications) {
          dispatch(
            activateModal({
              id: 'Notifications',
              params: {
                id: notificationId,
              },
            }),
          );
        } else if (!isAuthenticated) {
          triggerLogin();
          return;
        }
        break;

      case '/logout':
        dispatchUnauthenticate();
        break;

      case '/map-invite-link/:token':
        if (isAuthenticated) {
          dispatch(
            activateModal({
              id: 'Open Link Invitation',
              params: {
                token: inviteLinkToken,
              },
            }),
          );
        } else {
          triggerLogin();
          return;
        }
        break;

      default:
        break;
    }
    pushHistory('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, path]);

  return null;
};
