import { useDispatch } from 'react-redux';
import { styled } from 'styled-components';

import { useTranslations } from 'domains/language/useTranslations';
import { activateModal } from 'domains/modal/actions';

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CoachmarkButton = () => {
  const dispatch = useDispatch();
  const { textDictionary } = useTranslations();

  const onClick = () => dispatch(activateModal({ id: 'Coachmark Settings' }));

  return <StyledWrapper onClick={onClick}>{textDictionary['app.profile.menu.coachmarks_settings']}</StyledWrapper>;
};
