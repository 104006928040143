import { useState } from 'react';
import { useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { MembershipPromoVideo, UIButton } from 'common/components';
import { breakpoints, mediaStyles } from 'common/styles';
import { getIsAuthenticated } from 'domains/authentication/selectors';
import BackgroundDark from 'domains/create/landing/background-dark.svg';
import BackgroundLight from 'domains/create/landing/background-light.svg';
import CreateIllustration2 from 'domains/create/landing/briefing.png';
import CreateIllustration1 from 'domains/create/landing/create.png';
import { CreateLandingTitle } from 'domains/create/landing/CreateLandingTitle';
import CreateIllustration3 from 'domains/create/landing/events.png';
import { UpgradeAccountForCreateCallToAction } from 'domains/create/landing/UpgradeAccountForCreateCallToAction';
import { AuthButtons } from 'domains/landing/components/AuthButtons';
import { StyledLandingText } from 'domains/landing/components/StyledLandingText';
import { StyledLandingTitle } from 'domains/landing/components/StyledLandingTitle';
import { useTranslations } from 'domains/language/useTranslations';
import { useThemeAppearance } from 'domains/theme/useThemeAppearance';

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  color: ${({ theme }) => theme.colors.textBright};
`;

const IMAGE_SIZE = 420;
const COLUMN_MARGIN = 2;

const StyledWrapper = styled.div<{ currentTheme: string }>`
  max-width: calc(${IMAGE_SIZE * 3}px + ${COLUMN_MARGIN * 2}rem);
  margin: 0 auto;
  height: 600px;
  ${mediaStyles(({ spacing }) => `padding: ${spacing}rem`)};

  background-position-x: center;
  background-repeat: no-repeat;
  background-attachment: initial;
  background-image: ${({ currentTheme }) =>
    currentTheme === 'dark'
      ? `linear-gradient(to bottom, rgba(0, 0, 0, 0), #000), url("${BackgroundDark}")`
      : `linear-gradient(to bottom, rgba(0, 0, 0, 0), #f2f2f2), url("${BackgroundLight}")`};
`;

const StyledIllustrationRow = styled.div`
  display: flex;
  gap: ${COLUMN_MARGIN}rem;
  overflow-x: scroll;
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledCard = styled.div`
  width: ${IMAGE_SIZE}px;
  background: ${({ theme }) => theme.colors.backgroundSecondary};
  text-align: center;
  font-weight: bold;
  border-radius: 5px;
`;

const StyledBannerSection = styled.div`
  margin: 7rem 0 3rem;
`;

const StyledSection = styled.div`
  padding: 6rem 0;
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  align-items: center;
  gap: 3rem;
  @media ${breakpoints.medium} {
    flex-direction: row;
  }
`;

const StyledCardImage = styled.img``;

const StyledCardText = styled.p`
  margin: 0;
  padding: 1rem 4rem;
  font-weight: bold;
  line-height: 1.6rem;
`;

const StyledMembershipPromoVideo = styled(MembershipPromoVideo)`
  height: 300px !important;
  width: 100%;
  @media ${breakpoints.medium} {
    min-width: 400px;
  }
  @media ${breakpoints.large} {
    min-width: 600px;
  }
`;

const StyledText = styled(StyledLandingText)`
  color: ${({ theme }) => theme.colors.textFade};
`;

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @media ${breakpoints.medium} {
    flex-direction: row;
  }
`;

export const PublicCreateLanding = () => {
  const { textDictionary } = useTranslations();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const theme = useThemeAppearance();
  const [isCallToActionVisible, setCallToActionVisible] = useState(false);

  const onCreateButtonClick = () => {
    setCallToActionVisible(true);
  };

  return (
    <StyledContainer>
      <StyledWrapper currentTheme={theme}>
        <StyledBannerSection>
          <CreateLandingTitle />
          {isAuthenticated ? (
            <StyledButtonContainer>
              <UIButton
                data-test-id="create-a-new-custom-map-button"
                icon="rightArrow"
                onClick={onCreateButtonClick}
                variant="filled"
              >
                {textDictionary['app.create.landing_banner.button']}
              </UIButton>
              <UIButton
                data-test-id="create-a-new-custom-map-with-assistant-button"
                icon="magicWand"
                onClick={onCreateButtonClick}
                variant="filled"
              >
                {textDictionary['app.create.landing_banner.create_with_ai_button']}
              </UIButton>
            </StyledButtonContainer>
          ) : (
            <AuthButtons />
          )}
          <UpgradeAccountForCreateCallToAction
            isCallToActionVisible={isCallToActionVisible}
            setCallToActionVisible={setCallToActionVisible}
            type={'publicCTA'}
          />
        </StyledBannerSection>
        <StyledIllustrationRow>
          <StyledCard>
            <StyledCardImage src={CreateIllustration1} />
            <StyledCardText>{textDictionary['app.create_landing.promo_text1']}</StyledCardText>
          </StyledCard>
          <StyledCard>
            <StyledCardImage src={CreateIllustration2} />
            <StyledCardText>{textDictionary['app.create_landing.promo_text2']}</StyledCardText>
          </StyledCard>
          <StyledCard>
            <StyledCardImage src={CreateIllustration3} />
            <StyledCardText>{textDictionary['app.create_landing.promo_text3']}</StyledCardText>
          </StyledCard>
        </StyledIllustrationRow>
        <StyledSection>
          <StyledMembershipPromoVideo />
          <div>
            <StyledLandingTitle>{textDictionary['app.membership_features.title']}</StyledLandingTitle>
            <StyledText> {textDictionary['app.promo.advanced_strategic_intelligence_capabilities']} </StyledText>
          </div>
        </StyledSection>
      </StyledWrapper>
    </StyledContainer>
  );
};
