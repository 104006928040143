import { useSelector } from 'react-redux';

import { InfinityLoader } from 'common/components';
import { TrackPage } from 'domains/analytics/types';
import { ClusterListItem } from 'domains/clusters/components/ClusterListItem';
import { Cluster } from 'domains/clusters/types';
import { useTranslations } from 'domains/language/useTranslations';
import { getTopicsFilterSelectedIds } from 'domains/monitor/filter/selectors';
import { useMedia } from 'domains/viewport/useMedia';
import { getCanAccessMonitor } from 'entities/user/selectors';

type ClusterListProps = {
  hasNextPage: boolean;
  isCompact?: boolean;
  isOnCard?: boolean;
  isPending: boolean;
  items: Cluster[];
  loadNextPage: () => void;
  selectedId?: string;
  trackPage: TrackPage;
};

export const CLUSTER_LIMIT = 4;

export const ClusterList = ({
  items,
  hasNextPage,
  isPending,
  isCompact = false,
  selectedId,
  trackPage,
  loadNextPage,
  isOnCard,
}: ClusterListProps) => {
  const hasAccessToMonitor = useSelector(getCanAccessMonitor);
  const selectedTopics = useSelector(getTopicsFilterSelectedIds);
  const { textDictionary } = useTranslations();
  const isList = useMedia({ small: true, medium: true }, false);

  if (items.length === 0 && !isPending) {
    return (
      <span>
        {
          textDictionary[
            selectedTopics.length === 0
              ? 'app.monitor.empty_message.emerging_themes'
              : 'app.monitor.empty_message_filter'
          ]
        }
      </span>
    );
  }

  return (
    <InfinityLoader isPending={isPending} hasNextPage={hasNextPage} loadNextPage={loadNextPage}>
      {items.map((item, index) => (
        <ClusterListItem
          key={item.id}
          isCompact={isCompact}
          isSelected={!isList && selectedId === item.id}
          cluster={item}
          link={`/monitor/emerging-themes/${item.id}`}
          shareLink={`/monitor/emerging-themes/${item.id}`}
          isLocked={!hasAccessToMonitor && index >= CLUSTER_LIMIT}
          isFirstLocked={index === CLUSTER_LIMIT}
          trackPage={trackPage}
          isOnCard={isOnCard}
        />
      ))}
    </InfinityLoader>
  );
};
