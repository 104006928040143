import { combineReducers } from 'redux';

import { TopicsMetadataAction, TopicsMetadataActionTypes } from 'entities/topicsMetadata/actionTypes';
import { TopicsMetadataState } from 'entities/topicsMetadata/types';
import { addUserChangeReset } from 'entities/user/utils';

const initialState: TopicsMetadataState = {
  allTopicsCount: 0,
  customTopicsCount: 0,
};

const allTopicsCount = (
  state = initialState.allTopicsCount,
  action: TopicsMetadataAction,
): TopicsMetadataState['allTopicsCount'] => {
  switch (action.type) {
    case TopicsMetadataActionTypes.FETCH_FULFILLED: {
      return action.payload.allTopicsCount;
    }
    default:
      return state;
  }
};

const customTopicsCount = (
  state = initialState.allTopicsCount,
  action: TopicsMetadataAction,
): TopicsMetadataState['allTopicsCount'] => {
  switch (action.type) {
    case TopicsMetadataActionTypes.FETCH_FULFILLED: {
      return action.payload.customTopicsCount;
    }
    default:
      return state;
  }
};

export const topicsMetadataReducer = combineReducers({
  allTopicsCount: addUserChangeReset(allTopicsCount, initialState.allTopicsCount),
  customTopicsCount: addUserChangeReset(customTopicsCount, initialState.customTopicsCount),
});
