import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { activateModal } from 'domains/modal/actions';
import { DestinationTypes } from 'domains/types';
import {
  fetchTopicsSuccess,
  fetchTopicsForCollectionSuccess,
  fetchTopicsForCollectionFailure,
} from 'entities/topics/actions';
import { FetchTopicsForCollectionAction } from 'entities/topics/actionTypes';
import { Topic } from 'entities/topics/types';

export function* fetchTopicsForCollectionSaga({ payload }: FetchTopicsForCollectionAction) {
  const { id, offset, limit } = payload;
  try {
    const topics: Topic[] = yield apiFetch(`topic?simple=true&collectionId=${id}&limit=${limit}&offset=${offset}`);
    yield put(
      fetchTopicsSuccess({
        type: DestinationTypes.COLLECTION,
        topics,
        limit,
        offset,
      }),
    );
    yield put(fetchTopicsForCollectionSuccess({ id }, { offset, numberOfTopicsFetched: topics.length, limit }));
  } catch (e) {
    yield put(activateModal({ id: 'Error' }));
    yield put(fetchTopicsForCollectionFailure({ id }));
    console.error('Error fetching topics for collection', e);
  }
}
