import {
  CoachmarkDomainsActionType,
  ToggleCoachmarksAction,
  InitCoachmarksAction,
  AddCoachmarksToUsedAction,
  RemoveCoachmarksFromUsedAction,
  AddCoachmarksToInDOMAction,
  FetchUsedCoachmarksAction,
  FetchUsedCoachmarksSuccessAction,
  FetchCoachmarksVisibilityStatusAction,
  FetchCoachmarksVisibilityStatusSuccessAction,
} from 'domains/coachmarks/actionTypes';

export const toggleCoachmarks = (): ToggleCoachmarksAction => ({
  type: CoachmarkDomainsActionType.TOGGLE,
});

export const initCoachmarks = (): InitCoachmarksAction => ({
  type: CoachmarkDomainsActionType.INIT,
});

export const addCoachmarksToUsed = (payload: AddCoachmarksToUsedAction['payload']): AddCoachmarksToUsedAction => ({
  type: CoachmarkDomainsActionType.ADD_TO_USED,
  payload,
});

export const removeCoachmarksFromUsed = (
  payload?: RemoveCoachmarksFromUsedAction['payload'],
): RemoveCoachmarksFromUsedAction => ({
  type: CoachmarkDomainsActionType.REMOVE_FROM_USED,
  payload,
});

export const addCoachmarksToInDOM = (payload: AddCoachmarksToInDOMAction['payload']) => ({
  type: CoachmarkDomainsActionType.ADD_TO_IN_DOM,
  payload,
});

export const fetchUsedCoachmarks = (): FetchUsedCoachmarksAction => ({
  type: CoachmarkDomainsActionType.FETCH_USED,
});

export const fetchUsedCoachmarksSuccess = (
  payload: FetchUsedCoachmarksSuccessAction['payload'],
): FetchUsedCoachmarksSuccessAction => ({
  type: CoachmarkDomainsActionType.FETCH_USED_SUCCESS,
  payload,
});

export const fetchCoachmarksVisibilityStatus = (): FetchCoachmarksVisibilityStatusAction => ({
  type: CoachmarkDomainsActionType.FETCH_VISIBILITY_STATUS,
});

export const fetchCoachmarksVisibilityStatusSuccess = (
  payload: FetchCoachmarksVisibilityStatusSuccessAction['payload'],
): FetchCoachmarksVisibilityStatusSuccessAction => ({
  type: CoachmarkDomainsActionType.FETCH_VISIBILITY_STATUS_SUCCESS,
  payload,
});
