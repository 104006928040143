import { SVGProps } from 'react';

export const EventIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g fill="currentColor" transform="translate(-71.474 -21.869)">
      <path
        d="M103.438,44.951h16.686a2.809,2.809,0,0,0,2.807-2.787V26.358a2.8,2.8,0,0,0-2.807-2.778h-1.985V22.7a.831.831,0,1,0-1.663,0v.88h-9.389V22.7a.831.831,0,1,0-1.663,0v.88h-1.985a2.8,2.8,0,0,0-2.807,2.778V42.163a2.809,2.809,0,0,0,2.807,2.787Zm16.686-1.663H103.438a1.142,1.142,0,0,1-1.144-1.125V30.329h18.974V42.163a1.142,1.142,0,0,1-1.144,1.125ZM103.438,25.243h1.985V26.1a.831.831,0,1,0,1.663,0v-.861h9.389V26.1a.831.831,0,1,0,1.663,0v-.861h1.985a1.133,1.133,0,0,1,1.144,1.115v2.308H102.293V26.358a1.134,1.134,0,0,1,1.144-1.115Z"
        transform="translate(-29.157 0)"
      />
    </g>
  </svg>
);
