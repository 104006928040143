import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { fetchMembershipEventByIdFailure, fetchMembershipEventByIdSuccess } from 'domains/membershipEvents/actions';
import { FetchMembershipEventByIdAction } from 'domains/membershipEvents/actionTypes';
import { MembershipEvent } from 'domains/membershipEvents/types';

export function* fetchMembershipEventByIdSaga(action: FetchMembershipEventByIdAction) {
  try {
    const response: MembershipEvent = yield apiFetch(`profile/events/${action.meta.id}`);
    yield put(fetchMembershipEventByIdSuccess(response, action.meta));
  } catch (error) {
    yield put(fetchMembershipEventByIdFailure(action.meta));
  }
}
