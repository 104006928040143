import { combineReducers } from 'redux';

import { searchCriteriaReducer as buildSearchCriteriaReducer } from 'common/reducer/searchCriteriaReducer';
import { TopicLatestActionTypes } from 'domains/knowledgeFeed/actionTypes';
import { TopicKnowledgeFeedPackage, TopicKnowledgeFeedState } from 'domains/knowledgeFeed/types';
import { findMatchingCriteriaAndOthers } from 'domains/publication/reducer';
import { FullTopicPublicationSearchCriteria } from 'domains/publication/types';
import { addTopicSelectionReset, addUserOrMapChangeReset } from 'domains/topic/reducerHelpers';
import { KnowledgeFeedAction, KnowledgeFeedActionTypes } from 'entities/knowledgeFeed/actionTypes';

const initialState: TopicKnowledgeFeedState = {
  forTopic: {},
  searchCriteria: {
    contentType: '',
    searchTerm: '',
    language: '',
    publicationType: '',
    year: '',
    role: '',
    limit: 14,
    offset: 0,
  },
};

// Convenience function to capture the generic type args
const findKnowledgeFeedPackageAndOthers = (
  forTopic: TopicKnowledgeFeedState['forTopic'],
  searchCriteria: FullTopicPublicationSearchCriteria,
) =>
  findMatchingCriteriaAndOthers<TopicKnowledgeFeedPackage, FullTopicPublicationSearchCriteria>(
    forTopic,
    searchCriteria,
  );

const knowledgeFeedForTopicReducer = (state = initialState.forTopic, action: KnowledgeFeedAction) => {
  switch (action.type) {
    case KnowledgeFeedActionTypes.FETCH_FOR_TOPIC: {
      const { offset: _offset, limit: _limit, ...searchCriteria } = action.payload;
      const [matchingKnowledgeFeedPackage, others] = findKnowledgeFeedPackageAndOthers(state, searchCriteria);
      const newKnowledgeFeedPackage = {
        ...(matchingKnowledgeFeedPackage || {}),
        isFetching: true,
        hasNextPage: true,
        error: false,
        data: matchingKnowledgeFeedPackage?.data || [],
        searchCriteria,
      };
      return {
        ...state,
        [searchCriteria.id]: [...others, newKnowledgeFeedPackage],
      };
    }

    case KnowledgeFeedActionTypes.FETCH_FOR_TOPIC_FAILURE: {
      const { searchCriteria } = action.meta;
      const [matchingKnowledgeFeedPackage, others] = findKnowledgeFeedPackageAndOthers(state, searchCriteria);
      const newKnowledgeFeedPackage = {
        ...(matchingKnowledgeFeedPackage || {}),
        isFetching: false,
        hasNextPage: false,
        error: true,
        data: matchingKnowledgeFeedPackage?.data || [],
        searchCriteria,
      };
      return {
        ...state,
        [searchCriteria.id]: [...others, newKnowledgeFeedPackage],
      };
    }

    case KnowledgeFeedActionTypes.FETCH_FOR_TOPIC_SUCCESS: {
      const { data } = action.payload;
      const { offset, limit, ...searchCriteria } = action.meta;
      const [matchingKnowledgeFeedPackage, others] = findKnowledgeFeedPackageAndOthers(state, searchCriteria);
      const dataToKeep = matchingKnowledgeFeedPackage?.data.slice(0, offset) || [];
      const newKnowledgeFeedPackage = {
        ...(matchingKnowledgeFeedPackage || {}),
        isFetching: false,
        hasNextPage: data.length >= limit,
        error: false,
        data: dataToKeep.concat(data.map(({ id }) => id)),
        searchCriteria,
      };
      return {
        ...state,
        [searchCriteria.id]: [...others, newKnowledgeFeedPackage],
      };
    }
    default:
      return state;
  }
};

const searchCriteriaReducer = buildSearchCriteriaReducer(
  initialState.searchCriteria,
  TopicLatestActionTypes.APPLY_SEARCH_CRITERIA,
  TopicLatestActionTypes.CLEAR_SEARCH_CRITERIA,
);

export const knowledgeFeedReducer = combineReducers({
  forTopic: addUserOrMapChangeReset(knowledgeFeedForTopicReducer, initialState.forTopic),
  searchCriteria: addTopicSelectionReset(searchCriteriaReducer, initialState.searchCriteria),
});

export const isSameSearchCriteria = (criteria1: Record<string, any>, criteria2: Record<string, any>) => {
  // filter if key or type not the same
  Object.keys(criteria1).every(
    (key) => criteria1[key as keyof typeof criteria1] === criteria2[key as keyof typeof criteria2],
  );

  return !Object.keys(criteria1).some(
    (key) => criteria1[key as keyof typeof criteria1] !== criteria2[key as keyof typeof criteria2],
  );
};
