import { useDispatch } from 'react-redux';
import { styled } from 'styled-components';

import { UIButton } from 'common/components';
import { breakpoints } from 'common/styles';
import { trackEvent } from 'domains/analytics/actions';
import { TrackEvent } from 'domains/analytics/types';
import { useTranslations } from 'domains/language/useTranslations';
import { useAuth } from 'useAuth';

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;

  @media ${breakpoints.medium} {
    flex-direction: row;
    margin-bottom: 7rem;
    width: auto;
  }
`;

export const AuthButtons = () => {
  const dispatch = useDispatch();
  const { textDictionary } = useTranslations();
  const { triggerLogin } = useAuth();

  const onLoginRegisterClick = (name: TrackEvent) => () => {
    dispatch(trackEvent({ name, eventType: 'initialize modal' }));
    triggerLogin();
  };

  return (
    <StyledButtonContainer>
      <UIButton
        data-test-id="register-button"
        icon="rightArrow"
        onClick={onLoginRegisterClick('Signup')}
        variant="filled"
      >
        {textDictionary['app.registration.join_us']}
      </UIButton>
      <UIButton
        data-test-id="login-button"
        icon="rightArrow"
        onClick={onLoginRegisterClick('Login')}
        variant="outlined"
      >
        {textDictionary['app.user.sign_in']}
      </UIButton>
    </StyledButtonContainer>
  );
};
