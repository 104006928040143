import {
  SuggestionsFetchAction,
  SuggestionsActionTypes,
  SuggestionsFetchFulfilledAction,
  SuggestionsFetchRejectedAction,
} from 'domains/monitor/suggestions/actionTypes';
import { Topic } from 'entities/topics/types';

export const fetchSuggestions = (): SuggestionsFetchAction => ({
  type: SuggestionsActionTypes.SUGGESTIONS_FETCH,
});

export const suggestionsFulfilled = (payload: Topic[]): SuggestionsFetchFulfilledAction => ({
  type: SuggestionsActionTypes.SUGGESTIONS_FETCH_FULFILLED,
  payload,
});

export const suggestionsRejected = (): SuggestionsFetchRejectedAction => ({
  type: SuggestionsActionTypes.SUGGESTIONS_FETCH_REJECTED,
});
