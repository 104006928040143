import { PropsWithChildren } from 'react';
import { styled } from 'styled-components';

import { ThemeType } from 'domains/theme/constants/theme';

type StyledProps = { isDisabled?: boolean; isOn: boolean; theme: ThemeType };

const StyledToggleWrapper = styled.button<StyledProps>`
  padding: 0;
  align-items: center;
  box-sizing: border-box;
  width: 46px;
  height: 32px;
  border-radius: 9999em;
  margin-left: auto;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  transition: all 0.3s ease;
  border: 2px solid ${({ theme, isOn }) => (isOn ? theme.colors.blue : theme.colors.inputText)};
  background-color: transparent;
  position: relative;

  &[disabled] {
    border: 2px solid ${({ theme, isOn }) => (isOn ? `${theme.colors.blue}BB` : `${theme.colors.inputText}BB`)};
    cursor: not-allowed;
  }

  &:not([disabled]):hover {
    border: 2px solid ${({ theme, isOn }) => (isOn ? theme.colors.blueDark : theme.colors.inputTextFocused)};
  }
  &:not([disabled]):hover:after {
    background-color: ${({ theme, isOn }) => (isOn ? theme.colors.blueDark : theme.colors.inputTextFocused)};
  }
  &:after {
    content: '';
    position: absolute;
    width: 26px;
    height: 26px;
    top: 1px;
    border-radius: 13px;
    left: ${({ isOn }) => (isOn ? '15px' : '1px')};
    transition: all 0.3s ease;
    background-color: ${({ theme, isOn }) => (isOn ? theme.colors.blue : theme.colors.inputText)};
    opacity: ${({ isDisabled }) => (isDisabled ? 0.7 : 1)};
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const StyledSwitchText = styled.div`
  color: ${({ theme }) => theme.colors.inputTextFocused};
  font-weight: bold;
  display: inline-block;
`;

type UISwitchProps = PropsWithChildren & {
  className?: string;
  dataTestId?: string;
  isDisabled?: boolean;
  isOn: boolean;
  onClick: () => void;
};

export const UISwitch = ({ children, className, dataTestId, isDisabled, isOn, onClick }: UISwitchProps) => (
  <StyledContainer className={className}>
    {children && <StyledSwitchText>{children}</StyledSwitchText>}
    <StyledToggleWrapper isOn={isOn} disabled={isDisabled} onClick={onClick} data-test-id={dataTestId} />
  </StyledContainer>
);
