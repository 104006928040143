import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { Knowledge } from 'entities/knowledge/types';
import { getPublicationFetchParams } from 'entities/publication/sagas/utils';
import { fetchVideosForTopicFailure, fetchVideosForTopicSuccess } from 'entities/videos/actions';
import { VideosFetchForTopicAction } from 'entities/videos/actionTypes';
import { isFetchError } from 'TypeCheckMethods';

export function* fetchVideosForTopicSaga(action: VideosFetchForTopicAction) {
  const { id } = action.payload;
  try {
    const payload: Knowledge[] = yield apiFetch(`topic/${id}/media`, {
      queryParams: {
        ...getPublicationFetchParams(action.payload),
        content_type: 'Videos',
      },
    });

    yield put(fetchVideosForTopicSuccess({ topicId: id, data: payload }, action.payload));
  } catch (error) {
    const errorMeta = {
      searchCriteria: action.payload,
      logoutOnAuthError: true,
    };
    if (isFetchError(error)) {
      yield put(fetchVideosForTopicFailure({ topicId: id, error }, errorMeta));
    }
  }
}
