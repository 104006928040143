import { Knowledge } from 'entities/knowledge/types';
import { ErrorMeta } from 'types';

export enum KnowledgeActionTypes {
  FETCH_FOR_ID = 'entities/KNOWLEDGE_FETCH_FOR_ID',
  FETCH_FOR_ID_SUCCESS = 'entities/KNOWLEDGE_FETCH_FOR_ID_SUCCESS',
  FETCH_FOR_ID_FAILURE = 'entities/KNOWLEDGE_FETCH_FOR_ID_FAILURE',
}

export type KnowledgeItemDefaultAction = { meta: { id: string }; type: KnowledgeActionTypes.FETCH_FOR_ID };

export type KnowledgeItemFulfilledAction = {
  meta: { id: string };
  payload: Knowledge;
  type: KnowledgeActionTypes.FETCH_FOR_ID_SUCCESS;
};

type KnowledgeRejectedMeta = ErrorMeta & { id: string };

export type KnowledgeItemRejectedAction = {
  meta: KnowledgeRejectedMeta;
  type: KnowledgeActionTypes.FETCH_FOR_ID_FAILURE;
};

export type KnowledgeItemAction =
  | KnowledgeItemDefaultAction
  | KnowledgeItemFulfilledAction
  | KnowledgeItemRejectedAction;
