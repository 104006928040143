import { put, select } from 'redux-saga/effects';

import { TopicLatestApplySearchCriteria } from 'domains/knowledgeFeed/actionTypes';
import { getLatestSearchCriteria } from 'domains/knowledgeFeed/selectors';
import { TopicLatestSearchCriteria } from 'domains/knowledgeFeed/types';
import { AdvancedTopicKnowledgeSearchCriteria } from 'domains/types';
import { getAdvancedKnowledgeCriteria } from 'entities/knowledge/selectors';
import { fetchKnowledgeFeedForTopic } from 'entities/knowledgeFeed/actions';

export function* applyLatestSearchCriteriaSaga(action: TopicLatestApplySearchCriteria) {
  const searchCriteria: TopicLatestSearchCriteria = yield select(getLatestSearchCriteria);
  const advancedCriteria: AdvancedTopicKnowledgeSearchCriteria = yield select(getAdvancedKnowledgeCriteria);
  const id = action.payload?.topicId ?? advancedCriteria.id;
  if (!id) {
    return;
  }
  const payload = { ...advancedCriteria, ...searchCriteria, id };
  yield put(fetchKnowledgeFeedForTopic(payload));
}
