import { SVGProps } from 'react';

export const ResetIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      d="M4 24.024c0-2.09.322-4.106.916-6H.758a24.025 24.025 0 00-.758 6C0 37.27 10.754 48 24 48s24-10.73 24-23.976c0-13.246-10.754-24-24-24a23.862 23.862 0 00-13.854 4.422L6.71 0 2.84 12.024H16l-3.406-4.408A19.888 19.888 0 0124 4.024c11.04 0 20 8.962 20 20 0 11.04-8.96 20-20 20-11.038 0-20-8.96-20-20z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.85 12.682c-.072-.215-.149-.442-.234-.682h-3.232c-.085.24-.162.467-.234.682-.421 1.251-.702 2.087-1.786 2.536-1.085.45-1.871.06-3.058-.528-.203-.101-.419-.208-.65-.318l-2.284 2.284c.11.23.216.446.318.65.586 1.181.978 1.971.528 3.058-.449 1.085-1.285 1.365-2.536 1.784a40.26 40.26 0 00-.682.234v3.234c.24.085.466.161.68.233 1.251.42 2.09.701 2.538 1.785.452 1.092.053 1.893-.527 3.056-.102.205-.21.421-.319.652l2.284 2.284c.23-.11.445-.216.648-.317 1.18-.586 1.973-.98 3.06-.529 1.084.449 1.364 1.281 1.786 2.536.072.216.149.444.234.684h3.232c.085-.238.16-.464.232-.677.422-1.255.704-2.092 1.796-2.545 1.077-.449 1.86-.06 3.047.53.205.1.421.208.653.318l2.284-2.284c-.109-.229-.215-.443-.316-.646-.588-1.181-.983-1.974-.532-3.06.45-1.085 1.29-1.367 2.542-1.788.214-.072.44-.147.678-.232v-3.234c-.24-.085-.469-.162-.684-.234-1.26-.423-2.087-.701-2.534-1.784-.45-1.084-.06-1.87.527-3.055.102-.204.209-.42.319-.653l-2.284-2.284c-.228.108-.442.215-.644.315-1.181.587-1.976.982-3.064.531-1.084-.45-1.364-1.28-1.786-2.536zm-3.407 15.004a4 4 0 103.115-7.371 4 4 0 00-3.115 7.37z"
      fill="currentColor"
    />
  </svg>
);
