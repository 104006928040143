import { useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { MainLayout, JoinUsUpgradeBanner } from 'common/components';
import { CollectionCarousel } from 'common/components/collectionCarousel/CollectionCarousel';
import { mediaStyles } from 'common/styles';
import { selectCollections } from 'domains/collection/selectors';
import { SearchableTopicList } from 'domains/filteredTopicList/SearchableTopicList';
import { useTranslations } from 'domains/language/useTranslations';

const StyledContainer = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
`;

const StyledJoinUsUpgradeBanner = styled(JoinUsUpgradeBanner)`
  ${mediaStyles(({ spacing }) => `margin: 1rem ${spacing}rem;`)};
`;

const StyledCarouselContainer = styled.div`
  margin-bottom: 2rem;
`;

export const DiscoverPage = () => {
  const { listed } = useSelector(selectCollections);
  const { textDictionary } = useTranslations();

  const collectionHeader = textDictionary['app.discover.collections.title'];

  return (
    <StyledContainer>
      <MainLayout hideSidePanel={true}>
        <StyledJoinUsUpgradeBanner trackPage={'Discover'} />
        <StyledCarouselContainer>
          <CollectionCarousel collections={listed} header={collectionHeader} />
        </StyledCarouselContainer>
        <SearchableTopicList />
      </MainLayout>
    </StyledContainer>
  );
};
