import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { useTranslations } from 'domains/language/useTranslations';
import { activateModal } from 'domains/modal/actions';
import { TopHeaderItemBadge } from 'domains/navigation/components/TopHeaderItemBadge';
import { getNotificationBadgeCount } from 'domains/notifications/selectors';

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
`;

const StyledBadge = styled(TopHeaderItemBadge)`
  color: white;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.red};
`;

export const NotificationButton = () => {
  const dispatch = useDispatch();
  const { textDictionary } = useTranslations();
  const count = useSelector(getNotificationBadgeCount);

  const onClick = () => dispatch(activateModal({ id: 'Notifications' }));

  return (
    <StyledContainer onClick={onClick}>
      {textDictionary['app.notifications.title']}
      {count !== 0 && <StyledBadge>{count}</StyledBadge>}
    </StyledContainer>
  );
};
