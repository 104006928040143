import { memo } from 'react';

import { ListItemWrapper } from 'common/components/lists/ListItemWrapper';
import { ListItemType } from 'common/components/lists/types';
import { TrackPage } from 'domains/analytics/types';
import { CustomKnowledge } from 'domains/customContentAndSources/types';
import { KnowledgeDataNavigationItemBrowseView } from 'domains/knowledgeData/components/KnowledgeDataNavigationItemBrowseView';
import { ArticleItem } from 'domains/publication/components/ArticleItem';
import { Knowledge, KnowledgeType } from 'entities/knowledge/types';

type KnowledgeListItemProps = {
  isFirstLocked?: boolean;
  isLocked?: boolean;
  isSelected?: boolean;
  knowledge: Knowledge | CustomKnowledge;
  link: string;
  onClick?: () => void;
  shareLink: string;
  trackPage: TrackPage;
  type?: ListItemType;
  withoutWrapper?: boolean;
};

export const KnowledgeListItem = memo(
  ({
    isFirstLocked,
    isLocked = false,
    isSelected,
    knowledge,
    trackPage,
    withoutWrapper,
    ...props
  }: KnowledgeListItemProps) => {
    let item = null;
    if (knowledge.knowledgeType === KnowledgeType.video) {
      item = <ArticleItem knowledge={knowledge} isVideo={true} {...props} />;
    } else if (knowledge.interactive_link) {
      item = <KnowledgeDataNavigationItemBrowseView knowledge={knowledge} {...props} />;
    } else {
      item = <ArticleItem knowledge={knowledge} {...props} />;
    }
    if (withoutWrapper) {
      return item;
    }
    return (
      <ListItemWrapper isLocked={isLocked} trackPage={trackPage} isFirstLocked={isFirstLocked} isSelected={isSelected}>
        {item}
      </ListItemWrapper>
    );
  },
);

KnowledgeListItem.displayName = 'KnowledgeListItem';
