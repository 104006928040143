import { useRef } from 'react';
import { styled } from 'styled-components';

import {
  FullscreenContainer,
  FullscreenIcon,
  SectionHeadingButton,
  SourceLabelTitle,
  WebFrame,
} from 'common/components';
import { FullscreenRef } from 'common/components/FullscreenContainer';
import { useTranslations } from 'domains/language/useTranslations';
import { useMedia } from 'domains/viewport/useMedia';
import { Knowledge } from 'entities/knowledge/types';

const StyledFrame = styled(WebFrame)`
  border: none;
  overflow: hidden;
  border-radius: 5px;
  height: 100%;
  padding-top: 0;
  margin: 0;
`;

const StyledFullscreenIcon = styled(FullscreenIcon)`
  width: 14px;
  height: 14px;
`;

type KnowledgeDataDetailProps = { knowledge: Knowledge };

export const KnowledgeDataDetail = ({ knowledge }: KnowledgeDataDetailProps) => {
  const { textDictionary } = useTranslations();
  const fullscreenRef = useRef<FullscreenRef>(null);
  const isDesktop = useMedia({ small: false, medium: false }, true);

  const { title, source, time, interactive_link } = knowledge;

  const fullscreenButton = isDesktop ? (
    <SectionHeadingButton
      iconElement={<StyledFullscreenIcon />}
      onClick={() => fullscreenRef.current?.toggleFullscreen()}
    >
      {textDictionary['app.fullscreen.title']}
    </SectionHeadingButton>
  ) : undefined;

  return (
    <>
      <SourceLabelTitle source={source} date={time} rightElement={fullscreenButton} type="small" />
      {interactive_link && (
        <FullscreenContainer ref={fullscreenRef}>
          <StyledFrame url={interactive_link} title={title} />
        </FullscreenContainer>
      )}
    </>
  );
};
