import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { fetchLatestKnowledgeSuccess, fetchLatestKnowledgeFailure } from 'domains/monitor/latestKnowledge/actions';
import { LatestKnowledgeAction } from 'domains/monitor/latestKnowledge/actionTypes';
import { Knowledge } from 'entities/knowledge/types';

export const LATEST_KNOWLEDGE_LIMIT = 14;

export function* latestKnowledgeSaga({ meta }: LatestKnowledgeAction) {
  try {
    const { offset, limit = LATEST_KNOWLEDGE_LIMIT, filters } = meta;
    const payload: Knowledge[] = yield apiFetch('monitor/segmentation/latest_knowledge', {
      queryParams: {
        limit: limit,
        offset: offset * limit,
        ...filters,
      },
    });
    yield put(fetchLatestKnowledgeSuccess(meta, payload));
  } catch (e) {
    yield put(fetchLatestKnowledgeFailure(meta));
  }
}
