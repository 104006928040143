import { useDispatch } from 'react-redux';

import { useTranslations } from 'domains/language/useTranslations';
import { activateModal } from 'domains/modal/actions';

export const LanguageButton = () => {
  const dispatch = useDispatch();
  const { textDictionary } = useTranslations();

  const onClick = () => dispatch(activateModal({ id: 'Change Language' }));

  return <div onClick={onClick}>{textDictionary['app.profile.menu.language']}</div>;
};
