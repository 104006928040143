import { styled } from 'styled-components';

import { CarouselSlider } from 'common/components/CarouselSlider';
import { CollectionTile } from 'common/components/collectionTile/CollectionTile';
import { SectionTitleUnderlined } from 'common/components/SectionTitleUnderlined';
import { mediaStyles } from 'common/styles';
import { CollectionViewModel } from 'domains/collection/types';

const StyledCarousel = styled.div`
  ${mediaStyles(({ spacing }) => `margin: 2rem ${spacing}rem 0;`)};
`;

const StyledCarouselSlider = styled(CarouselSlider)`
  padding-bottom: 30px;
`;

type CollectionCarouselProps = { collections: CollectionViewModel[]; header: string };

export const CollectionCarousel = ({ collections, header }: CollectionCarouselProps) => (
  <StyledCarousel>
    <SectionTitleUnderlined dataTestId={`collection-carousel-header`}>{header}</SectionTitleUnderlined>
    <StyledCarouselSlider numberOfItems={4} minItemWidth={320}>
      {collections.map((collection) => (
        <CollectionTile collection={collection} key={collection.id} />
      ))}
    </StyledCarouselSlider>
  </StyledCarousel>
);
