import { styled } from 'styled-components';

import { breakWordStyle, lineTruncationStyle } from 'common/styles';

export const StyledType = styled.div`
  font-size: 12px;
  margin-bottom: 8px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.clusterCaptionText};

  ${breakWordStyle}
  ${lineTruncationStyle(2)}
`;

export const StyledTitle = styled.div`
  margin-bottom: 8px;
  line-height: 1.4rem;

  ${breakWordStyle}
  ${lineTruncationStyle(2)}
`;

export const StyledContent = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  font-weight: bold;
`;
