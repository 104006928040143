import { styled } from 'styled-components';

export const TopHeaderItemBadge = styled.span`
  margin-left: auto;
  font-weight: bold;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
