import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css, styled } from 'styled-components';
import { v4 as createUuid, validate as isValidUuid } from 'uuid';

import { UIButton } from 'common/components';
import { ErrorMessage } from 'common/components/ErrorMessage';
import { Separator } from 'common/components/Separator';
import { breakpointValues } from 'common/styles';
import { createScrollbarStyle } from 'common/styles/createScrollbarStyle';
import { cssResets } from 'common/styles/cssResets';
import { LanguageCode } from 'common/types/languageCode';
import { unsafelyEntries, unsafelyFromEntries, unsafelyKeys } from 'common/utilities/object';
import { useConst } from 'common/utilities/useConst';
import { useTrack } from 'common/utilities/useTrack';
import { clearHeroImageUrl, updateCollection } from 'domains/collection/actions';
import {
  coBrandsEqual,
  translationsEqual,
  permissionsEqual,
  topicIdsEqual,
} from 'domains/collection/components/edit/helpers/validation';
import { BackgroundImageTab } from 'domains/collection/components/edit/parts/BackgroundImageTab';
import { CoCuratorTab } from 'domains/collection/components/edit/parts/CoCuratorTab';
import { CommunitiesTab } from 'domains/collection/components/edit/parts/CommunitiesTab';
import { PermissionSettingsTab } from 'domains/collection/components/edit/parts/PermissionSettingsTab';
import { SetTranslationsTab } from 'domains/collection/components/edit/parts/SetTranslationsTab';
import { TopicsTab } from 'domains/collection/components/edit/parts/TopicsTab';
import { VideoTab } from 'domains/collection/components/edit/parts/VideoTab';
import { Permissions } from 'domains/collection/components/edit/types/permissions';
import { getDisplayFields } from 'domains/collection/helpers/getDisplayFields';
import { CollectionEditModel, Community, UpdateCollectionPayload } from 'domains/collection/types';
import { Translation, TranslationType } from 'domains/collection/types/translation';
import { getSiteLanguage } from 'domains/language/selectors';
import { useTranslations } from 'domains/language/useTranslations';
import { activateModal } from 'domains/modal/actions';
import { useRouterNavigation } from 'domains/routing/utils';
import { getCanEditCommunities } from 'entities/user/selectors';

const StyledContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  min-height: 25.625rem;
  padding: 2rem;

  @media (max-width: ${breakpointValues.medium - 1}px) {
    padding: 1rem;
  }
`;

const StyledHeadContainer = styled.div`
  box-sizing: border-box;

  @media (max-width: ${600 - 1}px) {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
`;

const StyledHead = styled.section`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  gap: 1rem;
  min-width: 0;
  padding-right: calc(2rem + 3px);
  width: 100%;

  @media (max-width: ${600 - 1}px) {
    align-items: center;
    flex-direction: column;
  }

  @media (max-width: ${breakpointValues.medium - 1}px) {
    padding-right: calc(1rem + 3px);
  }

  @media (max-width: ${breakpointValues.large - 1}px) {
    justify-content: space-between;
  }
`;

const StyledBody = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding-right: 2rem;

  @media (max-width: ${breakpointValues.medium - 1}px) {
    padding-right: 1rem;
  }
`;

const StyledHeadLeftCell = styled.h1`
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.textBright};
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  min-width: 17rem;

  @media (max-width: ${600 - 1}px) {
    min-width: 0;
  }
`;

const StyledHeadRightCell = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  gap: 1rem;
  min-width: 0;
  width: 100%;

  @media (max-width: ${breakpointValues.large - 1}px) {
    width: auto;
  }
`;

const StyledHeadButtons = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  gap: 1rem;
`;

const StyledCancelButton = styled(UIButton)`
  background-color: ${({ theme }) => theme.colors.buttonNeutralActiveBackground};
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
  color: ${({ theme }) => theme.colors.collectionCancelButtonText};
  font-weight: bold;
`;

const StyledSaveButton = styled(UIButton)`
  background-color: ${({ theme }) => theme.colors.collectionSaveButton};
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
  font-weight: bold;
`;

const StyledErrorMessageTabletContainer = styled.div`
  box-sizing: border-box;
  padding-right: calc(2rem + 3px);

  @media (max-width: ${600 - 1}px) {
    width: fit-content;
  }

  @media (max-width: ${breakpointValues.medium - 1}px) {
    padding-right: calc(1rem + 3px);
  }
`;

const StyledErrorMessage = styled(ErrorMessage)`
  box-sizing: border-box;
  display: block;
  flex-grow: 1;
  width: 100%;

  div {
    margin: 0;
  }

  @media (max-width: ${breakpointValues.large - 1}px) {
    display: none;
  }
`;

const StyledErrorMessageTablet = styled(ErrorMessage)<{ isActive: boolean }>`
  box-sizing: border-box;
  display: none;
  flex-grow: 1;
  width: 100%;

  div {
    margin: 0;
  }

  @media (max-width: ${breakpointValues.large - 1}px) {
    display: block;
    margin-top: ${({ isActive }) => (isActive ? '1rem' : '0')};
  }
`;

const StyledTabs = styled.section`
  box-sizing: border-box;
  display: flex;
  gap: 2rem;

  @media (max-width: ${breakpointValues.medium - 1}px) {
    flex-direction: column;
  }
`;

const StyledTabsNav = styled.nav`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-width: 16rem;
`;

const StyledTab = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  min-width: 0;
  width: 100%;
`;

const StyledTabContent = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  min-width: 0;
  width: 100%;

  ${createScrollbarStyle()}
`;

const StyledTabButton = styled.button<{ error?: boolean; isSelected?: boolean }>`
  ${cssResets.button}
  background-color: ${({ isSelected, theme }) => (isSelected ? theme.colors.tabSelected : 'transparent')};
  border-radius: 0.625rem 1.25rem 1.25rem 0.625rem;
  box-shadow: ${({ isSelected }) => (isSelected ? '0 4px 10px 0 rgba(0, 0, 0, 0.05)' : 'none')};
  box-sizing: border-box;
  color: ${({ isSelected, theme }) => (isSelected ? theme.colors.textBright : theme.colors.tabUnselectedText)};
  font-size: 14px;
  font-weight: 900;
  padding: calc(0.75rem - 0.5px) 1.5rem;
  width: 100%;

  ${({ error, theme }) =>
    error
      ? css`
          border-color: ${theme.colors.error};
          box-shadow: inset 0 0 0 2px ${theme.colors.error};
        `
      : ''}

  &:hover {
    background-color: ${({ isSelected, theme }) => (isSelected ? theme.colors.tabSelected : theme.colors.tabHover)};
  }
`;

const StyledTabTitleRow = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  min-height: 2.5rem;

  @media (max-width: ${450 - 1}px) {
    flex-direction: column;
    height: auto;
  }
`;

const StyledTabTitle = styled.h2`
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.textBright};
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
`;

const StyledCoCuratorButton = styled(UIButton)`
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
`;

const StyledTabDescription = styled.p`
  ${cssResets.p}
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.textFade};
  font-size: 1rem;
  line-height: 1.5;
`;

type CollectionUpdateProps = { collection?: CollectionEditModel };

export const CreateOrEditCollection = ({ collection }: CollectionUpdateProps) => {
  const id = useConst(() => (collection === undefined || !isValidUuid(collection.id) ? createUuid() : collection.id));
  const dispatch = useDispatch();
  const navigateToCreateCollection = useRouterNavigation({ to: 'create', params: { mode: 'collection' } });
  const siteLanguage = useSelector(getSiteLanguage);
  const canEditCommunities = useSelector(getCanEditCommunities);
  const [coCurators, setCoCurators] = useState<Community[]>(collection?.coBrands ?? []);
  const [description, setDescription] = useState<Required<Translation>>(initialiseTranslation(collection?.description));
  const [featuredVideoUrl, setFeaturedVideoUrl] = useState<string | undefined>(collection?.featuredVideoUrl);
  const [heroImageUrl, setHeroImageUrl] = useState<string | undefined>(collection?.heroImageUrl);
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [name, setName] = useState<Required<Translation>>(initialiseTranslation(collection?.name));
  const [permissions, setPermissions] = useState<Permissions>(
    collection === undefined ? defaultPermissions : extractPermissions(collection),
  );
  const [spacesUrl, setSpacesUrl] = useState<string | undefined>(collection?.spacesUrl);
  const [topicIds, setTopicIds] = useState<string[]>(collection?.topicIds ?? []);
  const [tab, setTab] = useState<Tab>(Tab.SetTranslations);
  const { textDictionary } = useTranslations();

  const initialStateRef = useRef({
    coCurators,
    description,
    featuredVideoUrl,
    heroImageUrl,
    name,
    permissions,
    spacesUrl,
    topicIds,
  });

  const navigateToViewCollection = useRouterNavigation({ to: 'collection', id });

  useTrack({
    name: 'Browse page',
    page: 'Collection Edit',
    entityId: id,
    entityName: collection === undefined ? '[New Collection]' : getDisplayFields(collection, siteLanguage).name,
    entityType: 'Collection',
  });

  useEffect(() => {
    dispatch(clearHeroImageUrl());
  }, [dispatch]);

  const isEqual = () =>
    coBrandsEqual(initialStateRef.current.coCurators, coCurators) &&
    translationsEqual(initialStateRef.current.description, description) &&
    initialStateRef.current.featuredVideoUrl === featuredVideoUrl &&
    initialStateRef.current.heroImageUrl === heroImageUrl &&
    translationsEqual(initialStateRef.current.name, name) &&
    permissionsEqual(initialStateRef.current.permissions, permissions) &&
    initialStateRef.current.spacesUrl === spacesUrl &&
    topicIdsEqual(initialStateRef.current.topicIds, topicIds);

  const collectionLanguage = collection?.defaultLanguage ?? siteLanguage;
  const isHeroImageValid = heroImageUrl !== undefined;
  const isDefaultNameValid = name[collectionLanguage].text.length > 0;
  const invalidDescriptions = unsafelyEntries(description).reduce<LanguageCode[]>(
    (accumulator, [key, value]) =>
      value.text.length > 0 && name[key].text.length === 0 ? [...accumulator, key] : accumulator,
    [],
  );

  const errorsExist = !isDefaultNameValid || !isHeroImageValid || invalidDescriptions.length > 0;

  const updateCoBrands = (coCurator: Community) => setCoCurators((value) => [...value, coCurator]);

  const onClickCreate = () =>
    dispatch(activateModal({ id: 'Collection Create Co-Curator', params: { updateCoBrands } }));

  const onClickCancel = () =>
    isEqual() ? navigateToCreateCollection() : dispatch(activateModal({ id: 'Collection Cancellation Confirmation' }));

  const onClickSave = () => {
    if (errorsExist) {
      setShowErrors(true);
      return;
    }

    const payload: UpdateCollectionPayload = {
      ...formatNameAndDescription(name, description),
      co_brands: coCurators,
      default_language: collectionLanguage,
      featured_video_url: featuredVideoUrl ?? null,
      hero_image_url: heroImageUrl,
      id,
      is_featured: permissions.isFeatured,
      is_listed: permissions.isListed,
      is_public: permissions.isPublic,
      spaces_url: spacesUrl ?? null,
      topic_ids: topicIds,
    };

    if (!isEqual()) dispatch(updateCollection(payload, siteLanguage));

    navigateToViewCollection();
  };

  const errorMessage =
    (!isDefaultNameValid || invalidDescriptions.length > 0) && !isHeroImageValid
      ? 'fields_are_mandatory'
      : 'field_is_mandatory';

  return (
    <StyledContainer>
      <StyledHeadContainer>
        <StyledHead>
          <StyledHeadLeftCell>
            {textDictionary[`app.collection.${collection === undefined ? 'create_collection' : 'edit_collection'}`]}
          </StyledHeadLeftCell>
          <StyledHeadRightCell>
            <StyledErrorMessage applyClassToWrapper isActive={showErrors && errorsExist}>
              {textDictionary[`app.collection.${errorMessage}`]}
            </StyledErrorMessage>
            <StyledHeadButtons>
              <StyledCancelButton onClick={onClickCancel} variant="neutral">
                {textDictionary['app.create.collection.cancel']}
              </StyledCancelButton>
              <StyledSaveButton onClick={onClickSave}>{textDictionary['app.create.collection.save']}</StyledSaveButton>
            </StyledHeadButtons>
          </StyledHeadRightCell>
        </StyledHead>
        <StyledErrorMessageTabletContainer>
          <StyledErrorMessageTablet applyClassToWrapper isActive={showErrors && errorsExist}>
            {textDictionary[`app.collection.${errorMessage}`]}
          </StyledErrorMessageTablet>
        </StyledErrorMessageTabletContainer>
      </StyledHeadContainer>
      <Separator />
      <StyledBody>
        <StyledTabs>
          <StyledTabsNav>
            <StyledTabButton
              error={showErrors && (!isDefaultNameValid || invalidDescriptions.length > 0)}
              isSelected={tab === Tab.SetTranslations}
              onClick={() => setTab(Tab.SetTranslations)}
            >
              {textDictionary['app.collection.set_translations']}
            </StyledTabButton>
            <StyledTabButton
              error={showErrors && !isHeroImageValid}
              isSelected={tab === Tab.BackgroundImage}
              onClick={() => setTab(Tab.BackgroundImage)}
            >
              {textDictionary['app.collection.background_image']}
            </StyledTabButton>
            <StyledTabButton isSelected={tab === Tab.Topics} onClick={() => setTab(Tab.Topics)}>
              {textDictionary['app.collection.topics']}
            </StyledTabButton>
            <StyledTabButton isSelected={tab === Tab.PermissionSettings} onClick={() => setTab(Tab.PermissionSettings)}>
              {textDictionary['app.collection.permission_settings']}
            </StyledTabButton>
            {canEditCommunities && (
              <>
                <StyledTabButton isSelected={tab === Tab.CoCurator} onClick={() => setTab(Tab.CoCurator)}>
                  {textDictionary['app.collection.co_curator']}
                </StyledTabButton>
                <StyledTabButton isSelected={tab === Tab.Communities} onClick={() => setTab(Tab.Communities)}>
                  {textDictionary['app.collection.communities']}
                </StyledTabButton>
                <StyledTabButton isSelected={tab === Tab.Video} onClick={() => setTab(Tab.Video)}>
                  {textDictionary['app.collection.video']}
                </StyledTabButton>
              </>
            )}
          </StyledTabsNav>
          <StyledTab>
            <StyledTabTitleRow>
              <StyledTabTitle>{textDictionary[`app.collection.${tabLookup[tab]}`]}</StyledTabTitle>
              {tab === Tab.CoCurator && (
                <StyledCoCuratorButton onClick={onClickCreate}>
                  {textDictionary['app.create.collection.modal.button.create_co_curator']}
                </StyledCoCuratorButton>
              )}
            </StyledTabTitleRow>
            <StyledTabDescription>
              {textDictionary[`app.collection.${tabLookup[tab]}_description`]}
            </StyledTabDescription>
            <StyledTabContent>
              {tab === Tab.BackgroundImage ? (
                <BackgroundImageTab
                  error={showErrors && !isHeroImageValid}
                  image={heroImageUrl}
                  setImage={setHeroImageUrl}
                />
              ) : tab === Tab.CoCurator ? (
                <CoCuratorTab coCurators={coCurators} setCoCurators={setCoCurators} />
              ) : tab === Tab.PermissionSettings ? (
                <PermissionSettingsTab id={id} permissions={permissions} setPermissions={setPermissions} />
              ) : tab === Tab.SetTranslations ? (
                <SetTranslationsTab
                  collectionLanguage={collectionLanguage}
                  description={description}
                  invalidDescriptions={invalidDescriptions}
                  name={name}
                  setDescription={setDescription}
                  setName={setName}
                  showError={showErrors}
                />
              ) : tab === Tab.Topics ? (
                <TopicsTab setTopicIds={setTopicIds} topicIds={topicIds} />
              ) : tab === Tab.Communities ? (
                <CommunitiesTab setSpacesUrl={setSpacesUrl} spacesUrl={spacesUrl} />
              ) : (
                <VideoTab setFeaturedVideoUrl={setFeaturedVideoUrl} featuredVideoUrl={featuredVideoUrl} />
              )}
            </StyledTabContent>
          </StyledTab>
        </StyledTabs>
      </StyledBody>
    </StyledContainer>
  );
};

enum Tab {
  BackgroundImage = 'BackgroundImage',
  CoCurator = 'CoCurator',
  Communities = 'Communities',
  PermissionSettings = 'PermissionSettings',
  SetTranslations = 'SetTranslations',
  Topics = 'Topics',
  Video = 'Video',
}

const tabLookup: Record<Tab, string> = {
  [Tab.BackgroundImage]: 'background_image',
  [Tab.CoCurator]: 'co_curator',
  [Tab.Communities]: 'communities',
  [Tab.PermissionSettings]: 'permission_settings',
  [Tab.SetTranslations]: 'set_translations',
  [Tab.Topics]: 'topics',
  [Tab.Video]: 'video',
};

const initialiseTranslation = (model: Translation = {}) => {
  const state = unsafelyFromEntries(
    Object.values(LanguageCode).map((value) => [value, { type: TranslationType.MANUAL, text: '' }]),
  );
  for (const untypedKey in state) {
    const key = untypedKey as keyof typeof state;
    const item = model[key];
    if (item !== undefined) state[key] = item;
  }
  return state;
};

const defaultPermissions = { isFeatured: false, isListed: false, isPublic: false };
const extractPermissions = ({ isFeatured, isListed, isPublic }: CollectionEditModel) => ({
  isFeatured,
  isListed,
  isPublic,
});

const formatNameAndDescription = (name: Required<Translation>, description: Required<Translation>) =>
  unsafelyKeys(name).reduce<{ description: Translation; name: Translation }>(
    (accumulator, next) => {
      if (name[next].text.length === 0) return accumulator;
      accumulator.description[next] = description[next];
      accumulator.name[next] = name[next];
      return accumulator;
    },
    { description: {}, name: {} },
  );
