import { SVGProps } from 'react';

export const SettingsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" {...props}>
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="M18.433 18.43h-.001c-.638.638-1.087 1.372-1.3 2.213a4.836 4.836 0 0 0-.095 1.812c-.282.13-.57.25-.865.359-1.014-1.28-2.548-2.124-4.172-2.124-1.625 0-3.16.843-4.173 2.124a11.606 11.606 0 0 1-.865-.358 4.833 4.833 0 0 0-.095-1.814c-.214-.84-.663-1.573-1.3-2.211-.637-.638-1.37-1.087-2.211-1.3a4.843 4.843 0 0 0-1.814-.094c-.13-.282-.249-.571-.357-.865 1.277-1.015 2.122-2.548 2.122-4.173 0-1.676-.887-2.98-2.117-3.9.108-.295.228-.584.359-.867 1.639.145 2.975-.62 4.017-1.665.64-.637 1.089-1.37 1.302-2.21a4.838 4.838 0 0 0 .094-1.816c.282-.13.57-.25.864-.357 1.014 1.278 2.546 2.123 4.173 2.123 1.624 0 3.158-.845 4.171-2.123.294.108.583.227.865.357a4.841 4.841 0 0 0 .095 1.815c.213.841.662 1.574 1.299 2.21 1.043 1.045 2.38 1.81 4.018 1.666.13.283.25.572.36.868-1.232.919-2.12 2.223-2.12 3.9 0 1.674.89 2.979 2.123 3.898-.109.296-.229.585-.36.868-1.638-.143-2.974.622-4.017 1.664Z"
    />
    <circle cx="12" cy="12" r="4" stroke="currentColor" strokeWidth="2" />
  </svg>
);
