import { combineReducers } from 'redux';

import { LegacyTopicActionTypes, TopicAction } from 'domains/topic/actionTypes';
import { TopicState } from 'domains/topic/types';

const initialState: TopicState = {
  isBriefingDownloading: false,
  isPresentationDownloading: false,
  surveyLink: null,
};

const surveyLink = (state = initialState.surveyLink, action: TopicAction): TopicState['surveyLink'] => {
  switch (action.type) {
    case LegacyTopicActionTypes.FETCH_SURVEY_LINK_SUCCESS:
      return action.payload;

    case LegacyTopicActionTypes.FETCH_SURVEY_LINK:
    case LegacyTopicActionTypes.FETCH_SURVEY_LINK_FAILURE:
      return initialState.surveyLink;

    default:
      return state;
  }
};

const isBriefingDownloading = (
  state = initialState.isBriefingDownloading,
  action: TopicAction,
): TopicState['isBriefingDownloading'] => {
  switch (action.type) {
    case LegacyTopicActionTypes.FETCH_BRIEFING:
      return true;

    case LegacyTopicActionTypes.FETCH_BRIEFING_SUCCESS:
    case LegacyTopicActionTypes.FETCH_BRIEFING_FAILURE:
      return false;

    default:
      return state;
  }
};

const isPresentationDownloading = (
  state = initialState.isPresentationDownloading,
  action: TopicAction,
): TopicState['isPresentationDownloading'] => {
  switch (action.type) {
    case LegacyTopicActionTypes.FETCH_PRESENTATION:
      return true;

    case LegacyTopicActionTypes.FETCH_PRESENTATION_FULFILLED:
    case LegacyTopicActionTypes.FETCH_PRESENTATION_REJECTED:
      return false;

    default:
      return state;
  }
};

export const topicReducer = combineReducers({
  isBriefingDownloading,
  isPresentationDownloading,
  surveyLink,
});
