import { SVGProps } from 'react';

export const SurveyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <g fill="currentColor">
      <path d="M37.994 4.01H33.81a2.995 2.995 0 00-2.814-2.002h-3.012a4.948 4.948 0 00-7.96 0H17.01a2.995 2.995 0 00-2.816 1.999H10a4.004 4.004 0 00-4 4.002V44a4.004 4.004 0 004 4h27.994a4.004 4.004 0 004-4V8.01a4.004 4.004 0 00-4-4zm-21.996 1a1 1 0 011-1h3.554a1.003 1.003 0 00.861-.498 2.966 2.966 0 015.164 0 1.002 1.002 0 00.86.497h3.554a1 1 0 011 1V6.01a2.003 2.003 0 01-1.998 1.999H17.997a2.003 2.003 0 01-2-1.999v-1zM39.993 44a2.003 2.003 0 01-1.999 2H10a2.003 2.003 0 01-2-2V8.01a2.003 2.003 0 012-1.999h4a4.004 4.004 0 003.999 4h11.998a4.003 4.003 0 004-4h4a2.003 2.003 0 011.998 1.999V44h-.002z" />
      <path d="M16.998 16.006h-4a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1v-4a.997.997 0 00-1-1zm-1 4h-1.999v-1.993h1.999v1.992zM16.998 26.003h-4a.997.997 0 00-1 1v4a1 1 0 001 1h4a.997.997 0 001-1v-3.995a.998.998 0 00-1-1.005zm-1 4h-1.999v-1.999h1.999v1.999zM16.998 36.004h-4a1 1 0 00-1 1v4a1 1 0 001 1h4a.997.997 0 001-1v-4a.997.997 0 00-1-1zm-1 3.999h-1.999v-1.999h1.999v1.999zM23.702 18.3a.997.997 0 00-1.413 0l-.293.292-.293-.293a1 1 0 00-1.413 1.414l.293.292-.293.293a1 1 0 101.413 1.413l.293-.292.293.292a1 1 0 101.413-1.413l-.292-.293.292-.292a1 1 0 000-1.414zM29.7 18.3a.998.998 0 00-1.413 0l-.293.292-.292-.293a1 1 0 00-1.414 1.414l.293.292-.293.293a1.001 1.001 0 101.414 1.413l.292-.292.293.292a1 1 0 101.414-1.413l-.293-.293.293-.292a1 1 0 000-1.414zM23.702 28.29a.997.997 0 00-1.413 0l-.293.293-.293-.292a1 1 0 00-1.413 1.413l.293.293-.293.292a1 1 0 101.413 1.414l.293-.293.293.293a1.001 1.001 0 101.413-1.414l-.292-.292.292-.293a.997.997 0 000-1.413zM29.7 28.29a.998.998 0 00-1.413 0l-.293.293-.292-.292a1 1 0 00-1.414 1.413l.293.293-.293.292a1.001 1.001 0 101.414 1.414l.292-.293.293.293a1.002 1.002 0 101.414-1.414l-.293-.292.293-.293a.999.999 0 000-1.413zM23.702 38.295a.999.999 0 00-1.413 0l-.293.292-.293-.292a1 1 0 10-1.413 1.413l.293.293-.293.293a.998.998 0 00-.031 1.444 1 1 0 001.444-.031l.293-.293.293.293a1 1 0 101.413-1.413L23.41 40l.292-.293a.996.996 0 000-1.413zM35.7 18.3a.998.998 0 00-1.413 0l-.292.292-.293-.293a1 1 0 00-1.413 1.414l.292.292-.292.293a1.002 1.002 0 00.303 1.665 1 1 0 001.11-.252l.293-.292.292.292a1 1 0 101.414-1.413l-.293-.293.293-.292a1 1 0 000-1.414z" />
    </g>
  </svg>
);
