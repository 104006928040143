import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { styled } from 'styled-components';

import { Spinner } from 'common/components';
import { TOPICS_FOR_COLLECTION_LIMIT } from 'common/constants/limit';
import { Status } from 'common/types/status';
import { replaceHistory } from 'common/utilities';
import { trackEvent } from 'domains/analytics/actions';
import { clearSingle, fetchCollectionForViewing } from 'domains/collection/actions';
import { CollectionPage } from 'domains/collection/components/CollectionPage';
import { isCollectionClientSide, selectCollections } from 'domains/collection/selectors';
import { getSiteLanguage } from 'domains/language/selectors';
import { activateModal } from 'domains/modal/actions';
import { fetchTopicsForCollection } from 'entities/topics/actions';
import { getTopics } from 'entities/topics/selectors';

const StyledSpinner = styled(Spinner)`
  // this is to center the spinner (half the screen - half the spinners width)
  margin-left: calc(50% - 32px);
  margin-top: 10rem;
  color: ${({ theme }) => theme.colors.fillPrimary};
`;

type RouteParams = { collectionId: string };

export const LoadCollectionPage = () => {
  const dispatch = useDispatch();
  const { collectionId } = useParams<RouteParams>();
  const { forViewing } = useSelector(selectCollections);
  const siteLanguage = useSelector(getSiteLanguage);
  const isClientSide = useSelector(isCollectionClientSide(collectionId));

  const noCollection = typeof forViewing === 'string';
  const offset = noCollection ? 0 : forViewing.topics.offset;
  const topics = useSelector(getTopics(noCollection ? [] : forViewing.topic_ids, offset));

  const fetchTopics = useCallback(
    (isInvalidating = false) =>
      dispatch(
        fetchTopicsForCollection({
          id: collectionId,
          limit: TOPICS_FOR_COLLECTION_LIMIT,
          offset: isInvalidating ? 0 : offset,
        }),
      ),
    [collectionId, dispatch, offset],
  );

  useEffect(() => {
    dispatch(fetchCollectionForViewing(collectionId));
  }, [collectionId, dispatch, siteLanguage]);

  useEffect(() => {
    if (!isClientSide && forViewing === Status.Ready) fetchTopics(true);
  }, [fetchTopics, forViewing, isClientSide, siteLanguage]);

  useEffect(() => {
    if (forViewing !== Status.Error) return;
    dispatch(clearSingle());
    dispatch(
      activateModal({ id: 'Error', params: { localisedMessageKey: 'app.collection.unavailable.error_message' } }),
    );
    replaceHistory('/create?mode=collection');
  }, [dispatch, forViewing]);

  const entityName = noCollection ? undefined : forViewing.name;
  const entityId = noCollection ? undefined : forViewing.id;

  useEffect(() => {
    if (collectionId !== entityId) return;
    dispatch(
      trackEvent({
        name: 'Browse page',
        page: 'Collection',
        entityId,
        entityName,
        entityType: 'Collection',
      }),
    );
  }, [collectionId, dispatch, entityId, entityName]);

  return noCollection ? (
    <StyledSpinner />
  ) : (
    <CollectionPage collection={forViewing} topics={topics} loadNextPage={fetchTopics} />
  );
};
