import { Topic } from 'entities/topics/types';

export enum SuggestionsActionTypes {
  SUGGESTIONS_FETCH = 'domains/SUGGESTIONS_FETCH',
  SUGGESTIONS_FETCH_FULFILLED = 'domains/SUGGESTIONS_FETCH_FULFILLED',
  SUGGESTIONS_FETCH_REJECTED = 'domains/SUGGESTIONS_FETCH_REJECTED',
}

export type SuggestionsFetchAction = { type: SuggestionsActionTypes.SUGGESTIONS_FETCH };

export type SuggestionsFetchFulfilledAction = {
  payload: Topic[];
  type: SuggestionsActionTypes.SUGGESTIONS_FETCH_FULFILLED;
};

export type SuggestionsFetchRejectedAction = { type: SuggestionsActionTypes.SUGGESTIONS_FETCH_REJECTED };

export type SuggestionsAction =
  | SuggestionsFetchAction
  | SuggestionsFetchFulfilledAction
  | SuggestionsFetchRejectedAction;
