import debounce from 'lodash.debounce';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { StyledGrid, InfinityLoader, SectionTitleUnderlined, Spinner } from 'common/components';
import { UIInput } from 'common/components/form/UIInput';
import { breakpoints, mediaStyles } from 'common/styles';
import { fetchFilteredTopics } from 'domains/filteredTopicList/actions';
import {
  getFilteredTopics,
  getFilteredTopicsAreFetching,
  getFilteredTopicsIsPossibleNextPage,
} from 'domains/filteredTopicList/selectors';
import { getSiteLanguage } from 'domains/language/selectors';
import { useTranslations } from 'domains/language/useTranslations';
import { TopicTile } from 'domains/topic/components/topicTile/TopicTile';

const StyledInnerContainer = styled.div`
  ${mediaStyles(({ spacing }) => `margin: 0 ${spacing}rem ${spacing}rem ${spacing}rem;`)};
`;

const StyledUIInput = styled(UIInput)`
  @media ${breakpoints.medium} {
    width: fit-content;
  }
`;

const StyledSpinner = styled(Spinner)`
  margin: 3rem auto;
  display: block;
  color: ${({ theme }) => theme.colors.textBright};
`;

const StyledNoResultsText = styled.div`
  color: ${({ theme }) => theme.colors.textBright};
`;

export const SearchableTopicList = () => {
  const dispatch = useDispatch();
  const { textDictionary } = useTranslations();
  const topics = useSelector(getFilteredTopics);
  const selectedLanguage = useSelector(getSiteLanguage);
  const isFetching = useSelector(getFilteredTopicsAreFetching);
  const [filterTerm, setFilterTerm] = useState('');
  const isPossibleNextPage = useSelector(getFilteredTopicsIsPossibleNextPage);

  useEffect(() => {
    fetchTopics(filterTerm, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  const fetchTopics = (term: string, isInvalidating = false) => {
    const offset = isInvalidating ? 0 : topics.length;
    if (!isFetching) {
      dispatch(
        fetchFilteredTopics({
          limit: 6 * 8,
          offset,
          filterTerm: term,
        }),
      );
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedTopicsSearch = useCallback(
    debounce((term) => {
      fetchTopics(term, true);
    }, 500),
    [],
  );

  const onInput = (value: string) => {
    setFilterTerm(value);
    debouncedTopicsSearch(value);
  };

  const onClear = () => {
    setFilterTerm('');
    debouncedTopicsSearch('');
  };

  return (
    <>
      <StyledInnerContainer data-test-id={'searchable-topic-list-topics'}>
        <SectionTitleUnderlined
          dataTestId={'all-topics-section-title'}
          rightElement={
            <StyledUIInput
              clear={onClear}
              onChange={onInput}
              placeholder={textDictionary['app.search.placeholder']}
              value={filterTerm}
            />
          }
        >
          {textDictionary['app.topic.all_topics']}
        </SectionTitleUnderlined>
        {isFetching && topics.length === 0 && <StyledSpinner />}
        {topics.length !== 0 && (
          <InfinityLoader
            isPending={isFetching}
            loadNextPage={() => fetchTopics(filterTerm, false)}
            hasNextPage={isPossibleNextPage}
          >
            <StyledGrid>
              {topics.map((topic) => (
                <TopicTile key={topic.id} {...topic} />
              ))}
            </StyledGrid>
          </InfinityLoader>
        )}
        {topics.length === 0 && !isFetching && (
          <StyledNoResultsText>{textDictionary['app.search.results.no_results']}</StyledNoResultsText>
        )}
      </StyledInnerContainer>
    </>
  );
};
