import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { fetchCoachmarksFailure, fetchCoachmarksSuccess } from 'entities/coachmarks/actions';
import { Coachmark } from 'entities/coachmarks/types';
import { isFetchError } from 'TypeCheckMethods';

type CoachmarksPayload = { manifest: { stories: Coachmark[] } };

export function* fetchCoachmarksSaga() {
  try {
    const { manifest }: CoachmarksPayload = yield apiFetch('coachmark');
    yield put(
      fetchCoachmarksSuccess({
        coachmarks: manifest?.stories || [],
      }),
    );
  } catch (error) {
    if (isFetchError(error)) {
      yield put(fetchCoachmarksFailure({ message: error?.message }));
    }
  }
}
