import {
  StakeholderActionTypes,
  StakeholdersFetchForTopicAction,
  StakeholdersFetchForTopicFailureAction,
  StakeholdersFetchForTopicSuccessAction,
  FetchStakeholderForIdAction,
  FetchStakeholderForIdFulfilledAction,
  FetchStakeholderForIdRejectedAction,
} from 'entities/stakeholders/actionTypes';

export const fetchStakeholdersForTopic = (
  payload: StakeholdersFetchForTopicAction['payload'],
): StakeholdersFetchForTopicAction => ({
  type: StakeholderActionTypes.FETCH_FOR_TOPIC,
  payload,
});

export const fetchStakeholdersForTopicSuccess = (
  payload: StakeholdersFetchForTopicSuccessAction['payload'],
  meta: StakeholdersFetchForTopicSuccessAction['meta'],
): StakeholdersFetchForTopicSuccessAction => ({
  type: StakeholderActionTypes.FETCH_FOR_TOPIC_SUCCESS,
  payload,
  meta,
});

export const fetchStakeholdersForTopicFailure = (
  payload: StakeholdersFetchForTopicFailureAction['payload'],
  meta: StakeholdersFetchForTopicFailureAction['meta'],
): StakeholdersFetchForTopicFailureAction => ({
  type: StakeholderActionTypes.FETCH_FOR_TOPIC_FAILURE,
  payload,
  meta,
});

export const fetchStakeholderById = (meta: FetchStakeholderForIdAction['meta']): FetchStakeholderForIdAction => ({
  type: StakeholderActionTypes.FETCH_FOR_ID,
  meta,
});

export const fetchStakeholderByIdSuccessful = (
  payload: FetchStakeholderForIdFulfilledAction['payload'],
  meta: FetchStakeholderForIdFulfilledAction['meta'],
): FetchStakeholderForIdFulfilledAction => ({
  type: StakeholderActionTypes.FETCH_FOR_ID_SUCCESS,
  payload,
  meta,
});

export const fetchStakeholderByIdFailure = (
  payload: FetchStakeholderForIdRejectedAction['payload'],
  meta: FetchStakeholderForIdRejectedAction['meta'],
): FetchStakeholderForIdRejectedAction => ({
  type: StakeholderActionTypes.FETCH_FOR_ID_FAILURE,
  payload,
  meta,
});
