import { ErrorMeta } from 'types';

export enum BriefingActionTypes {
  TRIGGER_GENERATE_BRIEFING = '[Briefing] TOPIC_TRIGGER_GENERATE_BRIEFING',
  TRIGGER_GENERATE_BRIEFING_FAILURE = '[Briefing] TOPIC_TRIGGER_GENERATE_BRIEFING_FAILURE',
  TRIGGER_GENERATE_BRIEFING_SUCCESS = '[Briefing] TOPIC_TRIGGER_GENERATE_BRIEFING_SUCCESS',
  MONITOR_PROGRESS_GENERATE_BRIEFING = '[Briefing] MONITOR_PROGRESS_GENERATE_BRIEFING',
  MONITOR_PROGRESS_GENERATE_BRIEFING_SUCCESS = '[Briefing] MONITOR_PROGRESS_GENERATE_BRIEFING_SUCCESS',
  MONITOR_PROGRESS_GENERATE_BRIEFING_FAILURE = '[Briefing] MONITOR_PROGRESS_GENERATE_BRIEFING_FAILURE',
  FETCH_BRIEFING = '[Briefing] FETCH_BRIEFING',
  FETCH_BRIEFING_SUCCESS = '[Briefing] FETCH_BRIEFING_SUCCESS',
  FETCH_BRIEFING_FAILURE = '[Briefing] FETCH_BRIEFING_FAILURE',
  REMOVE_BRIEFING = '[Briefing] REMOVE_BRIEFING',
}

export enum GenerateBriefingButtonActionTypes {
  DISABLE_GENERATE_BUTTON = '[Briefing] DISABLE_GENERATE_BUTTON',
  ENABLE_GENERATE_BUTTON = '[Briefing] ENABLE_GENERATE_BUTTON',
}

type DisableGenerateButtonAction = { type: GenerateBriefingButtonActionTypes.DISABLE_GENERATE_BUTTON };
type EnableGenerateButtonAction = { type: GenerateBriefingButtonActionTypes.ENABLE_GENERATE_BUTTON };
export type FetchBriefingAction = { payload: FetchBriefingPayload; type: BriefingActionTypes.FETCH_BRIEFING };
type FetchBriefingSuccessAction = { payload: FetchBriefingPayload; type: BriefingActionTypes.FETCH_BRIEFING_SUCCESS };

type FetchBriefingFailureAction = {
  meta: ErrorMeta;
  payload: BriefingFailurePayload;
  type: BriefingActionTypes.FETCH_BRIEFING_FAILURE;
};

type RemoveBriefingAction = { payload: { briefingId: string }; type: BriefingActionTypes.REMOVE_BRIEFING };
export type FetchBriefingPayload = { briefingId: string; templateName: string; topicName: string };

export type TriggerGenerateBriefingPayload = {
  options: { foresight: boolean; furtherReading: boolean };
  topicId: string;
  topicName: string;
};

export type TriggerGenerateBriefingSuccessPayload = {
  briefingId: string;
  content: { furtherReading: boolean };
  progress: number;
  status: 'Pending';
  templateName: string;
  topicId: string;
  topicName: string;
};

export type MonitorProgressGenerateBriefingSuccessPayload = {
  briefingId: string;
  progress: number;
  templateName: string;
};

export type BriefingFailurePayload = { briefingId: string };
type MonitorProgressGenerateBriefingPayload = { briefingId: string; templateName: string; topicName: string };

export type MonitorProgressGenerateBriefingAction = {
  payload: MonitorProgressGenerateBriefingPayload;
  type: BriefingActionTypes.MONITOR_PROGRESS_GENERATE_BRIEFING;
};

export type TriggerGenerateBriefing = {
  payload: TriggerGenerateBriefingPayload;
  type: BriefingActionTypes.TRIGGER_GENERATE_BRIEFING;
};

type TriggerGenerateBriefingSuccessAction = {
  payload: TriggerGenerateBriefingSuccessPayload;
  type: BriefingActionTypes.TRIGGER_GENERATE_BRIEFING_SUCCESS;
};

type TriggerGenerateBriefingFailureAction = {
  meta: ErrorMeta;
  payload: { error: any; briefingId: string };
  type: BriefingActionTypes.TRIGGER_GENERATE_BRIEFING_FAILURE;
};

type MonitorProgressGenerateBriefingSuccess = {
  payload: TriggerGenerateBriefingSuccessPayload;
  type: BriefingActionTypes.MONITOR_PROGRESS_GENERATE_BRIEFING_SUCCESS;
};

type MonitorProgressGenerateBriefingFailureAction = {
  meta: ErrorMeta;
  payload: BriefingFailurePayload;
  type: BriefingActionTypes.MONITOR_PROGRESS_GENERATE_BRIEFING_FAILURE;
};

export type GenerateBriefingActions =
  | TriggerGenerateBriefingSuccessAction
  | TriggerGenerateBriefingFailureAction
  | MonitorProgressGenerateBriefingSuccess
  | MonitorProgressGenerateBriefingFailureAction
  | FetchBriefingAction
  | FetchBriefingSuccessAction
  | FetchBriefingFailureAction
  | RemoveBriefingAction;

export type GenerateBriefingButtonActions = DisableGenerateButtonAction | EnableGenerateButtonAction;
