import debounce from 'lodash.debounce';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { css, styled } from 'styled-components';

const StyledWrapper = styled.div<{ isReady?: boolean }>`
  overflow: hidden;
  ${({ isReady }) =>
    isReady &&
    css`
      transition: max-height 0.3s ease;
    `};
`;

type ExpandableContentProps = PropsWithChildren & { className?: string; collapsedHeight?: string; isActive?: boolean };

export const ExpandableContent = ({
  children,
  className,
  collapsedHeight = '0px',
  isActive,
}: ExpandableContentProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(isActive ? 'initial' : collapsedHeight);
  const isActiveRef = useRef(isActive);
  isActiveRef.current = isActive;

  const _getWrapperStyle = () => ({
    maxHeight: height,
  });

  useEffect(() => {
    if (collapsedHeight && !isActive) {
      setHeight(collapsedHeight);
    }
  }, [collapsedHeight, isActive]);

  useEffect(() => {
    const newHeight = isActiveRef.current ? 'initial' : collapsedHeight;
    if (newHeight !== height) {
      if (contentRef.current) {
        setHeight(`${contentRef.current.scrollHeight}px`);
      }
      const delay = isActiveRef.current && contentRef.current ? 300 : 100;

      debounce(() => {
        const newHeight = isActiveRef.current ? 'initial' : collapsedHeight;

        setHeight(newHeight);
      }, delay)();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  return (
    <StyledWrapper style={_getWrapperStyle()} className={className} isReady={!!contentRef.current}>
      <div ref={contentRef}>{children}</div>
    </StyledWrapper>
  );
};
