import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, RouteProps } from 'react-router-dom';

import { replaceHistory } from 'common/utilities';
import { getIsAuthenticated } from 'domains/authentication/selectors';
import { useAuth } from 'useAuth';

type PrivateRouteProps = RouteProps & { canAccess: boolean };

export const PrivateRoute = ({ canAccess, children, render, ...rest }: PrivateRouteProps) =>
  canAccess ? (
    <Route {...rest} render={render}>
      {children}
    </Route>
  ) : (
    <Route {...rest}>
      <ForbiddenComponent />
    </Route>
  );

const ForbiddenComponent = () => {
  const { triggerLogin } = useAuth();
  const isAuthenticated = useSelector(getIsAuthenticated);
  useEffect(() => (isAuthenticated ? replaceHistory('/') : triggerLogin()), [isAuthenticated, triggerLogin]);
  return null;
};
