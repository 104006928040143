import { PropsWithChildren, useState } from 'react';
import { styled } from 'styled-components';

import { ExpandableContent, MarkdownArea } from 'common/components';
import { CustomKnowledge } from 'domains/customContentAndSources/types';
import { useTranslations } from 'domains/language/useTranslations';
import { KnowledgeButtons } from 'domains/publication/components/KnowledgeButtons';
import { KnowledgeSignalSection } from 'domains/signals/components/KnowledgeSignalSection';
import { Knowledge } from 'entities/knowledge/types';

const StyledListLink = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledFooterRow = styled.div`
  margin-top: 10px;
`;

const StyledDescriptionContainer = styled.div`
  padding-top: 10px;
  width: 100%;
  border-top: ${({ theme }) => theme.borders.normal};
`;

const StyledDescription = styled.div`
  margin: 0;
  line-height: 1.5rem;
`;

const StyledTextContent = styled.div`
  padding-top: 10px;
`;

type ExpandableKnowledgeItemWrapperProps = PropsWithChildren & {
  knowledge: Knowledge | CustomKnowledge;
  link?: string;
};

export const ExpandableKnowledgeItemWrapper = ({ children, knowledge, link }: ExpandableKnowledgeItemWrapperProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpansion = () => setIsExpanded(!isExpanded);
  const { textDictionary } = useTranslations();
  const { summary, signals } = knowledge;

  return (
    <StyledListLink onClick={toggleExpansion}>
      {children}
      <ExpandableContent isActive={isExpanded}>
        <StyledTextContent>
          <StyledDescriptionContainer>
            <StyledDescription>
              <MarkdownArea>{summary || textDictionary['app.discover.topic.summary.no_description']}</MarkdownArea>
            </StyledDescription>
          </StyledDescriptionContainer>
          {link && (
            <StyledFooterRow>
              <KnowledgeButtons knowledge={knowledge} shareLink={link} fullwidth />
            </StyledFooterRow>
          )}
        </StyledTextContent>
        <KnowledgeSignalSection signals={signals} />
      </ExpandableContent>
    </StyledListLink>
  );
};
