import { useSelector } from 'react-redux';

import { getSearchSectionVisibility } from 'domains/search/selectors';
import { getCanAccessCreate, getCanSeeSectionContent } from 'entities/user/selectors';

export const useSearch = () => {
  const searchVisibility = useSelector(getSearchSectionVisibility);
  const accessToCreate = useSelector(getCanAccessCreate);
  const accessToTabContent = useSelector(getCanSeeSectionContent);

  return {
    searchVisibility: {
      ...searchVisibility,
      keyIssues: accessToCreate && searchVisibility.keyIssues,
      publications: accessToTabContent && searchVisibility.publications,
      videos: accessToTabContent && searchVisibility.videos,
      interactiveData: accessToTabContent && searchVisibility.interactiveData,
    },
  };
};
