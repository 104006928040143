import { saveAs } from 'file-saver';
import { put } from 'redux-saga/effects';

import { fileApiFetch } from 'common/utilities';
import { fetchBriefingError, fetchBriefingSuccess } from 'domains/briefing/actions';
import { FetchBriefingAction } from 'domains/briefing/actionTypes';

export function* fetchBriefingSaga(action: FetchBriefingAction) {
  const { briefingId, topicName, templateName } = action.payload;
  try {
    const topicBriefing: Blob = yield fileApiFetch(`advancedbriefing/document/${templateName}/${briefingId}`, {
      extraHeaders: {
        'cache-control': 'no-cache, no-store, must-revalidate',
        expires: '0',
      },
    });
    saveAs(topicBriefing, `${topicName}.pdf`);
    yield put(fetchBriefingSuccess(briefingId));
  } catch (e) {
    console.error(e);
    yield put(fetchBriefingError(briefingId));
  }
}
