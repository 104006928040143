import { SVGProps } from 'react';
import { keyframes, styled } from 'styled-components';

const offset = 187;
const duration = 1400;

const rotatorAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(270deg);
  }
`;

const dashAnimation = keyframes`
  0% {
    stroke-dashoffset: ${offset};
  }

  50% {
    stroke-dashoffset: ${offset / 4};
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: ${offset};
    transform: rotate(450deg);
  }
`;

const StyledSvg = styled.svg`
  animation: ${duration}ms ${rotatorAnimation} linear infinite;
`;

const StyledPath = styled.circle`
  stroke-dasharray: ${offset};
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: ${duration}ms ${dashAnimation} ease-in-out infinite;
`;

export const Spinner = (props: SVGProps<SVGSVGElement>) => (
  <StyledSvg width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg" {...(props as any)}>
    <StyledPath fill="none" stroke="currentColor" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30" />
  </StyledSvg>
);
