import { styled } from 'styled-components';

import { FilterIcon } from 'common/components/icons';

const StyledIcon = styled(FilterIcon)`
  width: 24px;
  height: 24px;
`;

const StyledBadge = styled.div`
  position: absolute;
  left: -11px;
  top: 16px;
  font-weight: bold;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.textBright}DD;
  color: ${({ theme }) => theme.colors.backgroundSecondary};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7rem;
  line-height: 20px;
  letter-spacing: 1px;
  transition: background-color 0.3s;
`;

const StyledFilterContainer = styled.div`
  position: relative;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  color: ${({ theme }) => theme.colors.textBright}DD;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 27px;
  margin-left: 1rem;
  padding: 0 15px;
  transition: color 0.3s;
  height: 54px;
  &:hover {
    color: ${({ theme }) => theme.colors.textBright};
    & > ${StyledBadge} {
      background-color: ${({ theme }) => theme.colors.textBright};
    }
  }
`;

type FilterButtonProps = { count?: number; dataTestId?: string; onClick?: () => void };

export const FilterButton = ({ count, onClick, dataTestId }: FilterButtonProps) => (
  <StyledFilterContainer onClick={onClick} data-test-id={dataTestId}>
    <StyledIcon />
    {!!count && <StyledBadge>{count}</StyledBadge>}
  </StyledFilterContainer>
);
