import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';
import { v4 as createUuid } from 'uuid';

import { HiddenImageUpload, UIButton, TextInput } from 'common/components';
import { Separator } from 'common/components/Separator';
import { clearCommunityImageUrl, editCommunity, uploadImage } from 'domains/collection/actions';
import { selectCollections } from 'domains/collection/selectors';
import { LocalFile } from 'domains/createMapDetail/types';
import { useTranslations } from 'domains/language/useTranslations';
import { deactivateModal } from 'domains/modal/actions';
import { ModalHeader } from 'domains/modal/components/ModalHeader';
import { getModalParams } from 'domains/modal/selectors';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const StyledImage = styled.img`
  border-radius: 0.25rem;
  height: 4rem;
  object-fit: cover;
  object-position: center;
  width: 4rem;
`;

const StyledTextInput = styled(TextInput)`
  > label:first-child {
    color: ${({ theme }) => theme.colors.textBright};
  }
`;

const StyledTitle = styled.p`
  color: ${({ theme }) => theme.colors.textBright};
  font-weight: bold;
`;

export const CollectionCreateCoCuratorModal = () => {
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const params = useSelector(getModalParams);
  const [name, setName] = useState<string>(params.coBrand?.name ?? '');
  const { textDictionary } = useTranslations();
  const { uploadedImageUrls } = useSelector(selectCollections);

  useEffect(() => {
    dispatch(clearCommunityImageUrl());
  }, [dispatch]);

  const onSetImage = ({ file }: LocalFile) => dispatch(uploadImage({ image: file, type: 'community' }));

  const image = uploadedImageUrls.community ?? (params.coBrand?.image_url as string | undefined);

  const onClick = () => {
    if (image === undefined) return;
    dispatch(deactivateModal());
    if (image === params.coBrand?.image && name === params.coBrand?.name) return;
    const id = params.coBrand?.id ?? createUuid();
    const community = { id, image_url: image, name };
    dispatch(editCommunity(community));
    params.updateCoBrands(community);
  };

  const isValid = name.length > 0 && image !== undefined;
  const editMode = 'coBrand' in params;

  return (
    <>
      <ModalHeader
        title={textDictionary[`app.create.collection.modal.${editMode ? 'edit' : 'create'}_co_curator.title`]}
      />
      <StyledContainer>
        <StyledTextInput
          label={textDictionary['app.create.collection.modal.create_co_curator.curator_title']}
          name="curator-title"
          onChange={(event: ChangeEvent<HTMLInputElement>) => setName(event.target.value)}
          value={name}
        />
        <div>
          <StyledTitle>{textDictionary['app.create.collection.modal.create_co_curator.logo']}</StyledTitle>
          <HiddenImageUpload accept="image/*" onSetImage={onSetImage} ref={inputRef} />
          <UIButton fullwidth onClick={() => inputRef.current?.click()} variant="filled">
            {textDictionary['app.profile.upload_image.title']}
          </UIButton>
        </div>
        {image === undefined || <StyledImage src={image} />}
        <Separator height={1} />
        <UIButton disabled={!isValid} fullwidth onClick={onClick} variant="filled">
          {textDictionary[`app.create.collection.modal.${editMode ? 'edit' : 'create'}_co_curator.save`]}
        </UIButton>
      </StyledContainer>
    </>
  );
};
