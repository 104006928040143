import { put } from 'redux-saga/effects';

import { fetchCoachmarksVisibilityStatusSuccess } from 'domains/coachmarks/actions';
import { COACHMARKS_VISIBILITY_STATUS_KEY } from 'domains/coachmarks/localStorageKeys';

export function* fetchCoachmarksVisibilityStatusSaga() {
  let coachmarksVisibilityStatus = true;
  try {
    const storedValue = localStorage.getItem(COACHMARKS_VISIBILITY_STATUS_KEY);
    if (storedValue) {
      coachmarksVisibilityStatus = JSON.parse(storedValue);
    }
  } catch (error) {
    console.error(error);
  }

  yield put(fetchCoachmarksVisibilityStatusSuccess(coachmarksVisibilityStatus));
}
