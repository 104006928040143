import { PropsWithChildren, useEffect, useState } from 'react';

import { breakpointValues } from 'common/styles';
import { ScreenSize } from 'common/styles/mediaQueries/types';
import { ViewportContext } from 'domains/viewport/context';

const { medium, large, extraLarge, enormous } = breakpointValues;

type ScreenSizeQuery = { queryString: string; screenSize: ScreenSize };

// Adjust breakpoints with 1 pixel to be synced with the stylesheet media queries
const screenSizes: ScreenSizeQuery[] = [
  {
    screenSize: 'small',
    queryString: `(max-width: ${medium - 1}px)`,
  },
  {
    screenSize: 'medium',
    queryString: `(max-width: ${large - 1}px) and (min-width: ${medium - 1}px)`,
  },
  {
    screenSize: 'large',
    queryString: `(max-width: ${extraLarge - 1}px) and (min-width: ${large - 1}px)`,
  },
  {
    screenSize: 'extraLarge',
    queryString: `(max-width: ${enormous - 1}px) and (min-width: ${extraLarge - 1}px)`,
  },
  {
    screenSize: 'enormous',
    queryString: `(min-width: ${enormous - 1}px)`,
  },
];

const addMediaQueryListener = (mediaQueryList: MediaQueryList, eventListener: (event: MediaQueryListEvent) => void) => {
  if (mediaQueryList.addEventListener) {
    mediaQueryList.addEventListener('change', eventListener);
  } else {
    mediaQueryList.addListener(eventListener);
  }
};

const removeMediaQueryListener = (
  mediaQueryList: MediaQueryList,
  eventListener: (event: MediaQueryListEvent) => void,
) => {
  if (mediaQueryList.removeEventListener) {
    mediaQueryList.removeEventListener('change', eventListener);
  } else {
    mediaQueryList.removeListener(eventListener);
  }
};

export const ViewportProvider = ({ children }: PropsWithChildren) => {
  const [activeScreenSize, setActiveScreenSize] = useState<ScreenSize>();

  useEffect(() => {
    let isCleaningUp = false;
    const mediaQueries = screenSizes.map(({ screenSize, queryString }) => ({
      screenSize,
      mediaQuery: window.matchMedia(queryString),
    }));

    const mediaQueryListener = () => {
      if (isCleaningUp) {
        return;
      }
      const activeQuery = mediaQueries.find(({ mediaQuery }) => mediaQuery.matches);

      const screenSize = activeQuery ? activeQuery.screenSize : 'small';
      setActiveScreenSize(screenSize);
    };

    mediaQueries.forEach(({ mediaQuery }) => {
      addMediaQueryListener(mediaQuery, mediaQueryListener);
    });

    // Call it for the first time to set the active screen size
    mediaQueryListener();

    return () => {
      isCleaningUp = true;
      mediaQueries.forEach(({ mediaQuery }) => removeMediaQueryListener(mediaQuery, mediaQueryListener));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!activeScreenSize) {
    return null;
  }

  return <ViewportContext.Provider value={activeScreenSize}>{children}</ViewportContext.Provider>;
};
