import { styled } from 'styled-components';

import { Checkbox } from 'common/components';

export const BriefingList = styled.div<{ hasListItems: boolean }>`
  padding-top: ${({ hasListItems }) => (hasListItems ? '15px' : '0')};
`;

export const Text = styled.div`
  margin-bottom: 16px;
  font-weight: bold;
`;

export const GenerationContent = styled.div`
  padding-bottom: 25px;
  margin-bottom: 30px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.textBright}1F;
`;

export const ExplanationParagraph = styled.p`
  margin-bottom: 30px;
  line-height: 1.4rem;
`;

export const OptionCheckbox = styled(Checkbox)`
  margin-bottom: 16px;
`;
