import { SVGProps } from 'react';

export const LeftArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} fill="currentColor" strokeLinecap="round" strokeLinejoin="round">
    <path
      d="M6 1c-.56 0-1-.44-1-1v-9.586L.707-5.293a.99.99 0 0 1-1.414 0 .99.99 0 0 1 0-1.414l6-6a1.002 1.002 0 0 1 1.414 0l6 6a.99.99 0 0 1 0 1.414.99.99 0 0 1-1.414 0L7-9.586V0c0 .56-.44 1-1 1z"
      transform="rotate(-90 13.002 .002)"
    />
  </svg>
);
