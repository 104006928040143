import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppRoutes } from 'AppRoutes';
import { CookieBanner } from 'CookieBanner';
import { AudioPlayerProvider } from 'domains/audioPlayer/components/AudioPlayerProvider';
import { getIdToken } from 'domains/authentication/selectors';
import { fetchBookmarks } from 'domains/bookmarks/actions';
import { CoachmarkStack } from 'domains/coachmarks/components/CoachmarkStack';
import { fetchCollections } from 'domains/collection/actions';
import { CollectionType } from 'domains/collection/types/collectionType';
import { fetchTerms } from 'domains/language/actions';
import { getSiteLanguage } from 'domains/language/selectors';
import { ModalProvider } from 'domains/modal/components/ModalProvider';
import { MobileAppBanner } from 'domains/navigation/components/MobileAppBanner';
import { TopHeaderBar } from 'domains/navigation/components/TopHeaderBar';
import { fetchNotifications } from 'domains/notifications/actions';
import { VisualMediaPlayerProvider } from 'domains/visualMediaPlayer/components/VisualMediaPlayerProvider';
import { fetchWhatsNew } from 'domains/whatsNew/actions';
import { fetchLanguages } from 'entities/languages/actions';
import { fetchPermissions } from 'entities/permissions/actions';
import { fetchHighlightedTopics } from 'entities/topics/actions';
import { fetchTopicsMetadata } from 'entities/topicsMetadata/actions';
import { getCanDisplayBookmarks, getShouldDisplayNotifications, getUserId, getUserRole } from 'entities/user/selectors';
import { StyledAppContainer } from 'StyledAppContainer';

export const Main = () => {
  const dispatch = useDispatch();
  const shouldShowNotifications = useSelector(getShouldDisplayNotifications);
  const shouldShowBookmarks = useSelector(getCanDisplayBookmarks);
  const siteLanguage = useSelector(getSiteLanguage);
  const token = useSelector(getIdToken);
  const userId = useSelector(getUserId);
  const userRole = useSelector(getUserRole);

  useEffect(() => {
    window.dataLayer.push({ user_id: userId, user_type: userRole.toLowerCase() });
  }, [dispatch, userId, userRole]);

  useEffect(() => {
    if (shouldShowNotifications) {
      dispatch(fetchNotifications({ offset: 0 }));
    }
  }, [dispatch, shouldShowNotifications]);

  useEffect(() => {
    if (shouldShowBookmarks) {
      dispatch(fetchBookmarks({ offset: 0 }));
    }
  }, [dispatch, shouldShowBookmarks]);

  useEffect(() => {
    // we currently use types for
    /*
        fetching custom types for the discover navigation routes
        fetching the number of topics to display in the discover all topics route
     */
    dispatch(fetchTopicsMetadata());
    dispatch(fetchHighlightedTopics());
    dispatch(fetchCollections(CollectionType.Featured));
    dispatch(fetchCollections(CollectionType.Listed));
    dispatch(fetchCollections(CollectionType.Editable));
  }, [dispatch, token, siteLanguage]);

  useEffect(() => {
    dispatch(fetchPermissions());
  }, [dispatch, siteLanguage]);

  useEffect(() => {
    dispatch(fetchWhatsNew());
  }, [dispatch, siteLanguage]);

  useEffect(() => {
    dispatch(fetchLanguages());
    dispatch(
      fetchTerms({
        initialising: true,
        language: siteLanguage,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MobileAppBanner>
      <StyledAppContainer>
        <CoachmarkStack />
        <CookieBanner />
        <ModalProvider>
          <VisualMediaPlayerProvider>
            <AudioPlayerProvider>
              <TopHeaderBar>
                <AppRoutes />
              </TopHeaderBar>
            </AudioPlayerProvider>
          </VisualMediaPlayerProvider>
        </ModalProvider>
      </StyledAppContainer>
    </MobileAppBanner>
  );
};
