import { css, keyframes } from 'styled-components';

const fadeIn = keyframes`
  from { opacity: 0; }
  to   { opacity: 1; }
`;

export const imageFadeInStyle = css`
  width: 100%;
  height: 100%;
  animation: ${fadeIn} 0.3s ease-in-out;
`;
