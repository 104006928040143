import { LanguageCode } from 'common/types/languageCode';
import { PayloadTerm } from 'domains/language/types';
import { FetchError } from 'types';

export enum LanguageDomainActionType {
  FETCH = 'domains/LANGUAGE_FETCH_TERMS',
  FETCH_FULFILLED = 'domains/LANGUAGE_FETCH_TERMS_SUCCESS',
  FETCH_REJECTED = 'domains/LANGUAGE_FETCH_TERMS_FAILURE',
}

export type FetchLanguageTermsDefaultAction = {
  meta: { language: LanguageCode; initialising?: boolean };
  type: LanguageDomainActionType.FETCH;
};

export type FetchLanguageTermsFulfilledAction = {
  meta: { language: LanguageCode };
  payload: { terms: PayloadTerm[] };
  type: LanguageDomainActionType.FETCH_FULFILLED;
};

export type FetchLanguageTermsRejectedAction = { payload: FetchError; type: LanguageDomainActionType.FETCH_REJECTED };

export type FetchLanguageAction =
  | FetchLanguageTermsDefaultAction
  | FetchLanguageTermsFulfilledAction
  | FetchLanguageTermsRejectedAction;
