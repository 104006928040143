import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { editCommunitySuccess, editCommunityFailure } from 'domains/collection/actions';
import { EditCommunityAction } from 'domains/collection/actionTypes';

export function* editCommunitySaga({ payload }: EditCommunityAction) {
  try {
    yield apiFetch(`community/${payload.id}/edit`, { method: 'PUT', body: payload });
    yield put(editCommunitySuccess(payload.id));
  } catch (error) {
    yield put(editCommunityFailure(payload.id));
    console.error(`[editCommunitySaga] Failed to edit community with ID '${payload.id}': ${error}`);
  }
}
