import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

import { ChevronIcon, linkStyles } from 'common/components';
import { TopicSection } from 'domains/discover/components/TopicSection';
import { getSiteLanguage } from 'domains/language/selectors';
import { useTranslations } from 'domains/language/useTranslations';
import { fetchFollowedTopics } from 'entities/topics/actions';
import { getFollowedTopicsIsPending, getNFollowedTopics } from 'entities/topics/selectors';
import { getCanFollowTopic } from 'entities/user/selectors';

const StyledLink = styled(Link)`
  ${linkStyles}
`;

const StyledArrow = styled(ChevronIcon)`
  width: 16px;
  height: 16px;
  color: ${({ theme }) => theme.colors.textBright};
  padding-left: 0.5rem;
`;

export const FollowingTopics = () => {
  const { textDictionary } = useTranslations();
  const followingTopics = useSelector(getNFollowedTopics(8));
  const isPending = useSelector(getFollowedTopicsIsPending);
  const accessToFollowTopics = useSelector(getCanFollowTopic);
  const dispatch = useDispatch();
  const selectedLanguage = useSelector(getSiteLanguage);

  useEffect(() => {
    if (accessToFollowTopics) {
      dispatch(fetchFollowedTopics({ offset: 0, limit: 8 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedLanguage, accessToFollowTopics]);

  if (followingTopics.length === 0) {
    return null;
  }

  return (
    <TopicSection
      topics={followingTopics}
      title={
        <StyledLink to={'/followed'}>
          {textDictionary['app.monitor.following_topics']}
          <StyledArrow />
        </StyledLink>
      }
      isPending={isPending}
      titleDataTestId="following-section-title"
    />
  );
};
