import { SVGProps } from 'react';

export const BellIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props} fill="currentColor">
    <path
      d="M181.312 103.586a.916.916 0 0 0-.3-1.018 6.205 6.205 0 0 1-2.062-4.755V93.1a9.306 9.306 0 1 0-18.613 0v5.015a6.728 6.728 0 0 1-2 4.5.914.914 0 0 0 .611 1.592h21.5a.911.911 0 0 0 .862-.615zm-20.455-1.211a7.938 7.938 0 0 0 1.307-4.264V93.1a7.48 7.48 0 1 1 14.959 0v4.718a8.257 8.257 0 0 0 1.333 4.562z"
      transform="translate(-158.033 -83.789)"
    />
    <path
      d="M302.966 446.13a.913.913 0 0 0 0 1.826h4a.913.913 0 0 0 0-1.826z"
      transform="translate(-293.301 -424.11)"
    />
  </svg>
);
