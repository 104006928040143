import { TopicLatestSearchCriteria } from 'domains/knowledgeFeed/types';

export enum TopicLatestActionTypes {
  APPLY_SEARCH_CRITERIA = 'domains/LATEST_KNOWLEDGE_APPLY_SEARCH_CRITERIA',
  CLEAR_SEARCH_CRITERIA = 'domains/LATEST_KNOWLEDGE_CLEAR_SEARCH_CRITERIA',
}

export type TopicLatestApplySearchCriteriaPayload = Partial<TopicLatestSearchCriteria> & { topicId?: string };

export type TopicLatestApplySearchCriteria = {
  payload?: TopicLatestApplySearchCriteriaPayload;
  type: TopicLatestActionTypes.APPLY_SEARCH_CRITERIA;
};

export type TopicLatestClearSearchCriteria = { type: TopicLatestActionTypes.CLEAR_SEARCH_CRITERIA };
