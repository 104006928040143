import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { Knowledge } from 'entities/knowledge/types';
import { fetchKnowledgeDataForTopicFailure, fetchKnowledgeDataForTopicSuccess } from 'entities/knowledgeData/actions';
import { KnowledgeDataFetchForTopicAction } from 'entities/knowledgeData/actionTypes';
import { getPublicationFetchParams } from 'entities/publication/sagas/utils';
import { isFetchError } from 'TypeCheckMethods';

export function* fetchKnowledgeDataForTopicSaga(action: KnowledgeDataFetchForTopicAction) {
  const { id } = action.payload;
  try {
    const payload: Knowledge[] = yield apiFetch(`topic/${id}/knowledge_data`, {
      queryParams: getPublicationFetchParams(action.payload),
    });

    yield put(fetchKnowledgeDataForTopicSuccess({ topicId: id, data: payload }, action.payload));
  } catch (error) {
    if (isFetchError(error)) {
      const errorMeta = {
        searchCriteria: action.payload,
        logoutOnAuthError: true,
      };
      yield put(fetchKnowledgeDataForTopicFailure({ topicId: id, error }, errorMeta));
    }
  }
}
