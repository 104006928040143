import { Tab } from 'domains/termsAndConditions/types';

export const tabData: Record<string, Tab> = {
  general: {
    id: 'general',
    label: 'General',
    sections: [
      {
        id: 'what-is-the-forums-approach-to-strategic-intelligence',
        title: 'What is the Forum’s approach to Strategic Intelligence?',
        body: (
          <p>
            The World Economic Forum has developed its Strategic Intelligence platform in response to accelerating
            global complexity to give leaders a better sense of the fluid inter-relationships between global issues and
            help them make more informed decisions. Over time, it has evolved into a data-driven, dynamic knowledge tool
            used by organizations across the world. It enables users to discover factors influencing topics changing the
            world, monitor emerging trends and create bespoke transformation maps.
          </p>
        ),
      },
      {
        id: 'why-do-you–cover-this-selection-of-topics',
        title: 'Why do you cover this selection of topics?',
        body: (
          <p>
            The topic areas cover issues on which the Forum and its partners, members and constituents produce knowledge
            and/or engage. This includes all major industry groupings, economies at the national and regional level, and
            global issues ranging from emerging technologies, to environmental topics, to global governance, human
            rights and international security. They are continuously reviewed and updated, with new topics added as they
            become relevant, and others retired or adjusted to reflect developments.
          </p>
        ),
      },
      {
        id: 'who-curates-our-topic-areas',
        title: 'Who curates our topic areas?',
        body: (
          <p>
            The topic area visualization incorporates expert and machine-curated knowledge. We work with experts at
            leading universities, think tanks, international organizations and other not-for-profit research
            institutions. The choice of institution and individual co-curator depends on several factors, including
            their reputation and accomplishments, advice of the Forum’s own subject matter experts and their
            availability. Many co-curators come from institutions that are members of the Forum’s Global University
            Leaders Forum (GULF) community.
          </p>
        ),
      },
      {
        id: 'what-is-the-role-of-a-co-curator',
        title: 'What is the role of a co-curator?',
        body: (
          <p>
            A co-curator works with the Forum to identify and explain the key trends or drivers of change for their
            topic, drawing on their expertise and the latest research in their field as well as insights from various
            Forum activities and communities. Co-curators are invited into the Forum’s Expert Network and can be invited
            to Forum events and webinars to talk about the issues covered by the map they have worked on.
          </p>
        ),
      },
      {
        id: 'why-do-certain-articles-and-publications-appear-alongside-the-topic-area-visualization',
        title: 'Why do certain articles and publications appear alongside the topic area visualization?',
        body: (
          <p>
            Each topic area has a dynamic feed of the latest research and analysis drawn from leading research
            institutions and specialized media outlets around the world. These feeds provide a convenient
            ‘one-stop-shop’ service to users who can access the latest research on a topic by clicking on a link that
            will direct them back to the original source. The research and analysis contained in the feeds does not
            necessarily represent the views, opinions or positions of the World Economic Forum.
          </p>
        ),
      },
      {
        id: 'in-which-languages-is-the-content-available?',
        title: 'In which languages is the content available?',
        body: (
          <p>
            The content of the topic area visualizations is translated into Arabic, Japanese, Mandarin Chinese,
            Portuguese, and Spanish. Since the content is updated regularly, some of the latest changes might not have
            gone through translation and therefore appear initially in English only.
          </p>
        ),
      },
      {
        id: 'which-devices-is-strategic-intelligence-available-on',
        title: 'Which devices is Strategic Intelligence available on?',
        body: <p>Strategic Intelligence is available online as well as through iOS and Android mobile apps.</p>,
      },
      {
        id: 'how-can-i-download-the-strategic-intelligence-mobile-app',
        title: 'How can I download the Strategic Intelligence mobile app?',
        body: (
          <p>
            The Strategic Intelligence mobile app is available to download from the App Store, Google Play and Huawei
            App Gallery. If you have already registered for a Strategic Intelligence account online, you can login using
            the same credentials.
          </p>
        ),
      },
      {
        id: 'who-can-i-contact-if-i-am-having-difficulties-accessing-strategic-intelligence',
        title: 'Who can I contact if I am having difficulties accessing Strategic Intelligence?',
        body: (
          <p>
            Please contact <a href="mailto:intelligence@weforum.org">intelligence@weforum.org</a>
          </p>
        ),
      },
    ],
  },
  access: {
    id: 'access',
    label: 'Access',
    sections: [
      {
        id: 'how-can-i-access-my-account-if-I-have-forgotten-my-password',
        title: 'How can I access my account if I have forgotten my password?',
        body: (
          <p>
            Please select the ‘Forgot Your Password?’ link on the login screen from where you will be able to send a
            password reset link to your email address.
          </p>
        ),
      },
      {
        id: 'can-i-change-the-email-address-associated-with-my-account',
        title: 'Can I change the email address associated with my account?',
        body: (
          <p>
            Currently we are unable to change email addresses associated with accounts. Alternatively, you can create a
            new account with your new email address.
          </p>
        ),
      },
      {
        id: 'can-i-change-the-email-address-associated-with-my-account',
        title: 'How do I create an account with the World Economic Forum?',
        body: (
          <p>
            You can create an account with the World Economic Forum{' '}
            <a href="https://www.weforum.org/join-us/pricing/" rel="noopener noreferrer" target="_blank">
              here.
            </a>
          </p>
        ),
      },
    ],
  },
  membership: {
    id: 'membership',
    label: 'Membership',
    sections: [
      {
        id: 'what-are-the-different-membership-options',
        title: 'What are the different membership options?',
        body: (
          <p>
            As an individual, you can access the Strategic Intelligence platform via the below options:
            <ol>
              <li>
                Public Member: Free access to Strategic Intelligence, our public website (weforum.org), and UpLink
              </li>
              <li>
                Premium Digital Member: Exclusive access to the most powerful Strategic Intelligence tools and virtual
                events, at €30 per month or €306 per year.
              </li>
              <li>
                Pro Digital Member: Unlimited access to advanced analytics, exclusive virtual events and collaboration
                tools on the Strategic Intelligence platform, at €90 per month or €918 per year.
              </li>
            </ol>
            More information about these individual membership options is available{' '}
            <a href="https://www.weforum.org/join-us/pricing/" rel="noopener noreferrer" target="_blank">
              here.
            </a>
          </p>
        ),
      },
      {
        id: 'what-can-i-do-with-a-public-account',
        title: 'What can I do with a public account?',
        body: (
          <>
            <p>
              With a public account, you can view Strategic Intelligence and related insights, expert-curated analysis
              and data. To access our suite of advanced contextual intelligence tools, sign up for a Premium or Pro
              Digital Membership with the World Economic Forum. More information about these membership options is
              available{' '}
              <a href="https://www.weforum.org/join-us/pricing/" target="_blank" rel="noopener noreferrer">
                here.
              </a>
            </p>
          </>
        ),
      },
      {
        id: 'can-i-trial-digital-membership',
        title: 'Can I trial digital membership?',
        body: (
          <p>
            Yes, all first-time users receive a free 7-day trial period for our Premium and Pro Digital Memberships.
            During the trial period, you can upgrade, downgrade or cancel your membership without being charged. When
            the trial period ends, unless you cancel your membership, you will automatically be charged, and your
            membership will continue.
          </p>
        ),
      },
    ],
  },
  'payments-and-billing': {
    id: 'payments-and-billing',
    label: 'Payment',
    sections: [
      {
        id: 'what-are-my-payment-options',
        title: 'What are my payment options?',
        body: (
          <>
            <p>
              Digital Membership is available on monthly as well as annual billing cycles. With our annual payment
              option, you save 15% on both Premium and Pro memberships.
            </p>
            <p>
              Unless cancelled, the membership will be renewed at the end of each billing cycle. The exact renewal date
              is displayed on the Membership page in your profile
            </p>
          </>
        ),
      },
      {
        id: 'what-payment-methods-are-supported',
        title: 'What payment methods are supported?',
        body: (
          <>
            <p>
              Digital Membership can be purchased with the following credit cards:
              <ul>
                <li>Visa</li>
                <li>Mastercard</li>
                <li>American Express</li>
                <li>You can also pay via Apple Pay and Google Pay, if available in your region.</li>
              </ul>
            </p>
          </>
        ),
      },
      {
        id: 'what-currencies-are-supported',
        title: 'What currencies are supported?',
        body: (
          <>
            <p>We currently only accept payments in Euro (EUR).</p>
          </>
        ),
      },
      {
        id: 'how-do-i-change-my-billing-information',
        title: 'How do I change my billing information?',
        body: (
          <p>
            You can update your billing information, including: credit card, address on card and invoice address,
            through the Billing page of the digital membership portal.
          </p>
        ),
      },
      {
        id: 'can-i-cancel-my-digital-membership',
        title: 'Can I cancel my digital membership?',
        body: (
          <>
            <p>Yes, you can cancel your digital membership at any time.</p>
            <p>
              To cancel your membership (from the web version):
              <ol>
                <li>
                  Login to Strategic Intelligence:{' '}
                  <a href="https://intelligence.weforum.org" rel="noopener noreferrer" target="_blank">
                    https://intelligence.weforum.org
                  </a>{' '}
                </li>
                <li>
                  Once logged in, you can manage your account and membership details:{' '}
                  <a
                    href="https://digital-members.weforum.org/account/membership"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    https://digital-members.weforum.org/account/membership
                  </a>{' '}
                </li>
              </ol>
            </p>
            <p>
              Please note that the cancellation will come into effect at the end of the current billing cycle. You will
              retain access to all benefits until then. We are unable to issue refunds for cancellations in the middle
              of a billing cycle.
            </p>
          </>
        ),
      },
      {
        id: 'what-is-the-refund-policy',
        title: 'What is the refund policy?',
        body: (
          <p>
            Digital memberships are non-refundable. You may cancel your subscription at any time and it will take effect
            at the end of the current billing period.
          </p>
        ),
      },
      {
        id: 'will-my-card-be-automatically-charged-when-my-membership-renews',
        title: 'Will my card be automatically charged when my membership renews?',
        body: (
          <>
            <p>Yes, your card will be charged automatically when your subscription renews.</p>
            <p>The subscription renewal date is displayed on the Membership page of the digital membership portal.</p>
          </>
        ),
      },
      {
        id: 'how-can-i-update-my-credit-card-details',
        title: 'How can I update my credit card details?',
        body: (
          <p>
            To update your credit card details (from the web version):
            <ol>
              <li>
                Login to Strategic Intelligence:{' '}
                <a href="https://intelligence.weforum.org" rel="noopener noreferrer" target="_blank">
                  https://intelligence.weforum.org
                </a>{' '}
              </li>
              <li>
                Once connected, you can access your account and manage your membership:{' '}
                <a href="https://digital-members.weforum.org/account/billing" rel="noopener noreferrer" target="_blank">
                  https://digital-members.weforum.org/account/billing
                </a>{' '}
              </li>
              <li>Click on the ‘Update Credit Card’ option to make any changes to your credit card details</li>
            </ol>
          </p>
        ),
      },
      {
        id: 'what-should-i-do-if-i-find-a-billing-error-on-my-account',
        title: 'What should I do if I find a billing error on my account?',
        body: (
          <p>
            If you find a billing error, please
            <a href="mailto:intelligence@weforum.org">contact us</a> and provide details on the error as well as the
            email address under which your Digital Membership is registered.
          </p>
        ),
      },
      {
        id: 'how-do-i-get-an-invoice-or-a-receipt-for-my-billing-history',
        title: 'How do I get an invoice or a receipt for my billing history?',
        body: (
          <>
            <p>
              The invoices for your Digital Membership payments are available on the Invoices page of the digital
              membership portal.
            </p>
            <p>
              To access your invoices (from the web version):
              <ol>
                <li>
                  Login to the Strategic Intelligence platform:{' '}
                  <a href="https://intelligence.weforum.org" target="_blank" rel="noopener noreferrer">
                    https://intelligence.weforum.org
                  </a>{' '}
                </li>
                <li>
                  You can then download your invoices from your membership:{' '}
                  <a
                    href="https://digital-members.weforum.org/account/invoices"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://digital-members.weforum.org/account/invoices
                  </a>{' '}
                </li>
              </ol>
            </p>
          </>
        ),
      },
      {
        id: 'how-do-i-request-changes-to-an-account-that-isnt-mine',
        title: 'How do I request changes to an account that isn’t mine?',
        body: (
          <p>To maintain the privacy of our members, we can only assist you with an account that belongs to you.</p>
        ),
      },
      {
        id: 'why-do-i-need-to-pay-vat-on-my-strategic-intelligence-purchases',
        title: 'Why do I need to pay VAT on my Strategic Intelligence purchases?',
        body: (
          <p>
            Swiss residents are required to pay 8.1% VAT as part of their membership fee; residents from all other
            countries will not be required to pay VAT.
          </p>
        ),
      },
      {
        id: 'why-isnt-my-country-listed-in-the-dropdown-list-when-trying-to-become-a-digital-member',
        title: 'Why isn’t my country listed in the dropdown list when trying to become a Digital Member?',
        body: (
          <p>
            In accordance with U.S., E.U., U.N. and Swiss sanctions, Digital Membership is not available to users in
            Cuba, North Korea, Iran, Syria and Russia.
          </p>
        ),
      },
    ],
  },
};
