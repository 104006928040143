import { css, styled } from 'styled-components';

import { RoundedButton } from 'common/components';

export const StyledLandingButton = styled(RoundedButton)`
  color: white;
  max-width: 250px;
  margin-top: 1rem;
  border-radius: 25px;

  ${({ theme }) => css`
    background-color: ${theme.colors.blue};

    &:hover {
      background-color: ${theme.colors.blueDark};
    }
  `};
`;
