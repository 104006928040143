import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

import { useTranslations } from 'domains/language/useTranslations';
import { useRouterLink } from 'domains/routing/utils';

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

export const ResourcesButton = () => {
  const { textDictionary } = useTranslations();
  const link = useRouterLink({ to: 'resources' });

  return <StyledLink to={link}>{textDictionary['app.resources.list.title']}</StyledLink>;
};
