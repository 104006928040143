import { MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

import { ChevronIcon } from 'common/components';
import { useInternalLink } from 'common/utilities';

type ButtonProps = { $isSmall: boolean };

const StyledSeeDetailButton = styled(Link)<ButtonProps>`
  transition: background-color 0.3s;
  padding: ${({ $isSmall }) => ($isSmall ? '2px' : '6px 8px')};
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.topHeaderBarText};
  height: 20px;
  width: 20px;
  ${({ $isSmall }) =>
    $isSmall &&
    `
      flex: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    `};

  &:hover {
    background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  }
`;

const StyledChevron = styled(ChevronIcon)<Pick<ButtonProps, '$isSmall'>>`
  width: ${({ $isSmall }) => ($isSmall ? '14px' : '14px')};
  height: ${({ $isSmall }) => ($isSmall ? '14px' : '14px')};
`;

type KnowledgeDetailButtonProps = {
  className?: string;
  isSmall?: boolean;
  link: string;
  onClick?: (event: MouseEvent) => void;
};

export const KnowledgeDetailButton = ({ link, onClick, className, isSmall = true }: KnowledgeDetailButtonProps) => {
  const linkLocation = useInternalLink(link);

  const shouldUseInternalLink = !link || link.startsWith('/');

  if (!link && !onClick) {
    return null;
  }
  return (
    <div>
      <StyledSeeDetailButton
        className={className}
        to={shouldUseInternalLink ? linkLocation : link || ''}
        as={!shouldUseInternalLink ? 'a' : undefined}
        href={!shouldUseInternalLink ? link : undefined}
        target={!shouldUseInternalLink ? '_blank' : undefined}
        rel="noopener"
        $isSmall={isSmall}
        onClick={onClick}
      >
        <StyledChevron $isSmall={isSmall} />
      </StyledSeeDetailButton>
    </div>
  );
};
