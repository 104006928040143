import { PropsWithChildren, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { unauthenticate } from 'domains/authentication/actions';
import { getIsAuthenticated } from 'domains/authentication/selectors';
import { useTranslations } from 'domains/language/useTranslations';
import { AppLogo } from 'domains/navigation/components/AppLogo';
import { CreateItem } from 'domains/navigation/components/CreateItem';
import { DiscoverButton } from 'domains/navigation/components/DiscoverButton';
import { MobileMenuButton } from 'domains/navigation/components/MobileMenuButton';
import { MonitorItem } from 'domains/navigation/components/MonitorItem';
import { UserProfileButton } from 'domains/navigation/components/profile/UserProfileButton';
import { SearchItem } from 'domains/navigation/components/SearchItem';
import { SettingsButton } from 'domains/navigation/components/settings/SettingsButton';
import * as Styled from 'domains/navigation/components/TopHeaderBar.styled';

export const TopHeaderBar = ({ children }: PropsWithChildren) => {
  const dispatch = useDispatch();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const { textDictionary } = useTranslations();

  const toggleMenu = useCallback(() => setShowMobileMenu(!showMobileMenu), [showMobileMenu]);
  const closeMenu = useCallback(() => setShowMobileMenu(false), []);

  const onLogoutHandle = useCallback(() => {
    dispatch(unauthenticate({ isUserLogout: true }));
    toggleMenu();
  }, [dispatch, toggleMenu]);

  return (
    <Styled.Content>
      <Styled.Header>
        <Styled.HeaderContent>
          <Styled.BarRow>
            <DiscoverButton />
            <MonitorItem />
            <CreateItem />
          </Styled.BarRow>
          <Styled.BarRow>
            <AppLogo />
          </Styled.BarRow>
          <Styled.BarRow>
            <SearchItem />
            <SettingsButton />
            <UserProfileButton />
          </Styled.BarRow>
        </Styled.HeaderContent>
        <Styled.MobileNavigation>
          <AppLogo onClick={closeMenu} />
          <MobileMenuButton onClick={toggleMenu} />
        </Styled.MobileNavigation>
      </Styled.Header>
      <Styled.Page>{children}</Styled.Page>
      {showMobileMenu && (
        <Styled.Menu>
          <DiscoverButton onClick={toggleMenu} />
          <MonitorItem onClick={toggleMenu} />
          <CreateItem onClick={toggleMenu} />
          <SearchItem onClick={toggleMenu} />
          <SettingsButton onClick={toggleMenu} />
          <UserProfileButton onClick={toggleMenu} />
          {isAuthenticated && (
            <Styled.LogoutButton onClick={onLogoutHandle} variant={'filled'}>
              {textDictionary['app.user.logout']}
            </Styled.LogoutButton>
          )}
        </Styled.Menu>
      )}
    </Styled.Content>
  );
};
