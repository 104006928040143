import { styled } from 'styled-components';

import { breakpoints } from 'common/styles';

const StyledUIButtonsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0 30px;

  @media ${breakpoints.medium} {
    gap: 0 10px;
  }
  @media ${breakpoints.large} {
    gap: 0 30px;
  }
`;

export const UIButtonsRow = ({ children, className }: any) => (
  <StyledUIButtonsRow className={className}>{children}</StyledUIButtonsRow>
);
