export const darkShadows = {
  box: '0 0px 8px rgba(80, 80, 80, 0.08)',
  tile: 'none',
  aboutContent: 'none',
};

export const lightShadows = {
  box: '0 0px 8px rgba(31, 27, 82, 0.08)',
  tile: '0 0px 10px rgb(0 0 0 / 25%)',
  aboutContent: '#EDF2F5 0 0 4rem 2rem',
};
