import { combineReducers } from 'redux';

import { InitiativeAction, InitiativesType } from 'entities/initiatives/actionTypes';
import { InitiativeState } from 'entities/initiatives/types';
import { addUserChangeReset } from 'entities/user/utils';

const initialState: InitiativeState = {
  byId: {},
};

const byId = (state = initialState.byId, action: InitiativeAction): InitiativeState['byId'] => {
  switch (action.type) {
    case InitiativesType.FETCH_FOR_TOPIC_SUCCESS: {
      return action.payload.data.reduce(
        (byId, initiative) => ({
          ...byId,
          [initiative.id]: {
            ...state[initiative.id],
            ...initiative,
          },
        }),
        state,
      );
    }
    case InitiativesType.FETCH_FOR_ID: {
      return {
        ...state,
        [action.meta.id]: {
          ...state[action.meta.id],
          isPending: true,
        },
      };
    }
    case InitiativesType.FETCH_FOR_ID_SUCCESS: {
      return {
        ...state,
        [action.meta.id]: {
          ...state[action.meta.id],
          ...action.payload,
          isPending: false,
          error: false,
        },
      };
    }
    case InitiativesType.FETCH_FOR_ID_FAILURE: {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isPending: false,
          error: true,
        },
      };
    }
    default:
      return state;
  }
};

export const initiativesReducer = combineReducers({
  byId: addUserChangeReset(byId, initialState.byId),
});
