import { styled } from 'styled-components';

import { PlayIcon } from 'common/components';
import { cssResets } from 'common/styles/cssResets';
import { useTranslations } from 'domains/language/useTranslations';

type CollectionVideoCoverProps = { image: string };

export const CollectionVideoCover = ({ image }: CollectionVideoCoverProps) => {
  const { textDictionary } = useTranslations();

  return (
    <Container>
      <Image src={image} />
      <Overlay>
        <Button>
          {textDictionary['app.collection.watch_video_explainer']}
          <Play />
        </Button>
      </Overlay>
    </Container>
  );
};

const Image = styled.img`
  height: 100%;
  object-fit: cover;
  transition: transform 0.375s ease;
  width: 100%;
`;

const Container = styled.div`
  border-radius: 0.625rem;
  cursor: pointer;
  height: 365px;
  overflow: hidden;
  position: relative;
  width: 100%;

  &:hover {
    ${Image} {
      transform: translate3d(0, 0, 0) scale(1.2);
    }
  }
`;

const Overlay = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
`;

const Button = styled.button`
  ${cssResets.button}
  -webkit-backdrop-filter: blur(10px);
  align-items: center;
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 1.75rem;
  bottom: 1rem;
  color: #fff;
  display: flex;
  font-weight: bold;
  gap: 0.5rem;
  left: 1rem;
  padding: 1rem 1.5rem;
  position: absolute;
`;

const Play = styled(PlayIcon)`
  color: #fff;
`;
