import { Component, PropsWithChildren } from 'react';

import { Fallback } from 'common/components/lazy/Fallback';

type State = { hasError: boolean };

export class ErrorBoundary extends Component<PropsWithChildren, State> {
  constructor(props: PropsWithChildren) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <Fallback error={true} />;
    }
    return <>{this.props.children}</>;
  }
}
