import { MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { Spinner, UITag, UserProfileImage } from 'common/components';
import { activateModal } from 'domains/modal/actions';
import { getIsLoadingProfile, getIsUploadingProfileImage } from 'domains/user/selectors';
import { getShouldDisplayPremiumBadge, getShouldRedirectToToplink, getUserData } from 'entities/user/selectors';

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  color: ${({ theme }) => theme.colors.topHeaderBarText};
`;

const StyledUserDetails = styled.div`
  margin-left: 1rem;
`;

const StyledImageWrapper = styled.div`
  position: relative;
  width: 36px;
  height: 36px;
`;

const StyledUserProfileImage = styled(UserProfileImage)`
  width: 100%;
  height: 100%;
  margin: 0;
`;

const StyledFullNameText = styled.p`
  margin: 0 0 0.4rem 0;
  font-size: 0.9rem;
  line-height: 1.2rem;
  font-weight: bold;
`;

const StyledLoadingContainer = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  position: absolute;
  bottom: -5px;
  right: -5px;
  border: 4px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.blue};
`;

const StyledLoadingIndicator = styled(Spinner)`
  height: 1rem;
  width: 1rem;
  color: white;
`;

export const MyAccountButton = () => {
  const dispatch = useDispatch();
  const userData = useSelector(getUserData);
  const shouldRedirectToToplink = useSelector(getShouldRedirectToToplink);
  const isUploadingProfileImage = useSelector(getIsUploadingProfileImage);
  const isLoading = useSelector(getIsLoadingProfile);
  const showRoleTag = useSelector(getShouldDisplayPremiumBadge);

  const onClick = (event: MouseEvent) => {
    if (shouldRedirectToToplink) {
      event.stopPropagation();
      if (userData.id) {
        window.location.href = `${process.env.REACT_APP_TOPLINK_BASE_URL}/people/${userData.id}/view` || '';
      }
    } else {
      dispatch(activateModal({ id: 'User Profile' }));
    }
  };
  let icon = null;
  if (isLoading || isUploadingProfileImage) {
    icon = (
      <StyledLoadingContainer>
        <StyledLoadingIndicator />
      </StyledLoadingContainer>
    );
  }

  return (
    <StyledContainer onClick={onClick}>
      <StyledImageWrapper>
        <StyledUserProfileImage>{icon}</StyledUserProfileImage>
      </StyledImageWrapper>
      {!isLoading && (
        <StyledUserDetails>
          <StyledFullNameText>{userData.fullName}</StyledFullNameText>
          {userData?.localizedRole && showRoleTag && <UITag>{userData.localizedRole}</UITag>}
        </StyledUserDetails>
      )}
    </StyledContainer>
  );
};
