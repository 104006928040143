import { DigitalMemberTag } from 'common/components';
import { useElementIsLoaded } from 'common/utilities';
import { StyledLandingText } from 'domains/landing/components/StyledLandingText';
import { StyledLandingTitle } from 'domains/landing/components/StyledLandingTitle';
import EventsImage from 'domains/landing/create/EventsImage.png';
import {
  SlidingContainer,
  StyledInfoContainer,
  StyledSectionContainer,
  StyledImage,
} from 'domains/landing/create/shared';
import { useTranslations } from 'domains/language/useTranslations';

export const EventsSection = () => {
  const { textDictionary } = useTranslations();
  const { elementRef, isLoaded } = useElementIsLoaded({ threshold: 0.5 });

  return (
    <StyledSectionContainer ref={elementRef}>
      <StyledInfoContainer>
        <div ref={elementRef}>
          <SlidingContainer isLoaded={isLoaded} isLeft={true}>
            <DigitalMemberTag trackPage="Landing" />
            <StyledLandingTitle>{textDictionary['app.discover.topic.tabs.events.title']}</StyledLandingTitle>
            <StyledLandingText>{textDictionary['app.events.promo_text']}</StyledLandingText>
          </SlidingContainer>
        </div>
      </StyledInfoContainer>
      <StyledInfoContainer>
        <SlidingContainer isLoaded={isLoaded}>
          <StyledImage src={EventsImage} alt={textDictionary['app.discover.topic.tabs.events.title']} />
        </SlidingContainer>
      </StyledInfoContainer>
    </StyledSectionContainer>
  );
};
