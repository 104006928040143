import { SVGProps } from 'react';

export const PlusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" {...props} fill="currentColor">
    <path d="M7 13V1v6h6-6v6z" transform="rotate(-90 7 7)" />
    <path
      d="M7 14c-.56 0-1-.44-1-1V8H1c-.56 0-1-.44-1-1s.44-1 1-1h5V1c0-.56.44-1 1-1s1 .44 1 1v5h5c.56 0 1 .44 1 1s-.44 1-1 1H8v5c0 .56-.44 1-1 1z"
      transform="rotate(-90 7 7)"
    />
  </svg>
);
