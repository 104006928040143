import { ReactElement } from 'react';
import { styled } from 'styled-components';

import { Image } from 'common/components';
import { ImageCollection } from 'common/types/imageCollection';

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  text-decoration: none;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  cursor: pointer;
  border-radius: 4px;
`;

const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  filter: blur(5px);
  transform: scale(1.1);
`;

const StyledOverlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
`;

const StyledTitle = styled.h2`
  color: white;
  font-size: 1.4rem;
  margin: 0 2rem;
`;

type PlaceholderClusterCardProps = {
  icon?: ReactElement | null;
  image?: ImageCollection;
  message: string;
  onClick: () => void;
};

export const PlaceholderClusterCard = ({ message, image, icon = null, onClick }: PlaceholderClusterCardProps) => (
  <StyledContainer onClick={onClick}>
    <StyledImage src={image?.thumbnail} alt={message} />
    <StyledOverlay>
      <StyledTitle>{message}</StyledTitle>
      {icon}
    </StyledOverlay>
  </StyledContainer>
);
