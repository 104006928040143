import { MonitorFilterActionType, UpdateTopicsFilterAction } from 'domains/monitor/filter/actionTypes';
import { FilterState } from 'domains/monitor/filter/types';
import {
  UnfollowTopicFulfilledAction,
  TopicActionType,
  FetchFollowedTopicsListFulfilledAction,
} from 'entities/topics/actionTypes';
import { addUserChangeReset } from 'entities/user/utils';

const initialState: FilterState = {
  topics: { selectedIds: [] },
};

const reducer = (
  state = initialState,
  action: UpdateTopicsFilterAction | UnfollowTopicFulfilledAction | FetchFollowedTopicsListFulfilledAction,
): FilterState => {
  switch (action.type) {
    case MonitorFilterActionType.UPDATE_FILTER: {
      return {
        ...state,
        topics: {
          ...state.topics,
          selectedIds: action.payload.selectedTopics,
        },
      };
    }

    case TopicActionType.UNFOLLOW_FULFILLED: {
      return {
        ...state,
        topics: {
          ...state.topics,
          selectedIds: state.topics.selectedIds?.filter((id) => id !== action.payload.id),
        },
      };
    }

    case TopicActionType.FETCH_FOLLOWED_LIST_FULFILLED: {
      return {
        ...state,
        topics: {
          ...state.topics,
          selectedIds: state.topics.selectedIds?.filter((id) => action.payload.topics.some((topic) => topic.id === id)),
        },
      };
    }

    default:
      return state;
  }
};

export const filtersReducer = addUserChangeReset(reducer, initialState);
