import { combineReducers } from 'redux';

import { AuthenticationAction, AuthenticationType } from 'domains/authentication/actionTypes';
import { AuthenticationState, LoginState, TokensState } from 'domains/authentication/types';
import { addUnauthenticatedReset } from 'domains/authentication/utils';

export const initialState: AuthenticationState = {
  tokens: {
    accessToken: undefined,
    refreshToken: undefined,
    idToken: undefined,
    expiresIn: undefined,
  },
  login: {
    isPending: false,
    isSSOPending: false,
  },
};

const tokens = (state = initialState.tokens, action: AuthenticationAction): TokensState => {
  switch (action.type) {
    case AuthenticationType.LOGIN_FULFILLED: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

const login = (state = initialState.login, action: AuthenticationAction): LoginState => {
  switch (action.type) {
    case AuthenticationType.FWS_TRIGGERED_FIRST_TOKEN_RENEW:
    case AuthenticationType.LOGIN: {
      return {
        ...state,
        isPending: true,
        error: undefined,
        isSSOPending: ('payload' in action && action.payload.isSSO) || false,
      };
    }
    case AuthenticationType.LOGIN_FULFILLED: {
      return {
        ...state,
        isPending: false,
        isSSOPending: false,
      };
    }
    case AuthenticationType.LOGIN_REJECTED: {
      return {
        ...state,
        isPending: false,
        isSSOPending: false,
        error: action.payload.error,
      };
    }
    default:
      return state;
  }
};

export const authenticationReducer = combineReducers({
  tokens: addUnauthenticatedReset(tokens, initialState.tokens),
  login: addUnauthenticatedReset(login, initialState.login),
});
