import { useEffect, useLayoutEffect, useState, useCallback } from 'react';
import { css, styled } from 'styled-components';

const StyledOverlaySvg = styled.svg<{ isActive: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9997;

  ${({ isActive }) => css`
    fill: black;
    opacity: ${isActive ? 0.7 : 0};
  `};
`;

const StyledMask = styled.mask`
  // With SVG masks, the fill actually controls the opacity of the mask;
  // setting to white means "go fully opaque"
  // http://tutorials.jenkov.com/svg/mask.html
  fill: white;
`;

const StyledCircleCutout = styled.circle`
  // Use any colour that isn't the same as the overlay to make it cut out
  fill: blue;
`;

type CoachmarkOverlayProps = {
  cutoutSpacing?: number;
  isActive: boolean;
  onClose: () => void;
  shouldHighlightElement?: boolean;
  targetElement?: HTMLElement;
  viewportHeight: number;
  viewportWidth: number;
};

export const CoachmarkOverlay = ({
  targetElement,
  viewportWidth,
  viewportHeight,
  shouldHighlightElement,
  isActive,
  cutoutSpacing,
  onClose,
}: CoachmarkOverlayProps) => {
  const [x, setX] = useState<number>();
  const [y, setY] = useState<number>();
  const [radius, setRadius] = useState<number>();

  const calculatePositions = useCallback(() => {
    if (!targetElement || shouldHighlightElement === false) {
      return;
    }
    const element = targetElement.getBoundingClientRect();
    const width = element.width - 2;
    const height = element.height - 2;
    const diameter = Math.max(width, height);
    setX(element.left + 1 + width / 2);
    setY(element.top + 1 + height / 2);
    setRadius((diameter + (cutoutSpacing || 0)) / 2);
  }, [targetElement, cutoutSpacing, shouldHighlightElement]);

  useEffect(() => {
    if (!targetElement) {
      return;
    }

    const resizeObserver = new ResizeObserver(calculatePositions);
    resizeObserver.observe(targetElement);

    return () => {
      resizeObserver.unobserve(targetElement);
    };
  }, [targetElement, calculatePositions]);

  useLayoutEffect(() => {
    calculatePositions();
  }, [calculatePositions, viewportHeight, viewportWidth]);

  if (!targetElement) {
    return null;
  }

  return (
    <StyledOverlaySvg isActive={isActive} onClick={onClose}>
      <StyledMask id="coachmark-highlight">
        <rect height={viewportHeight} width={viewportWidth} />
        {shouldHighlightElement !== false && <StyledCircleCutout cx={x} cy={y} r={radius} />}
      </StyledMask>
      <rect mask="url(#coachmark-highlight)" height={viewportHeight} width={viewportWidth} />
    </StyledOverlaySvg>
  );
};
