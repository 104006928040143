import ReactMarkdown from 'react-markdown';
import { styled } from 'styled-components';

export const MarkdownArea = styled(ReactMarkdown)`
  white-space: pre-line;
  p,
  pre {
    white-space: pre-line;
    margin: 0;
  }

  * + p {
    margin-top: 0.8rem;
  }

  a {
    transition: color 0.3s;
    color: ${({ theme }) => theme.colors.blue};
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.colors.blueDark};
    }
  }
`;
