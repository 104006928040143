import { put } from 'redux-saga/effects';

import { unauthenticate } from 'domains/authentication/actions';
import { activateModal } from 'domains/modal/actions';
import { ErrorPayload } from 'types';

// We use the isFiringUnauthenticatedSingleton to throttle the calls to log
// the user out. This happens whenever we get a 401 response from an endpoint,
// so we could end up calling it multiple times in quick succession.
let isFiringUnauthenticatedSingleton = false;

const getThrottledUnauthenticated = () => {
  if (isFiringUnauthenticatedSingleton) {
    return true;
  }
  isFiringUnauthenticatedSingleton = true;
  setTimeout(() => {
    isFiringUnauthenticatedSingleton = false;
  }, 2000);
  return false;
};

type UnauthenticateAction = { meta?: { logoutOnAuthError?: boolean }; payload?: ErrorPayload };

export function* unauthenticatedErrorSaga({ meta, payload }: UnauthenticateAction) {
  const shouldLogoutOnAuthError = meta && meta.logoutOnAuthError;
  const error = payload && payload.error;

  if (error && shouldLogoutOnAuthError) {
    const statusCode = error && error.status;
    if (statusCode === 401 && !getThrottledUnauthenticated()) {
      yield put(unauthenticate());
      yield put(activateModal({ id: 'Unauthenticated' }));
    }
  }
}
