import { forwardRef, MouseEvent, useEffect, useState } from 'react';
import { css, styled, ThemeProvider } from 'styled-components';

import { UIButton } from 'common/components';
import { breakpoints, breakpointValues } from 'common/styles';
import { IncidentBanner } from 'domains/incidents/components/IncidentBanner';
import { AuthButtons } from 'domains/landing/components/AuthButtons';
import { StyledLandingTitle } from 'domains/landing/components/StyledLandingTitle';
import { useTranslations } from 'domains/language/useTranslations';
import { darkTheme } from 'domains/theme/constants/theme';

const StyledIncidentBanner = styled(IncidentBanner)`
  flex-grow: 0;
  margin-bottom: 6rem;
`;

const StyledBannerContent = styled.div<{ hasLoaded: boolean }>`
  transition:
    opacity 0.3s ease-in-out,
    transform 0.3s ease-in-out;
  position: relative;
  padding: 1rem;
  box-sizing: border-box;
  max-width: 1600px;
  margin: auto;
  ${({ hasLoaded }) => css`
    transform: ${`scale(${hasLoaded ? 1.0 : 1.3})`};
    opacity: ${hasLoaded ? 1 : 0};
  `};

  @media (max-width: ${breakpointValues.medium - 1}px) {
    button {
      width: 75%;
      margin: auto;
      max-width: 250px;

      > div {
        width: 100%;
        justify-content: space-between;
      }
    }
  }
`;

const StyledBannerSubTitle = styled.p`
  font-size: 1.5rem;
  margin: 0 0 1rem 0;
`;

const StyledBannerDescription = styled.p`
  font-size: 0.9rem;
  font-weight: bold;
  margin: 0 0 1rem 0;
`;

const StyledVideoPlayButton = styled(UIButton)`
  width: 100%;

  @media ${breakpoints.medium} {
    width: auto;
  }
`;

// Default text as this is the first text the user sees on the public landing page
const defaultSubtitle = 'Strategic insights and contextual intelligence from the World Economic Forum';
const defaultText =
  'Explore and monitor the issues and forces driving transformational change across economies, industries, and global issues';

type VideoOverlayContentProps = { className?: string; handleWatchVideoClick: (event: MouseEvent) => void };

export const VideoOverlayContent = forwardRef<HTMLDivElement, VideoOverlayContentProps>(
  ({ className, handleWatchVideoClick }, ref) => {
    const { textDictionary } = useTranslations();
    const [hasLoaded, setHasLoaded] = useState(false);

    useEffect(() => {
      requestAnimationFrame(() => {
        setHasLoaded(true);
      });
    }, []);

    return (
      <ThemeProvider theme={darkTheme}>
        <StyledBannerContent className={className} ref={ref} hasLoaded={hasLoaded}>
          <StyledIncidentBanner />
          <StyledLandingTitle>Strategic Intelligence</StyledLandingTitle>
          <StyledBannerSubTitle>
            {textDictionary['app.discover.curatedBanner.subtitle'] || defaultSubtitle}
          </StyledBannerSubTitle>
          <StyledBannerDescription>
            {textDictionary['app.discover.curatedBanner.text'] || defaultText}
          </StyledBannerDescription>
          <AuthButtons />
          <StyledVideoPlayButton variant={'outlined'} onClick={handleWatchVideoClick} icon={'play'}>
            {textDictionary['app.discover.curatedBanner.button']}
          </StyledVideoPlayButton>
        </StyledBannerContent>
      </ThemeProvider>
    );
  },
);

VideoOverlayContent.displayName = 'VideoOverlayContent';
