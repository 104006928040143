import { addLanguageChangeReset } from 'domains/language/reducerHelpers';
import {
  TopicContentMetaTypes,
  TopicFetchContentMetaAction,
  TopicFetchContentMetaFailureAction,
  TopicFetchContentMetaSuccessAction,
} from 'domains/topicSearchFilter/actionTypes';
import { TopicContentMetaState } from 'domains/topicSearchFilter/types';

const initialState: TopicContentMetaState = {
  isPending: false,
  error: false,
  filters: null,
};

type Action = TopicFetchContentMetaAction | TopicFetchContentMetaFailureAction | TopicFetchContentMetaSuccessAction;

const reducer = (state: TopicContentMetaState = initialState, action: Action) => {
  switch (action.type) {
    case TopicContentMetaTypes.FETCH_CONTENT_META: {
      return {
        ...state,
        isPending: true,
        error: false,
      };
    }

    case TopicContentMetaTypes.FETCH_CONTENT_META_SUCCESS: {
      return {
        ...state,
        isPending: false,
        error: false,
        filters: action.payload.filters,
      };
    }

    case TopicContentMetaTypes.FETCH_CONTENT_META_FAILURE: {
      return {
        ...state,
        isPending: false,
        error: true,
      };
    }

    default:
      return state;
  }
};

export const topicContentMetaReducer = addLanguageChangeReset(reducer, initialState);
