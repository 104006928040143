import { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UIButton, StyledModalText } from 'common/components';
import { trackEvent } from 'domains/analytics/actions';
import { TrackMeta } from 'domains/analytics/types';
import { getUpgradeRedirectUrl, UpgradeLocation } from 'domains/authentication/sagas/upgradeRedirect';
import { ModalHeader } from 'domains/modal/components/ModalHeader';
import { getUserRole } from 'entities/user/selectors';
import { getLinkToMembershipPage } from 'entities/user/utils';

type UpgradeModalProps = {
  additionalPrimaryButtonTrackMeta?: object;
  message: ReactNode;
  primaryButtonText: string;
  title: string;
  upgradeLocation?: UpgradeLocation;
};

export const UpgradeModal = ({
  title,
  message,
  primaryButtonText,
  additionalPrimaryButtonTrackMeta,
  upgradeLocation = 'overview',
}: UpgradeModalProps) => {
  const dispatch = useDispatch();
  const userRole = useSelector(getUserRole);

  const upgradeRedirectUrl = getUpgradeRedirectUrl(upgradeLocation);

  const onUpgradeHandle = () => {
    let trackEventMeta: TrackMeta = {
      name: 'Upgrade',
      ...additionalPrimaryButtonTrackMeta,
    };
    if (upgradeRedirectUrl) {
      trackEventMeta = {
        ...trackEventMeta,
        callback: () => window.open(upgradeRedirectUrl),
      };
    }
    dispatch(trackEvent(trackEventMeta));
    if (!upgradeRedirectUrl) {
      window.location.href = getLinkToMembershipPage(userRole);
    }
  };

  return (
    <>
      <ModalHeader title={title} />
      {message && <StyledModalText>{message}</StyledModalText>}
      <UIButton onClick={onUpgradeHandle} variant={'filled'} fullwidth>
        {primaryButtonText}
      </UIButton>
    </>
  );
};
