import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { UIButton } from 'common/components';
import { breakpoints } from 'common/styles';
import { trackEvent } from 'domains/analytics/actions';
import { CreateLandingTitle } from 'domains/create/landing/CreateLandingTitle';
import { UpgradeAccountForCreateCallToAction } from 'domains/create/landing/UpgradeAccountForCreateCallToAction';
import { enterAIGeneratedMap } from 'domains/createMapDetail/actions';
import { useTranslations } from 'domains/language/useTranslations';
import { activateModal } from 'domains/modal/actions';
import { useRouterNavigation } from 'domains/routing/utils';
import { getCustomTopicsCreatedByUser, getCustomTopicsIsPending } from 'entities/topics/selectors';
import { getCreateMapLimit, getIsCreateMapRestricted, getCanAccessGenerativeMaps } from 'entities/user/selectors';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1500px;
  padding: 5rem 3rem 4rem;
  box-sizing: border-box;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @media ${breakpoints.medium} {
    flex-direction: row;
  }
`;

const StyledAssistantButton = styled(UIButton)`
  display: none;
  @media ${breakpoints.large} {
    display: block;
  }
`;

export const CreateNewMapSection = () => {
  const dispatch = useDispatch();
  const mapLimit = useSelector(getCreateMapLimit);
  const isCreateMapRestricted = useSelector(getIsCreateMapRestricted);
  const myTopics = useSelector(getCustomTopicsCreatedByUser());
  const isPending = useSelector(getCustomTopicsIsPending);
  const { textDictionary } = useTranslations();
  const [isCallToActionVisible, setCallToActionVisible] = useState(false);
  const accessToGenerativeMaps = useSelector(getCanAccessGenerativeMaps);
  const navigateToNewMap = useRouterNavigation({ to: 'create', params: { mode: 'map-new' } });
  const canCreate = !isCreateMapRestricted || (!isPending && myTopics.length < mapLimit);

  const handleCreateMapClick = () => {
    if (canCreate) {
      navigateToNewMap();
    } else {
      dispatch(activateModal({ id: 'Create Upgrade Membership' }));
    }
  };

  const handleCreateMapWithAssistantClick = () => {
    const trackChannel = canCreate ? 'Wizard' : 'Call to action';
    dispatch(
      trackEvent({
        name: 'Open Map Assistant',
        page: 'Create landing',
        Channel: trackChannel,
      }),
    );
    if (!canCreate) {
      dispatch(activateModal({ id: 'Create Upgrade Membership' }));
    } else if (!accessToGenerativeMaps) {
      setCallToActionVisible(true);
    } else {
      dispatch(enterAIGeneratedMap());
      navigateToNewMap();
    }
  };

  return (
    <StyledContainer>
      <CreateLandingTitle />
      <StyledButtonContainer>
        <UIButton
          data-test-id="create-a-new-custom-map-button"
          disabled={isPending}
          icon="rightArrow"
          isLoading={!myTopics.length && isPending}
          onClick={handleCreateMapClick}
          variant="filled"
        >
          {textDictionary['app.create.landing_banner.button']}
        </UIButton>
        <StyledAssistantButton
          data-test-id="create-a-new-custom-map-with-assistant-button"
          disabled={isPending}
          icon="magicWand"
          isLoading={!myTopics.length && isPending}
          onClick={handleCreateMapWithAssistantClick}
          variant="filled"
        >
          {textDictionary['app.create.landing_banner.create_with_ai_button']}
        </StyledAssistantButton>
      </StyledButtonContainer>
      <UpgradeAccountForCreateCallToAction
        isCallToActionVisible={isCallToActionVisible}
        setCallToActionVisible={setCallToActionVisible}
        type={'digitalMemberCTA'}
      />
    </StyledContainer>
  );
};
