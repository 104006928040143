import { EventSession } from 'entities/events/types';
import { ErrorMeta } from 'types';

export enum EventType {
  FETCH_FOR_TOPIC = '[Events] - Fetch for topic',
  FETCH_FOR_TOPIC_SUCCESS = '[Events] - Fetch for topic successful',
  FETCH_FOR_TOPIC_FAILURE = '[Events] - Fetch for topic failure',
  FETCH_FOR_ID = '[Events] - Fetch for id',
  FETCH_FOR_ID_SUCCESS = '[Events] - Fetch for id successful',
  FETCH_FOR_ID_FAILURE = '[Events] - Fetch for id failure',
}

type TopicEventPayload = { id: string; limit: number; offset: number; searchTerm?: string; year?: string };
export type FetchEventDefaultAction = { payload: TopicEventPayload; type: EventType.FETCH_FOR_TOPIC };

export type FetchEventFulfilledAction = {
  meta: TopicEventPayload;
  payload: { data: EventSession[]; topicId: string };
  type: EventType.FETCH_FOR_TOPIC_SUCCESS;
};

type FetchEventRejectedMeta = ErrorMeta & { searchCriteria: TopicEventPayload };

export type FetchEventRejectedAction = {
  meta: FetchEventRejectedMeta;
  payload: { topicId: string };
  type: EventType.FETCH_FOR_TOPIC_FAILURE;
};

export type FetchEventForIdAction = { meta: { id: string }; type: EventType.FETCH_FOR_ID };

export type FetchEventForIdFulfilledAction = {
  meta: { id: string };
  payload: EventSession;
  type: EventType.FETCH_FOR_ID_SUCCESS;
};

export type FetchEventForIdRejectedAction = {
  meta: ErrorMeta;
  payload: { id: string };
  type: EventType.FETCH_FOR_ID_FAILURE;
};

export type EventAction =
  | FetchEventForIdAction
  | FetchEventForIdFulfilledAction
  | FetchEventForIdRejectedAction
  | FetchEventDefaultAction
  | FetchEventFulfilledAction
  | FetchEventRejectedAction;
