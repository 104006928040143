import { useDispatch, useSelector } from 'react-redux';

import { StyledModalText, UIButton } from 'common/components';
import { pushHistory } from 'common/utilities';
import { removeInviteMapSharing } from 'domains/createShareSettings/actions';
import { useTranslations } from 'domains/language/useTranslations';
import { deactivateModal } from 'domains/modal/actions';
import { ModalHeader } from 'domains/modal/components/ModalHeader';
import { getModalParams } from 'domains/modal/selectors';

export const ConfirmRemoveModal = () => {
  const dispatch = useDispatch();
  const { textDictionary } = useTranslations();
  const { topicId, email } = useSelector(getModalParams);

  const handleCloseClick = () => dispatch(deactivateModal());

  const handleRemoveClick = () => {
    if (!topicId || !email) {
      return;
    }
    dispatch(removeInviteMapSharing({ topic_id: topicId, email, removeMap: true }));
    dispatch(deactivateModal());
    pushHistory('/create');
  };

  return (
    <>
      <ModalHeader title={textDictionary['app.create.map_share.confirm_remove_user.title']} />
      <StyledModalText>{textDictionary['app.create.map_share.confirm_remove_user']}</StyledModalText>
      <UIButton onClick={handleRemoveClick} error={true}>
        {textDictionary['app.topic.actions.delete']}
      </UIButton>
      <UIButton variant="outlined" onClick={handleCloseClick}>
        {textDictionary['app.create.create_map.cancel']}
      </UIButton>
    </>
  );
};
