import {
  InviteMeta,
  TogglePublicLinkMeta,
  MapRemoveInviteMeta,
  ChangeInvitePermissionMeta,
  InviteUser,
  CreateLinkMeta,
  CreateLinkPayload,
  OpenLinkInvite,
  AcceptInviteMeta,
} from 'domains/createShareSettings/types';
import { ErrorAction } from 'types';

export enum MapShareType {
  PUBLIC_LINK = 'domains/MAP_SHARE_PUBLIC_LINK',
  PUBLIC_LINK_FULFILLED = 'domains/MAP_SHARE_PUBLIC_LINK_FULFILLED',
  PUBLIC_LINK_REJECTED = 'domains/MAP_SHARE_PUBLIC_LINK_REJECTED',
  INVITE = 'domains/MAP_SHARE_INVITE',
  INVITE_FULFILLED = 'domains/MAP_SHARE_INVITE_FULFILLED',
  INVITE_REJECTED = 'domains/MAP_SHARE_INVITE_REJECTED',
  ACCESS_LIST = 'domains/MAP_ACCESS_LIST',
  ACCESS_LIST_FULFILLED = 'domains/MAP_ACCESS_LIST_FULFILLED',
  ACCESS_LIST_REJECTED = 'domains/MAP_ACCESS_LIST_REJECTED',
  REMOVE_INVITE = 'domains/MAP_SHARE_REMOVE_INVITE',
  REMOVE_INVITE_FULFILLED = 'domains/MAP_SHARE_REMOVE_INVITE_FULFILLED',
  REMOVE_INVITE_REJECTED = 'domains/MAP_SHARE_REMOVE_INVITE_REJECTED',
  CHANGE_INVITE_PERMISSION = 'domains/MAP_SHARE_CHANGE_PERMISSION',
  CHANGE_INVITE_PERMISSION_FULFILLED = 'domains/MAP_SHARE_CHANGE_PERMISSION_FULFILLED',
  CHANGE_INVITE_PERMISSION_REJECTED = 'domains/MAP_SHARE_CHANGE_PERMISSION_REJECTED',
  CREATE_LINK = 'domains/MAP_SHARE_CREATE_LINK',
  CREATE_LINK_FULFILLED = 'domains/MAP_SHARE_CREATE_LINK_FULFILLED',
  CREATE_LINK_REJECTED = 'domains/MAP_SHARE_CREATE_LINK_REJECTED',
  OPEN_MAP_INVITATION = 'domains/OPEN_MAP_INVITATION',
  OPEN_MAP_INVITATION_FULFILLED = 'domains/OPEN_MAP_INVITATION_FULFILLED',
  OPEN_MAP_INVITATION_REJECTED = 'domains/OPEN_MAP_INVITATION_REJECTED',
  ACCEPT_INVITE = 'domains/ACCEPT_MAP_INVITATION',
  ACCEPT_INVITE_FULFILLED = 'domains/ACCEPT_MAP_INVITATION_FULFILLED',
  ACCEPT_INVITE_REJECTED = 'domains/ACCEPT_MAP_INVITATION_REJECTED',
}

export type TogglePublicLinkDefaultAction = { meta: TogglePublicLinkMeta; type: MapShareType.PUBLIC_LINK };
export type TogglePublicLinkFulfilledAction = { meta: TogglePublicLinkMeta; type: MapShareType.PUBLIC_LINK_FULFILLED };
export type TogglePublicLinkRejectedAction = { meta: TogglePublicLinkMeta; type: MapShareType.PUBLIC_LINK_REJECTED };
export type MapInviteDefaultAction = { meta: InviteMeta; type: MapShareType.INVITE };
export type MapInviteFulfilledAction = { meta: InviteMeta; payload: InviteUser[]; type: MapShareType.INVITE_FULFILLED };
export type MapInviteRejectedAction = { meta: InviteMeta; type: MapShareType.INVITE_REJECTED };
export type MapAccessListDefaultAction = { meta: { id: string }; type: MapShareType.ACCESS_LIST };

export type MapAccessListFulfilledAction = {
  meta: { id: string };
  payload: InviteUser[];
  type: MapShareType.ACCESS_LIST_FULFILLED;
};

export type MapAccessListRejectedAction = { meta: { id: string }; type: MapShareType.ACCESS_LIST_REJECTED };
export type MapRemoveInviteDefaultAction = { meta: MapRemoveInviteMeta; type: MapShareType.REMOVE_INVITE };
export type MapRemoveInviteFulfilledAction = { meta: MapRemoveInviteMeta; type: MapShareType.REMOVE_INVITE_FULFILLED };
export type MapRemoveInviteRejectedAction = { meta: MapRemoveInviteMeta; type: MapShareType.REMOVE_INVITE_REJECTED };

export type ChangeInvitePermissionDefaultAction = {
  meta: ChangeInvitePermissionMeta;
  type: MapShareType.CHANGE_INVITE_PERMISSION;
};

export type ChangeInvitePermissionFulfilledAction = {
  meta: ChangeInvitePermissionMeta;
  type: MapShareType.CHANGE_INVITE_PERMISSION_FULFILLED;
};

export type ChangeInvitePermissionRejectedAction = {
  meta: ChangeInvitePermissionMeta;
  type: MapShareType.CHANGE_INVITE_PERMISSION_REJECTED;
};

export type CreateInviteLinkDefaultAction = { meta: CreateLinkMeta; type: MapShareType.CREATE_LINK };

export type CreateInviteLinkFulfilledAction = {
  meta: CreateLinkMeta;
  payload: CreateLinkPayload;
  type: MapShareType.CREATE_LINK_FULFILLED;
};

export type CreateInviteLinkRejectedAction = { meta: CreateLinkMeta; type: MapShareType.CREATE_LINK_REJECTED };
export type OpenMapInvitationDefaultAction = { meta: { token: string }; type: MapShareType.OPEN_MAP_INVITATION };

export type OpenMapInvitationFulfilledAction = {
  payload: OpenLinkInvite;
  type: MapShareType.OPEN_MAP_INVITATION_FULFILLED;
};

export type OpenMapInvitationRejectedAction = ErrorAction & { type: MapShareType.OPEN_MAP_INVITATION_REJECTED };
export type AcceptMapInviteDefaultAction = { meta: AcceptInviteMeta; type: MapShareType.ACCEPT_INVITE };
export type AcceptMapInviteFulfilledAction = { meta: AcceptInviteMeta; type: MapShareType.ACCEPT_INVITE_FULFILLED };
export type AcceptMapInviteRejectedAction = { meta: AcceptInviteMeta; type: MapShareType.ACCEPT_INVITE_REJECTED };

export type MapShareAction =
  | TogglePublicLinkDefaultAction
  | TogglePublicLinkFulfilledAction
  | TogglePublicLinkRejectedAction
  | MapInviteDefaultAction
  | MapInviteFulfilledAction
  | MapInviteRejectedAction
  | MapAccessListDefaultAction
  | MapAccessListFulfilledAction
  | MapAccessListRejectedAction
  | MapRemoveInviteDefaultAction
  | MapRemoveInviteFulfilledAction
  | MapRemoveInviteRejectedAction
  | ChangeInvitePermissionDefaultAction
  | ChangeInvitePermissionFulfilledAction
  | ChangeInvitePermissionRejectedAction
  | CreateInviteLinkDefaultAction
  | CreateInviteLinkFulfilledAction
  | CreateInviteLinkRejectedAction
  | OpenMapInvitationDefaultAction
  | OpenMapInvitationFulfilledAction
  | OpenMapInvitationRejectedAction;
