import { Children, ReactElement, useRef, useEffect, useState, useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import { css, styled } from 'styled-components';

import { ChevronIcon } from 'common/components/icons';
import { mediaStyles } from 'common/styles';

const StyledArrowContainer = styled.div`
  transition: 0.3s all;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: absolute;
  top: calc(50% - 20px);
  z-index: 900;
`;

const StyledRightArrowContainer = styled(StyledArrowContainer)`
  ${mediaStyles(({ spacing }) => `right: ${spacing}rem;`)};
`;

const StyledLeftArrowContainer = styled(StyledArrowContainer)`
  ${mediaStyles(({ spacing }) => `left: ${spacing}rem;`)};
`;

const StyledWrapper = styled.div`
  width: 100%;
  position: relative;

  &:hover {
    ${StyledArrowContainer} {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
`;

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scroll-padding: 10%;
  scroll-behavior: smooth;
  padding: 10px 0;
  -ms-overflow-style: none; /* Edge */
  scrollbar-width: none; /* Firefox */
  box-sizing: content-box;

  &::-webkit-scrollbar {
    display: none;
  }
`;

type StyledItemProps = { lastElement: boolean; minWidth?: number; numberOfItems: number; shouldWrap?: boolean };

const getWidth = (numberOfItems: number) => {
  if (numberOfItems === 1) {
    return '80%';
  }
  return `calc(${100 / numberOfItems}% - 45px)`;
};

const StyledItem = styled.div<StyledItemProps>`
  width: ${({ numberOfItems }) => getWidth(numberOfItems)};
  flex-shrink: 0;
  height: 100%;
  scroll-snap-align: start;
  padding: 0 0 0 15px;

  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth}px;
    `}

  ${({ lastElement }) =>
    mediaStyles(
      ({ spacing }) => `
      &:last-child {
        padding-right: ${lastElement ? 0 : spacing}rem;
      }
      &:first-child {
        padding-left: 0;
      }
    `,
    )}
`;

const StyledArrow = styled(ChevronIcon)`
  width: 16px;
  height: 16px;
  color: white;
`;

const StyledRightArrow = styled(ChevronIcon)`
  width: 16px;
  height: 16px;
  color: white;
`;

const StyledLeftArrow = styled(StyledArrow)`
  transform: rotate(180deg);
`;

type CarouselSliderProps = {
  children: ReactElement[];
  className?: string;
  minItemWidth?: number;
  numberOfItems?: number;
  shouldWrap?: boolean;
};

export const CarouselSlider = ({ children, numberOfItems = 1, minItemWidth, className }: CarouselSliderProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const displayArrows = Children.count(children) > numberOfItems;

  useEffect(() => {
    if (scrollRef.current) {
      setWidth(scrollRef.current.offsetWidth);
    }
  }, [numberOfItems]);

  const scrollToPrevious = useCallback(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy(-(width / numberOfItems), 0);
    }
  }, [width, numberOfItems]);

  const scrollToNext = useCallback(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy(width / numberOfItems, 0);
    }
  }, [width, numberOfItems]);

  return (
    <StyledWrapper>
      <StyledContainer ref={scrollRef} className={className}>
        {children.map((item, index) => (
          <StyledItem key={index} numberOfItems={numberOfItems} lastElement={!displayArrows} minWidth={minItemWidth}>
            {item}
          </StyledItem>
        ))}
      </StyledContainer>
      {displayArrows && !isMobile && (
        <>
          <StyledLeftArrowContainer onClick={scrollToPrevious}>
            <StyledLeftArrow name="Chevron2" />
          </StyledLeftArrowContainer>
          <StyledRightArrowContainer onClick={scrollToNext}>
            <StyledRightArrow name="Chevron2" />
          </StyledRightArrowContainer>
        </>
      )}
    </StyledWrapper>
  );
};
