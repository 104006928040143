import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { UIButtonsRow, UIActionButton } from 'common/components';
import { TrackPage } from 'domains/analytics/types';
import { toggleBookmark } from 'domains/bookmarks/actions';
import { Cluster } from 'domains/clusters/types';
import { shareLinkAction } from 'domains/share/actions';
import { TopicIcons } from 'domains/topic/components/TopicIcons';
import { getCanDisplayBookmarks } from 'entities/user/selectors';

const StyledFooter = styled.div`
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
  align-items: center;
  flex-flow: wrap;
`;

type ClusterFooterProps = { cluster: Cluster; shareLink?: string; showIcons: boolean; trackPage?: TrackPage };

export const ClusterFooter = ({ cluster, showIcons, shareLink, trackPage }: ClusterFooterProps) => {
  const { id, title, related_topics, is_bookmarked, isBookmarkPending } = cluster;
  const dispatch = useDispatch();

  const accessToBookmarks = useSelector(getCanDisplayBookmarks);

  const onBookmarkHandle = () =>
    dispatch(
      toggleBookmark({
        bookmark: cluster,
        isBookmarked: !is_bookmarked,
        entityType: 'Cluster',
        trackPage,
      }),
    );

  const onShareClick = () =>
    dispatch(
      shareLinkAction({
        id: id,
        name: title,
        link: `${process.env.REACT_APP_WEBSITE_URL}${shareLink}`,
        type: 'Cluster',
      }),
    );

  return (
    <StyledFooter>
      <UIButtonsRow>
        {shareLink && <UIActionButton type="share" onClick={onShareClick} />}
        {accessToBookmarks && (
          <UIActionButton
            type="bookmark"
            isActive={is_bookmarked}
            isLoading={isBookmarkPending}
            onClick={onBookmarkHandle}
          />
        )}
      </UIButtonsRow>
      {showIcons && <TopicIcons topics={related_topics} />}
    </StyledFooter>
  );
};
