import { call, put, select } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { getIdTokenFromLocalStorage } from 'common/utilities/apiFetch';
import { getIsAuthenticating } from 'domains/authentication/selectors';
import { fetchPermissionsFailure, fetchPermissionsSuccess } from 'entities/permissions/actions';
import { PermissionData } from 'entities/permissions/types';
import { isFetchError } from 'TypeCheckMethods';

const { localStorage } = window;

export function* fetchPermissions(accessToken?: string) {
  // we cant always rely on getting the token from state, as it might not be set it
  // in that case, we get it straight from local storage to make sure its correct
  const storedToken: string = getIdTokenFromLocalStorage();
  const userAccessToken = accessToken || storedToken;
  const payload: PermissionData = yield apiFetch('auth/permissions?client=intelligence_web', {
    accessToken: userAccessToken,
  });
  localStorage.setItem('permissions', JSON.stringify(payload));
  return payload;
}

export function* fetchPermissionsSaga() {
  const isAuthenticating: boolean = yield select(getIsAuthenticating);
  if (isAuthenticating) {
    // Permission fetching is done post-authentication with the new token,
    // so we opt out of doing it here
    return;
  }
  try {
    const payload: PermissionData = yield call(fetchPermissions);
    yield put(fetchPermissionsSuccess(payload));
  } catch (error) {
    if (isFetchError(error)) {
      yield put(fetchPermissionsFailure(error.message, { logoutOnAuthError: true }));
    }
  }
}
