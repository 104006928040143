import { memo } from 'react';
import { useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { Tile } from 'common/components';
import { lineTruncationStyle } from 'common/styles';
import { useTranslations } from 'domains/language/useTranslations';
import { CreateNavigation, DiscoverNavigation } from 'domains/routing/types';
import { useRouterLink } from 'domains/routing/utils';
import { TopicTileFollowButton } from 'domains/topic/components/topicTile/TopicTileFollowButton';
import { TopicTileIcons } from 'domains/topic/components/topicTile/TopicTileIcons';
import { Topic } from 'entities/topics/types';
import { getUserId } from 'entities/user/selectors';

const StyledLabelContainer = styled.div`
  padding: 7px 10px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  font-size: 0.9rem;
  height: 30px;
  box-sizing: border-box;
`;

const StyledHiddenContent = styled.span<{ hasSubtitle: boolean }>`
  display: flex;
  flex-direction: column;
  height: 0;
  font-size: 1rem;
  font-weight: 600;
  opacity: 1;
  transition:
    height 0.3s ease,
    padding 0.3s ease;
  overflow: hidden;

  @media (hover: none) {
    height: 1.2rem;
    padding-top: 10px;
  }
`;

const StyledTile = styled(Tile)`
  display: block;

  &:hover ${StyledHiddenContent} {
    height: 1.2rem;
    padding-top: 10px;
  }
`;

const StyledOwnerLabel = styled.p`
  margin: 0;
  ${lineTruncationStyle(1)}
`;

type TopicTileProps = Topic & {
  className?: string;
  hideFollowAction?: boolean;
  hideOwnershipLabel?: boolean;
  navigationDestination?: DiscoverNavigation | CreateNavigation;
  noLink?: boolean;
  onClick?: () => void;
};

export const TopicTile = memo(
  ({
    attachment,
    can_edit,
    className,
    hideFollowAction = false,
    hideOwnershipLabel = false,
    id,
    images,
    is_custom,
    is_new,
    name,
    navigationDestination,
    noLink,
    onClick,
    provider,
  }: TopicTileProps) => {
    const { textDictionary } = useTranslations();
    const defaultDestination: DiscoverNavigation = {
      to: 'discover',
      params: { id },
    };
    const link = useRouterLink(navigationDestination || defaultDestination);
    const userId = useSelector(getUserId);

    const topicTileIcons = <TopicTileIcons attachment={attachment} isNew={is_new} />;
    let leftElement = topicTileIcons;
    let subElement: JSX.Element | undefined = undefined;
    if (is_custom && !hideOwnershipLabel) {
      const ownerLabel = 'app.create.share_settings.owner';
      const sharedKey = can_edit ? 'editor' : 'viewer';
      const sharedLabel = `app.create.share_settings.${sharedKey}`;
      const label = provider?.id === userId ? ownerLabel : sharedLabel;
      leftElement = (
        <>
          <StyledLabelContainer>{textDictionary[label]}</StyledLabelContainer>
          {topicTileIcons}
        </>
      );
      if (provider?.id !== userId) {
        subElement = (
          <StyledHiddenContent hasSubtitle={provider?.id !== userId}>
            <StyledOwnerLabel>{`${textDictionary[ownerLabel]}: ${provider?.name}`}</StyledOwnerLabel>
          </StyledHiddenContent>
        );
      }
    }

    return (
      <StyledTile
        className={className}
        title={name}
        link={noLink ? undefined : link}
        onClick={onClick}
        image={images?.x_small}
        leftElement={leftElement}
        rightElement={!hideFollowAction ? <TopicTileFollowButton id={id} /> : undefined}
        subElement={subElement}
      />
    );
  },
);

TopicTile.displayName = 'TopicTile';
