import { call, put } from 'redux-saga/effects';

import { pushHistory } from 'common/utilities';
import { activateModal } from 'domains/modal/actions';
import { fetchMyMaps } from 'entities/topics/actions';
import { FetchError } from 'types';

export function* handleCustomTopicUpdateFailureSaga(error: FetchError) {
  const { status } = error;
  if (status === 401) {
    return;
  }
  const topicNotFoundError = status && [403, 404].includes(status);
  const params = topicNotFoundError
    ? {
        localisedMessageKey: 'app.topic.unavailable.error_message',
      }
    : undefined;
  yield put(activateModal({ id: 'Error', params }));
  yield put(fetchMyMaps({ offset: 0, limit: 8 }));
  const isCurrentlyCreatePath = window.location.href.includes('create');
  yield call(pushHistory, isCurrentlyCreatePath ? '/create' : '/discover');
}
