import { styled } from 'styled-components';

import { PlayIconCircled } from 'common/components';

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.375s ease;
`;

const StyledImageContainer = styled.div<{ height?: string }>`
  width: 100%;
  height: ${({ height }) => height || '175px'};
  position: relative;
  overflow: hidden;
  border-radius: 0.625rem;
  cursor: pointer;

  &:hover {
    ${StyledImage} {
      transform: translate3d(0, 0, 0) scale(1.2);
    }
  }
`;

const StyledInfo = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;

const StyledPlayIcon = styled(PlayIconCircled)`
  transition: transform 0.3s;
  width: 45px;
  height: 45px;
  color: white;
  position: absolute;
  left: 1rem;
  bottom: 1rem;
`;

const StyledVideoLengthText = styled.span`
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 7px 8px;
  border-radius: 5px;
  position: absolute;
  right: 1rem;
  bottom: 1rem;
`;

type VideoCoverProps = { height?: string; image: string; videoLength: string };

export const VideoCover = ({ height, image, videoLength }: VideoCoverProps) => (
  <StyledImageContainer height={height}>
    <StyledImage src={image} />
    <StyledInfo>
      <StyledPlayIcon />
      <StyledVideoLengthText>{videoLength}</StyledVideoLengthText>
    </StyledInfo>
  </StyledImageContainer>
);
