import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css, styled } from 'styled-components';

import { breakpoints, easing } from 'common/styles';
import { getHexWithAlpha } from 'common/utilities';
import { deactivateModal, popModalScreen } from 'domains/modal/actions';
import { Modal } from 'domains/modal/components/Modal';
import { modalStack } from 'domains/modal/modalStack';
import { getActiveModalId, getActiveModals, getModalParams } from 'domains/modal/selectors';
import { lightTheme } from 'domains/theme/constants/theme';

const StyledWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

type StyledProps = { isActive: boolean };

const StyledOverlay = styled.div<StyledProps>`
  transition:
    background-color 0.3s ease,
    transform 0s ease ${({ isActive }) => (isActive ? 0 : 0.3)}s;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translate3d(${({ isActive }) => (isActive ? 0 : '100%')}, 0, 0);
  background-color: ${({ isActive, theme }) =>
    isActive ? getHexWithAlpha(theme.colors.fillPrimary, 0.4) : 'transparent'};
  z-index: 1000;
`;

const StyledModal = styled.div<{ isActive: boolean; presetTheme?: string }>`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  max-height: 100%;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  transition: transform 0.3s ${easing.slightBounce};

  ${({ theme, isActive, presetTheme }) => css`
    background-color: ${presetTheme === 'light'
      ? lightTheme.colors.backgroundSecondary
      : theme.colors.backgroundSecondary};
    color: ${theme.colors.fillPrimary};
    transform: translate(0, ${isActive ? 0 : 100}%);

    @media ${breakpoints.large} {
      overflow-y: hidden;
      top: 0;
      transition: transform 0.3s;
      width: 550px;
      height: 100%;
      transform: translate(${isActive ? 0 : 100}%, 0);
    }
  `}
`;

const StyledScrollContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
`;

const StyledInnerContent = styled.div<{ numberOfItems: number }>`
  display: flex;
  width: 100%;
  height: 100%;
  transition:
    transform 0.3s,
    opacity 0.3s;
  ${({ numberOfItems }) => css`
    transform: translate(${Math.max(0, numberOfItems - 1) * -100}%, 0);
  `}
`;

const StyledScreen = styled.div<{ isActive: boolean }>`
  min-width: 100%;
  width: 100%;
  min-height: 100%;
  height: 100%;

  ${({ isActive }) => css`
    transition:
      opacity 0.3s,
      height 0.3s;
    opacity: ${isActive ? 1 : 0};
    ${!isActive &&
    css`
      overflow-y: hidden;
      height: 0px;
    `}
  `};
`;

export const ModalProvider = ({ children }: PropsWithChildren) => {
  const dispatch = useDispatch();
  const activeModalId = useSelector(getActiveModalId);
  const modals = useSelector(getActiveModals);
  const params = useSelector(getModalParams);

  const [isActive, setIsActive] = useState(false);

  const handleDeactivate = useCallback(() => {
    if (params.dismissAction) params.dismissAction();
    else dispatch(params.popOnClose ? popModalScreen() : deactivateModal());
  }, [dispatch, params]);

  useEffect(() => {
    requestAnimationFrame(() => {
      setIsActive(!!activeModalId);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeModalId]);

  return (
    <StyledWrapper>
      {children}
      <StyledOverlay isActive={isActive} onClick={handleDeactivate} />
      <StyledModal isActive={isActive} presetTheme={params.theme}>
        <StyledScrollContainer>
          <StyledInnerContent numberOfItems={modals.length}>
            {modals.map((modal) => {
              const ModalComponent = modalStack[modal];
              return (
                <StyledScreen key={modal} isActive={modal === activeModalId}>
                  <Modal dismissAction={handleDeactivate}>
                    <ModalComponent />
                  </Modal>
                </StyledScreen>
              );
            })}
          </StyledInnerContent>
        </StyledScrollContainer>
      </StyledModal>
    </StyledWrapper>
  );
};
