import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

import { RightArrow } from 'common/components/icons';
import { UIButton } from 'common/components/UIButton';
import { useInternalLink } from 'common/utilities';
import { useTranslations } from 'domains/language/useTranslations';

type SeeAllButtonProps = { children?: ReactNode; className?: string; link: string; withoutText?: boolean };

const StyledArrow = styled(RightArrow)`
  width: 14px;
  height: 14px;
  margin-left: 0.5rem;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  display: block;
`;

const StyledUIButton = styled(UIButton)`
  padding: 0;
  width: 40px;

  svg {
    margin-left: 0;
  }
`;

export const SeeAllButton = ({ link, withoutText = false, children, className }: SeeAllButtonProps) => {
  const { textDictionary } = useTranslations();
  const to = useInternalLink(link);

  return (
    <StyledLink to={to}>
      {withoutText ? (
        <StyledUIButton variant={'neutral'} className={className}>
          <StyledArrow />
        </StyledUIButton>
      ) : (
        <UIButton variant={'neutral'} className={className}>
          {children || textDictionary['app.topic.see_all']}
          <StyledArrow />
        </UIButton>
      )}
    </StyledLink>
  );
};
