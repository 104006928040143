import {
  FetchMembershipEventsAction,
  FetchMembershipEventsSuccessAction,
  FetchMembershipEventsFailureAction,
  MembershipEventsTypes,
  FetchMembershipEventByIdAction,
  FetchMembershipEventByIdSuccessAction,
  FetchMembershipEventByIdFailureAction,
} from 'domains/membershipEvents/actionTypes';

export const fetchMembershipEvents = (): FetchMembershipEventsAction => ({
  type: MembershipEventsTypes.FETCH,
});

export const fetchMembershipEventsSuccess = (
  payload: FetchMembershipEventsSuccessAction['payload'],
): FetchMembershipEventsSuccessAction => ({
  type: MembershipEventsTypes.FETCH_FULFILLED,
  payload,
});

export const fetchMembershipEventsFailure = (): FetchMembershipEventsFailureAction => ({
  type: MembershipEventsTypes.FETCH_REJECTED,
});

export const fetchMembershipEventById = (
  meta: FetchMembershipEventByIdAction['meta'],
): FetchMembershipEventByIdAction => ({
  type: MembershipEventsTypes.FETCH_BY_ID,
  meta,
});

export const fetchMembershipEventByIdSuccess = (
  payload: FetchMembershipEventByIdSuccessAction['payload'],
  meta: FetchMembershipEventByIdSuccessAction['meta'],
): FetchMembershipEventByIdSuccessAction => ({
  type: MembershipEventsTypes.FETCH_BY_ID_FULFILLED,
  meta,
  payload,
});

export const fetchMembershipEventByIdFailure = (
  meta: FetchMembershipEventByIdFailureAction['meta'],
): FetchMembershipEventByIdFailureAction => ({
  type: MembershipEventsTypes.FETCH_BY_ID_REJECTED,
  meta,
});
