import { getAddedTopics, getRemovedTopics } from 'domains/createMapDetail/utils';
import { getDimensionById } from 'entities/keyIssues/selectors';
import { KeyIssue } from 'entities/keyIssues/types';
import { getTopicById } from 'entities/topics/selectors';
import { RootState } from 'types';

export const getMapIsPending = (state: RootState) => state.domains.createMap.isPending;

export const getCurrentMap = (state: RootState) => state.domains.createMap.data;

export const getLocalImage = (state: RootState) => state.domains.createMap.image;

export const getCanUploadMap = (state: RootState) => {
  const name = getCurrentCustomMapName(state);
  const images = state.domains.createMap.data?.images;
  const dimensions = getCurrentMapKeyIssues(state);
  return !!name && (!!images || !!state.domains.createMap.image) && dimensions.length !== 0;
};

export const getCurrentMapKeyIssuesIds = (state: RootState) => state.domains.createMap.keyIssues;

export const getCurrentMapNewCustomKeyIssues = (state: RootState) => state.domains.createMap.newCustomKeyIssues;

export const getIsCustomKeyIssueNew = (entityId: string) => (state: RootState) =>
  getCurrentMapNewCustomKeyIssues(state).some(({ id }) => id === entityId);

export const getCurrentMapExistingKeyIssuesIds = (state: RootState) => {
  const allCurrentMapKeyIssuesIds = getCurrentMapKeyIssuesIds(state);
  const allCurrentMapNewKeyIssues = getCurrentMapNewCustomKeyIssues(state);
  const allCurrentMapNewKeyIssuesIds = allCurrentMapNewKeyIssues.map(({ id }) => id);
  return allCurrentMapKeyIssuesIds.filter((keyIssueId) => !allCurrentMapNewKeyIssuesIds.includes(keyIssueId));
};

export const getCurrentMapKeyIssues = (state: RootState): KeyIssue[] => {
  const keyIssues: KeyIssue[] = [];
  state.domains.createMap.keyIssues.forEach((id) => {
    const keyIssue: KeyIssue = getDimensionById(id)(state);
    if (keyIssue) {
      keyIssues.push(keyIssue);
      return;
    }
    const customKeyIssue = state.domains.createMap.newCustomKeyIssues.find(
      (customKeyIssue) => customKeyIssue.id === id,
    );
    if (customKeyIssue) {
      const topics = customKeyIssue.topics.map(({ id }) => getTopicById(state, id));
      keyIssues.push({
        ...customKeyIssue,
        is_custom_dimension: true,
        isPending: false,
        topics: topics,
      });
    }
  });
  return keyIssues;
};

export const getCurrentMapCustomKeyIssues = (state: RootState) =>
  getCurrentMapKeyIssues(state).filter((topic) => topic.is_custom_dimension);

export const getIsKeyIssuesDragging = (state: RootState) => state.domains.createMap.isDraggingKeyIssue;

export const getMapHasChanges = (state: RootState) => {
  const map = getCurrentMap(state);
  const dimensions = getCurrentMapKeyIssues(state);
  const name = getCurrentCustomMapName(state);
  const description = getCurrentCustomMapDescription(state);
  const localImage = getLocalImage(state);

  if (!map) {
    return false;
  }

  const savedDimensions = (map.dimensions || []).map(({ id }) => id);
  const dimensionIds = dimensions.map(({ id }) => id);
  // Custom dimensions get saved automatically, so no need to track changes on
  // adding these
  const nonCustomDimensionIds = dimensions
    .filter(({ is_custom_dimension }) => !is_custom_dimension)
    .map(({ id }) => id);
  const dimensions_add = getAddedTopics(savedDimensions, nonCustomDimensionIds);
  const dimensions_remove = getRemovedTopics(savedDimensions, dimensionIds);

  return (
    dimensions_add.length !== 0 ||
    dimensions_remove.length !== 0 ||
    map.name !== name ||
    map.description !== description ||
    !!localImage
  );
};

export const getCurrentCustomMapName = (state: RootState) => state.domains.createMap.name;

export const getCurrentCustomMapDescription = (state: RootState) => state.domains.createMap.description;

export const getCurrentMapIsNew = (state: RootState) => !state.domains.createMap.data?.id;

export const getAIGeneratedMapProgress = (state: RootState) => state.domains.createMap.AIGeneratedMapProgress;

export const getIsAIGeneratedMapFetching = (state: RootState) => state.domains.createMap.isAIGeneratedMapFetching;

export const getAIGeneratedMapErrorCode = (state: RootState) => state.domains.createMap.AIGeneratedMapErrorCode;

export const getIsAIGeneratedModeOn = (state: RootState) => state.domains.createMap.isAIGeneratedModeOn;
