import {
  ApplyInitiativesSearchCriteriaAction,
  TopicInitiativeType,
  ClearInitiativesSearchCriteriaAction,
} from 'domains/initiatives/actionTypes';

export const applyInitiativesSearchCriteria = (
  payload: ApplyInitiativesSearchCriteriaAction['payload'],
): ApplyInitiativesSearchCriteriaAction => ({
  type: TopicInitiativeType.APPLY_SEARCH_CRITERIA,
  payload,
});

export const clearInitiativesSearchCriteria = (): ClearInitiativesSearchCriteriaAction => ({
  type: TopicInitiativeType.CLEAR_SEARCH_CRITERIA,
});
