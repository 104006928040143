import { useSelector } from 'react-redux';

import { ModalErrors, StyledModalTextLabel } from 'common/components';
import { useTranslations } from 'domains/language/useTranslations';
import { FIRST_NAME_FIELD_NAME, LAST_NAME_FIELD_NAME, FieldsType } from 'domains/user/components/UserProfileModal';
import { getProfileUpdateError } from 'entities/user/selectors';

type UserProfileAccountModalErrorsProps = { isFieldsMissing: boolean; onLabelClick: (id: FieldsType) => void };

export const UserProfileAccountModalErrors = ({
  isFieldsMissing,
  onLabelClick,
}: UserProfileAccountModalErrorsProps) => {
  const { textDictionary, interpolate } = useTranslations();
  const profileUpdateError = useSelector(getProfileUpdateError);

  const errorsData = {
    mandatoryFields: {
      isActive: isFieldsMissing,
      label: interpolate(textDictionary['app.registration.error.mandatory_fields'], {
        firstName: (firstName: string) => (
          <StyledModalTextLabel onClick={() => onLabelClick(FIRST_NAME_FIELD_NAME)}>{firstName}</StyledModalTextLabel>
        ),
        lastName: (lastName: string) => (
          <StyledModalTextLabel onClick={() => onLabelClick(LAST_NAME_FIELD_NAME)}>{lastName}</StyledModalTextLabel>
        ),
      }),
    },
    failedUpdate: {
      isActive: !isFieldsMissing && !!profileUpdateError,
      label: textDictionary['app.registration.error'],
    },
  };
  return <ModalErrors errors={errorsData} />;
};
