import { youtubeVideoUrlRegex } from 'common/utilities/youtubeVideoUrlRegex';

/**
 * Extracts the YouTube video ID from a given URL.
 *
 * @param url - The YouTube video URL.
 * @returns The YouTube video ID if found, otherwise undefined.
 */
export const extractYoutubeVideoId = (url: string) => {
  const match = url.match(youtubeVideoUrlRegex);
  return match === null ? undefined : match[1];
};
