import { SVGProps } from 'react';

export const InfoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <g fill="currentColor">
      <path d="M24 0a24 24 0 1024 24A24.027 24.027 0 0024 0zm0 45.045A21.046 21.046 0 1145.045 24 21.067 21.067 0 0124 45.045z" />
      <path d="M24.381 16.899a2.47 2.47 0 100-4.94 2.47 2.47 0 000 4.94zM23.733 20.93a1.477 1.477 0 00-1.477 1.478v12.544a1.477 1.477 0 102.955 0V22.419a1.478 1.478 0 00-1.478-1.488z" />
    </g>
  </svg>
);
