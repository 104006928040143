import { ComponentPropsWithoutRef, ReactNode } from 'react';
import { styled } from 'styled-components';

import { InfoIcon } from 'common/components';

const StyledTooltip = styled.span`
  background-color: ${({ theme }) => theme.colors.tooltipBackground};
  border: solid 1px ${({ theme }) => theme.colors.tooltipForeground};
  border-radius: 0.5rem;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.tooltipForeground};
  font-size: 0.75rem;
  left: 2rem;
  opacity: 0;
  padding: 0.5rem;
  position: absolute;
  top: -2px;
  transition: opacity 0.3s;
  visibility: hidden;
  z-index: 1;

  @media (max-width: 599px) {
    left: -4rem;
    top: 30px;
  }
`;

const StyledContainer = styled.div<{ maxTooltipWidth: number }>`
  position: relative;

  ${StyledTooltip} {
    width: ${({ maxTooltipWidth }) => `${maxTooltipWidth}px`};
  }

  &:hover {
    ${StyledTooltip} {
      opacity: 1;
      visibility: visible;
    }
  }
`;

const StyledInfoIcon = styled(InfoIcon)`
  flex-shrink: 0;
  height: 18px;
  width: 18px;
`;

type TooltipProps = Omit<ComponentPropsWithoutRef<'div'>, 'content'> & { content?: ReactNode; maxTooltipWidth: number };

const Tooltip = ({ children, content, ...rest }: TooltipProps) => (
  <StyledContainer {...rest}>
    {children}
    <StyledTooltip>{content}</StyledTooltip>
  </StyledContainer>
);

type InfoTooltipProps = Omit<TooltipProps, 'content'>;

export const InfoTooltip = ({ children, ...rest }: InfoTooltipProps) => (
  <Tooltip {...rest} content={children}>
    <StyledInfoIcon />
  </Tooltip>
);
