import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { fetchUnresolvedIncidentsSuccess, fetchUnresolvedIncidentsFailure } from 'domains/incidents/actions';
import { APIIncident } from 'domains/incidents/types';

export function* fetchUnresolvedIncidentsSaga() {
  try {
    const payload: APIIncident[] = yield apiFetch('incident/unresolved');
    yield put(fetchUnresolvedIncidentsSuccess(payload));
  } catch (error) {
    yield put(fetchUnresolvedIncidentsFailure({ error }));
  }
}
