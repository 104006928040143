import { SVGProps } from 'react';

export const CrossIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      d="M46.002 0l-22 22.015L1.999 0 0 1.998l22.015 22L0 46.001 1.998 48l22-22.015 22.004 22.01L48 45.997l-22.015-22L48 1.998 46.002 0z"
      fill="currentColor"
    />
  </svg>
);
