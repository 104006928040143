const DISMISSED_INCIDENTS_STORAGE_KEY = 'DISMISSED_INCIDENTS_STORAGE_KEY';
const LATEST_INCIDENT_DISMISSED_KEY = 'latest_unresolved_incident';

export const storeDismissedIncidents = (dismissedIds: string[]) =>
  localStorage.setItem(DISMISSED_INCIDENTS_STORAGE_KEY, JSON.stringify(dismissedIds));

export const retrieveDismissedIncidents = (): string[] => {
  const rawDismissedIds = localStorage.getItem(DISMISSED_INCIDENTS_STORAGE_KEY);
  if (!rawDismissedIds) {
    return [];
  }
  return JSON.parse(rawDismissedIds) || [];
};

export const retrieveLatestDismissedIncidentKey = () => localStorage.getItem(LATEST_INCIDENT_DISMISSED_KEY);
