import { SVGProps } from 'react';

export const ArrowTileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 444.097 444.097" {...props}>
    <polygon
      fill="currentColor"
      points="444.097,201.833 77.425,201.833 181.706,97.551 153.102,68.947 0,222.048 153.094,375.15 181.714,346.586 77.417,242.264 444.097,242.264"
    />
  </svg>
);
