import { MouseEvent } from 'react';
import { useDispatch } from 'react-redux';

import { StyledModalText, UIButton } from 'common/components';
import { pushHistory } from 'common/utilities';
import { useTranslations } from 'domains/language/useTranslations';
import { deactivateModal } from 'domains/modal/actions';
import { ModalHeader } from 'domains/modal/components/ModalHeader';

export const CollectionCancellationConfirmationModal = () => {
  const { textDictionary } = useTranslations();
  const dispatch = useDispatch();
  const route = '/create?mode=collection';

  const onClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    dispatch(deactivateModal());
    pushHistory(route);
  };

  return (
    <>
      <ModalHeader title={textDictionary['app.create.collection.modal.cancellation.title']} />
      <StyledModalText>{textDictionary['app.create.collection.modal.cancellation.text']}</StyledModalText>
      <UIButton as="a" error fullwidth href={route} onClick={onClick}>
        {textDictionary['app.create.collection.modal.cancellation.confirm']}
      </UIButton>
    </>
  );
};
