import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { StyledModalText, UIButton } from 'common/components';
import { trackEvent } from 'domains/analytics/actions';
import { useTranslations } from 'domains/language/useTranslations';
import { deactivateModal } from 'domains/modal/actions';
import { ModalHeader } from 'domains/modal/components/ModalHeader';
import { getModalParams } from 'domains/modal/selectors';
import { useRouterNavigation } from 'domains/routing/utils';
import { DestinationTypes } from 'domains/types';
import { removeMap } from 'entities/topics/actions';

const StyledMapTitle = styled.div`
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.modalCardBackground};
  border-radius: 5px;
  margin-bottom: 20px;
`;

export const ConfirmDeleteMapModal = () => {
  const dispatch = useDispatch();
  const { textDictionary } = useTranslations();
  const { topicId, name } = useSelector(getModalParams);
  const navigateToCreate = useRouterNavigation({ to: 'create' });

  const handleYes = () => {
    if (!topicId) {
      return;
    }
    dispatch(removeMap({ id: topicId, type: DestinationTypes.CUSTOM }));
    dispatch(
      trackEvent({
        name: 'Delete map',
        page: 'Create landing',
        entityId: topicId,
        entityType: 'Custom Map',
      }),
    );
    dispatch(deactivateModal());
    navigateToCreate();
  };

  return (
    <>
      <ModalHeader title={textDictionary['app.create.delete.modal_title']} />
      <StyledModalText>{textDictionary['app.create.delete.title']}</StyledModalText>
      <StyledMapTitle>{name}</StyledMapTitle>
      <UIButton data-test-id="confirm-deleting-custom-map-button" error fullwidth onClick={handleYes}>
        {textDictionary['app.create.delete.confirm']}
      </UIButton>
    </>
  );
};
