import { TopicVideosSearchCriteria } from 'domains/videos/types';

export enum TopicVideoActionTypes {
  APPLY_SEARCH_CRITERIA = 'domains/VIDEO_APPLY_SEARCH_CRITERIA',
  CLEAR_SEARCH_CRITERIA = 'domains/VIDEO_CLEAR_SEARCH_CRITERIA',
}

export type TopicVideoApplySearchCriteriaPayload = Partial<TopicVideosSearchCriteria> & { topicId?: string };

export type TopicVideoApplySearchCriteriaAction = {
  payload?: TopicVideoApplySearchCriteriaPayload;
  type: TopicVideoActionTypes.APPLY_SEARCH_CRITERIA;
};

export type TopicVideoClearSearchCriteriaAction = { type: TopicVideoActionTypes.CLEAR_SEARCH_CRITERIA };
