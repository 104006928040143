import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { uploadImageSuccess, uploadImageFailure } from 'domains/collection/actions';
import { UploadImageAction } from 'domains/collection/actionTypes';
import { ImageResponse } from 'domains/collection/types/collectionResponses';

export function* uploadImageSaga({ payload }: UploadImageAction) {
  try {
    const body = new FormData();
    body.append('object_type', payload.type);
    body.append('image', payload.image);
    const response = (yield apiFetch('image', { method: 'POST', body, noContentType: true })) as ImageResponse;
    yield put(uploadImageSuccess({ response, type: payload.type }));
  } catch (error) {
    yield put(uploadImageFailure());
    console.error(`[uploadImageSaga] Failed to upload image: ${error}`);
  }
}
