import debounce from 'lodash.debounce';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { ErrorMessage, HiddenImageUpload, UIButton } from 'common/components';
import { UIInput } from 'common/components/form/UIInput';
import { getCurrentMap } from 'domains/createMapDetail/selectors';
import { LocalImage } from 'domains/createMapDetail/types';
import {
  deleteTopicContent,
  scrapeTopicContentUrl,
  setTopicContentFile,
  updateTopicContentScrapedArticle,
  uploadTopicContent,
} from 'domains/customContentAndSources/actions';
import { SetTopicContentFile } from 'domains/customContentAndSources/actionTypes';
import { ArticlePreviewForm } from 'domains/customContentAndSources/components/ArticlePreviewForm';
import { DocumentPreviewForm } from 'domains/customContentAndSources/components/DocumentPreviewForm';
import {
  getCustomContentFormValid,
  getCustomContentIsDeleting,
  getCustomContentIsUploading,
  getCustomContentUploadError,
  getInProgressCustomContentId,
  getInProgressCustomDocument,
  getIsNewCustomContent,
  getScrapedCustomArticle,
} from 'domains/customContentAndSources/selectors';
import { useTranslations } from 'domains/language/useTranslations';

const StyledSearchInput = styled(UIInput)`
  margin-top: 1rem;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-bottom: 0;
`;

const StyledText = styled.p`
  color: ${({ theme }) => theme.colors.fillPrimary};
`;

const isFile = (blob: Blob): blob is File => !!(blob as File).name;

export const AddCustomContent = () => {
  const dispatch = useDispatch();
  const { textDictionary } = useTranslations();
  const currentMap = useSelector(getCurrentMap);
  const isUploading = useSelector(getCustomContentIsUploading);
  const hasUploadFailed = useSelector(getCustomContentUploadError);
  const inProgressDocument = useSelector(getInProgressCustomDocument);
  const scrapedArticle = useSelector(getScrapedCustomArticle);
  const isFormValid = useSelector(getCustomContentFormValid);
  const inProgressCustomContentId = useSelector(getInProgressCustomContentId);
  const isNewCustomContent = useSelector(getIsNewCustomContent);
  const isDeleting = useSelector(getCustomContentIsDeleting(inProgressCustomContentId));

  const [urlInput, setURLInput] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);

  const onSubmit = () => {
    if (!currentMap?.id || !isFormValid) {
      return;
    }

    dispatch(uploadTopicContent({ topicId: currentMap.id }));
  };

  const onRemove = () => {
    if (!inProgressCustomContentId || !currentMap?.id) {
      return;
    }

    dispatch(
      deleteTopicContent({
        id: inProgressCustomContentId,
        topicId: currentMap.id,
      }),
    );
  };

  const onSetDocument = ({ file }: LocalImage) => {
    const isFileTooBig = file.size / 1024 / 1024 >= 10;
    let payload: SetTopicContentFile['payload'] = {
      file: file,
      fileType: file.type,
      isFileTooBig,
    };
    if (isFile(file)) {
      payload = { ...payload, filename: file.name };
    }

    dispatch(setTopicContentFile(payload));
  };

  const onUploadDocumentClick = () => {
    inputRef.current?.click();
  };

  const isValidUrl = (url: string) => {
    let urlObject;
    try {
      urlObject = new URL(url);
    } catch (error) {
      return false;
    }
    return urlObject.protocol.startsWith('http');
  };

  const onURLSearch = (value = '') => {
    setURLInput(value);
    scrapeUrl(value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const scrapeUrl = useCallback(
    debounce(async (url: string) => {
      if (isValidUrl(url)) {
        dispatch(scrapeTopicContentUrl({ url }));
      } else {
        dispatch(updateTopicContentScrapedArticle(undefined));
      }
    }, 500),
    [],
  );

  useEffect(
    () => () => {
      dispatch(setTopicContentFile(undefined));
      dispatch(updateTopicContentScrapedArticle(undefined));
    },
    [dispatch],
  );

  useEffect(() => {
    if (!inProgressDocument && inputRef.current) {
      inputRef.current.value = '';
    }
  }, [inProgressDocument]);

  return (
    <>
      {isNewCustomContent && (
        <>
          <UIButton
            variant="filled"
            fullwidth
            onClick={onUploadDocumentClick}
            disabled={!!urlInput || isUploading}
            style={{ marginTop: '2rem' }}
          >
            {textDictionary['app.custom_attachment.upload_document']}
          </UIButton>
          <HiddenImageUpload
            ref={inputRef}
            onSetImage={onSetDocument}
            name="document"
            accept=""
            disabled={!!urlInput || isUploading}
          />
          <StyledText>{textDictionary['app.custom_attachment.url.help_text']}</StyledText>
          <StyledSearchInput
            disabled={!!inProgressDocument || isUploading}
            onChange={onURLSearch}
            placeholder={textDictionary['app.custom_attachment.url.title']}
            value={urlInput}
          />
        </>
      )}
      {inProgressDocument && <DocumentPreviewForm />}
      {(!!urlInput || !!scrapedArticle?.link) && <ArticlePreviewForm />}
      <StyledErrorMessage isActive={hasUploadFailed}>{textDictionary['app.auth.error']}</StyledErrorMessage>
      <UIButton
        variant="filled"
        fullwidth
        onClick={onSubmit}
        disabled={!isFormValid || isUploading}
        isLoading={isUploading}
        style={{ marginTop: '2rem' }}
      >
        {isNewCustomContent
          ? textDictionary['app.custom_key_issue.add_and_save']
          : textDictionary['app.create.create_map.save']}
      </UIButton>
      {!isNewCustomContent && (
        <UIButton
          fullwidth
          variant="filled"
          error
          onClick={onRemove}
          disabled={isDeleting}
          isLoading={isDeleting}
          style={{ marginTop: '1rem' }}
        >
          {textDictionary['app.topic.actions.delete']}
        </UIButton>
      )}
    </>
  );
};
