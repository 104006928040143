import { ModalContentType } from 'domains/modal/types';

export enum ModalTypes {
  ACTIVATE = '[Modal] - Activate',
  DEACTIVATE = '[Modal] - Deactivate',
  PUSH_SCREEN = '[Modal] - Push screen',
  POP_SCREEN = '[Modal] - Pop screen',
}

export type ModalActivateAction = {
  meta: { id: ModalContentType; params?: Record<string, any> };
  type: ModalTypes.ACTIVATE;
};

export type ModalDeactivateAction = { type: ModalTypes.DEACTIVATE };

export type ModalPushScreenAction = {
  meta: { id: ModalContentType; params?: Record<string, any> };
  type: ModalTypes.PUSH_SCREEN;
};

export type ModalPopScreenAction = { type: ModalTypes.POP_SCREEN };
export type ModalAction = ModalActivateAction | ModalDeactivateAction | ModalPushScreenAction | ModalPopScreenAction;
