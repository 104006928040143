import { useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { KnowledgeSignalContent } from 'domains/signals/components/KnowledgeSignalContent';
import { Signal } from 'domains/signals/types';
import { getCanAccessTopicSignals } from 'entities/user/selectors';

const StyledTransformativeTopicContent = styled.div`
  margin-top: 1rem;
`;

type KnowledgeSignalSectionProps = { signals?: Signal[] };

export const KnowledgeSignalSection = ({ signals }: KnowledgeSignalSectionProps) => {
  const hasAccessToSignals = useSelector(getCanAccessTopicSignals);
  const showHighlyTransformativeContent = hasAccessToSignals && signals && signals.length > 0;

  if (!showHighlyTransformativeContent) {
    return null;
  }

  return (
    <StyledTransformativeTopicContent>
      <KnowledgeSignalContent signals={signals} />
    </StyledTransformativeTopicContent>
  );
};
