import { SuggestionsAction, SuggestionsActionTypes } from 'domains/monitor/suggestions/actionTypes';
import { SuggestionsState } from 'domains/monitor/suggestions/types';
import { FollowTopicAction, TopicActionType } from 'entities/topics/actionTypes';
import { addUserChangeReset } from 'entities/user/utils';

const initialState: SuggestionsState = {
  isPending: false,
  ids: [],
  data: {},
};

const reducer = (state = initialState, action: SuggestionsAction | FollowTopicAction): SuggestionsState => {
  switch (action.type) {
    case SuggestionsActionTypes.SUGGESTIONS_FETCH: {
      return {
        ...state,
        isPending: true,
      };
    }
    case SuggestionsActionTypes.SUGGESTIONS_FETCH_FULFILLED: {
      const data = { ...state.data };
      action.payload.forEach((item) => {
        data[item.id] = item;
      });
      return {
        ...state,
        isPending: false,
        ids: action.payload.map((item) => item.id),
        data,
      };
    }
    case SuggestionsActionTypes.SUGGESTIONS_FETCH_REJECTED: {
      return {
        ...state,
        isPending: false,
      };
    }
    case TopicActionType.FOLLOW: {
      if (state.data[action.payload.id]) {
        return {
          ...state,
          data: {
            ...state.data,
            [action.payload.id]: {
              ...state.data[action.payload.id],
              isFollowingUpdating: true,
            },
          },
        };
      }
      return state;
    }
    case TopicActionType.FOLLOW_REJECTED:
    case TopicActionType.FOLLOW_FULFILLED: {
      if (state.data[action.payload.id]) {
        return {
          ...state,
          data: {
            ...state.data,
            [action.payload.id]: {
              ...state.data[action.payload.id],
              isFollowingUpdating: false,
            },
          },
        };
      }
      return state;
    }
    default:
      return state;
  }
};

export const suggestedTopicsReducer = addUserChangeReset(reducer, initialState);
