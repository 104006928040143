import { useState, useEffect, MouseEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from 'styled-components';

import { StyledModalText, Spinner, CheckIcon } from 'common/components';
import { LanguageCode } from 'common/types/languageCode';
import { fetchTerms } from 'domains/language/actions';
import { getSiteLanguage, getIsFetchingTerms } from 'domains/language/selectors';
import { useTranslations } from 'domains/language/useTranslations';
import { deactivateModal } from 'domains/modal/actions';
import { ModalHeader } from 'domains/modal/components/ModalHeader';
import { getLanguages } from 'entities/languages/selectors';

const StyledRow = styled.div<{ isSelected: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  cursor: pointer;
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0.7)};
`;

const StyledSpinner = styled(Spinner)`
  width: 16px;
  height: 16px;
  color: white;
`;

export const ChangeLanguageModal = () => {
  const dispatch = useDispatch();
  const isFetchingTerms = useSelector(getIsFetchingTerms);
  const selectedLanguageId = useSelector(getSiteLanguage);
  const languages = useSelector(getLanguages);
  const { textDictionary } = useTranslations();

  const [nextLanguageId, setNextLanguageId] = useState<string | null>(null);

  const handleSelectLanguage = (languageId: LanguageCode) => (event: MouseEvent) => {
    event.preventDefault();
    if (isFetchingTerms || selectedLanguageId === languageId) {
      return;
    }
    setNextLanguageId(languageId);
    dispatch(fetchTerms({ language: languageId }));
  };

  useEffect(() => {
    if (selectedLanguageId === nextLanguageId && !isFetchingTerms) {
      setNextLanguageId(null);
      setTimeout(() => {
        dispatch(deactivateModal());
      }, 750);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguageId, isFetchingTerms]);

  return (
    <>
      <ModalHeader title={textDictionary['app.language_chooser.title']} />
      <StyledModalText>{textDictionary['app.language_chooser.text']}</StyledModalText>
      {languages.map(({ id, label }) => (
        <StyledRow key={id} onClick={handleSelectLanguage(id)} isSelected={selectedLanguageId === id}>
          {label}
          {isFetchingTerms && nextLanguageId === id ? (
            <StyledSpinner />
          ) : (
            selectedLanguageId === id && <CheckIcon width={12} height={12} />
          )}
        </StyledRow>
      ))}
    </>
  );
};
