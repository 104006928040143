import { MouseEvent, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { css, keyframes, styled } from 'styled-components';

import { UIButton } from 'common/components';
import { breakpoints } from 'common/styles';
import { useTranslations } from 'domains/language/useTranslations';
import { getModalParams } from 'domains/modal/selectors';
import { lightTheme } from 'domains/theme/constants/theme';

const StyledContainer = styled.div<{ isAlwaysWhite: boolean }>`
  background-color: ${({ theme, isAlwaysWhite }) =>
    isAlwaysWhite ? theme.colors.white : theme.colors.modalBackground};
  width: 100%;
  box-sizing: border-box;
  padding: 0 1rem;
  min-height: 100%;

  @media (min-width: 480px) {
    padding: 0 2rem;
  }

  @media ${breakpoints.large} {
    padding: 0 3rem;
  }
`;

const cssAnimation = keyframes`
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const StyledInnerContainer = styled.div<{ hasDelay: boolean }>`
  padding-bottom: 2rem;
  animation-name: ${({ hasDelay }) => (hasDelay ? cssAnimation : '')};
  animation-duration: 0.4s;
  animation-direction: normal;

  @media ${breakpoints.large} {
    padding-bottom: 2.5rem;
  }
`;

const StyledButton = styled(UIButton)<{ isAlwaysWhite: boolean }>`
  ${({ isAlwaysWhite }) => css`
    color: ${isAlwaysWhite ? lightTheme.colors.buttonOutlinedText : ''};
    border-color: ${isAlwaysWhite ? lightTheme.colors.buttonOutlinedText : ''};

    &:focus-visible,
    &:hover {
      color: ${isAlwaysWhite ? lightTheme.colors.buttonOutlinedActiveText : ''};
      border-color: ${isAlwaysWhite ? lightTheme.colors.buttonOutlinedActiveBorder : ''};
      background-color: ${isAlwaysWhite ? lightTheme.colors.buttonOutlinedActiveBackground : ''};
    }
  `}
`;

type ModalProps = PropsWithChildren & { dismissAction: () => void; title?: string };

export const Modal = ({ children, dismissAction }: ModalProps) => {
  const { textDictionary } = useTranslations();
  const params = useSelector(getModalParams);

  const handleDeactivateClick = (event: MouseEvent) => {
    event.preventDefault();
    dismissAction();
  };

  return (
    <StyledContainer isAlwaysWhite={params.isAlwaysWhite === 'true'}>
      {children}
      <StyledInnerContainer hasDelay={params.hasDelay === 'true'}>
        <StyledButton
          variant="outlined"
          fullwidth
          style={{ marginTop: '1em' }}
          onClick={handleDeactivateClick}
          isAlwaysWhite={params.isAlwaysWhite === 'true'}
        >
          {textDictionary['app.faq_teaser.dismiss']}
        </StyledButton>
      </StyledInnerContainer>
    </StyledContainer>
  );
};
