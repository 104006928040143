import omit from 'lodash.omit';
import { combineReducers } from 'redux';

import {
  BriefingActionTypes,
  GenerateBriefingActions,
  GenerateBriefingButtonActions,
  GenerateBriefingButtonActionTypes,
} from 'domains/briefing/actionTypes';
import { Briefing } from 'domains/briefing/types';
import { addUserChangeReset } from 'entities/user/utils';

const initialState: BriefingState = {
  byId: {},
  disableGenerateButton: false,
};

type BriefingState = { byId: Record<string, Briefing>; disableGenerateButton: boolean };

const generateBriefingsButtonReducer = (
  state = initialState.disableGenerateButton,
  action: GenerateBriefingButtonActions,
) => {
  switch (action.type) {
    case GenerateBriefingButtonActionTypes.DISABLE_GENERATE_BUTTON:
      return true;
    case GenerateBriefingButtonActionTypes.ENABLE_GENERATE_BUTTON:
      return false;
    default:
      return state;
  }
};

const byIdReducer = (state = initialState.byId, action: GenerateBriefingActions): BriefingState['byId'] => {
  switch (action.type) {
    case BriefingActionTypes.TRIGGER_GENERATE_BRIEFING_SUCCESS:
      return {
        ...state,
        [action.payload.briefingId]: {
          status: 'Pending',
          briefingId: action.payload.briefingId,
          progress: 0,
          topicId: action.payload.topicId,
          topicName: action.payload.topicName,
          templateName: action.payload.templateName,
          content: {
            furtherReading: action.payload.content.furtherReading,
          },
        },
      };
    case BriefingActionTypes.MONITOR_PROGRESS_GENERATE_BRIEFING_SUCCESS: {
      const briefing = state[action.payload.briefingId];
      const status: Briefing['status'] = action.payload.progress === 100 ? 'Complete' : 'Pending';

      return {
        ...state,
        [action.payload.briefingId]: {
          ...briefing,
          progress: action.payload.progress,
          status: status,
        },
      };
    }
    case BriefingActionTypes.MONITOR_PROGRESS_GENERATE_BRIEFING_FAILURE:
    case BriefingActionTypes.FETCH_BRIEFING_FAILURE:
      return {
        ...state,
        [action.payload.briefingId]: {
          ...state[action.payload.briefingId],
          progress: 0,
          status: 'Failed',
        },
      };
    case BriefingActionTypes.FETCH_BRIEFING:
    case BriefingActionTypes.FETCH_BRIEFING_SUCCESS:
      return {
        ...state,
        [action.payload.briefingId]: {
          ...state[action.payload.briefingId],
          status: 'Complete',
        },
      };
    case BriefingActionTypes.REMOVE_BRIEFING:
      return omit(state, action.payload.briefingId);
    default:
      return state;
  }
};

export const briefingsReducer = combineReducers({
  byId: addUserChangeReset(byIdReducer, {}),
  disableGenerateButton: generateBriefingsButtonReducer,
});
