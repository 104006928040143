import { CollectionEditResponse, MappedCollectionEditResponse } from 'domains/collection/types/collectionResponses';

export const mapCollectionEditResponse = (response: CollectionEditResponse): MappedCollectionEditResponse => ({
  canEdit: response.can_edit,
  coBrands: response.co_brands,
  defaultLanguage: response.default_language,
  description: response.description,
  featuredVideoUrl: response.featured_video_url ?? undefined,
  heroImageUrl: response.hero_image_url,
  id: response.id,
  isFeatured: response.is_featured,
  isFollowing: response.is_following,
  isListed: response.is_listed,
  isPublic: response.is_public,
  name: response.name,
  spacesUrl: response.spaces_url ?? undefined,
  topicIds: response.topic_ids,
});
