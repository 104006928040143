import {
  FetchWhatsNewAction,
  WhatsNewActionType,
  FetchWhatsNewActionFulfilled,
  FetchWhatsNewActionRejected,
  UpdateWhatsNewBadgeAction,
} from 'domains/whatsNew/actionTypes';

export const fetchWhatsNew = (): FetchWhatsNewAction => ({
  type: WhatsNewActionType.FETCH,
});

export const fetchWhatsNewSuccess = (
  payload: FetchWhatsNewActionFulfilled['payload'],
): FetchWhatsNewActionFulfilled => ({
  type: WhatsNewActionType.FETCH_FULFILLED,
  payload,
});

export const fetchWhatsNewFailure = (payload: FetchWhatsNewActionRejected['payload']): FetchWhatsNewActionRejected => ({
  type: WhatsNewActionType.FETCH_REJECTED,
  payload,
});

export const updateWhatsNewBadge = (payload: UpdateWhatsNewBadgeAction['payload']): UpdateWhatsNewBadgeAction => ({
  type: WhatsNewActionType.UPDATE_BADGE,
  payload,
});
