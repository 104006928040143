import { ErrorMeta, FetchError } from 'types';

export enum LegacyTopicActionTypes {
  FETCH_BRIEFING = 'TOPIC_FETCH_BRIEFING',
  FETCH_BRIEFING_SUCCESS = 'TOPIC_FETCH_BRIEFING_SUCCESS',
  FETCH_BRIEFING_FAILURE = 'TOPIC_FETCH_BRIEFING_FAILURE',
  FETCH_SURVEY_LINK = 'TOPIC_FETCH_SURVEY_LINK',
  FETCH_SURVEY_LINK_SUCCESS = 'TOPIC_FETCH_SURVEY_LINK_SUCCESS',
  FETCH_SURVEY_LINK_FAILURE = 'TOPIC_FETCH_SURVEY_LINK_FAILURE',
  FETCH_PRESENTATION = '[Topic] - Fetch Presentation',
  FETCH_PRESENTATION_FULFILLED = '[Topic] - Fetch Presentation - Fulfilled',
  FETCH_PRESENTATION_REJECTED = '[Topic] - Fetch Presentation - Rejected',
}

type FetchLegacyTopicBriefingPayload = { id: string; name: string };

export type FetchLegacyTopicBriefingAction = {
  payload: FetchLegacyTopicBriefingPayload;
  type: LegacyTopicActionTypes.FETCH_BRIEFING;
};

export type FetchLegacyTopicBriefingSuccessAction = { type: LegacyTopicActionTypes.FETCH_BRIEFING_SUCCESS };

export type FetchLegacyTopicBriefingFailureAction = {
  meta: ErrorMeta;
  payload: { error: any };
  type: LegacyTopicActionTypes.FETCH_BRIEFING_FAILURE;
};

export type FetchSurveyLinkAction = { payload: string; type: LegacyTopicActionTypes.FETCH_SURVEY_LINK };
export type FetchSurveyLinkSuccessPayload = { link?: string };

export type FetchSurveyLinkSuccessAction = {
  payload: FetchSurveyLinkSuccessPayload;
  type: LegacyTopicActionTypes.FETCH_SURVEY_LINK_SUCCESS;
};

export type FetchSurveyLinkFailureAction = { type: LegacyTopicActionTypes.FETCH_SURVEY_LINK_FAILURE };

export type FetchTopicPresentationAction = {
  payload: FetchLegacyTopicBriefingPayload;
  type: LegacyTopicActionTypes.FETCH_PRESENTATION;
};

export type FetchTopicPresentationFulfilledAction = { type: LegacyTopicActionTypes.FETCH_PRESENTATION_FULFILLED };

export type FetchTopicPresentationRejectedAction = {
  meta: ErrorMeta;
  payload: FetchError;
  type: LegacyTopicActionTypes.FETCH_PRESENTATION_REJECTED;
};

export type TopicAction =
  | FetchLegacyTopicBriefingAction
  | FetchLegacyTopicBriefingSuccessAction
  | FetchLegacyTopicBriefingFailureAction
  | FetchSurveyLinkSuccessAction
  | FetchSurveyLinkAction
  | FetchSurveyLinkFailureAction
  | FetchTopicPresentationAction
  | FetchTopicPresentationFulfilledAction
  | FetchTopicPresentationRejectedAction;
