import { combineReducers } from 'redux';

import {
  CustomContentAndSourcesTypes,
  DeleteTopicContentAction,
  DeleteTopicContentFulfilledAction,
  DeleteTopicContentRejectedAction,
  SetTopicContentFile,
  SetTopicContentFileContent,
  TopicContentListFetchAction,
  TopicContentListFetchFulfilledAction,
  TopicContentListFetchRejectedAction,
  TopicContentScrapeUrl,
  TopicContentScrapeUrlFulfilled,
  TopicContentScrapeUrlRejected,
  TopicContentUpdateScrapedArticle,
  UploadTopicContentAction,
  UploadTopicContentFulfilledAction,
  UploadTopicContentRejectedAction,
} from 'domains/customContentAndSources/actionTypes';
import { CustomContent, CustomContentAndSourcesState, NewDocument } from 'domains/customContentAndSources/types';
import { getKnowledgeType } from 'entities/knowledge/reducers';
import { KnowledgeType } from 'entities/knowledge/types';
import { addUserChangeReset } from 'entities/user/utils';

const initialState: CustomContentAndSourcesState = {
  topicContent: {},
  uploadContent: {
    isPending: false,
    error: false,
  },
  deleteContent: {},
};

const topicContent = (
  state = initialState.topicContent,
  action:
    | TopicContentListFetchAction
    | TopicContentListFetchFulfilledAction
    | TopicContentListFetchRejectedAction
    | DeleteTopicContentFulfilledAction
    | UploadTopicContentFulfilledAction,
): CustomContentAndSourcesState['topicContent'] => {
  switch (action.type) {
    case CustomContentAndSourcesTypes.FETCH_TOPIC_CONTENT_LIST: {
      const prevTopicState = state[action.payload.topicId];
      return {
        ...state,
        [action.payload.topicId]: {
          ...(prevTopicState || {}),
          data: prevTopicState?.data ?? [],
          isPending: true,
          hasNextPage: true,
          error: false,
        },
      };
    }

    case CustomContentAndSourcesTypes.FETCH_TOPIC_CONTENT_LIST_FULFILLED: {
      const prevTopicState = state[action.meta.topicId];
      return {
        ...state,
        [action.meta.topicId]: {
          ...(prevTopicState || {}),
          data: action.payload.map((item) => {
            if (item.type === 'article') {
              return { ...item, knowledgeType: getKnowledgeType(item) };
            }
            return item;
          }),
          hasNextPage: action.payload.length >= (action.meta?.limit ?? 20),
          isPending: false,
          error: false,
        },
      };
    }

    case CustomContentAndSourcesTypes.FETCH_TOPIC_CONTENT_LIST_REJECTED: {
      const prevTopicState = state[action.meta.topicId];
      return {
        ...state,
        [action.meta.topicId]: {
          ...(prevTopicState || {}),
          isPending: false,
          hasNextPage: false,
          error: true,
        },
      };
    }

    case CustomContentAndSourcesTypes.UPLOAD_CONTENT_ATTACHMENT_FULFILLED: {
      if (!action.meta.updateBody) {
        return state;
      }
      const { updateBody, topicId } = action.meta;
      const prevTopicState = state[action.meta.topicId];
      if (!prevTopicState.data) {
        return state;
      }
      const newData: CustomContent[] = prevTopicState.data.map((item) =>
        item.id !== updateBody.id ? item : { ...item, ...updateBody },
      );
      return {
        ...state,
        [topicId]: {
          ...prevTopicState,
          data: newData,
        },
      };
    }

    case CustomContentAndSourcesTypes.DELETE_CONTENT_FULFILLED: {
      const prevTopicState = state[action.meta.topicId];
      return {
        ...state,
        [action.meta.topicId]: {
          ...(prevTopicState || {}),
          data: (prevTopicState.data || []).filter((item) => item.id !== action.meta.id),
          isPending: false,
          hasNextPage: false,
          error: true,
        },
      };
    }

    default:
      return state;
  }
};

const isSettingFile = (
  payload: SetTopicContentFile['payload'],
): payload is Pick<NewDocument, 'file'> & Partial<NewDocument> => !!payload && !!(payload as any).file;

const uploadContent = (
  state = initialState.uploadContent,
  action:
    | UploadTopicContentAction
    | UploadTopicContentFulfilledAction
    | UploadTopicContentRejectedAction
    | SetTopicContentFile
    | SetTopicContentFileContent
    | TopicContentScrapeUrl
    | TopicContentScrapeUrlFulfilled
    | TopicContentScrapeUrlRejected
    | TopicContentUpdateScrapedArticle,
): CustomContentAndSourcesState['uploadContent'] => {
  switch (action.type) {
    case CustomContentAndSourcesTypes.UPLOAD_CONTENT_ATTACHMENT: {
      return {
        ...state,
        isPending: true,
        error: false,
      };
    }

    case CustomContentAndSourcesTypes.UPLOAD_CONTENT_ATTACHMENT_FULFILLED: {
      return {
        ...state,
        isPending: false,
        error: false,
      };
    }

    case CustomContentAndSourcesTypes.UPLOAD_CONTENT_ATTACHMENT_REJECTED: {
      return {
        ...state,
        isPending: false,
        error: true,
      };
    }

    case CustomContentAndSourcesTypes.SET_FILE: {
      if (!action.payload) {
        return {
          ...state,
          document: undefined,
          error: false,
          isPending: false,
        };
      } else if (isSettingFile(action.payload)) {
        // If setting the file, clear out all the old state since it won't be relevant
        const newDoc = {
          ...action.payload,
          filename: action.payload.filename ?? '',
          originalFilename: action.payload.filename ?? '',
          isFileTooBig: action.payload.isFileTooBig ?? false,
        };
        return { ...state, document: newDoc };
      }

      const prevDocument = state.document;
      if (!prevDocument) {
        // Typescript has issues with the fact that the payload might not
        // contain the full file body, so we throw an error to prove this
        // doesn't happen
        throw new Error('Must set the file first');
      }
      return { ...state, document: { ...prevDocument, ...action.payload } };
    }

    case CustomContentAndSourcesTypes.SET_FILE_CONTENT: {
      return {
        ...state,
        isPending: false,
        error: false,
        document: {
          filename: action.payload.file_name,
          fileType: action.payload.mimetype,
          originalFilename: action.payload.file_name,
          isFileTooBig: false,
          id: action.payload.id,
        },
      };
    }

    case CustomContentAndSourcesTypes.SCRAPE_URL: {
      return {
        ...state,
        error: false,
        knowledge: {
          isPending: true,
          error: false,
        },
      };
    }

    case CustomContentAndSourcesTypes.SCRAPE_URL_FULFILLED: {
      return {
        ...state,
        error: false,
        knowledge: {
          data: action.payload,
          isPending: false,
          error: false,
        },
      };
    }

    case CustomContentAndSourcesTypes.SCRAPE_URL_REJECTED: {
      return {
        ...state,
        knowledge: {
          isPending: false,
          error: true,
          data: {
            link: action.meta.url,
            title: '',
            summary: '',
            language: '',
            publisher: '',
            type: 'article',
            knowledgeType: KnowledgeType.publication,
          },
        },
      };
    }

    case CustomContentAndSourcesTypes.UPDATE_SCRAPED_ARTICLE: {
      const { payload } = action;
      if (!payload) {
        return {
          ...state,
          isPending: false,
          error: false,
          knowledge: undefined,
        };
      }
      if (!state.knowledge?.data) {
        // Typescript has issues with the fact that the payload doesn't contain
        // a full knowledge body, so we throw an error to prove this won't
        // happen.
        throw new Error("Can't update something that doesn't exist");
      }
      return {
        ...state,
        knowledge: {
          ...state.knowledge,
          data: {
            ...(state.knowledge.data || {}),
            ...payload,
          },
        },
      };
    }

    default:
      return state;
  }
};

const deleteContent = (
  state = initialState.deleteContent,
  action: DeleteTopicContentAction | DeleteTopicContentFulfilledAction | DeleteTopicContentRejectedAction,
): CustomContentAndSourcesState['deleteContent'] => {
  switch (action.type) {
    case CustomContentAndSourcesTypes.DELETE_CONTENT: {
      return {
        ...state,
        [action.payload.id]: {
          isPending: true,
          error: false,
        },
      };
    }

    case CustomContentAndSourcesTypes.DELETE_CONTENT_FULFILLED: {
      return {
        ...state,
        [action.meta.id]: {
          isPending: false,
          error: false,
        },
      };
    }

    case CustomContentAndSourcesTypes.DELETE_CONTENT_REJECTED: {
      return {
        ...state,
        [action.meta.id]: {
          isPending: false,
          error: true,
        },
      };
    }
    default:
      return state;
  }
};

export const customContentAndSourcesReducer = combineReducers({
  topicContent: addUserChangeReset(topicContent, initialState.topicContent),
  uploadContent: addUserChangeReset(uploadContent, initialState.uploadContent),
  deleteContent: addUserChangeReset(deleteContent, initialState.deleteContent),
});
