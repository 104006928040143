export enum TransformationMapActionType {
  SET_TOPIC = '[TRANSFORMATION MAP] - Set',
  UPDATE_TOPIC = '[TRANSFORMATION MAP] - Update',
  SET_INNER_NODE = '[TRANSFORMATION MAP] - Set inner node',
  SET_OUTER_NODE = '[TRANSFORMATION MAP] - Set outer node',
  SET_EDIT_MODE = '[TRANSFORMATION MAP] - Set edit mode',
}

export type TransformationMapSetTopicAction = { meta?: { id: string }; type: TransformationMapActionType.SET_TOPIC };

export type TransformationMapUpdateTopicAction = {
  meta: { id: string };
  type: TransformationMapActionType.UPDATE_TOPIC;
};

export type TransformationMapSetInnerNodeAction = {
  meta: { id?: string; topicId?: string };
  type: TransformationMapActionType.SET_INNER_NODE;
};

type TransformationMapSetOuterNodeAction = { meta: { id?: string }; type: TransformationMapActionType.SET_OUTER_NODE };

export type TransformationMapSetEditModeAction = {
  meta: { mode: boolean };
  type: TransformationMapActionType.SET_EDIT_MODE;
};

export type TransformationMapAction =
  | TransformationMapSetTopicAction
  | TransformationMapSetInnerNodeAction
  | TransformationMapSetEditModeAction
  | TransformationMapUpdateTopicAction
  | TransformationMapSetOuterNodeAction;
