import { useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { UIButton, UIChip } from 'common/components';
import { StyledSectionTitle } from 'common/components/SectionTitleUnderlined';
import { useTranslations } from 'domains/language/useTranslations';
import { ModalHeader } from 'domains/modal/components/ModalHeader';
import { getModalParams } from 'domains/modal/selectors';
import { FilterField } from 'domains/topicSearchFilter/types';

const StyledContainer = styled.div`
  margin-bottom: 2rem;
`;

const StyledSectionTitleContainer = styled.div`
  margin-bottom: 1rem;
`;

const StyledTag = styled(UIChip)`
  margin: 0 10px 10px 0;
`;

type TopicFilterModalProps = {
  dataTestId?: string;
  fields?: FilterField[];
  handleApplyFilter?: (filter: Record<string, string | undefined>) => () => void;
  handleClear?: (topicId?: string) => void;
  searchCriteria?: Record<string, string>;
  title?: string;
};

export const TopicFilterModal = ({
  title,
  fields = [],
  searchCriteria = {},
  handleApplyFilter,
  handleClear,
  dataTestId = 'TopicFilterModal',
}: TopicFilterModalProps) => {
  const { textDictionary } = useTranslations();
  const { selectedTopicId } = useSelector(getModalParams);

  const onClear = () => {
    if (handleClear) {
      handleClear();
    }
  };

  return (
    <div data-test-id={dataTestId}>
      <ModalHeader title={title || textDictionary['app.filter.title']} />
      {fields.map((field) => (
        <StyledContainer key={field.id} data-test-id={`${dataTestId}-${field.id}`}>
          <StyledSectionTitleContainer>
            <StyledSectionTitle>{field.label}</StyledSectionTitle>
          </StyledSectionTitleContainer>
          {field.options.map((item) => (
            <StyledTag
              key={item.id}
              isActive={searchCriteria[field.id] === item.id}
              onClick={
                handleApplyFilter &&
                handleApplyFilter({
                  topicId: selectedTopicId,
                  [field.id]: item.id,
                })
              }
              dataTestId={`${dataTestId}-${field.id}-${item.id}`}
            >
              {item.label}
            </StyledTag>
          ))}
        </StyledContainer>
      ))}
      <UIButton data-test-id={`${dataTestId}-clear`} fullwidth onClick={onClear} variant="filled">
        {textDictionary['app.search_and_filter.clear']}
      </UIButton>
    </div>
  );
};
