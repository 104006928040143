import { styled } from 'styled-components';

import { RedCircleErrorIcon, Link } from 'common/components';
import { breakpoints } from 'common/styles';
import { useTranslations } from 'domains/language/useTranslations';
import { ModalHeader } from 'domains/modal/components/ModalHeader';

const StyledErrorContent = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
`;

const StyledTitle = styled.h1`
  font-size: 1.2rem;

  @media ${breakpoints.medium} {
    font-size: 1.6rem;
  }
`;

const StyledText = styled.p`
  text-align: center;
  font-size: 1.1rem;
`;

const StyledRedCircleErrorIcon = styled(RedCircleErrorIcon)`
  width: 400px;
  margin: 1rem 0;
  color: ${({ theme }) => theme.colors.fillPrimary};
`;

export const BriefingErrorModal = () => {
  const { textDictionary, interpolate } = useTranslations();

  const supportEmail = () => <Link href={`mailto:intelligence@weforum.org`}>intelligence@weforum.org</Link>;

  return (
    <>
      <ModalHeader />
      <StyledErrorContent>
        <StyledTitle>{textDictionary['app.topic.briefing.error_title']}</StyledTitle>
        <StyledRedCircleErrorIcon />
        <StyledText>
          {interpolate(textDictionary['app.topic.briefing.error_message'], {
            supportEmail,
          })}
        </StyledText>
      </StyledErrorContent>
    </>
  );
};
