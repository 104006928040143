import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

import { StyledModalText, UIButton } from 'common/components';
import { useTranslations } from 'domains/language/useTranslations';
import { deactivateModal } from 'domains/modal/actions';
import { ModalHeader } from 'domains/modal/components/ModalHeader';
import { ManageTopicItem } from 'domains/monitor/filter/components/ManageTopicItem';
import { fetchSuggestions } from 'domains/monitor/suggestions/actions';
import { getSuggestedTopics } from 'domains/monitor/suggestions/selectors';
import { useRouterLink } from 'domains/routing/utils';
import { getFollowedTopics } from 'entities/topics/selectors';

const StyledContainer = styled.div`
  margin: 1rem 0;
`;

const StyledSectionTitleText = styled.h2`
  font-size: 1.5rem;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.textFade};
`;

const StyledSectionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.blue};
  text-decoration: none;
  font-weight: 500;
`;

export const ManageTopicsModal = () => {
  const { textDictionary, interpolate } = useTranslations();
  const dispatch = useDispatch();
  const followingTopics = useSelector(getFollowedTopics);
  const suggestions = useSelector(getSuggestedTopics);
  const link = useRouterLink({ to: 'discover' });

  const onDismiss = () => dispatch(deactivateModal());

  const discover = (value: string) => (
    <StyledLink to={link} onClick={onDismiss}>
      {value}
    </StyledLink>
  );

  const descriptionText = interpolate(textDictionary['app.monitor.manage_topics.description'], { discover });

  useEffect(() => {
    if (suggestions.length === 0) {
      dispatch(fetchSuggestions());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      <ModalHeader title={textDictionary['app.discover.manage_topics']} />
      <StyledModalText>{descriptionText}</StyledModalText>
      {followingTopics.length > 0 && (
        <>
          <StyledSectionTitleText>{textDictionary['app.monitor.following_topics']}</StyledSectionTitleText>
          {followingTopics.map((topic) => (
            <ManageTopicItem
              key={topic.id}
              id={topic.id}
              name={topic.name}
              images={topic.images}
              isFollowing={true}
              isFollowingUpdating={topic.isFollowingUpdating}
            />
          ))}
        </>
      )}
      {suggestions.length > 0 && (
        <StyledContainer>
          <StyledSectionTitle>
            <StyledSectionTitleText>{textDictionary['app.monitor.suggested_topics.title']}</StyledSectionTitleText>
            <UIButton as="a" href={link} onClick={onDismiss} variant={'outlined'}>
              {textDictionary['app.monitor.see_more_topics']}
            </UIButton>
          </StyledSectionTitle>
          {suggestions.map((topic) => (
            <ManageTopicItem
              key={topic.id}
              id={topic.id}
              name={topic.name}
              images={topic.images}
              isFollowing={false}
              isFollowingUpdating={topic.isFollowingUpdating}
            />
          ))}
        </StyledContainer>
      )}
    </>
  );
};
