import { AuthenticationModal } from 'domains/authentication/components/AuthenticationModal';
import { UnauthenticatedModal } from 'domains/authentication/components/UnauthenticatedModal';
import { BriefingModal } from 'domains/briefing/components/BriefingModal';
import { CoachmarksSettingsModal } from 'domains/coachmarks/components/CoachmarksSettingsModal';
import { ResetCoachmarksConfirmationModal } from 'domains/coachmarks/components/ResetCoachmarksConfirmationModal';
import { ViewAllCoachmarksModal } from 'domains/coachmarks/components/ViewAllCoachmarksModal';
import { CollectionCancellationConfirmationModal } from 'domains/collection/modals/CollectionCancelConfirmationModal';
import { CollectionCreateCoCuratorModal } from 'domains/collection/modals/CollectionCreateCoCuratorModal';
import { CollectionDeletionConfirmationModal } from 'domains/collection/modals/CollectionDeletionConfirmationModal';
import { CollectionHeroImagePickerModal } from 'domains/collection/modals/CollectionHeroImagePickerModal';
import { MapImagePickerModal } from 'domains/createMapDetail/components/image/MapImagePickerModal';
import { KeyIssueModal } from 'domains/createMapDetail/components/keyIssueList/KeyIssueModal';
import { CancelMapModal } from 'domains/createMapDetail/components/modals/CancelMapModal';
import { ConfirmDeleteMapModal } from 'domains/createMapDetail/components/modals/ConfirmDeleteMapModal';
import { ConfirmRemoveModal } from 'domains/createShareSettings/components/ConfirmRemoveModal';
import { CreateLinkModal } from 'domains/createShareSettings/components/CreateLinkModal';
import { OpenLinkInvitationModal } from 'domains/createShareSettings/components/OpenLinkInvitationModal';
import { AddCustomContentModal } from 'domains/customContentAndSources/components/AddCustomContentModal';
import { AddCustomKeyIssueModal } from 'domains/customKeyIssue/components/AddCustomKeyIssueModal';
import { IncidentDetailModal } from 'domains/incidents/components/IncidentDetailModal';
import { ChangeLanguageModal } from 'domains/language/components/ChangeLanguageModal';
import { CreateMapUpgradeModal } from 'domains/modal/components/CreateMapUpgradeModal';
import { CustomKeyIssueUpgradeModal } from 'domains/modal/components/CustomKeyIssueUpgradeModal';
import { ErrorModal } from 'domains/modal/components/ErrorModal';
import { FollowTopicsLimitModalUpgradeModal } from 'domains/modal/components/FollowTopicsLimitUpgradeModal';
import { ManageTopicsModal } from 'domains/monitor/filter/components/ManageTopicsModal';
import { MonitorFilterModal } from 'domains/monitor/filter/components/MonitorFilterModal';
import { NotificationDetailModal } from 'domains/notifications/components/NotificationDetailModal';
import { NotificationModal } from 'domains/notifications/components/NotificationModal';
import { NotificationSettingsModal } from 'domains/notifications/components/NotificationSettingsModal';
import { SearchFilterModal } from 'domains/search/components/SearchFilterModal';
import { ShareModal } from 'domains/share/components/ShareModal';
import { BriefingErrorModal } from 'domains/topic/components/BriefingErrorModal';
import { EmbedMapWidgetModal } from 'domains/topic/components/EmbedMapWidgetModal';
import { TopicDataFilterModal } from 'domains/topicSearchFilter/components/TopicDataFilterModal';
import { TopicEventFilterModal } from 'domains/topicSearchFilter/components/TopicEventFilterModal';
import { TopicInitiativeFilterModal } from 'domains/topicSearchFilter/components/TopicInitiativeFilterModal';
import { TopicLatestFilterModal } from 'domains/topicSearchFilter/components/TopicLatestFilterModal';
import { TopicPublicationFilterModal } from 'domains/topicSearchFilter/components/TopicPublicationFilterModal';
import { TopicStakeholderFilterModal } from 'domains/topicSearchFilter/components/TopicStakeholderFilterModal';
import { TopicVideoFilterModal } from 'domains/topicSearchFilter/components/TopicVideoFilterModal';
import { UserProfileModal } from 'domains/user/components/UserProfileModal';
import { WhatsNewModal } from 'domains/whatsNew/components/WhatsNewModal';

export const modalStack = {
  Share: ShareModal,
  'Monitor filter': MonitorFilterModal,
  'Manage topics': ManageTopicsModal,
  'Map Share Link': CreateLinkModal,
  'Map Image Picker': MapImagePickerModal,
  'Confirm Delete Map': ConfirmDeleteMapModal,
  'Create Upgrade Membership': CreateMapUpgradeModal,
  'Create Upgrade Membership Custom Key Issue': CustomKeyIssueUpgradeModal,
  'Cancel Map': CancelMapModal,
  'Open Link Invitation': OpenLinkInvitationModal,
  Error: ErrorModal,
  'Follow Topics Limit': FollowTopicsLimitModalUpgradeModal,
  'Search Filter': SearchFilterModal,
  'User Profile': UserProfileModal,
  'Change Language': ChangeLanguageModal,
  Notifications: NotificationModal,
  'Notifications Detail': NotificationDetailModal,
  'Notifications Settings': NotificationSettingsModal,
  'Whats New': WhatsNewModal,
  'Coachmark Settings': CoachmarksSettingsModal,
  'All Coachmarks': ViewAllCoachmarksModal,
  'Reset Coachmarks': ResetCoachmarksConfirmationModal,
  'Embed Map Widget': EmbedMapWidgetModal,
  'Remove Collaborator': ConfirmRemoveModal,
  Incidents: IncidentDetailModal,
  'Topic Publication Filter': TopicPublicationFilterModal,
  'Topic Video Filter': TopicVideoFilterModal,
  'Topic Initiative Filter': TopicInitiativeFilterModal,
  'Topic Event Filter': TopicEventFilterModal,
  'Topic Stakeholder Filter': TopicStakeholderFilterModal,
  'Topic Data Filter': TopicDataFilterModal,
  'Briefing Error': BriefingErrorModal,
  'Key Issues': KeyIssueModal,
  'Add Custom Content': AddCustomContentModal,
  'Add Custom Key Issue': AddCustomKeyIssueModal,
  Unauthenticated: UnauthenticatedModal,
  Authenticate: AuthenticationModal,
  'Topic Latest Filter': TopicLatestFilterModal,
  'Topic Briefing': BriefingModal,
  'Collection Deletion Confirmation': CollectionDeletionConfirmationModal,
  'Collection Hero Image Picker': CollectionHeroImagePickerModal,
  'Collection Create Co-Curator': CollectionCreateCoCuratorModal,
  'Collection Cancellation Confirmation': CollectionCancellationConfirmationModal,
};
