import { PropsWithChildren, ReactElement, useState } from 'react';
import { css, styled } from 'styled-components';

import { ExpandButton } from 'common/components';
import { breakpoints, createScrollbarStyle, easing, mediaStyles } from 'common/styles';

const StyledHeader = styled.div`
  display: flex;

  @media ${breakpoints.extraLarge} {
    display: none;
  }
`;

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const StyledContent = styled.div<{ isOpen: boolean }>`
  overflow-y: auto;
  width: 100%;
  height: auto;
  scroll-behavior: smooth;

  @media ${breakpoints.extraLarge} {
    width: ${({ isOpen }) => `calc(100% - ${isOpen ? 350 : 0}px);`};
    height: 100%;
  }
`;

const StyledMovableSidebar = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  ${mediaStyles(({ spacing }) => `padding: ${spacing}rem 0 0 ${spacing}rem`)};
`;

const StyledSidebar = styled.div<{ isOpen?: boolean }>`
  display: none;
  ${createScrollbarStyle()}

  @media ${breakpoints.extraLarge} {
    display: block;
    height: 100%;
    overflow: auto;
    transition:
      transform 0.6s ${easing.slightBounce},
      width 0.6s ${easing.slightBounce};

    ${({ isOpen }) => css`
      padding-right: ${isOpen ? 0.5 : 0}rem;
      width: ${isOpen ? 350 : 0}px;
      ${StyledMovableSidebar} {
        transform: translate(${isOpen ? 0 : -350}px, 0);
      }
    `};
  }
`;

const StyledExpandButton = styled(ExpandButton)`
  display: none;
  left: 3rem;

  @media ${breakpoints.extraLarge} {
    display: flex;
  }
`;

type MainLayoutProps = PropsWithChildren & {
  headerElement?: ReactElement;
  hideSidePanel?: boolean;
  sidePanel?: ReactElement;
};

export const MainLayout = ({ children, headerElement, hideSidePanel = false, sidePanel }: MainLayoutProps) => {
  const [isOpen, setIsOpen] = useState(!hideSidePanel);

  const togglePanel = () => setIsOpen(!isOpen);

  return (
    <StyledContainer>
      <StyledSidebar isOpen={isOpen}>
        <StyledMovableSidebar>{sidePanel}</StyledMovableSidebar>
      </StyledSidebar>
      <StyledContent isOpen={isOpen}>
        {!!headerElement && <StyledHeader>{headerElement}</StyledHeader>}
        {children}
      </StyledContent>
      {!hideSidePanel && <StyledExpandButton isOpen={isOpen} onClick={togglePanel} />}
    </StyledContainer>
  );
};
