export const CommunitiesIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g data-name="Group 95468">
      <path
        d="M12 15.091a1 1 0 0 1 1 1v1.636a1 1 0 0 1-2 0v-1.636a1 1 0 0 1 1-1z"
        data-name="Path 245680"
        style={{ fill: '#fff', fillRule: 'evenodd' }}
        transform="translate(-2 -2)"
      />
      <path
        d="M12 5.273a1 1 0 0 1 1 1v1.636a1 1 0 1 1-2 0V6.273a1 1 0 0 1 1-1z"
        data-name="Path 245681"
        style={{ fill: '#fff', fillRule: 'evenodd' }}
        transform="translate(-2 -2)"
      />
      <path
        d="M14.677 13.545a1 1 0 0 1 1.366-.366L17.46 14a1 1 0 0 1-1 1.732l-1.417-.818a1 1 0 0 1-.366-1.369z"
        data-name="Path 245682"
        style={{ fill: '#fff', fillRule: 'evenodd' }}
        transform="translate(-2 -2)"
      />
      <path
        d="M6.174 8.636A1 1 0 0 1 7.54 8.27l1.417.818a1 1 0 1 1-1 1.732L6.54 10a1 1 0 0 1-.366-1.364z"
        data-name="Path 245683"
        style={{ fill: '#fff', fillRule: 'evenodd' }}
        transform="translate(-2 -2)"
      />
      <path
        d="M14.677 10.454a1 1 0 0 1 .366-1.366l1.417-.818a1 1 0 1 1 1 1.732l-1.417.818a1 1 0 0 1-1.366-.366z"
        data-name="Path 245684"
        style={{ fill: '#fff', fillRule: 'evenodd' }}
        transform="translate(-2 -2)"
      />
      <path
        d="M6.174 15.364A1 1 0 0 1 6.54 14l1.417-.818a1 1 0 1 1 1 1.732l-1.417.816a1 1 0 0 1-1.366-.366z"
        data-name="Path 245685"
        style={{ fill: '#fff', fillRule: 'evenodd' }}
        transform="translate(-2 -2)"
      />
      <path
        d="M12 4a8 8 0 1 0 8 8 8 8 0 0 0-8-8zM2 12a10 10 0 1 1 10 10A10 10 0 0 1 2 12z"
        data-name="Path 245686"
        style={{ fill: '#fff', fillRule: 'evenodd' }}
        transform="translate(-2 -2)"
      />
    </g>
  </svg>
);
