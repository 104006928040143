import { ToggleThemeAction, ThemeType, UpdateThemeAction } from 'domains/theme/actionTypes';

export const toggleThemeMode = (): ToggleThemeAction => ({
  type: ThemeType.TOGGLE,
});

export const updateThemeMode = (meta: UpdateThemeAction['meta']): UpdateThemeAction => ({
  type: ThemeType.UPDATE,
  meta,
});
