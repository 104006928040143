import { saveAs } from 'file-saver';
import { put } from 'redux-saga/effects';

import { fileApiFetch } from 'common/utilities';
import { activateModal } from 'domains/modal/actions';
import { fetchTopicPresentationFulfilled, fetchTopicPresentationRejected } from 'domains/topic/actions';
import { FetchTopicPresentationAction } from 'domains/topic/actionTypes';
import { isFetchError } from 'TypeCheckMethods';

export function* fetchTopicPresentationSaga(action: FetchTopicPresentationAction) {
  try {
    const topicPresentation: Blob = yield fileApiFetch(`topic/${action.payload.id}/presentation`, {
      extraHeaders: {
        Accept: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      },
    });
    saveAs(topicPresentation, `Dynamic briefing - ${action.payload.name}.pptx`);
    yield put(fetchTopicPresentationFulfilled());
  } catch (error) {
    if (isFetchError(error)) {
      yield put(fetchTopicPresentationRejected(error, { logoutOnAuthError: true }));
      if (error.status !== 401) {
        yield put(activateModal({ id: 'Error' }));
      }
    }
  }
}
