import QRCode from 'react-qr-code';
import { useDispatch, useSelector } from 'react-redux';
import { css, styled } from 'styled-components';

import { ResponsiveImage } from 'common/components';
import { breakpoints, lineTruncationStyle } from 'common/styles';
import { copyTextToClipboard } from 'common/utilities';
import { SIAppLogoImageSet } from 'domains/about/images/footer';
import { trackEvent } from 'domains/analytics/actions';
import { EntityType, TrackEvent } from 'domains/analytics/types';
import { useTranslations } from 'domains/language/useTranslations';
import { popModalScreen } from 'domains/modal/actions';
import { ModalHeader } from 'domains/modal/components/ModalHeader';
import { getModalParams } from 'domains/modal/selectors';
import { ShareModalButton } from 'domains/share/components/ShareModalButton';
import { ShareIconName } from 'domains/share/types';
import { useMedia } from 'domains/viewport/useMedia';

const card = css`
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.modalCardBackground};
  padding: 1rem;
  width: 100%;

  @media ${breakpoints.medium} {
    width: 50%;
  }
`;

const StyledList = styled.div`
  ${card};
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

const StyledBlock = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  border-color: ${({ theme }) => theme.colors.modalCardBackground};
  border-radius: 15px;
  border-style: solid;
  border-width: 10px;
  margin-bottom: 2rem;
`;

const sharedImageStyles = css`
  width: 100px;
  height: 80px;
  margin-right: 1rem;
  object-fit: cover;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  @media ${breakpoints.medium} {
    width: 180px;
    height: 135px;
  }
`;

const StyledResponsiveImage = styled(ResponsiveImage)`
  ${sharedImageStyles}
`;

const StyledImage = styled.img`
  ${sharedImageStyles}
`;

const StyledTitle = styled.h2`
  margin: 0;
  font-size: 1.4rem;
  line-height: 1.9rem;

  ${lineTruncationStyle(2)};
`;

const StyledSubTitle = styled.div`
  font-size: 0.85rem;
  word-break: break-all;
  color: ${({ theme }) => theme.colors.textBright};
`;

const StyledType = styled.div`
  font-size: 0.8rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.textFade};
`;

const StyledTitleContent = styled.div`
  width: calc(100% - 4.25rem);
`;

const StyledQRContainer = styled.div`
  ${card};
  text-align: center;
`;

type ShareOption = { href?: string; onClick?: () => void; title: string; trackName: TrackEvent; type: ShareIconName };

export const ShareModal = () => {
  const { textDictionary } = useTranslations();
  const dispatch = useDispatch();
  const { id, name, type, link, image } = useSelector(getModalParams);
  const handlePopModal = () => dispatch(popModalScreen());

  const isNotSmall = useMedia({ small: false }, true);

  const track = (eventName: TrackEvent) => {
    dispatch(
      trackEvent({
        name: eventName,
        entityId: id,
        entityName: name,
        entityType: type as EntityType,
      }),
    );
  };

  const handleClick = (trackName: TrackEvent) => () => {
    dispatch(popModalScreen());
    track(trackName);
  };

  const handleCopyClick = () => {
    if (link) {
      copyTextToClipboard(link);
      handleClick('Copy Share')();
    }
  };

  const secureName = encodeURIComponent(name || '');
  const secureLink = encodeURIComponent(link || '');

  const shareOptions: ShareOption[] = [
    {
      type: 'LinkedIn',
      title: 'app.topic.share.linkedin',
      href: `https://www.linkedin.com/shareArticle?mini=true&url=${secureLink}&title=${secureName}`,
      trackName: 'LinkedIn Share',
    },
    {
      type: 'Twitter',
      title: 'app.topic.share.twitter',
      href: `https://twitter.com/intent/tweet?text=${secureName}&url=${secureLink}&via=wef&via=%20@WEF_intel`,
      trackName: 'Twitter Share',
    },
    {
      type: 'WhatsApp',
      title: 'app.topic.share.whatsapp',
      href: `https://wa.me/send?text=${encodeURIComponent(`${name}: ${link}`)}`,
      trackName: 'WhatsApp Share',
    },
    {
      type: 'Telegram',
      title: 'app.topic.share.telegram',
      href: `https://t.me/share/url?url=${encodeURIComponent(`${link}`)}&text=${encodeURIComponent(`${name}`)}`,
      trackName: 'Telegram Share',
    },
    {
      type: 'Email',
      title: 'app.topic.share.email',
      href: `mailto:?body=${name}: ${link}`,
      trackName: 'Email Share',
    },
    {
      type: 'Copy',
      title: 'app.topic.share.copy',
      onClick: handleCopyClick,
      trackName: 'Copy Share',
    },
  ];

  return (
    <>
      <ModalHeader title={textDictionary['app.topic.share']} dismissAction={handlePopModal} />
      <StyledHeader>
        {image ? <StyledImage src={image} /> : <StyledResponsiveImage srcSet={SIAppLogoImageSet} alt="App Logo" />}
        <StyledTitleContent>
          {type && <StyledType>{type}</StyledType>}
          {name && <StyledTitle>{name}</StyledTitle>}
        </StyledTitleContent>
      </StyledHeader>
      <StyledBlock>
        <StyledList>
          {shareOptions.map((item) => (
            <ShareModalButton
              key={item.type}
              onClick={item.onClick || handleClick(item.trackName)}
              name={item.type}
              href={item.href}
            >
              {textDictionary[item.title]}
            </ShareModalButton>
          ))}
        </StyledList>
        {link && isNotSmall && (
          <StyledQRContainer>
            <QRCode size={200} value={`${link}?utm_campaign=si-share-qr`} />
            {link && <StyledSubTitle>{link}</StyledSubTitle>}
          </StyledQRContainer>
        )}
      </StyledBlock>
    </>
  );
};
