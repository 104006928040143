import { User, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';

export const oidcConfig: UserManagerSettings = {
  authority: `${process.env.REACT_APP_SSO_URL}/authentication/v1/sso`,
  client_id: process.env.REACT_APP_AUTH_WIDGET_CLIENT_ID || '',
  // Redefine the storage to store in localStorage (the default is
  // sessionStorage which gets wiped out on closing the browser).
  userStore: new WebStorageStateStore({
    store: window.localStorage,
  }),

  // We put this in to satisfy typescript, but in reality this one isn't used.
  // When calling signinRedirect, we dynamically insert the current url instead.
  redirect_uri: process.env.REACT_APP_WEBSITE_URL || '',
  // When testing token expiry, uncomment this line so the token expires every 20s
  // accessTokenExpiringNotificationTimeInSeconds: 1800 - 20,
};

const getUserFromStorage = () => {
  const userStorageKey = `oidc.user:${oidcConfig.authority}:${oidcConfig.client_id}`;
  const storedUser = localStorage.getItem(userStorageKey);
  if (!storedUser) {
    return null;
  }
  return User.fromStorageString(storedUser);
};

export type TokenStorage = { accessToken: string; expiresIn?: number; idToken?: string; refreshToken?: string };

export const mapOIDCUserToTokenStorage = (user: User): TokenStorage => ({
  accessToken: user.access_token,
  refreshToken: user.refresh_token,
  idToken: user.id_token,
  expiresIn: user.expires_in,
});

export const initialStoredUser = getUserFromStorage();

export const getTokensFromStorage = () => {
  if (initialStoredUser) {
    return mapOIDCUserToTokenStorage(initialStoredUser);
  }
  return null;
};
