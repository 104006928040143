import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Checkbox, UIButton } from 'common/components';
import { trackEvent } from 'domains/analytics/actions';
import { disableGenerateButton, triggerGenerateBriefing } from 'domains/briefing/actions';
import { BriefingListItem } from 'domains/briefing/components/BriefingListItem';
import * as Styled from 'domains/briefing/components/BriefingModal.styled';
import { getBriefings, getGenerateButtonStatus } from 'domains/briefing/selectors';
import { useTranslations } from 'domains/language/useTranslations';
import { ModalHeader } from 'domains/modal/components/ModalHeader';
import { getModalParams } from 'domains/modal/selectors';
import { getTransformationMapTopic } from 'domains/transformationMap/selectors';

export const BriefingModal = () => {
  const dispatch = useDispatch();
  const { textDictionary } = useTranslations();
  const [includeFurtherReadings, setIncludeFurtherReadings] = useState(true);
  const [includeForesight, setIncludeForesight] = useState(true);
  const topic = useSelector(getTransformationMapTopic);
  const briefings = useSelector(getBriefings);
  const isGenerateButtonDisabled = useSelector(getGenerateButtonStatus);
  const params = useSelector(getModalParams);

  const handleIncludeFurtherReadings = () => {
    setIncludeFurtherReadings(!includeFurtherReadings);
  };

  const handleIncludeForesight = () => {
    setIncludeForesight(!includeForesight);
  };

  const generateBriefing = () => {
    dispatch(disableGenerateButton());
    dispatch(
      triggerGenerateBriefing({
        topicId: topic!.id,
        topicName: topic!.name,
        options: {
          furtherReading: includeFurtherReadings,
          foresight: includeForesight,
        },
      }),
    );
    dispatch(
      trackEvent({
        name: 'Generate briefing',
        entityId: topic!.id,
        entityName: topic!.name,
        entityType: 'Topic',
        secondaryEntityType: 'Briefing - Advanced',
      }),
    );
  };

  const title = textDictionary['app.briefing.title'];

  return (
    <>
      <ModalHeader dismissAction={params.dismissAction} title={title} />
      <Styled.ExplanationParagraph>{textDictionary['app.briefing.advanced_briefing_note']}</Styled.ExplanationParagraph>
      <div>
        <Styled.GenerationContent>
          <Styled.Text>{textDictionary['app.briefing.configuration_note']}</Styled.Text>
          <Styled.OptionCheckbox
            id="further_readings"
            text={textDictionary['app.briefing.further_reading_checkbox']}
            value={includeFurtherReadings}
            onChange={handleIncludeFurtherReadings}
          />
          <Checkbox
            id="foresight"
            text={textDictionary['app.briefing.foresight_checkbox']}
            value={includeForesight}
            onChange={handleIncludeForesight}
          />
        </Styled.GenerationContent>
        <UIButton
          variant={'filled'}
          onClick={generateBriefing}
          isLoading={isGenerateButtonDisabled}
          style={{ width: '100%' }}
        >
          {textDictionary['app.briefing.generate_button']}
        </UIButton>
        <Styled.BriefingList hasListItems={briefings.length > 0}>
          {briefings.map((briefing) => (
            <BriefingListItem key={briefing.briefingId} briefing={briefing} />
          ))}
        </Styled.BriefingList>
      </div>
    </>
  );
};
