import { PropsWithChildren, ReactNode } from 'react';
import { styled } from 'styled-components';

import { BackButton } from 'common/components';
import { SectionTitleUnderlined } from 'common/components/SectionTitleUnderlined';
import { breakpoints, createScrollbarStyle, lineTruncationStyle, mediaStyles } from 'common/styles';

const StyledContainer = styled.div`
  height: 100%;
  position: relative;
  color: ${({ theme }) => theme.colors.fillPrimary};
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  margin: 1rem;
  z-index: 100;

  @media ${breakpoints.medium} {
    margin: 2rem 0 0 2rem;
  }

  @media ${breakpoints.extraLarge} {
    margin: 3rem 0 0 3rem;
  }
`;

const StyledHeaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-right: 1rem;
  box-sizing: border-box;
`;

const StyledContent = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 6rem);
  padding-right: 1rem;
  margin-top: 4rem;
  display: inline-block;
  width: 100%;
  box-sizing: border-box;

  ${createScrollbarStyle()};

  ${mediaStyles(
    ({ spacing }) => `
    padding-bottom: ${spacing}rem;
  `,
  )};
`;

const StyledTitle = styled.h2`
  margin: 12px 0 24px 0;
  font-size: 1.6rem;

  ${lineTruncationStyle(1)};
`;

type ListLayoutProps = PropsWithChildren & {
  backLabel?: string;
  backLink?: string;
  rightHeaderElement?: ReactNode;
  title?: string;
};

export const ListLayout = ({ backLabel, backLink, children, rightHeaderElement = null, title }: ListLayoutProps) => (
  <StyledContainer>
    <StyledContent>
      <>
        {title && <StyledTitle>{title}</StyledTitle>}
        {children}
      </>
    </StyledContent>
    <StyledHeaderContainer>
      <SectionTitleUnderlined rightElement={rightHeaderElement || undefined}>
        {backLink && backLabel && <BackButton path={backLink} label={backLabel} />}
      </SectionTitleUnderlined>
    </StyledHeaderContainer>
  </StyledContainer>
);
