import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { css, styled, ThemeProvider } from 'styled-components';

import { Card, Image, LockIcon } from 'common/components';
import { breakpoints, lineTruncationStyle } from 'common/styles';
import { useFormatDate } from 'common/utilities';
import { trackEvent } from 'domains/analytics/actions';
import { TrackPage } from 'domains/analytics/types';
import { getIsAuthenticated } from 'domains/authentication/selectors';
import { ClusterFooter } from 'domains/clusters/components/ClusterFooter';
import { PlaceholderClusterCard } from 'domains/clusters/components/PlaceholderClusterCard';
import { Cluster } from 'domains/clusters/types';
import { ChevronLink } from 'domains/landing/components/shared';
import { useTranslations } from 'domains/language/useTranslations';
import { darkTheme } from 'domains/theme/constants/theme';
import { useMedia } from 'domains/viewport/useMedia';
import { getUserRole } from 'entities/user/selectors';
import { getLinkToMembershipPage } from 'entities/user/utils';
import { useAuth } from 'useAuth';

const StyledContainer = styled(Link)`
  width: 100%;
  height: 100%;
  text-decoration: none;
  overflow: hidden;
  color: inherit;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;

const StyledImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  color: white;

  ${StyledColumn} {
    padding: 1rem;

    @media ${breakpoints.large} {
      padding: 2rem;
    }
  }
`;

const fillBackgroundStyle = css`
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const StyledImage = styled(Image)`
  object-fit: cover;
  ${fillBackgroundStyle}
`;

const StyledOverlay = styled.div`
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.7));
  ${fillBackgroundStyle}
`;

const StyledTitle = styled.h2`
  font-size: 1.7rem;
  line-height: 2.2rem;
  margin: 8px 0 16px;
  ${lineTruncationStyle(2)}
`;

const StyledCaption = styled.div`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.textFade};
  font-weight: bold;
`;

const StyledDate = styled.span`
  padding-right: 1rem;
  margin-right: 1rem;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: ${({ theme }) => theme.colors.textFade};
`;

const StyledSummary = styled.p`
  font-size: 0.9rem;
  margin: 0;
  line-height: 1.4rem;
  color: ${({ theme }) => theme.colors.textFade};

  ${lineTruncationStyle(4)}
`;

const StyledIcon = styled(LockIcon)`
  width: 40px;
  height: 40px;
  color: white;
  margin-top: 1rem;
`;

const StyledRow = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

const StyledSideImage = styled(Image)`
  width: 120px;
  min-width: 120px;
  height: 90px;
  border-radius: 10px;
  margin-right: 1.5rem;
  background-size: cover;
  transition: opacity 0.3s ease;
`;

const StyledContentWrap = styled.div`
  height: 100%;
  width: 100%;
`;

type ClusterCardProps = {
  className?: string;
  cluster: Cluster;
  isLocked?: boolean;
  toLink: string;
  trackPage?: TrackPage;
  type?: 'medium' | 'wide';
};

export const ClusterCard = ({
  cluster,
  toLink,
  isLocked = false,
  className,
  type = 'medium',
  trackPage,
}: ClusterCardProps) => {
  const { title, images, description, knowledge_count, time } = cluster;
  const userRole = useSelector(getUserRole);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const { getRelativeDate } = useFormatDate();
  const { textDictionary, interpolate } = useTranslations();
  const isExtraLarge = useMedia({ small: false, medium: false, large: false }, true);
  const caption = interpolate(textDictionary['app.monitor.articles_count'], {
    count: () => knowledge_count,
  });
  const { triggerLogin } = useAuth();

  if (isLocked) {
    const onClickHandle = () => {
      if (isAuthenticated) {
        dispatch(
          trackEvent({
            name: 'Upgrade',
            page: trackPage,
          }),
        );
        window.location.href = getLinkToMembershipPage(userRole);
      } else {
        triggerLogin();
      }
    };
    const message =
      textDictionary[isAuthenticated ? 'app.about.key_points.membership_feature' : 'app.topic.locked_content'];
    return (
      <PlaceholderClusterCard
        image={images}
        message={message}
        onClick={onClickHandle}
        icon={<StyledIcon name="Locked" />}
      />
    );
  }

  const content = (
    <StyledColumn>
      <StyledContainer to={toLink}>
        <StyledCaption>
          <StyledDate>{getRelativeDate({ date: time })}</StyledDate>
          <span>{caption}</span>
        </StyledCaption>
        <StyledTitle>{title}</StyledTitle>
        <StyledSummary>{description}</StyledSummary>
      </StyledContainer>
      <ClusterFooter
        cluster={cluster}
        shareLink={`/monitor/emerging-themes/${cluster.id}`}
        showIcons={true}
        trackPage={trackPage}
      />
    </StyledColumn>
  );

  if (type === 'wide' && isExtraLarge) {
    return (
      <Card className={className}>
        <StyledRow>
          <StyledSideImage src={images?.x_small} alt={title} />
          <StyledContentWrap>{content}</StyledContentWrap>
          <ChevronLink to={toLink} />
        </StyledRow>
      </Card>
    );
  }

  return (
    <StyledImageContainer className={className}>
      <StyledImage src={images?.thumbnail} alt={title} />
      <StyledOverlay />
      <ThemeProvider theme={darkTheme}>{content}</ThemeProvider>
    </StyledImageContainer>
  );
};
