import {
  FetchProfileFulfilledAction,
  FetchProfileDefaultAction,
  FetchProfileRejectedAction,
  UserProfileType,
  UpdateProfileFulfilledAction,
  UpdateProfileDefaultAction,
  UpdateProfileRejectedAction,
  ClearProfileFailureAction,
} from 'entities/user/actionTypes';

export const fetchProfile = (): FetchProfileDefaultAction => ({
  type: UserProfileType.FETCH,
});

export const fetchProfileSuccess = (payload: FetchProfileFulfilledAction['payload']): FetchProfileFulfilledAction => ({
  type: UserProfileType.FETCH_FULFILLED,
  payload,
});

export const fetchProfileFailure = (
  payload: FetchProfileRejectedAction['payload'],
  meta: FetchProfileRejectedAction['meta'],
): FetchProfileRejectedAction => ({
  type: UserProfileType.FETCH_REJECTED,
  payload,
  meta,
});

export const updateProfile = (payload: UpdateProfileDefaultAction['payload']): UpdateProfileDefaultAction => ({
  type: UserProfileType.UPDATE_PROFILE,
  payload,
});

export const updateProfileSuccess = (
  payload: UpdateProfileFulfilledAction['payload'],
  meta: UpdateProfileFulfilledAction['meta'],
): UpdateProfileFulfilledAction => ({
  type: UserProfileType.UPDATE_PROFILE_FULFILLED,
  payload,
  meta,
});

export const updateProfileFailure = (payload: UpdateProfileRejectedAction['payload']): UpdateProfileRejectedAction => ({
  type: UserProfileType.UPDATE_PROFILE_REJECTED,
  payload,
});

export const clearProfileFailure = (): ClearProfileFailureAction => ({
  type: UserProfileType.CLEAR_PROFILE_FAILURE,
});
