import { SVGProps } from 'react';

export const ReplayIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      d="M23.721 8.187v-6.94c0-1.119-1.343-1.666-2.114-.87L12.18 9.804a1.231 1.231 0 000 1.766l9.427 9.427c.771.77 2.114.223 2.114-.871v-6.964c9.278 0 16.615 8.506 14.576 18.132-1.17 5.646-5.746 10.198-11.367 11.367-8.88 1.865-16.79-4.229-17.983-12.462-.15-1.193-1.194-2.114-2.438-2.114-1.492 0-2.686 1.319-2.487 2.811a19.93 19.93 0 0023.704 16.715c7.76-1.518 14.003-7.76 15.52-15.521C45.71 19.33 36.033 8.187 23.721 8.187zm-2.736 27.36h-2.114V27.44l-2.512.77v-1.715l4.402-1.567h.224v10.62zm10.646-4.377c0 .795-.075 1.492-.25 2.04-.173.546-.422 1.044-.72 1.417a2.787 2.787 0 01-1.12.82c-.422.175-.92.25-1.467.25s-1.02-.075-1.468-.25a2.866 2.866 0 01-1.144-.82c-.323-.373-.572-.846-.746-1.418-.174-.572-.274-1.244-.274-2.04v-1.84c0-.796.075-1.492.25-2.04.173-.547.422-1.044.72-1.417a2.79 2.79 0 011.12-.821c.423-.174.92-.249 1.467-.249s1.02.075 1.468.249c.447.174.82.448 1.144.82.323.374.572.846.746 1.418.174.572.274 1.244.274 2.04v1.84zm-2.114-2.14c0-.472-.025-.87-.1-1.193a2.492 2.492 0 00-.299-.771c-.124-.2-.273-.349-.472-.423a1.732 1.732 0 00-.622-.125c-.224 0-.448.05-.622.125a1.086 1.086 0 00-.472.423 2.492 2.492 0 00-.299.77c-.074.324-.1.722-.1 1.195v2.412c0 .473.026.87.1 1.194.075.323.174.597.299.796.124.199.273.348.472.423.2.075.398.124.622.124.224 0 .448-.05.622-.124.174-.075.348-.224.473-.423.124-.199.223-.473.273-.796.05-.323.1-.721.1-1.194V29.03h.024z"
      fill="currentColor"
    />
  </svg>
);
