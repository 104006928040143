import { FilterCategories, FilterField } from 'domains/topicSearchFilter/types';
import { ErrorAction } from 'types';

export enum TopicContentMetaTypes {
  FETCH_CONTENT_META = '[Topic detail] - Fetch Content Meta',
  FETCH_CONTENT_META_SUCCESS = '[Topic detail] - Fetch Content Meta Success',
  FETCH_CONTENT_META_FAILURE = '[Topic detail] - Fetch Content Meta Failure',
}

export type TopicFetchContentMetaAction = { type: TopicContentMetaTypes.FETCH_CONTENT_META };

export type TopicFetchContentMetaSuccessAction = {
  payload: { filters: Record<FilterCategories, FilterField[]> };
  type: TopicContentMetaTypes.FETCH_CONTENT_META_SUCCESS;
};

export type TopicFetchContentMetaFailureAction = ErrorAction & {
  type: TopicContentMetaTypes.FETCH_CONTENT_META_FAILURE;
};
