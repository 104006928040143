import { RootState } from 'types';

export const getMembershipEventsIsPending = (state: RootState) => state.domains.membershipEvents.isPending;

export const getMembershipEventsById = (id?: string) => (state: RootState) =>
  id ? state.domains.membershipEvents.data[id] : undefined;

export const getMembershipEvents = (limit?: number) => (state: RootState) => {
  const ids = state.domains.membershipEvents.ids || [];
  return (limit ? ids.slice(0, limit) : ids).map((id) => state.domains.membershipEvents.data[id]);
};
