import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { CustomTopicGrid } from 'domains/customTopicGrid/CustomTopicGrid';
import { useTranslations } from 'domains/language/useTranslations';
import { fetchFollowedTopics } from 'entities/topics/actions';
import {
  getFollowedTopics,
  getFollowedTopicsIsPending,
  getFollowedTopicsHasPossibleNextPage,
} from 'entities/topics/selectors';

const requestLimit = 6 * 8;

const StyledContainer = styled.div`
  padding-top: 3rem;
`;

export const FollowedTopicsPage = () => {
  const dispatch = useDispatch();
  const { textDictionary } = useTranslations();
  const topics = useSelector(getFollowedTopics);
  const isFetching = useSelector(getFollowedTopicsIsPending);
  const isPossibleNextPage = useSelector(getFollowedTopicsHasPossibleNextPage);

  const fetchTopics = (shouldInvalidate = false) => {
    const offset = shouldInvalidate ? 0 : topics.length;
    if (!isFetching) {
      dispatch(
        fetchFollowedTopics({
          limit: requestLimit,
          offset,
        }),
      );
    }
  };

  return (
    <StyledContainer>
      <CustomTopicGrid
        topics={topics}
        fetchTopics={fetchTopics}
        isFetching={isFetching}
        isPossibleNextPage={isPossibleNextPage}
        title={textDictionary['app.monitor.following_topics']}
        titleDataTestId={'followed-topics-section-title'}
      />
    </StyledContainer>
  );
};
