import { styled } from 'styled-components';

import { Image } from 'common/components';
import { ImageProps } from 'common/components/image/Image';
import { ImagePlaceholder } from 'common/components/image/ImagePlaceholder';
import { imageFadeInStyle } from 'common/components/image/imageStyles';

const StyledImagePlaceholder = styled(ImagePlaceholder)`
  ${imageFadeInStyle}
`;

export const TopicImage = (props: ImageProps) => (
  <Image renderPlaceholder={() => <StyledImagePlaceholder className={props.className} type="topic" />} {...props} />
);
