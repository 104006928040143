import { combineReducers } from 'redux';

import { BookmarkActionTypes, BookmarksAction, BookmarksFetchFulfilledAction } from 'domains/bookmarks/actionTypes';
import { ClusterDigDeeperAction, ClusterActionType } from 'domains/clusters/actionTypes';
import { CustomKnowledge } from 'domains/customContentAndSources/types';
import { CustomKeyIssueFPKnowledgeFulfilledAction, CustomKeyIssueTypes } from 'domains/customKeyIssue/actionTypes';
import { LatestKnowledgeAction, LatestKnowledgeActionType } from 'domains/monitor/latestKnowledge/actionTypes';
import {
  FetchKnowledgeDataSearchFulfilledAction,
  FetchPublicationSearchFulfilledAction,
  FetchVideosSearchFulfilledAction,
  SearchType,
} from 'domains/search/actionTypes';
import { KnowledgeActionTypes, KnowledgeItemAction } from 'entities/knowledge/actionTypes';
import { ContentType, Knowledge, KnowledgeState, KnowledgeType } from 'entities/knowledge/types';
import { KnowledgeDataActionTypes, KnowledgeDataFetchForTopicSuccessAction } from 'entities/knowledgeData/actionTypes';
import { KnowledgeFeedAction, KnowledgeFeedActionTypes } from 'entities/knowledgeFeed/actionTypes';
import { PublicationAction, PublicationActionTypes } from 'entities/publication/actionTypes';
import { addUserChangeReset } from 'entities/user/utils';
import { VideoActionTypes, VideosFetchForTopicSuccessAction } from 'entities/videos/actionTypes';

const initialState: KnowledgeState = {
  byId: {},
};

type Action =
  | KnowledgeItemAction
  | KnowledgeFeedAction
  | PublicationAction
  | LatestKnowledgeAction
  | ClusterDigDeeperAction
  | BookmarksAction
  | VideosFetchForTopicSuccessAction
  | KnowledgeDataFetchForTopicSuccessAction
  | FetchVideosSearchFulfilledAction
  | FetchKnowledgeDataSearchFulfilledAction
  | FetchPublicationSearchFulfilledAction
  | CustomKeyIssueFPKnowledgeFulfilledAction;

export const getKnowledgeType = (knowledge: Knowledge | CustomKnowledge): KnowledgeType => {
  switch (knowledge.source.content_type) {
    case ContentType.data:
      return KnowledgeType.data;
    case ContentType.video:
      return KnowledgeType.video;
    case ContentType.publication:
      return KnowledgeType.publication;
    default:
      return KnowledgeType.publication;
  }
};

const reduceData = (state: KnowledgeState['byId'], items: Knowledge[]): KnowledgeState['byId'] =>
  items.reduce(
    (byId, knowledge) => ({
      ...byId,
      [knowledge.id]: {
        ...state[knowledge.id],
        ...knowledge,
        knowledgeType: getKnowledgeType(knowledge),
      },
    }),
    state,
  );

const getArticles = (payload: BookmarksFetchFulfilledAction['payload']) =>
  payload.bookmarks.filter((item) => item.type === 'article') as Knowledge[];

const byId = (state = initialState.byId, action: Action): KnowledgeState['byId'] => {
  switch (action.type) {
    case ClusterActionType.FETCH_DIG_DEEPER_FULFILLED:
    case LatestKnowledgeActionType.FETCH_FULFILLED:
    case SearchType.FETCH_VIDEOS_FULFILLED:
    case SearchType.FETCH_KNOWLEDGE_DATA_FULFILLED:
    case CustomKeyIssueTypes.FETCH_FP_KNOWLEDGE_FULFILLED: {
      return reduceData(state, action.payload);
    }
    case VideoActionTypes.FETCH_FOR_TOPIC_SUCCESS:
    case PublicationActionTypes.FETCH_FOR_TOPIC_SUCCESS:
    case KnowledgeFeedActionTypes.FETCH_FOR_TOPIC_SUCCESS:
    case KnowledgeDataActionTypes.FETCH_FOR_TOPIC_SUCCESS: {
      return reduceData(state, action.payload.data);
    }
    case KnowledgeActionTypes.FETCH_FOR_ID: {
      return {
        ...state,
        [action.meta.id]: {
          ...state[action.meta.id],
          isPending: true,
        },
      };
    }
    case KnowledgeActionTypes.FETCH_FOR_ID_SUCCESS: {
      return {
        ...state,
        [action.meta.id]: {
          ...state[action.meta.id],
          ...action.payload,
          knowledgeType: getKnowledgeType(action.payload),
          isPending: false,
        },
      };
    }
    case KnowledgeActionTypes.FETCH_FOR_ID_FAILURE: {
      return {
        ...state,
        [action.meta.id]: {
          ...state[action.meta.id],
          isPending: false,
        },
      };
    }
    case BookmarkActionTypes.BOOKMARK_TOGGLE: {
      if (!state[action.meta.bookmark.id]) {
        return state;
      }
      return {
        ...state,
        [action.meta.bookmark.id]: {
          ...state[action.meta.bookmark.id],
          isBookmarkPending: true,
        },
      };
    }
    case BookmarkActionTypes.BOOKMARK_TOGGLE_FULFILLED: {
      if (!state[action.meta.bookmark.id]) {
        return state;
      }
      return {
        ...state,
        [action.meta.bookmark.id]: {
          ...state[action.meta.bookmark.id],
          isBookmarkPending: false,
          is_bookmarked: action.meta.isBookmarked,
        },
      };
    }
    case BookmarkActionTypes.BOOKMARK_TOGGLE_REJECTED: {
      if (!state[action.meta.bookmark.id]) {
        return state;
      }
      return {
        ...state,
        [action.meta.bookmark.id]: {
          ...state[action.meta.bookmark.id],
          isBookmarkPending: false,
        },
      };
    }
    case BookmarkActionTypes.BOOKMARKS_FETCH_FULFILLED: {
      const data = { ...state };
      getArticles(action.payload).forEach((item) => {
        data[item.id] = { ...state[item.id], ...item };
      });
      return data;
    }
    case SearchType.FETCH_PUBLICATIONS_FULFILLED: {
      return reduceData(state, action.payload);
    }
    default:
      return state;
  }
};

export const knowledgeReducer = combineReducers({
  byId: addUserChangeReset(byId, initialState.byId),
});
