import omit from 'lodash.omit';
import uniq from 'lodash.uniq';
import { combineReducers } from 'redux';

import { CoachmarkDomainAction, CoachmarkDomainsActionType } from 'domains/coachmarks/actionTypes';
import { CoachmarkDomainState } from 'domains/coachmarks/types';
import { addUserOrLanguageChangeReset } from 'entities/topics/reducerHelpers';

const initialState: CoachmarkDomainState = {
  isEnabled: null,
  usedIds: [],
  inDOMIds: [],
};

const isEnabled = (
  state = initialState.isEnabled,
  action: CoachmarkDomainAction,
): CoachmarkDomainState['isEnabled'] => {
  switch (action.type) {
    case CoachmarkDomainsActionType.TOGGLE:
      return !state;
    case CoachmarkDomainsActionType.FETCH_VISIBILITY_STATUS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const usedIds = (state = initialState.usedIds, action: CoachmarkDomainAction): CoachmarkDomainState['usedIds'] => {
  switch (action.type) {
    case CoachmarkDomainsActionType.ADD_TO_USED:
    case CoachmarkDomainsActionType.FETCH_USED_SUCCESS:
      return action.payload && action.payload.ids ? uniq([...state, ...action.payload.ids]) : state;

    case CoachmarkDomainsActionType.REMOVE_FROM_USED:
      return action.payload && action.payload.ids ? (omit(state, action.payload.ids) as string[]) : [];

    default:
      return state;
  }
};

const inDOMIds = (state = initialState.inDOMIds, action: CoachmarkDomainAction): CoachmarkDomainState['inDOMIds'] => {
  switch (action.type) {
    case CoachmarkDomainsActionType.ADD_TO_IN_DOM:
      return action.payload?.ids || [];
    default:
      return state;
  }
};

export const coachmarksReducer = combineReducers({
  isEnabled,
  usedIds,
  inDOMIds: addUserOrLanguageChangeReset(inDOMIds, initialState.inDOMIds),
});
