import { SVGProps } from 'react';

export const ChevronIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      d="M11.014 1.013a3.427 3.427 0 014.864 0l20.557 20.556a3.43 3.43 0 010 4.865L15.878 46.992a3.44 3.44 0 01-4.864-4.864l18.09-18.159L10.947 5.81a3.427 3.427 0 01.068-4.797z"
      fill="currentColor"
    />
  </svg>
);
