import { KeyIssue } from 'entities/keyIssues/types';
import { ErrorPayload } from 'types';

export enum KeyIssuesActionType {
  FETCH = 'domains/DIMENSIONS_FETCH',
  FETCH_FULFILLED = 'domains/DIMENSIONS_FETCH_SUCCESS',
  FETCH_REJECTED = 'domains/DIMENSIONS_FETCH_FAILURE',
  UPDATE_SEARCH_TERM = 'domains/KEY_ISSUE_LIST_UPDATE_SEARCH_TERM',
}

type KeyIssuesMeta = { id?: string; limit?: number; offset: number; page?: 'create' | 'search'; searchTerm?: string };
export type FetchKeyIssuesDefaultAction = { meta: KeyIssuesMeta; type: KeyIssuesActionType.FETCH };

export type FetchKeyIssuesFulfilledAction = {
  meta: KeyIssuesMeta;
  payload: KeyIssue[];
  type: KeyIssuesActionType.FETCH_FULFILLED;
};

export type FetchKeyIssuesRejectedAction = { payload: ErrorPayload; type: KeyIssuesActionType.FETCH_REJECTED };

export type UpdateSearchTermAction = {
  meta?: KeyIssuesMeta;
  payload: string;
  type: KeyIssuesActionType.UPDATE_SEARCH_TERM;
};

export type KeyIssuesAction =
  | FetchKeyIssuesDefaultAction
  | FetchKeyIssuesFulfilledAction
  | FetchKeyIssuesRejectedAction
  | UpdateSearchTermAction;
