import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import {
  fetchTopicAndTagSuggestionsFulfilled,
  fetchTopicAndTagSuggestionsRejected,
} from 'domains/customKeyIssue/actions';
import { CustomKeyIssueTopicAndTagSuggestionsAction } from 'domains/customKeyIssue/actionTypes';
import { TextClassificationResponse } from 'domains/customKeyIssue/types';

export function* fetchTopicAndTagSuggestionsSaga({ payload }: CustomKeyIssueTopicAndTagSuggestionsAction) {
  try {
    const title = payload.title.trim();
    const subtitle = payload.subtitle.trim();
    const description = payload.description.trim();

    if (!title && !subtitle && !description) {
      yield put(fetchTopicAndTagSuggestionsFulfilled({ fp: [], topics: [] }));
      return;
    }

    const response: TextClassificationResponse = yield apiFetch('knowledge_preview', {
      method: 'POST',
      body: {
        title: getPreviewPayloadTitle(title, subtitle),
        text: description,
      },
    });
    yield put(fetchTopicAndTagSuggestionsFulfilled(response));
  } catch (error) {
    yield put(fetchTopicAndTagSuggestionsRejected());
  }
}

const getPreviewPayloadTitle = (title?: string, subtitle?: string) => {
  if (!subtitle) {
    return title;
  }

  if (!title) {
    return subtitle;
  }

  if (title.endsWith('.')) {
    title = title.substring(0, title.length - 2);
  }
  return `${title}. ${subtitle}`;
};
