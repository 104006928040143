import {
  FetchTopicsSearchDefaultAction,
  FetchTopicsSearchFulfilledAction,
  FetchTopicsSearchRejectedAction,
  FetchPublicationSearchDefaultAction,
  FetchPublicationSearchFulfilledAction,
  FetchPublicationSearchRejectedAction,
  FetchVideosSearchDefaultAction,
  FetchVideosSearchFulfilledAction,
  FetchVideosSearchRejectedAction,
  FetchKnowledgeDataSearchDefaultAction,
  FetchKnowledgeDataSearchFulfilledAction,
  FetchKnowledgeDataSearchRejectedAction,
  SearchType,
  UpdateSearchQueryAction,
  UpdateSearchSectionVisibilityAction,
} from 'domains/search/actionTypes';

export const updateSearchQuery = (meta: UpdateSearchQueryAction['meta']): UpdateSearchQueryAction => ({
  type: SearchType.UPDATE_QUERY,
  meta,
});

export const updateSearchSectionVisibility = (meta: UpdateSearchSectionVisibilityAction['meta']) => ({
  type: SearchType.UPDATE_SECTION_VISIBILITY,
  meta,
});

export const searchTopic = (meta: FetchTopicsSearchDefaultAction['meta']): FetchTopicsSearchDefaultAction => ({
  type: SearchType.FETCH_TOPICS,
  meta,
});

export const searchTopicSuccess = (
  meta: FetchTopicsSearchFulfilledAction['meta'],
  payload: FetchTopicsSearchFulfilledAction['payload'],
): FetchTopicsSearchFulfilledAction => ({
  type: SearchType.FETCH_TOPICS_FULFILLED,
  meta,
  payload,
});

export const searchTopicRejected = (
  meta: FetchTopicsSearchRejectedAction['meta'],
): FetchTopicsSearchRejectedAction => ({
  type: SearchType.FETCH_TOPICS_REJECTED,
  meta,
});

export const searchPublications = ({
  offset = 0,
  limit = 20,
  ...meta
}: FetchPublicationSearchDefaultAction['meta']): FetchPublicationSearchDefaultAction => ({
  type: SearchType.FETCH_PUBLICATIONS,
  meta: { offset, limit, ...meta },
});

export const searchPublicationsSuccess = (
  meta: FetchPublicationSearchFulfilledAction['meta'],
  payload: FetchPublicationSearchFulfilledAction['payload'],
): FetchPublicationSearchFulfilledAction => ({
  type: SearchType.FETCH_PUBLICATIONS_FULFILLED,
  meta,
  payload,
});

export const searchPublicationsRejected = (
  meta: FetchPublicationSearchRejectedAction['meta'],
): FetchPublicationSearchRejectedAction => ({
  type: SearchType.FETCH_PUBLICATIONS_REJECTED,
  meta,
});

export const searchVideos = ({
  offset = 0,
  limit = 20,
  ...meta
}: FetchVideosSearchDefaultAction['meta']): FetchVideosSearchDefaultAction => ({
  type: SearchType.FETCH_VIDEOS,
  meta: { offset, limit, ...meta },
});

export const searchVideosSuccess = (
  meta: FetchVideosSearchFulfilledAction['meta'],
  payload: FetchVideosSearchFulfilledAction['payload'],
): FetchVideosSearchFulfilledAction => ({
  type: SearchType.FETCH_VIDEOS_FULFILLED,
  meta,
  payload,
});

export const searchVideosRejected = (
  meta: FetchVideosSearchRejectedAction['meta'],
): FetchVideosSearchRejectedAction => ({
  type: SearchType.FETCH_VIDEOS_REJECTED,
  meta,
});

export const searchKnowledgeData = ({
  offset = 0,
  limit = 20,
  ...meta
}: FetchKnowledgeDataSearchDefaultAction['meta']): FetchKnowledgeDataSearchDefaultAction => ({
  type: SearchType.FETCH_KNOWLEDGE_DATA,
  meta: { offset, limit, ...meta },
});

export const searchKnowledgeDataSuccess = (
  meta: FetchKnowledgeDataSearchFulfilledAction['meta'],
  payload: FetchKnowledgeDataSearchFulfilledAction['payload'],
): FetchKnowledgeDataSearchFulfilledAction => ({
  type: SearchType.FETCH_KNOWLEDGE_DATA_FULFILLED,
  meta,
  payload,
});

export const searchKnowledgeDataRejected = (
  meta: FetchKnowledgeDataSearchRejectedAction['meta'],
): FetchKnowledgeDataSearchRejectedAction => ({
  type: SearchType.FETCH_KNOWLEDGE_DATA_REJECTED,
  meta,
});
