import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from 'styled-components';

import { SectionTitleUnderlined, Image, UIFollowButton, UIButton, RightArrow } from 'common/components';
import { lineTruncationStyle } from 'common/styles';
import { useTranslations } from 'domains/language/useTranslations';
import { fetchSuggestions } from 'domains/monitor/suggestions/actions';
import { getSuggestedTopics, getIsSuggestionsPending } from 'domains/monitor/suggestions/selectors';
import { useRouterLink } from 'domains/routing/utils';
import { followTopic } from 'entities/topics/actions';

const StyledContainer = styled.div`
  margin-top: 2rem;
`;

const StyledTopic = styled.div`
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledTitle = styled.p`
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  ${lineTruncationStyle(2)};
`;

const StyledImage = styled(Image)`
  width: 64px;
  height: 64px;
  border-radius: 4px;
  margin-right: 16px;
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
`;

const StyledRightArrow = styled(RightArrow)`
  width: 14px;
  height: 14px;
  margin-left: 10px;
`;

export const TopicSuggestions = () => {
  const dispatch = useDispatch();
  const { textDictionary } = useTranslations();
  const suggestions = useSelector(getSuggestedTopics);
  const isPending = useSelector(getIsSuggestionsPending);
  const link = useRouterLink({ to: 'discover' });

  useEffect(() => {
    dispatch(fetchSuggestions());
  }, [dispatch]);

  if (isPending || suggestions.length === 0) {
    return null;
  }

  const followTopicHandle = (id: string) => () => dispatch(followTopic({ id }, { event: { page: 'Monitor' } }));

  const allButton = (
    <UIButton as={'a'} href={link} variant="neutral">
      {textDictionary['app.monitor.see_more_topics']}
      <StyledRightArrow />
    </UIButton>
  );

  return (
    <StyledContainer>
      <SectionTitleUnderlined rightElement={allButton}>
        {textDictionary['app.monitor.suggested_topics.title']}
      </SectionTitleUnderlined>
      {suggestions.map((topic) => (
        <StyledTopic key={topic.id}>
          <StyledRow>
            <StyledImage src={topic.images?.thumbnail} alt={topic.name} />
            <StyledTitle>{topic.name}</StyledTitle>
          </StyledRow>
          <UIFollowButton
            variant={'collapsed'}
            isFollowing={false}
            onClick={followTopicHandle(topic.id)}
            isLoading={topic.isFollowingUpdating || false}
          />
        </StyledTopic>
      ))}
    </StyledContainer>
  );
};
