import { SVGProps } from 'react';

export const PauseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      d="M19.2 33.6c1.32 0 2.4-1.08 2.4-2.4V16.8c0-1.32-1.08-2.4-2.4-2.4-1.32 0-2.4 1.08-2.4 2.4v14.4c0 1.32 1.08 2.4 2.4 2.4zM24 0C10.752 0 0 10.752 0 24s10.752 24 24 24 24-10.752 24-24S37.248 0 24 0zm0 43.2C13.416 43.2 4.8 34.584 4.8 24S13.416 4.8 24 4.8 43.2 13.416 43.2 24 34.584 43.2 24 43.2zm4.8-9.6c1.32 0 2.4-1.08 2.4-2.4V16.8c0-1.32-1.08-2.4-2.4-2.4-1.32 0-2.4 1.08-2.4 2.4v14.4c0 1.32 1.08 2.4 2.4 2.4z"
      fill="currentColor"
    />
  </svg>
);
