import { isSameSearchCriteria } from 'domains/publication/reducer';
import {
  getTransformationMapSelectedId,
  getTransformationMapSelectedOuterNode,
} from 'domains/transformationMap/selectors';
import { AdvancedTopicKnowledgeSearchCriteria } from 'domains/types';
import { Knowledge } from 'entities/knowledge/types';
import { RootState } from 'types';

const getPublicationStateForTopic = (state: RootState, topicId: string) => {
  const { offset: _offset, limit: _limit, ...initialCriteria } = getPublicationSearchCriteria(state);
  const searchCriteria = {
    ...initialCriteria,
    ...getAdvancedKnowledgeCriteria(state),
    id: topicId,
  };
  const matchingPublicationPackage = state.domains.publication.forTopic[topicId]?.find((publicationPackage) =>
    isSameSearchCriteria(publicationPackage.searchCriteria, searchCriteria),
  );
  return matchingPublicationPackage;
};

export const getPublicationsForTopic =
  (topicId: string, limit?: number) =>
  (state: RootState): Knowledge[] => {
    const publications = (getPublicationStateForTopic(state, topicId)?.data || []).map(
      (id) => state.entities.knowledge.byId[id],
    );
    if (limit) {
      return publications.slice(0, limit);
    }
    return publications;
  };

export const getPublicationForTopicIsPending = (topicId: string) => (state: RootState) =>
  getPublicationStateForTopic(state, topicId)?.isFetching || false;

export const getPublicationForTopicHasNextPage = (topicId: string) => (state: RootState) =>
  getPublicationStateForTopic(state, topicId)?.hasNextPage || false;

export const getPublicationSearchCriteria = (state: RootState) => state.domains.publication.searchCriteria;

export const getPublicationSearchTerm = (state: RootState) => state.domains.publication.searchCriteria.searchTerm;

export const getIsPublicationFiltered = (state: RootState) => {
  const filter = state.domains.publication.searchCriteria;
  return (
    !!filter.language ||
    !!filter.publicationType ||
    !!filter.searchTerm ||
    !!getTransformationMapSelectedOuterNode(state)?.id
  );
};

export const getIsPublicationFilterCount = (state: RootState) => {
  const { language, publicationType, searchTerm } = state.domains.publication.searchCriteria;
  const count = [language, publicationType, searchTerm].filter((item) => !!item).length;
  return count;
};

export const getAdvancedKnowledgeCriteria = (state: RootState): AdvancedTopicKnowledgeSearchCriteria => {
  const id = getTransformationMapSelectedId(state);
  const secondaryTopic = getTransformationMapSelectedOuterNode(state);

  return {
    id,
    secondaryTopicId: secondaryTopic?.id,
  };
};
