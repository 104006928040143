import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getCurrentThemeMode } from 'domains/theme/selectors';

const getMediaMatch: () => MediaQueryList | undefined = () => window.matchMedia('(prefers-color-scheme: dark)');

export const useThemeAppearance = () => {
  const userSelectedTheme = useSelector(getCurrentThemeMode);
  const getSystemDarkThemePreference = () => getMediaMatch()?.matches || false;
  const [isSystemPreferenceDarkTheme, setIsSystemPreferenceDarkTheme] = useState(getSystemDarkThemePreference);

  useEffect(() => {
    const mqListener = (e: MediaQueryListEvent) => setIsSystemPreferenceDarkTheme(e.matches);

    const darkThemeMq = getMediaMatch();
    if (!darkThemeMq || !darkThemeMq.addEventListener || !darkThemeMq.removeEventListener) {
      return;
    }
    darkThemeMq.addEventListener('change', mqListener);
    return () => darkThemeMq.removeEventListener('change', mqListener);
  }, []);

  const systemTheme = isSystemPreferenceDarkTheme ? 'dark' : 'light';

  return userSelectedTheme === 'default' ? systemTheme : userSelectedTheme;
};
