import { LanguagePayload } from 'entities/languages/types';

export enum LanguageActionTypes {
  FETCH_ALL = 'entities/LANGUAGES_FETCH_ALL',
  FETCH_ALL_FULFILLED = 'entities/LANGUAGES_FETCH_ALL_SUCCESS',
  FETCH_ALL_REJECTED = 'entities/LANGUAGES_FETCH_ALL_FAILURE',
}

export type FetchLanguagesDefaultAction = { type: LanguageActionTypes.FETCH_ALL };
export type FetchLanguagesFulfilledAction = { payload: LanguagePayload; type: LanguageActionTypes.FETCH_ALL_FULFILLED };
export type FetchLanguagesRejectedAction = { type: LanguageActionTypes.FETCH_ALL_REJECTED };
export type LanguageAction = FetchLanguagesDefaultAction | FetchLanguagesFulfilledAction | FetchLanguagesRejectedAction;
