import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import {
  JoinUsUpgradeBanner,
  ExpandableText,
  MasterDetailContentWrapper,
  SectionTitleUnderlined,
  UIActionButton,
  UIButtonsRow,
} from 'common/components';
import { trackEvent } from 'domains/analytics/actions';
import { toggleBookmark } from 'domains/bookmarks/actions';
import { KnowledgeDataDetail } from 'domains/knowledgeData/components/KnowledgeDataDetail';
import { getSiteLanguage } from 'domains/language/selectors';
import { useTranslations } from 'domains/language/useTranslations';
import { ArticleDetail } from 'domains/publication/components/ArticleDetail';
import { useTranslationLink } from 'domains/publication/useTranslationLink';
import { shareLinkAction } from 'domains/share/actions';
import { KnowledgeSignalSection } from 'domains/signals/components/KnowledgeSignalSection';
import { TopicSlider } from 'domains/topic/components/TopicSlider';
import { VideoDetail } from 'domains/videos/components/VideoDetail';
import { fetchKnowledgeById } from 'entities/knowledge/actions';
import { Knowledge, KnowledgeType } from 'entities/knowledge/types';
import { getCanAccessMonitor, getCanDisplayBookmarks, getUserId } from 'entities/user/selectors';

const StyledTitle = styled.h2`
  font-size: 2rem;
  margin: 0.6rem 0;
`;

const StyledContent = styled.div`
  height: 100%;
  margin: 1rem 0;
  color: ${({ theme }) => theme.colors.fillPrimary};
`;

const StyledButtons = styled(UIButtonsRow)`
  margin-top: 2rem;
`;

const StyledDescription = styled(ExpandableText)`
  p {
    font-size: 1.1rem;
    line-height: 1.75rem;
    white-space: pre-line;
  }
`;

const StyledContentSeparationSpace = styled.div`
  height: 2rem;
`;

type KnowledgeDetailProps = { id?: string; knowledge?: Knowledge; shareLink?: string };

export const KnowledgeDetail = ({ knowledge, shareLink, id }: KnowledgeDetailProps) => {
  const dispatch = useDispatch();
  const { textDictionary } = useTranslations();
  const hasAccessToMonitor = useSelector(getCanAccessMonitor);
  const hasAccessToBookmarks = useSelector(getCanDisplayBookmarks);
  const userId = useSelector(getUserId);
  const language = useSelector(getSiteLanguage);
  const { translationUrl, displayTranslationLink, translationLinkLabel, onTranslationLinkClick } =
    useTranslationLink(knowledge);

  useEffect(() => {
    if (id) {
      dispatch(fetchKnowledgeById({ id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, userId, language]);

  if (!knowledge) {
    return null;
  }

  const { title, summary, link, isBookmarkPending, is_bookmarked, topics = [], signals } = knowledge;

  const onBookmarkHandle = () => {
    dispatch(
      toggleBookmark({
        bookmark: knowledge,
        isBookmarked: !is_bookmarked,
        entityType: 'Knowledge',
      }),
    );
  };

  const trackOpenLink = () => {
    dispatch(
      trackEvent({
        name: 'Open Link',
        entityId: id,
        entityName: title,
        entityType: 'Knowledge',
      }),
    );
  };

  const onShareClick = () =>
    dispatch(
      shareLinkAction({
        id: id || '',
        name: title,
        link: shareLink || '',
        type: 'Knowledge',
      }),
    );

  let topicsSlider = null;
  if (!hasAccessToMonitor) {
    topicsSlider = (
      <MasterDetailContentWrapper>
        <JoinUsUpgradeBanner trackPage="Knowledge detail" />
      </MasterDetailContentWrapper>
    );
  } else if (topics.length > 0) {
    topicsSlider = (
      <>
        <MasterDetailContentWrapper>
          <SectionTitleUnderlined>{textDictionary['app.topic.related_topics']}</SectionTitleUnderlined>
          <TopicSlider
            topics={topics}
            hideFollowAction={true}
            numberOfItems={{
              small: 1,
              medium: 3,
              large: 2,
              extraLarge: 3,
              enormous: 4,
            }}
          />
        </MasterDetailContentWrapper>
      </>
    );
  }
  let mainContent = undefined;
  if (knowledge.knowledgeType === KnowledgeType.video) {
    mainContent = <VideoDetail knowledge={knowledge} />;
  } else if (knowledge.interactive_link) {
    mainContent = <KnowledgeDataDetail knowledge={knowledge} />;
  } else {
    mainContent = <ArticleDetail knowledge={knowledge} />;
  }

  return (
    <>
      <MasterDetailContentWrapper>
        {mainContent}
        <StyledContent>
          <StyledTitle>{title}</StyledTitle>
          <StyledDescription text={summary} />
          <StyledButtons>
            <UIActionButton type="open" href={link} onClick={trackOpenLink} />
            {shareLink && id && <UIActionButton type="share" onClick={onShareClick} />}
            {hasAccessToBookmarks && (
              <UIActionButton
                type="bookmark"
                isActive={is_bookmarked}
                isLoading={isBookmarkPending}
                onClick={onBookmarkHandle}
              />
            )}
            {displayTranslationLink && (
              <UIActionButton
                type="open"
                href={translationUrl}
                onClick={onTranslationLinkClick}
                label={translationLinkLabel}
              />
            )}
          </StyledButtons>
        </StyledContent>
        <KnowledgeSignalSection signals={signals} />
      </MasterDetailContentWrapper>
      <StyledContentSeparationSpace />
      {topicsSlider}
    </>
  );
};
