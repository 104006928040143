import { produce } from 'immer';

import { Status } from 'common/types/status';
import { CollectionAction, CollectionActionType } from 'domains/collection/actionTypes';
import { getDisplayFields } from 'domains/collection/helpers/getDisplayFields';
import { CollectionViewModel, CollectionsState } from 'domains/collection/types';
import { CollectionType } from 'domains/collection/types/collectionType';
import { TopicAction, TopicActionType } from 'entities/topics/actionTypes';
import { addUserChangeReset } from 'entities/user/utils';

const initialState: CollectionsState = {
  communities: [],
  editable: [],
  featured: [],
  listed: [],
  nextPageMayExist: { communities: false, editable: false },
  offset: { editable: 0 },
  status: { editable: Status.Ready, featured: Status.Ready, listed: Status.Ready, communities: Status.Ready },

  forViewing: Status.Ready,
  forEditing: Status.Ready,
  uploadedImageUrls: {},
};

const collections = (state = initialState, action: CollectionAction | TopicAction): CollectionsState => {
  const update = (recipe: (draft: CollectionsState) => void) => produce(state, recipe);
  switch (action.type) {
    case CollectionActionType.FETCH_COLLECTION_FOR_VIEWING:
      return { ...state, forViewing: Status.Loading };
    case CollectionActionType.FETCH_COLLECTION_FOR_VIEWING_SUCCESS:
      return update((draft) => {
        draft.forViewing = { ...action.response, topics: { nextPageMayExist: true, offset: 0, status: Status.Ready } };
      });
    case CollectionActionType.FETCH_COLLECTION_FOR_VIEWING_FAILURE:
      return { ...state, forViewing: Status.Error };
    case CollectionActionType.FETCH_COLLECTION_FOR_EDITING:
      return { ...state, forEditing: Status.Loading };
    case CollectionActionType.FETCH_COLLECTION_FOR_EDITING_SUCCESS:
      return update((draft) => {
        draft.forEditing = { ...action.response, topics: { nextPageMayExist: true, offset: 0, status: Status.Ready } };
      });
    case CollectionActionType.FETCH_COLLECTION_FOR_EDITING_FAILURE:
      return { ...state, forEditing: Status.Error };
    case CollectionActionType.FETCH_COLLECTIONS:
      return update((draft) => {
        draft.status[typeToKey(action.payload.type)] = Status.Loading;
        if (action.payload.type === CollectionType.Editable)
          draft.offset.editable = action.payload.offset + action.payload.limit;
      });
    case CollectionActionType.FETCH_COLLECTIONS_SUCCESS:
      return update((draft) => {
        const key = typeToKey(action.payload.type);
        if (action.payload.offset === 0) draft[key] = [];
        const response =
          action.payload.type === CollectionType.Editable
            ? action.payload.response.filter(({ can_edit }) => can_edit)
            : action.payload.response;
        draft[key].push(
          ...response.map((item) => ({
            ...item,
            topics: { nextPageMayExist: true, offset: action.payload.offset, status: Status.Ready },
          })),
        );
        draft.status[key] = Status.Ready;
        if (action.payload.type === CollectionType.Editable)
          draft.nextPageMayExist.editable = action.payload.response.length === action.payload.limit;
      });
    case CollectionActionType.FETCH_COLLECTIONS_FAILURE:
      return update((draft) => {
        draft.status[typeToKey(action.payload.type)] = Status.Error;
      });
    case CollectionActionType.FETCH_COMMUNITIES:
      return update((draft) => {
        draft.status.communities = Status.Loading;
      });
    case CollectionActionType.FETCH_COMMUNITIES_SUCCESS:
      return update((draft) => {
        if (action.payload.meta.offset === 0) draft.communities = action.payload.result;
        else draft.communities.push(...action.payload.result);
        draft.nextPageMayExist.communities = action.payload.result.length === action.payload.meta.limit;
        draft.status.communities = Status.Ready;
      });
    case CollectionActionType.FETCH_COMMUNITIES_FAILURE:
      return update((draft) => {
        draft.status.communities === Status.Error;
      });
    case CollectionActionType.CLEAR_COMMUNITIES:
      return update((draft) => {
        draft.communities = [];
        draft.status.communities = Status.Ready;
      });
    case TopicActionType.FETCH_TOPICS_FOR_COLLECTION:
      return update((draft) => {
        keys.forEach((key) => {
          const item = draft[key].find((item) => item.id === action.payload.id);
          if (item !== undefined) item.topics.status = Status.Loading;
        });
        if (typeof draft.forViewing !== 'string' && draft.forViewing.id === action.payload.id)
          draft.forViewing.topics.status = Status.Loading;
      });
    case TopicActionType.FETCH_TOPICS_FOR_COLLECTION_SUCCESS:
      return update((draft) => {
        const topics = {
          nextPageMayExist: action.meta.numberOfTopicsFetched === action.meta.limit,
          offset: action.meta.offset + action.meta.numberOfTopicsFetched,
          status: Status.Ready,
        };
        keys.forEach((key) => {
          const item = draft[key].find((item) => item.id === action.payload.id);
          if (item !== undefined) item.topics = topics;
        });
        if (typeof draft.forViewing !== 'string' && draft.forViewing.id === action.payload.id)
          draft.forViewing.topics = topics;
      });
    case CollectionActionType.UPLOAD_IMAGE_SUCCESS:
      return update((draft) => {
        if ('image_url' in action.payload.response)
          draft.uploadedImageUrls.community = action.payload.response.image_url;
        else draft.uploadedImageUrls.hero = action.payload.response.hero_image_url;
      });
    case CollectionActionType.DELETE_COLLECTION:
      return update((draft) => {
        keys.forEach((key) => (draft[key] = draft[key].filter(({ id }) => id !== action.payload.id)));
      });
    case CollectionActionType.UPDATE_COLLECTION:
      return update((draft) => {
        const model: CollectionViewModel = {
          ...action.payload,
          ...getDisplayFields(action.payload, action.siteLanguage),
          can_edit: true,
          is_following: false,
          is_client_side: true,
          topics: { nextPageMayExist: true, offset: 0, status: Status.Ready },
        };
        keys.forEach((key) => {
          draft[key] = draft[key].filter(({ id }) => id !== model.id);
          if (key === 'editable' || (key === 'featured' && model.is_featured) || (key === 'listed' && model.is_listed))
            draft[key].push(model);
          draft[key].sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
        });
      });
    case CollectionActionType.CLEAR_SINGLE:
      return update((draft) => {
        draft.forViewing = Status.Ready;
        draft.forEditing = Status.Ready;
      });
    case CollectionActionType.CLEAR_COMMUNITY_IMAGE_URL:
      return update((draft) => {
        draft.uploadedImageUrls.community = undefined;
      });
    case CollectionActionType.CLEAR_HERO_IMAGE_URL:
      return update((draft) => {
        draft.uploadedImageUrls.hero = undefined;
      });
  }
  return state;
};

export const collectionsReducer = addUserChangeReset(collections, initialState);

const typeToKey = (type: CollectionType) => {
  if (type === CollectionType.Editable) return 'editable';
  else if (type === CollectionType.Featured) return 'featured';
  return 'listed';
};

const keys = ['editable', 'featured', 'listed'] as const;
