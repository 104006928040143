import { MouseEvent, ReactNode } from 'react';
import { styled } from 'styled-components';

import { CheckIcon, PlusIcon } from 'common/components/icons';
import { Spinner } from 'common/components/index';
import { useTranslations } from 'domains/language/useTranslations';

const StyledInteractiveContainer = styled.button<{ isFollowing: boolean }>`
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 20px;
  transition:
    padding 0.3s ease,
    color 0.3s ease,
    background-color 0.3s ease;
  cursor: pointer;
  outline: none;
  font-weight: bold;
  border-style: solid;
  border-width: 2px;
  border-color: ${({ theme }) => theme.colors.buttonFollowPrimary};
  color: ${({ theme, isFollowing }) =>
    isFollowing ? theme.colors.buttonFollowSecondary : theme.colors.buttonFollowPrimary};
  background-color: ${({ theme, isFollowing }) => (isFollowing ? theme.colors.buttonFollowPrimary : 'transparent')};

  /* Safari hack to force it to render on top of the image, see TopicTile */
  transform: translate3d(0, 0, 0);

  &:hover {
    padding: 4px 10px;
    color: ${({ theme }) => theme.colors.buttonFollowPrimary};
    background-color: ${({ theme }) => theme.colors.buttonOutlinedActiveBackground};
  }
`;

const StyledOpenContainer = styled.button<{ isFollowing: boolean }>`
  display: flex;
  align-items: center;
  padding: 4px 5px 4px 24px;
  border-radius: 32px;
  transition: all 0.3s ease;
  cursor: pointer;
  outline: none;
  font-weight: bold;
  border-style: solid;
  border-width: 2px;
  border-color: ${({ theme }) => theme.colors.buttonFollowPrimary};
  color: ${({ theme, isFollowing }) =>
    isFollowing ? theme.colors.buttonFollowSecondary : theme.colors.buttonFollowPrimary};
  background-color: ${({ theme, isFollowing }) =>
    isFollowing ? theme.colors.buttonFollowPrimary : theme.colors.buttonFollowSecondary};

  > svg {
    margin-left: 5px;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.buttonFollowPrimary};
    background-color: ${({ theme }) => theme.colors.buttonOutlinedActiveBackground};
  }
`;

const StyledFollowText = styled.span`
  font-size: 0;
  margin-right: 0;
  opacity: 0;
  transform: translate3d(0, 33%, 0);
  width: 0;
  transition:
    font-size 0.2s ease,
    margin-right 0.2s ease,
    opacity 0.3s 0.1s ease,
    transform 0.3s 0.1s ease,
    width 0.2s;

  @media (hover: hover) {
    ${StyledInteractiveContainer}:hover & {
      font-size: 0.9rem;
      padding: 0 14px 0 6px;
      opacity: 1;
      transform: translate3d(0, 0%, 0);
      line-height: 16px;
      width: auto;
    }
  }
`;

const StyledFollowButton = styled.button<{ isFollowing: boolean }>`
  color: ${({ theme, isFollowing }) =>
    isFollowing ? theme.colors.buttonFollowSecondary : theme.colors.buttonFollowPrimary};
  background-color: ${({ theme, isFollowing }) => (isFollowing ? theme.colors.buttonFollowPrimary : 'transparent')};
  padding: 0;
  transition: 0.3s all;
  display: flex;
  border-radius: 20px;
  cursor: pointer;
  width: 40px;
  min-width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-color: ${({ theme }) => theme.colors.buttonFollowPrimary};
  border-style: solid;
  border-width: 2px;
  &:hover {
    color: ${({ theme }) => theme.colors.buttonFollowPrimary};
    background-color: ${({ theme }) => theme.colors.buttonOutlinedActiveBackground};
  }
`;

const StyledUnfollowIcon = styled(CheckIcon)`
  width: 12px;
  height: 12px;
  fill: none;
  padding: 7px;
`;

const StyledFollowIcon = styled(PlusIcon)`
  width: 14px;
  height: 14px;
  padding: 7px;
`;

const StyledLoadingWrapper = styled.div`
  padding: 7px;
  color: ${({ theme }) => theme.colors.buttonFollowPrimary};
`;

const StyledSpinner = styled(Spinner)`
  width: 22px;
  height: 22px;
  padding: 3px;
`;

type UIFollowButtonProps = {
  className?: string;
  isFollowing: boolean;
  isLoading: boolean;
  onClick: (event: MouseEvent) => void;
  variant: 'collapsed' | 'open' | 'interactive';
};

/*
  use in follow button in
   - topic tile
      interactive variant
      not themed
   - collections
      open variant
      themed
   - monitor page
      collapsed variant
      themed
 */

export const UIFollowButton = ({ variant, isFollowing, onClick, isLoading, className }: UIFollowButtonProps) => {
  const { textDictionary } = useTranslations();

  const followText = isFollowing ? textDictionary['app.topic.following'] : textDictionary['app.topic.follow'];

  let icon: ReactNode = null;
  if (isLoading) {
    icon = <StyledSpinner />;
  } else {
    icon = isFollowing ? <StyledUnfollowIcon /> : <StyledFollowIcon />;
  }

  const onClickWithoutPropagation = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    if (onClick && !isLoading) {
      onClick(event);
    }
  };

  if (variant === 'interactive') {
    return (
      <StyledInteractiveContainer isFollowing={isFollowing} onClick={onClickWithoutPropagation} className={className}>
        <StyledFollowText>{followText}</StyledFollowText>
        {icon}
      </StyledInteractiveContainer>
    );
  }

  if (variant === 'open') {
    return (
      <StyledOpenContainer isFollowing={isFollowing} onClick={onClickWithoutPropagation} className={className}>
        {followText}
        {icon}
      </StyledOpenContainer>
    );
  }

  if (isLoading) {
    return <StyledLoadingWrapper onClick={onClickWithoutPropagation}>{icon}</StyledLoadingWrapper>;
  }

  return (
    <StyledFollowButton isFollowing={isFollowing} onClick={onClickWithoutPropagation} className={className}>
      {icon}
    </StyledFollowButton>
  );
};
