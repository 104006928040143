import { Route, Switch } from 'react-router-dom';
import { styled } from 'styled-components';

import { InteractiveDataSearchPage } from 'domains/search/components/interactiveData/InteractiveDataSearchPage';
import { SearchKnowledgePage } from 'domains/search/components/publications/PublicationsSearchPage';
import { SearchPage } from 'domains/search/components/SearchPage';
import { VideoSearchPage } from 'domains/search/components/videos/VideosSearchPage';

const StyledContainer = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
`;

export const SearchRoutes = () => (
  <StyledContainer>
    <Switch>
      <Route path="/search/publications/:knowledgeId?">
        <SearchKnowledgePage />
      </Route>
      <Route path="/search/videos/:videoId?">
        <VideoSearchPage />
      </Route>
      <Route path="/search/interactive-data/:dataId?">
        <InteractiveDataSearchPage />
      </Route>
      <Route exact={true} path="/search">
        <SearchPage />
      </Route>
    </Switch>
  </StyledContainer>
);
