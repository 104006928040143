import { styled } from 'styled-components';

import { StarIcon, UIButton } from 'common/components';
import { useTranslations } from 'domains/language/useTranslations';

const StyledStarIcon = styled(StarIcon)`
  height: 16px;
  margin-left: 10px;
`;

type CreateCustomKeyIssueButtonProps = { className?: string; onAdd: () => void; showStarIcon: boolean };

export const CreateCustomKeyIssueButton = ({ onAdd, showStarIcon }: CreateCustomKeyIssueButtonProps) => {
  const { textDictionary } = useTranslations();

  return (
    <UIButton
      onClick={onAdd}
      title={textDictionary['app.custom_key_issue.create_button.help_text']}
      data-test-id={'create-custom-key-issue-button'}
    >
      {textDictionary['app.custom_key_issue.add_issue']}
      {showStarIcon && <StyledStarIcon />}
    </UIButton>
  );
};
