import { MouseEvent } from 'react';
import { styled } from 'styled-components';

import { CrossCircledIcon, AddItemIcon, Spinner } from 'common/components';
import { useTranslations } from 'domains/language/useTranslations';

const StyledPlusIcon = styled(AddItemIcon)`
  width: 16px;
  height: 16px;
  margin-right: 1rem;
`;

const StyledCrossIcon = styled(CrossCircledIcon)`
  width: 16px;
  height: 16px;
  margin-right: 1rem;
`;

const StyledSpinner = styled(Spinner)`
  width: 18px;
  height: 18px;
  margin-right: 1rem;
`;

type AddRemoveButtonProps = {
  dataTestId?: string;
  isAdded: boolean;
  isPending: boolean;
  onAdd?: () => void;
  onRemove?: () => void;
};

const StyledAddRemoveButton = styled.button`
  display: flex;
  width: 100%;
  font-weight: bold;
  align-items: center;
  padding: 0 16px 0 96px;
  cursor: pointer;
  transition: 0.3s;
  color: ${({ theme }) => theme.colors.textFade};
  background: none;
  border: none;
  height: 100%;
  &:hover {
    background-color: ${({ theme }) => theme.colors.activeButtonHoverBackground};
    color: ${({ theme }) => theme.colors.textBright};
  }
`;

export const AddRemoveButton = ({ isPending, isAdded, onAdd, onRemove, dataTestId }: AddRemoveButtonProps) => {
  const { textDictionary } = useTranslations();
  const handleTouchClick = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    return isAdded ? onRemove && onRemove() : onAdd && onAdd();
  };

  if (isPending) {
    return (
      <StyledAddRemoveButton>
        <StyledSpinner />
        {textDictionary['app.loading']}
      </StyledAddRemoveButton>
    );
  }

  if (isAdded) {
    return (
      <StyledAddRemoveButton onClick={handleTouchClick} data-test-id={dataTestId}>
        <StyledCrossIcon />
        {textDictionary['app.create.remove']}
      </StyledAddRemoveButton>
    );
  }

  return (
    <StyledAddRemoveButton onClick={handleTouchClick} data-test-id={dataTestId}>
      <StyledPlusIcon />
      {textDictionary['app.create.add']}
    </StyledAddRemoveButton>
  );
};
