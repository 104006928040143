import { ClusterListItem } from 'domains/clusters/components/ClusterListItem';
import { Cluster } from 'domains/clusters/types';
import { KnowledgeListItem } from 'domains/publication/components/KnowledgeListItem';
import { Knowledge } from 'entities/knowledge/types';

type BookmarkItemProps = { bookmark: Cluster | Knowledge; isSelected?: boolean };

export const BookmarkItem = ({ bookmark, isSelected = false }: BookmarkItemProps) => {
  if (bookmark.type === 'cluster') {
    return (
      <ClusterListItem
        cluster={bookmark}
        isSelected={isSelected}
        link={`/bookmarks/${bookmark.id}`}
        shareLink={`/monitor/emerging-themes/${bookmark.id}`}
        trackPage="Bookmarks"
        isCompact={true}
      />
    );
  }
  return (
    <KnowledgeListItem
      knowledge={bookmark}
      link={`/bookmarks/${bookmark.id}`}
      shareLink={`/monitor/latest-knowledge/${bookmark.id}`}
      isSelected={isSelected}
      trackPage="Bookmarks"
      type="large"
    />
  );
};
