import {
  FetchIncidentsAction,
  FetchIncidentsFailureAction,
  FetchIncidentsSuccessAction,
  DismissIncidentAction,
  IncidentTypes,
} from 'domains/incidents/constants/actionTypes';
import { APIIncident } from 'domains/incidents/types';

export const fetchUnresolvedIncidents = (): FetchIncidentsAction => ({
  type: IncidentTypes.FETCH_UNRESOLVED,
});

export const fetchUnresolvedIncidentsSuccess = (payload: APIIncident[]): FetchIncidentsSuccessAction => ({
  type: IncidentTypes.FETCH_UNRESOLVED_SUCCESS,
  payload,
});

export const fetchUnresolvedIncidentsFailure = (payload: { error: any }): FetchIncidentsFailureAction => ({
  type: IncidentTypes.FETCH_UNRESOLVED_FAILURE,
  payload,
});

export const dismissIncident = (payload: { id: string }): DismissIncidentAction => ({
  type: IncidentTypes.DISMISS_INCIDENT,
  payload,
});
