import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { AppRedirectRoute } from 'AppRedirectRoute';
import { AboutRoute } from 'domains/about/components/AboutRoute';
import { DownloadMobileApp } from 'domains/about/components/DownloadMobileApp';
import { getIsAuthenticated } from 'domains/authentication/selectors';
import { BookmarksPage } from 'domains/bookmarks/components/BookmarksPage';
import { CollectionRoutes } from 'domains/collection/components/CollectionRoutes';
import { FollowedTopicsPage } from 'domains/customTopicGrid/FollowedTopicsPage';
import { SharedTopicsPage } from 'domains/customTopicGrid/SharedTopicsPage';
import { TopicRoutes } from 'domains/discover/TopicRoutes';
import { FAQRoutes } from 'domains/faqs/components/FAQRoutes';
import { Landing } from 'domains/landing/Landing';
import { MembershipEventsRoutes } from 'domains/membershipEvents/components/MembershipEventsRoutes';
import { MonitorRoutes } from 'domains/monitor/MonitorRoutes';
import { PageNotFound } from 'domains/pageNotFound/PageNotFound';
import { ResourcesPage } from 'domains/resources/ResourcesPage';
import { PrivateRoute } from 'domains/routing/components/PrivateRoute';
import { SearchRoutes } from 'domains/search/components/SearchRoutes';
import { TocRoutes } from 'domains/termsAndConditions/components/TermsAndConditionRoutes';
import { UserRoutes } from 'domains/user/components/UserRoutes';
import { WhatsNewRoute } from 'domains/whatsNew/components/WhatsNewRoute';
import { getCanDisplayBookmarks } from 'entities/user/selectors';

export const AppRoutes = () => {
  const accessToBookmarks = useSelector(getCanDisplayBookmarks);
  const accessToAuthenticatedContent = useSelector(getIsAuthenticated);
  // TODO remove unneeded routes regarding auth changes
  return (
    <Switch>
      <Route path="/about">
        <AboutRoute />
      </Route>

      <Route exact={true} path="/">
        <Landing />
      </Route>

      <Route path={['/app', '/download-app']}>
        <DownloadMobileApp />
      </Route>

      <Route path="/monitor">
        <MonitorRoutes />
      </Route>

      <Route path="/collection">
        <CollectionRoutes />
      </Route>

      <Route path={['/create', '/topics', '/discover']}>
        <TopicRoutes />
      </Route>

      <Route path="/search">
        <SearchRoutes />
      </Route>

      <Route path={['/faq', '/faqs']}>
        <FAQRoutes />
      </Route>

      <Route path={'/resources'}>
        <ResourcesPage />
      </Route>

      <Route path={['/terms-and-conditions', '/privacy-policy', '/cookie-notice']}>
        <TocRoutes />
      </Route>

      <PrivateRoute path="/bookmarks/:bookmarkId?" canAccess={accessToBookmarks}>
        <BookmarksPage />
      </PrivateRoute>

      <PrivateRoute path="/followed" canAccess={accessToAuthenticatedContent}>
        <FollowedTopicsPage />
      </PrivateRoute>

      <PrivateRoute path="/shared" canAccess={accessToAuthenticatedContent}>
        <SharedTopicsPage />
      </PrivateRoute>

      <Route path="/membership-events">
        <MembershipEventsRoutes />
      </Route>

      <Route path="/app-redirect">
        <AppRedirectRoute />
      </Route>

      <Route
        path={[
          '/login',
          '/register',
          '/reset-password-login',
          '/logout',
          '/profile',
          '/notifications/:id',
          '/notifications',
          '/map-invite-link/:token',
        ]}
      >
        <UserRoutes />
      </Route>

      <Route path="/news">
        <WhatsNewRoute />
      </Route>

      <Route path="*">
        <PageNotFound />
      </Route>
    </Switch>
  );
};
