import { useTranslations } from 'domains/language/useTranslations';
import { ModalHeader } from 'domains/modal/components/ModalHeader';
import { SearchFilter } from 'domains/search/components/SearchFilter';

export const SearchFilterModal = () => {
  const { textDictionary } = useTranslations();
  return (
    <>
      <ModalHeader title={textDictionary['app.filter.title']} />
      <SearchFilter />
    </>
  );
};
