import { ReactElement, useRef, useEffect, PropsWithChildren } from 'react';
import { styled } from 'styled-components';

import { BackButton } from 'common/components';
import { ListLayout } from 'common/components/layouts/MasterDetailListLayout';
import { breakpoints, mediaStyles } from 'common/styles';
import { useInternalLink } from 'common/utilities';
import { useMedia } from 'domains/viewport/useMedia';

const StyledContainer = styled.div`
  display: flex;
  height: 100%;
`;

const StyledListContainer = styled.div`
  display: inline-block;
  height: 100%;
  width: 100%;

  @media ${breakpoints.large} {
    width: 500px;
    min-width: 500px;
  }
`;

const StyledDetailContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 0;
  overflow-y: auto;
  display: inline-block;
`;

const StyledInnerContainer = styled.div`
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  box-sizing: border-box;

  ${mediaStyles(({ spacing }) => `padding: ${spacing}rem 0`)};

  @media ${breakpoints.large} {
    padding-top: 0;
  }
`;

const StyledDetailContent = styled.div`
  height: 100%;
  box-sizing: border-box;
  margin-top: 1rem;

  ${mediaStyles(
    ({ spacing }) => `
      padding: ${spacing}rem 0;
      margin-top: 0rem;
    `,
  )};
`;

const StyledHeader = styled.div`
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};

  @media ${breakpoints.large} {
    display: none;
  }

  ${mediaStyles(({ spacing }) => `margin: 0 ${spacing}rem`)};
`;

const StyledPadding = styled.div`
  ${mediaStyles(({ spacing }) => `padding-bottom: ${spacing}rem`)};
`;

type NavigationPath = { label: string; path: string };

type MasterDetailLayoutProps = PropsWithChildren & {
  detailBackNavigation: NavigationPath;
  headerElement?: ReactElement;
  listBackNavigation: NavigationPath;
  listComponent: ReactElement;
  listHeaderRightElement?: ReactElement;
  listTitle: string;
  mainView?: 'left' | 'right';
};

export const MasterDetailLayout = ({
  children,
  detailBackNavigation,
  headerElement,
  listBackNavigation,
  listComponent,
  listHeaderRightElement,
  listTitle,
  mainView = 'right',
}: MasterDetailLayoutProps) => {
  const isMobile = useMedia({ small: true, medium: true }, false);

  const listLink = useInternalLink(listBackNavigation.path);

  const detailLink = useInternalLink(detailBackNavigation.path);

  const leftPartRef = useRef<HTMLDivElement>(null);
  const rightPartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const currentHidden = mainView === 'right' ? leftPartRef : rightPartRef;
    if (!currentHidden.current) return;
    for (const video of currentHidden.current.getElementsByTagName('video') as any) {
      video.pause();
    }
  }, [mainView]);

  const header = (
    <StyledHeader>
      <BackButton path={detailLink} label={detailBackNavigation.label} />
    </StyledHeader>
  );

  return (
    <StyledContainer>
      {(!isMobile || mainView === 'left') && (
        <StyledListContainer ref={leftPartRef}>
          <ListLayout
            title={listTitle}
            backLabel={listBackNavigation.label}
            backLink={listLink}
            rightHeaderElement={listHeaderRightElement}
          >
            {headerElement}
            {listComponent}
          </ListLayout>
        </StyledListContainer>
      )}
      {(!isMobile || mainView === 'right') && (
        <StyledDetailContainer ref={rightPartRef}>
          <StyledInnerContainer>
            {header}
            <StyledDetailContent>
              <StyledPadding>{children}</StyledPadding>
            </StyledDetailContent>
          </StyledInnerContainer>
        </StyledDetailContainer>
      )}
    </StyledContainer>
  );
};
