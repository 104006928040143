import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { fetchStakeholdersForTopicFailure, fetchStakeholdersForTopicSuccess } from 'entities/stakeholders/actions';
import { StakeholdersFetchForTopicAction } from 'entities/stakeholders/actionTypes';
import { Stakeholder } from 'entities/stakeholders/types';
import { isFetchError } from 'TypeCheckMethods';

export function* fetchStakeholdersForTopicSaga(action: StakeholdersFetchForTopicAction) {
  const { id, offset, limit, searchTerm, type, role } = action.payload;
  try {
    const payload: Stakeholder[] = yield apiFetch(`topic/${id}/stakeholder`, {
      queryParams: {
        offset,
        limit,
        full_name: searchTerm,
        type,
        role,
        approved: 'true',
      },
    });

    yield put(
      fetchStakeholdersForTopicSuccess(
        {
          topicId: action.payload.id,
          data: payload,
        },
        action.payload,
      ),
    );
  } catch (error) {
    if (isFetchError(error)) {
      const errorMeta = {
        searchCriteria: action.payload,
        logoutOnAuthError: true,
      };
      yield put(fetchStakeholdersForTopicFailure({ topicId: id, error }, errorMeta));
    }
  }
}
