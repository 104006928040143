import { Fragment, ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { getSiteLanguage, getAllTermsById } from 'domains/language/selectors';

type TranslationItem = { key: string; value: string };

export type Transforms = Record<string, (value: string) => ReactNode>;

export const interpolate = (translation: string, transforms: Transforms): ReactNode => {
  if (!translation) {
    return null;
  }

  const matches = translation.match(/\$\{(.*?):(.*?)\}/g);
  let values: TranslationItem[] = [];
  if (matches) {
    values = matches.reduce((prev: TranslationItem[], current: string) => {
      const match = current.match(/(.*)\${(.*):\s(.*)}(.*)/);
      if (!match) {
        return prev;
      }
      return [...prev, { key: match[2], value: match[3] }];
    }, []);
  }

  const splits = translation.split(/\${.*?}/);

  return splits.map((value, index) => {
    const match = values[index - 1];
    return (
      <Fragment key={index}>
        {index !== 0 && match && transforms[match.key] ? transforms[match.key](match.value) : null}
        {value}
      </Fragment>
    );
  });
};

const isLanguageRtl = (currentLanguageCode: string) => {
  const rtlLanguageId = ['ar'];
  return !!rtlLanguageId.find((langCode) => currentLanguageCode === langCode);
};

export const useTranslations = () => {
  const language = useSelector(getSiteLanguage);
  const textDictionary = useSelector(getAllTermsById);
  const selectedLanguageId = useSelector(getSiteLanguage);
  const isCurrentLanguageRtl = isLanguageRtl(selectedLanguageId);

  return { interpolate, language, textDictionary, isCurrentLanguageRtl };
};
