import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import {
  fetchClusterByIdSuccess,
  fetchClusterByIdFailure,
  fetchClusterDigDeeperItemsSuccess,
  fetchClusterDigDeeperItemsFailure,
} from 'domains/clusters/actions';
import { ClusterDigDeeperDefaultAction, ClusterItemDefaultAction } from 'domains/clusters/actionTypes';
import { Cluster } from 'domains/clusters/types';
import { Knowledge } from 'entities/knowledge/types';

export const DIG_DEEPER_LIMIT = 20;

export function* clusterItemSaga({ meta }: ClusterItemDefaultAction) {
  try {
    const payload: Cluster = yield apiFetch(`monitor/cluster/${meta.id}`);
    yield put(fetchClusterByIdSuccess(meta, payload));
  } catch (e) {
    yield put(fetchClusterByIdFailure(meta));
  }
}

export function* clusterKnowledgeSaga({ meta }: ClusterDigDeeperDefaultAction) {
  try {
    const { id, offset = 0 } = meta;
    const payload: Knowledge[] = yield apiFetch(`monitor/cluster/${id}/knowledge`, {
      queryParams: {
        limit: DIG_DEEPER_LIMIT,
        offset: offset * DIG_DEEPER_LIMIT,
      },
    });
    yield put(fetchClusterDigDeeperItemsSuccess(meta, payload));
  } catch (e) {
    yield put(fetchClusterDigDeeperItemsFailure(meta));
  }
}
