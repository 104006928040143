import debounce from 'lodash.debounce';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { ErrorMessage, ListItemBox, UIButton, SectionTitleUnderlined, Spinner, SelectInput } from 'common/components';
import { SelectInputOption } from 'common/components/form/SelectInput';
import { buildFingerprint, searchConcept } from 'domains/customKeyIssue/actions';
import { StyledSubtitle } from 'domains/customKeyIssue/components/shared';
import {
  getCustomKeyIssueConceptIsPending,
  getCustomKeyIssueConceptList,
  getCustomKeyIssueFingerprint,
  getCustomKeyIssueFingerprintHasErrored,
  getCustomKeyIssueFingerprintIsPending,
  getCustomKeyIssueFPKnowledge,
  getCustomKeyIssueFPKnowledgeHasErrored,
  getCustomKeyIssueFPKnowledgeIsPending,
  getCustomKeyIssueTagSuggestions,
} from 'domains/customKeyIssue/selectors';
import { useTranslations } from 'domains/language/useTranslations';
import { ExpandableKnowledgeItem } from 'domains/publication/components/ExpandableKnowledgeItem';
import { LoadMoreButton } from 'domains/topicDetail/components/LoadMoreButton';

const StyledSectionTitleUnderlined = styled(SectionTitleUnderlined)`
  margin-top: 2rem;
`;

const StyledSelectInput = styled(SelectInput)`
  margin-bottom: 1rem;
`;

const CenteringContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

const StyledSpinner = styled(Spinner)`
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.colors.fillPrimary};
`;

const StyledLoadingText = styled.p`
  color: ${({ theme }) => theme.colors.fillPrimary};
`;

type FingerprintBuilderProps = {
  dataTestId?: string;
  disabled?: boolean;
  name: string;
  selectedTags: SelectInputOption[];
  setSelectedTags: (tags: SelectInputOption[]) => void;
};

const FP_KNOWLEDGE_LIMIT = 7;

export const FingerprintBuilder = ({ name, selectedTags, setSelectedTags, disabled }: FingerprintBuilderProps) => {
  const dispatch = useDispatch();
  const { textDictionary } = useTranslations();
  const [showingAllResults, setShowingAllResults] = useState(false);
  const concepts = useSelector(getCustomKeyIssueConceptList);
  const isConceptPending = useSelector(getCustomKeyIssueConceptIsPending);
  const isFetchingFingerprint = useSelector(getCustomKeyIssueFingerprintIsPending);
  const hasFingerprintErrored = useSelector(getCustomKeyIssueFingerprintHasErrored);
  const isFetchingFPKnowledge = useSelector(getCustomKeyIssueFPKnowledgeIsPending);
  const storedTagSuggestions = useSelector(getCustomKeyIssueTagSuggestions);
  const tagSuggestions = storedTagSuggestions
    .filter(({ id }) => !selectedTags.some(({ value }) => value === id))
    .slice(0, 5);
  const fingerprint = useSelector(getCustomKeyIssueFingerprint);
  const fpKnowledge = useSelector(getCustomKeyIssueFPKnowledge);
  const hasFPKnowledgeErrored = useSelector(getCustomKeyIssueFPKnowledgeHasErrored);
  const isPending = isFetchingFingerprint || isFetchingFPKnowledge;
  const hasKnowledgeFetchFailed = hasFingerprintErrored || hasFPKnowledgeErrored;

  const onAddTagSuggestion = (item: SelectInputOption) => {
    const selectedTagsWithoutItem = selectedTags.filter(({ value }) => value !== item.value);
    setSelectedTags([...selectedTagsWithoutItem, item]);
  };

  const onConceptSelected = (value: SelectInputOption) => {
    setSelectedTags([...selectedTags, value]);
  };

  const onConceptRemoved = (removedItem: SelectInputOption) => {
    setSelectedTags(selectedTags.filter((item) => item.value !== removedItem.value));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchConceptHandle = useCallback(
    debounce((query: string) => {
      if (!query) {
        return;
      }
      dispatch(searchConcept({ query }));
    }, 500),
    [dispatch],
  );

  const triggerBuildFingerprint = useCallback(() => {
    const mappings = selectedTags.map((tag) => tag.value);
    dispatch(buildFingerprint({ mappings, name }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedTags]);

  useEffect(() => {
    triggerBuildFingerprint();
  }, [triggerBuildFingerprint]);

  let text = '';
  if (isFetchingFingerprint) {
    text = textDictionary['app.custom_key_issue.fingerprint.loading'];
  } else if (isFetchingFPKnowledge) {
    text = textDictionary['app.custom_key_issue.knowledge_preview.loading'];
  }

  return (
    <>
      <StyledSectionTitleUnderlined>{textDictionary['app.custom_key_issue.tags.title']}</StyledSectionTitleUnderlined>
      <StyledSubtitle>{textDictionary['app.custom_key_issue.search_tags.help_text']}</StyledSubtitle>
      <StyledSelectInput
        label="Search for tags..."
        placeholder={textDictionary['app.custom_key_issue.search_tags.explainer']}
        defaultItems={selectedTags}
        dropdownOptions={concepts.map((concept) => ({
          label: concept.name,
          value: concept.id,
          subtitle: concept.subtitle,
          description: concept.description,
        }))}
        suggestionOptions={tagSuggestions.map(({ id, name }) => ({
          label: name,
          value: id,
        }))}
        onAddSuggestion={onAddTagSuggestion}
        isPending={isConceptPending}
        onItemSelect={onConceptSelected}
        onInputChange={searchConceptHandle}
        onItemRemove={onConceptRemoved}
        disabled={disabled}
        dataTestId={'add-custom-key-issue-fingerprint-input'}
      />
      {selectedTags.length > 0 && (isFetchingFingerprint || !!fingerprint.length || hasKnowledgeFetchFailed) && (
        <>
          <StyledSectionTitleUnderlined>{textDictionary['app.monitor.knowledge']}</StyledSectionTitleUnderlined>
          {text && <StyledLoadingText>{text}</StyledLoadingText>}
          <ErrorMessage isWarning={!hasFingerprintErrored} isActive={hasKnowledgeFetchFailed}>
            {textDictionary['app.auth.error']}
          </ErrorMessage>
          {hasKnowledgeFetchFailed && (
            <UIButton onClick={() => triggerBuildFingerprint()} style={{ marginBottom: '1rem' }} variant={'filled'}>
              {textDictionary['app.login.try_again']}
            </UIButton>
          )}
          {isPending && (
            <CenteringContainer>
              <StyledSpinner />
            </CenteringContainer>
          )}
          {!isFetchingFPKnowledge && !!fpKnowledge.length && (
            <>
              {(showingAllResults ? fpKnowledge : fpKnowledge.slice(0, FP_KNOWLEDGE_LIMIT)).map((knowledge) => (
                <ListItemBox key={knowledge.id}>
                  <ExpandableKnowledgeItem knowledge={knowledge} link={knowledge.link} />
                </ListItemBox>
              ))}
              {fpKnowledge.length > FP_KNOWLEDGE_LIMIT && (
                <LoadMoreButton
                  showSeeMore={!showingAllResults}
                  onClick={() => setShowingAllResults(!showingAllResults)}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
