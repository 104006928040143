import { Cluster } from 'domains/clusters/types';
import { Knowledge } from 'entities/knowledge/types';

export enum ClusterActionType {
  FETCH = '[Clusters] - Fetch Item',
  FETCH_FULFILLED = '[Clusters] - Fetch Item - Fulfilled',
  FETCH_REJECTED = '[Clusters] - Fetch Item - Rejected',
  FETCH_DIG_DEEPER = '[Clusters] - Fetch Dig Deeper',
  FETCH_DIG_DEEPER_FULFILLED = '[Clusters] - Fetch Dig Deeper - Fulfilled',
  FETCH_DIG_DEEPER_REJECTED = '[Clusters] - Fetch Dig Deeper - Rejected',
}

type ClusterItemMeta = { id: string; offset?: number };
export type ClusterItemDefaultAction = { meta: ClusterItemMeta; type: ClusterActionType.FETCH };

export type ClusterItemFulfilledAction = {
  meta: ClusterItemMeta;
  payload: Cluster;
  type: ClusterActionType.FETCH_FULFILLED;
};

export type ClusterItemRejectedAction = { meta: ClusterItemMeta; type: ClusterActionType.FETCH_REJECTED };
export type ClusterItemAction = ClusterItemDefaultAction | ClusterItemFulfilledAction | ClusterItemRejectedAction;
export type ClusterDigDeeperDefaultAction = { meta: ClusterItemMeta; type: ClusterActionType.FETCH_DIG_DEEPER };

export type ClusterDigDeeperFulfilledAction = {
  meta: ClusterItemMeta;
  payload: Knowledge[];
  type: ClusterActionType.FETCH_DIG_DEEPER_FULFILLED;
};

export type ClusterDigDeeperRejectedAction = {
  meta: ClusterItemMeta;
  type: ClusterActionType.FETCH_DIG_DEEPER_REJECTED;
};

export type ClusterDigDeeperAction =
  | ClusterDigDeeperDefaultAction
  | ClusterDigDeeperFulfilledAction
  | ClusterDigDeeperRejectedAction;
