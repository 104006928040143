import { FetchBookmarksMeta, ToggleBookmarkMeta } from 'domains/bookmarks/types';
import { Cluster } from 'domains/clusters/types';
import { Knowledge } from 'entities/knowledge/types';

export enum BookmarkActionTypes {
  BOOKMARKS_FETCH = '[Bookmarks] BOOKMARKS_FETCH',
  BOOKMARKS_FETCH_FULFILLED = '[Bookmarks] BOOKMARKS_FETCH_FULFILLED',
  BOOKMARKS_FETCH_REJECTED = '[Bookmarks] BOOKMARKS_FETCH_REJECTED',
  BOOKMARK_TOGGLE = '[Bookmarks] BOOKMARK_TOGGLE',
  BOOKMARK_TOGGLE_FULFILLED = '[Bookmarks] BOOKMARK_TOGGLE_FULFILLED',
  BOOKMARK_TOGGLE_REJECTED = '[Bookmarks] BOOKMARK_TOGGLE_REJECTED',
}

export type BookmarkPayload = { bookmarks: (Cluster | Knowledge)[]; total: number };

export type BookmarksFetchAction = { meta: FetchBookmarksMeta; type: BookmarkActionTypes.BOOKMARKS_FETCH };

export type BookmarksFetchFulfilledAction = {
  meta: FetchBookmarksMeta;
  payload: BookmarkPayload;
  type: BookmarkActionTypes.BOOKMARKS_FETCH_FULFILLED;
};

export type BookmarksFetchRejectedAction = {
  meta: FetchBookmarksMeta;
  type: BookmarkActionTypes.BOOKMARKS_FETCH_REJECTED;
};

export type ToggleBookmarkAction = { meta: ToggleBookmarkMeta; type: BookmarkActionTypes.BOOKMARK_TOGGLE };

export type ToggleBookmarkFulfilledAction = {
  meta: ToggleBookmarkMeta;
  type: BookmarkActionTypes.BOOKMARK_TOGGLE_FULFILLED;
};

export type ToggleBookmarkRejectedAction = {
  meta: ToggleBookmarkMeta;
  type: BookmarkActionTypes.BOOKMARK_TOGGLE_REJECTED;
};

export type BookmarksAction =
  | BookmarksFetchAction
  | BookmarksFetchFulfilledAction
  | BookmarksFetchRejectedAction
  | ToggleBookmarkAction
  | ToggleBookmarkFulfilledAction
  | ToggleBookmarkRejectedAction;
