import { SVGProps } from 'react';

export const CrossCircledIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill={'none'} {...props}>
    <path transform="translate(1 .778)" d="M0 0h18v18H0z" />
    <path transform="translate(1 .778)" d="M0 0h18v18H0z" />
    <g transform="translate(1.461 1.017)">
      <rect
        width="18"
        height="18"
        rx="9"
        transform="translate(-.461 -.017)"
        stroke={'currentColor'}
        strokeWidth="2px"
      />
    </g>
    <path
      d="M8 13h5m5 0h-5m0 0V8m0 5v5"
      transform="rotate(-45 7.879 15.122)"
      strokeLinecap={'round'}
      strokeLinejoin={'round'}
      stroke={'currentColor'}
      strokeWidth="2px"
    />
  </svg>
);
