import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { popModalScreen } from 'domains/modal/actions';
import { removeNotificationFailure, removeNotificationSuccess } from 'domains/notifications/actions';
import { RemoveNotificationDefaultAction } from 'domains/notifications/actionTypes';
import { isFetchError } from 'TypeCheckMethods';

export function* removeNotificationSaga(action: RemoveNotificationDefaultAction) {
  try {
    yield apiFetch(`notification/${action.meta.id}`, { method: 'DELETE' });
    yield put(removeNotificationSuccess(action.meta.id));
    yield put(popModalScreen());
  } catch (error) {
    if (isFetchError(error)) {
      yield put(removeNotificationFailure({ error }, { id: action.meta.id, logoutOnAuthError: true }));
    }
  }
}
