import { isSameSearchCriteria } from 'domains/knowledgeFeed/reducer';
import { KnowledgeFeedForTopicParameters } from 'domains/knowledgeFeed/types';
import { getAdvancedKnowledgeCriteria } from 'entities/knowledge/selectors';
import { RootState } from 'types';

const getKnowledgeFeedStateForTopic = (state: RootState, topicId: string) => {
  const searchCriteria = {
    ...getLatestSearchCriteria(state),
    ...getAdvancedKnowledgeCriteria(state),
    id: topicId,
  };
  const matchingKnowledgeFeedPackage = state.domains.knowledgeFeed.forTopic[topicId]?.find((knowledgeFeedPackage) =>
    isSameSearchCriteria(knowledgeFeedPackage.searchCriteria, searchCriteria),
  );
  return matchingKnowledgeFeedPackage;
};

export const getKnowledgeFeedForTopic =
  ({ topicId }: KnowledgeFeedForTopicParameters) =>
  (state: RootState) =>
    (getKnowledgeFeedStateForTopic(state, topicId)?.data || []).map((id) => state.entities.knowledge.byId[id]);

export const getKnowledgeFeedForTopicIsPending = (topicId: string) => (state: RootState) =>
  getKnowledgeFeedStateForTopic(state, topicId)?.isFetching || false;

export const getKnowledgeFeedForTopicHasNextPage = (topicId: string) => (state: RootState) =>
  getKnowledgeFeedStateForTopic(state, topicId)?.hasNextPage || false;

export const getLatestSearchCriteria = (state: RootState) => state.domains.knowledgeFeed.searchCriteria;

export const getLatestFilterCount = (state: RootState) => {
  const { contentType, searchTerm, language, publicationType, year, role } = state.domains.knowledgeFeed.searchCriteria;
  const count = [language, publicationType, searchTerm, contentType, year, role].filter((item) => !!item).length;
  return count;
};
