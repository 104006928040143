import { useEffect, useCallback, useRef, useState } from 'react';

type ImageLoaderProps = { src?: string };

export const useImageLoader = ({ src }: ImageLoaderProps) => {
  const [internalSrc, setInternalSrc] = useState('');
  const [useFallback, setUseFallback] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const displayImage = useRef<HTMLImageElement | null>(null);

  const unsetDisplayImage = useCallback(() => {
    if (displayImage.current) {
      displayImage.current.onload = null;
      displayImage.current.onerror = null;
    }
    displayImage.current = null;
  }, []);

  useEffect(() => {
    setIsImageLoaded(!src);
    const setFallbackTimer = setTimeout(() => {
      setUseFallback(true);
    }, 500);
    if (src) {
      unsetDisplayImage();
      displayImage.current = new window.Image();
      displayImage.current.src = src;
      displayImage.current.onload = () => {
        clearTimeout(setFallbackTimer);
        setInternalSrc(src);
        setIsImageLoaded(true);
        setUseFallback(false);
        unsetDisplayImage();
      };
      displayImage.current.onerror = () => {
        clearTimeout(setFallbackTimer);
        setUseFallback(true);
        setIsImageLoaded(true);
        unsetDisplayImage();
      };
    }

    return () => {
      clearTimeout(setFallbackTimer);
      unsetDisplayImage();
    };
  }, [src, unsetDisplayImage]);

  return { internalSrc, useFallback, isImageLoaded };
};
