import { styled } from 'styled-components';

import { StyledLandingText } from 'domains/landing/components/StyledLandingText';
import { StyledLandingTitle } from 'domains/landing/components/StyledLandingTitle';
import { useTranslations } from 'domains/language/useTranslations';

const StyledText = styled(StyledLandingText)`
  color: ${({ theme }) => theme.colors.textFade};
  margin-bottom: 5rem;
`;

type CreateLandingTitleProps = { mode?: 'collection' | 'map' };

export const CreateLandingTitle = ({ mode = 'map' }: CreateLandingTitleProps) => {
  const { textDictionary } = useTranslations();
  const modifier = mode === 'collection' ? '.collection' : '';
  return (
    <>
      <StyledLandingTitle>{textDictionary[`app.create${modifier}.landing_banner.title`]}</StyledLandingTitle>
      <StyledText>{textDictionary[`app.create${modifier}.landing_banner.text`]}</StyledText>
    </>
  );
};
