import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

import { WEFLogoIcon } from 'common/components';
import { useRouterLink } from 'domains/routing/utils';

const StyledTitleLink = styled(Link)`
  display: flex;
  text-decoration: none;
`;

type AppLogoProps = { onClick?: () => void };

const StyledWEFLogoIcon = styled(WEFLogoIcon)`
  width: 84px;
  color: ${({ theme }) => theme.colors.wefLogoText};
`;

export const AppLogo = ({ onClick }: AppLogoProps) => {
  const link = useRouterLink({ to: 'landing' });

  return (
    <StyledTitleLink to={link} onClick={onClick}>
      <StyledWEFLogoIcon />
    </StyledTitleLink>
  );
};
