import { Link } from 'react-router-dom';
import { css, styled } from 'styled-components';

import { ChevronIcon, Spinner } from 'common/components';
import { breakpoints } from 'common/styles';
import { TopHeaderItemBadge } from 'domains/navigation/components/TopHeaderItemBadge';

export const StyledLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: inherit;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.div<{ $isIndented?: boolean }>`
  ${({ $isIndented }) =>
    $isIndented &&
    css`
      padding-left: 2rem;
    `} @media ${breakpoints.large} {
    ${({ $isIndented }) =>
      $isIndented &&
      css`
        padding-left: 1rem;
      `}
  }
`;

export const ChevronDownIcon = styled(ChevronIcon)`
  width: 16px;
  height: 16px;
  color: ${({ theme }) => theme.colors.topHeaderBarText};
  transform: rotate(90deg);
  margin-top: 5px;

  @media ${breakpoints.large} {
    margin-top: 0;
    margin-left: 10px;
    width: 10px;
    height: 10px;
  }
`;

export const StyledChevronIcon = styled(ChevronIcon)`
  width: 10px;
  height: 10px;
  color: ${({ theme }) => theme.colors.topHeaderBarText};
  margin-left: 10px;
`;

export const StyledSpinner = styled(Spinner)`
  width: 10px;
  height: 10px;
  color: ${({ theme }) => theme.colors.topHeaderBarText};
  margin-left: 10px;
`;

export const Badge = styled(TopHeaderItemBadge)`
  margin-left: 10px;
  width: auto;
  padding: 0 8px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;
