import { useState, useEffect, PropsWithChildren } from 'react';
import { isMobile, isIOS } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { CrossIcon, MobileAppIcon, UIButton } from 'common/components';
import { history } from 'common/utilities';
import { useTranslations } from 'domains/language/useTranslations';
import { getCanDisplayAppBanner } from 'entities/user/selectors';

const { localStorage } = window;
const HEIGHT = 60;

type StyledProps = { isOpen: boolean };

const StyledContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledPage = styled.div<StyledProps>`
  width: 100%;
  height: ${({ isOpen }) => (isOpen ? `calc(100% - ${HEIGHT}px)` : '100%')};
`;

const StyledBannerContainer = styled.div<StyledProps>`
  transition: height 0.3s;
  height: ${({ isOpen }) => (isOpen ? HEIGHT : 0)}px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  box-sizing: border-box;
  border-bottom: 1px solid ${({ theme }) => theme.colors.outline};
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  color: ${({ theme }) => theme.colors.fillPrimary};
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledCloseIcon = styled(CrossIcon)`
  cursor: pointer;
  padding: 10px;
  height: 16px;
  width: 16px;
  color: ${({ theme }) => theme.colors.fillPrimary};
`;

const StyledTitle = styled.p`
  font-size: 0.9rem;
  font-weight: 600;
  margin: 0;
`;

const StyledCaption = styled.p`
  font-size: 0.9rem;
  margin: 0;
`;

const StyledWEFLogoIcon = styled(MobileAppIcon)`
  width: 40px;
  height: 40px;
  border-radius: 5px;
  margin: 0 15px 0 5px;
`;

const iosHideOnPaths = ['/app', '/topics', '/monitor'];

const APP_BANNER_USER_CLOSED = 'APP_BANNER_USER_CLOSED';

export const MobileAppBanner = ({ children }: PropsWithChildren) => {
  const { textDictionary } = useTranslations();
  const canDisplayAppBanner = useSelector(getCanDisplayAppBanner);
  const [isOpen, setIsOpen] = useState(isMobile);
  const [showOnPath, setShowOnPath] = useState(true);

  const onCloseHandle = () => {
    setIsOpen(false);
    localStorage.setItem(APP_BANNER_USER_CLOSED, 'true');
  };

  const onOpenHandle = () => {
    setIsOpen(false);
    window.location.href = `${isIOS ? process.env.REACT_APP_IOS_DEEPLINK_URL : process.env.REACT_APP_WEBSITE_URL}/app`;
  };

  const currentPath = history && history.location ? history.location.pathname : '';

  useEffect(() => {
    if (!isIOS) {
      return;
    }
    setShowOnPath(currentPath === '/' || !iosHideOnPaths.some((path) => currentPath.includes(path)));
  }, [currentPath]);

  const savedValue = localStorage.getItem(APP_BANNER_USER_CLOSED);

  const isDisplaying = isOpen && !savedValue && showOnPath && canDisplayAppBanner;
  return (
    <StyledContainer>
      <StyledBannerContainer isOpen={isDisplaying}>
        {isDisplaying && (
          <>
            <StyledRow>
              <StyledCloseIcon onClick={onCloseHandle} />
              <StyledWEFLogoIcon />
              <div>
                <StyledTitle>Strategic IQ</StyledTitle>
                <StyledCaption>{textDictionary['app.journey_banner.message']}</StyledCaption>
              </div>
            </StyledRow>
            <UIButton onClick={onOpenHandle}>{textDictionary['app.journey_banner.open']}</UIButton>
          </>
        )}
      </StyledBannerContainer>
      <StyledPage isOpen={isDisplaying}>{children}</StyledPage>
    </StyledContainer>
  );
};
