import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { enableGenerateButton, triggerGenerateBriefingSuccess } from 'domains/briefing/actions';
import { TriggerGenerateBriefing } from 'domains/briefing/actionTypes';

type TriggerGenerateBriefingResponse = { request: string };

const TEMPLATE_NAME = 'live_briefing_v2';

export function* triggerGenerateBriefingSaga(action: TriggerGenerateBriefing) {
  try {
    const response: TriggerGenerateBriefingResponse = yield apiFetch(
      `advancedbriefing/generate/${TEMPLATE_NAME}/${action.payload.topicId}?async=true&reports=${action.payload.options.furtherReading}&foresight=${action.payload.options.foresight}`,
      {
        extraHeaders: {
          'cache-control': 'no-cache, no-store, must-revalidate',
          expires: '0',
        },
      },
    );
    yield put(
      triggerGenerateBriefingSuccess({
        briefingId: response.request,
        topicId: action.payload.topicId,
        topicName: action.payload.topicName,
        templateName: TEMPLATE_NAME,
        status: 'Pending',
        progress: 0,
        content: action.payload.options,
      }),
    );
    yield put(enableGenerateButton());
  } catch (error) {
    console.error(error);
  }
}
