import { SVGProps } from 'react';

export const SpreadsheetFileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
    <g id="Group_95325" data-name="Group 95325" transform="translate(12138 18488)">
      <g id="Rectangle_41724" data-name="Rectangle 41724" transform="translate(-12138 -18488)" fill="#2a63e9">
        <rect width="64" height="64" stroke="none" />
      </g>
      <g id="Group_95322" data-name="Group 95322" transform="translate(-12126.943 -18477.258)">
        <path
          id="Path_245113"
          data-name="Path 245113"
          d="M177.873,27.283,165.8,15.213A4.1,4.1,0,0,0,162.937,14h-21.66a4.141,4.141,0,0,0-4.079,4.079v34.36a4.069,4.069,0,0,0,4.079,4.079h33.731a4.069,4.069,0,0,0,4.079-4.079V30.149a4.1,4.1,0,0,0-1.213-2.866Zm-2.315.992H166.63a1.835,1.835,0,0,1-1.819-1.819V17.582Zm-.551,25.873H141.277a1.717,1.717,0,0,1-1.708-1.708V18.078a1.694,1.694,0,0,1,1.708-1.708h21.164V26.456a4.187,4.187,0,0,0,4.189,4.189h10.086V52.439a1.717,1.717,0,0,1-1.708,1.708Z"
          transform="translate(-137.198 -14)"
          fill="#fff"
        />
        <path
          id="Path_246199"
          data-name="Path 246199"
          d="M252.779,431.2H228.7a1.219,1.219,0,1,0,0,2.425h24.136a1.3,1.3,0,0,0,1.344-1.213,1.4,1.4,0,0,0-1.405-1.213Z"
          transform="translate(-222.974 -395.393)"
          fill="#fff"
        />
        <path
          id="Path_246200"
          data-name="Path 246200"
          d="M252.779,340.48H228.7a1.219,1.219,0,1,0,0,2.425h24.136a1.3,1.3,0,0,0,1.344-1.213,1.354,1.354,0,0,0-1.405-1.213Z"
          transform="translate(-222.974 -309.665)"
          fill="#fff"
        />
        <path
          id="Path_246201"
          data-name="Path 246201"
          d="M252.779,249.76H228.7a1.219,1.219,0,1,0,0,2.425h24.136a1.3,1.3,0,0,0,1.344-1.213,1.354,1.354,0,0,0-1.405-1.213Z"
          transform="translate(-222.974 -223.936)"
          fill="#fff"
        />
        <path
          id="Path_246202"
          data-name="Path 246202"
          d="M10.625,0H.947A1.115,1.115,0,0,0,0,1.213,1.115,1.115,0,0,0,.947,2.425h9.721a1.115,1.115,0,0,0,.947-1.213A1.146,1.146,0,0,0,10.625,0Z"
          transform="translate(6.811 26.083) rotate(90)"
          fill="#fff"
        />
        <path
          id="Path_246203"
          data-name="Path 246203"
          d="M10.625,0H.947A1.115,1.115,0,0,0,0,1.213,1.115,1.115,0,0,0,.947,2.425h9.721a1.115,1.115,0,0,0,.947-1.213A1.146,1.146,0,0,0,10.625,0Z"
          transform="translate(15.048 26.083) rotate(90)"
          fill="#fff"
        />
        <path
          id="Path_246205"
          data-name="Path 246205"
          d="M10.625,0H.947A1.115,1.115,0,0,0,0,1.213,1.115,1.115,0,0,0,.947,2.425h9.721a1.115,1.115,0,0,0,.947-1.213A1.146,1.146,0,0,0,10.625,0Z"
          transform="translate(23.284 26.083) rotate(90)"
          fill="#fff"
        />
        <path
          id="Path_246204"
          data-name="Path 246204"
          d="M10.625,0H.947A1.115,1.115,0,0,0,0,1.213,1.115,1.115,0,0,0,.947,2.425h9.721a1.115,1.115,0,0,0,.947-1.213A1.146,1.146,0,0,0,10.625,0Z"
          transform="translate(23.284 26.083) rotate(90)"
          fill="#fff"
        />
        <path
          id="Path_246206"
          data-name="Path 246206"
          d="M10.625,0H.947A1.115,1.115,0,0,0,0,1.213,1.115,1.115,0,0,0,.947,2.425h9.721a1.115,1.115,0,0,0,.947-1.213A1.146,1.146,0,0,0,10.625,0Z"
          transform="translate(31.521 26.083) rotate(90)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);
