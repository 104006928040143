import { combineReducers } from 'redux';

import { StakeholderActionTypes, StakeholderAction } from 'entities/stakeholders/actionTypes';
import { StakeholderState } from 'entities/stakeholders/types';

const initialState: StakeholderState = {
  byId: {},
};

const byId = (state = initialState.byId, action: StakeholderAction): StakeholderState['byId'] => {
  switch (action.type) {
    case StakeholderActionTypes.FETCH_FOR_TOPIC_SUCCESS: {
      return action.payload.data.reduce(
        (byId, stakeholder) => ({
          ...byId,
          [stakeholder.id]: {
            ...state[stakeholder.id],
            ...stakeholder,
          },
        }),
        state,
      );
    }
    case StakeholderActionTypes.FETCH_FOR_ID: {
      return {
        ...state,
        [action.meta.id]: {
          ...state[action.meta.id],
          isPending: true,
        },
      };
    }
    case StakeholderActionTypes.FETCH_FOR_ID_SUCCESS: {
      const { roles: _roles, ...stakeholder } = action.payload;
      return {
        ...state,
        [action.meta.id]: {
          ...state[action.meta.id],
          ...stakeholder,
          isPending: false,
          error: false,
        },
      };
    }
    case StakeholderActionTypes.FETCH_FOR_ID_FAILURE: {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isPending: false,
          error: true,
        },
      };
    }
    default:
      return state;
  }
};

export const stakeholdersReducer = combineReducers({
  byId,
});
