import { ReactNode, useState } from 'react';
import { styled } from 'styled-components';

import { Skeleton } from 'common/components/Skeleton';

const StyledContainer = styled.div`
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  gap: 1rem;
  width: 100%;
`;

const StyledImage = styled.img<{ isLoaded: boolean }>`
  display: ${({ isLoaded }) => (isLoaded ? 'block' : 'none')};
  flex-shrink: 0;
  height: 2.5rem;
  object-fit: cover;
  object-position: center;
  width: 2.5rem;
`;

const StyledSkeleton = styled(Skeleton)`
  flex-shrink: 0;
  height: 2.5rem;
  width: 2.5rem;
`;

const StyledName = styled.div`
  flex-grow: 1;
  overflow-x: hidden;
`;

const StyledIcon = styled.div`
  border-radius: 0.25rem;
  height: 1.25rem;
  min-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0.625rem;
  width: 1.25rem;

  &:focus-visible,
  &:hover {
    background-color: #ffffff30;
    cursor: pointer;
  }
`;

type SearchDropdownCardProps = { icon: ReactNode; image?: string; name: string; onClick: () => void };

export const SearchDropdownCard = ({ icon, image, name, onClick }: SearchDropdownCardProps) => {
  const [isImgLoaded, setIsImgLoaded] = useState<boolean>(false);

  return (
    <StyledContainer>
      <StyledImage alt={name} isLoaded={isImgLoaded} onLoad={() => setIsImgLoaded(true)} src={image} />
      {!isImgLoaded && <StyledSkeleton />}
      <StyledName>{name}</StyledName>
      <StyledIcon onClick={onClick}>{icon}</StyledIcon>
    </StyledContainer>
  );
};
