import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

import { useTranslations } from 'domains/language/useTranslations';
import { useRouterLink } from 'domains/routing/utils';

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

export const TermsButton = () => {
  const { textDictionary } = useTranslations();
  const link = useRouterLink({ to: 'terms-and-conditions' });

  return <StyledLink to={link}>{textDictionary['app.settings.terms_of_use']}</StyledLink>;
};
