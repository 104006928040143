import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { fetchKeyIssuesFailure, fetchKeyIssuesSuccess } from 'entities/keyIssues/actions';
import { FetchKeyIssuesDefaultAction } from 'entities/keyIssues/actionTypes';
import { KeyIssue } from 'entities/keyIssues/types';
import { isFetchError } from 'TypeCheckMethods';

export function* fetchDimensionsSaga({ meta }: FetchKeyIssuesDefaultAction) {
  try {
    const { searchTerm, offset, limit = 20 } = meta;
    const dimensions: KeyIssue[] = yield apiFetch('dimension', {
      queryParams: {
        search: searchTerm,
        statuses: 'Active',
        has_parent: 'true',
        limit,
        offset,
      },
    });
    yield put(fetchKeyIssuesSuccess(dimensions, { ...meta, limit }));
  } catch (error) {
    if (isFetchError(error)) {
      yield put(fetchKeyIssuesFailure({ error }));
    }
  }
}
