import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { openMapInvitationFailure, openMapInvitationSuccess } from 'domains/createShareSettings/actions';
import { OpenMapInvitationDefaultAction } from 'domains/createShareSettings/actionTypes';
import { OpenLinkInvite } from 'domains/createShareSettings/types';
import { fetchTopicsMetadata } from 'entities/topicsMetadata/actions';
import { isFetchError } from 'TypeCheckMethods';
import { FetchError } from 'types';

export function* openLinkInvitationSaga(action: OpenMapInvitationDefaultAction) {
  try {
    const response: OpenLinkInvite = yield apiFetch(`mapshare/open_invite`, {
      queryParams: { omt: action.meta.token },
    });
    yield put(openMapInvitationSuccess(response));
    yield put(fetchTopicsMetadata());
  } catch (error) {
    if (isFetchError(error)) {
      if (error.status === 401) {
        yield put(openMapInvitationFailure({ error: error as FetchError }, { logoutOnAuthError: true }));
      }
    }
  }
}
