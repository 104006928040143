import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { Cluster } from 'domains/clusters/types';
import { fetchEmergingThemesFailure, fetchEmergingThemesSuccess } from 'domains/monitor/emergingThemes/actions';
import { EmergingThemesAction } from 'domains/monitor/emergingThemes/actionTypes';

export const EMERGING_THEMES_LIMIT = 6;

export function* emergingThemesSaga({ meta }: EmergingThemesAction) {
  try {
    const { offset, filters } = meta;
    const payload: Cluster[] = yield apiFetch('monitor/segmentation/emerging_themes', {
      queryParams: {
        limit: EMERGING_THEMES_LIMIT.toString(),
        offset: offset * EMERGING_THEMES_LIMIT,
        ...filters,
      },
    });
    yield put(fetchEmergingThemesSuccess(meta, payload));
  } catch (e) {
    yield put(fetchEmergingThemesFailure(meta));
  }
}
