import { css, styled } from 'styled-components';

import { StatisticsIcon, UIChip } from 'common/components';
import { isQuotationMark } from 'common/utilities/isQuotationMark';
import { useTranslations } from 'domains/language/useTranslations';
import { Signal } from 'domains/signals/types';

const iconStyle = css`
  width: 16px;
  height: 16px;
  color: ${({ theme }) => theme.colors.fillTertiary};
  margin-right: 0.3rem;
`;

const StyledStatisticsIcon = styled(StatisticsIcon)`
  ${iconStyle};
`;

const getIcon = (type: string) => <StyledStatisticsIcon key={type} />;

const StyledHeaderRow = styled.div`
  display: flex;
  align-items: center;
  padding-top: 4px;
  flex-direction: row;
`;

const StyledTagContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const StyledTag = styled(UIChip)`
  display: flex;
  font-size: 10px;
  align-items: center;
  justify-content: center;
  padding: 4px 4px;
  margin-top: 0;
`;

type SignalProps = { index: number; signal: Signal };

const SignalSection = ({ signal, index }: SignalProps) => {
  const { text, signal_type } = signal;

  const removeLeadingQuotationMark = (text: string) => text.substring(1, text.length);
  const removeTrailingQuotationMark = (text: string) => text.substring(0, text.length - 1);

  let signalText = text;
  const startsWithQuotationMark = isQuotationMark(signalText.charAt(0));
  if (startsWithQuotationMark) {
    signalText = removeLeadingQuotationMark(signalText);
  }

  const endsWithQuotationMark = isQuotationMark(signalText.charAt(signalText.length - 1));
  if (endsWithQuotationMark) {
    signalText = removeTrailingQuotationMark(signalText);
  }

  return (
    <StyledListItem data-test-id={`article-signal-excerpt-item-${index}`}>
      &quot;<span>{signalText}</span>&quot;
      <StyledTagContainer>
        {signal_type.map((type, index) => (
          <StyledHeaderRow key={index} data-test-id={`article-signal-excerpt-tag`}>
            <StyledTag>
              {getIcon(type)}
              {type.toUpperCase()}
            </StyledTag>
          </StyledHeaderRow>
        ))}
      </StyledTagContainer>
    </StyledListItem>
  );
};

const StyledWrapper = styled.div`
  color: ${({ theme }) => theme.colors.fillPrimary};
`;
const StyledHeader = styled.div`
  padding-bottom: 0.6rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.textFade};
  font-size: 0.9rem;
`;
const StyledList = styled.ul`
  padding-left: 1rem;
  margin-bottom: 0;
`;
const StyledListItem = styled.li`
  list-style-type: none;
  line-height: 1.1rem;
  font-size: 0.8rem;
  padding-bottom: 0.9rem;

  &:last-child {
    padding-bottom: 0.5rem;
  }
`;

type KnowledgeSignalContentProps = { signals?: Signal[] };

export const KnowledgeSignalContent = ({ signals }: KnowledgeSignalContentProps) => {
  const { textDictionary } = useTranslations();

  if (!signals?.length) {
    return null;
  }

  return (
    <StyledWrapper>
      <StyledHeader data-test-id="article-signal-excerpts-section-title">
        {textDictionary['app.article.signal_title.transformational']}
      </StyledHeader>
      <StyledList>
        {signals?.map((signal, index) => <SignalSection key={index} index={index} signal={signal} />)}
      </StyledList>
    </StyledWrapper>
  );
};
