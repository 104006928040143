import { isSameSearchCriteria } from 'domains/publication/reducer';
import { getAdvancedKnowledgeCriteria } from 'domains/publication/selectors';
import { getTransformationMapSelectedOuterNode } from 'domains/transformationMap/selectors';
import { RootState } from 'types';

const getVideoStateForTopic = (state: RootState, topicId: string) => {
  const { offset: _offset, limit: _limit, ...initialCriteria } = getVideoSearchCriteria(state);
  const searchCriteria = {
    ...initialCriteria,
    ...getAdvancedKnowledgeCriteria(state),
    id: topicId,
  };
  const matchingKnowledgePackage = state.domains.videos.forTopic[topicId]?.find((videoPackage) =>
    isSameSearchCriteria(videoPackage.searchCriteria, searchCriteria),
  );
  return matchingKnowledgePackage;
};

export const getVideosItemsForTopic = (topicId: string, limit?: number) => (state: RootState) => {
  const videos = (getVideoStateForTopic(state, topicId)?.data || []).map((id) => state.entities.knowledge.byId[id]);
  if (limit) {
    return videos.slice(0, limit);
  }
  return videos;
};

export const getVideosItemsForTopicIsPending = (topicId: string) => (state: RootState) =>
  getVideoStateForTopic(state, topicId)?.isFetching || false;

export const getVideosItemsForTopicHasNextPage = (topicId: string) => (state: RootState) =>
  getVideoStateForTopic(state, topicId)?.hasNextPage || false;

export const getVideoSearchCriteria = (state: RootState) => state.domains.videos.searchCriteria;

export const getVideoSearchTerm = (state: RootState) => state.domains.videos.searchCriteria.searchTerm;

export const getIsVideosFiltered = (state: RootState) => {
  const filter = state.domains.videos.searchCriteria;
  return !!filter.searchTerm || !!filter.publicationType || !!getTransformationMapSelectedOuterNode(state)?.id;
};

export const getVideosFilterCount = (state: RootState) => {
  const { publicationType, searchTerm } = state.domains.videos.searchCriteria;
  const count = [publicationType, searchTerm].filter((item) => !!item).length;
  return count;
};
