import { StyledModalText } from 'common/components';
import { useTranslations } from 'domains/language/useTranslations';
import { ModalHeader } from 'domains/modal/components/ModalHeader';

export const ResetCoachmarksConfirmationModal = () => {
  const { textDictionary } = useTranslations();

  return (
    <>
      <ModalHeader title={textDictionary['app.coachmarks.reset_confirmation_modal.title']} />
      <StyledModalText>{textDictionary['app.coachmarks.reset_confirmation_modal.text']}</StyledModalText>
    </>
  );
};
