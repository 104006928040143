import { combineReducers } from 'redux';

import { UploadProfileAction, UserActionType } from 'domains/user/actionTypes';
import { UserDomainState } from 'domains/user/types';

const initialState: UserDomainState = {
  isUploadingProfileImage: false,
  profileImageUploadError: null,
  // loginRegisterParams: null,
};

const isUploadingProfileImage = (state = initialState.isUploadingProfileImage, action: UploadProfileAction) => {
  switch (action.type) {
    case UserActionType.UPLOAD_PROFILE_IMAGE_PENDING:
      return true;

    case UserActionType.UPLOAD_PROFILE_IMAGE_FULFILLED:
    case UserActionType.UPLOAD_PROFILE_IMAGE_REJECTED:
      return false;

    default:
      return state;
  }
};

const profileImageUploadError = (state = initialState.isUploadingProfileImage, action: UploadProfileAction) => {
  switch (action.type) {
    case UserActionType.UPLOAD_PROFILE_IMAGE_PENDING:
    case UserActionType.UPLOAD_PROFILE_IMAGE_FULFILLED:
      return null;

    case UserActionType.UPLOAD_PROFILE_IMAGE_REJECTED:
      return action.payload.error || null;

    default:
      return state;
  }
};

// const loginRegisterParams = (
//   state = initialState.loginRegisterParams,
//   action: UpdateLoginRegisterParamsAction,
// ) => {
//   switch (action.type) {
//     case UserActionType.UPDATE_LOGIN_REGISTER_PARAMS: {
//       const { payload } = action;
//       const acceptedParamPrefixes = ["destination", "utm_", "token_name"];
//       if (!payload) {
//         return null;
//       }
//       const validParams = Object.keys(payload)
//         .filter((key) =>
//           acceptedParamPrefixes.some((paramPrefix) =>
//             key.startsWith(paramPrefix),
//           ),
//         )
//         .reduce<Record<string, string>>((params, key) => {
//           params[key] = payload[key];
//           return params;
//         }, {});
//       return Object.keys(validParams).length ? validParams : null;
//     }
//     default:
//       return state;
//   }
// };

export const userReducer = combineReducers({
  isUploadingProfileImage,
  profileImageUploadError,
  // loginRegisterParams,
});
