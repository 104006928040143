import { forwardRef } from 'react';
import { styled } from 'styled-components';

import { TextInputProps, TextInput } from 'common/components/form/TextInput';
import { CrossIcon } from 'common/components/icons/CrossIcon';
import { Spinner } from 'common/components/Spinner';

const StyledClearButton = styled.div`
  height: 100%;
  width: 100%;
  color: ${({ theme }) => theme.colors.fillPrimary};
  opacity: 0.7;
  cursor: pointer;
  transition: opacity 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 1;
  }
`;

const StyledClearButtonIcon = styled(CrossIcon)`
  width: 14px;
  height: 14px;
`;

const StyledSpinner = styled(Spinner)`
  width: 1rem;
  height: 1rem;
  color: ${({ theme }) => theme.colors.fillPrimary};
`;

type ClearInputProps = TextInputProps & { dataTestId?: string; isPending?: boolean; onClearHandle: () => void };

export const ClearInput = forwardRef<HTMLInputElement, ClearInputProps>(
  ({ isPending, onClearHandle, dataTestId, ...props }, ref) => {
    const icon = (
      <StyledClearButton>
        {isPending && <StyledSpinner />}
        {!isPending && !!props.value && <StyledClearButtonIcon onClick={onClearHandle} />}
      </StyledClearButton>
    );

    return <TextInput data-test-id={dataTestId} rightElement={icon} {...props} ref={ref} />;
  },
);

ClearInput.displayName = 'ClearInput';
