import { Topic } from 'entities/topics/types';

export enum TopicDetailType {
  INIT = '[Topic detail] - Init',
  GET_AI_GENERATED_DETAILS = '[Topic detail] - Get AI generated topic details',
  GET_AI_GENERATED_DETAILS_SUCCESS = '[Topic detail] - Get AI generated topic details success',
  GET_AI_GENERATED_DETAILS_FAILURE = '[Topic detail] - Get AI generated topic details failure',
  GET_AI_GENERATED_DETAILS_NOT_VALID_PROMPT = '[Topic detail] - Get AI generated topic details not valid prompt error',
}

export type InitTopicAction = { meta: { editMode?: boolean; id: string }; type: TopicDetailType.INIT };

export type GetAIGeneratedTopicDetailsAction = {
  payload: { prompt: string };
  type: TopicDetailType.GET_AI_GENERATED_DETAILS;
};

export type GetAIGeneratedTopicDetailsSuccessAction = {
  payload: Topic;
  type: TopicDetailType.GET_AI_GENERATED_DETAILS_SUCCESS;
};

type GetAIGeneratedTopicDetailsFailureAction = {
  payload: Topic;
  type: TopicDetailType.GET_AI_GENERATED_DETAILS_FAILURE;
};

type GetAIGeneratedTopicDetailsNotValidPromptAction = {
  errorCode: number | null;
  type: TopicDetailType.GET_AI_GENERATED_DETAILS_NOT_VALID_PROMPT;
};

export enum errorCodes {
  notValidPrompt = 422,
  notEnoughData = 400,
  timeoutExceed = 504,
}

export type TopicDetailAction =
  | InitTopicAction
  | GetAIGeneratedTopicDetailsAction
  | GetAIGeneratedTopicDetailsFailureAction
  | GetAIGeneratedTopicDetailsNotValidPromptAction
  | GetAIGeneratedTopicDetailsSuccessAction;
