import {
  KnowledgeActionTypes,
  KnowledgeItemDefaultAction,
  KnowledgeItemFulfilledAction,
  KnowledgeItemRejectedAction,
} from 'entities/knowledge/actionTypes';

export const fetchKnowledgeById = (meta: KnowledgeItemDefaultAction['meta']): KnowledgeItemDefaultAction => ({
  type: KnowledgeActionTypes.FETCH_FOR_ID,
  meta,
});

export const fetchKnowledgeByIdSuccess = (
  meta: KnowledgeItemFulfilledAction['meta'],
  payload: KnowledgeItemFulfilledAction['payload'],
): KnowledgeItemFulfilledAction => ({
  type: KnowledgeActionTypes.FETCH_FOR_ID_SUCCESS,
  meta,
  payload,
});

export const fetchKnowledgeByIdRejected = (meta: KnowledgeItemRejectedAction['meta']): KnowledgeItemRejectedAction => ({
  type: KnowledgeActionTypes.FETCH_FOR_ID_FAILURE,
  meta,
});
