import { TopicPublicationSearchCriteria } from 'domains/publication/types';

export enum TopicPublicationActionTypes {
  APPLY_SEARCH_CRITERIA = 'domains/KNOWLEDGE_APPLY_SEARCH_CRITERIA',
  CLEAR_SEARCH_CRITERIA = 'domains/KNOWLEDGE_CLEAR_SEARCH_CRITERIA',
}

export type TopicPublicationApplySearchCriteriaPayload = Partial<TopicPublicationSearchCriteria> & { topicId?: string };

export type TopicPublicationApplySearchCriteria = {
  payload?: TopicPublicationApplySearchCriteriaPayload;
  type: TopicPublicationActionTypes.APPLY_SEARCH_CRITERIA;
};

export type TopicPublicationClearSearchCriteria = { type: TopicPublicationActionTypes.CLEAR_SEARCH_CRITERIA };
