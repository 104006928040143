import { SVGProps } from 'react';

export const TelegramIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <path
      d="M4.688 4.686A16 16 0 1 1 0 16 16 16 0 0 1 4.688 4.686zm11.885 7.126q-2.333.971-9.33 4.019-1.136.452-1.19.884c-.062.486.548.678 1.376.939l.352.112a11.439 11.439 0 0 0 2.487.589A3.03 3.03 0 0 0 12 17.714q6.54-4.413 6.75-4.461a.366.366 0 0 1 .33.032.322.322 0 0 1 .075.283 44.756 44.756 0 0 1-3.682 3.632c-.39.363-.667.62-.723.679-.126.13-.254.254-.377.372-.759.731-1.328 1.28.032 2.176.659.434 1.185.793 1.711 1.151.565.385 1.13.77 1.859 1.248.188.123.366.25.541.375a3.043 3.043 0 0 0 1.994.829c.427-.039.87-.441 1.092-1.64.53-2.834 1.573-8.973 1.813-11.5a2.83 2.83 0 0 0-.025-.63.688.688 0 0 0-.228-.433 1.053 1.053 0 0 0-.62-.186c-.602.002-1.525.323-5.97 2.171z"
      fill="currentColor"
    ></path>
  </svg>
);
