import { Switch, Route, Redirect } from 'react-router-dom';
import { styled } from 'styled-components';

import { FAQs } from 'domains/faqs/components/FAQs';

const StyledContainer = styled.div`
  height: 100%;
  overflow-y: hidden;
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
`;

const routes = ['general', 'access', 'membership', 'payments-and-billing'];

export const FAQRoutes = () => (
  <Switch>
    <StyledContainer>
      {routes.map((path) => (
        <Route key={path} path={`/faq/${path}`}>
          <FAQs type={path} />
        </Route>
      ))}
      <Route exact={true} path="/FAQ">
        <Redirect to="/faq/general" />
      </Route>
    </StyledContainer>
  </Switch>
);
