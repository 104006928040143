import { styled } from 'styled-components';

import { breakWordStyle } from 'common/styles';

export const MasterDetailContentWrapper = styled.div`
  width: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
  max-width: 1400px;

  ${breakWordStyle}
`;
