import { forwardRef, ReactElement, useImperativeHandle, useRef, useState } from 'react';
import { css, styled } from 'styled-components';

import { FullscreenExitIcon } from 'common/components/icons';
import { breakpoints, mediaStyles } from 'common/styles';
import { useTranslations } from 'domains/language/useTranslations';

const FRAME_HEIGHT = 50;
const NAV_BAR_HEIGHT = 61;

type Rect = { bottom: number; height?: number; left: number; right: number; top: number; width?: number };

const fullscreenCSS = css`
  position: absolute;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.outline};
  box-shadow: 0px 0px 15px -2px rgba(255, 255, 255, 0.05);
  z-index: 1000;

  ${mediaStyles(
    ({ spacing }) => `
    height: calc(100% - ${spacing * 2}rem);
    width: calc(100% - ${spacing * 2}rem);
    top: ${spacing}rem;
    left:${spacing}rem;
    right: ${spacing}rem;
    bottom: ${spacing}rem;
  `,
  )};
`;

const rectCSS = (rect: Rect) => css`
  position: absolute;
  top: ${rect.top}px;
  left: ${rect.left}px;
  right: ${rect.right}px;
  bottom: ${rect.bottom}px;
  height: ${`${rect.height}px`};
  width: ${`${rect.width}px`};
`;

const getContainerStyle = (rect?: Rect, isFullscreen?: boolean) => {
  if (!rect) {
    return css`
      height: ${FRAME_HEIGHT}vh;
      max-height: 300px;

      @media ${breakpoints.medium} {
        max-height: ${FRAME_HEIGHT}vh;
      }
    `;
  }
  return isFullscreen ? fullscreenCSS : rectCSS(rect);
};

const StyledFrameContainer = styled.div<{ rect?: Rect; isFullscreen: boolean }>`
  transition: 0.3s all;
  ${({ rect, isFullscreen }) => getContainerStyle(rect, isFullscreen)};
`;

const StyledOverlay = styled.div<{ isFullscreen: boolean }>`
  transition: 0.3s background-color;
  transform: ${({ isFullscreen }) => `translateY(${isFullscreen ? 0 : -100}%)`};
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: ${({ isFullscreen }) => `rgba(0, 0, 0, ${isFullscreen ? 0.6 : 0})`};
  z-index: 100;
`;

const StyledPlaceholder = styled.div<{ height?: number }>`
  ${({ height }) =>
    height
      ? css`
          display: block;
          width: 100%;
          height: ${FRAME_HEIGHT}vh;
        `
      : css`
          display: none;
        `};
`;

const StyledFullscreenButton = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: white;
  padding: 10px 15px;
  border-color: 1px solid black;
  color: black;
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: 0.9;
  z-index: 1001;
  border-radius: 5px;
`;

const StyledFullscreenIcon = styled(FullscreenExitIcon)`
  width: 14px;
  height: 14px;
  margin-left: 10px;
`;

type FullscreenContainerProps = { children: ReactElement };

export type FullscreenRef = { isFullscreen: boolean; toggleFullscreen: () => void };

export const FullscreenContainer = forwardRef<FullscreenRef, FullscreenContainerProps>(({ children }, ref) => {
  useImperativeHandle(ref, () => ({
    toggleFullscreen: onFullscreenHandle,
    isFullscreen: isFullscreen,
  }));

  const dataContainer = useRef<HTMLDivElement>(null);
  const [frameRect, setFrameRect] = useState<Rect | undefined>();
  const [isFullscreen, setIsFullscreen] = useState(false);
  const { textDictionary } = useTranslations();

  const onFullscreenHandle = () => {
    if (dataContainer.current && !isFullscreen) {
      const rect = dataContainer.current.getBoundingClientRect();
      const top = rect.y - NAV_BAR_HEIGHT;
      setFrameRect({
        top,
        bottom: top + rect.height,
        left: rect.x,
        right: rect.x + rect.width,
        width: rect.width,
        height: rect.height,
      });
      requestAnimationFrame(() => {
        setIsFullscreen(true);
      });
    } else {
      setIsFullscreen(false);
      setTimeout(() => {
        setFrameRect(undefined);
      }, 500);
    }
  };

  return (
    <>
      <StyledOverlay isFullscreen={isFullscreen} />
      <StyledFrameContainer ref={dataContainer} rect={frameRect} isFullscreen={isFullscreen}>
        {children}
        {isFullscreen && (
          <StyledFullscreenButton onClick={onFullscreenHandle}>
            <span>{textDictionary['app.fullscreen_exit.title']}</span>
            <StyledFullscreenIcon />
          </StyledFullscreenButton>
        )}
      </StyledFrameContainer>
      <StyledPlaceholder height={frameRect ? frameRect.bottom - frameRect.top : undefined} />
    </>
  );
});

FullscreenContainer.displayName = 'FullscreenContainer';
