import {
  FetchCoachmarksDefaultAction,
  FetchCoachmarksFulfilledAction,
  FetchCoachmarksRejectedAction,
  CoachmarkEntityActionType,
} from 'entities/coachmarks/actionTypes';

export const fetchCoachmarks = (): FetchCoachmarksDefaultAction => ({
  type: CoachmarkEntityActionType.FETCH,
});

export const fetchCoachmarksSuccess = (
  payload: FetchCoachmarksFulfilledAction['payload'],
): FetchCoachmarksFulfilledAction => ({
  type: CoachmarkEntityActionType.FETCH_FULFILLED,
  payload,
});

export const fetchCoachmarksFailure = (
  payload: FetchCoachmarksRejectedAction['payload'],
): FetchCoachmarksRejectedAction => ({
  type: CoachmarkEntityActionType.FETCH_REJECTED,
  payload,
});
