import { AudioPlayerAction, AudioPlayerTypes } from 'domains/audioPlayer/actionTypes';
import { AudioPlayerState } from 'domains/audioPlayer/types';

const initialState: AudioPlayerState = {
  isPlaying: false,
};

export const audioPlayerReducer = (state = initialState, action: AudioPlayerAction): AudioPlayerState => {
  switch (action.type) {
    case AudioPlayerTypes.SET:
      return {
        ...state,
        file: action.meta,
      };

    case AudioPlayerTypes.PLAY:
      return {
        ...state,
        isPlaying: action.meta.isPlaying,
      };
    default:
      return state;
  }
};
