import {
  KnowledgeFeedActionTypes,
  KnowledgeFeedFetchForTopicAction,
  KnowledgeFeedFetchForTopicFailureAction,
  KnowledgeFeedFetchForTopicPayload,
  KnowledgeFeedFetchForTopicSuccessAction,
} from 'entities/knowledgeFeed/actionTypes';

export const fetchKnowledgeFeedForTopic = (
  payload: KnowledgeFeedFetchForTopicPayload,
): KnowledgeFeedFetchForTopicAction => ({
  type: KnowledgeFeedActionTypes.FETCH_FOR_TOPIC,
  payload,
});

export const fetchKnowledgeFeedForTopicSuccess = (
  payload: KnowledgeFeedFetchForTopicSuccessAction['payload'],
  meta: KnowledgeFeedFetchForTopicSuccessAction['meta'],
): KnowledgeFeedFetchForTopicSuccessAction => ({
  type: KnowledgeFeedActionTypes.FETCH_FOR_TOPIC_SUCCESS,
  payload,
  meta,
});

export const fetchKnowledgeFeedForTopicFailure = (
  payload: KnowledgeFeedFetchForTopicFailureAction['payload'],
  meta: KnowledgeFeedFetchForTopicFailureAction['meta'],
): KnowledgeFeedFetchForTopicFailureAction => ({
  type: KnowledgeFeedActionTypes.FETCH_FOR_TOPIC_FAILURE,
  payload,
  meta,
});
