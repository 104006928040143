import { getLanguageById, getDefaultLanguage } from 'entities/languages/selectors';
import { RootState } from 'types';

export const getSiteLanguage = (state: RootState) => state.domains.language.siteLanguage || getDefaultLanguage(state);

export const getSiteLanguageLabel = (state: RootState) =>
  getLanguageById(state, getSiteLanguage(state))?.label ?? 'English';

export const getAllTermsById = (state: RootState) => state.domains.language.byId;

export const getIsFetchingTerms = (state: RootState) => state.domains.language.isFetching;
