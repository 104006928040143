import { createElement, ReactNode, MouseEvent } from 'react';

import { OpenLinkIcon, ShareIcon, BookmarkIcon, BookmarkFilledIcon } from 'common/components/icons';
import { ListItemButton } from 'common/components/lists/ListItemButton';
import { useTranslations } from 'domains/language/useTranslations';

type buttonTypes = 'bookmark' | 'share' | 'open';

type UIActionButtonProps = {
  href?: string;
  isActive?: boolean;
  isLoading?: boolean;
  label?: ReactNode;
  onClick?: (event: MouseEvent) => void;
  type: buttonTypes;
};

const getIcon = (type: buttonTypes, isActive: boolean) => {
  let buttonIcon;
  switch (type) {
    case 'bookmark':
      buttonIcon = isActive ? BookmarkFilledIcon : BookmarkIcon;
      break;
    case 'open':
      buttonIcon = OpenLinkIcon;
      break;
    case 'share':
      buttonIcon = ShareIcon;
      break;
  }
  return createElement(buttonIcon, {
    style: { width: 20, height: 20 },
  });
};

const getLabelText = (type: string, isActive: boolean, textDictionary: any) => {
  switch (type) {
    case 'bookmark':
      return isActive ? textDictionary['app.bookmarked.title'] : textDictionary['app.bookmark.title'];
    case 'open':
      return textDictionary['app.topic.actions.open'];
    case 'share':
      return textDictionary['app.monitor.share'];
    default:
      return '';
  }
};

export const UIActionButton = ({
  href,
  isActive = true,
  isLoading = false,
  label,
  onClick,
  type,
}: UIActionButtonProps) => {
  const { textDictionary } = useTranslations();
  const buttonText = label || getLabelText(type, isActive, textDictionary);

  const buttonOnClick = (event: MouseEvent) => {
    if (onClick) {
      onClick(event);
    }
    if (href) {
      window.open(href, '_blank');
    }
  };

  return (
    <ListItemButton
      title={buttonText}
      ariaLabel={buttonText}
      onClick={buttonOnClick}
      iconElement={getIcon(type, isActive)}
      isActive={isActive}
      isLoading={isLoading}
    />
  );
};
