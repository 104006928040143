import { SVGProps } from 'react';

export const BriefingIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke={'none'}>
      <g fill={'currentColor'}>
        <path d="M7.004 13V1v12z" transform="rotate(180 8.502 7)" />
        <path
          d="M7.004 14c-.56 0-1-.44-1-1V3.414L1.711 7.707a.99.99 0 0 1-1.414 0 .99.99 0 0 1 0-1.414l6-6a1.002 1.002 0 0 1 1.414 0l6 6a.99.99 0 0 1 0 1.414.99.99 0 0 1-1.414 0L8.004 3.414V13c0 .56-.44 1-1 1z"
          transform="rotate(180 8.502 7)"
        />
        <path
          d="M19 10c-.56 0-1-.44-1-1V3a.988.988 0 0 0-.293-.707A.993.993 0 0 0 17 2H3a.988.988 0 0 0-.707.293A.993.993 0 0 0 2 3v6c0 .56-.44 1-1 1s-1-.44-1-1V3c0-.406.079-.796.236-1.168.152-.36.366-.677.643-.953.276-.277.594-.491.953-.643A2.968 2.968 0 0 1 3 0h14c.406 0 .796.079 1.168.236.36.152.677.366.953.643.277.276.49.594.643.953.157.372.236.762.236 1.168v6c0 .56-.44 1-1 1z"
          transform="rotate(180 10 10)"
        />
      </g>
      <g fill={'none'}>
        <path
          d="M18 9a1 1 0 0 0 2 0V3a3 3 0 0 0-3-3H3a3 3 0 0 0-3 3v6a.99.99 0 0 0 1 1 .99.99 0 0 0 1-1V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1z"
          transform="rotate(180 10 10)"
        />
        <path transform="translate(1.5 1)" d="M0 0h18v18H0z" />
        <path transform="translate(1.5 1)" d="M0 0h18v18H0z" />
      </g>
    </g>
  </svg>
);
