import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { css, styled } from 'styled-components';

const StyledInput = styled.input`
  transition: border 0.3s;
  position: relative;
  width: 100%;
  height: 40px;
  background: transparent;
  box-sizing: border-box;
  outline: none;
  padding-left: 1rem;
  border-radius: 20px;
  text-overflow: ellipsis;
  appearance: none;
  padding-top: 10px;
  padding-bottom: 10px;
  color: ${({ theme }) => theme.colors.textBright};
  border-style: solid;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.inputBorder}AA;

  ${({ theme, disabled }) => css`
    &:-webkit-autofill {
      color: ${theme.colors.textBright};
      -webkit-text-fill-color: ${theme.colors.textBright} !important;
    }

    ${disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}

    &:focus {
      color: ${theme.colors.inputTextFocused};
      border-color: ${theme.colors.inputBorder};
    }
  `}
`;

type TextInputProps = ComponentPropsWithoutRef<'input'> & { label: string; name: string };

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(({ className: _, label, ...props }, ref) => (
  <StyledInput ref={ref} autoCorrect="off" type="text" placeholder={label} {...props} />
));

TextInput.displayName = 'TextInput';
