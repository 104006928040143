import union from 'lodash.union';
import { combineReducers } from 'redux';

import { ClusterItemAction, ClusterActionType } from 'domains/clusters/actionTypes';
import { MapShareType, TogglePublicLinkFulfilledAction } from 'domains/createShareSettings/actionTypes';
import {
  CustomKeyIssueTopicAndTagSuggestionsFulfilledAction,
  CustomKeyIssueTopicSearchFulfilledAction,
  CustomKeyIssueTypes,
} from 'domains/customKeyIssue/actionTypes';
import { FetchTopicsSearchFulfilledAction, SearchType } from 'domains/search/actionTypes';
import { TopicAction, TopicActionType } from 'entities/topics/actionTypes';
import { addUserOrLanguageChangeReset } from 'entities/topics/reducerHelpers';
import { TopicsState } from 'entities/topics/types';

const initialState: TopicsState = {
  allIds: [],
  byId: {},
  byType: {},
  highlightedIds: [],
  following: {
    ids: [],
    isPending: false,
    hasNextPage: false,
    limit: 8,
    offset: 0,
  },
  isFetching: {
    byType: {},
    highlighted: false,
  },
  isPossibleNextPageByType: {},
  isError: false,
  machineTranslationsOffIds: [],
  myMaps: {
    ids: [],
    isPending: false,
    hasNextPage: false,
    limit: 8,
    offset: 0,
  },
  sharedWithMe: {
    ids: [],
    isPending: false,
    hasNextPage: false,
    limit: 8,
    offset: 0,
  },
};

type Action =
  | TopicAction
  | ClusterItemAction
  | TogglePublicLinkFulfilledAction
  | CustomKeyIssueTopicSearchFulfilledAction;

const allIds = (state = initialState.allIds, action: Action) => {
  switch (action.type) {
    case TopicActionType.FETCH_TOPIC:
      return union(state, [action.payload.id]);

    case TopicActionType.FETCH_TOPIC_FULFILLED:
      return union(state, [action.payload.topic.id]);

    case TopicActionType.FETCH_TOPIC_REJECTED:
      return union(state, [action.payload.id]);

    case TopicActionType.FETCH_HIGHLIGHTS_FULFILLED:
    case TopicActionType.FETCH_TOPICS_FULFILLED:
      return union(
        state,
        (action.payload.topics || []).map(({ id }) => id),
      );

    case TopicActionType.REMOVE_MAP_FULFILLED:
      return state.filter((id) => action.meta.id !== id);
    default:
      return state;
  }
};

const byId = (
  state = initialState.byId,
  action: Action | FetchTopicsSearchFulfilledAction | CustomKeyIssueTopicAndTagSuggestionsFulfilledAction,
) => {
  switch (action.type) {
    case TopicActionType.FETCH_TOPIC: {
      const { id } = action.payload;
      return {
        ...state,
        [id]: {
          ...state[id],
          isTMLoaded: state[id] ? state[id].isTMLoaded : false,
          isFetching: true,
          topicNotFoundError: false,
          error: false,
        },
      };
    }

    case TopicActionType.REMOVE_MAP: {
      return {
        ...state,
        [action.meta.id]: {
          ...(state[action.meta.id] || {}),
          isDeleting: true,
        },
      };
    }

    case TopicActionType.FETCH_TOPIC_FULFILLED: {
      const { topic } = action.payload;
      const dimensions = topic.dimensions || [];
      const outerTopicState = dimensions
        .reduce((allTopics, dimension) => [...allTopics, ...(dimension.topics || [])], dimensions)
        .reduce(
          (topicState, topic) => ({
            ...topicState,
            [topic.id]: {
              ...state[topic.id],
              ...topic,
              isFetching: false,
              isTMLoaded: state[topic.id] ? state[topic.id].isTMLoaded : false,
            },
          }),
          {},
        );
      return {
        ...state,
        [action.payload.topic.id]: {
          ...action.payload.topic,
          isFetching: false,
          isTMLoaded: true,
          topicNotFoundError: false,
          error: false,
        },
        ...outerTopicState,
      };
    }
    case TopicActionType.FETCH_TOPIC_REJECTED: {
      const { id, error } = action.payload;
      const status = error?.status;
      const topicNotFoundError = status && [403, 404].includes(status);
      return {
        ...state,
        [id]: {
          ...state[id],
          isFetching: false,
          isTMLoaded: false,
          topicNotFoundError,
          error: status !== 401 ? error : null,
        },
      };
    }
    case CustomKeyIssueTypes.FETCH_TOPIC_AND_TAG_SUGGESTIONS_FULFILLED:
    case TopicActionType.FETCH_FOLLOWED_LIST_FULFILLED:
    case TopicActionType.FETCH_HIGHLIGHTS_FULFILLED:
    case TopicActionType.FETCH_TOPICS_FULFILLED: {
      return (action.payload.topics || []).reduce<TopicsState['byId']>((byId, topic) => {
        const attachment: any = {
          ...state[topic.id]?.attachment,
          ...topic?.attachment,
        };
        return {
          ...byId,
          [topic.id]: {
            ...state[topic.id],
            ...topic,
            isTMLoaded: state[topic.id] ? state[topic.id].isTMLoaded : false,
            attachment,
          },
        };
      }, state);
    }
    case TopicActionType.FOLLOW:
    case TopicActionType.UNFOLLOW:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isFollowingUpdating: true,
        },
      };

    case TopicActionType.FOLLOW_FULFILLED:
    case TopicActionType.FOLLOW_REJECTED:
    case TopicActionType.UNFOLLOW_FULFILLED:
    case TopicActionType.UNFOLLOW_REJECTED:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          is_following:
            action.payload.isFollowing !== undefined
              ? action.payload.isFollowing
              : state[action.payload.id].is_following,
          isFollowingUpdating: false,
        },
      };
    case TopicActionType.REMOVE_MAP_FULFILLED: {
      const { [action.meta.id]: _id, ...rest } = state;
      return rest;
    }
    case ClusterActionType.FETCH_FULFILLED: {
      const newState = { ...state };
      action.payload.related_topics.forEach((topic) => {
        newState[topic.id] = { ...newState[topic.id], ...topic };
      });
      return newState;
    }
    case CustomKeyIssueTypes.FETCH_TOPIC_SEARCH_FULFILLED:
    case SearchType.FETCH_TOPICS_FULFILLED: {
      return (action.payload || []).reduce<TopicsState['byId']>(
        (byId, topic) => ({
          ...byId,
          [topic.id]: {
            ...state[topic.id],
            ...topic,
            is_following: topic.is_following || state[topic.id]?.is_following || false,
          },
        }),
        state,
      );
    }
    case MapShareType.PUBLIC_LINK_FULFILLED: {
      return {
        ...state,
        [action.meta.topicId]: {
          ...state[action.meta.topicId],
          is_public: action.meta.permission === 'public',
        },
      };
    }
    default:
      return state;
  }
};

const machineTranslationsOffIds = (state = initialState.machineTranslationsOffIds, action: Action) => {
  switch (action.type) {
    case TopicActionType.TOGGLE_TOPIC_MACHINE_TRANSLATION: {
      const isOff = state.includes(action.meta.id);
      return isOff ? state.filter((id) => action.meta.id !== id) : [...state, action.meta.id];
    }
    default:
      return state;
  }
};

const byType = (state = initialState.byType, action: Action) => {
  switch (action.type) {
    case TopicActionType.FETCH_TOPICS_FULFILLED: {
      const { offset, type, topics } = action.payload;
      const stateToKeep = (state[type] || []).slice(0, offset);
      return {
        ...state,
        [type]: union(
          stateToKeep,
          (topics || []).map(({ id }) => id),
        ),
      };
    }
    case TopicActionType.REMOVE_MAP_FULFILLED: {
      const typeList = state[action.meta.type] || [];
      return {
        ...state,
        [action.meta.type]: typeList.filter((id) => action.meta.id !== id),
      };
    }
    default:
      return state;
  }
};

const isPossibleNextPageByType = (state = initialState.isPossibleNextPageByType, action: TopicAction) => {
  switch (action.type) {
    case TopicActionType.FETCH_TOPICS_FULFILLED: {
      const { limit, type, topics } = action.payload;
      return {
        ...state,
        [type]: (topics || []).length >= limit,
      };
    }
    default:
      return state;
  }
};

const highlightedIds = (state = initialState.highlightedIds, action: TopicAction) => {
  switch (action.type) {
    case TopicActionType.FETCH_HIGHLIGHTS_FULFILLED:
      return action.payload.topics.map(({ id }) => id);
    case TopicActionType.REMOVE_MAP_FULFILLED:
      return state.filter((id) => id !== action.meta.id);
    default:
      return state;
  }
};

const following = (state = initialState.following, action: TopicAction): TopicsState['following'] => {
  switch (action.type) {
    case TopicActionType.FETCH_FOLLOWED_LIST: {
      return {
        ...state,
        isPending: true,
      };
    }
    case TopicActionType.FETCH_FOLLOWED_LIST_FULFILLED: {
      const fetchedTopicIds = action.payload.topics.map(({ id }) => id);
      const newIds = action.payload.offset ? [...state.ids, ...fetchedTopicIds] : fetchedTopicIds;

      return {
        ...state,
        isPending: false,
        ids: newIds,
        error: false,
        hasNextPage: action.payload.topics.length === action.payload.limit,
        limit: action.payload.limit,
        offset: action.payload.offset,
      };
    }
    case TopicActionType.FETCH_FOLLOWED_LIST_REJECTED: {
      return {
        ...state,
        isPending: false,
        error: true,
      };
    }
    case TopicActionType.REMOVE_MAP_FULFILLED: {
      return {
        ...state,
        ids: state.ids.filter((id) => id !== action.meta.id),
      };
    }
    case TopicActionType.FOLLOW_FULFILLED: {
      return {
        ...state,
        ids: [...state.ids, action.payload.id],
      };
    }
    case TopicActionType.UNFOLLOW_FULFILLED: {
      return {
        ...state,
        ids: state.ids.filter((id) => id !== action.payload.id),
      };
    }

    default:
      return state;
  }
};

const myMaps = (state = initialState.myMaps, action: TopicAction): TopicsState['myMaps'] => {
  switch (action.type) {
    case TopicActionType.FETCH_MY_MAPS: {
      return {
        ...state,
        isPending: true,
      };
    }
    case TopicActionType.FETCH_MY_MAPS_FULFILLED: {
      const newIds = action.payload.offset ? [...state.ids, ...action.payload.topicIds] : action.payload.topicIds;

      return {
        ...state,
        isPending: false,
        ids: newIds,
        error: false,
        hasNextPage: action.payload.topicIds.length === action.payload.limit,
        limit: action.payload.limit,
        offset: action.payload.offset,
      };
    }
    case TopicActionType.FETCH_MY_MAPS_REJECTED: {
      return {
        ...state,
        isPending: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

const sharedWithMe = (state = initialState.sharedWithMe, action: TopicAction): TopicsState['sharedWithMe'] => {
  switch (action.type) {
    case TopicActionType.FETCH_SHARED_WITH_ME: {
      return {
        ...state,
        isPending: true,
      };
    }
    case TopicActionType.FETCH_SHARED_WITH_ME_FULFILLED: {
      const newIds = action.payload.offset ? [...state.ids, ...action.payload.topicIds] : action.payload.topicIds;

      return {
        ...state,
        isPending: false,
        ids: newIds,
        error: false,
        hasNextPage: action.payload.topicIds.length === action.payload.limit,
        limit: action.payload.limit,
        offset: action.payload.offset,
      };
    }
    case TopicActionType.FETCH_SHARED_WITH_ME_REJECTED: {
      return {
        ...state,
        isPending: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

const isFetchingByType = (state = initialState.isFetching.byType, action: TopicAction) => {
  switch (action.type) {
    case TopicActionType.FETCH_OF_TYPE:
      return {
        ...state,
        [action.payload.type]: true,
      };
    case TopicActionType.FETCH_TOPICS_FULFILLED:
    case TopicActionType.FETCH_TOPICS_REJECTED:
      return {
        ...state,
        [action.payload.type]: false,
      };

    default:
      return state;
  }
};

const isFetchingHighlighted = (state = initialState.isFetching.highlighted, action: TopicAction) => {
  switch (action.type) {
    case TopicActionType.FETCH_HIGHLIGHTS:
      return true;
    case TopicActionType.FETCH_HIGHLIGHTS_FULFILLED:
    case TopicActionType.FETCH_HIGHLIGHTS_REJECTED:
      return false;

    default:
      return state;
  }
};

const isFetching = combineReducers({
  highlighted: isFetchingHighlighted,
  byType: isFetchingByType,
});

export const topicsReducer = combineReducers({
  allIds: addUserOrLanguageChangeReset(allIds, initialState.allIds),
  byId: addUserOrLanguageChangeReset(byId, initialState.byId),
  byType: addUserOrLanguageChangeReset(byType, initialState.byType),
  highlightedIds: addUserOrLanguageChangeReset(highlightedIds, initialState.highlightedIds),
  following: addUserOrLanguageChangeReset(following, initialState.following),
  myMaps: addUserOrLanguageChangeReset(myMaps, initialState.myMaps),
  sharedWithMe: addUserOrLanguageChangeReset(sharedWithMe, initialState.sharedWithMe),
  isFetching: addUserOrLanguageChangeReset(isFetching, initialState.isFetching),
  isPossibleNextPageByType: addUserOrLanguageChangeReset(
    isPossibleNextPageByType,
    initialState.isPossibleNextPageByType,
  ),
  machineTranslationsOffIds,
});
