import {
  PublicationActionTypes,
  PublicationFetchForTopicAction,
  PublicationFetchForTopicFailureAction,
  PublicationFetchForTopicPayload,
  PublicationFetchForTopicSuccessAction,
} from 'entities/publication/actionTypes';

export const fetchPublicationForTopic = (payload: PublicationFetchForTopicPayload): PublicationFetchForTopicAction => ({
  type: PublicationActionTypes.FETCH_FOR_TOPIC,
  payload,
});

export const fetchPublicationForTopicSuccess = (
  payload: PublicationFetchForTopicSuccessAction['payload'],
  meta: PublicationFetchForTopicSuccessAction['meta'],
): PublicationFetchForTopicSuccessAction => ({
  type: PublicationActionTypes.FETCH_FOR_TOPIC_SUCCESS,
  payload,
  meta,
});

export const fetchPublicationForTopicFailure = (
  payload: PublicationFetchForTopicFailureAction['payload'],
  meta: PublicationFetchForTopicFailureAction['meta'],
): PublicationFetchForTopicFailureAction => ({
  type: PublicationActionTypes.FETCH_FOR_TOPIC_FAILURE,
  payload,
  meta,
});
