import { forwardRef, ReactElement, useEffect, useState, FocusEvent, ComponentPropsWithoutRef } from 'react';
import { css, styled } from 'styled-components';

type StyledProps = { hasLeftIcon?: boolean; hasRightIcon?: boolean; hasValue?: boolean; isFocused?: boolean };

const StyledWrapper = styled.div<StyledProps>`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const StyledInput = styled.input<StyledProps>`
  transition: border 0.3s;
  position: relative;
  width: 100%;
  height: 40px;
  background: transparent;
  padding-left: ${({ hasLeftIcon = true }) => (hasLeftIcon ? 'calc(3.3rem - 4px)' : '14px')};
  font-size: 1rem;
  box-sizing: border-box;
  outline: none;
  border-radius: 5px;
  text-overflow: ellipsis;
  appearance: none;
  padding-top: 10px;
  padding-bottom: 10px;

  ${({ theme, isFocused, disabled, hasRightIcon }) => css`
    color: ${theme.colors.inputText};
    padding-right: ${hasRightIcon ? 3 : 2}rem;
    border: 1px solid ${theme.colors.inputBorder}AA;

    &:-webkit-autofill {
      color: ${theme.colors.fillPrimary};
      -webkit-text-fill-color: ${theme.colors.fillPrimary} !important;
    }

    ${disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}

    ${isFocused &&
    css`
      color: ${theme.colors.inputTextFocused};
      border-color: ${theme.colors.inputBorder};
    `}

    &:focus {
      color: ${theme.colors.inputTextFocused};
      border-color: ${theme.colors.inputBorder};
    }
  `}
`;

const hasValueStyles = css`
  color: ${({ theme }) => theme.colors.inputTextFocused};
`;

const StyledLabel = styled.label<StyledProps>`
  transition: all 0.3s ease;
  font-weight: bold;
  margin-bottom: 16px;

  ${({ theme, hasValue, isFocused }) => css`
    color: ${isFocused ? theme.colors.inputTextFocused : theme.colors.inputText};

    ${StyledInput}:-webkit-autofill + & {
      ${hasValueStyles}
    }

    ${(hasValue || isFocused) && hasValueStyles}

    ${StyledInput}:focus + & {
      color: ${theme.colors.blue};
    }
  `}
`;

const StyledIconContainer = styled.div<StyledProps>`
  transition: color 0.3s ease;
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  top: 0;
  bottom: 0;
  left: 1rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ theme, isFocused }) => css`
    color: ${isFocused ? theme.colors.blue : theme.colors.fillTertiary};
  `}
`;

const StyledRightElementContainer = styled.div<StyledProps>`
  position: absolute;
  top: 35px;
  bottom: 0;
  right: 1rem;
`;

export type TextInputProps = ComponentPropsWithoutRef<'input'> & {
  icon?: ReactElement;
  label?: string;
  name: string;
  rightElement?: ReactElement;
};

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ className, icon, label, onBlur, onFocus, rightElement = null, type = 'text', ...props }, ref) => {
    const [hasValue, setHasValue] = useState(props.value !== undefined ? !!props.value : !!props.defaultValue);
    const [isFocused, setIsFocused] = useState(false);

    const onBlurHandle = (event: FocusEvent<HTMLInputElement>) => {
      setIsFocused(false);
      if (props.value === undefined) {
        setHasValue(!!event.target.value);
      }
      if (onBlur) {
        onBlur(event);
      }
    };

    const onFocusHandle = (event: FocusEvent<HTMLInputElement>) => {
      setIsFocused(true);
      if (onFocus) {
        onFocus(event);
      }
    };

    useEffect(() => {
      setHasValue(props.value !== undefined ? !!props.value : !!props.defaultValue);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);

    return (
      <StyledWrapper className={className}>
        {label === undefined || (
          <StyledLabel hasValue={hasValue} isFocused={isFocused} htmlFor={props.name} hasLeftIcon={!!icon}>
            {label}
          </StyledLabel>
        )}
        <StyledInput
          ref={ref}
          hasRightIcon={!!rightElement}
          hasLeftIcon={!!icon}
          type={type}
          {...props}
          isFocused={isFocused}
          onBlur={onBlurHandle}
          onFocus={onFocusHandle}
        />
        {icon && <StyledIconContainer isFocused={isFocused}>{icon}</StyledIconContainer>}
        {rightElement && <StyledRightElementContainer>{rightElement}</StyledRightElementContainer>}
      </StyledWrapper>
    );
  },
);

TextInput.displayName = 'TextInput';
