import { WhatsNewAction, WhatsNewActionType } from 'domains/whatsNew/actionTypes';
import { WHATS_NEW_VERSION } from 'domains/whatsNew/components/WhatsNewModal';
import { WhatsNewState } from 'domains/whatsNew/types';

const initialState: WhatsNewState = {
  isFulfilled: false,
  isPending: false,
  data: undefined,
  error: false,
  showBadge: false,
};

export const whatsNewReducer = (state = initialState, action: WhatsNewAction): WhatsNewState => {
  switch (action.type) {
    case WhatsNewActionType.FETCH:
      return {
        ...state,
        isFulfilled: false,
        isPending: true,
      };
    case WhatsNewActionType.FETCH_FULFILLED: {
      const storedVersion = localStorage.getItem(WHATS_NEW_VERSION);
      if (!action.payload || !action.payload.manifest) {
        return {
          ...state,
          isFulfilled: true,
          isPending: false,
          error: false,
          showBadge: false,
        };
      }
      return {
        ...state,
        isFulfilled: true,
        isPending: false,
        data: action.payload.manifest,
        error: false,
        showBadge: storedVersion !== action.payload.manifest.version,
      };
    }
    case WhatsNewActionType.FETCH_REJECTED:
      return {
        ...state,
        error: true,
        isPending: false,
        data: undefined,
      };
    case WhatsNewActionType.UPDATE_BADGE:
      return {
        ...state,
        showBadge: action.payload.showBadge,
      };
    default:
      return state;
  }
};
