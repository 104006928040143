import { SVGProps } from 'react';

export const PictureInPictureIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      d="M26.727 22.546h-18v12h18v-12zM43.637 4H4.363A4.376 4.376 0 000 8.364v30.545c0 2.4 1.964 4.32 4.364 4.32h39.272c2.4 0 4.364-1.92 4.364-4.32V8.364C48 5.964 46.036 4 43.636 4zm0 34.93H4.363V8.32h39.272v30.61z"
      fill="currentColor"
    />
  </svg>
);
