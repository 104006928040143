import {
  PublicationFetchForTopicFailurePayload,
  PublicationFetchForTopicPayload,
  PublicationFetchForTopicSuccessAction,
} from 'entities/publication/actionTypes';
import { ErrorMeta } from 'types';

export enum VideoActionTypes {
  FETCH_FOR_TOPIC = 'entities/VIDEOS_FETCH_FOR_TOPIC',
  FETCH_FOR_TOPIC_SUCCESS = 'entities/VIDEOS_FETCH_FOR_TOPIC_SUCCESS',
  FETCH_FOR_TOPIC_FAILURE = 'entities/VIDEOS_FETCH_FOR_TOPIC_FAILURE',
}

export type VideosFetchForTopicPayload = Omit<PublicationFetchForTopicPayload, 'language'>;
export type VideosFetchForTopicAction = { payload: VideosFetchForTopicPayload; type: VideoActionTypes.FETCH_FOR_TOPIC };

export type VideosFetchForTopicSuccessAction = Omit<PublicationFetchForTopicSuccessAction, 'type'> & {
  type: VideoActionTypes.FETCH_FOR_TOPIC_SUCCESS;
};

type VideosFetchForTopicFailureMeta = ErrorMeta & { searchCriteria: VideosFetchForTopicPayload };

export type VideosFetchForTopicFailureAction = {
  meta: VideosFetchForTopicFailureMeta;
  payload: PublicationFetchForTopicFailurePayload;
  type: VideoActionTypes.FETCH_FOR_TOPIC_FAILURE;
};

export type VideosAction =
  | VideosFetchForTopicAction
  | VideosFetchForTopicSuccessAction
  | VideosFetchForTopicFailureAction;
