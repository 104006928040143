import { put, select, take } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { fetchProfile, updateProfileFailure, updateProfileSuccess } from 'entities/user/actions';
import { UpdateProfileDefaultAction, UserProfileType } from 'entities/user/actionTypes';
import { getUserId } from 'entities/user/selectors';
import { isFetchError } from 'TypeCheckMethods';

type ResponsePayload = { status?: { success: boolean } };

export function* updateProfileSaga(action: UpdateProfileDefaultAction) {
  const { email, firstName, lastName, organization, notifications } = action.payload;
  try {
    const userId: string = yield select(getUserId);
    const payload: ResponsePayload = yield apiFetch(`profile/${userId}`, {
      method: 'PUT',
      body: {
        email,
        first_name: firstName,
        last_name: lastName,
        organization,
        notifications,
      },
    });
    if (!payload.status?.success) {
      throw new Error('Profile update unsuccessful');
    }

    yield put(fetchProfile());
    yield take([UserProfileType.FETCH_FULFILLED, UserProfileType.FETCH_REJECTED]);
    yield put(updateProfileSuccess(action.payload, { event: { userId: userId } }));
  } catch (error) {
    if (isFetchError(error)) {
      yield put(updateProfileFailure(error));
    }
  }
}
