import { memo } from 'react';
import { css, styled } from 'styled-components';

import { UIChip } from 'common/components';
import { ListItemWrapper } from 'common/components/lists/ListItemWrapper';
import { ListImage, ListLink } from 'common/components/lists/ListLink';
import { breakWordStyle, lineTruncationStyle } from 'common/styles';
import { useFormatDate, useInternalLink } from 'common/utilities';
import { useTranslations } from 'domains/language/useTranslations';
import { MembershipEvent } from 'domains/membershipEvents/types';

type StyledProps = { isRegistered?: boolean };

const StyledTitle = styled.p<StyledProps>`
  font-size: 1rem;
  margin: 0 0 0.5rem 0;

  ${breakWordStyle}
  ${({ isRegistered }) => isRegistered && lineTruncationStyle(1)};
`;

const StyledDate = styled.p`
  font-size: 0.9rem;
  margin: 0 0 0.5rem 0;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledImage = styled(ListImage)`
  width: 80px;
  min-width: 80px;
  height: 80px;
  border-radius: 4px;
  margin-right: 15px;
`;

const StyledTag = styled(UIChip)<{ isSelected?: boolean }>`
  margin: 5px 0 0;
  ${({ theme, isSelected }) =>
    !isSelected &&
    css`
      background-color: ${theme.colors.green};
      color: white;
    `}
`;

type UpcomingEventListItemProps = { event: MembershipEvent; isSelected?: boolean; link: string; onClick?: () => void };

export const UpcomingEventListItem = memo(
  ({ event, link, isSelected = false, onClick }: UpcomingEventListItemProps) => {
    const { getRelativeDate } = useFormatDate();
    const { textDictionary } = useTranslations();
    const linkLocation = useInternalLink(link);
    return (
      <ListItemWrapper isSelected={isSelected} trackPage="Event List">
        <ListLink to={linkLocation} onClick={onClick}>
          <StyledContent>
            <StyledImage src={event.images?.x_small} />
            <div>
              {event.start_date && (
                <StyledDate>{getRelativeDate({ date: event.start_date, precision: 'day' })}</StyledDate>
              )}
              <StyledTitle isRegistered={event.is_registered}>{event.name}</StyledTitle>
              {event.is_registered && (
                <StyledTag isSelected={isSelected} disabled>
                  {textDictionary['app.event.registered']}
                </StyledTag>
              )}
            </div>
          </StyledContent>
        </ListLink>
      </ListItemWrapper>
    );
  },
);

UpcomingEventListItem.displayName = 'UpcomingEventListItem';
