import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { styled, ThemeProvider } from 'styled-components';

import { SectionTitleUnderlined } from 'common/components';
import { UIInput } from 'common/components/form/UIInput';
import { breakpoints } from 'common/styles';
import { trackEvent } from 'domains/analytics/actions';
import { StyledLandingText } from 'domains/landing/components/StyledLandingText';
import { StyledLandingTitle } from 'domains/landing/components/StyledLandingTitle';
import { useTranslations } from 'domains/language/useTranslations';
import BackgroundImage from 'domains/resources/background.jpg';
import advancedFeatures from 'domains/resources/data/advancedFeatures.json';
import getStarted from 'domains/resources/data/getStarted.json';
import introduction from 'domains/resources/data/introduction.json';
import { Introduction } from 'domains/resources/Introduction';
import { SourceVideo } from 'domains/resources/types';
import { VideoCard } from 'domains/resources/VideoCard';
import { darkTheme } from 'domains/theme/constants/theme';
import { setVisualMediaPlayer } from 'domains/visualMediaPlayer/actions';

const StyledContainer = styled.div`
  background-attachment: initial;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000), url('${BackgroundImage}');
  background-position:
    center,
    center 33%;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 5rem 1rem 4rem;

  @media ${breakpoints.medium} {
    padding: 5rem 2rem 4rem;
  }

  @media ${breakpoints.extraLarge} {
    padding: 5rem 3rem 4rem;
  }
`;

const StyledText = styled(StyledLandingText)`
  color: ${({ theme }) => theme.colors.textBright};
  margin-bottom: 5rem;
`;

const StyledUIInput = styled(UIInput)`
  width: 100%;

  @media ${breakpoints.medium} {
    width: 20rem;
  }
`;

const StyledGridContainer = styled.div`
  margin: 1rem;
  padding-bottom: 1rem;

  @media ${breakpoints.medium} {
    margin: 1rem 2rem 2rem;
  }

  @media ${breakpoints.extraLarge} {
    margin: 1rem 3rem 3rem;
  }
`;

const StyledGrid = styled.div`
  display: grid;
  grid-gap: 3rem 2rem;
  grid-template-columns: 100%;
  margin-bottom: 2rem;

  @media ${breakpoints.medium} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media ${breakpoints.large} {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
`;

export const ResourcesPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState('');
  const [videos, setVideos] = useState<SourceVideo[]>([]);
  const { textDictionary } = useTranslations();

  const urlParams = new URLSearchParams(location.search);
  const videoUrlParam = urlParams.get('video');

  useEffect(() => {
    dispatch(trackEvent({ name: 'Browse page', page: 'Resources' }));
  }, [dispatch]);

  useEffect(() => {
    if (!videoUrlParam) dispatch(setVisualMediaPlayer(undefined));
  }, [dispatch, videoUrlParam]);

  const onSearchChange = (value: string) => {
    setSearchTerm(value);
    setVideos(
      [introduction, ...getStarted, ...advancedFeatures].filter(({ name }) =>
        name.toLowerCase().includes(value.toLowerCase()),
      ),
    );
  };

  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <StyledContainer>
          <StyledLandingTitle>{textDictionary['app.resources.title']}</StyledLandingTitle>
          <StyledText>{textDictionary['app.resources.description']}</StyledText>
          <StyledUIInput
            clear={() => onSearchChange('')}
            onChange={onSearchChange}
            placeholder={textDictionary['app.search.title']}
            value={searchTerm}
          />
        </StyledContainer>
      </ThemeProvider>
      {searchTerm ? (
        <StyledGridContainer>
          <SectionTitleUnderlined>{textDictionary['app.resources.list.search']}</SectionTitleUnderlined>
          <StyledGrid>
            {videos.map((video) => (
              <VideoCard key={video.name} {...video} />
            ))}
          </StyledGrid>
        </StyledGridContainer>
      ) : (
        <StyledGridContainer>
          <SectionTitleUnderlined>{textDictionary['app.resources.list.introduction']}</SectionTitleUnderlined>
          <Introduction />
          <SectionTitleUnderlined>{textDictionary['app.resources.list.start']}</SectionTitleUnderlined>
          <StyledGrid>
            {getStarted.map((video) => (
              <VideoCard key={video.name} {...video} />
            ))}
          </StyledGrid>
          <SectionTitleUnderlined>{textDictionary['app.resources.list.advanced']}</SectionTitleUnderlined>
          <StyledGrid>
            {advancedFeatures.map((video) => (
              <VideoCard key={video.name} {...video} />
            ))}
          </StyledGrid>
        </StyledGridContainer>
      )}
    </>
  );
};
