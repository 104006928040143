import {
  ClusterItemDefaultAction,
  ClusterItemFulfilledAction,
  ClusterItemRejectedAction,
  ClusterDigDeeperDefaultAction,
  ClusterDigDeeperFulfilledAction,
  ClusterDigDeeperRejectedAction,
  ClusterActionType,
} from 'domains/clusters/actionTypes';

export const fetchClusterById = (id: string): ClusterItemDefaultAction => ({
  type: ClusterActionType.FETCH,
  meta: { id },
});

export const fetchClusterByIdSuccess = (
  meta: ClusterItemFulfilledAction['meta'],
  payload: ClusterItemFulfilledAction['payload'],
): ClusterItemFulfilledAction => ({
  type: ClusterActionType.FETCH_FULFILLED,
  meta,
  payload,
});

export const fetchClusterByIdFailure = (meta: ClusterItemRejectedAction['meta']): ClusterItemRejectedAction => ({
  type: ClusterActionType.FETCH_REJECTED,
  meta,
});

export const fetchClusterDigDeeperItems = (
  meta: ClusterDigDeeperDefaultAction['meta'],
): ClusterDigDeeperDefaultAction => ({
  type: ClusterActionType.FETCH_DIG_DEEPER,
  meta,
});

export const fetchClusterDigDeeperItemsSuccess = (
  meta: ClusterDigDeeperFulfilledAction['meta'],
  payload: ClusterDigDeeperFulfilledAction['payload'],
): ClusterDigDeeperFulfilledAction => ({
  type: ClusterActionType.FETCH_DIG_DEEPER_FULFILLED,
  meta,
  payload,
});

export const fetchClusterDigDeeperItemsFailure = (
  meta: ClusterDigDeeperRejectedAction['meta'],
): ClusterDigDeeperRejectedAction => ({
  type: ClusterActionType.FETCH_DIG_DEEPER_REJECTED,
  meta,
});
