import { useTranslations } from 'domains/language/useTranslations';
import { UpgradeModal } from 'domains/modal/components/UpgradeModal';

export const CreateMapUpgradeModal = () => {
  const { textDictionary } = useTranslations();

  return (
    <UpgradeModal
      title={textDictionary['app.create.upgrade.title']}
      message={textDictionary['app.create.upgrade_text']}
      primaryButtonText={textDictionary['app.message_banner.upgrade.button.2']}
      additionalPrimaryButtonTrackMeta={{
        page: 'Create',
        subpage: 'Create custom map',
      }}
      upgradeLocation="pro"
    />
  );
};
