import {
  TopicStakeholderActionTypes,
  TopicStakeholdersApplySearchCriteriaAction,
  TopicStakeholdersClearSearchCriteriaAction,
} from 'domains/stakeholders/actionTypes';

export const applyStakeholdersSearchCriteria = (
  payload?: TopicStakeholdersApplySearchCriteriaAction['payload'],
): TopicStakeholdersApplySearchCriteriaAction => ({
  type: TopicStakeholderActionTypes.APPLY_SEARCH_CRITERIA,
  payload,
});

export const clearStakeholdersSearchCriteria = (): TopicStakeholdersClearSearchCriteriaAction => ({
  type: TopicStakeholderActionTypes.CLEAR_SEARCH_CRITERIA,
});
