import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { Knowledge } from 'entities/knowledge/types';
import { fetchPublicationForTopicFailure, fetchPublicationForTopicSuccess } from 'entities/publication/actions';
import { PublicationFetchForTopicAction } from 'entities/publication/actionTypes';
import { getPublicationFetchParams } from 'entities/publication/sagas/utils';
import { isFetchError } from 'TypeCheckMethods';

export function* fetchPublicationForTopicSaga(action: PublicationFetchForTopicAction) {
  const { id } = action.payload;
  try {
    const queryParams = getPublicationFetchParams(action.payload);
    const payload: Knowledge[] = yield apiFetch(`topic/${id}/publication`, {
      queryParams,
    });

    yield put(fetchPublicationForTopicSuccess({ topicId: id, data: payload }, action.payload));
  } catch (error) {
    if (isFetchError(error)) {
      const errorMeta = {
        searchCriteria: action.payload,
        logoutOnAuthError: true,
      };
      yield put(fetchPublicationForTopicFailure({ topicId: id, error }, errorMeta));
    }
  }
}
