import { useDispatch, useSelector } from 'react-redux';

import { AnalyticsActionMeta } from 'domains/analytics/types';
import { useTranslations } from 'domains/language/useTranslations';
import { followTopic, unfollowTopic } from 'entities/topics/actions';
import { getTopicIsFollowed, getTopicIsFollowedUpdating } from 'entities/topics/selectors';
import { getCanFollowTopic } from 'entities/user/selectors';

type Options = { followText?: string; unfollowText?: string };

export const useTopicFollowing = (topicId: string, options?: Options) => {
  const dispatch = useDispatch();
  const isFollowing = useSelector(getTopicIsFollowed(topicId));
  const isFollowingUpdating = useSelector(getTopicIsFollowedUpdating(topicId));
  const canFollow = useSelector(getCanFollowTopic);

  const { textDictionary } = useTranslations();

  const toggleFollow = (meta?: AnalyticsActionMeta) => {
    if (isFollowing) {
      dispatch(unfollowTopic({ id: topicId }, meta));
    } else {
      dispatch(followTopic({ id: topicId }, meta));
    }
  };

  const followActionText = isFollowing
    ? options?.unfollowText || textDictionary['app.topic.following']
    : options?.followText || textDictionary['app.topic.follow'];

  return {
    isFollowing,
    isFollowingUpdating,
    canFollow,
    toggleFollow,
    followActionText,
  };
};
