import { BookmarksAction, BookmarkActionTypes } from 'domains/bookmarks/actionTypes';
import { BOOKMARKS_LIMIT } from 'domains/bookmarks/sagas/fetchBookmarksSaga';
import { BookmarksState } from 'domains/bookmarks/types';
import { addUserChangeReset } from 'entities/user/utils';

const initialState: BookmarksState = {
  total: 0,
  offset: 0,
  hasNextPage: false,
  isPending: false,
  items: [],
};

const reducer = (state = initialState, action: BookmarksAction): BookmarksState => {
  switch (action.type) {
    case BookmarkActionTypes.BOOKMARKS_FETCH: {
      return {
        ...state,
        isPending: true,
        offset: action.meta.offset,
      };
    }
    case BookmarkActionTypes.BOOKMARKS_FETCH_FULFILLED: {
      const { bookmarks } = action.payload;
      const newItems = bookmarks.map((item) => ({
        id: item.id,
        type: item.type,
      }));
      const oldItems = state.items;
      return {
        ...state,
        isPending: false,
        hasNextPage: bookmarks.length === BOOKMARKS_LIMIT,
        total: action.payload.total,
        items: action.meta.offset === 0 ? newItems : [...oldItems, ...newItems],
      };
    }
    case BookmarkActionTypes.BOOKMARKS_FETCH_REJECTED: {
      return {
        ...state,
        isPending: false,
      };
    }
    case BookmarkActionTypes.BOOKMARK_TOGGLE_FULFILLED: {
      const isBookmarked = state.items.some((item) => item.id === action.meta.bookmark.id);
      if (isBookmarked) {
        return {
          ...state,
          items: state.items.filter((item) => item.id !== action.meta.bookmark.id),
          total: state.total - 1,
        };
      }
      return {
        ...state,
        items: [{ id: action.meta.bookmark.id, type: action.meta.bookmark.type }, ...state.items],
        total: state.total + 1,
      };
    }
    default:
      return state;
  }
};

export const bookmarksReducer = addUserChangeReset(reducer, initialState);
