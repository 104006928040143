import { put } from 'redux-saga/effects';

import { fetchKeyIssues } from 'entities/keyIssues/actions';
import { UpdateSearchTermAction } from 'entities/keyIssues/actionTypes';

export function* fetchKeyIssuesOnSearch({ meta, payload }: UpdateSearchTermAction) {
  yield put(
    fetchKeyIssues({
      searchTerm: payload,
      offset: 0,
      limit: meta?.limit,
      page: meta?.page,
    }),
  );
}
