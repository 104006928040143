import { css, styled } from 'styled-components';

import { CrossIcon } from 'common/components';

export const ItemBox = styled.div<{ status: string }>`
  color: ${({ theme }) => theme.colors.textBright};
  position: relative;
  border-radius: 4px;
  margin-bottom: 0.5rem;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.modalCardBackground};
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  ${({ status }) =>
    status === 'Complete' &&
    css`
      border-color: green;
    `}
  ${({ status }) =>
    status === 'Failed' &&
    css`
      border-color: red;
    `}
`;

export const Text = styled.div`
  padding: 4px 0;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.textFade};
`;

export const BriefingTitle = styled.h2`
  margin: 0;
  font-size: 20px;
  font-weight: 500;
`;

export const StyledCrossIcon = styled(CrossIcon)`
  position: absolute;
  width: 12px;
  height: 12px;
  top: 12px;
  right: 12px;
  color: ${({ theme }) => theme.colors.fillPrimary};
  cursor: pointer;
`;
