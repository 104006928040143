import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { fetchCommunitiesFailure, fetchCommunitiesSuccess } from 'domains/collection/actions';
import { FetchCommunitiesAction } from 'domains/collection/actionTypes';
import { FetchCommunitiesResponse } from 'domains/collection/types/communityResponses';

export function* fetchCommunitiesSaga({ payload: { meta, search } }: FetchCommunitiesAction) {
  try {
    const response: FetchCommunitiesResponse = yield apiFetch(`community/search/${search}`, {
      queryParams: meta,
    }) ?? { requested: search, result: [] };
    yield put(fetchCommunitiesSuccess({ ...response, meta }));
  } catch (error) {
    yield put(fetchCommunitiesFailure());
    console.error(`[fetchCommunitiesSaga] Failed to fetch communities: ${error}`);
  }
}
