type SimpleFileType = 'image' | 'video' | 'audio' | 'document' | 'spreadsheet' | 'presentation' | 'pdf' | 'generic';

const presentationTypes = [
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
  'application/vnd.ms-powerpoint', // .ppt
  'application/vnd.oasis.opendocument.presentation', // .odp
];

const spreadsheetTypes = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
  'application/vnd.ms-excel', // .xls
  'application/vnd.oasis.opendocument.spreadsheet', // .ods
];

const documentTypes = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/msword', // .doc
  'application/vnd.oasis.opendocument.text', // .odt
];

export const getFileTypeFromMime = (mimeType?: string): SimpleFileType => {
  if (!mimeType) {
    return 'generic';
  }
  if (mimeType.startsWith('image/')) {
    return 'image';
  } else if (mimeType.startsWith('audio/')) {
    return 'audio';
  } else if (mimeType.startsWith('video/')) {
    return 'video';
  } else if (presentationTypes.includes(mimeType)) {
    return 'presentation';
  } else if (spreadsheetTypes.includes(mimeType)) {
    return 'spreadsheet';
  } else if (documentTypes.includes(mimeType)) {
    return 'document';
  } else if (mimeType === 'application/pdf') {
    return 'pdf';
  }
  return 'generic';
};
