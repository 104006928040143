import { combineReducers } from 'redux';

import { addUnauthenticatedReset } from 'domains/authentication/utils';
import { Role } from 'entities/permissions/constants';
import { UserProfileAction, UserProfileType } from 'entities/user/actionTypes';
import { UserState } from 'entities/user/types';

const initialState: UserState = {
  isUpdatingProfile: false,
  profileUpdateError: null,
  isFetchingUserData: false,
  userDataFetchFulfilled: false,
  userData: {
    role: Role.UNAUTHENTICATED_ROLE,
    notifications: { email: true },
  },
};

const isUpdatingProfile = (state = initialState.isUpdatingProfile, action: UserProfileAction) => {
  switch (action.type) {
    case UserProfileType.UPDATE_PROFILE:
      return true;

    case UserProfileType.UPDATE_PROFILE_FULFILLED:
    case UserProfileType.UPDATE_PROFILE_REJECTED:
      return false;

    default:
      return state;
  }
};

const profileUpdateError = (state = initialState.profileUpdateError, action: UserProfileAction) => {
  switch (action.type) {
    case UserProfileType.UPDATE_PROFILE:
    case UserProfileType.UPDATE_PROFILE_FULFILLED:
    case UserProfileType.CLEAR_PROFILE_FAILURE:
      return null;

    case UserProfileType.UPDATE_PROFILE_REJECTED:
      return action.payload.message;

    default:
      return state;
  }
};

const isFetchingUserData = (state = initialState.isFetchingUserData, action: UserProfileAction) => {
  switch (action.type) {
    case UserProfileType.FETCH:
      return true;

    case UserProfileType.FETCH_FULFILLED:
    case UserProfileType.FETCH_REJECTED:
      return false;

    default:
      return state;
  }
};

const userData = (state = initialState.userData, action: UserProfileAction) => {
  switch (action.type) {
    case UserProfileType.FETCH_FULFILLED:
      return {
        ...action.payload.userData,
        imageUrl: `${action.payload.userData.imageUrl}?salt=${new Date().getTime()}`,
      };
    default:
      return state;
  }
};

export const userReducer = combineReducers({
  isUpdatingProfile,
  profileUpdateError: addUnauthenticatedReset(profileUpdateError, initialState.profileUpdateError),
  isFetchingUserData,
  userData: addUnauthenticatedReset(userData, initialState.userData),
});
