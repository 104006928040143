import { put, select } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { AdvancedTopicKnowledgeSearchCriteria } from 'domains/types';
import { getAdvancedKnowledgeCriteria } from 'entities/knowledge/selectors';
import { Knowledge } from 'entities/knowledge/types';
import { fetchKnowledgeFeedForTopicFailure, fetchKnowledgeFeedForTopicSuccess } from 'entities/knowledgeFeed/actions';
import { KnowledgeFeedFetchForTopicAction } from 'entities/knowledgeFeed/actionTypes';
import { getLatestFetchParams } from 'entities/knowledgeFeed/sagas/utils';
import { FetchError } from 'types';

export function* fetchKnowledgeFeedForTopicSaga(action: KnowledgeFeedFetchForTopicAction) {
  const { id } = action.payload;
  try {
    const advancedCriteria: AdvancedTopicKnowledgeSearchCriteria = yield select(getAdvancedKnowledgeCriteria);
    const searchCriteria = {
      ...advancedCriteria,
      ...action.payload,
    };
    const queryParams = getLatestFetchParams(searchCriteria);
    const payload: Knowledge[] = yield apiFetch(`topic/${id}/knowledge_feed`, {
      queryParams,
    });

    yield put(fetchKnowledgeFeedForTopicSuccess({ topicId: id, data: payload }, searchCriteria));
  } catch (error) {
    const fetchError = error as FetchError;
    const errorMeta = {
      searchCriteria: action.payload,
      logoutOnAuthError: true,
    };
    yield put(fetchKnowledgeFeedForTopicFailure({ topicId: id, error: fetchError }, errorMeta));
  }
}
