import { styled } from 'styled-components';

import { useTranslations } from 'domains/language/useTranslations';

const StyledLink = styled.a`
  color: inherit;
  text-decoration: none;
`;

export const ContactButton = () => {
  const { textDictionary } = useTranslations();

  return (
    <StyledLink href="mailto:intelligence@weforum.org" target="_blank" rel="noreferrer">
      {textDictionary['app.settings.feedback']}
    </StyledLink>
  );
};
