import { styled, ThemeProvider } from 'styled-components';

import { MembershipPromoVideo } from 'common/components/MembershipPromoVideo';
import { breakpoints } from 'common/styles';
import { useTranslations } from 'domains/language/useTranslations';
import { darkTheme } from 'domains/theme/constants/theme';

const StyledContainer = styled.div`
  position: relative;
  width: 350px;
  height: 300px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  border-radius: 10px;

  @media ${breakpoints.extraLarge} {
    width: 500px;
    height: 400px;
  }
`;

const StyledVideoPreview = styled(MembershipPromoVideo)`
  @media ${breakpoints.extraLarge} {
    height: 300px;
  }
`;

const StyledVideoText = styled.div`
  padding-left: 1rem;
  padding-top: 1rem;
`;

const StyledWatchNowText = styled.div`
  color: ${({ theme }) => theme.colors.textFade};
  text-transform: uppercase;
  margin-bottom: 0.8rem;
`;

const StyledVideoTitle = styled.div`
  color: ${({ theme }) => theme.colors.textBright};
  font-weight: bold;
  font-size: 1.5rem;
`;

export const UpgradeVideo = () => {
  const { textDictionary } = useTranslations();

  return (
    <ThemeProvider theme={darkTheme}>
      <StyledContainer>
        <StyledVideoPreview />
        <StyledVideoText>
          <StyledWatchNowText>{textDictionary['app.landing.watch_now']}</StyledWatchNowText>
          <StyledVideoTitle>{textDictionary['app.membership_features.title']}</StyledVideoTitle>
        </StyledVideoText>
      </StyledContainer>
    </ThemeProvider>
  );
};
