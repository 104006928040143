import { ComponentPropsWithRef, ReactNode } from 'react';
import { css, styled } from 'styled-components';

import {
  DeleteIcon,
  EditIcon,
  LockIcon,
  MagicWandIcon,
  PlayIcon,
  RightArrow,
  ShareIcon,
} from 'common/components/icons';
import { Spinner } from 'common/components/Spinner';
import { useTranslations } from 'domains/language/useTranslations';

type Icon = 'rightArrow' | 'lock' | 'play' | 'magicWand' | 'share' | 'edit' | 'delete';
type Variant = 'filled' | 'outlined' | 'neutral';

type UIButtonProps = StyledButtonProps &
  (
    | (ComponentPropsWithRef<'a'> & { as: 'a'; href?: string; target?: string })
    | (ComponentPropsWithRef<'button'> & { as?: 'button' })
  );

type StyledButtonProps = {
  error?: boolean;
  fullwidth?: boolean;
  icon?: Icon;
  isLoading?: boolean;
  success?: boolean;
  variant?: Variant;
};

const StyledButton = styled.button<StyledButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ icon }) => (icon ? '60px' : '40px')};
  padding: ${({ icon }) => (icon ? '0.5rem 0.5rem 0.5rem 1.5rem' : '0 1.8rem')};
  box-sizing: border-box;
  font-size: 1rem;
  cursor: pointer;
  border-radius: ${({ icon }) => (icon ? '30px' : '20px')};
  transition:
    background-color 0.3s,
    color 0.3s;
  border: none;
  text-decoration: none;
  width: ${({ fullwidth }) => (fullwidth ? '100%' : 'fit-content')};
  white-space: nowrap;
  background: none;

  ${({ variant = 'filled', error, success, isLoading, disabled }) => css`
    ${variant === 'filled'
      ? css`
          background-color: ${({ theme }) => theme.colors.blue};
          color: ${({ theme }) => theme.colors.white};

          &:hover {
            background-color: ${({ theme }) => theme.colors.blueDark};
          }
        `
      : ``}

    ${variant === 'neutral'
      ? css`
          padding: 10px 20px;
          background-color: ${({ theme }) => theme.colors.buttonNeutralBackground};
          color: ${({ theme }) => theme.colors.buttonNeutralText};

          &:hover {
            color: ${({ theme }) => theme.colors.buttonNeutralActiveText};
            background-color: ${({ theme }) => theme.colors.buttonNeutralActiveBackground};
          }
        `
      : ``}

    ${variant === 'outlined'
      ? css`
          color: ${({ theme }) => theme.colors.buttonOutlinedText};
          border-color: ${({ theme }) => theme.colors.buttonOutlinedText};
          border-width: 2px;
          border-style: solid;

          &:hover,
          &:focus-visible {
            color: ${({ theme }) => theme.colors.buttonOutlinedActiveText};
            border-color: ${({ theme }) => theme.colors.buttonOutlinedActiveBorder};
            background-color: ${({ theme }) => theme.colors.buttonOutlinedActiveBackground};
          }

          &:focus-visible {
            outline: auto;
          }
        `
      : ``}
    ${error
      ? css`
          background-color: ${({ theme }) => theme.colors.red};
          color: white;
        `
      : ``}
    ${success
      ? css`
          background-color: ${({ theme }) => theme.colors.green};
          color: white;

          &:focus-visible,
          &:hover {
            background-color: ${({ theme }) => theme.colors.green};
            color: white;
          }
        `
      : ``}
    ${disabled
      ? css`
          cursor: not-allowed;
          opacity: 0.5;
        `
      : ``}

    ${isLoading
      ? css`
          opacity: 0.6;
          cursor: wait;
        `
      : ``}
  `};
`;

const StyledSpinner = styled(Spinner)`
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.colors.buttonOutlinedText};
  margin-left: 1rem;
`;

const StyledIconContainer = styled.div<{ variant?: Variant }>`
  margin-left: 1rem;
  border-color: ${({ theme }) => theme.colors.buttonOutlinedText};
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  padding: 12px;
  display: flex;
  ${({ variant = 'filled', theme }) => css`
    ${variant === 'filled'
      ? css`
          border-color: ${theme.colors.white};
        `
      : ``}
    ${variant === 'outlined'
      ? css`
          border-color: ${theme.colors.buttonOutlinedText};
        `
      : ``}
    ${variant === 'neutral'
      ? css`
          border-color: ${theme.colors.buttonNeutralText};
        `
      : ``}
  `}
`;

const StyledPlayIcon = styled(PlayIcon)`
  // this is needed because the play icon itself is not square
  // by adding this to the width, we are centering it
  padding-left: 2px;
`;

const iconLookup: Readonly<{ [key in Icon]: ReactNode }> = {
  delete: <DeleteIcon width={16} height={16} />,
  edit: <EditIcon width={16} height={16} />,
  lock: <LockIcon width={14} height={14} />,
  magicWand: <MagicWandIcon width={14} height={14} />,
  play: <StyledPlayIcon width={15} height={17} />,
  rightArrow: <RightArrow width={14} height={14} />,
  share: <ShareIcon width={16} height={16} />,
};

export const UIButton = ({ children, ...rest }: UIButtonProps) => {
  const { textDictionary } = useTranslations();

  const { icon, isLoading, variant } = rest;

  return (
    <StyledButton {...rest}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {isLoading ? (
          <>
            {textDictionary['app.loading']}
            <StyledSpinner />
          </>
        ) : (
          <>
            {children}
            {icon === undefined || <StyledIconContainer variant={variant}> {iconLookup[icon]} </StyledIconContainer>}
          </>
        )}
      </div>
    </StyledButton>
  );
};
