import { AudioFile } from 'domains/audioPlayer/types';

export enum AudioPlayerTypes {
  SET = '[Audio player] - SET',
  PLAY = '[Audio player] - PLAY',
}

export type SetAudioPlayerAction = { meta?: AudioFile; type: AudioPlayerTypes.SET };
type PlayAudioPlayerAction = { meta: { isPlaying: boolean }; type: AudioPlayerTypes.PLAY };
export type AudioPlayerAction = SetAudioPlayerAction | PlayAudioPlayerAction;
