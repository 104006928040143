import { ReactNode } from 'react';

import { ModalFormError } from 'common/components/modals/ModalFormError';

type ModalErrorsProps = { errors: Record<string, { isActive?: boolean; label: ReactNode }> };

export const ModalErrors = ({ errors }: ModalErrorsProps) => (
  <>
    {Object.keys(errors).map((key) => {
      const { isActive, label } = errors[key];
      return (
        <ModalFormError key={key} isActive={isActive}>
          {label}
        </ModalFormError>
      );
    })}
  </>
);
