import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { styled } from 'styled-components';

import { breakpoints } from 'common/styles';
import { pushHistory } from 'common/utilities';
import { trackEvent } from 'domains/analytics/actions';
import { useTranslations } from 'domains/language/useTranslations';
import { covers } from 'domains/resources/covers';
import getStarted from 'domains/resources/data/getStarted.json';
import introduction from 'domains/resources/data/introduction.json';
import { SplashStyles } from 'domains/resources/SplashStyles';
import { VideoCard } from 'domains/resources/VideoCard';
import { VideoCover } from 'domains/resources/VideoCover';
import { setVisualMediaPlayer } from 'domains/visualMediaPlayer/actions';
import { MediaType } from 'domains/visualMediaPlayer/types';

const StyledIntroductionGrid = styled.div`
  display: grid;
  grid-gap: 3rem 2rem;
  grid-template-columns: 100%;
  margin-bottom: 2rem;

  @media ${breakpoints.large} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

const StyledIntroduction = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledIntroductionLabel = styled.div`
  color: ${({ theme }) => theme.colors.textFade};
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
`;

const StyledIntroductionTitle = styled.div`
  color: ${({ theme }) => theme.colors.textBright};
  font-size: 1.5rem;
  font-weight: bold;
  padding: 0.6rem 0 0.8rem;
  text-transform: capitalize;
`;

const StyledIntroductionDescription = styled.div`
  color: ${({ theme }) => theme.colors.textFade};
`;

const { StyledSplash, StyledCaption, StyledColumn } = SplashStyles;
const { image, length, name, url } = introduction;

export const Introduction = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { textDictionary } = useTranslations();

  const urlParams = new URLSearchParams(location.search);
  const videoUrlParam = urlParams.get('video');

  useEffect(() => {
    if (videoUrlParam !== name) return;
    dispatch(
      setVisualMediaPlayer({
        id: 'introduction',
        image,
        mediaType: MediaType.videoViaEmbeddedHtmlPlayer,
        name,
        url,
        onClose: () => pushHistory(location.pathname),
        splash: (
          <StyledSplash>
            <StyledColumn>
              <StyledCaption>{textDictionary['app.resources.video.next']}</StyledCaption>
              <VideoCard {...getStarted[0]} />
            </StyledColumn>
          </StyledSplash>
        ),
        useReactPlayer: true,
      }),
    );
  }, [dispatch, location.pathname, textDictionary, videoUrlParam]);

  const onClick = () => {
    dispatch(trackEvent({ name: 'Play Media', page: 'Resources', entityName: name, entityId: url }));
    pushHistory(`${location.pathname}?video=${name}`);
  };

  return (
    <StyledIntroductionGrid>
      <div onClick={onClick}>
        <VideoCover image={covers[image as keyof typeof covers]} videoLength={length} height={'350px'} />
      </div>
      <StyledIntroduction>
        <StyledIntroductionLabel>{textDictionary['app.resources.label.video']}</StyledIntroductionLabel>
        <StyledIntroductionTitle>Strategic Intelligence - Introduction</StyledIntroductionTitle>
        <StyledIntroductionDescription>
          Strategic Intelligence offers a hybrid model combining artificial intelligence and human expertise to help
          users anticipate and understand global transformations. Dive into this conversation with Cosima Piepenbrock,
          Head of Product at the World Economic Forum, discussing Strategic Intelligence as a tool for decision-makers
          in today&apos;s complex world.
        </StyledIntroductionDescription>
      </StyledIntroduction>
    </StyledIntroductionGrid>
  );
};
