import isEqual from 'lodash.isequal';
import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

/**
 * Performs the provided effect when the provided dependencies change.
 * Unlike `useEffect`, which only checks for reference equality in its dependencies, `useDeepEffect`
 * checks for value equality, meaning it will detect changes in nested properties.
 *
 * @param effect - The effect function to execute.
 * @param deps - An optional array of dependencies. If provided, the effect will
 * only run when there is a change in the values of these dependencies, not just their references.
 *
 * The value equality check is done using lodash's `isEqual` function. This means the effect will
 * run when any properties in the `deps` change.
 *
 * Note: Checking for value equality can be more expensive than checking for reference equality,
 * so use this hook judiciously to avoid potential performance issues.
 */

export const useDeepEffect = (effect: EffectCallback, deps?: DependencyList) => {
  const depsRef = useRef<DependencyList | undefined>(undefined);

  useEffect(() => {
    if (isEqual(depsRef.current, deps)) return;
    depsRef.current = deps;
    return effect();
  }, [deps, effect]);
};
