import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

import { SectionTitleUnderlined, StyledModalText, RightArrow, UIButton } from 'common/components';
import { breakpoints, createScrollbarStyle } from 'common/styles';
import { useTranslations } from 'domains/language/useTranslations';
import { activateModal } from 'domains/modal/actions';
import { FilterTags } from 'domains/monitor/filter/components/FilterTags';
import { getTopicsFilterSelectedIds } from 'domains/monitor/filter/selectors';
import { useRouterLink } from 'domains/routing/utils';
import { useMedia } from 'domains/viewport/useMedia';
import { getFollowedTopics, getFollowedTopicsIsPending } from 'entities/topics/selectors';

const StyledOuterContainer = styled.div`
  margin-top: 1rem;

  @media ${breakpoints.medium} {
    margin-top: 0;
  }
`;

const StyledRightArrow = styled(RightArrow)`
  width: 14px;
  height: 14px;
  margin-left: 10px;
`;

const StyledSeeAllButton = styled(UIButton)`
  margin-top: 10px;
`;

const StyledLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.blue};

  &:hover {
    color: ${({ theme }) => theme.colors.blueDark};
  }
`;

const StyledContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  ${createScrollbarStyle()};

  @media ${breakpoints.extraLarge} {
    max-height: 45vh;
  }
`;

export const Filter = () => {
  const dispatch = useDispatch();
  const isLarge = useMedia({ small: false, medium: false }, true);
  const items = useSelector(getFollowedTopics);
  const [numberOfItems, setNumberOfItems] = useState(10);
  const isPending = useSelector(getFollowedTopicsIsPending);
  const selectedItems = useSelector(getTopicsFilterSelectedIds);
  const { textDictionary, interpolate } = useTranslations();
  const link = useRouterLink({ to: 'discover' });

  const toggleItems = () => setNumberOfItems(numberOfItems < items.length ? items.length : 10);

  const displayItems = isLarge ? items.slice(0, numberOfItems) : items;

  const manageTopicHandle = () =>
    dispatch(
      activateModal({
        id: 'Manage topics',
      }),
    );

  const discoverInterpolation = (discover: string) => <StyledLink to={link}>{discover}</StyledLink>;

  const manageButton = (
    <UIButton onClick={manageTopicHandle} variant="neutral">
      {textDictionary['app.monitor.manage']}
      <StyledRightArrow />
    </UIButton>
  );

  if (items.length === 0 && !isPending) {
    return (
      <>
        <SectionTitleUnderlined>{textDictionary['mp.1.1.title']}</SectionTitleUnderlined>
        <StyledModalText>
          {interpolate(textDictionary['app.monitor.filter_empty'], {
            discover: discoverInterpolation,
          })}
        </StyledModalText>
      </>
    );
  }
  return (
    <StyledOuterContainer>
      <SectionTitleUnderlined rightElement={manageButton}>{textDictionary['app.filter.title']}</SectionTitleUnderlined>
      <StyledContainer>
        <FilterTags items={displayItems} selectedItems={selectedItems} />
        {isLarge && items.length > 10 && (
          <StyledSeeAllButton onClick={toggleItems} variant={'neutral'}>
            {textDictionary[numberOfItems < items.length ? 'app.topic.see_all' : 'app.monitor.hide_topics']}
          </StyledSeeAllButton>
        )}
      </StyledContainer>
    </StyledOuterContainer>
  );
};
