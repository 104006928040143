import { combineReducers } from 'redux';

import { searchCriteriaReducer as buildSearchCriteriaReducer } from 'common/reducer/searchCriteriaReducer';
import { findMatchingCriteriaAndOthers } from 'domains/publication/reducer';
import { addTopicSelectionReset, addUserOrMapChangeReset } from 'domains/topic/reducerHelpers';
import { TopicVideoActionTypes } from 'domains/videos/actionTypes';
import { FullTopicVideosSearchCriteria, TopicVideosPackage, TopicVideosState } from 'domains/videos/types';
import { VideoActionTypes, VideosAction } from 'entities/videos/actionTypes';

const initialState: TopicVideosState = {
  forTopic: {},
  searchCriteria: {
    searchTerm: '',
    publicationType: '',
    limit: 20,
    offset: 0,
  },
};

// Convenience function to capture the generic type args
const findVideosPackageAndOthers = (
  forTopic: TopicVideosState['forTopic'],
  searchCriteria: FullTopicVideosSearchCriteria,
) => findMatchingCriteriaAndOthers<TopicVideosPackage, FullTopicVideosSearchCriteria>(forTopic, searchCriteria);

const videosForTopicReducer = (state = initialState.forTopic, action: VideosAction) => {
  switch (action.type) {
    case VideoActionTypes.FETCH_FOR_TOPIC: {
      const { offset: _offset, limit: _limit, ...searchCriteria } = action.payload;
      const [matchingVideoPackage, others] = findVideosPackageAndOthers(state, searchCriteria);
      const newVideoPackage = {
        ...(matchingVideoPackage || {}),
        isFetching: true,
        hasNextPage: true,
        error: false,
        data: matchingVideoPackage?.data || [],
        searchCriteria,
      };
      return {
        ...state,
        [searchCriteria.id]: [...others, newVideoPackage],
      };
    }

    case VideoActionTypes.FETCH_FOR_TOPIC_SUCCESS: {
      const { data } = action.payload;
      const { offset, limit, ...searchCriteria } = action.meta;
      const [matchingVideoPackage, others] = findVideosPackageAndOthers(state, searchCriteria);

      const dataToKeep = matchingVideoPackage?.data.slice(0, offset) || [];
      const newVideoPackage = {
        ...(matchingVideoPackage || {}),
        isFetching: false,
        hasNextPage: data.length >= limit,
        error: false,
        data: dataToKeep.concat(data.map(({ id }) => id)),
        searchCriteria,
      };
      return {
        ...state,
        [searchCriteria.id]: [...others, newVideoPackage],
      };
    }

    case VideoActionTypes.FETCH_FOR_TOPIC_FAILURE: {
      const { searchCriteria } = action.meta;
      const [matchingVideoPackage, others] = findVideosPackageAndOthers(state, searchCriteria);
      const newVideoPackage = {
        ...(matchingVideoPackage || {}),
        isFetching: false,
        hasNextPage: false,
        error: true,
        data: matchingVideoPackage?.data || [],
        searchCriteria,
      };
      return {
        ...state,
        [searchCriteria.id]: [...others, newVideoPackage],
      };
    }

    default:
      return state;
  }
};

const searchCriteriaReducer = buildSearchCriteriaReducer(
  initialState.searchCriteria,
  TopicVideoActionTypes.APPLY_SEARCH_CRITERIA,
  TopicVideoActionTypes.CLEAR_SEARCH_CRITERIA,
);

export const videosReducer = combineReducers({
  forTopic: addUserOrMapChangeReset(videosForTopicReducer, initialState.forTopic),
  searchCriteria: addTopicSelectionReset(searchCriteriaReducer, initialState.searchCriteria),
});
