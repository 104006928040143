import { SVGProps } from 'react';

export const ArticleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 64 64" {...props}>
    <g id="Group_95331" data-name="Group 95331" transform="translate(12064 18488)">
      <g id="Group_95324" data-name="Group 95324">
        <g id="Rectangle_41727" data-name="Rectangle 41727" transform="translate(-12064 -18488)" fill="#2a63e9">
          <rect width="64" height="64" stroke="none" />
        </g>
        <g id="Group_95323" data-name="Group 95323" transform="translate(-12052.943 -18477)">
          <path
            id="Path_245113"
            data-name="Path 245113"
            d="M177.873,27.283,165.8,15.213A4.1,4.1,0,0,0,162.937,14h-21.66a4.141,4.141,0,0,0-4.079,4.079v34.36a4.069,4.069,0,0,0,4.079,4.079h33.731a4.069,4.069,0,0,0,4.079-4.079V30.149a4.1,4.1,0,0,0-1.213-2.866Zm-2.315.992H166.63a1.835,1.835,0,0,1-1.819-1.819V17.582Zm-.551,25.873H141.277a1.717,1.717,0,0,1-1.708-1.708V18.078a1.694,1.694,0,0,1,1.708-1.708h21.164V26.456a4.187,4.187,0,0,0,4.189,4.189h10.086V52.439a1.717,1.717,0,0,1-1.708,1.708Z"
            transform="translate(-137.198 -14)"
            fill="#fff"
          />
          <path
            id="Path_246200"
            data-name="Path 246200"
            d="M237.463,340.48h-9.569c-.291,0-.534.551-.534,1.213s.243,1.213.534,1.213h9.593c.291,0,.534-.551.534-1.213s-.267-1.213-.559-1.213Z"
            transform="translate(-222.974 -309.665)"
            fill="#fff"
          />
          <path
            id="Path_246201"
            data-name="Path 246201"
            d="M237.463,249.76h-9.569c-.291,0-.534.551-.534,1.213s.243,1.213.534,1.213h9.593c.291,0,.534-.551.534-1.213s-.267-1.213-.559-1.213Z"
            transform="translate(-222.974 -223.936)"
            fill="#fff"
          />
          <path
            id="Path_246202"
            data-name="Path 246202"
            d="M6.548,0H.584C.265,0,0,.551,0,1.213S.265,2.425.584,2.425H6.574c.318,0,.584-.551.584-1.213S6.866,0,6.548,0Z"
            transform="translate(6.811 26.083) rotate(90)"
            fill="#fff"
          />
          <path
            id="Path_246203"
            data-name="Path 246203"
            d="M6.548,0H.584C.265,0,0,.551,0,1.213S.265,2.425.584,2.425H6.574c.318,0,.584-.551.584-1.213S6.866,0,6.548,0Z"
            transform="translate(15.048 26.083) rotate(90)"
            fill="#fff"
          />
          <path
            id="Path_246205"
            data-name="Path 246205"
            d="M10.625,0H.947A1.115,1.115,0,0,0,0,1.213,1.115,1.115,0,0,0,.947,2.425h9.721a1.115,1.115,0,0,0,.947-1.213A1.146,1.146,0,0,0,10.625,0Z"
            transform="translate(27.879 33.103) rotate(180)"
            fill="#fff"
          />
          <path
            id="Path_246209"
            data-name="Path 246209"
            d="M22.436,0H.947A1.115,1.115,0,0,0,0,1.213,1.115,1.115,0,0,0,.947,2.425H22.479a1.115,1.115,0,0,0,.947-1.213A1.146,1.146,0,0,0,22.436,0Z"
            transform="translate(27.879 37.698) rotate(180)"
            fill="#fff"
          />
          <path
            id="Path_246208"
            data-name="Path 246208"
            d="M10.625,0H.947A1.115,1.115,0,0,0,0,1.213,1.115,1.115,0,0,0,.947,2.425h9.721a1.115,1.115,0,0,0,.947-1.213A1.146,1.146,0,0,0,10.625,0Z"
            transform="translate(27.879 28.508) rotate(180)"
            fill="#fff"
          />
        </g>
      </g>
    </g>{' '}
  </svg>
);
