import { SVGProps } from 'react';

export const CheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.998-1.004a1 1 0 0 1 .627.223.99.99 0 0 1 .156 1.406l-8 10a.997.997 0 0 1-1.406.156.999.999 0 0 1-.082-.074l-4-4a.99.99 0 0 1 0-1.414.99.99 0 0 1 1.414 0l3.21 3.21L11.22-.625c.2-.25.487-.379.779-.379z"
      fill="currentColor"
    />
  </svg>
);
