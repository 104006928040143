import { NotificationSettings, UserData } from 'entities/user/types';
import { ErrorMeta, FetchError } from 'types';

export enum UserProfileType {
  FETCH = 'entities/USER_FETCH_PROFILE',
  FETCH_FULFILLED = 'entities/USER_FETCH_PROFILE_SUCCESS',
  FETCH_REJECTED = 'USER_FETCH_PROFILE_FAILURE',
  UPDATE_PROFILE = 'entities/USER_UPDATE_PROFILE',
  UPDATE_PROFILE_FULFILLED = 'entities/USER_UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_REJECTED = 'entities/USER_UPDATE_PROFILE_FAILURE',
  CLEAR_PROFILE_FAILURE = 'entities/USER_CLEAR_PROFILE_FAILURE',
}

export type FetchProfileDefaultAction = { type: UserProfileType.FETCH };
export type FetchProfileFulfilledAction = { payload: { userData: UserData }; type: UserProfileType.FETCH_FULFILLED };

export type FetchProfileRejectedAction = {
  meta: ErrorMeta;
  payload: { message?: string };
  type: UserProfileType.FETCH_REJECTED;
};

type UpdateProfilePayload = {
  email?: string;
  firstName?: string;
  lastName?: string;
  notifications?: NotificationSettings;
  organization?: string;
};

export type UpdateProfileDefaultAction = { payload: UpdateProfilePayload; type: UserProfileType.UPDATE_PROFILE };

export type UpdateProfileFulfilledAction = {
  meta: { event: { userId: string } };
  payload: UpdateProfilePayload;
  type: UserProfileType.UPDATE_PROFILE_FULFILLED;
};

export type UpdateProfileRejectedAction = { payload: FetchError; type: UserProfileType.UPDATE_PROFILE_REJECTED };
export type ClearProfileFailureAction = { type: UserProfileType.CLEAR_PROFILE_FAILURE };

export type UserProfileAction =
  | FetchProfileDefaultAction
  | FetchProfileFulfilledAction
  | FetchProfileRejectedAction
  | UpdateProfileDefaultAction
  | UpdateProfileFulfilledAction
  | UpdateProfileRejectedAction
  | ClearProfileFailureAction;
