import { combineReducers } from 'redux';

import { searchCriteriaReducer as buildSearchCriteriaReducer } from 'common/reducer/searchCriteriaReducer';
import { TopicEventsType } from 'domains/events/actionTypes';
import { FullTopicEventSearchCriteria, TopicEventPackage, TopicEventState } from 'domains/events/types';
import { findMatchingCriteriaAndOthers } from 'domains/publication/reducer';
import { addTopicSelectionReset, addUserOrMapChangeReset } from 'domains/topic/reducerHelpers';
import { EventAction, EventType } from 'entities/events/actionTypes';

const initialState: TopicEventState = {
  forTopic: {},
  searchCriteria: { searchTerm: '', year: '', limit: 20, offset: 0 },
};

const eventsForTopicReducer = (state = initialState.forTopic, action: EventAction): TopicEventState['forTopic'] => {
  switch (action.type) {
    case EventType.FETCH_FOR_TOPIC: {
      const { offset: _offset, limit: _limit, ...searchCriteria } = action.payload;
      const [matchingEventPackage, others] = findMatchingCriteriaAndOthers<
        TopicEventPackage,
        FullTopicEventSearchCriteria
      >(state, searchCriteria);
      const newEventPackage = {
        ...(matchingEventPackage || {}),
        isFetching: true,
        hasNextPage: true,
        error: false,
        data: matchingEventPackage?.data || [],
        searchCriteria,
      };
      return {
        ...state,
        [searchCriteria.id]: [...others, newEventPackage],
      };
    }

    case EventType.FETCH_FOR_TOPIC_SUCCESS: {
      const { data } = action.payload;
      const { offset, limit, ...searchCriteria } = action.meta;
      const [matchingEventPackage, others] = findMatchingCriteriaAndOthers<
        TopicEventPackage,
        FullTopicEventSearchCriteria
      >(state, searchCriteria);

      const dataToKeep = matchingEventPackage?.data.slice(0, offset) || [];
      const newEventPackage = {
        ...(matchingEventPackage || {}),
        isFetching: false,
        hasNextPage: data.length >= limit,
        error: false,
        data: dataToKeep.concat(data.map(({ id }) => id)),
        searchCriteria,
      };
      return {
        ...state,
        [searchCriteria.id]: [...others, newEventPackage],
      };
    }

    case EventType.FETCH_FOR_TOPIC_FAILURE: {
      const { searchCriteria } = action.meta;
      const [matchingEventPackage, others] = findMatchingCriteriaAndOthers<
        TopicEventPackage,
        FullTopicEventSearchCriteria
      >(state, searchCriteria);
      const newEventPackage = {
        ...(matchingEventPackage || {}),
        isFetching: false,
        hasNextPage: false,
        error: true,
        data: matchingEventPackage?.data || [],
        searchCriteria,
      };
      return {
        ...state,
        [searchCriteria.id]: [...others, newEventPackage],
      };
    }

    default:
      return state;
  }
};

const searchCriteriaReducer = buildSearchCriteriaReducer(
  initialState.searchCriteria,
  TopicEventsType.APPLY_SEARCH_CRITERIA,
  TopicEventsType.CLEAR_SEARCH_CRITERIA,
);

export const eventsReducer = combineReducers({
  forTopic: addUserOrMapChangeReset(eventsForTopicReducer, initialState.forTopic),
  searchCriteria: addTopicSelectionReset(searchCriteriaReducer, initialState.searchCriteria),
});
