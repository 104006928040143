export enum UserActionType {
  UPLOAD_PROFILE_IMAGE = 'domains/UPLOAD_PROFILE_IMAGE',
  UPLOAD_PROFILE_IMAGE_PENDING = 'UPLOAD_PROFILE_IMAGE_PENDING',
  UPLOAD_PROFILE_IMAGE_FULFILLED = 'domains/UPLOAD_PROFILE_IMAGE_SUCCESS',
  UPLOAD_PROFILE_IMAGE_REJECTED = 'domains/UPLOAD_PROFILE_IMAGE_FAILURE',
  // UPDATE_LOGIN_REGISTER_PARAMS = "domains/UPDATE_LOGIN_REGISTER_PARAMS",
}

export type UploadProfileImageDefaultAction = { payload: Blob; type: UserActionType.UPLOAD_PROFILE_IMAGE };
export type UploadProfileImagePendingAction = { type: UserActionType.UPLOAD_PROFILE_IMAGE_PENDING };
export type UploadProfileImageSuccessAction = { type: UserActionType.UPLOAD_PROFILE_IMAGE_FULFILLED };

export type UploadProfileImageFailureAction = {
  payload: { error?: boolean };
  type: UserActionType.UPLOAD_PROFILE_IMAGE_REJECTED;
};

// export type UpdateLoginRegisterParamsAction = {
//   payload: Record<string, string> | null;
//   type: UserActionType.UPDATE_LOGIN_REGISTER_PARAMS;
// };

export type UploadProfileAction =
  | UploadProfileImageDefaultAction
  | UploadProfileImagePendingAction
  | UploadProfileImageSuccessAction
  | UploadProfileImageFailureAction;
