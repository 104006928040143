import { combineReducers } from 'redux';

import { searchCriteriaReducer as buildSearchCriteriaReducer } from 'common/reducer/searchCriteriaReducer';
import { TopicPublicationActionTypes } from 'domains/publication/actionTypes';
import {
  FullTopicPublicationSearchCriteria,
  TopicPublicationPackage,
  TopicPublicationState,
} from 'domains/publication/types';
import { addTopicSelectionReset, addUserOrMapChangeReset } from 'domains/topic/reducerHelpers';
import { PublicationAction, PublicationActionTypes } from 'entities/publication/actionTypes';

export const initialState: TopicPublicationState = {
  forTopic: {},
  searchCriteria: {
    searchTerm: '',
    language: '',
    publicationType: '',
    limit: 20,
    offset: 0,
  },
};

export const isSameSearchCriteria = (criteria1: Record<string, any>, criteria2: Record<string, any>) =>
  !Object.keys(criteria1).some(
    (key) => criteria1[key as keyof typeof criteria1] !== criteria2[key as keyof typeof criteria2],
  );

type CommonPackage<S> = { searchCriteria: S };

/**
 * All the topic knowledge feeds are stored as an object with topic ids as the
 * key and a list of "knowledge packages" as the value. i.e.
 * state: Record<string, TopicKnowledgeFeedPackage[]>, where:
 * TopicKnowledgeFeedPackage {
 *  data: string[],  // the ids
 *  searchCriteria: {language: "", searchTerm: "", ...}
 * }
 *
 * These packages represent the articles requested for a certain set of filters.
 * Different filtering requests result in another package stored in the list.
 *
 * findMatchingCriteriaAndOthers finds which of the stored packages matches the
 * currently requested search criteria and returns the other ones (for modifying
 * the matching one with more data and then fitting back into the list of others).
 *
 * @param state The current knowledge feed state per topic
 * @param searchCriteria The criteria we're currently displaying knowledge for
 * @returns [
 *  Any knowledge package that matches the given `searchCriteria`,
 *  the rest of the knowledge packages for that topic
 * ]
 */
export const findMatchingCriteriaAndOthers = <T extends CommonPackage<S>, S extends { id?: string }>(
  state: Record<string, T[]>,
  searchCriteria: S,
): [T | undefined, T[]] => {
  const packagesForTopic = (searchCriteria.id && state[searchCriteria.id]) || [];
  const others = [];
  let matchingPackage = undefined;
  for (const topicPackage of packagesForTopic) {
    const isSame = isSameSearchCriteria(topicPackage.searchCriteria, searchCriteria);
    if (isSame) {
      matchingPackage = topicPackage;
    } else {
      others.push(topicPackage);
    }
  }
  return [matchingPackage, others];
};

// Convenience function to capture the generic type args
const findPublicationsPackageAndOthers = (
  forTopic: TopicPublicationState['forTopic'],
  searchCriteria: FullTopicPublicationSearchCriteria,
) =>
  findMatchingCriteriaAndOthers<TopicPublicationPackage, FullTopicPublicationSearchCriteria>(forTopic, searchCriteria);

const publicationsForTopicReducer = (
  state = initialState.forTopic,
  action: PublicationAction,
): TopicPublicationState['forTopic'] => {
  switch (action.type) {
    case PublicationActionTypes.FETCH_FOR_TOPIC: {
      const { offset: _offset, limit: _limit, ...searchCriteria } = action.payload;
      const [matchingPublicationPackage, others] = findPublicationsPackageAndOthers(state, searchCriteria);
      const newPublicationPackage = {
        ...(matchingPublicationPackage || {}),
        isFetching: true,
        hasNextPage: true,
        error: false,
        data: matchingPublicationPackage?.data || [],
        searchCriteria,
      };
      return {
        ...state,
        [searchCriteria.id]: [...others, newPublicationPackage],
      };
    }

    case PublicationActionTypes.FETCH_FOR_TOPIC_SUCCESS: {
      const { data } = action.payload;
      const { offset, limit, ...searchCriteria } = action.meta;
      const [matchingPublicationPackage, others] = findPublicationsPackageAndOthers(state, searchCriteria);

      const dataToKeep = matchingPublicationPackage?.data.slice(0, offset) || [];
      const newPublicationPackage = {
        ...(matchingPublicationPackage || {}),
        isFetching: false,
        hasNextPage: data.length >= limit,
        error: false,
        data: dataToKeep.concat(data.map(({ id }) => id)),
        searchCriteria,
      };
      return {
        ...state,
        [searchCriteria.id]: [...others, newPublicationPackage],
      };
    }

    case PublicationActionTypes.FETCH_FOR_TOPIC_FAILURE: {
      const { searchCriteria } = action.meta;
      const [matchingPublicationPackage, others] = findPublicationsPackageAndOthers(state, searchCriteria);
      const newPublicationPackage = {
        ...(matchingPublicationPackage || {}),
        isFetching: false,
        hasNextPage: false,
        error: true,
        data: matchingPublicationPackage?.data || [],
        searchCriteria,
      };
      return {
        ...state,
        [searchCriteria.id]: [...others, newPublicationPackage],
      };
    }

    default:
      return state;
  }
};

const searchCriteriaReducer = buildSearchCriteriaReducer(
  initialState.searchCriteria,
  TopicPublicationActionTypes.APPLY_SEARCH_CRITERIA,
  TopicPublicationActionTypes.CLEAR_SEARCH_CRITERIA,
);

export const publicationReducer = combineReducers({
  forTopic: addUserOrMapChangeReset(publicationsForTopicReducer, initialState.forTopic),
  searchCriteria: addTopicSelectionReset(searchCriteriaReducer, initialState.searchCriteria),
});
