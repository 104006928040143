import { SVGProps } from 'react';

export const TwitterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      d="M48 9.114a19.661 19.661 0 01-5.656 1.55 9.864 9.864 0 004.33-5.448 19.727 19.727 0 01-6.254 2.39 9.831 9.831 0 00-7.188-3.11c-6.358 0-11.03 5.932-9.594 12.09-8.182-.41-15.438-4.33-20.296-10.288-2.58 4.426-1.338 10.216 3.046 13.148a9.806 9.806 0 01-4.458-1.232c-.108 4.562 3.162 8.83 7.898 9.78a9.87 9.87 0 01-4.448.168A9.855 9.855 0 0014.58 35 19.8 19.8 0 010 39.08a27.878 27.878 0 0015.096 4.424c18.284 0 28.614-15.442 27.99-29.292A20.049 20.049 0 0048 9.114z"
      fill="currentColor"
    />
  </svg>
);
