import { useDispatch } from 'react-redux';

import { useDeepEffect } from 'common/utilities/useDeepEffect';
import { trackEvent } from 'domains/analytics/actions';
import { TrackMeta } from 'domains/analytics/types';

export const useTrack = (meta: TrackMeta) => {
  const dispatch = useDispatch();

  useDeepEffect(() => {
    dispatch(trackEvent(meta));
  }, [dispatch, meta]);
};
