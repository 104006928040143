import { styled } from 'styled-components';

const StyledTickContainer = styled.div<{ isSelected: boolean }>`
  display: flex;
  padding-left: 0.19px;

  > svg > path {
    stroke: ${({ isSelected, theme }) => theme.colors[`translationIndicator${isSelected ? 'Primary' : 'Secondary'}`]};
  }
`;

const StyledDot = styled.div`
  background-color: ${({ theme }) => theme.colors.translationIndicatorPrimary};
  border-radius: 100%;
  border: solid 1px ${({ theme }) => theme.colors.translationIndicatorSecondary};
  height: 0.5rem;
  margin: 0 1.5px;
  width: 0.5rem;
`;

type CollectionLanguageChipIndicatorProps = StyledTickProps & { isComplete: boolean };

export const CollectionLanguageChipIndicator = ({ isComplete, ...rest }: CollectionLanguageChipIndicatorProps) =>
  isComplete ? <StyledTick {...rest} /> : <StyledDot />;

type StyledTickProps = { isSelected: boolean };

const StyledTick = (props: StyledTickProps) => (
  <StyledTickContainer {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="12.828" height="9.414" viewBox="0 0 12.828 9.414">
      <path
        id="Path_245704"
        data-name="Path 245704"
        d="M318,493.04l2.971,2.96L328,489"
        transform="translate(-316.586 -487.586)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  </StyledTickContainer>
);
