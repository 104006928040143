import { isSameSearchCriteria } from 'domains/publication/reducer';
import { RootState } from 'types';

const getEventsStateForTopic = (state: RootState, topicId: string) => {
  const { offset: _offset, limit: _limit, ...initialCriteria } = getEventsSearchCriteria(state);
  const searchCriteria = {
    ...initialCriteria,
    id: topicId,
  };
  const matchingEventPackage = state.domains.events.forTopic[topicId]?.find((eventPackage) =>
    isSameSearchCriteria(eventPackage.searchCriteria, searchCriteria),
  );
  return matchingEventPackage;
};

export const getEventsForTopic = (topicId: string, limit?: number) => (state: RootState) => {
  const events = (getEventsStateForTopic(state, topicId)?.data || []).map((id) => state.entities.events.byId[id]);
  return limit ? events.slice(0, limit) : events;
};

export const isFetchingEventsForTopic = (topicId: string) => (state: RootState) =>
  getEventsStateForTopic(state, topicId)?.isFetching || false;

export const hasNextEventsForTopicPage = (topicId: string) => (state: RootState) => {
  const eventState = getEventsStateForTopic(state, topicId);
  return eventState ? eventState.hasNextPage : true;
};

export const getEventsSearchCriteria = (state: RootState) => state.domains.events.searchCriteria;

export const getIsEventsFiltered = (state: RootState) => {
  const filter = state.domains.events.searchCriteria;
  return !!filter.year || !!filter.searchTerm;
};

export const getEventFilterCount = (state: RootState) => {
  const { year, searchTerm } = state.domains.events.searchCriteria;
  const count = [year, searchTerm].filter((item) => !!item).length;
  return count;
};
