import { MembershipEventsAction, MembershipEventsTypes } from 'domains/membershipEvents/actionTypes';
import { MembershipEventState, LabelState } from 'domains/membershipEvents/types';
import { addUserOrLanguageChangeReset } from 'entities/topics/reducerHelpers';

const initialState: MembershipEventState = {
  isPending: false,
  ids: [],
  data: {},
};

const getLabel = (isUpcoming: boolean, isRegistered: boolean): LabelState => {
  if (!isUpcoming) {
    return 'past';
  } else if (isRegistered) {
    return 'registered';
  }
  return 'register';
};

const reducer = (state = initialState, action: MembershipEventsAction): MembershipEventState => {
  switch (action.type) {
    case MembershipEventsTypes.FETCH: {
      return {
        ...state,
        isPending: true,
      };
    }
    case MembershipEventsTypes.FETCH_FULFILLED: {
      const data = { ...state.data };
      action.payload.events.forEach((event) => {
        data[event.id] = {
          ...event,
          label: getLabel(event.is_upcoming, event.is_registered),
        };
      });
      return {
        ...state,
        isPending: false,
        ids: action.payload.events.map((event) => event.id),
        data,
      };
    }
    case MembershipEventsTypes.FETCH_REJECTED: {
      return {
        ...state,
        isPending: false,
      };
    }
    case MembershipEventsTypes.FETCH_BY_ID_FULFILLED: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.id]: {
            ...state.data[action.meta.id],
            ...action.payload,
          },
        },
      };
    }
    default:
      return state;
  }
};

export const membershipEventsReducer = addUserOrLanguageChangeReset(reducer, initialState);
