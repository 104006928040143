import { put } from 'redux-saga/effects';

import { TOPICS_FOR_COLLECTION_LIMIT } from 'common/constants/limit';
import { apiFetch } from 'common/utilities';
import { fetchCollectionForEditingSuccess, fetchCollectionForEditingFailure } from 'domains/collection/actions';
import { FetchCollectionForEditingAction } from 'domains/collection/actionTypes';
import { mapCollectionEditResponse } from 'domains/collection/mappers/collectionResponseMappers';
import { CollectionEditResponse } from 'domains/collection/types/collectionResponses';
import { fetchTopicsForCollection } from 'entities/topics/actions';

export function* fetchCollectionForEditingSaga({ id }: FetchCollectionForEditingAction) {
  try {
    yield put(fetchTopicsForCollection({ id, limit: TOPICS_FOR_COLLECTION_LIMIT, offset: 0 }));
    const response: CollectionEditResponse = yield apiFetch(`collection/${id}/edit`);
    yield put(fetchCollectionForEditingSuccess(mapCollectionEditResponse(response)));
  } catch (error) {
    yield put(fetchCollectionForEditingFailure(id));
    console.error(`[${fetchCollectionForEditingSaga.name}] Failed to fetch collection with ID '${id}': ${error}`);
  }
}
