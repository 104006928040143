import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { ErrorMessage, StyledModalText, Link, Image, UIButton } from 'common/components';
import { openMapInvitation } from 'domains/createShareSettings/actions';
import { getIsOpenLinkPending, getOpenLinkError, getOpenLinkInvite } from 'domains/createShareSettings/selectors';
import { useTranslations } from 'domains/language/useTranslations';
import { ModalHeader } from 'domains/modal/components/ModalHeader';
import { getModalParams } from 'domains/modal/selectors';

const StyledImage = styled(Image)`
  width: 100%;
  height: 150px;
  border-radius: 5px;
  margin-bottom: 1rem;
`;

export const OpenLinkInvitationModal = () => {
  const dispatch = useDispatch();
  const { textDictionary, interpolate } = useTranslations();
  const { token } = useSelector(getModalParams);
  const invite = useSelector(getOpenLinkInvite);
  const isPending = useSelector(getIsOpenLinkPending);
  const error = useSelector(getOpenLinkError);

  useEffect(() => {
    if (token) {
      dispatch(openMapInvitation(token));
    }
  }, [dispatch, token]);

  const supportEmail = (supportEmail: string) => <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>;

  const errorText = interpolate(textDictionary['app.topic.follow.error_message'], {
    supportEmail,
  });

  const nameLabel = () => <strong>{invite && invite.topic_name}</strong>;
  const ownerLabel = () => <strong>{invite && invite.creator}</strong>;

  const inviteText = interpolate(textDictionary['app.map_share.invite_link.message'], {
    name: nameLabel,
    owner: ownerLabel,
  });

  return (
    <>
      <ModalHeader title={textDictionary['app.notification.map_share.title']} />
      {isPending && <StyledModalText>{textDictionary['app.loading']}</StyledModalText>}
      {invite && (
        <>
          <StyledImage src={invite.images?.thumbnail} alt={invite.creator} />
          <StyledModalText>{inviteText}</StyledModalText>
          <UIButton as="a" href={`/topics/${invite.topic_id}`} variant={'filled'} fullwidth>
            {textDictionary['app.topic.view_transformation_map']}
          </UIButton>
        </>
      )}
      <ErrorMessage isActive={error}>{errorText}</ErrorMessage>
    </>
  );
};
