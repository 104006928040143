import { ScreenSize } from 'common/styles/mediaQueries/types';

export const breakpointValues: Record<ScreenSize, number> = {
  small: 0,
  medium: 768,
  large: 1024,
  extraLarge: 1440,
  enormous: 1700,
};

export const breakpoints: Partial<Record<ScreenSize, string>> = {
  medium: `(min-width: ${breakpointValues.medium}px)`,
  large: `(min-width: ${breakpointValues.large}px)`,
  extraLarge: `(min-width: ${breakpointValues.extraLarge}px)`,
  enormous: `(min-width: ${breakpointValues.enormous}px)`,
};

export const maxWidth = {
  medium: `(max-width: ${breakpointValues.medium - 1}px)`,
  large: `(max-width: ${breakpointValues.large - 1}px)`,
  extraLarge: `(max-width: ${breakpointValues.extraLarge - 1}px)`,
  enormous: `(max-width: ${breakpointValues.enormous - 1}px)`,
};
