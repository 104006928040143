import { put } from 'redux-saga/effects';

import { imageUploadApiFetch } from 'common/utilities';
import { uploadProfileImageFailure, uploadProfileImagePending, uploadProfileImageSuccess } from 'domains/user/actions';
import { UploadProfileImageDefaultAction } from 'domains/user/actionTypes';
import { fetchProfile } from 'entities/user/actions';

export function* uploadProfileImageSaga(action: UploadProfileImageDefaultAction) {
  try {
    yield put(uploadProfileImagePending());
    yield imageUploadApiFetch(`profile/photo`, {
      image: action.payload,
    });

    yield put(uploadProfileImageSuccess());
    yield put(fetchProfile());
  } catch (error) {
    yield put(uploadProfileImageFailure({ error: !!error }));
  }
}
