import { memo, MouseEvent, PropsWithChildren, ReactNode } from 'react';
import { styled } from 'styled-components';

import { ImageFallback } from 'common/components/image/ImageFallback';
import { useImageLoader } from 'common/components/image/useImageLoader';

type StyledProps = { error?: boolean; isImageLoaded?: boolean; src?: string };

const StyledImage = styled.div<StyledProps>`
  background-image: ${({ src }) => `url("${src}")`};
  opacity: ${({ isImageLoaded }) => (isImageLoaded ? 1 : 0)};
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: opacity 0.3s ease;
`;

type ImageBackgroundProps = PropsWithChildren & {
  children?: ReactNode;
  className?: string;
  dataTestId?: string;
  onClick?: (event: MouseEvent) => void;
  renderPlaceholder?: () => ReactNode;
  src?: string;
};

export const ImageBackground = memo(
  ({ children, className, dataTestId, onClick, renderPlaceholder, src }: ImageBackgroundProps) => {
    const { internalSrc, useFallback, isImageLoaded } = useImageLoader({ src });

    if (useFallback) {
      return (
        <ImageFallback
          renderPlaceholder={renderPlaceholder}
          onClick={onClick}
          className={className}
          dataTestId={dataTestId}
        />
      );
    }
    return (
      <StyledImage
        src={internalSrc}
        isImageLoaded={isImageLoaded}
        className={className}
        onClick={onClick}
        data-test-id={dataTestId}
      >
        {children}
      </StyledImage>
    );
  },
);

ImageBackground.displayName = 'ImageBackground';
