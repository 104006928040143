import { PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css, styled } from 'styled-components';

import { CrossIcon, FullscreenIcon, MediaPlayer, PictureInPictureIcon, WebFrame } from 'common/components';
import { breakpoints, breakWordStyle, easing, styleRules } from 'common/styles';
import { getHexWithAlpha } from 'common/utilities';
import { useMediaQuery } from 'common/utilities/useMediaQuery';
import { useTranslations } from 'domains/language/useTranslations';
import { setVisualMediaPlayer } from 'domains/visualMediaPlayer/actions';
import { getVisualMediaPlayerFile } from 'domains/visualMediaPlayer/selectors';
import { MediaType } from 'domains/visualMediaPlayer/types';

const StyledContainer = styled.div<{ isOpen: boolean }>`
  width: 100%;
  height: 100%;

  @media ${breakpoints.medium} {
    position: relative;
    display: block;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      display: flex;
      flex-direction: column;
    `}
`;

const StyledMediaPlayer = styled.div<{ isMinimized?: boolean; isOpen: boolean }>`
  max-width: 100%;
  min-width: 100%;
  max-height: 90%;
  transform: translate3d(${({ isOpen }) => (isOpen ? 0 : -120)}%, 0, 0);
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  position: absolute;
  border-radius: 5px;
  z-index: 1000;
  bottom: ${styleRules.medium.spacing}rem;
  left: ${styleRules.medium.spacing}rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ isMinimized }) =>
    isMinimized
      ? css`
          max-width: 450px;
          min-width: 450px;
          transition:
            transform 0.3s ${easing.slightBounce},
            max-width 0.3s,
            min-width 0.3s,
            top 0.3s;
        `
      : css`
          max-width: calc(100% - (${styleRules.medium.spacing * 2}rem));
          min-width: calc(100% - (${styleRules.medium.spacing * 2}rem));
          top: ${styleRules.medium.spacing}rem;
          transition:
            opacity 0.3s,
            max-width 0.3s,
            min-width 0.3s,
            top 0.3s;
        `}
`;

const StyledPage = styled.div`
  transition: height 0.3s;
  width: 100%;
  height: 100%;
`;

const StyledContent = styled.div<{ isVideoViaDirectFileLink?: boolean }>`
  position: relative;
  max-height: calc(100% - 6rem);
  ${({ isVideoViaDirectFileLink }) =>
    isVideoViaDirectFileLink
      ? css``
      : css`
          height: 100vh;
        `};
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: rgba(0, 0, 0, 0.8);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
`;

const iconStyle = css`
  padding: 10px;
  width: 14px;
  height: 14px;
  color: white;
  cursor: pointer;
`;

const StyledMinimizeIcon = styled(PictureInPictureIcon)`
  ${iconStyle};
`;

const StyledFullscreenIcon = styled(FullscreenIcon)`
  ${iconStyle};
`;

const StyledCloseIcon = styled(CrossIcon)`
  ${iconStyle};
`;

const StyledVideo = styled(MediaPlayer)`
  border: none;
  overflow: hidden;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const StyledOverlay = styled.div<{ isOpen: boolean }>`
  transition:
    background-color 0.3s ease,
    transform 0s ease ${({ isOpen }) => (isOpen ? 0 : 0.3)}s;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translate3d(${({ isOpen }) => (isOpen ? 0 : '100%')}, 0, 0);
  background-color: ${({ isOpen, theme }) => (isOpen ? getHexWithAlpha(theme.colors.fillPrimary, 0.4) : 'transparent')};
  z-index: 1000;
`;

const StyledFrame = styled(WebFrame)`
  border: none;
  overflow: hidden;
  height: 100%;
  padding-top: 0;
  margin: 0;
`;

const StyledPopover = styled.div`
  box-sizing: border-box;
  transition:
    opacity 0.3s,
    bottom 0.3s;
  opacity: 0;
  position: absolute;
  padding: 10px;
  text-align: center;
  left: -30px;
  width: 100px;
  bottom: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 6px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid ${({ theme }) => theme.borders.normal};
  z-index: 100;
  color: white;

  ${breakWordStyle}
`;

const StyledIconContainer = styled.div`
  position: relative;

  &:hover {
    ${StyledPopover} {
      opacity: 1;
      bottom: calc(100% + 2px);
    }
  }
`;

const StyledSplash = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.inputBackground};
  box-sizing: border-box;
  display: flex;
  height: calc(100% - 56px - 8px - 32px);
  inset: 38px 0 0 0;
  justify-content: center;
  padding: 4rem;
  position: absolute;
  width: 100%;

  @media ${breakpoints.medium} {
    height: calc(100% - 56px - 8px);
  }
`;

export const VisualMediaPlayerProvider = ({ children }: PropsWithChildren) => {
  const dispatch = useDispatch();
  const isResizable = useMediaQuery('(min-width: 768px) and (min-height: 768px)');
  const { textDictionary } = useTranslations();
  const mediaFile = useSelector(getVisualMediaPlayerFile);
  const [isOpen, setIsOpen] = useState(!!mediaFile);
  const [showSplash, setShowSplash] = useState<'full' | 'empty' | 'none'>('none');
  const [isMinimized, setIsMinimized] = useState(false);

  const isVideoViaDirectFileLink = mediaFile?.mediaType !== MediaType.knowledgeData;

  useEffect(() => {
    if (mediaFile?.id) {
      setShowSplash('empty');
      setTimeout(() => setShowSplash('none'), 200);
    }
  }, [mediaFile?.id]);

  useEffect(() => {
    if (isOpen !== !!mediaFile) {
      window.addEventListener('keyup', handleKeyPress);
      setIsOpen(!!mediaFile);
      setIsMinimized(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaFile]);

  const handleKeyPress = (event: KeyboardEvent) => (event.code === 'Escape' ? closePlayer() : '');

  const closePlayer = () => {
    setShowSplash('none');
    dispatch(setVisualMediaPlayer(undefined));
    mediaFile?.onClose?.();
    window.removeEventListener('keyup', handleKeyPress);
    setIsOpen(false);
  };

  const toggleSize = () => setIsMinimized(!isMinimized);

  return (
    <StyledContainer isOpen={isOpen}>
      <StyledPage>{children}</StyledPage>
      <StyledOverlay isOpen={isOpen && !isMinimized} />
      <StyledMediaPlayer isOpen={isOpen} isMinimized={isMinimized}>
        {mediaFile?.url && (
          <StyledContent isVideoViaDirectFileLink={isVideoViaDirectFileLink} data-test-id="visual-media-player">
            <StyledButtonContainer>
              {isVideoViaDirectFileLink && isResizable && (
                <StyledIconContainer>
                  {isMinimized ? (
                    <StyledFullscreenIcon onClick={toggleSize} />
                  ) : (
                    <StyledMinimizeIcon onClick={toggleSize} />
                  )}
                  <StyledPopover>
                    {textDictionary[isMinimized ? 'app.fullscreen.title' : 'app.fullscreen_exit.title']}
                  </StyledPopover>
                </StyledIconContainer>
              )}
              <StyledIconContainer>
                <StyledCloseIcon onClick={closePlayer} />
                <StyledPopover>{textDictionary['app.dialog.close']}</StyledPopover>
              </StyledIconContainer>
            </StyledButtonContainer>
            {isVideoViaDirectFileLink && (
              <StyledVideo
                url={mediaFile.url}
                title={mediaFile.name}
                isVideo={mediaFile?.mediaType === MediaType.videoViaDirectFileLink}
                onEnded={() => setShowSplash('full')}
                onPauseOrPlay={() => setShowSplash('none')}
                useReactPlayer={mediaFile.useReactPlayer}
              />
            )}
            {!isVideoViaDirectFileLink && <StyledFrame url={mediaFile.url} title={mediaFile.name} />}
            {showSplash === 'none' || !mediaFile.splash || (
              <StyledSplash>{showSplash === 'full' && mediaFile.splash}</StyledSplash>
            )}
          </StyledContent>
        )}
      </StyledMediaPlayer>
    </StyledContainer>
  );
};
