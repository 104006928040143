import { put, select } from 'redux-saga/effects';

import { TopicKnowledgeDataApplySearchCriteriaAction } from 'domains/knowledgeData/actionTypes';
import { getKnowledgeDataSearchCriteria } from 'domains/knowledgeData/selectors';
import { TopicKnowledgeDataSearchCriteria } from 'domains/knowledgeData/types';
import { getAdvancedKnowledgeCriteria } from 'domains/publication/selectors';
import { AdvancedTopicKnowledgeSearchCriteria } from 'domains/types';
import { fetchKnowledgeDataForTopic } from 'entities/knowledgeData/actions';

export function* applyKnowledgeDataSearchCriteriaSaga(action: TopicKnowledgeDataApplySearchCriteriaAction) {
  const searchCriteria: TopicKnowledgeDataSearchCriteria = yield select(getKnowledgeDataSearchCriteria);
  const advancedCriteria: AdvancedTopicKnowledgeSearchCriteria = yield select(getAdvancedKnowledgeCriteria);
  const id = action.payload?.topicId ?? advancedCriteria.id;
  if (!id) {
    return;
  }
  const payload = { ...advancedCriteria, ...searchCriteria, id };
  yield put(fetchKnowledgeDataForTopic(payload));
}
