import { SVGProps } from 'react';

import { constantColors } from 'domains/theme/constants/colors';

export const StopwatchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      d="M24.873 30.391a2.846 2.846 0 002.087-1.167l7.488-10.355a.8.8 0 00-1.117-1.117l-10.357 7.492a2.85 2.85 0 001.899 5.147zm-1.469-2.939a1.248 1.248 0 01.51-.913l6.318-4.57-4.567 6.315a1.253 1.253 0 01-2.259-.832h-.002zM17 11.2h-4a.8.8 0 000 1.6h4a.8.8 0 000-1.6zM17 16.8H9a.8.8 0 000 1.6h8a.8.8 0 000-1.6zM17 22.4H5A.8.8 0 005 24h12a.8.8 0 100-1.6zM17 28H1.8a.8.8 0 100 1.6H17a.8.8 0 000-1.6zM17 33.6H6.6a.8.8 0 100 1.6H17a.8.8 0 000-1.6zM17 39.2h-5.6a.8.8 0 000 1.6H17a.8.8 0 000-1.6z"
      fill={constantColors.blue}
    />
    <path
      d="M42.793 15.652c-.008-.016-.008-.034-.017-.052-.008-.018-.025-.025-.035-.04a21.756 21.756 0 00-2.85-3.847l1.718-1.718 1.131 1.132 3.392-3.395-3.392-3.391-3.394 3.391 1.132 1.132-1.718 1.718a21.728 21.728 0 00-3.848-2.853c-.015-.01-.024-.026-.04-.035-.016-.01-.034-.009-.052-.018A21.448 21.448 0 0026.6 4.953V4h1.2a2 2 0 000-4h-7.2a2 2 0 100 4h1.2v.925c-.867.091-1.729.234-2.579.428a.8.8 0 00.357 1.558 20.345 20.345 0 012.225-.38l.858-.067c.26-.02.504-.035.74-.046V8a.8.8 0 101.6 0V6.424c2.921.133 5.778.91 8.364 2.276l-.788 1.365a.8.8 0 101.385.8l.786-1.362a20.156 20.156 0 016.217 6.218l-1.362.786a.8.8 0 00.801 1.386l1.364-.789a19.855 19.855 0 012.279 8.495H42.47a.8.8 0 000 1.6h1.58a19.857 19.857 0 01-2.281 8.495l-1.364-.79a.798.798 0 00-1.193.589.8.8 0 00.393.797l1.363.786a20.155 20.155 0 01-6.218 6.219l-.786-1.362a.798.798 0 00-1.48.182.8.8 0 00.094.615l.791 1.368a19.845 19.845 0 01-8.365 2.276V44.8a.8.8 0 00-1.6 0v1.583a20.18 20.18 0 01-3.821-.494.8.8 0 10-.357 1.559c1.588.366 3.214.551 4.844.552.04 0 .077 0 .116-.003.006 0 .01.003.014.003.006 0 .012-.004.019-.004a21.46 21.46 0 0010.575-2.859.677.677 0 00.075-.031c.016-.01.025-.025.04-.035a21.756 21.756 0 007.818-7.808.747.747 0 00.048-.063c.01-.017.01-.034.017-.052a21.516 21.516 0 000-21.496h.001zm-.055-9.05l1.131 1.13-1.131 1.133-1.132-1.132 1.132-1.131zM23.4 4.817V2.4h-2.8a.4.4 0 110-.8h7.2a.4.4 0 010 .8H25v2.417c-.266-.011-.533-.017-.8-.017h-.13c-.131 0-.26.009-.393.011-.094.003-.18.002-.277.006z"
      fill="currentColor"
    />
  </svg>
);
