import { Key, useEffect, useRef, useState } from 'react';
import { styled } from 'styled-components';

import { InfinityLoader, Spinner } from 'common/components';
import { UIInput } from 'common/components/form/UIInput';
import { createScrollbarStyle } from 'common/styles';
import { SearchDropdownCard } from 'domains/collection/components/edit/parts/SearchDropdownCard';
import { useTranslations } from 'domains/language/useTranslations';

const StyledContainer = styled.div`
  position: relative;
`;

const StyledResultsContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.searchDropdownBackground};
  border-radius: 5px;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.2);
  max-height: 28.25rem;
  overflow-y: auto;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  position: absolute;
  top: 54px;
  width: calc(100% - 1.75rem);
  z-index: 1;

  ${createScrollbarStyle()}

  * {
    border-radius: 5px;
  }
`;

const StyledDropdownCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.textBright};
`;

const StyledSpinner = styled(Spinner)`
  color: ${({ theme }) => theme.colors.textBright};
  display: block;
  margin: 3rem auto;
`;

const StyledNoResultsText = styled.div`
  color: ${({ theme }) => theme.colors.textBright};
`;

type SearchDropdownProps = {
  children: (Parameters<typeof SearchDropdownCard>[0] & { key: Key })[];
  clearResults: () => void;
  fetchResults: (search: string, offset: number) => void;
  isLoading: boolean;
  nextPageMayExist: boolean;
  placeholder?: string;
};

export const SearchDropdown = ({
  children,
  clearResults,
  fetchResults,
  isLoading,
  nextPageMayExist,
  placeholder,
}: SearchDropdownProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const termLengthRef = useRef<number>(0);
  const [show, setShow] = useState<boolean>(false);
  const [term, setTerm] = useState<string>('');
  const [timeoutId, setTimeoutId] = useState<number | undefined>(undefined);
  const { textDictionary } = useTranslations();

  useEffect(() => {
    const listener = (event: MouseEvent) =>
      containerRef.current === null
        ? undefined
        : setShow(termLengthRef.current > 0 && containerRef.current.contains(event.target as Node | null));
    document.addEventListener('mousedown', listener);
    return () => document.removeEventListener('mousedown', listener);
  }, []);

  const clear = () => {
    setTerm('');
    termLengthRef.current = 0;
    setShow(false);
  };

  const onChange = (value: string) => {
    setTerm(value);
    termLengthRef.current = value.length;
    // TODO prevent import of @types/node globals (e.g. setTimeout)
    window.clearTimeout(timeoutId);

    if (value.length === 0) {
      setShow(false);
      return;
    }

    setTimeoutId(
      window.setTimeout(() => {
        clearResults();
        fetchResults(value, 0);
        setShow(true);
      }, 500),
    );
  };

  return (
    <StyledContainer ref={containerRef}>
      <UIInput clear={clear} isPending={isLoading} onChange={onChange} placeholder={placeholder} value={term} />
      {show && (
        <StyledResultsContainer>
          {children.length > 0 ? (
            <InfinityLoader
              isPending={isLoading}
              loadNextPage={() => fetchResults(term, children.length)}
              hasNextPage={nextPageMayExist}
            >
              <StyledDropdownCardsContainer>
                {children.map(({ key, ...rest }) => (
                  <SearchDropdownCard {...rest} key={key} />
                ))}
              </StyledDropdownCardsContainer>
            </InfinityLoader>
          ) : isLoading ? (
            <StyledSpinner />
          ) : (
            <StyledNoResultsText>{textDictionary['app.search.results.no_results']}</StyledNoResultsText>
          )}
        </StyledResultsContainer>
      )}
    </StyledContainer>
  );
};
