import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslations } from 'domains/language/useTranslations';
import { applyPublicationSearchCriteria, clearPublicationSearchCriteria } from 'domains/publication/actions';
import { TopicPublicationApplySearchCriteriaPayload } from 'domains/publication/actionTypes';
import { getPublicationSearchCriteria } from 'domains/publication/selectors';
import { TopicFilterModal } from 'domains/topicSearchFilter/components/TopicFilterModal';
import { getTopicFilters } from 'domains/topicSearchFilter/selectors';

export const TopicPublicationFilterModal = () => {
  const dispatch = useDispatch();
  const { textDictionary } = useTranslations();
  const searchCriteria = useSelector(getPublicationSearchCriteria);
  const filters = useSelector(getTopicFilters('publications'));

  useEffect(() => {
    dispatch(applyPublicationSearchCriteria());
  }, [dispatch]);

  const handleApplyFilter = (payload: TopicPublicationApplySearchCriteriaPayload) => () =>
    dispatch(applyPublicationSearchCriteria(payload));

  const handleClear = () => dispatch(clearPublicationSearchCriteria());

  return (
    <TopicFilterModal
      title={textDictionary['app.publications.filter.title']}
      fields={filters}
      searchCriteria={searchCriteria as any}
      handleApplyFilter={handleApplyFilter}
      handleClear={handleClear}
    />
  );
};
