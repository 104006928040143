import { SectionTitleUnderlined } from 'common/components';
import { TopicSlider } from 'domains/topic/components/TopicSlider';
import { Topic } from 'entities/topics/types';

type TopicSectionProps = {
  isPending?: boolean;
  seeAllLink?: JSX.Element;
  title: string | JSX.Element;
  titleDataTestId?: string;
  topics: Topic[];
};

export const TopicSection = ({ title, topics, isPending = false, titleDataTestId, seeAllLink }: TopicSectionProps) => (
  <>
    <SectionTitleUnderlined isLoading={isPending} dataTestId={titleDataTestId} rightElement={seeAllLink}>
      {title}
    </SectionTitleUnderlined>
    <TopicSlider topics={topics} />
  </>
);
