import { darkBorders, lightBorders } from 'domains/theme/constants/border';
import { dark, light } from 'domains/theme/constants/colors';
import { darkShadows, lightShadows } from 'domains/theme/constants/shadows';

export const lightTheme = {
  name: 'light',
  shadows: lightShadows,
  borders: lightBorders,
  colors: light,
};

export const darkTheme = {
  name: 'dark',
  shadows: darkShadows,
  borders: darkBorders,
  colors: dark,
};

export type ThemeType = typeof lightTheme;
