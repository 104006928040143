import { useEffect, useCallback, useRef, PropsWithChildren } from 'react';
import { css, styled } from 'styled-components';

const StyledWrapper = styled.div<{ isActive: boolean }>`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 100%;
  z-index: 9997;
  transition:
    all 0.375s ease,
    left 0s 1s ease;
  overflow: hidden;
  top: 80px;

  ${({ isActive }) =>
    isActive &&
    css`
      transition:
        all 0.375s ease,
        left 0s ease;
      left: 0;
    `};
`;

const StyledContentWrapper = styled.div`
  display: flex;
  -webkit-overflow-scrolling: touch;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 1;
  align-items: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
`;

const StyledOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
`;

type OverlayProps = PropsWithChildren & {
  className?: string;
  deactivate?: () => void;
  isActive: boolean;
  overlayGroup?: string;
};

export const Overlay = ({ children, className, deactivate, isActive, overlayGroup }: OverlayProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleDocumentClick = useCallback(
    (event: any) => {
      if (
        (wrapperRef.current && wrapperRef.current.contains(event.target)) ||
        (event.target.hasAttribute('data-overlay-group') &&
          event.target.getAttribute('data-overlay-group') === overlayGroup) ||
        event.target.closest(`[data-overlay-group="${overlayGroup}"]`)
      ) {
        return;
      }
      if (deactivate) {
        deactivate();
      }
      event.preventDefault();
    },
    [deactivate, overlayGroup],
  );

  useEffect(() => {
    if (isActive) {
      document.addEventListener('click', handleDocumentClick, true);
    } else {
      document.removeEventListener('click', handleDocumentClick, true);
    }

    return () => {
      document.removeEventListener('click', handleDocumentClick, true);
    };
  }, [isActive, handleDocumentClick]);

  const handleOverlayClick = () => {
    if (deactivate) {
      deactivate();
    }
  };

  return (
    <StyledWrapper className={className} isActive={isActive}>
      <StyledContentWrapper ref={wrapperRef}>
        <StyledOverlay onClick={handleOverlayClick} />
        {children}
      </StyledContentWrapper>
    </StyledWrapper>
  );
};
