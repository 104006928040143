type AlphaValues = 0 | 0.1 | 0.2 | 0.3 | 0.4 | 0.5 | 0.6 | 0.7 | 0.8 | 0.9 | 1;

const regex = /^#([0-9a-f]{6}|[0-9a-f]{3})$/i;

export const getHexWithAlpha = (color: string, alpha: AlphaValues) => {
  if (!color.match(regex)) {
    throw new Error('Color input not a hex color');
  }
  const hexAlpha = Math.round(alpha * 255).toString(16);
  return `${color}${hexAlpha}`;
};
