import { PropsWithChildren } from 'react';

import * as Styled from 'domains/navigation/components/TopBarItem.styled';

type TopBarItemProps = PropsWithChildren & { className?: string; onClick?: () => void; opacity?: number; to?: string };

export const TopBarItem = ({ children, className, onClick, opacity, to }: TopBarItemProps) => (
  <Container to={to} opacity={opacity} onClick={onClick}>
    <Styled.Item className={className}>{children}</Styled.Item>
  </Container>
);

const Container = ({ to, ...props }: TopBarItemProps) =>
  to ? <Styled.Link to={to} {...props} /> : <Styled.Container {...props} />;
