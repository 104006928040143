import { pushHistory, useInternalLink } from 'common/utilities';
import { NavigationAction } from 'domains/routing/types';

const getOptionalIdPath = (path: string, id?: string) => (id ? `${path}/${id}` : path);

const getRoute = (action: NavigationAction) => {
  switch (action.to) {
    case 'landing':
      return '/';
    case 'discover':
      return getOptionalIdPath('/topics', action.params?.id);
    case 'latest-knowledge':
      return getOptionalIdPath('/monitor/latest-knowledge', action.params?.knowledgeId);
    case 'emerging-themes':
      return getOptionalIdPath('/monitor/emerging-themes', action.params?.clusterId);
    case 'dig-deeper':
      return getOptionalIdPath(
        `/monitor/emerging-themes/${action.params.clusterId}/dig-deeper`,
        action.params.knowledgeId,
      );
    case 'create': {
      if (action.params === undefined) return '/create';
      if ('id' in action.params) return `/create/${action.params.id}?editing=true`;
      if (action.params.mode === 'map-new') return '/create/new';
      return `/create?mode=${action.params.mode}`;
    }
    case 'collection':
      return `/collection/${action.id}`;
    case 'collection-create':
      return '/collection/new';
    case 'collection-edit':
      return `/collection/${action.id}/edit`;
    case 'bookmarks':
      return getOptionalIdPath('/bookmarks', action.params?.id);
    case 'membership-events':
      return getOptionalIdPath('/membership-events', action.params?.id);
    case 'search': {
      if (action.subPath) {
        getOptionalIdPath(`/search/${action.subPath}/`, action.params?.knowledgeId);
      }
      return '/search';
    }
    case 'monitor':
    case 'terms-and-conditions':
    case 'faq':
    case 'about':
    case 'resources':
      return `/${action.to}`;
    default: {
      const message = `Undefined route from getRoute(): ${action}`;
      if (process.env.NODE_ENV !== 'production') {
        throw new Error(message);
      } else {
        console.error(message);
      }
    }
  }
};

export const useRouterLink = (action: NavigationAction) => useInternalLink(getRoute(action) ?? '/');

export const useRouterNavigation = (action: NavigationAction) => {
  const link = useRouterLink(action);
  return () => pushHistory(link);
};
