import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { inviteMapSharingSuccess, inviteMapSharingFailure } from 'domains/createShareSettings/actions';
import { MapInviteDefaultAction } from 'domains/createShareSettings/actionTypes';
import { InviteUser } from 'domains/createShareSettings/types';

export function* createInvitationSaga(action: MapInviteDefaultAction) {
  try {
    const { invitations, mapId } = action.meta;
    const body = {
      shared_users: invitations.map((item) => ({
        email: item.email,
        permission: item.permission,
      })),
      topic_id: mapId,
    };
    const response: InviteUser[] = yield apiFetch('mapshare', {
      method: 'POST',
      body,
    });
    yield put(inviteMapSharingSuccess(response, action.meta));
  } catch (error) {
    yield put(inviteMapSharingFailure(action.meta));
  }
}
