import { Link } from 'react-router-dom';
import { css, styled } from 'styled-components';

const itemStyle = css`
  display: block;
  margin-bottom: 20px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.textFade};

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledLink = styled(Link)`
  ${itemStyle}
`;

const StyledAnchor = styled.a`
  ${itemStyle}
`;

type FooterListItemProps = { href?: string; label: string; to?: string };

export const FooterListItem = ({ href, label, to }: FooterListItemProps) => {
  if (to) {
    return <StyledLink to={to}>{label}</StyledLink>;
  }
  return (
    <StyledAnchor href={href} target="_blank">
      {label}
    </StyledAnchor>
  );
};
