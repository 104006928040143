import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { fetchKnowledgeByIdRejected, fetchKnowledgeByIdSuccess } from 'entities/knowledge/actions';
import { KnowledgeItemAction } from 'entities/knowledge/actionTypes';
import { Knowledge } from 'entities/knowledge/types';

export function* knowledgeItemSaga(action: KnowledgeItemAction) {
  try {
    const payload: Knowledge = yield apiFetch(`knowledge/${action.meta.id}`);
    yield put(fetchKnowledgeByIdSuccess(action.meta, payload));
  } catch (e) {
    yield put(fetchKnowledgeByIdRejected({ ...action.meta, logoutOnAuthError: true }));
  }
}
