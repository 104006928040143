import { put, select } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { fetchFollowedTopics, removeMapSuccess, removeMapFailure } from 'entities/topics/actions';
import { RemoveMapDefaultAction } from 'entities/topics/actionTypes';
import { getAllTopicsCount, getFollowedTopicsLimit, getFollowedTopicsOffset } from 'entities/topics/selectors';
import { fetchTopicsMetadata, fetchTopicsMetadataSuccess } from 'entities/topicsMetadata/actions';
import { getCustomTopicCount } from 'entities/topicsMetadata/selectors';

export function* removeMapSaga(action: RemoveMapDefaultAction) {
  try {
    yield apiFetch(`map/${action.meta.id}`, { method: 'DELETE' });
    yield put(removeMapSuccess(action.meta));
    const limit: number = yield select(getFollowedTopicsLimit);
    const offset: number = yield select(getFollowedTopicsOffset);
    yield put(fetchFollowedTopics({ limit, offset }));
    const allTopicsCount: number = yield select(getAllTopicsCount);
    const customTopicsCount: number = yield select(getCustomTopicCount);
    yield put(fetchTopicsMetadataSuccess({ allTopicsCount, customTopicsCount: customTopicsCount - 1 }));
    yield put(fetchTopicsMetadata());
  } catch (error) {
    yield put(removeMapFailure());
    console.error(`[removeMapSaga] Failed to remove map: ${error}`);
  }
}
