import {
  UploadProfileImageDefaultAction,
  UploadProfileImageFailureAction,
  UploadProfileImagePendingAction,
  UploadProfileImageSuccessAction,
  UserActionType,
} from 'domains/user/actionTypes';

export const uploadProfileImage = (
  payload: UploadProfileImageDefaultAction['payload'],
): UploadProfileImageDefaultAction => ({
  type: UserActionType.UPLOAD_PROFILE_IMAGE,
  payload,
});

export const uploadProfileImagePending = (): UploadProfileImagePendingAction => ({
  type: UserActionType.UPLOAD_PROFILE_IMAGE_PENDING,
});

export const uploadProfileImageSuccess = (): UploadProfileImageSuccessAction => ({
  type: UserActionType.UPLOAD_PROFILE_IMAGE_FULFILLED,
});

export const uploadProfileImageFailure = (
  payload: UploadProfileImageFailureAction['payload'],
): UploadProfileImageFailureAction => ({
  type: UserActionType.UPLOAD_PROFILE_IMAGE_REJECTED,
  payload,
});

// export const updateLoginRegisterParams = (
//   payload: UpdateLoginRegisterParamsAction["payload"],
// ): UpdateLoginRegisterParamsAction => ({
//   type: UserActionType.UPDATE_LOGIN_REGISTER_PARAMS,
//   payload,
// });
