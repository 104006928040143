import { ReactElement, MouseEvent, PropsWithChildren } from 'react';
import { css, styled } from 'styled-components';

import { ChevronIcon } from 'common/components/icons';

const StyledChevronIcon = styled(ChevronIcon)`
  color: ${({ theme }) => theme.colors.textBright};
  width: 24px;
`;

const containerStyles = css`
  transition: background-color 0.3s ease;
  padding: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 400;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.textBright};
  &:hover {
    background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  }
`;

const StyledAnchor = styled.a`
  ${containerStyles};
`;

const StyledModalMenuButton = styled.button`
  border: none;
  background: transparent;
  outline: none;

  ${containerStyles};
`;

const StyledTextWrapper = styled.span`
  text-align: left;
`;

const StyledDescriptionText = styled.p`
  margin: 0.2rem 0 0 0;
  padding: 0;
  font-size: 0.8rem;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.textFade};
`;

type ListButtonProps = PropsWithChildren & {
  descriptionText?: string;
  href?: string;
  iconElement?: ReactElement | null;
  onClick?: (event: MouseEvent) => void;
};

export const ListButton = ({ children, descriptionText, href, iconElement = null, onClick }: ListButtonProps) => {
  const content = (
    <>
      <div style={{ display: 'flex' }}>
        {iconElement}
        <StyledTextWrapper>
          {children}
          {descriptionText && <StyledDescriptionText>{descriptionText}</StyledDescriptionText>}
        </StyledTextWrapper>
      </div>
      <StyledChevronIcon />
    </>
  );
  if (href) {
    return (
      <StyledAnchor href={href} target="_blank">
        {content}
      </StyledAnchor>
    );
  }
  return <StyledModalMenuButton onClick={onClick}>{content}</StyledModalMenuButton>;
};
