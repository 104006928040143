import { addStateReset } from 'common/reducer/addStateReset';
import { AuthenticationType } from 'domains/authentication/actionTypes';
import { Role } from 'entities/permissions/constants';

export const addUserChangeReset = addStateReset(AuthenticationType.LOGOUT);

export const getLinkToMembershipPage = (accountType: string): string => {
  if (accountType === Role.UNAUTHENTICATED_ROLE) {
    return `${process.env.REACT_APP_PAYMENT_PORTAL_BASE_URL}%2Fjoin-us%2Fplans`;
  } else if (accountType === Role.PUBLIC_ROLE) {
    return `${process.env.REACT_APP_PAYMENT_PORTAL_BASE_URL}sign-in?redirect_path=%2Fjoin-us%2Fplans` || '';
  }
  return `${process.env.REACT_APP_MY_FORUM_URL}/membership`;
};

export const isComingFromPaymentPortal = () => {
  const paymentPortalSSOUrl = process.env.REACT_APP_PAYMENT_PORTAL_SSO_URL;
  if (!paymentPortalSSOUrl) {
    return false;
  }
  const parsedPaymentPortalURL = new URL(paymentPortalSSOUrl);
  return !!document.referrer && document.referrer.includes(parsedPaymentPortalURL.hostname);
};
