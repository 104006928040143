import { PropsWithChildren } from 'react';
import { css, styled } from 'styled-components';

import { ExpandableContent } from 'common/components/ExpandableContent';
import { ErrorIcon } from 'common/components/icons';
import { StyledModalTextLabel } from 'common/components/modals/ModalTextLabel';
import { breakpoints } from 'common/styles';

const StyledErrorWrapper = styled.div`
  padding-top: 12px;
`;

const StyledError = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 0.8rem 0.8rem;
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 1.3rem;
  text-align: center;
  margin: 1.5rem 0 0 0;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.backgroundError};

  ${({ theme }) => css`
    color: ${theme.colors.red};
    border: 2px solid ${theme.colors.red};
  `}

  @media ${breakpoints.medium} {
    padding: 1rem;
  }
`;

const StyledText = styled.p`
  margin: 0;

  ${StyledModalTextLabel} {
    cursor: pointer;
    font-size: 0.85rem;
    font-weight: 600;
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.red};
  }
`;

const StyledErrorIcon = styled(ErrorIcon)`
  width: 24px;
  height: 24px;
  padding-right: 0.6rem;
  color: ${({ theme }) => theme.colors.red};
`;

type ModalFormErrorProps = PropsWithChildren & { isActive?: boolean };

export const ModalFormError = ({ children, isActive }: ModalFormErrorProps) => (
  <ExpandableContent isActive={isActive}>
    <StyledErrorWrapper>
      <StyledError>
        <StyledErrorIcon />
        <StyledText>{children}</StyledText>
      </StyledError>
    </StyledErrorWrapper>
  </ExpandableContent>
);
