import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { fetchTopicsMetadataFailure, fetchTopicsMetadataSuccess } from 'entities/topicsMetadata/actions';
import { TopicsMetadataResponse } from 'entities/topicsMetadata/types';
import { isFetchError } from 'TypeCheckMethods';

export function* fetchTopicsMetadataSaga() {
  try {
    const payload: TopicsMetadataResponse = yield apiFetch('topic/metadata');
    const topicsMetadataPayload = {
      allTopicsCount: payload.all.count,
      customTopicsCount: payload.significant.types
        .filter((type) => type.type === 'Custom Maps')
        .reduce((accumulator, current) => accumulator + current.count, 0),
    };

    yield put(fetchTopicsMetadataSuccess(topicsMetadataPayload));
  } catch (error) {
    if (isFetchError(error)) {
      yield put(fetchTopicsMetadataFailure({ error }, { logoutOnAuthError: true }));
    }
  }
}
