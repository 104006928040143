import { put, select } from 'redux-saga/effects';

import { searchKnowledgeData, searchPublications, searchTopic, searchVideos } from 'domains/search/actions';
import { UpdateSearchQueryAction } from 'domains/search/actionTypes';
import {
  getIsInteractiveDataSearchCached,
  getIsPublicationsSearchCached,
  getIsSearchKeyIssuesCachedQuery,
  getIsTopicsSearchCached,
  getIsVideosSearchCached,
  getSearchSectionVisibility,
} from 'domains/search/selectors';
import { SearchVisibilitySectionType } from 'domains/search/types';
import { updateSearchTerm as searchKeyIssues } from 'entities/keyIssues/actions';
import { getCanAccessCreate, getCanSeeSectionContent } from 'entities/user/selectors';

export function* triggerSearchesOnTypingSaga({ meta }: UpdateSearchQueryAction) {
  if (!meta.query) {
    return;
  }
  const searchVisibility: Record<SearchVisibilitySectionType, boolean> = yield select(getSearchSectionVisibility);
  const isTopicSearchCached: boolean = yield select(getIsTopicsSearchCached);
  const isPublicationsSearchCached: boolean = yield select(getIsPublicationsSearchCached);
  const isVideosSearchCached: boolean = yield select(getIsVideosSearchCached);
  const isInteractiveDataSearchCached: boolean = yield select(getIsInteractiveDataSearchCached);
  const isKeyIssueSearchCached: boolean = yield select(getIsSearchKeyIssuesCachedQuery);
  const accessToCreate: boolean = yield select(getCanAccessCreate);
  const accessToTabContent: boolean = yield select(getCanSeeSectionContent);

  const { type, query } = meta;
  if ((!isTopicSearchCached && searchVisibility.topics && !type) || type === 'topics') {
    yield put(searchTopic({ query }));
  }
  if (
    (!isPublicationsSearchCached && searchVisibility.publications && accessToTabContent && !type) ||
    type === 'publications'
  ) {
    yield put(searchPublications({ query }));
  }
  if ((!isVideosSearchCached && searchVisibility.videos && accessToTabContent && !type) || type === 'videos') {
    yield put(searchVideos({ query }));
  }
  if (
    (!isInteractiveDataSearchCached && searchVisibility.interactiveData && accessToTabContent && !type) ||
    type === 'interactiveData'
  ) {
    yield put(searchKnowledgeData({ query }));
  }
  if ((!isKeyIssueSearchCached && searchVisibility.keyIssues && accessToCreate && !type) || type === 'keyIssues') {
    yield put(searchKeyIssues(query || '', { offset: 0, limit: 6, page: 'search' }));
  }
}
