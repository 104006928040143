import { hasOwnProperty } from 'common/utilities/prototype';
import { SearchSection } from 'domains/search/types';
import { getDimensions, getIsDimensionsSearchCached, getSearchDimensionIds } from 'entities/keyIssues/selectors';
import { getTopicById } from 'entities/topics/selectors';
import { RootState } from 'types';

const getResults = (section: SearchSection, query?: string | null, limit?: number) => {
  if (!query) {
    return [];
  }
  const ids = hasOwnProperty(section.idsForQuery, query) ? section.idsForQuery[query] || [] : [];
  return limit ? ids.slice(0, limit) : ids;
};

export const getCurrentSearchTerm = ({ domains }: RootState) => domains.search.currentSearchTerm;

export const getSearchSectionVisibility = ({ domains }: RootState) => domains.search.sectionVisibility;

export const getTopicSearchResults = (state: RootState) =>
  getResults(state.domains.search.topics, state.domains.search.currentSearchTerm, 8).map((id) =>
    getTopicById(state, id),
  );

export const getTopicSearchIsPending = ({ domains }: RootState) => domains.search.topics.isPending;

export const getIsTopicsSearchCached = (state: RootState) =>
  hasOwnProperty(state.domains.search.topics.idsForQuery, getCurrentSearchTerm(state));

export const getPublicationsSearchResults = (limit?: number) => (state: RootState) =>
  getResults(state.domains.search.publications, state.domains.search.currentSearchTerm, limit).map(
    (id) => state.entities.knowledge.byId[id],
  );

export const getPublicationsSearchIsPending = ({ domains }: RootState) => domains.search.publications.isPending;

export const getPublicationsSearchHasNextPage = ({ domains }: RootState) => domains.search.publications.hasNextPage;

export const getIsPublicationsSearchCached = (state: RootState) =>
  hasOwnProperty(state.domains.search.publications.idsForQuery, getCurrentSearchTerm(state));

export const getVideosSearchResults = (limit?: number) => (state: RootState) =>
  getResults(state.domains.search.videos, state.domains.search.currentSearchTerm, limit).map(
    (id) => state.entities.knowledge.byId[id],
  );

export const getVideosSearchIsPending = (state: RootState) => state.domains.search.videos.isPending;

export const getVideosSearchHasNextPage = (state: RootState) => state.domains.search.videos.hasNextPage;

export const getIsVideosSearchCached = (state: RootState) =>
  hasOwnProperty(state.domains.search.videos.idsForQuery, getCurrentSearchTerm(state));

export const getInteractiveDataSearchResults = (limit?: number) => (state: RootState) =>
  getResults(state.domains.search.interactiveData, state.domains.search.currentSearchTerm, limit).map(
    (id) => state.entities.knowledge.byId[id],
  );

export const getInteractiveDataSearchIsPending = ({ domains }: RootState) => domains.search.interactiveData.isPending;

export const getInteractiveDataSearchHasNextPage = ({ domains }: RootState) =>
  domains.search.interactiveData.hasNextPage;

export const getIsInteractiveDataSearchCached = (state: RootState) =>
  hasOwnProperty(state.domains.search.interactiveData.idsForQuery, getCurrentSearchTerm(state));

export const getSearchKeyIssuesIsPending = ({ domains }: RootState) => domains.search.keyIssues.isPending;

export const getSearchKeyIssuesResults = (state: RootState) => {
  const { currentSearchTerm } = state.domains.search;
  if (!currentSearchTerm) {
    return [];
  }
  const keyIssues = getDimensions(currentSearchTerm)(state);
  return keyIssues.slice(0, 6);
};

export const getIsSearchKeyIssuesCachedQuery = (state: RootState) =>
  getIsDimensionsSearchCached(state, getCurrentSearchTerm(state));

export const getSearchIsPending = ({ domains }: RootState) =>
  domains.search.topics.isPending ||
  domains.search.interactiveData.isPending ||
  domains.search.publications.isPending ||
  domains.search.videos.isPending ||
  domains.search.keyIssues.isPending;

const getHasResults = (section: SearchSection, query: string) => (section.idsForQuery[query] || []).length !== 0;

export const getSearchHasResults = (state: RootState) => {
  const { search } = state.domains;
  const query = search.currentSearchTerm;
  if (!query) {
    return false;
  }
  return (
    getHasResults(search.topics, query) ||
    getSearchDimensionIds(state, query).length !== 0 ||
    getHasResults(search.publications, query) ||
    getHasResults(search.videos, query) ||
    getHasResults(search.interactiveData, query)
  );
};

export const getSearchTerm = (state: RootState) => state.domains.search.currentSearchTerm;
