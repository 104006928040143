import { lazy } from 'react';

import { LazyComponent } from 'common/components/lazy/LazyComponent';

const About = lazy(() => import('./About'));

export const AboutRoute = () => (
  <LazyComponent>
    <About />
  </LazyComponent>
);
