import {
  LatestKnowledgeActionType,
  LatestKnowledgeDefaultAction,
  LatestKnowledgeFulfilledAction,
  LatestKnowledgeRejectedAction,
} from 'domains/monitor/latestKnowledge/actionTypes';

export const fetchLatestKnowledge = (meta: LatestKnowledgeDefaultAction['meta']): LatestKnowledgeDefaultAction => ({
  type: LatestKnowledgeActionType.FETCH,
  meta,
});

export const fetchLatestKnowledgeSuccess = (
  meta: LatestKnowledgeFulfilledAction['meta'],
  payload: LatestKnowledgeFulfilledAction['payload'],
): LatestKnowledgeFulfilledAction => ({
  type: LatestKnowledgeActionType.FETCH_FULFILLED,
  meta,
  payload,
});

export const fetchLatestKnowledgeFailure = (
  meta: LatestKnowledgeRejectedAction['meta'],
): LatestKnowledgeRejectedAction => ({
  type: LatestKnowledgeActionType.FETCH_REJECTED,
  meta,
});
