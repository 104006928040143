import { ReactElement, MouseEvent, PropsWithChildren } from 'react';
import { styled } from 'styled-components';

const StyledContainer = styled.div`
  color: ${({ theme }) => theme.colors.fillPrimary};
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const StyledText = styled.p`
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0;
  margin-right: 10px;
`;

type SectionHeadingButtonProps = PropsWithChildren & {
  iconElement: ReactElement;
  onClick: (event: MouseEvent<HTMLDivElement>) => void;
};

export const SectionHeadingButton = ({ children, iconElement, onClick }: SectionHeadingButtonProps) => (
  <StyledContainer onClick={onClick}>
    <StyledText>{children}</StyledText>
    {iconElement}
  </StyledContainer>
);
