import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { getMapAccessListSuccess, getMapAccessListFailure } from 'domains/createShareSettings/actions';
import { MapAccessListDefaultAction } from 'domains/createShareSettings/actionTypes';
import { InviteUser } from 'domains/createShareSettings/types';

export function* getAccessListSaga(action: MapAccessListDefaultAction) {
  try {
    const response: InviteUser[] = yield apiFetch(`mapshare/access`, {
      queryParams: { tid: action.meta.id },
    });
    yield put(getMapAccessListSuccess(action.meta.id, response));
  } catch (error) {
    yield put(getMapAccessListFailure(action.meta.id));
  }
}
