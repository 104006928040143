import { SVGProps } from 'react';

export const CopyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      d="M33.545 0H7.364A4.376 4.376 0 003 4.364v30.545h4.364V4.364h26.181V0zm6.546 8.727h-24a4.376 4.376 0 00-4.364 4.364v30.545c0 2.4 1.964 4.364 4.364 4.364h24c2.4 0 4.364-1.964 4.364-4.364V13.091c0-2.4-1.964-4.364-4.364-4.364zm0 34.91h-24V13.09h24v30.545z"
      fill="currentColor"
    />
  </svg>
);
