import { ThemeType, UpdateThemeAction } from 'domains/theme/actionTypes';
import { ThemeState } from 'domains/theme/types';

export const initialState: ThemeState = {
  currentMode: 'default',
};

export const themeReducer = (state = initialState, action: UpdateThemeAction): ThemeState => {
  switch (action.type) {
    case ThemeType.UPDATE:
      return {
        ...state,
        currentMode: action.meta.mode,
      };

    default:
      return state;
  }
};
