import { useContext } from 'react';

import { ScreenSize } from 'common/styles/mediaQueries/types';
import { ViewportContext } from 'domains/viewport/context';

// Returns the value for media query that is true, default value otherwise
// returns the smallest screen size value if multiple is true
export const useMedia = <P>(types: Partial<Record<ScreenSize, P>>, defaultValue: P) => {
  const activeScreenSize = useContext(ViewportContext);
  if (!activeScreenSize) {
    // Since we don't render until we have a screen size, this will only
    // happen when the hook is used outside of ViewportProvider
    throw new Error('useMedia must be used within ViewportProvider.');
  }

  return activeScreenSize in types ? types[activeScreenSize] : defaultValue;
};

export const useIsPhone = () => useMedia({ small: true }, false);
