import { useEffect, useRef } from 'react';

const VIDEO_MP4_LOCATION = `${process.env.PUBLIC_URL}/tm_loop_v3.mp4`;
const VIDEO_WEBM_LOCATION = `${process.env.PUBLIC_URL}/tm_loop_v3.webm`;

type BackgroundVideoProps = { className?: string; shouldPauseVideo: boolean };

export const BackgroundVideo = ({ shouldPauseVideo, className }: BackgroundVideoProps) => {
  const backgroundVideoRef = useRef<HTMLVideoElement>(null);
  const playPausePromiseRef = useRef<Promise<null | void> | undefined>(undefined);

  useEffect(() => {
    // Slow the loop video down slightly
    if (backgroundVideoRef.current) {
      backgroundVideoRef.current.playbackRate = 0.8;
    }
  }, []);

  useEffect(() => {
    const playPause = () => {
      const videoElement = backgroundVideoRef.current;
      let playPausePromise;
      if (!videoElement || !playPausePromiseRef.current) {
        return;
      }
      if (shouldPauseVideo) {
        playPausePromise = videoElement.pause();
      } else {
        playPausePromise = videoElement.play();
      }
      if (playPausePromise !== undefined) {
        playPausePromiseRef.current = playPausePromise.catch(() => null);
        return playPausePromiseRef.current;
      }
    };

    if (backgroundVideoRef.current) {
      let isEffectCleaningUp = false;
      if (playPausePromiseRef.current) {
        playPausePromiseRef.current.then(() => {
          if (!isEffectCleaningUp) {
            playPause();
          }
        });
      } else {
        playPause();
      }
      return () => {
        isEffectCleaningUp = true;
      };
    }
  }, [shouldPauseVideo]);

  return (
    <video className={className} ref={backgroundVideoRef} autoPlay={true} playsInline={true} loop={true} muted={true}>
      <source src={VIDEO_WEBM_LOCATION} type="video/webm" />
      <source src={VIDEO_MP4_LOCATION} type="video/mp4" />
    </video>
  );
};
