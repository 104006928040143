import { css, styled } from 'styled-components';

import { UIButton } from 'common/components';
import { breakpoints, createScrollbarStyle } from 'common/styles';

const BAR_HEIGHT = 80;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;
`;

export const Page = styled.div`
  height: calc(100% - 80px);
  width: 100%;
  overflow: auto;

  ${createScrollbarStyle()}
`;

export const Header = styled.header`
  height: ${BAR_HEIGHT}px;
  overflow-y: visible;
  box-shadow: 0 4px 10px 0 #0000000d; // TODO: theming shadows?
  z-index: 1000;
  position: relative;

  ${({ theme }) => css`
    color: ${theme.colors.topHeaderBarText};
    background-color: ${theme.colors.topHeaderBarBackground};
  `}
`;

export const HeaderContent = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  display: none;

  @media ${breakpoints.large} {
    display: flex;
  }
`;

export const MobileNavigation = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding: 0 1rem;
  box-sizing: border-box;

  @media ${breakpoints.large} {
    display: none;
  }
`;

export const BarRow = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 1.5rem;
`;

export const Menu = styled.div`
  position: absolute;
  top: 80px;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: auto;
  z-index: 1000;
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  border-top: ${({ theme }) => theme.borders.normal};
  display: block;

  @media ${breakpoints.large} {
    display: none;
  }
`;

export const LogoutButton = styled(UIButton)`
  margin: 1rem;
  width: calc(100% - 2rem);
`;
