import { useSelector } from 'react-redux';

import { InfinityLoader } from 'common/components';
import { ListItemType } from 'common/components/lists/types';
import { TrackPage } from 'domains/analytics/types';
import { useTranslations } from 'domains/language/useTranslations';
import { getTopicsFilterSelectedIds } from 'domains/monitor/filter/selectors';
import { KnowledgeListItem } from 'domains/publication/components/KnowledgeListItem';
import { useMedia } from 'domains/viewport/useMedia';
import { Knowledge } from 'entities/knowledge/types';

type KnowledgeListProps = {
  emptyMessage?: string;
  getToLink: (id: string) => string;
  hasNextPage?: boolean;
  isPending?: boolean;
  items: Knowledge[];
  loadNextPage?: () => void;
  lockTiles?: boolean;
  selectedId?: string;
  showEmptyMessage?: boolean;
  trackPage: TrackPage;
  type?: ListItemType;
};

const LATEST_KNOWLEDGE_PUBLIC_LIMIT = 4;

export const KnowledgeList = ({
  items,
  hasNextPage = false,
  isPending = false,
  selectedId,
  trackPage,
  type,
  lockTiles,
  emptyMessage,
  showEmptyMessage = true,
  loadNextPage,
  getToLink,
}: KnowledgeListProps) => {
  const isList = useMedia({ small: true, medium: true }, false);
  const selectedTopics = useSelector(getTopicsFilterSelectedIds);
  const { textDictionary } = useTranslations();

  if (showEmptyMessage && items.length === 0 && !isPending) {
    if (emptyMessage) {
      return <p>{emptyMessage}</p>;
    }
    return (
      <p>
        {textDictionary[selectedTopics.length === 0 ? 'app.monitor.empty_message' : 'app.monitor.empty_message_filter']}
      </p>
    );
  }

  return (
    <InfinityLoader isPending={isPending} hasNextPage={hasNextPage} loadNextPage={loadNextPage}>
      {items.map((item, index) => (
        <KnowledgeListItem
          key={item.id}
          link={getToLink(item.id)}
          shareLink={getToLink(item.id)}
          isSelected={!isList && selectedId === item.id}
          knowledge={item}
          isLocked={lockTiles && index >= LATEST_KNOWLEDGE_PUBLIC_LIMIT}
          trackPage={trackPage}
          type={type}
          isFirstLocked={index === LATEST_KNOWLEDGE_PUBLIC_LIMIT}
        />
      ))}
    </InfinityLoader>
  );
};
