import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { breakpoints } from 'common/styles';
import { UpgradeBanner } from 'domains/landing/topBanner/UpgradeBanner';
import { VideoBanner } from 'domains/landing/topBanner/VideoBanner';
import { getShouldShowBecomeMemberMarketing } from 'entities/user/selectors';

const StyledRelativeContainer = styled.div`
  position: relative;
`;

const StyledContainer = styled.div`
  padding-bottom: 20px;

  @media ${breakpoints.medium} {
    padding-bottom: 60px;
  }
`;

export const TopBanner = () => {
  const videoContainer = useRef<HTMLDivElement>(null);
  const [isVideoHidden, setIsVideoHidden] = useState(false);
  const showUpgradeBanner = useSelector(getShouldShowBecomeMemberMarketing);

  useEffect(() => {
    const curatedBannerElement = videoContainer.current;
    if (!curatedBannerElement) {
      return;
    }
    const options = {
      threshold: 0.3,
    };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        setIsVideoHidden(!entry.isIntersecting || entry.intersectionRatio < options.threshold);
      });
    }, options);
    observer.observe(curatedBannerElement);

    return () => {
      observer.unobserve(curatedBannerElement);
    };
  }, []);

  return (
    <StyledRelativeContainer>
      {showUpgradeBanner ? (
        <UpgradeBanner />
      ) : (
        <StyledContainer ref={videoContainer}>
          <VideoBanner videoIsPaused={isVideoHidden} />
        </StyledContainer>
      )}
    </StyledRelativeContainer>
  );
};
