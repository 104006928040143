import { Stakeholder } from 'entities/stakeholders/types';
import { ErrorMeta, ErrorPayload } from 'types';

export enum StakeholderActionTypes {
  FETCH_FOR_TOPIC = '[Stakeholders] - Fetch for topic',
  FETCH_FOR_TOPIC_SUCCESS = '[Stakeholders] - Fetch for topic successful',
  FETCH_FOR_TOPIC_FAILURE = '[Stakeholders] - Fetch for topic failure',
  FETCH_FOR_ID = '[Stakeholders] - Fetch for id',
  FETCH_FOR_ID_SUCCESS = '[Stakeholders] - Fetch for id successful',
  FETCH_FOR_ID_FAILURE = '[Stakeholders] - Fetch for id failure',
}

export type StakeholdersFetchForTopicPayload = {
  id: string;
  limit: number;
  offset: number;
  role?: string;
  searchTerm?: string;
  type?: string;
};

export type StakeholdersFetchForTopicAction = {
  payload: StakeholdersFetchForTopicPayload;
  type: StakeholderActionTypes.FETCH_FOR_TOPIC;
};

type StakeholdersFetchForTopicSuccessPayload = { data: Stakeholder[]; topicId: string };

export type StakeholdersFetchForTopicSuccessAction = {
  meta: StakeholdersFetchForTopicPayload;
  payload: StakeholdersFetchForTopicSuccessPayload;
  type: StakeholderActionTypes.FETCH_FOR_TOPIC_SUCCESS;
};

type StakeholdersFetchForTopicFailurePayload = ErrorPayload & { topicId: string };
type StakeholdersFetchForTopicFailureMeta = ErrorMeta & { searchCriteria: StakeholdersFetchForTopicPayload };

export type StakeholdersFetchForTopicFailureAction = {
  meta: StakeholdersFetchForTopicFailureMeta;
  payload: StakeholdersFetchForTopicFailurePayload;
  type: StakeholderActionTypes.FETCH_FOR_TOPIC_FAILURE;
};

export type FetchStakeholderForIdAction = { meta: { id: string }; type: StakeholderActionTypes.FETCH_FOR_ID };

export type FetchStakeholderForIdFulfilledAction = {
  meta: { id: string };
  payload: Stakeholder;
  type: StakeholderActionTypes.FETCH_FOR_ID_SUCCESS;
};

export type FetchStakeholderForIdRejectedAction = {
  meta: ErrorMeta;
  payload: { id: string };
  type: StakeholderActionTypes.FETCH_FOR_ID_FAILURE;
};

export type StakeholderAction =
  | StakeholdersFetchForTopicAction
  | StakeholdersFetchForTopicSuccessAction
  | StakeholdersFetchForTopicFailureAction
  | FetchStakeholderForIdAction
  | FetchStakeholderForIdFulfilledAction
  | FetchStakeholderForIdRejectedAction;
