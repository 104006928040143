import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { updateCollectionSuccess, updateCollectionFailure, fetchCollections } from 'domains/collection/actions';
import { UpdateCollectionAction } from 'domains/collection/actionTypes';
import { CollectionType } from 'domains/collection/types/collectionType';

export function* updateCollectionSaga({ payload }: UpdateCollectionAction) {
  try {
    const { co_brands, ...rest } = payload;
    const body = { ...rest, co_brand_ids: co_brands.map(({ id }) => id) };
    yield apiFetch(`collection/${payload.id}/edit`, { body, method: 'PUT' });
    yield put(updateCollectionSuccess(payload));
    yield put(fetchCollections(CollectionType.Editable));
  } catch (error) {
    yield put(updateCollectionFailure(payload.id));
    console.error(`[updateCollectionSaga] Failed to update collection with ID '${payload.id}': ${error}`);
  }
}
