import { put } from 'redux-saga/effects';

import { updateAIGeneratedMapProgressSuccess } from 'domains/createMapDetail/actions';
import { FakeProgressStatuses } from 'domains/createMapDetail/types';

function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function* getAIGeneratedMapProgressSaga() {
  const fakeStatuses = [
    FakeProgressStatuses.Preparing,
    FakeProgressStatuses.Selecting,
    FakeProgressStatuses.Finalizing,
    FakeProgressStatuses.Finish,
  ];

  for (let i = 0; i < fakeStatuses.length; i++) {
    yield put(updateAIGeneratedMapProgressSuccess({ status: fakeStatuses[i] }));
    yield timeout(4000);
  }
}
