import { SVGProps } from 'react';

export const LinkedInIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <path
      fillRule="evenodd"
      d="M72.057 16h-27.27a2.378 2.378 0 0 0-2.412 2.3v27.4a2.069 2.069 0 0 0 2.031 2.3h27.27a2.65 2.65 0 0 0 2.7-2.3V18.3a2.294 2.294 0 0 0-2.319-2.3zM54.565 28.191h4.308v2.2h.047a5.854 5.854 0 0 1 5-2.386c4.6 0 5.888 2.444 5.888 6.971v8.457h-4.576v-7.628c0-2.027-.809-3.805-2.7-3.805-2.3 0-3.393 1.556-3.393 4.11v7.319h-4.574zm-7.619 15.238h4.571V28.191h-4.571zm5.143-20.572A2.857 2.857 0 1 1 49.232 20a2.856 2.856 0 0 1 2.857 2.857z"
      transform="translate(-42.375 -16)"
      fill="currentColor"
    />
  </svg>
);
