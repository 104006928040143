import { DragEvent, memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { addKeyIssueToMap, removeTopicFromMap, toggleDraggingCustomMap } from 'domains/createMapDetail/actions';
import { AddRemoveButton } from 'domains/createMapDetail/components/keyIssueList/AddRemoveButton';
import { EditCustomKeyIssueButton } from 'domains/createMapDetail/components/keyIssueList/EditCustomKeyIssueButton';
import { KeyIssueTile } from 'domains/topic/components/KeyIssueTile';
import { getDimensionsSearchTerm } from 'entities/keyIssues/selectors';
import { KeyIssue } from 'entities/keyIssues/types';
import { getCreateMapCustomKeyIssueAccess } from 'entities/user/selectors';

const StyledContainer = styled.div`
  cursor: ${({ draggable, onClick }) => {
    if (draggable) {
      return 'move';
    }
    return onClick ? 'pointer' : 'initial';
  }};
  margin-bottom: 1rem;
`;

const StyledFlexContainer = styled.div`
  display: flex;
  height: 100%;
  & > button {
    width: 50%;
    padding: 0;
    text-align: center;
    justify-content: center;
  }
`;

type DimensionItemProps = KeyIssue & {
  dataTestId?: string;
  isLight?: boolean;
  isOnMap: boolean;
  onEditSelect?: (id: string) => void;
  shouldHighlightSearchTerm?: boolean;
};

export const DimensionItem = memo(
  ({
    description,
    id,
    images,
    isPending,
    name,
    primary_topic,
    isOnMap,
    is_custom_dimension,
    onEditSelect,
    shouldHighlightSearchTerm = true,
    dataTestId,
  }: DimensionItemProps) => {
    const dispatch = useDispatch();
    const [isExpanded, setIsExpanded] = useState(false);
    const searchTerm = useSelector(getDimensionsSearchTerm);
    const hasAccessToCustomKeyIssues = useSelector(getCreateMapCustomKeyIssueAccess);

    const handleDragStart = (event: DragEvent<HTMLDivElement>) => {
      event.dataTransfer.setData('topicId', id);

      dispatch(toggleDraggingCustomMap({ isDragging: true }));
    };

    const handleDragEnd = () => {
      dispatch(toggleDraggingCustomMap({ isDragging: false }));
    };

    const onAddKeyIssue = () => {
      dispatch(addKeyIssueToMap({ keyIssueId: id }));
    };

    const onRemoveKeyIssue = () => {
      dispatch(removeTopicFromMap({ keyIssueId: id }));
    };

    const highlightedTerm = shouldHighlightSearchTerm ? searchTerm : undefined;

    return (
      <StyledContainer
        draggable={!isOnMap}
        onClick={description ? () => setIsExpanded(!isExpanded) : undefined}
        onDragStart={!isOnMap ? handleDragStart : undefined}
        onDragEnd={!isOnMap ? handleDragEnd : undefined}
      >
        <KeyIssueTile
          name={name}
          images={images}
          primary_topic={primary_topic}
          description={description}
          actionElements={
            is_custom_dimension && hasAccessToCustomKeyIssues ? (
              <StyledFlexContainer>
                <EditCustomKeyIssueButton id={id} onEditSelect={onEditSelect} />
                <AddRemoveButton
                  isPending={isPending}
                  isAdded={isOnMap}
                  onAdd={onAddKeyIssue}
                  onRemove={onRemoveKeyIssue}
                  dataTestId={dataTestId}
                />
              </StyledFlexContainer>
            ) : (
              <AddRemoveButton
                isPending={isPending}
                isAdded={isOnMap}
                onAdd={onAddKeyIssue}
                onRemove={onRemoveKeyIssue}
                dataTestId={dataTestId}
              />
            )
          }
          highlightedTerm={highlightedTerm}
          isDescriptionExpanded={isExpanded}
          actionElementsPosition={'below'}
        />
      </StyledContainer>
    );
  },
);

DimensionItem.displayName = 'DimensionItem';
