import { styled } from 'styled-components';

import { ListItemType } from 'common/components/lists/types';
import { breakpoints, lineTruncationStyle } from 'common/styles';
import { useFormatDate } from 'common/utilities';
import { EventSession } from 'entities/events/types';
import { Source } from 'entities/knowledge/types';

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  font-size: 12px;
  flex-direction: row;
  align-items: center;
`;

const StyledRow = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  word-break: break-all;
  max-width: none;

  @media ${breakpoints.medium} {
    max-width: 36rem;
  }
`;

const StyledText = styled.p`
  margin: 0;
  ${lineTruncationStyle(1)};
`;

const StyledImage = styled.img`
  height: 24px;
  background-size: contain;
  transition: opacity 0.3s ease;
  margin-right: 10px;
`;

const StyledDate = styled.p`
  margin: 0 0 0 10px;
  display: none;
  color: ${({ theme }) => theme.colors.clusterCaptionText};

  @media ${breakpoints.medium} {
    display: block;
  }
  ${lineTruncationStyle(1)};
`;

export type SourceLabelProps = {
  className?: string;
  date?: number;
  hideImage?: boolean;
  isDateAbsolute?: boolean;
  source: Source | EventSession;
  type?: ListItemType;
};

export const SourceLabel = ({
  className,
  date,
  hideImage = false,
  isDateAbsolute = false,
  source,
  type = 'large',
}: SourceLabelProps) => {
  const { getRelativeDate, getDate } = useFormatDate();

  return (
    <StyledContainer className={className}>
      <StyledRow>
        {!hideImage && source.images && <StyledImage src={source.images.thumbnail} />}
        <StyledText>{source.name}</StyledText>
      </StyledRow>
      {date && (
        <StyledDate>
          {isDateAbsolute
            ? getDate({
                date,
                onlyDate: type !== 'large',
              })
            : getRelativeDate({
                date,
                precision: 'hour',
                onlyDate: type !== 'large',
              })}
        </StyledDate>
      )}
    </StyledContainer>
  );
};
