import { css, styled } from 'styled-components';

import { LeftArrow } from 'common/components/icons';
import { UIButton } from 'common/components/UIButton';
import { useTranslations } from 'domains/language/useTranslations';

const StyledArrow = styled(LeftArrow)<{ isLeft?: boolean }>`
  width: 14px;
  height: 14px;
  transition: transform 0.3s;
  transform: ${({ isLeft }) => `rotate(${isLeft ? 0 : '180deg'})`};
`;

const UIButtonStyled = styled(UIButton)<{ isOpen: boolean; isRightPanel?: boolean }>`
  z-index: 1000;
  position: absolute;
  bottom: 3rem;
  left: ${({ isRightPanel }) => (isRightPanel ? 'auto' : 'calc(100% + 1rem)')};
  right: ${({ isRightPanel }) => (isRightPanel ? 'calc(100% + 1rem)' : 'auto')};
  padding-left: 13px;
  padding-right: 13px;
  & span {
    overflow: hidden;
    transition:
      max-width,
      padding 0.3s;
    max-width: ${({ isOpen }) => (isOpen ? 'auto' : '0')};

    ${({ isOpen, isRightPanel }) =>
      isOpen &&
      css`
        padding-right: ${isRightPanel ? '0.5rem' : '0'};
        padding-left: ${isRightPanel ? '0' : '0.5rem'};
      `}
  }
  &:hover span {
    max-width: 200px;
    ${({ isOpen, isRightPanel }) =>
      !isOpen &&
      css`
        padding-right: ${isRightPanel ? '0.5rem' : '0'};
        padding-left: ${isRightPanel ? '0' : '0.5rem'};
      `}
  }
`;

type ExpandButtonProps = { className?: string; isOpen: boolean; isRightPanel?: boolean; onClick: () => void };

export const ExpandButton = ({ className, isOpen, isRightPanel = false, onClick }: ExpandButtonProps) => {
  const { textDictionary } = useTranslations();
  const label = isOpen ? 'app.create.hide_panel' : 'app.create.show_panel';
  return (
    <UIButtonStyled
      onClick={onClick}
      variant="neutral"
      className={className}
      isRightPanel={isRightPanel}
      isOpen={isOpen}
    >
      {!isRightPanel && <StyledArrow isLeft={isOpen} />}
      <span>{textDictionary[label]}</span>
      {isRightPanel && <StyledArrow isLeft={!isOpen} />}
    </UIButtonStyled>
  );
};
