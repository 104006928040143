import { Coachmark } from 'entities/coachmarks/types';

export enum CoachmarkEntityActionType {
  FETCH = 'entities/COACHMARKS_FETCH_ALL',
  FETCH_FULFILLED = 'entities/COACHMARKS_FETCH_ALL_SUCCESS',
  FETCH_REJECTED = 'entities/COACHMARKS_FETCH_ALL_FAILURE',
}

export type FetchCoachmarksDefaultAction = { type: CoachmarkEntityActionType.FETCH };

export type FetchCoachmarksFulfilledAction = {
  payload: { coachmarks: Coachmark[] };
  type: CoachmarkEntityActionType.FETCH_FULFILLED;
};

export type FetchCoachmarksRejectedAction = {
  payload: { message?: string };
  type: CoachmarkEntityActionType.FETCH_REJECTED;
};

export type FetchCoachmarksAction =
  | FetchCoachmarksDefaultAction
  | FetchCoachmarksFulfilledAction
  | FetchCoachmarksRejectedAction;
