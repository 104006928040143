import { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { CrossIcon, ErrorMessage, SectionTitleUnderlined, Spinner } from 'common/components';
import { ClearInput } from 'common/components/form/ClearInput';
import { breakWordStyle } from 'common/styles';
import { setTopicContentFile } from 'domains/customContentAndSources/actions';
import { ContentFileIcon } from 'domains/customContentAndSources/components/ContentFileIcon';
import { getCustomContentIsUploading, getInProgressCustomDocument } from 'domains/customContentAndSources/selectors';
import { useTranslations } from 'domains/language/useTranslations';

const StyledSectionTitleUnderlined = styled(SectionTitleUnderlined)`
  margin-top: 2rem;
`;

const StyledSpinner = styled(Spinner)`
  width: 14px;
  height: 14px;
  color: ${({ theme }) => theme.colors.fillPrimary};
`;

const StyledClearButtonIcon = styled(CrossIcon)`
  width: 14px;
  height: 14px;
  cursor: pointer;
  transition: all 0.3s;
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.colors.textFade};
  &:hover {
    color: ${({ theme }) => theme.colors.textBright};
  }
`;

const StyledFileIcon = styled(ContentFileIcon)`
  color: ${({ theme }) => theme.colors.fillPrimary};
  height: 80px;
  margin-right: 1rem;
`;

const StyledInput = styled(ClearInput)`
  margin-bottom: 1rem;
`;

const StyledFileContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
`;

const StyledFilePropertyText = styled.p`
  color: ${({ theme }) => theme.colors.fillPrimary};

  ${breakWordStyle}
`;

export const DocumentPreviewForm = () => {
  const dispatch = useDispatch();
  const { textDictionary, interpolate } = useTranslations();
  const isUploading = useSelector(getCustomContentIsUploading);
  const inProgressDocument = useSelector(getInProgressCustomDocument);

  const clearDocument = () => {
    dispatch(setTopicContentFile(undefined));
  };

  const clearFilename = () => {
    dispatch(setTopicContentFile({ filename: '' }));
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'filename') {
      dispatch(setTopicContentFile({ filename: event.target.value }));
    }
  };

  if (!inProgressDocument) {
    return null;
  }

  return (
    <>
      {!inProgressDocument.id && (
        <StyledSectionTitleUnderlined
          rightElement={isUploading ? <StyledSpinner /> : <StyledClearButtonIcon onClick={clearDocument} />}
        >
          {textDictionary['app.custom_attachment.document.title']}
        </StyledSectionTitleUnderlined>
      )}
      <ErrorMessage isActive={inProgressDocument.isFileTooBig}>
        {textDictionary['app.custom_attachment.file.error.too_large']}
      </ErrorMessage>
      <StyledFileContainer>
        <StyledFileIcon mimetype={inProgressDocument.fileType} />
        <div>
          <StyledFilePropertyText>
            {interpolate(textDictionary['app.custom_attachment.document.name_display'], {
              fileName: () => inProgressDocument.originalFilename,
            })}
          </StyledFilePropertyText>
          <StyledFilePropertyText>
            {interpolate(textDictionary['app.custom_attachment.document.type_display'], {
              fileType: () => inProgressDocument.fileType,
            })}
          </StyledFilePropertyText>
        </div>
      </StyledFileContainer>
      <StyledInput
        name="filename"
        label={textDictionary['app.custom_key_issue.title']}
        value={inProgressDocument.filename}
        onChange={onChange}
        autoComplete="off"
        disabled={isUploading}
        onClearHandle={clearFilename}
      />
    </>
  );
};
