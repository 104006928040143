import { useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { breakpoints } from 'common/styles';
import { useElementIsLoaded } from 'common/utilities';
import { FollowingTopics } from 'domains/discover/components/FollowingTopics';
import { TopicSlider } from 'domains/landing/topics/TopicSlider';
import { useTranslations } from 'domains/language/useTranslations';
import { getHighlightedTopics } from 'entities/topics/selectors';
import { getCanFollowTopic } from 'entities/user/selectors';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  justify-content: center;
  padding: 4rem 2rem 2rem;
  max-width: 1600px;
  margin: 0 auto;

  @media ${breakpoints.medium} {
    padding: 4rem;
  }
`;

export const HighlightsSections = () => {
  const { textDictionary } = useTranslations();
  const { isLoaded, elementRef } = useElementIsLoaded({ threshold: 0.5 });
  const highlights = useSelector(getHighlightedTopics);
  const accessToFollowTopics = useSelector(getCanFollowTopic);

  return (
    <StyledContainer ref={elementRef}>
      <TopicSlider
        title={textDictionary['app.discover.topics.highlights.title']}
        message={textDictionary['app.discover_topics.highlights']}
        topics={highlights}
        isLoaded={isLoaded}
      />
      {accessToFollowTopics && (
        <div>
          <FollowingTopics />
        </div>
      )}
    </StyledContainer>
  );
};
