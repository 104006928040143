import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { styled } from 'styled-components';

import { Spinner } from 'common/components';
import { Status } from 'common/types/status';
import { replaceHistory } from 'common/utilities';
import { getIsAuthenticated } from 'domains/authentication/selectors';
import { clearSingle, fetchCollectionForEditing, fetchCollections } from 'domains/collection/actions';
import { CreateOrEditCollection } from 'domains/collection/components/edit/CreateOrEditCollection';
import { isCollectionClientSide, selectCollections } from 'domains/collection/selectors';
import { CollectionType } from 'domains/collection/types/collectionType';
import { getSiteLanguage } from 'domains/language/selectors';
import { activateModal } from 'domains/modal/actions';
import { getCanEditCollections } from 'entities/user/selectors';
import { useAuth } from 'useAuth';

const StyledSpinner = styled(Spinner)`
  color: ${({ theme }) => theme.colors.textBright};
  display: block;
  margin: 3rem auto;
`;

export const EditCollection = () => {
  const { triggerLogin } = useAuth();
  const dispatch = useDispatch();
  const { collectionId: id } = useParams<{ collectionId?: string }>();
  const canEditCollections = useSelector(getCanEditCollections);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const { forEditing } = useSelector(selectCollections);
  const siteLanguage = useSelector(getSiteLanguage);

  if (id === undefined) throw Error('collectionId param undefined in EditCollection.');
  const isClientSide = useSelector(isCollectionClientSide(id));

  useEffect(() => {
    dispatch(isClientSide ? fetchCollections(CollectionType.Editable) : fetchCollectionForEditing(id));
  }, [dispatch, id, isClientSide, siteLanguage]);

  useEffect(() => {
    if (!isAuthenticated) {
      triggerLogin();
      return;
    }

    if (!canEditCollections || (typeof forEditing !== 'string' && !forEditing.canEdit)) {
      replaceHistory(`/collection/${id}`);
      return;
    }

    if (forEditing === Status.Error) {
      dispatch(clearSingle());
      dispatch(
        activateModal({ id: 'Error', params: { localisedMessageKey: 'app.collection.unavailable.error_message' } }),
      );
      replaceHistory('/create?mode=collection');
    }
  }, [canEditCollections, dispatch, forEditing, id, isAuthenticated, triggerLogin]);

  const isLoading = typeof forEditing === 'string' || !forEditing.canEdit || isClientSide;
  return !isAuthenticated ? null : isLoading ? <StyledSpinner /> : <CreateOrEditCollection collection={forEditing} />;
};
