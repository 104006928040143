import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import { CreateOrEditCollection } from 'domains/collection/components/edit/CreateOrEditCollection';
import { EditCollection } from 'domains/collection/components/edit/EditCollection';
import { LoadCollectionPage } from 'domains/collection/components/LoadCollectionPage';
import { PageNotFound } from 'domains/pageNotFound/PageNotFound';
import { PrivateRoute } from 'domains/routing/components/PrivateRoute';
import { getCanEditCollections } from 'entities/user/selectors';

export const CollectionRoutes = () => {
  const canEditCollections = useSelector(getCanEditCollections);

  return (
    <Switch>
      <PrivateRoute path="/collection/new" canAccess={canEditCollections} component={CreateOrEditCollection} />

      <Route path="/collection/:collectionId/edit" component={EditCollection} />

      <Route path="/collection/:collectionId" component={LoadCollectionPage} />

      <Route path="*">
        <PageNotFound />
      </Route>
    </Switch>
  );
};
