import { call, put, select } from 'redux-saga/effects';

import { apiFetch, history, pushHistory } from 'common/utilities';
import { setTransformationMapTopic } from 'domains/transformationMap/actions';
import { TransformationMapUpdateTopicAction } from 'domains/transformationMap/actionTypes';
import { fetchTopicSuccess } from 'entities/topics/actions';
import { getTopic } from 'entities/topics/selectors';
import { Topic } from 'entities/topics/types';

export function* updateMapTopicSaga(action: TransformationMapUpdateTopicAction) {
  const { id } = action.meta;
  const topic: Topic = yield select(getTopic(id));
  if (!topic?.isTMLoaded) {
    const newTopic: Topic = yield apiFetch(`map/${id}`);
    yield put(fetchTopicSuccess({ topic: newTopic }));
  }
  const productDomain = history.location.pathname.split('/')[1];
  if (!['topics', 'discover', 'create'].includes(productDomain)) {
    return;
  }
  const newUrl = `/${productDomain}/${id}${history.location.search}`;
  if (history.location.pathname + history.location.search !== newUrl) {
    yield call(pushHistory, newUrl);
  }
  yield put(setTransformationMapTopic(action.meta));
}
