import { styled } from 'styled-components';

import { UIButton } from 'common/components';
import { breakpoints } from 'common/styles';
import { CreateLandingTitle } from 'domains/create/landing/CreateLandingTitle';
import { useTranslations } from 'domains/language/useTranslations';
import { useRouterNavigation } from 'domains/routing/utils';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1500px;
  padding: 5rem 3rem 4rem;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media ${breakpoints.medium} {
    flex-direction: row;
  }
`;

export const CreateNewCollectionSection = () => {
  const onClick = useRouterNavigation({ to: 'collection-create' });
  const { textDictionary } = useTranslations();

  return (
    <StyledContainer>
      <CreateLandingTitle mode="collection" />
      <StyledButtonContainer>
        <UIButton icon="rightArrow" onClick={onClick} variant="filled">
          {textDictionary['app.create.collection.landing_banner.button']}
        </UIButton>
      </StyledButtonContainer>
    </StyledContainer>
  );
};
