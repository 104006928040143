import { SectionTitleUnderlinedProps, SectionTitleUnderlined } from 'common/components/SectionTitleUnderlined';
import { SourceLabelProps, SourceLabel } from 'common/components/SourceLabel';

// We opt out of rendering if `source` is not available since this is required
// for every case where this component is used.
// But since its listed as required in the underlying SourceLabel, we re-specify
// `source` as an optional prop of this component.
type SourceLabelTitleProps = SectionTitleUnderlinedProps &
  Omit<SourceLabelProps, 'source'> & { source?: SourceLabelProps['source'] };

export const SourceLabelTitle = ({ source, date, type, hideImage, ...titleProps }: SourceLabelTitleProps) => {
  if (!source) {
    return null;
  }

  return (
    <SectionTitleUnderlined {...titleProps}>
      <SourceLabel source={source} date={date} type={type} hideImage={hideImage} />
    </SectionTitleUnderlined>
  );
};
