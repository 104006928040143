import { RootState } from 'types';

export const getWhatsNewStories = (state: RootState) =>
  (state.domains.whatsNew.data && state.domains.whatsNew.data.stories) || [];

export const getWhatsNewVersion = (state: RootState) =>
  (state.domains.whatsNew.data && state.domains.whatsNew.data.version) || undefined;

export const showWhatsNewIcon = (state: RootState) => !!state.domains.whatsNew.data;

export const showWhatsNewIconBadge = (state: RootState) => !!state.domains.whatsNew.showBadge;
