import { put, select } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { fetchFollowedTopics, unfollowTopicFailure, unfollowTopicSuccess } from 'entities/topics/actions';
import { UnfollowTopicDefaultAction } from 'entities/topics/actionTypes';
import { getFollowedTopicsIds, getFollowedTopicsLimit } from 'entities/topics/selectors';
import { isFetchError } from 'TypeCheckMethods';

export function* unfollowTopicSaga(action: UnfollowTopicDefaultAction) {
  const { id } = action.payload;

  try {
    const followedTopics: string[] = yield select(getFollowedTopicsIds);
    yield apiFetch(`topic/${id}/follow`, { method: 'DELETE' });
    yield put(unfollowTopicSuccess({ id, isFollowing: false }, action.meta));
    if (followedTopics.includes(id)) {
      const followedTopicsLimit: number = yield select(getFollowedTopicsLimit);
      yield put(
        fetchFollowedTopics({
          limit: followedTopicsLimit,
          offset: 0,
        }),
      );
    }
  } catch (error) {
    if (isFetchError(error)) {
      yield put(unfollowTopicFailure({ id, error }, { logoutOnAuthError: true }));
    }
  }
}
