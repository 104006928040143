import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { fetchHighlightedTopicsFailure, fetchHighlightedTopicsSuccess } from 'entities/topics/actions';
import { Topic } from 'entities/topics/types';
import { isFetchError } from 'TypeCheckMethods';

export function* fetchHighlightedTopicsSaga() {
  try {
    const topics: Topic[] = yield apiFetch('topic/curated/highlighted');
    yield put(fetchHighlightedTopicsSuccess({ topics }));
  } catch (error) {
    if (isFetchError(error)) {
      yield put(fetchHighlightedTopicsFailure({ error }, { logoutOnAuthError: true }));
    }
  }
}
