import { SVGProps } from 'react';

export const DialogIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      d="M40.844 6.66a26.77 26.77 0 00-16.842-5.657A26.767 26.767 0 007.159 6.659 18.03 18.03 0 000 20.72a18.03 18.03 0 007.156 14.057 26.766 26.766 0 0016.842 5.657c.647 0 1.298-.021 1.945-.064l4.108 6.742a1.622 1.622 0 001.35.782h.04a1.622 1.622 0 001.346-.708L41.442 34.3a17.905 17.905 0 006.58-13.572 18.03 18.03 0 00-7.178-14.068zM39.038 32.06l-7.548 11.2-3.292-5.427a1.623 1.623 0 00-1.386-.78h-.162c-.88.088-1.764.133-2.648.133-11.448.008-20.76-7.4-20.76-16.467 0-9.069 9.312-16.475 20.756-16.475s20.756 7.392 20.756 16.475a14.803 14.803 0 01-5.716 11.341z"
      fill="currentColor"
    />
  </svg>
);
