import { PropsWithChildren } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css, styled } from 'styled-components';

import { trackEvent } from 'domains/analytics/actions';
import { TrackPage } from 'domains/analytics/types';
import { getIsAuthenticated } from 'domains/authentication/selectors';
import { useTranslations } from 'domains/language/useTranslations';
import { getUserRole } from 'entities/user/selectors';
import { getLinkToMembershipPage } from 'entities/user/utils';

const StyledLandingTag = css`
  display: flex;
  padding: 3px 10px;
  background-color: ${({ theme }) => theme.colors.yellow};
  color: ${({ theme }) => theme.colors.black};
  border-radius: 4px;
  text-transform: uppercase;
  width: fit-content;
  text-decoration: none;
  font-weight: bold;
  margin-bottom: 12px;
`;

const StyledLink = styled.a`
  ${StyledLandingTag}
`;

const StyledDiv = styled.div`
  cursor: pointer;
  ${StyledLandingTag}
`;

type DigitalMemberTagProps = PropsWithChildren & { className?: string; text?: string; trackPage: TrackPage };

export const DigitalMemberTag = ({ className, text, trackPage }: DigitalMemberTagProps) => {
  const { textDictionary } = useTranslations();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const userRole = useSelector(getUserRole);

  const onClick = () => {
    dispatch(
      trackEvent({
        name: 'Upgrade',
        page: trackPage,
      }),
    );
    window.location.href = getLinkToMembershipPage(userRole);
  };

  return isAuthenticated ? (
    <StyledDiv onClick={onClick} className={className}>
      {text || textDictionary['app.about.key_points.membership_feature']}
    </StyledDiv>
  ) : (
    <StyledLink
      href={`${process.env.REACT_APP_PAYMENT_PORTAL_BASE_URL}join-us/pricing/`}
      target="_blank"
      rel="noopener noreferrer"
      className={className}
    >
      {text || textDictionary['app.about.key_points.membership_feature']}
    </StyledLink>
  );
};
