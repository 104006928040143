import { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { css, styled } from 'styled-components';

import { UIChip, TextArea, TextInput } from 'common/components';
import { LanguageCode } from 'common/types/languageCode';
import { Setter } from 'common/types/setter';
import { CollectionLanguageChipIndicator } from 'domains/collection/components/edit/parts/CollectionLanguageChipTick';
import { LanguagesCarousel } from 'domains/collection/components/edit/parts/LanguagesCarousel';
import { Translation, TranslationType } from 'domains/collection/types/translation';
import { getSiteLanguage } from 'domains/language/selectors';
import { useTranslations } from 'domains/language/useTranslations';
import { getLanguages } from 'entities/languages/selectors';
import { Language } from 'entities/languages/types';

const StyledChip = styled(UIChip)<{ error: boolean; isSelected: boolean }>`
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.textBright : theme.colors.backgroundSecondary};
  border-color: ${({ theme }) => theme.colors.languageChipBorder};
  box-sizing: border-box;
  color: ${({ isSelected, theme }) => (isSelected ? theme.colors.backgroundSecondary : theme.colors.textBright)};
  display: flex;
  gap: 1rem;
  height: 39px;
  margin: 0 10px 0 0;
  min-width: fit-content;
  padding: 0.5rem 1.25rem;

  ${({ error, theme }) =>
    error
      ? css`
          border-color: ${theme.colors.error};
          box-shadow: inset 0 0 0 1px ${theme.colors.error};
        `
      : ''}

  &:hover {
    background-color: ${({ isSelected, theme }) =>
      isSelected ? theme.colors.textBright : theme.colors.backgroundSecondary};
    color: ${({ isSelected, theme }) => (isSelected ? theme.colors.backgroundSecondary : theme.colors.textBright)};
  }
`;

const StyledTextFieldContainer = styled.div`
  position: relative;
`;

const StyledTextInput = styled(TextInput)<{ error: boolean; readOnly?: boolean }>`
  > label {
    color: ${({ theme }) => theme.colors.textBright};
  }

  > input {
    opacity: 1;

    ${({ readOnly, theme }) =>
      readOnly
        ? css`
            background-color: ${({ theme }) => theme.colors.referenceTextBackground};
            border-color: ${theme.colors.readonlyBorder};
            color: ${theme.colors.readonlyText};

            &:focus {
              border-color: ${({ theme }) => theme.colors.writableBorder};
            }
          `
        : css`
            background-color: ${theme.colors.background};
            border-color: ${theme.colors.writableBorder};
            color: ${theme.colors.textBright};

            &:focus {
              border-color: ${({ theme }) => theme.colors.writableBorder};
            }
          `}

    ${({ error, theme }) =>
      error
        ? css`
            border-color: ${theme.colors.error};
            box-shadow: inset 0 0 0 1px ${theme.colors.error};
          `
        : ``}
  }
`;

const StyledTranslationChip = styled(UIChip)`
  background-color: ${({ theme }) => theme.colors.translationChipBackground};
  border-color: ${({ theme }) => theme.colors.translationChipBackground};
  border-radius: 5px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.translationChipText};
  cursor: default;
  display: flex;
  font-weight: bold;
  height: 1.875rem;
  margin: 0;
  padding: 0 0.5rem;
  position: absolute;
  right: 0.5rem;
  top: calc(19.5px + 16px + 5px);

  &:hover {
    background-color: ${({ theme }) => theme.colors.translationChipBackground};
    border-color: ${({ theme }) => theme.colors.translationChipBackground};
  }
`;

const StyledTextArea = styled(TextArea)`
  > label {
    color: ${({ theme }) => theme.colors.textBright};
  }

  > textarea {
    border-radius: 5px;

    ${({ readOnly, theme }) =>
      readOnly
        ? css`
            background-color: ${({ theme }) => theme.colors.referenceTextBackground};
            border-color: ${theme.colors.readonlyBorder};
            color: ${theme.colors.readonlyText};
            cursor: not-allowed;

            &:focus {
              border-color: ${({ theme }) => theme.colors.readonlyBorder};
            }
          `
        : css`
            background-color: ${theme.colors.background};
            border-color: ${theme.colors.writableBorder};
            color: ${theme.colors.textBright};

            &:focus {
              border-color: ${({ theme }) => theme.colors.writableBorder};
            }
          `}
  }
`;

type SetTranslationsTabProps = {
  collectionLanguage: LanguageCode;
  description: Required<Translation>;
  invalidDescriptions: LanguageCode[];
  name: Required<Translation>;
  setDescription: Setter<Required<Translation>>;
  setName: Setter<Required<Translation>>;
  showError: boolean;
};

export const SetTranslationsTab = ({
  collectionLanguage,
  description,
  invalidDescriptions,
  name,
  setDescription,
  setName,
  showError,
}: SetTranslationsTabProps) => {
  const languages = useSelector(getLanguages);
  const siteLanguage = useSelector(getSiteLanguage);
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageCode>(siteLanguage);
  const { textDictionary } = useTranslations();

  useEffect(() => setSelectedLanguage(siteLanguage), [siteLanguage]);

  const isNameValid = (language: LanguageCode) =>
    language !== collectionLanguage || name[collectionLanguage].text.length > 0;
  const isDescriptionValid = (language: LanguageCode) => !invalidDescriptions.includes(language);

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) =>
    setName((names) => ({
      ...names,
      [selectedLanguage]: { type: TranslationType.MANUAL, text: event.target.value },
    }));

  const onDescriptionChange = (text: string) =>
    setDescription((descriptions) => ({
      ...descriptions,
      [selectedLanguage]: { type: TranslationType.MANUAL, text },
    }));

  return (
    <>
      <LanguagesCarousel>
        {languages
          .reduce<Language[]>(
            (accumulator, next) => (next.id === collectionLanguage ? [next, ...accumulator] : [...accumulator, next]),
            [],
          )
          .map(({ id, label }) => (
            <StyledChip
              error={showError && (!isNameValid(id) || !isDescriptionValid(id))}
              key={id}
              onClick={() => setSelectedLanguage(id)}
              isSelected={id === selectedLanguage}
            >
              <div>{label}</div>
              <CollectionLanguageChipIndicator
                isComplete={
                  name[id].text.length > 0 &&
                  (description[collectionLanguage].text.length === 0 || description[id].text.length > 0)
                }
                isSelected={id === selectedLanguage}
              />
            </StyledChip>
          ))}
      </LanguagesCarousel>
      <StyledTextFieldContainer>
        <StyledTextInput
          disabled={selectedLanguage !== collectionLanguage}
          error={showError && !isNameValid(selectedLanguage)}
          label={textDictionary['app.create.collection.collection_title']}
          name="title-default-language"
          onChange={onNameChange}
          readOnly={selectedLanguage !== collectionLanguage}
          value={name[collectionLanguage].text}
        />
        {selectedLanguage === collectionLanguage || (
          <StyledTranslationChip>{collectionLanguage.toLocaleUpperCase()}</StyledTranslationChip>
        )}
      </StyledTextFieldContainer>
      {selectedLanguage === collectionLanguage || (
        <StyledTextInput
          error={showError && !isDescriptionValid(selectedLanguage)}
          name="title-selected-language"
          value={name[selectedLanguage].text}
          onChange={onNameChange}
        />
      )}
      <StyledTextFieldContainer>
        <StyledTextArea
          disabled={selectedLanguage !== collectionLanguage}
          isClearable={false}
          name="description"
          placeholder={textDictionary['app.create.collection.collection_description']}
          value={description[collectionLanguage].text}
          readOnly={selectedLanguage !== collectionLanguage}
          onValueChange={onDescriptionChange}
        />
        {selectedLanguage === collectionLanguage || (
          <StyledTranslationChip>{collectionLanguage.toLocaleUpperCase()}</StyledTranslationChip>
        )}
      </StyledTextFieldContainer>
      {selectedLanguage === collectionLanguage || (
        <StyledTextArea
          isClearable={false}
          name="description"
          value={description[selectedLanguage].text}
          onValueChange={onDescriptionChange}
        />
      )}
    </>
  );
};
