import {
  FetchLanguagesDefaultAction,
  FetchLanguagesRejectedAction,
  FetchLanguagesFulfilledAction,
  LanguageActionTypes,
} from 'entities/languages/actionTypes';
import { LanguagePayload } from 'entities/languages/types';

export const fetchLanguages = (): FetchLanguagesDefaultAction => ({
  type: LanguageActionTypes.FETCH_ALL,
});

export const fetchLanguagesSuccess = (payload: LanguagePayload): FetchLanguagesFulfilledAction => ({
  type: LanguageActionTypes.FETCH_ALL_FULFILLED,
  payload,
});

export const fetchLanguagesFailure = (): FetchLanguagesRejectedAction => ({
  type: LanguageActionTypes.FETCH_ALL_REJECTED,
});
