import { TopicCaseStudyActionTypes } from 'domains/caseStudy/actionTypes';
import { CaseStudyResponseObject, TopicCaseStudyPayload } from 'domains/caseStudy/types';

export const fetchCaseStudiesById = (payload: TopicCaseStudyPayload) => ({
  type: TopicCaseStudyActionTypes.FETCH_CASE_STUDIES,
  payload,
});

export const fetchCaseStudiesByIdSuccess = (
  payload: { topicId: string; data: CaseStudyResponseObject[] },
  meta: TopicCaseStudyPayload,
) => ({
  type: TopicCaseStudyActionTypes.FETCH_CASE_STUDIES_SUCCESS,
  payload,
  meta,
});

export const fetchCaseStudiesByIdSuccessFailure = (payload: { topicId: string }) => ({
  type: TopicCaseStudyActionTypes.FETCH_CASE_STUDIES_FAILURE,
  payload,
});
