import { useState, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { StyledModalText, ExpandableContent, ChevronIcon, UIButton } from 'common/components';
import { useTranslations } from 'domains/language/useTranslations';
import { ModalHeader } from 'domains/modal/components/ModalHeader';
import { getCoachmarks } from 'entities/coachmarks/selectors';

const StyledTips = styled.div`
  padding: 1rem 0 1rem 0;
`;

const StyledSubtitle = styled.p`
  margin: 0 0 0.5rem 0;
  font-size: 1.3rem;
  line-height: 1.6rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.textBright};
`;

const StyledTipWrapper = styled.div`
  margin-bottom: 1rem;
`;

const StyledTipImage = styled.img`
  display: block;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 8px;
  border: 1px solid;
  overflow: hidden;
  border-color: ${({ theme }) => theme.colors.outline};
`;

const StyledTipText = styled.p`
  margin: 0 0 1rem 0;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.colors.textFade};
  font-size: 1rem;
`;

const StyledUIButton = styled(UIButton)`
  background: ${({ theme }) => theme.colors.backgroundPrimary};
  &:hover {
    background: ${({ theme }) => theme.colors.backgroundPrimary};
  }
`;

const StyledReadMoreIcon = styled(ChevronIcon)<{ isActive: boolean }>`
  width: 10px;
  height: 10px;
  margin-left: 0.5rem;
  transform: ${({ isActive }) => (isActive ? 'rotate(-90deg)' : 'rotate(90deg)')};
`;

export const ViewAllCoachmarksModal = () => {
  const coachmarks = useSelector(getCoachmarks);
  const [expandedId, setExpandedId] = useState<string | null>(null);
  const { textDictionary } = useTranslations();

  const makeExpandTipButtonClickHandler = (id: string) => (event: MouseEvent) => {
    setExpandedId(expandedId === id ? null : id);
    event.preventDefault();
  };

  return (
    <>
      <ModalHeader title={textDictionary['app.coachmarks.view_all_coachmarks.title']} />
      <StyledModalText>{textDictionary['app.coachmarks.view_all_coachmarks.text']}</StyledModalText>
      <StyledTips>
        {coachmarks
          .filter(({ images }: any) => images)
          .map(({ id, title, description, images }: any) => (
            <StyledTipWrapper key={id}>
              <StyledSubtitle>{title}</StyledSubtitle>
              <StyledTipText>{description}</StyledTipText>
              <ExpandableContent isActive={expandedId === id}>
                <StyledTipImage src={images && images.medium} />
              </ExpandableContent>
              <StyledUIButton variant={'neutral'} onClick={makeExpandTipButtonClickHandler(id)}>
                {
                  textDictionary[
                    expandedId === id
                      ? 'app.coachmarks.view_all_coachmarks.hide_coachmark'
                      : 'app.coachmarks.view_all_coachmarks.view_coachmark'
                  ]
                }
                <StyledReadMoreIcon isActive={expandedId === id} />
              </StyledUIButton>
            </StyledTipWrapper>
          ))}
      </StyledTips>
    </>
  );
};
