import { TopicStakeholdersSearchCriteria } from 'domains/stakeholders/types';

export enum TopicStakeholderActionTypes {
  APPLY_SEARCH_CRITERIA = 'domains/STAKEHOLDERS_APPLY_SEARCH_CRITERIA',
  CLEAR_SEARCH_CRITERIA = 'domains/STAKEHOLDERS_CLEAR_SEARCH_CRITERIA',
}

type TopicStakeholdersApplySearchCriteriaPayload = Partial<TopicStakeholdersSearchCriteria> & { topicId?: string };

export type TopicStakeholdersApplySearchCriteriaAction = {
  payload?: TopicStakeholdersApplySearchCriteriaPayload;
  type: TopicStakeholderActionTypes.APPLY_SEARCH_CRITERIA;
};

export type TopicStakeholdersClearSearchCriteriaAction = { type: TopicStakeholderActionTypes.CLEAR_SEARCH_CRITERIA };
