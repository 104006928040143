import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { InfinityLoader } from 'common/components';
import { fetchClusterDigDeeperItems } from 'domains/clusters/actions';
import {
  getDigDeeperHasNextPage,
  getDigDeeperIsPending,
  getDigDeeperItems,
  getDigDeeperOffset,
} from 'domains/clusters/selectors';
import { KnowledgeListItem } from 'domains/publication/components/KnowledgeListItem';
import { getCanAccessMonitor } from 'entities/user/selectors';

type DigDeeperListProps = { clusterId: string };

export const DigDeeperList = ({ clusterId }: DigDeeperListProps) => {
  const dispatch = useDispatch();
  const items = useSelector(getDigDeeperItems(clusterId));
  const offset = useSelector(getDigDeeperOffset(clusterId));
  const isPending = useSelector(getDigDeeperIsPending(clusterId));
  const hasNextPage = useSelector(getDigDeeperHasNextPage(clusterId));
  const hasAccessToMonitor = useSelector(getCanAccessMonitor);

  useEffect(() => {
    dispatch(fetchClusterDigDeeperItems({ id: clusterId, offset: 0 }));
  }, [dispatch, clusterId]);

  const loadNextPage = useCallback(
    () => dispatch(fetchClusterDigDeeperItems({ id: clusterId, offset: offset + 1 })),
    [dispatch, clusterId, offset],
  );

  const getToLink = (knowledgeId: string) => `/monitor/emerging-themes/${clusterId}/dig-deeper/${knowledgeId}`;

  if (items.length === 0) {
    return null;
  }

  return (
    <InfinityLoader isPending={isPending} hasNextPage={hasNextPage} loadNextPage={loadNextPage}>
      {items.map((item, index) => (
        <KnowledgeListItem
          key={item.id}
          link={getToLink(item.id)}
          shareLink={getToLink(item.id)}
          knowledge={item}
          isLocked={!hasAccessToMonitor && index !== 0}
          isFirstLocked={index === 1}
          trackPage="Cluster detail"
        />
      ))}
    </InfinityLoader>
  );
};
