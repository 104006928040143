import { useDispatch, useSelector } from 'react-redux';
import { styled, ThemeProvider } from 'styled-components';

import { CrossIcon } from 'common/components/icons';
import { UIButton } from 'common/components/index';
import { breakpoints } from 'common/styles';
import { useLocalStorageState } from 'common/utilities';
import { trackEvent } from 'domains/analytics/actions';
import { TrackEvent, TrackPage } from 'domains/analytics/types';
import { getIsAuthenticated } from 'domains/authentication/selectors';
import { useTranslations } from 'domains/language/useTranslations';
import { darkTheme, lightTheme } from 'domains/theme/constants/theme';
import { useThemeAppearance } from 'domains/theme/useThemeAppearance';
import { getShouldShowBecomeMemberMarketing, getUserRole } from 'entities/user/selectors';
import { getLinkToMembershipPage } from 'entities/user/utils';
import { useAuth } from 'useAuth';

const CALL_TO_ACTION_TIMESTAMP = 'topic_detail_call_to_action_banner';

const StyledContainer = styled.div`
  padding: 1.5rem;
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  color: ${({ theme }) => theme.colors.textBright};
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 50px;
  max-height: 600px;
  transition:
    min-height 0.3s,
    max-height 0.3s;
  border-radius: 5px;
`;

const StyledMessageContainer = styled.div`
  margin-right: 20px;
  margin-bottom: 20px;
`;

const StyledButtonContainer = styled.div<{ hasDismissAction?: boolean }>`
  display: flex;
  align-items: center;
  margin: 0;
  width: 100%;

  @media ${breakpoints.medium} {
    width: auto;
    padding-right: ${({ hasDismissAction }) => (hasDismissAction ? 2 : 0)}rem;
  }
`;

const StyledCloseIcon = styled(CrossIcon)`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
  color: ${({ theme }) => theme.colors.textFade};
  cursor: pointer;
  margin-left: 3rem;
  transition: all 0.3s;
  border-radius: 20px;
  padding: 7px;
  &:hover {
    color: ${({ theme }) => theme.colors.textBright};
    background: ${({ theme }) => theme.colors.textBright}0F;
  }
`;

const LoginButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

const StyledLabel = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.textFade};
`;

const StyledTitle = styled.h3`
  font-size: 1.4rem;
  margin: 0;
`;

const StyledMessage = styled.p`
  margin: 1rem 0 0;
  font-size: 1rem;
  line-height: 1.4rem;
`;

type JoinUsUpgradeBannerProps = { className?: string; trackPage: TrackPage };

export const JoinUsUpgradeBanner = ({ className, trackPage }: JoinUsUpgradeBannerProps) => {
  const dispatch = useDispatch();
  const { textDictionary } = useTranslations();
  const showUpgradeBanner = useSelector(getShouldShowBecomeMemberMarketing);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const { triggerLogin } = useAuth();
  const userRole = useSelector(getUserRole);
  const theme = useThemeAppearance();

  const [dismissedModalValue, setDismissedModalValue] = useLocalStorageState(CALL_TO_ACTION_TIMESTAMP, null);

  const handleUpgradeLinkClick = () => {
    dispatch(
      trackEvent({
        name: 'Upgrade',
        page: trackPage,
        subpage: 'Message banner',
      }),
    );
    window.location.href = getLinkToMembershipPage(userRole);
  };

  const onLoginRegisterClick = (name: TrackEvent) => () => {
    dispatch(trackEvent({ name, eventType: 'initialize modal' }));
    triggerLogin();
  };

  const onDismissClick = () => {
    const date = new Date();
    setDismissedModalValue(date.getTime().toString());
  };

  if (dismissedModalValue || (isAuthenticated && !showUpgradeBanner)) {
    return null;
  }

  const upgradeButton = (
    <UIButton
      data-test-id={'topic-upgrade-banner-upgrade-button'}
      onClick={handleUpgradeLinkClick}
      variant={'outlined'}
    >
      {textDictionary['app.message_banner.upgrade.button']}
    </UIButton>
  );

  const loginSignupButtons = (
    <LoginButtonsContainer>
      <UIButton
        data-test-id={'topic-upgrade-banner-signup-button'}
        onClick={onLoginRegisterClick('Signup')}
        variant={'filled'}
      >
        {textDictionary['app.registration.join_us']}
      </UIButton>
      <UIButton
        data-test-id={'topic-upgrade-banner-login-button'}
        onClick={onLoginRegisterClick('Login')}
        variant={'outlined'}
      >
        {textDictionary['app.user.sign_in']}
      </UIButton>
    </LoginButtonsContainer>
  );

  const button = isAuthenticated ? upgradeButton : loginSignupButtons;

  const message = isAuthenticated ? (
    <>
      <StyledTitle>{textDictionary['app.upgrade_banner.title']}</StyledTitle>
      <StyledMessage>{textDictionary['app.upgrade_banner.message']}</StyledMessage>
    </>
  ) : (
    <>
      <StyledLabel>{textDictionary['app.discover.topic.tabs.sign_up_ad.label']}</StyledLabel>
      <StyledTitle>{textDictionary['app.discover.topic.tabs.sign_up_ad.title']}</StyledTitle>
      <StyledMessage>{textDictionary['app.discover.topic.tabs.sign_up_ad.expert_analysis']}</StyledMessage>
      <StyledMessage>{textDictionary['app.discover.topic.tabs.sign_up_ad.research']}</StyledMessage>
    </>
  );

  if (dismissedModalValue) return null;

  return (
    <ThemeProvider theme={theme === 'light' ? darkTheme : lightTheme}>
      <StyledContainer className={className}>
        <StyledMessageContainer>{message}</StyledMessageContainer>
        <StyledButtonContainer hasDismissAction={!!onDismissClick}>{button}</StyledButtonContainer>
        {onDismissClick && <StyledCloseIcon onClick={onDismissClick} />}
      </StyledContainer>
    </ThemeProvider>
  );
};
