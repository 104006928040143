import { PropsWithChildren, useState } from 'react';
import { useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { UserPlaceholderIcon } from 'common/components/icons';
import { getUserData } from 'entities/user/selectors';

const StyledRelativeContainer = styled.div`
  position: relative;
`;

const StyledUserIcon = styled(UserPlaceholderIcon)`
  width: 100%;
  height: 100%;
`;

const StyledProfileImage = styled.img<{ displayProfilePicture: boolean }>`
  transition: opacity 0.3s;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 50%;
  object-fit: cover;
  opacity: ${({ displayProfilePicture }) => (displayProfilePicture ? 1 : 0)};
`;

type UserProfileImageProps = PropsWithChildren & { className?: string };

export const UserProfileImage = ({ children, className }: UserProfileImageProps) => {
  const userData = useSelector(getUserData);
  const [displayProfilePicture, setDisplayProfilePicture] = useState(false);

  const onLoad = () => setDisplayProfilePicture(true);
  const onError = () => setDisplayProfilePicture(false);

  return (
    <StyledRelativeContainer className={className}>
      <StyledUserIcon />
      {userData?.imageUrl && (
        <StyledProfileImage
          src={userData?.imageUrl}
          onLoad={onLoad}
          onError={onError}
          displayProfilePicture={displayProfilePicture}
        />
      )}
      {children}
    </StyledRelativeContainer>
  );
};
