import { useFullScreen } from 'common/utilities';
import { useTranslations } from 'domains/language/useTranslations';

export const FullScreenButton = () => {
  const { isFullScreen, isAvailable, toggle } = useFullScreen();
  const { textDictionary } = useTranslations();

  if (!isAvailable) {
    return null;
  }

  return (
    <div onClick={toggle}>
      {isFullScreen ? textDictionary['app.fullscreen_exit.title'] : textDictionary['app.fullscreen.title']}
    </div>
  );
};
