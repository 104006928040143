import {
  AddKeyIssueToMapDefaultAction,
  AddKeyIssueToMapFulfilledAction,
  AddKeyIssueToMapRejectedAction,
  AddNewCustomKeyIssueAction,
  CreateInitFulfilledAction,
  CreateMapTypes,
  MapSetAction,
  MapSetImageAction,
  RemoveAllKeyIssuesFromMapAction,
  RemoveKeyIssueFromMapAction,
  ResetMap,
  SetMapDraggingAction,
  UpdateAIGeneratedMapProgressAction,
  UpdateAIGeneratedMapProgressSuccessAction,
  UploadMapDefaultAction,
  UploadMapFulfilledAction,
  UploadMapRejectedAction,
} from 'domains/createMapDetail/actionTypes';
import { LocalImage, UploadMapMeta, AIGeneratedMapProgress } from 'domains/createMapDetail/types';
import { CustomKeyIssueMeta } from 'domains/customKeyIssue/types';
import { GetAIGeneratedTopicDetailsSuccessAction, TopicDetailType } from 'domains/topicDetail/actionTypes';
import { Topic } from 'entities/topics/types';

export const initiateCreateModeFulfilled = (payload: Topic): CreateInitFulfilledAction => ({
  type: CreateMapTypes.INIT_FULFILLED,
  payload,
});

export const uploadMap = (meta?: UploadMapMeta): UploadMapDefaultAction => ({
  type: CreateMapTypes.UPLOAD,
  meta,
});

export const uploadMapSuccessful = (payload: UploadMapFulfilledAction['payload']): UploadMapFulfilledAction => ({
  type: CreateMapTypes.UPLOAD_FULFILLED,
  payload,
});

export const uploadMapFailure = (
  payload: UploadMapRejectedAction['payload'],
  meta: UploadMapRejectedAction['meta'],
): UploadMapRejectedAction => ({
  type: CreateMapTypes.UPLOAD_REJECTED,
  payload,
  meta,
});

export const setCustomMapFields = (meta: Partial<Topic>): MapSetAction => ({
  type: CreateMapTypes.SET,
  meta,
});

export const setMapImage = (image: LocalImage): MapSetImageAction => ({
  type: CreateMapTypes.SET_IMAGE,
  meta: { image },
});

export const resetCreateMap = (): ResetMap => ({
  type: CreateMapTypes.RESET,
});

export const addKeyIssueToMap = (meta: AddKeyIssueToMapDefaultAction['meta']): AddKeyIssueToMapDefaultAction => ({
  type: CreateMapTypes.ADD_KEY_ISSUE,
  meta,
});

export const addKeyIssueToMapSuccess = (
  payload: AddKeyIssueToMapFulfilledAction['payload'],
  meta: AddKeyIssueToMapFulfilledAction['meta'],
): AddKeyIssueToMapFulfilledAction => ({
  type: CreateMapTypes.ADD_KEY_ISSUE_FULFILLED,
  payload,
  meta,
});

export const addKeyIssueToMapFailure = (
  meta: AddKeyIssueToMapRejectedAction['meta'],
): AddKeyIssueToMapRejectedAction => ({
  type: CreateMapTypes.ADD_KEY_ISSUE_REJECTED,
  meta,
});

export const removeTopicFromMap = (payload: RemoveKeyIssueFromMapAction['payload']): RemoveKeyIssueFromMapAction => ({
  type: CreateMapTypes.REMOVE_KEY_ISSUE,
  payload,
});

export const removeAllTopicsFromMap = (): RemoveAllKeyIssuesFromMapAction => ({
  type: CreateMapTypes.REMOVE_ALL_KEY_ISSUES,
});

export const enterAIGeneratedMap = () => ({
  type: CreateMapTypes.ENTER_AI_GENERATED_MODE,
});

export const exitAIGeneratedMap = () => ({
  type: CreateMapTypes.EXIT_AI_GENERATED_MODE,
});

export const toggleDraggingCustomMap = (meta: SetMapDraggingAction['meta']): SetMapDraggingAction => ({
  type: CreateMapTypes.SET_DRAGGING,
  meta,
});

export const addNewCustomKeyIssueToMap = (payload: CustomKeyIssueMeta): AddNewCustomKeyIssueAction => ({
  type: CreateMapTypes.ADD_NEW_CUSTOM_KEY_ISSUE,
  payload,
});

export const getAIGeneratedMapDetailsSuccess = (payload: Topic): GetAIGeneratedTopicDetailsSuccessAction => ({
  type: TopicDetailType.GET_AI_GENERATED_DETAILS_SUCCESS,
  payload,
});

export const getAIGeneratedMapDetailsFailure = () => ({
  type: TopicDetailType.GET_AI_GENERATED_DETAILS_FAILURE,
});

export const getAIGeneratedMapDetailsNotValidPrompt = (errorCode: number) => ({
  type: TopicDetailType.GET_AI_GENERATED_DETAILS_NOT_VALID_PROMPT,
  errorCode,
});

export const updateAIGeneratedMapProgress = (): UpdateAIGeneratedMapProgressAction => ({
  type: CreateMapTypes.GET_AI_GENERATED_PROGRESS,
});

export const updateAIGeneratedMapProgressSuccess = (
  meta: AIGeneratedMapProgress,
): UpdateAIGeneratedMapProgressSuccessAction => ({
  type: CreateMapTypes.GET_AI_GENERATED_PROGRESS_SUCCESS,
  meta,
});
