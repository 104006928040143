import { SVGProps } from 'react';

export const MagicWandIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path
      d="m2685.1 2085.849-2-2 1.013-3.038a.529.529 0 0 0-.127-.54.522.522 0 0 0-.538-.125l-3.039 1.011-2-2a.525.525 0 0 0-.894.429l.333 3-3.042 1.69a.527.527 0 0 0 .138.973l2.1.483-6.729 6.728a1.052 1.052 0 1 0 1.488 1.488l6.729-6.727.484 2.1a.525.525 0 0 0 .439.4.573.573 0 0 0 .074.005.525.525 0 0 0 .46-.271l1.689-3.042 3 .334a.527.527 0 0 0 .43-.9"
      transform="translate(-2670 -2079)"
      fill="currentColor"
    />
  </svg>
);
