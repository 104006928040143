import { useSelector } from 'react-redux';

import { useTranslations } from 'domains/language/useTranslations';
import { UpgradeModal } from 'domains/modal/components/UpgradeModal';
import { getFollowTopicLimit } from 'entities/user/selectors';

export const FollowTopicsLimitModalUpgradeModal = () => {
  const { textDictionary, interpolate } = useTranslations();
  const followTopicsLimit = useSelector(getFollowTopicLimit);

  const message = interpolate(textDictionary['app.topic.follow.limit.upgrade_message'], {
    limit: () => followTopicsLimit || '',
  });

  return (
    <UpgradeModal
      title={textDictionary['app.membership_portal']}
      message={message}
      primaryButtonText={textDictionary['app.message_banner.upgrade.button.2']}
      additionalPrimaryButtonTrackMeta={{ page: 'Follow Topics Limit Modal' }}
      upgradeLocation="premium"
    />
  );
};
