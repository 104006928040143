import { memo } from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

import { Image } from 'common/components';
import { ListItemWrapper } from 'common/components/lists/ListItemWrapper';
import { breakpoints, breakWordStyle, lineTruncationStyle } from 'common/styles';
import { useInternalLink } from 'common/utilities';
import { TrackPage } from 'domains/analytics/types';
import { ClusterCaption } from 'domains/clusters/components/ClusterCaption';
import { ClusterFooter } from 'domains/clusters/components/ClusterFooter';
import { Cluster } from 'domains/clusters/types';

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.fillPrimary};
  text-decoration: none;
`;

const StyledTitle = styled.div<{ isCompact: boolean }>`
  font-weight: bold;
  font-size: ${({ isCompact }) => (isCompact ? 1.2 : 1.55)}rem;
  margin-bottom: 1rem;
  ${breakWordStyle}
`;

const StyledSummary = styled.p`
  font-weight: 300;
  font-size: 1rem;
  margin: 0;
  line-height: 1.25rem;

  ${breakWordStyle}
  ${lineTruncationStyle(8)}
`;

const StyledImage = styled(Image)`
  width: 100%;
  height: 250px;
  border-radius: 4px;
  margin: 1rem 0 2rem;
`;

const StyledCompactImage = styled(Image)`
  width: 120px;
  min-width: 120px;
  height: 90px;
  border-radius: 4px;
  margin-right: 15px;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;

  @media ${breakpoints.large} {
    margin: 10px 0;
  }
`;

const StyledCompactSummary = styled.p`
  transition: color 0.3s;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0;

  ${breakWordStyle}
  ${lineTruncationStyle(3)};
`;

type ClusterListItemProps = {
  cluster: Cluster;
  isCompact?: boolean;
  isFirstLocked?: boolean;
  isLocked?: boolean;
  isOnCard?: boolean;
  isSelected?: boolean;
  link: string;
  onClick?: () => void;
  shareLink: string;
  trackPage: TrackPage;
};

export const ClusterListItem = memo(
  ({
    cluster,
    isCompact = false,
    isFirstLocked,
    isLocked = false,
    isOnCard = true,
    isSelected = false,
    link,
    onClick,
    shareLink,
    trackPage,
  }: ClusterListItemProps) => {
    const { title, description, images, time, knowledge_count } = cluster;
    const linkLocation = useInternalLink(link);

    return (
      <ListItemWrapper
        isLocked={isLocked}
        isFirstLocked={isFirstLocked}
        trackPage={trackPage}
        isSelected={isSelected}
        isOnCard={isOnCard}
      >
        <StyledLink to={linkLocation} onClick={onClick}>
          {isCompact ? (
            <>
              <ClusterCaption time={time} knowledgeCount={knowledge_count} />
              <StyledTitle isCompact={isCompact}>{title}</StyledTitle>
              <StyledContent>
                {images && <StyledCompactImage src={images?.x_small} alt={title} />}
                <StyledCompactSummary>{description}</StyledCompactSummary>
              </StyledContent>
            </>
          ) : (
            <>
              <StyledImage src={images?.thumbnail} alt={title} />
              <StyledTitle isCompact={isCompact}>{title}</StyledTitle>
              <ClusterCaption time={time} knowledgeCount={knowledge_count} />
              <StyledSummary>{description}</StyledSummary>
            </>
          )}
        </StyledLink>
        <ClusterFooter cluster={cluster} shareLink={shareLink} showIcons={!isCompact} />
      </ListItemWrapper>
    );
  },
);

ClusterListItem.displayName = 'ClusterListItem';
