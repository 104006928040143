import { SVGProps } from 'react';

export const PlayIconCircled = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="20" cy="20" r="20" transform="translate(2 2)" fill="rgba(0,0,0,0.5)" />
    <circle
      cx="21"
      cy="21"
      r="21"
      transform="translate(1 1)"
      fill="none"
      stroke="rgba(255,255,255,0.4)"
      strokeWidth="2"
    />
    <path
      d="M17.333,28.277V15.723a1,1,0,0,1,1.5-.868l10.984,6.277a1,1,0,0,1,0,1.736L18.83,29.145A1,1,0,0,1,17.333,28.277Z"
      fill="none"
      stroke="#fff"
      strokeWidth="2"
    />
  </svg>
);
