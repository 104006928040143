import { SVGProps } from 'react';

export const LockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 18" {...props}>
    <path
      fill="currentColor"
      d="M183.042,44.8a4.932,4.932,0,0,0-4.929,4.929v2.357H177.9a1.511,1.511,0,0,0-1.5,1.5V61.3a1.511,1.511,0,0,0,1.5,1.5h10.286a1.511,1.511,0,0,0,1.5-1.5V53.587a1.511,1.511,0,0,0-1.5-1.5h-.214V49.73a4.932,4.932,0,0,0-4.929-4.929Zm0,1.286a3.621,3.621,0,0,1,3.643,3.643v2.357H179.4V49.73a3.621,3.621,0,0,1,3.643-3.643ZM177.9,53.372h10.286a.2.2,0,0,1,.214.214V61.3a.2.2,0,0,1-.214.214H177.9a.2.2,0,0,1-.214-.214V53.587A.2.2,0,0,1,177.9,53.372Z"
      transform="translate(-176.399 -44.801)"
    />
  </svg>
);
