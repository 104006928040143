import GooglePlayStoreLogoImage1x from 'domains/about/images/footer/google_play_store.png';
import GooglePlayStoreLogoImage2x from 'domains/about/images/footer/google_play_store@2x.png';
import GooglePlayStoreLogoImage3x from 'domains/about/images/footer/google_play_store@3x.png';
import HuaweiStoreLogoImage1x from 'domains/about/images/footer/huawei_app_store_logo.png';
import HuaweiStoreLogoImage2x from 'domains/about/images/footer/huawei_app_store_logo@2x.png';
import HuaweiStoreLogoImage3x from 'domains/about/images/footer/huawei_app_store_logo@3x.png';
import SIAppLogoImage1x from 'domains/about/images/footer/si_app_icon.png';
import SIAppLogoImage2x from 'domains/about/images/footer/si_app_icon@2x.png';
import SIAppLogoImage3x from 'domains/about/images/footer/si_app_icon@3x.png';

export const SIAppLogoImageSet = [SIAppLogoImage1x, SIAppLogoImage2x, SIAppLogoImage3x];

export const GooglePlayStoreLogoImageSet = [
  GooglePlayStoreLogoImage1x,
  GooglePlayStoreLogoImage2x,
  GooglePlayStoreLogoImage3x,
];

export const HuaweiStoreLogoImageSet = [HuaweiStoreLogoImage1x, HuaweiStoreLogoImage2x, HuaweiStoreLogoImage3x];
