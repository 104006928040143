import debounce from 'lodash.debounce';
import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { styled } from 'styled-components';

import { UIInput } from 'common/components/form/UIInput';
import { Spinner } from 'common/components/Spinner';
import { fetchUnsplashImages } from 'common/components/unsplash/utils';

const StyledCenteringContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

const StyledSpinner = styled(Spinner)`
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.colors.fillTertiary};
`;

const ImageGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 1rem;
`;

const ImageContainer = styled.div`
  align-self: center;
  position: relative;
  margin-bottom: 1px;
  border: 1px solid transparent;
  border-radius: 2px;
  width: calc(33% - 1px);
  height: 110px;
  overflow: hidden;
  font-size: 0.8rem;

  &:hover {
    border: 1px solid #fff;
  }
`;

const UnsplashImage = styled.img<{ isDownloading?: boolean }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: ${({ isDownloading }) => (isDownloading ? '0.2' : '1')};
  cursor: pointer;

  ${ImageContainer}:hover & {
    opacity: 0.5;
  }
`;

const ImageAttribution = styled.a`
  display: none;
  position: absolute;
  bottom: 5px;
  left: 5px;
  text-decoration: none;
  white-space: nowrap;
  color: #fff;

  &:hover {
    text-decoration: underline;
  }

  ${ImageContainer}:hover & {
    display: block;
  }
`;

const StyledImageSpinner = styled(Spinner)`
  position: absolute;
  top: 25%;
  left: 25%;
  width: 50%;
  height: 50%;
  color: ${({ theme }) => theme.colors.fillPrimary};
`;

type UnsplashGalleryProps = { onError: () => void; onImageClick: (image: string) => void };

type UnsplashImageProps = {
  alt_description: string;
  id: string;
  links: { download_location: string };
  urls: { small: string };
  user: { links: { html: string }; name: string };
};

export const UnsplashGallery = ({ onImageClick, onError }: UnsplashGalleryProps) => {
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [images, setImages] = useState<UnsplashImageProps[]>([]);
  const [downloadingImageId, setDownloadingImageId] = useState<string | null>(null);
  const isCleaningUpRef = useRef(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const downloadImagesFromUnsplash = useCallback(
    debounce(async (query?: string) => {
      if (!query || query === '') {
        query = 'business';
      }
      // Request images from unsplash
      if (!isCleaningUpRef.current) {
        try {
          setIsSearching(true);
          const image = await fetchUnsplashImages(query);
          if (!isCleaningUpRef.current) {
            setImages(image.results.slice(0, 9));
          }
        } catch {
          onError();
        } finally {
          setIsSearching(false);
        }
      }
    }, 500),
    [],
  );

  useEffect(() => {
    downloadImagesFromUnsplash();
    return () => {
      isCleaningUpRef.current = true;
    };
  }, [downloadImagesFromUnsplash]);

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
    downloadImagesFromUnsplash(value);
  };

  const handleImageClick = (image: UnsplashImageProps) => async (e: MouseEvent) => {
    e.preventDefault();
    setDownloadingImageId(image.id);
    const imageUrl = image.links.download_location;
    await onImageClick(imageUrl);
    setDownloadingImageId(null);
  };

  return (
    <>
      <UIInput onChange={handleSearchChange} value={searchTerm} />
      {isSearching && (
        <StyledCenteringContainer>
          <StyledSpinner />
        </StyledCenteringContainer>
      )}
      <ImageGrid>
        {images.map((image) => (
          <ImageContainer key={image.id} data-test-id={`unsplash-container`}>
            <UnsplashImage
              alt={image.alt_description}
              src={image.urls.small}
              onClick={handleImageClick(image)}
              isDownloading={downloadingImageId === image.id}
            />
            <ImageAttribution
              href={`${image.user.links.html}?utm_source=strategic_intelligence&utm_medium=referral`}
              target="_blank"
            >
              {image.user.name}
            </ImageAttribution>
            {downloadingImageId === image.id && <StyledImageSpinner />}
          </ImageContainer>
        ))}
      </ImageGrid>
    </>
  );
};
