import { WhatsNewPayload } from 'domains/whatsNew/types';
import { ErrorPayload } from 'types';

export enum WhatsNewActionType {
  FETCH = '[Whats new] - Fetch',
  FETCH_FULFILLED = '[Whats new] - Fetch Fulfilled',
  FETCH_REJECTED = '[Whats new] - Fetch Rejected',
  UPDATE_BADGE = '[Whats new] - Update Badge',
}

export type FetchWhatsNewAction = { type: WhatsNewActionType.FETCH };
export type FetchWhatsNewActionFulfilled = { payload: WhatsNewPayload; type: WhatsNewActionType.FETCH_FULFILLED };
export type FetchWhatsNewActionRejected = { payload: ErrorPayload; type: WhatsNewActionType.FETCH_REJECTED };
export type UpdateWhatsNewBadgeAction = { payload: { showBadge: boolean }; type: WhatsNewActionType.UPDATE_BADGE };

export type WhatsNewAction =
  | FetchWhatsNewAction
  | FetchWhatsNewActionFulfilled
  | FetchWhatsNewActionRejected
  | UpdateWhatsNewBadgeAction;
