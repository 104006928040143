import { styled } from 'styled-components';

import { MediaPlayer, SourceLabel } from 'common/components';
import { Knowledge } from 'entities/knowledge/types';

const StyledSourceLabel = styled(SourceLabel)`
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.textBright};
`;

const StyledVideo = styled(MediaPlayer)`
  border: none;
  overflow: hidden;
  border-radius: 5px;
  max-height: 60vh;
`;

type VideoDetailProps = { knowledge: Knowledge };

export const VideoDetail = ({ knowledge }: VideoDetailProps) => {
  const url = knowledge.embedded_media?.url ?? knowledge.attachment?.url;
  if (!url) {
    return null;
  }

  return (
    <>
      <StyledVideo
        url={url}
        isVideo={!knowledge.embedded_media?.url}
        title={knowledge.title}
        html={knowledge.embedded_media?.html}
        height={knowledge.embedded_media?.height}
        width={knowledge.embedded_media?.width}
      />
      <StyledSourceLabel source={knowledge.source} date={knowledge.time} type="small" />
    </>
  );
};
