import { put, select, delay } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { searchConceptFulfilled, searchConceptRejected } from 'domains/customKeyIssue/actions';
import { CustomKeyIssueConceptSearchDefaultAction } from 'domains/customKeyIssue/actionTypes';
import { getCustomKeyIssueConceptListForQuery } from 'domains/customKeyIssue/selectors';
import { Concept } from 'domains/customKeyIssue/types';

export function* fetchConceptSearchSaga({ meta }: CustomKeyIssueConceptSearchDefaultAction) {
  let hasTriedOnce = false;
  while (true) {
    try {
      const { query, limit = 5 } = meta;
      let results: Concept[] | undefined = yield select(getCustomKeyIssueConceptListForQuery(query));
      if (!query || query.length < 3) {
        results = [];
      } else if (!results) {
        const response: Concept[] = yield apiFetch(`concept/${query}`, {
          queryParams: { limit },
        });
        results = response;
      }
      yield put(searchConceptFulfilled(meta, results));
      return;
    } catch (error) {
      if (hasTriedOnce) {
        yield put(searchConceptRejected(meta));
        return;
      }
      yield delay(1500);
      hasTriedOnce = true;
    }
  }
}
