import { combineReducers } from 'redux';

import { MapShareAction, MapShareType } from 'domains/createShareSettings/actionTypes';
import {
  MapShareState,
  PublicLinkState,
  InvitationState,
  CollaboratorState,
  ShareLinkState,
  OpenLinkState,
} from 'domains/createShareSettings/types';

const publicLinkInitialState: PublicLinkState = {
  isPending: false,
  error: false,
  isPublic: false,
};

const publicLink = (state = publicLinkInitialState, action: MapShareAction): PublicLinkState => {
  switch (action.type) {
    case MapShareType.PUBLIC_LINK:
      return {
        ...state,
        isPending: true,
      };
    case MapShareType.PUBLIC_LINK_FULFILLED:
      return {
        ...state,
        isPending: false,
        error: false,
        isPublic: true,
      };
    case MapShareType.PUBLIC_LINK_REJECTED:
      return {
        ...state,
        error: true,
        isPending: false,
      };
    default:
      return state;
  }
};

const invitationInitialState: InvitationState = {
  isPending: false,
  error: false,
};

const invitation = (state = invitationInitialState, action: MapShareAction): InvitationState => {
  switch (action.type) {
    case MapShareType.INVITE: {
      return {
        ...state,
        isPending: true,
      };
    }
    case MapShareType.INVITE_FULFILLED: {
      return {
        ...state,
        isPending: false,
        error: false,
      };
    }
    case MapShareType.INVITE_REJECTED: {
      return {
        ...state,
        isPending: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

const collaboratorsInitialState: CollaboratorState = {
  list: [],
  error: false,
  isPending: false,
  data: {},
};

const collaborators = (state = collaboratorsInitialState, action: MapShareAction): CollaboratorState => {
  switch (action.type) {
    case MapShareType.INVITE_FULFILLED: {
      const data = { ...state.data };
      action.payload.forEach((item) => {
        data[item.email] = item;
      });
      return {
        ...state,
        list: [...state.list, ...(action.payload || []).map((item) => item.email)],
        data,
      };
    }
    case MapShareType.ACCESS_LIST: {
      return {
        ...state,
        isPending: true,
      };
    }
    case MapShareType.ACCESS_LIST_FULFILLED: {
      const data = { ...state.data };
      action.payload.forEach((item) => {
        data[item.email] = item;
      });
      return {
        ...state,
        isPending: false,
        error: false,
        list: (action.payload || []).map((item) => item.email),
        data,
      };
    }
    case MapShareType.ACCESS_LIST_REJECTED: {
      return {
        ...state,
        isPending: false,
        error: true,
      };
    }
    case MapShareType.REMOVE_INVITE: {
      if (!state.data) {
        return state;
      }
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.email]: {
            ...state.data[action.meta.email],
            removePending: true,
          },
        },
      };
    }
    case MapShareType.REMOVE_INVITE_FULFILLED: {
      if (!state.data) {
        return state;
      }
      const { [action.meta.email]: _, ...data } = state.data;
      return {
        ...state,
        list: state.list.filter((id) => id !== action.meta.email),
        data,
      };
    }
    case MapShareType.REMOVE_INVITE_REJECTED: {
      if (!state.data) {
        return state;
      }
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.email]: {
            ...state.data[action.meta.email],
            removePending: false,
          },
        },
      };
    }
    case MapShareType.CHANGE_INVITE_PERMISSION: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.email]: {
            ...state.data[action.meta.email],
            isPermissionChanging: true,
          },
        },
      };
    }
    case MapShareType.CHANGE_INVITE_PERMISSION_REJECTED:
    case MapShareType.CHANGE_INVITE_PERMISSION_FULFILLED: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.email]: {
            ...state.data[action.meta.email],
            isPermissionChanging: false,
          },
        },
      };
    }
    default: {
      return state;
    }
  }
};

const shareLinkInitialState: ShareLinkState = {
  isPending: false,
};

const shareLink = (state = shareLinkInitialState, action: MapShareAction): ShareLinkState => {
  switch (action.type) {
    case MapShareType.CREATE_LINK:
      return {
        ...state,
        isPending: true,
      };
    case MapShareType.CREATE_LINK_FULFILLED:
      return {
        ...state,
        isPending: false,
        error: false,
        token: action.payload.open_mapshare_token,
      };
    case MapShareType.CREATE_LINK_REJECTED:
      return {
        ...state,
        error: true,
        isPending: false,
      };
    default:
      return state;
  }
};

const openLinkInitialState: OpenLinkState = {
  isPending: false,
  isFulfilled: false,
};

const openLink = (state = openLinkInitialState, action: MapShareAction): OpenLinkState => {
  switch (action.type) {
    case MapShareType.OPEN_MAP_INVITATION:
      return {
        ...state,
        isPending: true,
      };
    case MapShareType.OPEN_MAP_INVITATION_FULFILLED:
      return {
        ...state,
        isPending: false,
        error: false,
        isFulfilled: true,
        data: action.payload,
      };
    case MapShareType.OPEN_MAP_INVITATION_REJECTED:
      return {
        ...state,
        error: true,
        isPending: false,
      };
    default:
      return state;
  }
};

export const shareMapReducer = combineReducers<MapShareState>({
  publicLink,
  invitation,
  collaborators,
  shareLink,
  openLink,
});
