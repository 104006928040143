import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { EditIcon } from 'common/components';
import { getIsCustomKeyIssueNew } from 'domains/createMapDetail/selectors';
import { loadCustomKeyIssue, toggleCustomKeyIssueForm } from 'domains/customKeyIssue/actions';
import { useTranslations } from 'domains/language/useTranslations';
import { getDimensionByIdIsFetching } from 'entities/keyIssues/selectors';

const StyledButton = styled.button<{ isPending: boolean }>`
  transition: 0.3s;
  color: ${({ theme }) => theme.colors.textFade};
  background: none;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.activeButtonHoverBackground};
    color: ${({ theme }) => theme.colors.textBright};
  }
`;

const StyledEditIcon = styled(EditIcon)`
  width: 12px;
  height: 12px;
  padding-right: 1rem;
`;

type EditCustomKeyIssueButtonProps = { id: string; onEditSelect?: (id: string) => void };

export const EditCustomKeyIssueButton = ({ id, onEditSelect: onEdit }: EditCustomKeyIssueButtonProps) => {
  const dispatch = useDispatch();
  const isPending = useSelector(getDimensionByIdIsFetching(id));
  const isCustomKeyIssueNew = useSelector(getIsCustomKeyIssueNew(id));
  const { textDictionary } = useTranslations();

  const onEditClick = () => {
    if (!isCustomKeyIssueNew) {
      dispatch(loadCustomKeyIssue({ id }));
    } else {
      dispatch(toggleCustomKeyIssueForm({ id, isActive: true }));
    }
    if (onEdit) {
      onEdit(id);
    }
  };

  return (
    <StyledButton isPending={isPending} onClick={onEditClick} data-test-id={'edit-custom-key-issue-button'}>
      <StyledEditIcon />
      {textDictionary['app.topic.actions.edit']}
    </StyledButton>
  );
};
