import { User } from 'oidc-client-ts';
import { call, select } from 'redux-saga/effects';

import { logResponseError } from 'common/utilities/logging';
import { getIdToken } from 'domains/authentication/selectors';
import { getSiteLanguage } from 'domains/language/selectors';
import { TokenManagerSingleton } from 'TokenManager';

export type QueryValue = string | string[] | number | undefined | null | boolean;

export const getIdTokenFromLocalStorage = (): string => {
  let key;
  const partialKey = 'oidc.user:';
  for (let n = 0; n < localStorage.length; ++n) {
    const thisKey = localStorage.key(n);
    if (thisKey!.includes(partialKey)) {
      // found it
      key = thisKey;
      break;
    }
  }
  if (key) {
    const keyItem = localStorage.getItem(key) as string;

    const user = User.fromStorageString(keyItem);
    return user.id_token || '';
  }
  return '';
};

const getQueryValue = (data: QueryValue) => {
  if (Array.isArray(data)) {
    if (data.length === 0) {
      return undefined;
    }
    return data.join(',');
  }
  return data;
};

const getQueryString = (query: Record<string, QueryValue>) => {
  const qs = Object.keys(query)
    .map((key) => [key, getQueryValue(query[key])])
    .filter((pair) => pair[0] !== null && pair[0] !== undefined && pair[1] !== null && pair[1] !== undefined)
    .map((pair) => (pair as string[]).map(encodeURIComponent).join('='))
    .join('&');
  return qs && `?${qs}`;
};

const getHeaders = (
  accessToken?: string,
  language?: string,
  contentType?: string,
  headers: Record<string, string> = {},
): Record<string, string> => ({
  ...headers,
  'x-api-key': process.env.REACT_APP_API_KEY || '',
  ...(contentType && { 'Content-Type': contentType }),
  ...(accessToken && { 'X-Authorization': `Bearer ${accessToken}` }),
  ...(language && { 'Accept-Language': language }),
});
type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

const fetchApi = async <BodyType>(
  endpoint: string,
  method: HttpMethod,
  contentType?: string,
  accessToken?: string,
  language?: string,
  body?: Record<string, BodyType> | FormData,
  extraHeaders?: Record<string, string>,
  baseUrl?: string,
) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise<Response>(async (resolve, reject) => {
    try {
      const headers = getHeaders(accessToken, language, contentType, extraHeaders);
      const options: Record<string, any> = {
        method,
        headers,
        ...(body && {
          body: contentType === 'application/json' ? JSON.stringify(body) : body,
        }),
      };
      const url = baseUrl || `${process.env.REACT_APP_BASE_URL}v1/`;
      const endpointURL = `${url}${endpoint}`;
      const response = await fetch(endpointURL, options);
      if (!response.ok) {
        logResponseError(options, response);
        reject(response);
        return;
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });

export type FetchOptions<BodyType> = {
  accessToken?: string;
  baseUrl?: string;
  body?: Record<string, BodyType> | FormData;
  contentType?: string;
  extraHeaders?: Record<string, string>;
  language?: string;
  method?: HttpMethod;
  noContentType?: boolean;
  queryParams?: Record<string, QueryValue>;
};

export const fetchApiWithTokenRefresh = async <BodyType>(endpoint: string, options: FetchOptions<BodyType> = {}) => {
  const { method = 'GET', queryParams, body, extraHeaders, baseUrl, language, noContentType } = options;
  if (queryParams) {
    const query = getQueryString(queryParams);
    endpoint = `${endpoint}${query}`;
  }
  let contentType = options?.contentType;
  if (!noContentType && !contentType && !!body) contentType = 'application/json';
  let hasRefreshed = false;
  let tokenToUse = options.accessToken;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    try {
      const response = await fetchApi(endpoint, method, contentType, tokenToUse, language, body, extraHeaders, baseUrl);
      return response;
    } catch (exception) {
      if (hasRefreshed || !tokenToUse || (exception as Response).status !== 401) {
        // If we've already refreshed or the response is not a 401 or we have
        // no token to refresh, we throw
        throw exception;
      }

      // We got a 401, let's try refreshing the token and try again
      let tokenStorage;
      try {
        tokenStorage = await TokenManagerSingleton.tokenRefresh();
      } catch (tokenException) {
        // Throw the original error, not the token exception
        throw exception;
      }
      hasRefreshed = true;
      if (!tokenStorage) {
        throw exception;
      }
      tokenToUse = tokenStorage.idToken;
    }
  }
};

export function* baseApiFetch<BodyType>(endpoint: string, options: FetchOptions<BodyType> = {}) {
  const language: string = yield select(getSiteLanguage);
  const storedToken: string = yield select(getIdToken);
  const newOptions = {
    ...options,
    language,
    accessToken: options.accessToken || storedToken,
  };
  const response: Response = yield call(fetchApiWithTokenRefresh, endpoint, newOptions);
  return response;
}

export function* apiFetch<BodyType, T>(endpoint: string, options: FetchOptions<BodyType> = {}) {
  const response: Response = yield call(baseApiFetch, endpoint, options);
  const responseJson: T = yield call(() => response.json());
  return responseJson;
}

export function* fileApiFetch<BodyType>(endpoint: string, options: FetchOptions<BodyType> = {}) {
  const response: Response = yield call(baseApiFetch, endpoint, options);
  const responseFile: Blob = yield call(() => response.blob());
  return responseFile;
}

type Keys = 'accessToken' | 'baseUrl' | 'extraHeaders' | 'method' | 'queryParams';
type ImageUploadOptions<T> = Pick<FetchOptions<T>, Keys> & { image: Blob };

export function* imageUploadApiFetch<BodyType, T>(endpoint: string, options: ImageUploadOptions<BodyType>) {
  const { image: _, ...newOptions } = options;
  const body = new FormData();
  body.append('image', options.image);

  const payload: T = yield call(formDataUploadApiFetch, endpoint, {
    ...newOptions,
    body,
  });
  return payload;
}

type FileUploadOptions<T> = Pick<FetchOptions<T>, Keys> & { body: FormData };

export function* formDataUploadApiFetch<BodyType, T>(endpoint: string, options: FileUploadOptions<BodyType>) {
  const { queryParams, extraHeaders, baseUrl, accessToken, method = 'POST', body } = options;
  if (queryParams) {
    const query = getQueryString(queryParams);
    endpoint = `${endpoint}${query}`;
  }
  const language: string = yield select(getSiteLanguage);
  const storedToken: string = yield select(getIdToken);

  const response: Response = yield call(
    fetchApi,
    endpoint,
    method,
    undefined,
    accessToken || storedToken,
    language,
    body,
    extraHeaders,
    baseUrl,
  );
  const payload: T = yield call(() => response.json());
  return payload;
}
