import { useDispatch, useSelector } from 'react-redux';

import { AddCustomContent } from 'domains/customContentAndSources/components/AddCustomContent';
import { getIsNewCustomContent } from 'domains/customContentAndSources/selectors';
import { useTranslations } from 'domains/language/useTranslations';
import { deactivateModal } from 'domains/modal/actions';
import { ModalHeader } from 'domains/modal/components/ModalHeader';

export const AddCustomContentModal = () => {
  const { textDictionary } = useTranslations();
  const dispatch = useDispatch();
  const isNewCustomContent = useSelector(getIsNewCustomContent);

  const dismissModal = () => dispatch(deactivateModal());

  const titleKey = isNewCustomContent ? 'add' : 'edit';
  return (
    <>
      <ModalHeader
        title={textDictionary[`app.custom_attachment.section.${titleKey}.title`]}
        dismissAction={dismissModal}
      />
      <AddCustomContent />
    </>
  );
};
