import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { removeInviteMapSharingFailure, removeInviteMapSharingSuccess } from 'domains/createShareSettings/actions';
import { MapRemoveInviteDefaultAction } from 'domains/createShareSettings/actionTypes';
import { activateModal } from 'domains/modal/actions';
import { DestinationTypes } from 'domains/types';
import { removeMapSuccess } from 'entities/topics/actions';

export function* removeInvitationSaga(action: MapRemoveInviteDefaultAction) {
  try {
    yield apiFetch('mapshare', {
      method: 'DELETE',
      queryParams: {
        topic_id: action.meta.topic_id,
        email: action.meta.email,
      },
    });
    yield put(removeInviteMapSharingSuccess(action.meta));
    if (action.meta && action.meta.removeMap) {
      yield put(
        removeMapSuccess({
          id: action.meta.topic_id,
          type: DestinationTypes.CUSTOM,
        }),
      );
    }
  } catch (error) {
    yield put(removeInviteMapSharingFailure(action.meta));
    yield put(activateModal({ id: 'Error' }));
  }
}
