import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { styled } from 'styled-components';

import { MasterDetailLayout } from 'common/components';
import { getPageId } from 'common/utilities';
import { trackEvent } from 'domains/analytics/actions';
import { useTranslations } from 'domains/language/useTranslations';
import { KnowledgeDetail } from 'domains/publication/components/KnowledgeDetail';
import { KnowledgeList } from 'domains/publication/components/KnowledgeList';
import { searchPublications } from 'domains/search/actions';
import { SearchInput } from 'domains/search/components/SearchInput';
import {
  getCurrentSearchTerm,
  getPublicationsSearchHasNextPage,
  getPublicationsSearchIsPending,
  getPublicationsSearchResults,
} from 'domains/search/selectors';
import { getKnowledgeById } from 'entities/knowledge/selectors';

const StyledSearch = styled(SearchInput)`
  margin-bottom: 2rem;
`;

export const SearchKnowledgePage = () => {
  const dispatch = useDispatch();
  const { textDictionary } = useTranslations();
  const { knowledgeId } = useParams<{ knowledgeId: string }>();
  const publications = useSelector(getPublicationsSearchResults());
  const id = getPageId(knowledgeId, publications);
  const knowledge = useSelector(getKnowledgeById(id));
  const query = useSelector(getCurrentSearchTerm);
  const isPending = useSelector(getPublicationsSearchIsPending);
  const hasNextPage = useSelector(getPublicationsSearchHasNextPage);

  useEffect(() => {
    if (knowledge) {
      dispatch(
        trackEvent({
          name: 'Browse page',
          page: 'Search',
          subpage: 'Knowledge detail',
          entityId: knowledge.id,
          entityName: knowledge.title,
          entityType: 'Publication',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [knowledge?.id, dispatch]);

  const loadNextPage = useCallback(() => {
    if (query) {
      dispatch(searchPublications({ query, offset: publications.length }));
    }
  }, [dispatch, query, publications.length]);

  const getLink = (knowledgeId: string) => `/search/publications/${knowledgeId}`;

  const knowledgeList = (
    <KnowledgeList
      items={publications.length === 0 && knowledge ? [knowledge] : publications}
      selectedId={id}
      trackPage="Search"
      getToLink={getLink}
      type="medium"
      showEmptyMessage={!!query}
      emptyMessage={textDictionary['app.search.results.no_results']}
      loadNextPage={loadNextPage}
      isPending={isPending}
      hasNextPage={hasNextPage && !!query}
    />
  );

  const shareLink = `${process.env.REACT_APP_WEBSITE_URL}${getLink(knowledge?.id || '')}`;

  return (
    <MasterDetailLayout
      headerElement={<StyledSearch type="publications" />}
      listTitle={textDictionary['app.discover.topic.tabs.knowledge.title']}
      listComponent={knowledgeList}
      listBackNavigation={{
        label: textDictionary['app.search.title'],
        path: '/search',
      }}
      detailBackNavigation={{
        label: textDictionary['app.discover.topic.tabs.knowledge.title'],
        path: '/search/publications',
      }}
      mainView={knowledgeId ? 'right' : 'left'}
    >
      <KnowledgeDetail id={id} knowledge={knowledge} shareLink={shareLink} />
    </MasterDetailLayout>
  );
};
