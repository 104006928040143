import { useState, ReactElement, Children, MouseEvent } from 'react';
import { css, styled } from 'styled-components';

import { ExpandableContent } from 'common/components';
import { breakpoints } from 'common/styles';

const StyledButton = styled.div`
  height: 100%;
  cursor: pointer;
`;

const StyledDropdownContent = styled.div<{ alignment: 'right' | 'left' }>`
  display: none;
  position: absolute;
  min-width: 265px;
  top: 100%;
  padding-top: 0;
  border-radius: 10px;
  overflow: visible;

  ${({ alignment }) =>
    alignment === 'left'
      ? css`
          left: 0;
        `
      : css`
          right: 0;
        `}

  @media ${breakpoints.large} {
    z-index: 1000;
  }
`;

const StyledContent = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.dropdownBackground};
  box-shadow: 0 8px 15px rgba(31, 27, 82, 0.1);
  border-radius: 10px;
  overflow: auto;
  max-height: calc(100vh - 80px);

  & > div > a,
  & > div > div,
  & > div > button {
    transition: background-color 0.3s;
    display: flex;
    cursor: pointer;
    font-size: 0.9rem;
    position: relative;
    padding: 12px 26px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.topHeaderBarText}AA;
    text-decoration: none;

    &:hover {
      background-color: ${({ theme }) => theme.colors.topHeaderBarActiveBackground};
      color: ${({ theme }) => theme.colors.topHeaderBarText};
    }
  }
`;

const StyledContainer = styled.div<{ isVisible: boolean }>`
  position: relative;
  display: none;
  height: 100%;

  @media ${breakpoints.large} {
    display: inline-block;
  }

  &:hover {
    ${StyledDropdownContent} {
      display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
    }
  }

  ${StyledDropdownContent} {
    display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  }
`;

const StyledSmallContainer = styled.div`
  display: block;

  @media ${breakpoints.large} {
    display: none;
  }
`;

const StyledExpandableContent = styled(ExpandableContent)`
  & > div > a,
  & > div > div,
  & > div > button {
    display: flex;
    font-size: 0.9rem;
    position: relative;
    padding: 12px 26px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.topHeaderBarText}AA;
    text-decoration: none;
    box-sizing: border-box;
  }
`;

const StyledItem = styled.div``;

type DropdownProps = {
  alignment?: 'right' | 'left';
  button: ReactElement;
  children: (boolean | ReactElement)[];
  onClick?: () => void;
};

export const Dropdown = ({ alignment = 'left', button, children, onClick }: DropdownProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const onSubItemClick = () => {
    setIsVisible(false);
    requestAnimationFrame(() => {
      if (onClick) {
        onClick();
      }
    });
  };

  const toggleOpen = (e: MouseEvent) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const list = Children.map(children, (child) => (
    <>{child && <StyledItem onClick={onSubItemClick}>{child}</StyledItem>}</>
  ));

  const showDropdown = () => setIsVisible(true);
  const hideDropdown = () => setIsVisible(false);
  const toggleDropdown = () => setIsVisible(!isVisible);

  return (
    <>
      <StyledSmallContainer>
        <div onClick={toggleOpen}>{button}</div>
        <StyledExpandableContent isActive={isOpen}>{list}</StyledExpandableContent>
      </StyledSmallContainer>
      <StyledContainer isVisible={isVisible} onMouseEnter={showDropdown} onMouseLeave={hideDropdown}>
        <StyledButton onClick={toggleDropdown}>{button}</StyledButton>
        {(list || []).length !== 0 && (
          <StyledDropdownContent alignment={alignment}>
            <StyledContent>{list}</StyledContent>
          </StyledDropdownContent>
        )}
      </StyledContainer>
    </>
  );
};
