import { put, select } from 'redux-saga/effects';

import { ApplyEventsSearchCriteriaAction } from 'domains/events/actionTypes';
import { getEventsSearchCriteria } from 'domains/events/selectors';
import { TopicEventSearchCriteria } from 'domains/events/types';
import { getTransformationMapSelectedId } from 'domains/transformationMap/selectors';
import { fetchEventsForTopic } from 'entities/events/actions';

export function* applyEventsSearchCriteriaSaga(action: ApplyEventsSearchCriteriaAction) {
  const searchCriteria: TopicEventSearchCriteria = yield select(getEventsSearchCriteria);
  let id: string = action.payload?.topicId;
  if (!id) {
    id = yield select(getTransformationMapSelectedId);
  }
  yield put(fetchEventsForTopic({ id, ...searchCriteria }));
}
