import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';

import { JoinUsUpgradeBanner } from 'common/components';
import { CollectionCarousel } from 'common/components/collectionCarousel/CollectionCarousel';
import { TopicPreviewCarousel } from 'common/components/topicPreviewCarousel/TopicPreviewCarousel';
import { mediaStyles } from 'common/styles';
import { fetchCollections } from 'domains/collection/actions';
import { selectCollections } from 'domains/collection/selectors';
import { CollectionType } from 'domains/collection/types/collectionType';
import { FeaturedCollection } from 'domains/discover/components/FeaturedCollection';
import { FollowingTopicsSection } from 'domains/landing/components/FollowingTopicsSection';
import { useTranslations } from 'domains/language/useTranslations';
import { getHighlightedTopics } from 'entities/topics/selectors';

const StyledCarouselContainer = styled.div`
  ${mediaStyles(({ spacing }) => `margin: 2rem ${spacing}rem 0;`)};
`;

const StyledJoinUsUpgradeBanner = styled(JoinUsUpgradeBanner)`
  ${mediaStyles(({ spacing }) => `margin: 2rem ${spacing}rem 0;`)};
`;

export const LandingPage = () => {
  const dispatch = useDispatch();
  const highlights = useSelector(getHighlightedTopics);
  const { featured, listed } = useSelector(selectCollections);
  const { textDictionary } = useTranslations();

  useEffect(() => {
    dispatch(fetchCollections(CollectionType.Featured));
    dispatch(fetchCollections(CollectionType.Listed));
  }, [dispatch]);

  const topicPreviewHeader = textDictionary['app.discover.topics.highlights.title'];
  const collectionHeader = textDictionary['app.discover.collections.title'];

  return (
    <>
      <TopicPreviewCarousel topics={highlights} header={topicPreviewHeader} />
      <StyledJoinUsUpgradeBanner trackPage={'Landing'} />
      {featured.map((collection) => (
        <StyledCarouselContainer key={collection?.id}>
          <FeaturedCollection collection={collection} />
        </StyledCarouselContainer>
      ))}
      <FollowingTopicsSection />
      <CollectionCarousel collections={listed} header={collectionHeader} />
    </>
  );
};
