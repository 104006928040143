import { Knowledge } from 'entities/knowledge/types';

export enum LatestKnowledgeActionType {
  FETCH = '[Latest knowledge] - Fetch',
  FETCH_FULFILLED = '[Latest knowledge] - Fetch - Fulfilled',
  FETCH_REJECTED = '[Latest knowledge] - Fetch - Rejected',
}

type LatestKnowledgeMeta = { filters?: Record<string, string[]>; limit?: number; offset: number };
export type LatestKnowledgeDefaultAction = { meta: LatestKnowledgeMeta; type: LatestKnowledgeActionType.FETCH };

export type LatestKnowledgeFulfilledAction = {
  meta: LatestKnowledgeMeta;
  payload: Knowledge[];
  type: LatestKnowledgeActionType.FETCH_FULFILLED;
};

export type LatestKnowledgeRejectedAction = {
  meta: LatestKnowledgeMeta;
  type: LatestKnowledgeActionType.FETCH_REJECTED;
};

export type LatestKnowledgeAction =
  | LatestKnowledgeDefaultAction
  | LatestKnowledgeFulfilledAction
  | LatestKnowledgeRejectedAction;
