import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { css, styled } from 'styled-components';

import { Spinner } from 'common/components/Spinner';
import { breakpoints } from 'common/styles';

const linkStyles = css`
  transition: 0.3s background-color;
  padding: 12px 20px;
  border-radius: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  background-color: white;
  cursor: pointer;
  width: 100%;
  color: ${({ theme }) => theme.colors.blue};
  text-decoration: none;
  box-sizing: border-box;

  @media ${breakpoints.medium} {
    width: auto;
    min-width: 160px;
    border-radius: 25px;
  }

  &:hover {
    background-color: #ddd;
  }
`;

const StyledContainer = styled.div`
  ${linkStyles};
`;

const StyledLink = styled(Link)`
  ${linkStyles};
`;

const ButtonText = styled.div`
  margin: 0;
  font-weight: bold;
  display: inline-block;
  white-space: nowrap;
`;

const StyledSpinner = styled(Spinner)`
  width: 15px;
  height: 15px;
  display: inline-block;
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.blue};
`;

type RoundedButtonProps = PropsWithChildren & {
  className?: string;
  dataTestId?: string;
  href?: string;
  isLoading?: boolean;
  onClick?: () => void;
  to?: string;
};

export const RoundedButton = ({
  children,
  className,
  dataTestId,
  href,
  isLoading,
  onClick,
  to,
}: RoundedButtonProps) => {
  const buttonContent = (
    <>
      <ButtonText>{children}</ButtonText>
      {isLoading && <StyledSpinner />}
    </>
  );

  if (to) {
    return (
      <StyledLink data-test-id={dataTestId} to={to} onClick={onClick} className={className}>
        {buttonContent}
      </StyledLink>
    );
  }
  return (
    <StyledContainer
      data-test-id={dataTestId}
      onClick={onClick}
      className={className}
      {...(href && {
        href,
        as: 'a',
        target: '_BLANK',
      })}
    >
      {buttonContent}
    </StyledContainer>
  );
};
