import { put } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import {
  monitorProgressGenerateBriefingFailure,
  monitorProgressGenerateBriefingSuccess,
} from 'domains/briefing/actions';
import {
  BriefingActionTypes,
  FetchBriefingPayload,
  MonitorProgressGenerateBriefingAction,
} from 'domains/briefing/actionTypes';
import { fetchBriefingSaga } from 'domains/briefing/sagas/fetchBriefingSaga';

type MonitorProgressGenerateBriefingResponse = { progress: number; request: string };

const DELAY_BETWEEN_FETCHES = 4000;

const delay = (ms: number | undefined) => new Promise((res) => setTimeout(res, ms));

// where the backend uses the terminology "requestId", the front end uses "briefingId"
// where the backend uses the terminology "documentId", the front end uses "templateName"
export function* monitorProgressGenerateBriefingSaga(action: MonitorProgressGenerateBriefingAction) {
  const { briefingId, templateName, topicName } = action.payload;
  try {
    while (true) {
      const response: MonitorProgressGenerateBriefingResponse = yield apiFetch(
        `advancedbriefing/status/${templateName}/${briefingId}`,
        {
          extraHeaders: {
            'cache-control': 'no-cache, no-store, must-revalidate',
            expires: '0',
          },
        },
      );
      yield put(
        monitorProgressGenerateBriefingSuccess({
          briefingId: briefingId,
          progress: response.progress,
          templateName: action.payload.templateName,
        }),
      );
      if (response.progress === 100) {
        const payload: FetchBriefingPayload = {
          briefingId,
          templateName,
          topicName: topicName,
        };
        yield fetchBriefingSaga({
          payload,
          type: BriefingActionTypes.FETCH_BRIEFING,
        });
        break;
      }
      yield delay(DELAY_BETWEEN_FETCHES);
    }
  } catch (e) {
    yield put(
      monitorProgressGenerateBriefingFailure({
        briefingId: briefingId,
      }),
    );
  }
}
