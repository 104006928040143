import { call, put, select } from 'redux-saga/effects';

import { apiFetch } from 'common/utilities';
import { unauthenticate } from 'domains/authentication/actions';
import { getIdToken } from 'domains/authentication/selectors';
import { FetchLanguageTermsDefaultAction } from 'domains/language/actionTypes';
import { activateModal } from 'domains/modal/actions';
import { fetchProfileFailure, fetchProfileSuccess } from 'entities/user/actions';
import { FetchProfileDefaultAction, UserProfileType } from 'entities/user/actionTypes';
import { UserData, UserInfoPayload } from 'entities/user/types';

export function* fetchProfile(token?: string) {
  if (!token) {
    token = yield select(getIdToken);
  }
  const payload: UserInfoPayload = yield apiFetch('userinfo', {
    accessToken: token,
  });
  const data = payload['http://sdy.ai/metadata'];
  const userData = {
    id: data.ext_id,
    fullName: data.full_name,
    firstName: data.first_name,
    lastName: data.last_name,
    imageUrl: data.photo_url,
    email: data.email,
    organization: data.organization,
    role: data.role,
    localizedRole: data.role_localised || data.role,
    isConstituent: data.constituent,
    notifications: data.notifications,
  };

  return userData;
}

type TriggerProfileFetchAction = FetchLanguageTermsDefaultAction | FetchProfileDefaultAction;

export function* fetchProfileSaga(action: TriggerProfileFetchAction) {
  try {
    const userData: UserData = yield call(fetchProfile);
    yield put(fetchProfileSuccess({ userData }));
  } catch (error) {
    const isDirectlyFetchingProfile = action.type === UserProfileType.FETCH;
    yield put(fetchProfileFailure({}, { logoutOnAuthError: !isDirectlyFetchingProfile }));
    if (isDirectlyFetchingProfile) {
      yield put(unauthenticate());
      yield put(activateModal({ id: 'Unauthenticated' }));
    }
  }
}
