import { put, select } from 'redux-saga/effects';

import { ApplyInitiativesSearchCriteriaAction } from 'domains/initiatives/actionTypes';
import { getInitiativesSearchCriteria } from 'domains/initiatives/selectors';
import { TopicInitiativeSearchCriteria } from 'domains/initiatives/types';
import { getTransformationMapSelectedId } from 'domains/transformationMap/selectors';
import { fetchInitiativesForTopic } from 'entities/initiatives/actions';

export function* applyInitiativesSearchCriteriaSaga(action: ApplyInitiativesSearchCriteriaAction) {
  const searchCriteria: TopicInitiativeSearchCriteria = yield select(getInitiativesSearchCriteria);
  let id: string = action.payload?.topicId;
  if (!id) {
    id = yield select(getTransformationMapSelectedId);
  }
  yield put(fetchInitiativesForTopic({ ...searchCriteria, id }));
}
